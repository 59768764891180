const styles = {
    container: {
        alignItems: "center",
        backgroundColor: "rgba(139, 149, 177, 0.16)",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        position: "fixed",
        width: "100%",
        zIndex: 2000
    }
};

export default styles;
