// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../../config/translation";

export const CLEAR_ENTRIES_LIST = "CLEAR_ENTRIES_LIST";
export const CLEAR_LEGAL_ENTITIES = "CLEAR_LEGAL_ENTITIES";
export const CLEAR_SUPPLIERS = "CLEAR_SUPPLIERS";
export const SAVE_LEGAL_ENTITIES = "SAVE_LEGAL_ENTITIES";
export const SAVE_SUPPLIERS = "SAVE_SUPPLIERS";
export const SET_LEGAL_ENTITY_SUPPLIERS_TAB = "SET_LEGAL_ENTITY_SUPPLIERS_TAB";
export const SET_URL_PARAMS = "SET_URL_PARAMS";

export const defaultState = {
    dataByTab: {
        legalEntities: {
            entries: [],
            totalCount: 0
        },
        suppliers: {
            entries: [],
            totalCount: 0
        }
    },
    legalEntities: undefined,
    suppliers: undefined,
    tab: "legalEntities",
    searchFilter: undefined,
    urlParams: {
        entityIdParam: undefined
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: defaultState.legalEntities
            });
        }
        case CLEAR_ENTRIES_LIST: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    [action.payload]: defaultState.dataByTab[action.payload]
                })
            });
        }
        case CLEAR_SUPPLIERS: {
            return Object.assign({}, state, {
                suppliers: defaultState.suppliers
            });
        }
        case SAVE_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    legalEntities: {
                        entries: action.payload.entries
                    }
                }),
                ...(!action.payload.isSearch && {
                    legalEntities: action.payload.entries
                })
            });
        }
        case SAVE_SUPPLIERS: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    suppliers: {
                        entries: action.payload.entries
                    }
                }),
                ...(!action.payload.isSearch && {
                    suppliers: action.payload.entries
                })
            });
        }
        case SET_LEGAL_ENTITY_SUPPLIERS_TAB: {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }

        case SET_URL_PARAMS: {
            return Object.assign({}, state, {
                urlParams: Object.assign({}, state.urlParams, {
                    entityIdParam: action.payload
                })
            });
        }

        default:
            return state;
    }
};

export const clearEntriesList = tab => {
    return {
        type: CLEAR_ENTRIES_LIST,
        payload: tab
    };
};

export const clearLegalEntities = () => {
    return {
        type: CLEAR_LEGAL_ENTITIES
    };
};

export const clearSuppliers = () => {
    return {
        type: CLEAR_SUPPLIERS
    };
};

export const deleteMapping =
    (legalEntityId, successCb, errorCb) => dispatch => {
        dispatch(startSpinner());

        api.delete(`legalentities/${legalEntityId}`)
            .then(() => {
                dispatch(getLegalEntities());
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.entitiesMapping.success.delete,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log("Error calling deleteMapping: ", e);
                errorCb && errorCb();
                dispatch(endSpinner());
            });
    };

export const getLegalEntities = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearLegalEntities());

    api.get("legalentities")
        .then(response => {
            dispatch(saveLegalEntities(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getLegalEntities: ", e);
            dispatch(endSpinner());
        });
};

export const getSuppliers = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearSuppliers());

    api.get("entities")
        .then(response => {
            dispatch(saveSuppliers(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getSuppliers: ", e);
            dispatch(endSpinner());
        });
};

export const saveLegalEntities = (entries, isSearch = false) => {
    return {
        type: SAVE_LEGAL_ENTITIES,
        payload: { entries, isSearch }
    };
};

export const saveSuppliers = (entries, isSearch = false) => {
    return {
        type: SAVE_SUPPLIERS,
        payload: { entries, isSearch }
    };
};

export const setLegalEntitySupplierTab = tab => {
    return {
        type: SET_LEGAL_ENTITY_SUPPLIERS_TAB,
        payload: tab
    };
};

export const setEntityIdParam = entityId => {
    return {
        type: SET_URL_PARAMS,
        payload: entityId
    };
};

export const upsertMapping = (mapping, successCb, errorCb) => dispatch => {
    dispatch(startSpinner());

    api.put("legalentities", mapping)
        .then(() => {
            mapping.tab === "legalEntities"
                ? dispatch(getLegalEntities())
                : dispatch(getSuppliers());
            successCb && successCb();
            dispatch(endSpinner());
            dispatch(
                openSnackbar(
                    mapping.type === "mapp"
                        ? translation.entitiesMapping.success[
                              get(mapping, "operation", undefined)
                          ]
                        : translation.entitiesMapping.success.delete,
                    "success"
                )
            );
        })
        .catch(e => {
            console.log("Error calling upsertMapping: ", e);

            let error = undefined;
            let errorType = "error";
            if (get(e, "response.status", undefined) === 409) {
                error = {
                    title: translation.entitiesMapping.errors
                        .duplicatedMappingTitle,
                    description:
                        translation.entitiesMapping.errors
                            .duplicatedMappingError
                };
                errorType = "warn";
            } else if (get(mapping, "operation", undefined) === "create") {
                error = {
                    title: translation.entitiesMapping.errors.genericTitle,
                    description:
                        translation.entitiesMapping.errors.genericErrorCreate
                };
            } else {
                error = {
                    title: translation.entitiesMapping.errors.genericTitle,
                    description:
                        translation.entitiesMapping.errors.genericErrorUpdate
                };
            }

            errorCb && errorCb(error, errorType);
            dispatch(endSpinner());
        });
};

export const deleteSupplierSeminars =
    (supplierId, successCb, errorCb) => dispatch => {
        dispatch(startSpinner());

        api.delete(`supplier/${supplierId}/seminars`)
            .then(() => {
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.entitiesMapping.success.deleteSemianrs,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log("Error calling deleteSupplierEvents: ", e);

                let error = undefined;
                if (get(e, "response.status", undefined) === 404) {
                    error = translation.entitiesMapping.errors.noSeminarsFound;
                } else {
                    error = translation.entitiesMapping.errors.genericTitle;
                }

                errorCb && errorCb();
                dispatch(endSpinner());
                dispatch(openSnackbar(error, "error"));
            });
    };
