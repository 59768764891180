// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
// Components
import { InfoMessage } from "../../../";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// Styles
import styles from "./SnackbarContentWrapper.css";

class SnackbarContentWrapper extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        className: PropTypes.string,
        CustomCloseIcon: PropTypes.func,
        message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        onClose: PropTypes.func,
        type: PropTypes.oneOf(["error", "info", "success", "warn"])
    };

    render() {
        const { classes, className, CustomCloseIcon, message, onClose, type } =
            this.props;

        return (
            <SnackbarContent
                style={styles.root}
                className={clsx(classes.root, classes.message, className)}
                aria-describedby="client-snackbar"
                message={
                    <InfoMessage
                        endAdornment={
                            CustomCloseIcon ? (
                                <CustomCloseIcon
                                    key="close"
                                    aria-label="close"
                                    onClick={onClose}
                                />
                            ) : (
                                <span
                                    className={clsx("icon-close", classes.icon)}
                                    key="close"
                                    aria-label="close"
                                    onClick={onClose}
                                />
                            )
                        }
                        icon={"default"}
                        message={message}
                        style={styles.messageComponent}
                        type={type}
                    />
                }
            />
        );
    }
}

export default withStyles(styles)(SnackbarContentWrapper);
