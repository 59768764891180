export const CLOSE_POPUP = "CLOSE_POPUP";
export const OPEN_POPUP = "OPEN_POPUP";

export const defaultState = {
    children: null,
    opened: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLOSE_POPUP: {
            return Object.assign({}, defaultState);
        }
        case OPEN_POPUP: {
            return Object.assign({}, state, {
                children: action.payload.children,
                opened: true
            });
        }
        default:
            return state;
    }
};

export const closePopup = () => {
    return {
        type: CLOSE_POPUP
    };
};

export const openPopup = ({ children = defaultState.children }) => {
    return {
        type: OPEN_POPUP,
        payload: { children }
    };
};
