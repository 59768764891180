import Global from "../../../../../../../../../config/global.css";

const styles = {
    card: {
        innerContainer: {
            display: "grid",
            gap: "12px",
            height: "fit-content"
        }
    },
    container: {
        display: "grid",
        gap: "24px",
        height: "fit-content"
    },
    description: {
        color: Global.colors.darkSlateBlue
    },
    approvedStatus: {
        alignItems: "center",
        backgroundColor: Global.colors.oliveGreen,
        borderRadius: "8px",
        color: Global.colors.white,
        display: "flex",
        fontFamily: "Muli",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 600,
        height: "37px",
        paddingLeft: "30px",
        textTransform: "uppercase"
    },
    draftStatus: {
        alignItems: "center",
        backgroundColor: Global.colors.leo,
        borderRadius: "8px",
        color: Global.colors.white,
        display: "flex",
        fontFamily: "Muli",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 600,
        height: "37px",
        paddingLeft: "30px",
        textTransform: "uppercase"
    },
    keywords: {
        boxContainer: {
            backgroundColor: Global.colors.darkSlateBlue,
            borderRadius: "4px",
            color: Global.colors.white,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "13px",
            margin: "4px",
            minHeight: "24px",
            padding: "5.5px 8px",
            width: "fit-content"
        },
        container: {
            container: {
                gap: "4px",
                padding: "24px 20px 20px"
            },
            title: {
                paddingLeft: "4px"
            }
        },
        innerContainer: {
            display: "flex",
            flexWrap: "wrap"
        },
        text: {
            alignItems: "center",
            color: Global.colors.darkSlateBlue,
            display: "flex",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "normal",
            hyphens: "auto",
            WebkitHyphens: "auto",
            lineHeight: "1.4em",
            wordBreak: "break-word"
        }
    },
    link: {
        fontSize: "inherit",
        fontStyle: "inherit",
        fontWeight: "600",
        lineHeight: "inherit",
        maxHeight: "fit-content",
        textAlign: "start"
    },
    multilineDescription: {
        whiteSpace: "break-spaces"
    }
};

export default styles;
