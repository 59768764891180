// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { change } from "redux-form";
import PropTypes from "prop-types";
import get from "lodash.get";
import debounce from "lodash/debounce";
// Components
import {
    InfiniteScrollLoading,
    MaterialFabButton,
    SearchBar,
    TableHeaders
} from "../../../components";
import { OfferRow } from "./components";
import { OffersFilters } from "../components";
import InfiniteScroll from "react-infinite-scroller";
// Actions
import {
    clearOffers,
    clearSearchFilter,
    clearSearchQuery,
    getLegalEntities,
    getSupplierEntities,
    getOffers,
    saveSearchFilter,
    saveSearchQuery
} from "./Offers.ducks";
// Actions
import { setSelectedSupplier } from "../Dashboard/Dashboard.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
import { clearOfferDetails } from "./components/OfferDetails/OfferDetails.ducks";
// Helpers
import { openOfferDetailsFormPopup } from "./helpers/offerDetailsFormHelper";
import { getUserRoleEntityId, hasPermission } from "./helpers/offerPermissions";
// Translations
import translation from "../../../config/translation";
// Styles
import styles from "./Offers.css";

class Offers extends PureComponent {
    static propTypes = {
        clearOfferDetails: PropTypes.func.isRequired,
        clearOffers: PropTypes.func.isRequired,
        clearSearchFilter: PropTypes.func.isRequired,
        clearSearchQuery: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        details: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        editPopup: PropTypes.object,
        getLegalEntities: PropTypes.func.isRequired,
        getSupplierEntities: PropTypes.func.isRequired,
        getOffers: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        supplierEntities: PropTypes.array,
        location: PropTypes.object,
        offerDetailsForm: PropTypes.object,
        offers: PropTypes.array,
        popup: PropTypes.object,
        profileData: PropTypes.object,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        saveSearchFilter: PropTypes.func.isRequired,
        saveSearchQuery: PropTypes.func.isRequired,
        searchFilter: PropTypes.object,
        searchQuery: PropTypes.string,
        totalCount: PropTypes.number,
        setSelectedSupplier: PropTypes.func,
        selectedSupplier: PropTypes.object
    };

    tableHeaders = [
        { label: translation.offers.tableHeaders.title },
        {
            label: translation.offers.tableHeaders.offerStatus,
            style: styles.alignRight
        },
        {
            label: translation.offers.tableHeaders.classification,
            style: styles.alignRight
        }
    ];

    componentDidMount() {
        const {
            clearOfferDetails,
            clearOffers,
            clearSearchFilter,
            clearSearchQuery,
            details,
            getLegalEntities,
            getSupplierEntities,
            legalEntities,
            supplierEntities,
            location,
            offers
        } = this.props;

        const previousLocation = get(location, "state.pathname", "");

        const hasPermissionToFilterByLegalEntity =
            this.hasPermissionToFilterByLegalEntity();

        const hasPermissionToFilterBySupplierEntity =
            this.hasPermissionToFilterBySupplierEntity();

        if (
            previousLocation.includes("offers/overview/") &&
            offers &&
            offers.length > 0
        ) {
            hasPermissionToFilterByLegalEntity &&
                (!legalEntities || legalEntities.length === 0) &&
                getLegalEntities &&
                getLegalEntities();

            hasPermissionToFilterBySupplierEntity &&
                (!supplierEntities || supplierEntities.length === 0) &&
                getSupplierEntities &&
                getSupplierEntities();

            const previousLocationSplitted = previousLocation.split("/");
            const offerURL =
                previousLocationSplitted &&
                previousLocationSplitted.length >= 3 &&
                previousLocationSplitted[3];
            const rowElement = offerURL && document.getElementById(offerURL);

            rowElement &&
                rowElement.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                    inline: "nearest"
                });
        } else {
            clearSearchFilter && clearSearchFilter();
            clearSearchQuery && clearSearchQuery();
            clearOffers && clearOffers();
            hasPermissionToFilterByLegalEntity &&
                getLegalEntities &&
                getLegalEntities();
            hasPermissionToFilterBySupplierEntity &&
                getSupplierEntities &&
                getSupplierEntities();
            this.handleLoadOffers({ fromStart: true });
        }

        details && clearOfferDetails && clearOfferDetails();
    }

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();

        this.debounceSearchOnChange && this.debounceSearchOnChange.cancel();
    }

    hasPermissionToFilterByLegalEntity = () => {
        const { profileData } = this.props;

        const userRole = get(profileData, "role", {});
        const hasPermissionToFilterByLegalEntity = hasPermission(
            userRole,
            "view"
        );

        return hasPermissionToFilterByLegalEntity;
    };

    hasPermissionToFilterBySupplierEntity = () => {
        const { profileData } = this.props;

        const userRole = get(profileData, "role", {});
        const hasPermissionToFilterBySupplierEntity = hasPermission(
            userRole,
            "view"
        );

        return hasPermissionToFilterBySupplierEntity;
    };

    handleLoadOffers = ({
        fromStart = true,
        query,
        classification,
        legalEntity,
        supplierEntity,
        status
    }) => {
        const { getOffers, offers, profileData } = this.props;

        const entityId = getUserRoleEntityId(profileData);

        getOffers &&
            getOffers({
                title: query || "",
                entity_id: entityId || legalEntity,
                supplier_id: supplierEntity,
                classification,
                limit: 20,
                status,
                startIndex: fromStart
                    ? 0
                    : offers && offers.length > 0
                    ? offers.length
                    : 0
            });
    };

    handleLoadMoreOffers = ({
        query,
        classification,
        legalEntity,
        supplierEntity,
        status
    }) => {
        const { offers, totalCount } = this.props;
        totalCount > 0 &&
            offers.length > 0 &&
            this.handleLoadOffers({
                fromStart: false,
                query,
                classification,
                legalEntity,
                supplierEntity,
                status
            });
    };

    openAddOfferPopup = () => {
        const {
            closeEditPopup,
            closePopup,
            dispatch,
            openEditPopup,
            openPopup
        } = this.props;

        openOfferDetailsFormPopup({
            closeEditPopup,
            closePopup,
            dispatch,
            editMode: false,
            getOfferDetailsForm: () => this.props.offerDetailsForm,
            openPopup,
            openEditPopup,
            status: ""
        });
    };

    loadEntries = (query, filters) => {
        this.handleLoadOffers({
            classification: get(filters, "classification", undefined),
            fromStart: true,
            legalEntity: get(filters, "legalEntity.value", undefined),
            supplierEntity: get(filters, "supplierEntity.value", undefined),
            status: get(filters, "offerStatus", undefined),
            query
        });
    };

    debounceSearchOnChange = debounce(this.loadEntries, 750);

    handleSearch = value => {
        const { saveSearchQuery, searchFilter, searchQuery } = this.props;

        const newValue = value || "";
        if (newValue !== searchQuery) {
            saveSearchQuery && saveSearchQuery(newValue);

            this.debounceSearchOnChange(newValue.trim(), searchFilter);
        }
    };

    getInitialFilterLegalEntity = filterOptionsLegalEntity => {
        const { searchFilter } = this.props;

        return (
            get(searchFilter, "legalEntity", undefined) ||
            (filterOptionsLegalEntity && filterOptionsLegalEntity[0])
        );
    };

    getInitialFilterSupplierEntity = filterOptionsSupplierEntity => {
        const { searchFilter, selectedSupplier } = this.props;

        return (
            get(searchFilter, "supplierEntity", undefined) ||
            (!selectedSupplier
                ? filterOptionsSupplierEntity && filterOptionsSupplierEntity[0]
                : selectedSupplier)
        );
    };

    handleSelectedSupplierFromDashboard = selectedSupplier => {
        const { setSelectedSupplier } = this.props;

        setSelectedSupplier && setSelectedSupplier(selectedSupplier);
    };

    getFilterOptionsLegalEntity = () => {
        const { legalEntities, supplierEntities } = this.props;

        const supplierIsSelected = this.getInitialFilterSupplierEntity();

        const sortByName = (a, b) => {
            return a["name"].localeCompare(b["name"]);
        };

        let filters = [];

        filters.push({
            key: "all",
            label: translation.offers.filters.all,
            option: {
                name: translation.offers.filters.all
            },
            value: "all"
        });

        if (legalEntities && !supplierIsSelected) {
            legalEntities.sort(sortByName).forEach(legalEntity => {
                const name = get(legalEntity, "name", undefined);
                const id = get(legalEntity, "_id", name);
                filters.push({
                    key: name,
                    label: name,
                    option: { name: name },
                    value: id
                });
            });
        } else {
            supplierEntities &&
                supplierEntities.map(supplierEntity => {
                    const getLegalEntitiesFromSupplier = get(
                        supplierEntity,
                        "legal_entities",
                        undefined
                    );
                    if (
                        getLegalEntitiesFromSupplier &&
                        supplierIsSelected &&
                        supplierIsSelected.value === supplierEntity._id
                    ) {
                        getLegalEntitiesFromSupplier
                            .sort(sortByName)
                            .forEach(legalEntity => {
                                const name = get(
                                    legalEntity,
                                    "name",
                                    undefined
                                );
                                const id = get(legalEntity, "_id", name);
                                filters.push({
                                    key: name,
                                    label: name,
                                    option: { name: name },
                                    value: id
                                });
                            });
                    }
                });
        }

        return filters;
    };

    getFilterOptionsSupplierEntity = () => {
        const { legalEntities, supplierEntities } = this.props;
        const options = this.getFilterOptionsLegalEntity();
        const legalIsSelected = this.getInitialFilterLegalEntity(options);

        const sortByName = (a, b) => {
            return a["name"].localeCompare(b["name"]);
        };

        let filters = [];
        filters.push({
            key: "all",
            label: translation.offers.filters.all,
            option: {
                name: translation.offers.filters.all
            },
            value: "all"
        });

        if (supplierEntities && legalIsSelected.value === "all") {
            supplierEntities.sort(sortByName).forEach(supplierEntity => {
                const name = get(supplierEntity, "name", undefined);
                const id = get(supplierEntity, "_id", name);
                filters.push({
                    key: name,
                    label: name,
                    option: { name: name },
                    value: id
                });
            });
        } else {
            legalEntities &&
                legalEntities.map(legalEntity => {
                    const getSuppliersFromLegalEntity = get(
                        legalEntity,
                        "suppliers_data",
                        undefined
                    );
                    if (
                        getSuppliersFromLegalEntity &&
                        legalIsSelected &&
                        legalIsSelected.value === legalEntity._id
                    ) {
                        getSuppliersFromLegalEntity
                            .sort(sortByName)
                            .forEach(supplierEntity => {
                                const name = get(
                                    supplierEntity,
                                    "name",
                                    undefined
                                );
                                const id = get(supplierEntity, "_id", name);
                                filters.push({
                                    key: name,
                                    label: name,
                                    option: { name: name },
                                    value: id
                                });
                            });
                    }
                });
        }

        return filters;
    };

    applyFilters = filtersValues => {
        const { saveSearchFilter, searchFilter, searchQuery } = this.props;

        let newFilters = { ...searchFilter };
        filtersValues &&
            filtersValues.forEach(({ filter, value }) => {
                let filterValue =
                    typeof value === "object"
                        ? get(value, "value", undefined)
                        : value;
                let newFilter = value;
                filterValue === "all" && (newFilter = undefined);

                newFilters[filter] = newFilter;
            });

        saveSearchFilter && saveSearchFilter(newFilters);
        this.loadEntries(searchQuery, newFilters);
    };

    render() {
        const { offers, profileData, searchFilter, searchQuery, totalCount } =
            this.props;

        const userRole = get(profileData, "role", {});
        const filterOptionsLegalEntity = this.getFilterOptionsLegalEntity();
        const filterOptionsSupplierEntity =
            this.getFilterOptionsSupplierEntity();

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.offersOverview}
                </div>
                <div className="offers-search" style={styles.topbarContainer}>
                    <SearchBar
                        icon="icon-search"
                        inputValue={searchQuery}
                        onChange={this.handleSearch}
                        placeholder={translation.offers.search}
                        style={{
                            container: styles.searchBarContainer,
                            input: styles.searchBarInput
                        }}
                    />
                    {hasPermission(userRole, "create") && (
                        <MaterialFabButton
                            onClick={this.openAddOfferPopup}
                            style={styles.addButtonContainer}
                            variant="circular"
                        >
                            <span
                                className="icon-plus"
                                style={styles.addButtonIcon}
                            />
                        </MaterialFabButton>
                    )}
                    <OffersFilters
                        applyFilters={this.applyFilters}
                        defaultLegalEntity={this.getInitialFilterLegalEntity(
                            filterOptionsLegalEntity
                        )}
                        defaultSupplierEntity={this.getInitialFilterSupplierEntity(
                            filterOptionsSupplierEntity
                        )}
                        handleSelectedSupplierFromDashboard={
                            this.handleSelectedSupplierFromDashboard
                        }
                        hasPermissionToFilterByLegalEntity={this.hasPermissionToFilterByLegalEntity()}
                        hasPermissionToFilterBySupplierEntity={this.hasPermissionToFilterBySupplierEntity()}
                        legalEntities={filterOptionsLegalEntity}
                        supplierEntities={filterOptionsSupplierEntity}
                        searchFilter={searchFilter}
                    />
                </div>
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...styles.tableHeaders
                    }}
                    headers={this.tableHeaders}
                />
                <div
                    className="infinite-scroll-container"
                    style={styles.rowsContainer}
                >
                    <InfiniteScroll
                        className="infinite-scroll"
                        hasMore={totalCount > (offers ? offers.length : 0)}
                        initialLoad={true}
                        loader={<InfiniteScrollLoading key="loading" />}
                        loadMore={() =>
                            this.handleLoadMoreOffers({
                                query: searchQuery,
                                classification: get(
                                    searchFilter,
                                    "classification",
                                    undefined
                                ),
                                status: get(
                                    searchFilter,
                                    "offerStatus",
                                    undefined
                                ),
                                legalEntity: get(
                                    searchFilter,
                                    "legalEntity.value",
                                    undefined
                                ),
                                supplierEntity: get(
                                    searchFilter,
                                    "supplierEntity.value",
                                    undefined
                                )
                            })
                        }
                        threshold={10}
                        useWindow={false}
                        style={styles.infineScroll}
                        getScrollParent={() =>
                            document.getElementsByClassName(
                                "dashboard-children-container"
                            )[0]
                        }
                    >
                        {offers &&
                            offers.map((offer, index) => {
                                return (
                                    <OfferRow
                                        containerStyle={styles.tableGrid}
                                        id={get(offer, "url", index)}
                                        index={index}
                                        key={index}
                                        offer={offer}
                                    />
                                );
                            })}
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details,
        editPopup: state.editPopup,
        legalEntities: state.offers.legalEntities,
        supplierEntities: state.offers.supplierEntities,
        offerDetailsForm: get(state, "form.offerDetails", undefined),
        offers: state.offers.offers,
        popup: state.popup,
        profileData: state.profileData,
        searchFilter: state.offers.searchFilter,
        searchQuery: state.offers.searchQuery,
        totalCount: state.offers.totalCount,
        selectedSupplier: state.dashboard.selectedSupplier
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                clearOfferDetails,
                clearOffers,
                clearSearchFilter,
                clearSearchQuery,
                closeEditPopup,
                closePopup,
                getLegalEntities,
                getSupplierEntities,
                getOffers,
                openEditPopup,
                openPopup,
                saveSearchFilter,
                saveSearchQuery,
                change,
                setSelectedSupplier
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
