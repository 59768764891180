import Global from "../../config/global.css";

const styles = {
    checkedStyle: {
        border: `2px solid ${Global.colors.blue}`
    },
    checkmark: {
        border: `2px solid ${Global.colors.blueyGrey}`,
        borderRadius: "100px",
        height: "16px",
        left: "0",
        position: "absolute",
        top: "1px",
        width: "16px"
    },
    container: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "block",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        paddingLeft: "24px",
        position: "relative",
        userSelect: "none",
        width: "fit-content"
    },
    containerDisabled: {
        color: Global.colors.darkPaleGrey,
        cursor: "default"
    },
    disabledStyle: {
        border: `2px solid ${Global.colors.darkPaleGrey}`
    },
    errorStyle: {
        border: `2px solid ${Global.colors.melon}`
    },
    input: {
        cursor: "pointer",
        opacity: "0",
        position: "absolute"
    }
};

export default styles;
