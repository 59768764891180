// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./SubMenuItem.css";

class SubMenuItem extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        icon: PropTypes.node.isRequired,
        id: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
            .isRequired,
        onClick: PropTypes.func.isRequired
    };

    state = {
        onHover: false
    };

    handleMouseEnter = () => {
        this.setState({ onHover: true });
    };

    handleMouseLeave = () => {
        this.setState({ onHover: false });
    };

    render() {
        const { active, icon, id, label, onClick } = this.props;
        const { onHover } = this.state;

        return (
            <div
                onClick={onClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                style={{
                    ...styles.container,
                    ...(onHover ? styles.onHover : {}),
                    ...(active ? styles.active : {})
                }}
            >
                <div
                    style={{
                        ...styles.iconContainer
                    }}
                >
                    <span
                        className={icon}
                        style={{
                            ...styles.icon,
                            ...styles.submenu[id],
                            ...(active ? styles.iconActive : {})
                        }}
                    />
                </div>
                <span>{label}</span>
            </div>
        );
    }
}

export default SubMenuItem;
