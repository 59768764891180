export const CLOSE_VIEW_POPUP = "CLOSE_VIEW_POPUP";
export const OPEN_VIEW_POPUP = "OPEN_VIEW_POPUP";
export const UPDATE_VIEW_POPUP_STATE = "UPDATE_VIEW_POPUP_STATE";

export const defaultState = {
    bottomContainer: undefined,
    children: null,
    header: undefined,
    previousState: undefined,
    opened: false,
    state: undefined,
    style: undefined
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLOSE_VIEW_POPUP: {
            if (state.previousState) {
                return Object.assign({}, state.previousState);
            }

            return Object.assign({}, defaultState);
        }
        case OPEN_VIEW_POPUP: {
            const isAlreadyOpened = state.opened;
            return Object.assign({}, state, {
                bottomContainer: action.payload.bottomContainer,
                children: action.payload.children,
                header: action.payload.header,
                opened: true,
                state: action.payload.state,
                style: action.payload.style,
                previousState: isAlreadyOpened
                    ? Object.assign({}, state)
                    : undefined
            });
        }
        case UPDATE_VIEW_POPUP_STATE: {
            return Object.assign({}, state, {
                state: action.payload
            });
        }
        default:
            return state;
    }
};

export const closeViewPopup = () => {
    return {
        type: CLOSE_VIEW_POPUP
    };
};

export const openViewPopup = ({
    bottomContainer = defaultState.bottomContainer,
    children = defaultState.children,
    header = defaultState.header,
    state = defaultState.state,
    style = defaultState.style
}) => {
    return {
        type: OPEN_VIEW_POPUP,
        payload: {
            bottomContainer,
            children,
            header,
            state,
            style
        }
    };
};

export const updateViewPopupState = state => {
    return {
        type: UPDATE_VIEW_POPUP_STATE,
        payload: state
    };
};
