// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { IconButton } from "../../../../../components";
// Actions
import {
    closeViewPopup,
    updateViewPopupState
} from "../../../../../components/ViewPopup/ViewPopup.ducks";
import { clearOfferDetails } from "../../../Offers/components/OfferDetails/OfferDetails.ducks";
// Helpers
import { formatDateToDEFormat } from "../../../../../services/date";
// Style
import styles from "./OfferDetailsHeader.css";

class OfferDetailsHeader extends PureComponent {
    static propTypes = {
        clearOfferDetails: PropTypes.func.isRequired,
        closeViewPopup: PropTypes.func.isRequired,
        handleBackToOfferLevel: PropTypes.func,
        level: PropTypes.string.isRequired,
        offer: PropTypes.object.isRequired,
        updateViewPopupState: PropTypes.func.isRequired,
        viewPopup: PropTypes.object
    };

    offerLevel = "offer";

    componentWillUnmount() {
        const { clearOfferDetails } = this.props;

        clearOfferDetails && clearOfferDetails();
    }

    handleGoBack = () => {
        const {
            clearOfferDetails,
            closeViewPopup,
            handleBackToOfferLevel,
            offer,
            updateViewPopupState,
            viewPopup
        } = this.props;

        const level = get(viewPopup, "state.level", this.offerLevel);
        if (level === this.offerLevel) {
            closeViewPopup && closeViewPopup();
            clearOfferDetails && clearOfferDetails();

            const loadOffer = get(
                viewPopup,
                "previousState.state.loadOffer",
                undefined
            );
            loadOffer && loadOffer();

            const scrollPosition = get(
                viewPopup,
                "state.scrollPosition",
                undefined
            );
            if (scrollPosition) {
                const element = document.getElementsByClassName(
                    scrollPosition.element
                )[0];

                if (element) {
                    setTimeout(() => {
                        element.scrollTo(
                            scrollPosition.left,
                            scrollPosition.top
                        );
                    }, 2);
                    return;
                }
            }

            const offerURL = get(offer, "url", undefined);
            const rowElement = offerURL && document.getElementById(offerURL);

            rowElement &&
                rowElement.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                    inline: "start"
                });
        } else if (level === "event") {
            updateViewPopupState &&
                updateViewPopupState(
                    Object.assign({}, get(viewPopup, "state", {}), {
                        level: this.offerLevel
                    })
                );
            handleBackToOfferLevel && handleBackToOfferLevel();

            const scrollPosition = get(
                viewPopup,
                "state.scrollPosition",
                undefined
            );
            if (scrollPosition) {
                const element = document.getElementsByClassName(
                    scrollPosition.element
                )[0];

                if (element) {
                    setTimeout(() => {
                        element.scrollTo(
                            scrollPosition.left,
                            scrollPosition.top
                        );
                    }, 2);
                    return;
                }
            }
        }
    };

    getHeaderTitle = () => {
        const { offer, viewPopup } = this.props;

        const offerTitle = get(offer, "title", "");
        const level = get(viewPopup, "state.level", this.offerLevel);

        if (level === this.offerLevel) {
            return offerTitle;
        } else {
            const eventStartDate = get(
                viewPopup,
                "state.event.start_date",
                undefined
            );

            return `${offerTitle} / ${formatDateToDEFormat(eventStartDate)}`;
        }
    };

    render() {
        const title = this.getHeaderTitle();

        return (
            <div style={styles.title}>
                <IconButton
                    defaultClassName="icon-arrow-back"
                    onClick={this.handleGoBack}
                    style={styles.backIcon}
                    type={["default"]}
                />
                <span>{title}</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPopup: state.viewPopup
    };
};

const mapDispatchToProps = {
    clearOfferDetails,
    closeViewPopup,
    updateViewPopupState
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsHeader);
