// Modules
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// Components
import { GroupCard } from "../../../";
import {
    IconButton,
    MaterialTextField
} from "../../../../../../../../../components";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import { required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Helpers
import { createEmptyLink } from "../../../../../../helpers/offerDetailsFormatHelper";
// Styles
import styles from "./ExternalMaterialLink.css";

class ExternalMaterialLink extends PureComponent {
    static propTypes = {
        classes: PropTypes.object
    };

    renderLink = ({ fields }) => {
        const { classes } = this.props;

        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.container}>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldInput
                                                }
                                            }}
                                            label={
                                                translation.offers.editPopup
                                                    .marketingMaterialsLink
                                            }
                                            name={`${item}.content`}
                                            placeholder={
                                                translation.offers.editPopup
                                                    .marketingMaterialsLinkPlaceholder
                                            }
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={required}
                                        />
                                    </div>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                            </div>
                        );
                    })}
                {fields.length === 0 && (
                    <IconButton
                        defaultClassName="icon-plus"
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                            fields.push(createEmptyLink());
                        }}
                        type={["default"]}
                        style={styles.addButton}
                    >
                        <span style={styles.addButtonLabel}>
                            {translation.offers.editPopup.addMarketingMaterials}
                        </span>
                    </IconButton>
                )}
            </Fragment>
        );
    };

    render() {
        return (
            <GroupCard title={translation.offers.editPopup.marketingMaterials}>
                <FieldArray component={this.renderLink} name="links" />
            </GroupCard>
        );
    }
}

export default withStyles(styles)(ExternalMaterialLink);
