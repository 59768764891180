// Modules
import React from "react";
import { submit } from "redux-form";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import differenceWith from "lodash.differencewith";
// Components
import { WarningPopup } from "../../../../../../../../components";
import { EventDetailsForm } from "../components";
// Helper
import { changeFormat } from "../../../../../../../../services/date";
// Translations
import translation from "../../../../../../../../config/translation";
// Styles
import styles from "../Events.css";

/**
 * Checks if there are changes and if there is displays a popup to confirm unsaved changes.
 *
 * @param {Object} params all functions and params to perform the check and popup opening.
 */
function handleCancelEditPopup({
    closeEditPopup,
    getEventDetailsForm,
    closePopup,
    openPopup
}) {
    const eventDetailsForm = getEventDetailsForm && getEventDetailsForm();
    const initialValues = get(eventDetailsForm, "initial", undefined);
    const newValues = get(eventDetailsForm, "values", undefined);
    const valuesHasChanged = !isEqual(initialValues, newValues);

    if (valuesHasChanged) {
        openPopup({
            children: displayWarningPopup(
                () => {
                    closeEditPopup();
                    closePopup();
                },
                translation.events.editPopup.confirm,
                closePopup,
                "warn",
                translation.events.editPopup.unsavedWarningMessage,
                translation.events.editPopup.unsavedChanges
            )
        });
    } else {
        closeEditPopup();
    }
}

/**
 * Opens the warning popup with the error passed in param.
 *
 * @param {Object} param the error and functions to open and close warning popup
 */
export function handleErrorPopupDisplay({
    action,
    actionLabel,
    errorType,
    errorMessage: { title, subtitle },
    closePopup,
    openPopup
}) {
    openPopup({
        children: displayWarningPopup(
            action || closePopup,
            actionLabel || translation.events.editPopup.tryAgain,
            closePopup,
            errorType,
            subtitle,
            title
        )
    });
}

/**
 * Displays the warning popup.
 *
 * @param {Function} action the main action
 * @param {String} actionLabel the main action label
 * @param {Function} closeAction the close action
 * @param {String} errorType the error type (warn or error)
 * @param {String} subtitle the popup subtitle
 * @param {String} title the popup title
 */
export function displayWarningPopup(
    action,
    actionLabel,
    closeAction,
    errorType,
    subtitle,
    title
) {
    const isSuccess = errorType === "success";

    return (
        <WarningPopup
            buttonAction={() => {
                action && action();
            }}
            buttonLabel={actionLabel}
            closeFunction={() => {
                closeAction && closeAction();
            }}
            iconClassName={isSuccess ? "icon-success" : undefined}
            imageStyle={
                isSuccess
                    ? styles.warningPopupImageSuccess
                    : errorType === "error"
                    ? styles.warningPopupImageError
                    : styles.warningPopupImage
            }
            subTitle={subtitle}
            title={title}
        />
    );
}

/**
 * Opens the event details form.
 *
 * @param {Object} params all functions and params to perform the open.
 */
export function openEventDetailsFormPopup({
    closeEditPopup,
    getEventDetailsForm,
    closePopup,
    openPopup,
    dispatch,
    openEditPopup,
    editMode,
    match
}) {
    openEditPopup &&
        openEditPopup({
            cancelAction: () =>
                handleCancelEditPopup({
                    closeEditPopup,
                    getEventDetailsForm,
                    closePopup,
                    openPopup
                }),
            cancelButtonLabel: translation.events.editPopup.cancel,
            children: <EventDetailsForm {...{ match }} />,
            confirmAction: () => {
                dispatch(submit("eventDetails"));
            },
            confirmButtonLabel: editMode
                ? translation.events.editPopup.saveChanges
                : translation.events.editPopup.createEvent,
            header: editMode
                ? translation.events.editPopup.editEvent
                : translation.events.editPopup.createEvent
        });
}

/**
 * Sorts by label.
 *
 * @param {Object} a object to compare
 * @param {Object} b object to compare
 */
export const sortByLabel = (a, b) =>
    get(a, "label", "").localeCompare(get(b, "label", ""));

export function addIfDiff(oldValue, newValue, key, diffObj) {
    oldValue !== newValue && (diffObj[key] = newValue);
}

/**
 * Map modules to be sent in the api call to save/update the event.
 */
export function mapModules(initialModules, modules, key, event) {
    if (initialModules !== modules) {
        event[key] = (modules || []).map(module => {
            const startDate =
                module.startDate &&
                changeFormat(module.startDate, "YYYY-MM-DDTHH:mm:ss");
            const endDate =
                module.endDate &&
                changeFormat(module.endDate, "YYYY-MM-DDTHH:mm:ss");

            let unit = get(module, "duration.unit", undefined);
            unit = unit ? unit.value || unit : undefined;

            return {
                number: get(module, "number", undefined),
                title: get(module, "name", undefined),
                duration: get(module, "duration.value", undefined),
                duration_type: unit,
                start_date: startDate,
                end_date: endDate
            };
        });
    }
}

/**
 * Map location data to be sent in the api call to save/update the event.
 */
export function mapLocation(initialLocation, location, key, event) {
    if (initialLocation !== location) {
        let newLocation = {};
        if (location.id) {
            newLocation._id = location.id;
            addIfDiff(
                initialLocation.latitude,
                location.latitude,
                "latitude",
                newLocation
            );
            addIfDiff(
                initialLocation.longitude,
                location.longitude,
                "longitude",
                newLocation
            );
            if (newLocation.latitude && !newLocation.longitude) {
                newLocation["longitude"] = location.longitude;
            }
            if (!newLocation.latitude && newLocation.longitude) {
                newLocation["latitude"] = location.latitude;
            }

            let name = get(location, "name", undefined);
            name = name ? name.value || name : undefined;
            addIfDiff(initialLocation.name, name, "name", newLocation);

            addIfDiff(
                initialLocation.postalcode,
                location.postalcode,
                "postal_code",
                newLocation
            );
        } else {
            newLocation = {
                latitude: get(location, "latitude", undefined),
                longitude: get(location, "longitude", undefined),
                name: get(location, "name.value", undefined),
                postal_code: get(location, "postalcode", undefined)
            };
        }

        event[key] = newLocation;
    }
}

/**
 * Map entity data to be sent in the api call to save/update the event.
 */
export function mapEntity(initialEntity, entity, key, event) {
    if (initialEntity !== entity) {
        let newEntity = {};

        if (entity.id) {
            newEntity._id = entity.id;

            let name = get(entity, "name", undefined);
            name = name ? name.value || name : undefined;
            addIfDiff(initialEntity.name, name, "name", newEntity);
            addIfDiff(initialEntity.link, entity.link, "link", newEntity);
        } else {
            newEntity = {
                name: get(entity, "name.value", undefined),
                link: get(entity, "link", undefined)
            };
        }

        event[key] = newEntity;
    }
}

/**
 * Checks if there are differences between two objects.
 *
 * @param {Object} initialValue the initial object
 * @param {Object} value the object to compare
 */
export function checkDiffContactContent(initialValue, value) {
    if (!initialValue) {
        return true;
    }

    const diffs = differenceWith(
        Object.entries(value),
        Object.entries(initialValue),
        isEqual
    );

    return diffs && diffs.length > 0;
}

/**
 * Map contacts to be sent in the api call to save/update the event.
 */
export function mapContacts(initialContacts, contacts, key, event, entityId) {
    if (
        (!initialContacts || initialContacts.length === 0) &&
        (!contacts || contacts.length === 0)
    ) {
        return;
    }

    let newContacts = [];

    contacts &&
        contacts.forEach((contact, index) => {
            const initialContact =
                initialContacts &&
                initialContacts.length > index &&
                initialContacts[index];
            const diff = checkDiffContactContent(initialContact, contact);

            if (diff) {
                let newContact = {};
                if (entityId !== null && entityId === contact.entityId) {
                    newContact._id = contact.id;

                    let name = get(contact, "name", undefined);
                    name = name ? name.value || name : undefined;
                    addIfDiff(initialContact.name, name, "name", newContact);
                    addIfDiff(
                        initialContact.email,
                        contact.email,
                        "email",
                        newContact
                    );
                    addIfDiff(
                        initialContact.phone,
                        contact.phone,
                        "phone",
                        newContact
                    );
                    addIfDiff(
                        initialContact.pictureLink,
                        contact.pictureLink,
                        "picture_link",
                        newContact
                    );
                } else {
                    newContact = {
                        name: get(contact, "name.value", undefined),
                        email: get(contact, "email", undefined),
                        phone: get(contact, "phone", undefined),
                        picture_link: get(contact, "pictureLink", undefined)
                    };
                }

                if (Object.keys(newContact).length > 0) {
                    newContacts.push(newContact);
                }
            } else {
                const newContact = {
                    _id:
                        entityId !== null && entityId === contact.entityId
                            ? get(contact, "id", undefined)
                            : undefined,
                    name: get(contact, "name", undefined),
                    email: get(contact, "email", undefined),
                    phone: get(contact, "phone", undefined),
                    picture_link: get(contact, "pictureLink", undefined)
                };

                newContacts.push(newContact);
            }
        });

    event[key] = newContacts;
}

/**
 * Maps the event data to be saved/updated.
 *
 * @param {Object} initialValue the initial object
 * @param {Object} value the object to compare
 */
export function mapEventData(initialValues, values) {
    let event = {};
    event._id = values.id;

    const price =
        !values.price || get(values, "price", "").length === 0
            ? null
            : values.price && values.price.replace(".", "").replace(",", ".");
    const intialPrice = initialValues.price
        ? initialValues.price.replace(".", "").replace(",", ".")
        : null;
    addIfDiff(intialPrice, price, "price", event);

    let type = get(values, "type", undefined);
    type = type ? type.value || type : undefined;
    addIfDiff(initialValues.type, type, "type", event);

    let durationType = get(values, "duration.unit", undefined);
    durationType = durationType
        ? durationType.value || durationType
        : undefined;
    addIfDiff(
        initialValues.duration.unit,
        durationType,
        "duration_type",
        event
    );
    addIfDiff(
        initialValues.duration.value,
        values.duration.value,
        "duration",
        event
    );
    addIfDiff(
        initialValues.externalId,
        values.externalId,
        "external_id",
        event
    );
    addIfDiff(
        initialValues.externalLink,
        values.externalLink,
        "external_link",
        event
    );

    addIfDiff(
        initialValues.enrolmentLink,
        values.enrolmentLink,
        "enrolment_link",
        event
    );

    const initialStartDate =
        initialValues.startDate &&
        changeFormat(initialValues.startDate, "YYYY-MM-DDTHH:mm:ss");
    const startDate =
        values.startDate &&
        changeFormat(values.startDate, "YYYY-MM-DDTHH:mm:ss");
    addIfDiff(initialStartDate, startDate, "start_date", event);

    const initialEndDate =
        initialValues.endDate &&
        changeFormat(initialValues.endDate, "YYYY-MM-DDTHH:mm:ss");
    const endDate =
        values.endDate && changeFormat(values.endDate, "YYYY-MM-DDTHH:mm:ss");
    addIfDiff(initialEndDate, endDate, "end_date", event);

    addIfDiff(
        initialValues.instructors,
        values.instructors,
        "instructors",
        event
    );

    mapModules(initialValues.modules, values.modules, "modules", event);
    addIfDiff(
        initialValues.location.id,
        values.location.id,
        "location_id",
        event
    );
    mapLocation(initialValues.location, values.location, "location", event);
    addIfDiff(initialValues.entity.id, values.entity.id, "entity_id", event);
    mapEntity(initialValues.entity, values.entity, "entity", event);
    mapContacts(
        initialValues.contacts,
        values.contacts,
        "entity_contacts",
        event,
        values.entity.id
    );

    return event;
}
