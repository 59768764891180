// Modules
import { Text } from "slate";
import escapeHtml from "escape-html";
import { jsx } from "slate-hyperscript";
// Helpers
import { ELEMENT_TAGS, TEXT_TAGS } from "./elements";

/**
 * From internal structure to HTML tags.
 *
 * @param {Node} node the node element to convert
 */
export function serializeNode(node) {
    if (Text.isText(node)) {
        let text = escapeHtml(node.text);
        text = text.replace(/(\r\n|\n|\r)/gi, "<br>");

        if (node.bold) {
            text = `<strong>${text}</strong>`;
        }

        return text;
    }

    const children = node.children.map(n => serializeNode(n)).join("");

    switch (node.type) {
        case "heading-one":
        case "heading-three":
            return `<h3>${children}</h3>`;
        case "heading-two":
        case "heading-four":
            return `<h4>${children}</h4>`;
        case "heading-five":
            return `<h5>${children}</h5>`;
        case "heading-six":
            return `<h6>${children}</h6>`;
        case "bulleted-list":
            return `<ul>${children}</ul>`;
        case "list-item":
            return `<li>${children}</li>`;
        case "numbered-list":
            return `<ol>${children}</ol>`;
        case "paragraph":
            return `<p>${children}</p>`;
        case "div":
            return `<div>${children}</div>`;
        case "span":
            return `<span>${children}</span>`;
        case "quote":
            return `<blockquote>${children}</blockquote>`;
        case "code":
            return `<pre><code>${children}</code></pre>`;
        case "link":
            return `<a target="_blank" href={${node.url}}>${children}</a>`;
        default:
            return children;
    }
}

export function serialize(nodes) {
    if (!nodes || nodes.length === 0) {
        return null;
    }

    let html = "";
    nodes &&
        nodes.forEach(node => {
            html += serializeNode(node);
        });

    if (!html.startsWith("<div>") && !html.endsWith("</div>")) {
        html = "<div>" + html + "</div>";
    }

    return html;
}

/**
 * From html to internal structure.
 *
 * @param {*} el the element to convert
 */
export function deserialize(el) {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === "BR") {
        return "\n";
    }

    const { nodeName } = el;
    let parent = el;

    if (
        nodeName === "PRE" &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === "CODE"
    ) {
        parent = el.childNodes[0];
    }

    let children = Array.from(parent.childNodes).map(deserialize).flat();

    if (!children || children.length === 0) {
        children = [""];
    }

    if (el.nodeName === "BODY") {
        return jsx("fragment", {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx("element", attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map(child => jsx("text", attrs, child));
    }

    return jsx("element", {}, children);
}
