import Global from "../../config/global.css";

const styles = {
    button: {
        backgroundColor: Global.colors.blue,
        textTransform: "uppercase"
    },
    closeIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px",
        position: "absolute",
        right: "24px",
        top: "25px"
    },
    container: {
        alignItems: "center",
        background: Global.colors.white,
        borderRadius: "8px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.16)",
        display: "flex",
        flexDirection: "column",
        minWidth: "344px",
        padding: "24px 24px 32px",
        position: "relative",
        width: "380px"
    },
    subTitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px",
        marginBottom: "36px",
        marginTop: "16px",
        textAlign: "center"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "23px",
        marginTop: "32px",
        textAlign: "center"
    },
    warningIcon: {
        alignItems: "center",
        background: "rgba(255, 121, 89, 0.2)",
        borderRadius: "100px",
        color: Global.colors.melon,
        display: "flex",
        fontSize: "24px",
        justifyContent: "center",
        height: "48px",
        width: "48px"
    }
};

export default styles;
