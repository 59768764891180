const styles = {
    circle: {
        cx: "44px",
        cy: "44px",
        fill: "none",
        r: "18px",
        strokeWidth: "6.4px"
    },
    container: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: "24px 24px 0px"
    }
};

export default styles;
