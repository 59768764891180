// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
// Components
import { MaterialButton } from "../";
// Styles
import styles from "./EditPopup.css";

class EditPopup extends PureComponent {
    static propTypes = {
        editPopup: PropTypes.object
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        const {
            editPopup: { opened }
        } = this.props;

        opened && this.setWidth();
    };

    componentDidUpdate(prevProps) {
        const {
            editPopup: { opened }
        } = this.props;
        if (opened && !get(prevProps, "editPopup.opened", undefined)) {
            const parent = this.setWidth();

            parent && parent.scrollTo(0, 0);
        }
    }

    setWidth() {
        const element = document.getElementsByClassName("edit-popup")[0];
        const parent = element && element.parentElement;

        element.style.width = `${parent.scrollWidth - 48}px`;

        return parent;
    }

    render() {
        const {
            editPopup: {
                cancelAction,
                cancelButtonLabel,
                children,
                confirmAction,
                confirmButtonLabel,
                header,
                opened,
                optionalAction,
                optionalButtonLabel,
                optionalConfirmAction,
                optionalConfirmLabel,
                optionalDeleteAction,
                optionalDeleteButtonLabel,
                style
            }
        } = this.props;

        if (!opened) {
            return null;
        }

        return (
            <div
                className="edit-popup"
                style={{ ...styles.container, ...get(style, "container", {}) }}
            >
                <div style={styles.header}>{header}</div>
                <div style={{ ...styles.body, ...get(style, "body", {}) }}>
                    {children}
                    {(cancelAction ||
                        confirmAction ||
                        optionalAction ||
                        optionalDeleteAction ||
                        optionalConfirmAction) && (
                        <div style={styles.buttonsContainer}>
                            <div style={styles.optionalButtonsContainer}>
                                {optionalAction && (
                                    <MaterialButton
                                        customType="boldPrimary"
                                        onClick={() => optionalAction()}
                                        style={styles.optionalButton}
                                    >
                                        {optionalButtonLabel}
                                    </MaterialButton>
                                )}
                                {optionalDeleteAction && (
                                    <MaterialButton
                                        customType="boldPrimary"
                                        onClick={() => optionalDeleteAction()}
                                        style={{
                                            ...styles.optionalDeleteButton,
                                            ...styles.buttonsMarginLeft
                                        }}
                                    >
                                        {optionalDeleteButtonLabel}
                                    </MaterialButton>
                                )}
                            </div>
                            {(cancelAction ||
                                confirmAction ||
                                optionalConfirmAction) && (
                                <div style={styles.requiredButtonsContainer}>
                                    <div style={styles.cancelButtonContainer}>
                                        {cancelAction && (
                                            <MaterialButton
                                                customType="boldPrimary"
                                                onClick={() => cancelAction()}
                                                style={styles.cancelButton}
                                            >
                                                {cancelButtonLabel}
                                            </MaterialButton>
                                        )}
                                    </div>

                                    {optionalConfirmAction && (
                                        <div
                                            style={
                                                styles.optionalConfirmButtonContainer
                                            }
                                        >
                                            <MaterialButton
                                                customType="boldPrimary"
                                                onClick={() => {
                                                    optionalConfirmAction();
                                                }}
                                                style={
                                                    styles.optionalConfirmButton
                                                }
                                            >
                                                {optionalConfirmLabel}
                                            </MaterialButton>
                                        </div>
                                    )}

                                    {confirmAction && (
                                        <MaterialButton
                                            customType="boldPrimary"
                                            onClick={() => confirmAction()}
                                            style={styles.confirmButton}
                                        >
                                            {confirmButtonLabel}
                                        </MaterialButton>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        editPopup: state.editPopup
    };
};

export default connect(mapStateToProps)(EditPopup);
