import Global from "../../config/global.css";

const styles = {
    clearIcon: {
        container: {
            alignItems: "center",
            color: Global.colors.darkSlateBlue,
            cursor: "pointer",
            display: "flex",
            height: "16px",
            justifyContent: "center",
            marginLeft: "8px",
            width: "16px"
        },
        icon: {
            color: Global.colors.darkSlateBlue,
            fontSize: "16px"
        }
    },
    container: {
        width: "240px"
    },
    control: {
        backgroundColor: Global.colors.white,
        border: "none !important",
        flexWrap: "unset",
        padding: "8px 16px",
        width: "inherit"
    },
    icon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px"
    },
    iconContainer: {
        marginLeft: "8px"
    },
    input: {
        color: Global.colors.darkSlateBlue,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "1.4em",
        marginLeft: "-2px",
        marginRight: "0px",
        overflow: "hidden",
        padding: 0
    },
    menuList: {
        maxHeight: "336px"
    },
    option: {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "unset",
        lineHeight: "1.4em",
        minHeight: "40px",
        padding: "12px 16px"
    },
    optionFocused: {
        backgroundColor: Global.colors.paleGrey
    },
    singleValue: {
        display: "-webkit-box",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "15px",
        maxHeight: "48px",
        marginRight: 0,
        hyphens: "auto",
        WebkitHyphens: "auto",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        whiteSpace: "unset",
        wordBreak: "break-word"
    },
    valueContainer: {
        flex: "unset",
        flexWrap: "unset",
        overflow: "unset",
        width: "176px"
    },
    valueContainerWithClear: {
        width: "152px"
    }
};

export default styles;
