export const routesPaths = {
    chat: "/chat",
    chambersLocations: "/offers/chamberslocations",
    dashboard: "/",
    mapping: "/mapping",
    entitiesMapping: "/mapping/entitiesmapping",
    entitiesUploadsTracking: "/mapping/entitiesuploads",
    unmappedEntities: "/mapping/unmappedentities",
    coopPartnersMapping: "/mapping/cooppartnersmapping",
    eventDetails: "/offers/overview/:offerUrl/events/:eventIndex",
    invoicing: "/invoicing",
    offers: "/offers",
    offersClassification: "/offers/classification",
    offersFileUpload: "/offers/fileupload",
    offersKeywordsTagging: "/offers/keywordstagging",
    offersOverview: "/offers/overview",
    offerDetails: "/offers/overview/:offerUrl",
    badges: "/badges",
    settings: "/settings",
    userManual: "/usermanual",
    users: "/users",
    marketingMaterials: "/marketingmaterials"
};
