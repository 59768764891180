import Global from "../../config/global.css";

const styles = {
    container: {
        alignSelf: "center",
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        justifySelf: "start",
        width: "auto",
        height: "auto",
        backgroundColor: Global.colors.digitalBlueT4,
        onHover: {
            color: `${Global.colors.white} !important`,
            backgroundColor: `${Global.colors.darkSlateBlue} !important`
        }
    },
    iconRipple: {
        height: "48px !important",
        left: "0px !important",
        top: "-12px !important",
        width: "50px !important"
    },
    label: {
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "1.4em",
        padding: "6px 8px"
    }
};

export default styles;
