import Global from "../../config/global.css";

const styles = {
    menu: {
        paper: {
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            "&.MuiPaper-rounded": {
                borderRadius: "0px 0px 6px 6px"
            },
            "& .MuiList-padding": {
                padding: "0px"
            }
        }
    },
    menuItem: {
        root: {
            alignItems: "center",
            color: Global.colors.darkSlateBlue,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            justifyContent: "center",
            lineHeight: "13px",
            minHeight: "64px",
            textTransform: "uppercase",
            whiteSpace: "normal",
            "&:hover": {
                backgroundColor: Global.colors.lightPaleGrey
            }
        }
    }
};

export default styles;
