// Modules
import React from "react";
import get from "lodash.get";
// Translation
import translation from "../../../../config/translation";

/**
 * Opens a snackbar with success message.
 *
 * @param {Object} offer the offer details
 * @param {Funtion} openSnackbar function to open the snackbar
 * @param {Funtion} refreshDataCb function to trigger data refresh
 * @param {Object} customTexts custom title and subtitle texts
 */
export function handleActionSuccess({
    offer,
    openSnackbar,
    refreshDataCb,
    customTexts
}) {
    const offerTitle = get(offer, "title", undefined);
    const message = (
        <span>
            {`${get(customTexts, "text1", undefined)} `}
            <b>{`"${offerTitle}"`}</b>
            {` ${get(customTexts, "text2", undefined)}`}
        </span>
    );

    refreshDataCb && refreshDataCb();
    openSnackbar && openSnackbar(message, "success");
}

/**
 * Opens popup with an error message.
 *
 * @param {Funtion} openPopup function to open popup
 * @param {Funtion} refreshDataCb function to trigger data refresh
 */
export function handleActionError({ openPopup, refreshDataCb }) {
    openPopup &&
        openPopup(
            true,
            translation.offersClassification.error.title,
            translation.offersClassification.error.subtitle,
            refreshDataCb
        );
}
