import Global from "../../../config/global.css";

const styles = {
    bottomContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        default: {
            fontWeight: "600",
            textTransform: "uppercase"
        },
        disabled: {
            backgroundColor: Global.colors.darkPaleGrey,
            color: Global.colors.white
        }
    },
    buttonsContainer: {
        alignItems: "center",
        display: "grid",
        gap: "8px",
        gridAutoFlow: "column"
    },
    clearButton: {
        color: Global.colors.blueyGrey,
        fontSize: "16px",
        pointerEvents: "auto"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "24px 24px 32px"
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "24px"
    },
    innerContainer: {
        display: "flex",
        flex: 1
    },
    textField: {
        backgroundColor: Global.colors.white,
        borderRadius: "10px",
        height: "fit-content",
        minWidth: "500px",
        width: "50%",

        input: {
            textOverflow: "ellipsis"
        }
    },
    textFieldDisabled: {
        pointerEvents: "none"
    },
    uploadButton: {
        backgroundColor: Global.colors.blue,
        borderRadius: "6px",
        fontSize: "14px",
        lineHeight: "21px",
        maxHeight: "32px",
        minWidth: "120px",
        padding: "4px 24px",
        pointerEvents: "auto"
    },
    uploadInput: {
        display: "none"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
