export const CLOSE_EDIT_POPUP = "CLOSE_EDIT_POPUP";
export const OPEN_EDIT_POPUP = "OPEN_EDIT_POPUP";

export const defaultState = {
    children: null,
    cancelAction: undefined,
    cancelButtonLabel: undefined,
    confirmAction: undefined,
    confirmButtonLabel: undefined,
    header: undefined,
    opened: false,
    optionalAction: undefined,
    optionalButtonLabel: undefined,
    optionalConfirmAction: undefined,
    optionalConfirmLabel: undefined,
    optionalDeleteAction: undefined,
    optionalDeleteButtonLabel: undefined,
    style: undefined
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLOSE_EDIT_POPUP: {
            return Object.assign({}, defaultState);
        }
        case OPEN_EDIT_POPUP: {
            return Object.assign({}, state, {
                cancelAction: action.payload.cancelAction,
                cancelButtonLabel: action.payload.cancelButtonLabel,
                children: action.payload.children,
                confirmAction: action.payload.confirmAction,
                confirmButtonLabel: action.payload.confirmButtonLabel,
                header: action.payload.header,
                opened: true,
                optionalAction: action.payload.optionalAction,
                optionalButtonLabel: action.payload.optionalButtonLabel,
                optionalConfirmAction: action.payload.optionalConfirmAction,
                optionalConfirmLabel: action.payload.optionalConfirmLabel,
                optionalDeleteAction: action.payload.optionalDeleteAction,
                optionalDeleteButtonLabel:
                    action.payload.optionalDeleteButtonLabel,
                style: action.payload.style
            });
        }
        default:
            return state;
    }
};

export const closeEditPopup = () => {
    return {
        type: CLOSE_EDIT_POPUP
    };
};

export const openEditPopup = ({
    cancelAction = defaultState.cancelAction,
    cancelButtonLabel = defaultState.cancelButtonLabel,
    children = defaultState.children,
    confirmAction = defaultState.confirmAction,
    confirmButtonLabel = defaultState.confirmButtonLabel,
    header = defaultState.header,
    optionalAction = defaultState.optionalAction,
    optionalButtonLabel = defaultState.optionalButtonLabel,
    optionalConfirmAction = defaultState.optionalConfirmAction,
    optionalConfirmLabel = defaultState.optionalConfirmLabel,
    optionalDeleteAction = defaultState.optionalDeleteAction,
    optionalDeleteButtonLabel = defaultState.optionalDeleteButtonLabel,
    style = defaultState.style
}) => {
    return {
        type: OPEN_EDIT_POPUP,
        payload: {
            cancelAction,
            cancelButtonLabel,
            children,
            confirmAction,
            confirmButtonLabel,
            header,
            optionalAction,
            optionalButtonLabel,
            optionalConfirmAction,
            optionalConfirmLabel,
            optionalDeleteAction,
            optionalDeleteButtonLabel,
            style
        }
    };
};
