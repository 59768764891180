// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./MenuCard.css";

class MenuCard extends PureComponent {
    static propTypes = {
        menu: PropTypes.shape({
            icon: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        }).isRequired,
        onClickAction: PropTypes.func.isRequired
    };

    state = {
        cardOnHover: undefined
    };

    handleCardHover = (cardOnHover = undefined) => {
        this.setState({ cardOnHover });
    };

    handleCardOnClick = menu => {
        const { onClickAction } = this.props;

        onClickAction && onClickAction(menu);

        this.handleCardHover();
    };

    render() {
        const { menu } = this.props;

        if (!menu) {
            return null;
        }

        const { cardOnHover } = this.state;
        const key = menu.key;
        const isOnHover = cardOnHover === key;

        return (
            <div
                key={key}
                onClick={() => {
                    this.handleCardOnClick(menu);
                }}
                onMouseEnter={() => this.handleCardHover(key)}
                onMouseLeave={() => this.handleCardHover()}
                style={{
                    ...styles.default,
                    ...(isOnHover ? styles.hover : {})
                }}
            >
                <div
                    style={{
                        ...styles.iconContainer.default,
                        ...(isOnHover ? styles.iconContainer.hover : {})
                    }}
                >
                    <span className={menu.icon} style={styles.icon} />
                </div>
                <div style={styles.label}>{get(menu, "title", "")}</div>
            </div>
        );
    }
}

export default MenuCard;
