// Modules
import axios from "axios";
import get from "lodash.get";
import jwt from "jsonwebtoken";

async function performRequest(action) {
    const { method, path, data, params } = action;

    const token = generateToken();

    const options = {
        mode: "cors",
        headers: {
            Authorization: token,
            "Content-Type":
                get(action, "headers.contentType", undefined) ||
                "application/json"
        }
    };

    return await axios({
        method,
        baseURL: api.url(),
        url: path,
        headers: options.headers,
        params: params ? params : {},
        data: data
    });
}

function generateToken() {
    const secret = process.env.REACT_APP_API_AUTH_TOKEN_SECRET;
    const token = jwt.sign(
        { app: process.env.REACT_APP_FIREBASE_PROJECT_ID },
        secret,
        { expiresIn: 60, algorithm: "HS256" }
    );
    const authHeader = `Bearer ${token}`;
    return authHeader;
}

const api = {
    delete: path => {
        return performRequest({ method: "delete", path });
    },
    get: (path, params) => {
        return performRequest({ method: "get", path, params });
    },
    post: (path, data) => {
        return performRequest({ method: "post", path, data });
    },
    postFile: (path, data) => {
        return performRequest({
            method: "post",
            path,
            data,
            contentType: "multipart/form-data"
        });
    },
    put: (path, data) => {
        return performRequest({ method: "put", path, data });
    },
    url: () => {
        return process.env.REACT_APP_API_URL;
    }
};

export default api;
