// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import merge from "lodash.merge";
// Components
import { MaterialIconButton } from "..";
// Styles
import styles from "./IconButton.css";

class IconButton extends PureComponent {
    static propTypes = {
        children: PropTypes.node
    };

    render() {
        const { children, ...props } = this.props;

        const onHoverStyle = merge(
            JSON.parse(JSON.stringify(get(styles, "container.onHover", {}))),
            JSON.parse(JSON.stringify(get(props, "style.onHover", {})))
        );

        const style = merge(
            JSON.parse(JSON.stringify(styles.container)),
            JSON.parse(JSON.stringify(get(props, "style", {}))),
            JSON.parse(JSON.stringify({ onHover: onHoverStyle }))
        );

        return (
            <MaterialIconButton {...props} style={style}>
                {children}
            </MaterialIconButton>
        );
    }
}

export default IconButton;
