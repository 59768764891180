// Modules
import get from "lodash.get";
// Translation
import translation from "./../../config/translation";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../components/MaterialSpinner/MaterialSpinner.ducks";
import { clearProfileData } from "../ProfileData.ducks";
import { navigateTo } from "../Router/Router.ducks";

export const GET_STARTED_ERROR_ADD = "GET_STARTED_ERROR_ADD";
export const GET_STARTED_ERROR_CLEAR = "GET_STARTED_ERROR_CLEAR";

export const defaultState = {
    errorMessage: undefined,
    errorType: undefined
};

// Reducer
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_STARTED_ERROR_ADD: {
            return Object.assign({}, state, {
                errorMessage: action.payload.message,
                errorType: action.payload.type
            });
        }
        case GET_STARTED_ERROR_CLEAR: {
            return Object.assign({}, defaultState);
        }
        default:
            return state;
    }
};

export const addError = (type, message) => {
    return {
        type: GET_STARTED_ERROR_ADD,
        payload: { type, message }
    };
};

export const clearError = () => {
    return {
        type: GET_STARTED_ERROR_CLEAR
    };
};

export const getStartedLogin = (username, password, successCb, errorCb) => {
    return async (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();

        dispatch(startSpinner());
        try {
            await firebase.login({
                email: username,
                password: password
            });

            dispatch(clearError());
            successCb && successCb();
            dispatch(endSpinner());
        } catch (error) {
            let errorMessage;
            if (error.code) {
                errorMessage = translation.errors[error.code];
            } else {
                errorMessage = translation.errors.default;
            }

            switch (error.code) {
                case "auth/wrong-password": {
                    errorCb && errorCb("password", errorMessage);
                    break;
                }
                case "auth/invalid-email": {
                    errorCb && errorCb("email", errorMessage);
                    break;
                }
                case "auth/user-not-found": {
                    dispatch(addError("warn", errorMessage));
                    errorCb && errorCb("user", errorMessage);
                    break;
                }
                case "auth/user-disabled": {
                    dispatch(addError("error", errorMessage));
                    errorCb && errorCb("user", errorMessage);
                    break;
                }
                default:
                    break;
            }

            dispatch(endSpinner());
        }
    };
};

export const updatePassword = ({
    newPassword,
    legalConsent = undefined,
    successCb = undefined,
    errorCb = undefined
}) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();

        const user = firebaseAuth.currentUser;

        let profileData = {
            isNew: false
        };

        if (legalConsent) {
            profileData.legalConsentTimestamp = new Date();
        }

        await user
            .updatePassword(newPassword)
            .then(async function () {
                await firebase.updateProfile(profileData, { merge: true });

                firebase.reloadAuth();

                dispatch(endSpinner());
                successCb && successCb();
            })
            .catch(function (error) {
                dispatch(endSpinner());

                let errorMessage = translation.errors.default;
                if (get(error, "code", error) === "auth/weak-password") {
                    errorMessage =
                        translation.settings.errorMessage.weakNewPassword;
                } else if (
                    get(error, "code", error) === "auth/requires-recent-login"
                ) {
                    firebase.logout();
                    dispatch(clearProfileData());
                }

                errorCb && errorCb(errorMessage);
            });
    };
};

export const updateLegalAgreement = ({
    legalConsent = undefined,
    successCb = undefined,
    errorCb = undefined
}) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();

        const user = firebaseAuth.currentUser;

        let profileData = {};

        if (legalConsent) {
            const timestamp = new Date();
            profileData = {
                legalAgreement: {
                    accepted: true,
                    timestamp: timestamp
                }
            };
        }

        if (user) {
            await user
                .updateProfile(user)
                .then(async function () {
                    await firebase.updateProfile(profileData, { merge: true });

                    firebase.reloadAuth();

                    dispatch(endSpinner());
                    successCb && successCb();
                })
                .catch(function () {
                    dispatch(endSpinner());

                    let errorMessage = translation.errors.default;

                    errorCb && errorCb(errorMessage);
                });
        }
    };
};

export const backToLogin = () => {
    return async (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();

        dispatch(startSpinner());
        try {
            await firebase.logout();
            dispatch(navigateTo("/login"));
            dispatch(clearProfileData());
            dispatch(endSpinner());
        } catch (error) {
            dispatch(endSpinner());
        }
    };
};
