// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
// Components
import {
    CustomSelect,
    CustomTooltip,
    IconButton,
    TableRow
} from "../../../../../components";
// Helpers
import { hasPermission } from "../../helpers/entitiesMappingPermissions";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./CoopPartnersMappingRow.css";

class CustomEditIconButton extends PureComponent {
    static propTypes = {
        handleTooltipOnHover: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    render() {
        const { handleTooltipOnHover, openEditPopup } = this.props;

        return (
            <IconButton
                defaultClassName="icon-edit"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover &&
                        handleTooltipOnHover(undefined, false);
                    openEditPopup && openEditPopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    }
}

class CoopPartnersMappingRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        index: PropTypes.number,
        entry: PropTypes.object.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        profileData: PropTypes.object
    };

    oneLine = 40;

    state = {
        isRowExpandend: false,
        legalEntityHeight: undefined,
        suppliersHeight: undefined,
        tooltipOpened: undefined
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }

    componentDidUpdate(prevProps) {
        const { entry } = this.props;
        if (!isEqual(prevProps.entry, entry)) {
            this.updateLegalEntityHeightRef();
            this.updateSuppliersHeightRef();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    getLegalEntities = row => {
        const legalEntities = get(row, "legal_entities", []).map(
            legal_entity => {
                const legalEntityName = get(legal_entity, "name", undefined);
                const legalEntityId = get(legal_entity, "_id", undefined);
                return {
                    label: legalEntityName,
                    value: legalEntityId,
                    option: { name: legalEntityName, id: legalEntityId }
                };
            }
        );

        return (
            legalEntities &&
            legalEntities.sort((a, b) => a["label"].localeCompare(b["label"]))
        );
    };

    handleExpandCollapseRow = () => {
        const { isRowExpandend } = this.state;

        this.setState({
            isRowExpandend: !isRowExpandend
        });
    };

    handleTooltipOnHover = (tooltip, onHover) => {
        this.setState({ tooltipOpened: onHover ? tooltip : undefined });
    };

    renderActionButtons = () => {
        const { openEditPopup, profileData } = this.props;
        const { tooltipOpened } = this.state;

        const userRole = get(profileData, "role", {});

        const tooltip = "editTooltip";

        return (
            <div
                className="legal-entities-mapping-row-actions"
                style={styles.iconsContainer}
            >
                {hasPermission(userRole, "update") && (
                    <CustomTooltip
                        onClose={() =>
                            this.handleTooltipOnHover(tooltip, false)
                        }
                        onOpen={() => this.handleTooltipOnHover(tooltip, true)}
                        open={tooltipOpened === tooltip}
                        placement="top"
                        title={translation.labels.edit}
                    >
                        <span>
                            <CustomEditIconButton
                                handleTooltipOnHover={this.handleTooltipOnHover}
                                openEditPopup={openEditPopup}
                            />
                        </span>
                    </CustomTooltip>
                )}
                {this.renderExpandCollapseButton()}
            </div>
        );
    };

    renderExpandCollapseButton = () => {
        const { isRowExpandend, legalEntityHeight, suppliersHeight } =
            this.state;

        const displayExpandButton =
            legalEntityHeight > this.oneLine || suppliersHeight > this.oneLine;

        if (!displayExpandButton) {
            return null;
        }

        const tooltip = "expandTooltip";

        return (
            <CustomTooltip
                placement="top"
                title={
                    isRowExpandend
                        ? translation.labels.close
                        : translation.labels.open
                }
            >
                <div
                    style={{
                        ...styles.expandCollapseButton,
                        ...(isRowExpandend ? styles.expandButton : {})
                    }}
                >
                    <IconButton
                        defaultClassName={"icon-arrow-down"}
                        onClick={event => {
                            event.target && event.target.blur();
                            this.handleTooltipOnHover(tooltip, false);
                            this.handleExpandCollapseRow();
                        }}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
            </CustomTooltip>
        );
    };

    renderRow = row => {
        const { index } = this.props;
        const { isRowExpandend, legalEntityHeight } = this.state;
        let legalEntities = this.getLegalEntities(row);

        const selectInnerContainerStyle = height => {
            return {
                ...(height > this.oneLine
                    ? styles.displayShrinkedContainer
                    : {}),
                ...(isRowExpandend ? styles.increaseRowContainer : {})
            };
        };

        const selectControlStyle = height => {
            return {
                ...styles.selectControl,
                ...(height > this.oneLine
                    ? styles.displayShrinkedControlContainer
                    : {})
            };
        };

        return (
            <Fragment>
                <div
                    ref={ref => {
                        if (!this.suppliersRef) {
                            this.suppliersRef = ref;
                            this.updateSuppliersHeightRef();
                        }
                    }}
                    style={{
                        ...styles.suppliersContainer,
                        ...(isRowExpandend ? styles.increaseRowContainer : {})
                    }}
                >
                    {get(row, "name", undefined)}
                </div>
                <div
                    ref={ref => {
                        if (!this.legalEntityRef) {
                            this.legalEntityRef = ref;
                            this.updateLegalEntityHeightRef();
                        }
                    }}
                    style={{
                        ...styles.selectsContainer,
                        ...(isRowExpandend ? styles.increaseRowContainer : {})
                    }}
                >
                    {legalEntities && legalEntities.length > 0 ? (
                        <CustomSelect
                            containerStyle={selectInnerContainerStyle(
                                legalEntityHeight
                            )}
                            controlStyle={selectControlStyle(legalEntityHeight)}
                            disabled
                            disabledStyle={styles.selectControlDisabled}
                            dropdownIndicatorContainerStyle={
                                styles.dropdownIndicatorContainer
                            }
                            isMulti
                            keyLabel={`legalEntity-${row._id}-${index}`}
                            multiValueLabelStyle={styles.selectMultiValueLabel}
                            multiValueStyle={styles.selectMultiValue}
                            value={legalEntities}
                        />
                    ) : (
                        <div />
                    )}
                </div>

                {this.renderActionButtons()}
            </Fragment>
        );
    };

    resize = () => {
        this.updateLegalEntityHeightRef();
        this.updateSuppliersHeightRef();
    };

    updateSuppliersHeightRef = () => {
        const { suppliersHeight } = this.state;
        const newSuppliersHeight = get(
            this.suppliersRef,
            "scrollHeight",
            undefined
        );

        this.legalEntityRef &&
            suppliersHeight !== newSuppliersHeight &&
            this.setState({
                suppliersHeight: newSuppliersHeight
            });
    };

    updateLegalEntityHeightRef = () => {
        const { legalEntityHeight } = this.state;
        const newLegalEntityHeight = get(
            this.legalEntityRef,
            "firstChild.firstChild.scrollHeight",
            undefined
        );
        this.legalEntityRef &&
            legalEntityHeight !== newLegalEntityHeight &&
            this.setState({
                legalEntityHeight: newLegalEntityHeight
            });
    };

    render() {
        const { containerStyle, entry } = this.props;

        return (
            <TableRow
                containerStyle={{
                    ...containerStyle,
                    ...styles.rowContainer
                }}
            >
                {this.renderRow(entry)}
            </TableRow>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profileData
    };
};

export default connect(mapStateToProps)(CoopPartnersMappingRow);
