import Global from "../../config/global.css";

const styles = {
    arrowIcon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px"
    },
    clearIcon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "16px"
    },
    clearIconContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        marginLeft: "16px",
        width: "24px"
    },
    control: {
        padding: "15px 10px",
        "&:focus-within": {
            borderColor: Global.colors.darkSlateBlue
        }
    },
    disabled: {
        color: Global.colors.darkPaleGrey
    },
    disabledContainer: {
        border: `1px solid ${Global.colors.darkPaleGrey}`
    },
    iconContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        marginLeft: "8px",
        width: "24px"
    },
    label: {
        background: Global.colors.white,
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginBottom: "-17px",
        transform: "translate(12px, 16px)",
        position: "sticky",
        width: "fit-content",
        zIndex: "unset"
    },
    labelShrink: {
        padding: "0px 2px",
        position: "sticky",
        transform: "translate(12px, -6px) scale(0.72)",
        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        zIndex: 1
    },
    menu: {
        zIndex: 2
    },
    multiValueRemoveIcon: {
        icon: {
            color: "inherit",
            fontSize: "16px"
        }
    },
    optionFocused: {
        backgroundColor: Global.colors.paleGrey
    },
    singleValue: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        margin: 0
    },
    valueContainer: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "inherit",
        lineHeight: "18px"
    }
};

export default styles;
