import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "12px",
        gridTemplateColumns: "minmax(502px, 50%)",
        gridTemplateRows: "62px min-content",
        padding: "32px 24px"
    },
    selectControl: {
        padding: "7px 8px"
    },
    selectMenu: {
        maxHeight: "240px"
    },
    selectMenuContainer: {
        position: "relative"
    },
    selectMultiValue: {
        backgroundColor: "transparent",
        border: `1px solid ${Global.colors.darkSlateBlue}`,
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        margin: "4px"
    },
    selectMultiValueLabel: {
        color: Global.colors.darkSlateBlue,
        padding: "4.5px 8px"
    },
    selectMultiValueRemove: {
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        margin: "unset"
    },
    selectMultiValueRemoveIcon: {
        container: {
            marginBottom: "-1px",
            marginRight: "-1px",
            marginTop: "-1px"
        }
    },
    textField: {
        width: "100%",

        disabled: {
            WebkitTextFillColor: Global.colors.darkPaleGrey
        },
        input: {
            padding: "15px 12px !important"
        }
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
