import Global from "../../../../config/global.css";

const styles = {
    container: {
        alignItems: "center",
        borderRadius: "4px",
        display: "flex",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        height: "24px",
        justifyContent: "center",
        lineHeight: "13px",
        width: "105px"
    },
    status: {
        signed: {
            backgroundColor: Global.colors.lightGreen,
            border: `1px solid ${Global.colors.oliveGreen}`,
            color: Global.colors.oliveGreen
        },
        notSigned: {
            backgroundColor: Global.colors.lightLeo,
            border: `1px solid ${Global.colors.leo}`,
            color: Global.colors.leo
        }
    }
};

export default styles;
