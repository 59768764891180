/**
 * Checks if a given value (case-insensitive) exists in an array of existing values.
 * @param {string} value - The value to check.
 * @param {string[]} existingValues - An array of existing values to compare against.
 * @returns {boolean} - Returns true if the value exists (case-insensitive), otherwise false.
 */
export function doesValueExist(value, existingValues) {
    const newValue = value.keyword.toLowerCase();
    if (existingValues) {
        for (const exValue of existingValues) {
            if (exValue.keyword.toLowerCase() === newValue) {
                return true;
            }
        }
    }

    return false;
}
