// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import debounce from "lodash/debounce";
// Components
import { Filter, SearchBar, TableHeaders } from "../../../components";
import { EntityUploadTrackingRow } from "./components";
// Actions
import { getEntitiesUploads } from "./EntitiesUploadsTracking.ducks";
// Helpers
import { getUserRoleEntityId } from "./helpers/entitiesUploadsPermissions";
import { FILTERS, getTrackings } from "./helpers/filterUploads";
import { getFilteredLegalEntitiesBySearchQuery } from "../helpers/legalEntitiesSearch";
// Translations
import translation from "../../../config/translation";
// Styles
import styles from "./EntitiesUploadsTracking.css";

class EntitiesUploadsTracking extends PureComponent {
    static propTypes = {
        entitiesUploads: PropTypes.array,
        getEntitiesUploads: PropTypes.func.isRequired,
        profileData: PropTypes.object
    };

    state = {
        filter: "nameAsc",
        searchQuery: undefined,
        uploads: []
    };

    tableHeaders = [
        { label: translation.entitiesUploads.tableHeaders.legalEntity },
        { label: translation.entitiesUploads.tableHeaders.suppliers },
        {
            label: translation.entitiesUploads.tableHeaders.lastUpload,
            style: styles.lastUpload
        },
        { label: translation.entitiesUploads.tableHeaders.status }
    ];

    componentDidMount() {
        this.getEntitiesUploadsTracking();
    }

    componentDidUpdate(prevProps) {
        const { entitiesUploads } = this.props;
        const { searchQuery } = this.state;

        const oldValues =
            (prevProps.entitiesUploads &&
                JSON.stringify(prevProps.entitiesUploads)) ||
            [];
        const nextValues = JSON.stringify(entitiesUploads);

        if (oldValues !== nextValues) {
            const filteredUploads = getFilteredLegalEntitiesBySearchQuery(
                searchQuery,
                entitiesUploads
            );
            this.setState({ uploads: filteredUploads });
        }
    }

    getEntitiesUploadsTracking = () => {
        const { getEntitiesUploads, profileData } = this.props;

        const entityId = getUserRoleEntityId(profileData);
        if (entityId === -1) {
            return;
        }

        getEntitiesUploads && getEntitiesUploads(entityId);
    };

    applyFilter = filterSelected => {
        const filter = get(filterSelected, "key", "nameAsc");

        this.setState({ filter }, () => {
            this.getEntitiesUploadsTracking();
        });
    };

    handleSearch = value => {
        const { entitiesUploads } = this.props;
        const { searchQuery } = this.state;

        if (value !== searchQuery) {
            const filteredUploads = getFilteredLegalEntitiesBySearchQuery(
                value,
                entitiesUploads
            );

            this.setState({
                searchQuery: value || "",
                uploads: filteredUploads
            });
        }
    };

    debounceSearchOnChange = debounce(this.handleSearch, 750);

    render() {
        const { filter, uploads } = this.state;

        const trackingsByLegalEntities = getTrackings({ uploads, filter });

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.entitiesUploads}
                </div>
                <div style={styles.topbarContainer}>
                    <SearchBar
                        icon="icon-search"
                        onChange={this.debounceSearchOnChange}
                        placeholder={translation.labels.search}
                        style={{
                            container: styles.searchBarContainer,
                            input: styles.searchBarInput
                        }}
                    />
                    <Filter
                        defaultOption={FILTERS[0]}
                        onSelection={this.applyFilter}
                        options={FILTERS}
                    />
                </div>
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...styles.tableHeaders
                    }}
                    headers={this.tableHeaders}
                />
                <div style={styles.rowsContainer}>
                    {trackingsByLegalEntities &&
                        trackingsByLegalEntities.map((entity, index) => {
                            return (
                                <EntityUploadTrackingRow
                                    containerStyle={styles.tableGrid}
                                    entry={entity}
                                    index={index}
                                    key={index}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        entitiesUploads: state.entitiesUploads.listByLegalEntity,
        profileData: state.profileData
    };
};

const mapDispatchToProps = {
    getEntitiesUploads
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntitiesUploadsTracking);
