// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { Flyers, Images, Podcasts, Presentations, Videos } from "../";
// Styles
import styles from "./RightContainer.css";

class RightContainer extends PureComponent {
    static propTypes = {
        details: PropTypes.object.isRequired
    };

    renderVideos = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const videos = medias.filter(media =>
            ["video_link", "video_file"].includes(media.type)
        );

        return <Videos videos={videos} />;
    };

    renderPresentations = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const presentations = medias.filter(
            media => media.type === "presentation"
        );

        return <Presentations presentations={presentations} />;
    };

    renderPodcasts = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const podcasts = medias.filter(media =>
            ["audio", "podcast"].includes(media.type)
        );

        return <Podcasts podcasts={podcasts} />;
    };

    renderChatbots = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const chatbots = medias.filter(media => media.type === "chatbot");

        return <Images images={chatbots} type="chatbots" />;
    };

    renderFlyers = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const flyers = medias.filter(media => media.type === "flyer");

        return <Flyers flyers={flyers} />;
    };

    hasMedias = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const rightContainerMedias = medias.filter(
            media => !["image", "link", "additional_link"].includes(media.type)
        );

        return rightContainerMedias && rightContainerMedias.length > 0;
    };

    render() {
        const { details } = this.props;

        if (!details || !this.hasMedias()) {
            return null;
        }

        return (
            <div style={styles.container}>
                {this.renderFlyers()}
                {this.renderVideos()}
                {this.renderPresentations()}
                {this.renderPodcasts()}
                {this.renderChatbots()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details
    };
};

export default connect(mapStateToProps)(RightContainer);
