import Global from "../../../../../config/global.css";

const styles = {
    editPopup: {
        body: { height: "calc(100vh - 306px)" },
        container: {
            gridTemplateRows: "72px auto",
            height: "unset",
            top: "162px"
        }
    },
    infineScroll: {
        padding: "4px 0px 24px",
        position: "relative"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row"
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "minmax(150px, 35%)  minmax(300px, 55%)  minmax(100px, 15%)",
        padding: "12px 24px"
    },
    tableHeaders: {
        marginTop: "16px"
    },
    tableGridKeywords: {
        gridTemplateColumns: "minmax(200px, 25%) minmax(200px, 75%) 32px"
    },

    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
