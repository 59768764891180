// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import {
    FabButtonWithTooltip,
    IconButton
} from "../../../../../../../../../components";
import { EventDetailsContent } from "./components";
// Actions
import { navigateTo } from "../../../../../../../Router.ducks";
import {
    clearOfferDetails,
    deleteEvent,
    getOfferDetails,
    setOfferDetailsTab
} from "../../../../OfferDetails.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../../../components/Popup/Popup.ducks";
import { getOriginalOfferDetails, unmergeOffer } from "./EventDetails.ducks";
// Helpers
import { routesPaths } from "../../../../../../../components/Routes/Routes.helper";
import { formatDate } from "./helpers/detailsHelper";
import { getSeminarsSortedByStartDate } from "../../helpers/eventsHelper";
import {
    displayWarningPopup,
    handleErrorPopupDisplay,
    openEventDetailsFormPopup
} from "../../helpers/eventDetailsFormHelper";
import {
    getUserRoleEntityId,
    hasPermission
} from "../../../../../../helpers/offerPermissions";
import { openUnmergeOfferReviewPopup } from "./helpers/unmerge";
// Translation
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./EventDetails.css";

class EventDetails extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        clearOfferDetails: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        deleteEvent: PropTypes.func.isRequired,
        details: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        editPopup: PropTypes.object,
        eventDetailsForm: PropTypes.object,
        getOfferDetails: PropTypes.func.isRequired,
        getOriginalOfferDetails: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        navigateTo: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object,
        searchFilter: PropTypes.object,
        setOfferDetailsTab: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired,
        unmergeOffer: PropTypes.func.isRequired
    };

    state = {
        tooltipOpened: undefined
    };

    componentDidMount() {
        const {
            clearOfferDetails,
            details,
            getOfferDetails,
            match,
            setOfferDetailsTab
        } = this.props;
        const offerUrl = get(match, "params.offerUrl", undefined);

        details &&
            details.url !== offerUrl &&
            clearOfferDetails &&
            clearOfferDetails();

        if ((!details || details.url !== offerUrl) && offerUrl) {
            const entityId = this.getEntityIdUserAndSearchRelated();
            if (entityId === -1) {
                return;
            }

            getOfferDetails && getOfferDetails(offerUrl, entityId);
            setOfferDetailsTab && setOfferDetailsTab("events");
        }

        this.refreshOfferDetails(false);

        const elementClassName = "dashboard-children-container";
        const element = document.getElementsByClassName(elementClassName)[0];
        element && element.scrollTo(0, 0);
    }

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    getEntityIdUserAndSearchRelated = () => {
        const { profileData, searchFilter } = this.props;

        const entityId = getUserRoleEntityId(profileData);

        return entityId || get(searchFilter, "legalEntity.value", undefined);
    };

    getEventDetails = () => {
        const { details, match } = this.props;

        const eventIndex = get(match, "params.eventIndex", undefined);

        const seminars = getSeminarsSortedByStartDate(details);

        return (
            eventIndex &&
            seminars &&
            seminars.length >= eventIndex &&
            seminars[eventIndex]
        );
    };

    handleClickOnBackIcon = () => {
        const { details, navigateTo } = this.props;

        let path = routesPaths.offerDetails;
        path = path.replace(":offerUrl", details.url);

        navigateTo && navigateTo(path);
    };

    openEditEventPopup = () => {
        const {
            closeEditPopup,
            closePopup,
            openPopup,
            dispatch,
            match,
            openEditPopup
        } = this.props;

        openEventDetailsFormPopup({
            closeEditPopup,
            getEventDetailsForm: () => this.props.eventDetailsForm,
            closePopup,
            openPopup,
            dispatch,
            openEditPopup,
            editMode: true,
            match
        });
    };

    openUnmergePopup = originalOfferId => {
        const { closePopup, getOriginalOfferDetails, openPopup, unmergeOffer } =
            this.props;

        const errorCb = () => {
            handleErrorPopupDisplay({
                errorType: "error",
                errorMessage: {
                    title: translation.event.genericError.title,
                    subtitle: translation.event.genericError.subtitle
                },
                closePopup,
                openPopup
            });
        };

        getOriginalOfferDetails &&
            getOriginalOfferDetails({
                offerId: originalOfferId,
                successCb: offerData => {
                    let events = get(offerData, "seminars", []);
                    events =
                        events.length > 1
                            ? getSeminarsSortedByStartDate(offerData)
                            : events;

                    openUnmergeOfferReviewPopup({
                        openPopup,
                        originalOfferId,
                        successCb: this.refreshOfferDetails,
                        errorCb,
                        closePopup,
                        events,
                        unmergeOffer
                    });
                },
                errorCb
            });
    };

    handleDeleteEvent = eventId => {
        const { closePopup, deleteEvent, details, openPopup } = this.props;

        const offerUrl = get(details, "url", undefined);

        openPopup({
            children: displayWarningPopup(
                () => {
                    deleteEvent &&
                        deleteEvent(offerUrl, eventId, () => {
                            this.refreshOfferDetails();
                        });
                },
                translation.event.delete,
                closePopup,
                "error",
                translation.event.deleteEventMessage,
                translation.event.deleteEventTitle
            )
        });
    };

    refreshOfferDetails = (navigate = true) => {
        const {
            clearOfferDetails,
            closePopup,
            details,
            getOfferDetails,
            navigateTo,
            setOfferDetailsTab
        } = this.props;

        const offerUrl = get(details, "url", undefined);

        clearOfferDetails && clearOfferDetails();
        setOfferDetailsTab && setOfferDetailsTab("events");

        const entityId = this.getEntityIdUserAndSearchRelated();
        getOfferDetails && getOfferDetails(offerUrl, entityId);

        closePopup && closePopup();

        navigate &&
            navigateTo &&
            navigateTo(routesPaths.offerDetails.replace(":offerUrl", offerUrl));
    };

    handleTooltipOnHover = (tooltip, onHover) => {
        this.setState({ tooltipOpened: onHover ? tooltip : undefined });
    };

    render() {
        const { classes, details, profileData, tab, navigateTo } = this.props;
        const { tooltipOpened } = this.state;

        if (!details) {
            return null;
        }

        const offerTitle = get(details, "title", "");
        const eventDetails = this.getEventDetails();

        if (!eventDetails) {
            return null;
        }

        const userRole = get(profileData, "role", {});

        const eventId = get(eventDetails, "_id", undefined);
        const eventStartDate = formatDate(
            get(eventDetails, "start_date", undefined)
        );
        const originalOfferId = get(
            eventDetails,
            "original_seminar_details_id",
            undefined
        );

        const hasUnmerge = !!originalOfferId;

        const tooltipUnmerge = "unmerge";
        const tooltipEdit = "edit";
        const tooltipDelete = "delete";

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.offersOverview}
                </div>
                <div style={styles.header.container}>
                    <div style={styles.header.innerContainer}>
                        <div style={styles.header.title}>
                            <IconButton
                                defaultClassName="icon-arrow-back"
                                onClick={this.handleClickOnBackIcon}
                                style={styles.header.backIcon}
                                type={["default"]}
                            />
                            <span>{eventStartDate}</span>
                        </div>
                        <span
                            style={styles.header.breadcrums}
                        >{`${translation.navigationMenu.offerManagement} / ${offerTitle} / ${translation.offerDetails.tabs[tab]} / ${eventStartDate}`}</span>
                    </div>

                    <div style={styles.header.iconsContainer}>
                        <Fragment>
                            {hasPermission(userRole, "update") && (
                                <Fragment>
                                    {hasUnmerge && (
                                        <FabButtonWithTooltip
                                            action={() =>
                                                this.openUnmergePopup(
                                                    originalOfferId
                                                )
                                            }
                                            classes={classes}
                                            iconClassName="icon-unmerge"
                                            handleTooltipOnHover={
                                                this.handleTooltipOnHover
                                            }
                                            tooltipLabel={
                                                translation.event.unmerge.unlink
                                            }
                                            tooltipName={tooltipUnmerge}
                                            tooltipOpened={tooltipOpened}
                                        />
                                    )}
                                    <FabButtonWithTooltip
                                        action={this.openEditEventPopup}
                                        classes={classes}
                                        iconClassName="icon-edit"
                                        handleTooltipOnHover={
                                            this.handleTooltipOnHover
                                        }
                                        tooltipLabel={translation.labels.edit}
                                        tooltipName={tooltipEdit}
                                        tooltipOpened={tooltipOpened}
                                    />
                                </Fragment>
                            )}
                            {hasPermission(userRole, "delete") && (
                                <FabButtonWithTooltip
                                    action={() =>
                                        this.handleDeleteEvent(eventId)
                                    }
                                    classes={classes}
                                    iconClassName="icon-delete"
                                    handleTooltipOnHover={
                                        this.handleTooltipOnHover
                                    }
                                    tooltipLabel={translation.labels.delete}
                                    tooltipName={tooltipDelete}
                                    tooltipOpened={tooltipOpened}
                                />
                            )}
                        </Fragment>
                    </div>
                </div>
                <EventDetailsContent
                    eventDetails={eventDetails}
                    navigateTo={navigateTo}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details,
        editPopup: state.editPopup,
        eventDetailsForm: get(state, "form.eventDetails", undefined),
        popup: state.popup,
        profileData: state.profileData,
        searchFilter: state.offers.searchFilter,
        tab: state.offerDetails.tab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                clearOfferDetails,
                closeEditPopup,
                closePopup,
                deleteEvent,
                getOfferDetails,
                getOriginalOfferDetails,
                navigateTo,
                openEditPopup,
                openPopup,
                setOfferDetailsTab,
                unmergeOffer
            },
            dispatch
        )
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(EventDetails);
