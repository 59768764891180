import Global from "../../../../../config/global.css";

const styles = {
    editPopup: {
        body: { height: "calc(100vh - 306px)" },
        container: {
            gridTemplateRows: "72px auto",
            height: "unset",
            top: "162px"
        }
    },
    infineScroll: {
        padding: "4px 0px 24px",
        position: "relative"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row",
        height: "calc(100vh - 350px)",
        marginRight: "4px",
        padding: "0px 20px 0px 24px",
        overflow: "auto"
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "minmax(200px, 35%) minmax(92px, 10%) minmax(200px, 55%) 50px 32px",
        padding: "0px 24px"
    },
    tableGridKeywords: {
        gridTemplateColumns: "minmax(200px, 25%) minmax(200px, 75%) 32px"
    },
    tableHeaders: {
        height: "44px",
        padding: "0px 48px"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
