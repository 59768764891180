// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// Form
import { Field } from "redux-form";
// Components
import {
    MaterialTextField,
    Select
} from "../../../../../../../../../../../components/";
// Validators
import {
    link,
    price
} from "../../../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Helpers
import { eventTypes } from "../../../../helpers/formMappingsHelper";
// Styles
import styles from "./BasicInfo.css";

class BasicInfo extends PureComponent {
    static propTypes = {
        classes: PropTypes.object
    };

    render() {
        const { classes } = this.props;

        return (
            <div style={styles.container}>
                <Field
                    component={MaterialTextField}
                    disabled
                    label={translation.event.associatedSeminar}
                    name="offer.title"
                    style={styles.textField}
                    variant="outlined"
                />
                <Field
                    InputProps={{
                        classes: { input: classes.price }
                    }}
                    component={MaterialTextField}
                    endAdornment={<span style={styles.priceSymbol}>{"€"}</span>}
                    label={translation.event.price}
                    name="price"
                    style={styles.textField}
                    variant="outlined"
                    validate={price}
                />
                <Field
                    component={MaterialTextField}
                    label={translation.event.externalLink}
                    name="externalLink"
                    style={styles.textField}
                    variant="outlined"
                    validate={link}
                />
                <Field
                    component={Select}
                    isClearable
                    isSearchable
                    label={translation.event.eventType}
                    name="type"
                    placeholder={""}
                    options={eventTypes}
                    style={styles.textField}
                />
                <Field
                    component={MaterialTextField}
                    label={translation.event.externalId}
                    name="externalId"
                    style={styles.textField}
                    variant="outlined"
                />
                <Field
                    component={MaterialTextField}
                    label={translation.event.enrolmentLink}
                    name="enrolmentLink"
                    style={styles.textField}
                    variant="outlined"
                    validate={link}
                />
            </div>
        );
    }
}

export default withStyles(styles)(BasicInfo);
