import Global from "../../../../config/global.css";

const styles = {
    backIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px",
        marginRight: "8px",
        width: "max-content"
    },
    buttonSelected: {
        backgroundColor: Global.colors.digitalBlueT4
    },
    container: {
        alignItems: "center",
        backgroundColor: Global.colors.white,
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        display: "grid",
        gridAutoColumns: "auto 1fr",
        gridAutoFlow: "column",
        gap: "32px",
        padding: "0px 24px 0px 18px",
        zIndex: 5
    },
    icon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        height: "48px",
        width: "48px",

        default: {
            borderRadius: "16px !important"
        },
        onHover: {
            backgroundColor: Global.colors.lighterSlateBlue
        }
    },
    iconRippleChild: {
        borderRadius: "16px"
    },
    logo: {
        width: "252px"
    },
    profile: {
        container: {
            margin: "0px 0px 0px auto"
        },
        image: {
            backgroundColor: Global.colors.darkSlateBlue
        }
    },
    profileIcon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px"
    },
    rightContainer: {
        alignItems: "center",
        display: "grid",
        gridAutoFlow: "column",
        gap: "12px",
        justifyContent: "end"
    }
};

export default styles;
