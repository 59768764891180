// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import differenceWith from "lodash.differencewith";
// Components
import {
    CreatableSelect,
    MaterialTextField,
    WarningPopup
} from "../../../../../../../components/";
// Form
import { Field, formValueSelector, reduxForm } from "redux-form";
// Actions
import {
    getKeywords,
    upsertKeywords
} from "../../../../OffersKeywordsTagging.ducks";
import { closeEditPopup } from "../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../components/Popup/Popup.ducks";
// Translations
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./EditOfferKeywordsForm.css";

function onSubmit(values, props) {
    const { closeEditPopup, entry, upsertKeywords } = props;

    const initialKeywords = (get(entry, "keywords", []) || []).map(keyword =>
        get(keyword, "keyword", undefined)
    );

    const keywords = (get(values, "keywords", []) || []).map(
        keyword =>
            get(keyword, "value", undefined) &&
            get(keyword, "value", undefined).trim()
    );

    const keywordsRemoved = differenceWith(initialKeywords, keywords, isEqual);
    const keywordsAdded = differenceWith(keywords, initialKeywords, isEqual);

    if (
        (!keywordsRemoved || keywordsRemoved.length === 0) &&
        (!keywordsAdded || keywordsAdded.length === 0)
    ) {
        closeEditPopup();
        return;
    }

    const offerId = get(entry, "_id", undefined);

    let data = {
        add_offers:
            (keywordsAdded &&
                keywordsAdded.map(keyword => ({
                    offer_id: offerId,
                    keyword
                }))) ||
            [],
        delete_offers:
            (keywordsRemoved &&
                keywordsRemoved.map(keyword => ({
                    offer_id: offerId,
                    keyword
                }))) ||
            []
    };

    upsertKeywords &&
        upsertKeywords({
            data,
            successCb: closeEditPopup,
            errorCb: () => {
                onError(props);
            }
        });
}

function onError(props) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.offersKeywordsTagging.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={styles.warningErrorPopupImage}
                subTitle={translation.offersKeywordsTagging.error.subtitle}
                title={translation.offersKeywordsTagging.error.title}
            />
        )
    });
}

const prepareKeywords = keywords => {
    return !keywords || keywords.length === 0
        ? null
        : keywords.map(keyword => {
              const keywordName = get(keyword, "keyword", undefined);
              return {
                  label: keywordName,
                  value: keywordName
              };
          });
};

class EditOfferKeywordsForm extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        entry: PropTypes.object,
        getKeywords: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        keywordsList: PropTypes.array,
        newKeywords: PropTypes.array,
        upsertKeywords: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { getKeywords } = this.props;

        getKeywords && getKeywords();
    }

    prepareKeywordsList = () => {
        const { keywordsList } = this.props;

        return (
            keywordsList &&
            keywordsList.map(keyword => {
                return {
                    label: keyword,
                    value: keyword
                };
            })
        );
    };

    shouldDisplayDeleteKeywordsNote = () => {
        const { entry, newKeywords } = this.props;

        const initialKeywords = (get(entry, "keywords", []) || []).map(
            keyword => get(keyword, "keyword", undefined)
        );

        const keywords = (newKeywords || []).map(
            keyword =>
                get(keyword, "value", undefined) &&
                get(keyword, "value", undefined).trim()
        );

        const keywordsRemoved = differenceWith(
            initialKeywords,
            keywords,
            isEqual
        );

        return keywordsRemoved && keywordsRemoved.length > 0;
    };

    render() {
        const { entry } = this.props;

        const keywordsOptions = this.prepareKeywordsList();

        const defaultKeywords = prepareKeywords(
            get(entry, "keywords", undefined)
        );

        const displayDeleteKeywordsNote =
            this.shouldDisplayDeleteKeywordsNote();

        return (
            <form style={styles.container}>
                <Field
                    component={MaterialTextField}
                    disabled
                    label={
                        translation.offersKeywordsTagging.tableHeaders
                            .offerTitle
                    }
                    name="offerTitle"
                    style={styles.textField}
                    variant="outlined"
                />
                <Field
                    component={CreatableSelect}
                    controlStyle={styles.selectControl}
                    defaultOption={defaultKeywords}
                    hideSelectedOptions={false}
                    editable
                    isClearable
                    isMulti
                    isSearchable
                    label={
                        translation.offersKeywordsTagging.tableHeaders.keywords
                    }
                    menuListStyle={styles.selectMenu}
                    menuStyle={
                        displayDeleteKeywordsNote
                            ? undefined
                            : styles.selectMenuContainer
                    }
                    multiValueLabelStyle={styles.selectMultiValueLabel}
                    multiValueRemoveStyle={styles.selectMultiValueRemove}
                    multiValueStyle={styles.selectMultiValue}
                    name="keywords"
                    noOptionsMessage={translation.labels.noOptions}
                    options={keywordsOptions}
                    placeholder={""}
                />
                {displayDeleteKeywordsNote ? (
                    <div style={styles.deleteKeywordsNote}>
                        {translation.offersKeywordsTagging.deleteKeywordsNote}
                    </div>
                ) : (
                    <div />
                )}
            </form>
        );
    }
}

const selector = formValueSelector("editOfferKeywords");

const mapStateToProps = (state, props) => {
    const { entry } = props;

    return {
        initialValues: {
            offerTitle: get(entry, "title", undefined),
            keywords: prepareKeywords(get(entry, "keywords", undefined))
        },
        keywordsList: state.offersKeywordsTagging.keywords,
        newKeywords: selector(state, "keywords")
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    closePopup,
    getKeywords,
    openPopup,
    upsertKeywords
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editOfferKeywords",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EditOfferKeywordsForm)
);
