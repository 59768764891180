import Global from "../../../config/global.css";

const styles = {
    container: {
        height: "fit-content",
        padding: "24px 0px 0px"
    },
    tabs: {
        container: {
            background: Global.colors.white,
            borderRadius: "12px",
            height: "64px",
            margin: "0px 24px 24px",
            minHeight: "64px",
            width: "fit-content"
        },
        icon: {
            container: {
                default: {
                    alignItems: "center",
                    backgroundColor: "rgb(255, 197, 93, 0.4)",
                    borderRadius: "100px",
                    display: "flex",
                    height: "32px",
                    justifyContent: "center",
                    margin: "0px 16px 0px 0px",
                    width: "32px"
                },
                selected: {
                    backgroundColor: "inherit"
                }
            },
            icon: {
                default: {
                    color: Global.colors.darkSlateBlue,
                    borderRadius: "100px",
                    fontSize: "24px"
                },
                selected: {
                    color: Global.colors.white
                }
            }
        },
        indicator: {
            visibility: "hidden"
        }
    },
    tabDefault: {
        borderRadius: "12px",
        color: Global.colors.darkSlateBlue,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "bold",
        height: "64px",
        lineHeight: "15px",
        maxHeight: "64px",
        minHeight: "64px",
        opacity: "unset",
        padding: "16px",
        textTransform: "unset",
        width: "204px",

        "&:hover": {
            backgroundColor: Global.colors.digitalBlueT4,
            color: Global.colors.darkSlateBlue,

            "&:not(.Mui-selected) .tab-icon": {
                backgroundColor: `${Global.colors.digitalBlueT4} !important`
            }
        }
    },
    tabSelected: {
        backgroundColor: `${Global.colors.digitalBlue} !important`,
        color: `${Global.colors.white} !important`
    },
    tabsContainer: {
        display: "grid",
        gap: "16px",
        gridAutoFlow: "column"
    },
    tabWrapper: {
        flexDirection: "row",
        justifyContent: "start",
        textAlign: "start"
    },
    warningPopupSuccessImage: {
        background: "rgba(140, 191, 89, 0.2)",
        color: Global.colors.oliveGreen
    },
    warningPopupImageError: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
