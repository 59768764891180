// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CustomTooltip, IconButton, TableRow } from "../../../../../components";
// Helpers
import { hasPermission } from "../../helpers/entitiesMappingPermissions";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./LegalEntityMappingRow.css";

class CustomEditIconButton extends PureComponent {
    static propTypes = {
        handleTooltipOnHover: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    render() {
        const { handleTooltipOnHover, openEditPopup } = this.props;

        return (
            <IconButton
                defaultClassName="icon-edit"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover &&
                        handleTooltipOnHover(undefined, false);
                    openEditPopup && openEditPopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    }
}
class LegalEntityMappingRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        entry: PropTypes.object.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        profileData: PropTypes.object
    };

    oneLine = 40;

    state = {
        tooltipOpened: undefined
    };

    getSuppliers = row => {
        const suppliers = get(row, "cooppartners_data", []).map(supplier => {
            const supplierName = get(supplier, "name", undefined);
            const supplierId = get(supplier, "_id", undefined);
            return {
                label: supplierName,
                value: supplierId,
                option: { name: supplierName, id: supplierId }
            };
        });

        return (
            suppliers &&
            suppliers.sort((a, b) => a["label"].localeCompare(b["label"]))
        );
    };

    handleTooltipOnHover = (tooltip, onHover) => {
        this.setState({ tooltipOpened: onHover ? tooltip : undefined });
    };

    renderRow = row => {
        let suppliers = this.getSuppliers(row);

        return (
            <Fragment>
                <div
                    style={{
                        ...styles.legalEntityContainer
                    }}
                >
                    {row.name}
                </div>
                <div
                    style={{
                        ...styles.selectsContainer
                    }}
                >
                    {suppliers && suppliers.length > 0 ? (
                        <div style={styles.suppliersContainer}>
                            {suppliers.map((supplier, index) => (
                                <span key={index} style={styles.suppliers}>
                                    {supplier.label}
                                </span>
                            ))}
                        </div>
                    ) : (
                        <div />
                    )}
                </div>

                {this.renderActionButtons()}
            </Fragment>
        );
    };

    renderActionButtons = () => {
        const { openEditPopup, profileData } = this.props;
        const { tooltipOpened } = this.state;

        const userRole = get(profileData, "role", {});

        const tooltip = "editTooltip";

        return (
            <div
                className="legal-entities-mapping-row-actions"
                style={styles.iconsContainer}
            >
                {hasPermission(userRole, "update") && (
                    <CustomTooltip
                        onClose={() =>
                            this.handleTooltipOnHover(tooltip, false)
                        }
                        onOpen={() => this.handleTooltipOnHover(tooltip, true)}
                        open={tooltipOpened === tooltip}
                        placement="top"
                        title={translation.labels.edit}
                    >
                        <span>
                            <CustomEditIconButton
                                handleTooltipOnHover={this.handleTooltipOnHover}
                                openEditPopup={openEditPopup}
                            />
                        </span>
                    </CustomTooltip>
                )}
            </div>
        );
    };

    render() {
        const { containerStyle, entry } = this.props;

        return (
            <TableRow
                containerStyle={{
                    ...containerStyle,
                    ...styles.rowContainer
                }}
            >
                {this.renderRow(entry)}
            </TableRow>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profileData
    };
};

export default connect(mapStateToProps)(LegalEntityMappingRow);
