import Global from "../../../../../config/global.css";

const styles = {
    actions: {
        button: {
            container: {
                placeSelf: "end"
            },
            default: {
                fontWeight: "600",
                textTransform: "uppercase",
                width: "max-content"
            },
            disabled: {
                backgroundColor: Global.colors.darkPaleGrey,
                color: Global.colors.white
            }
        },
        container: {
            display: "grid",
            gap: "8px"
        },
        innerContainer: {
            display: "grid",
            gap: "24px",
            gridAutoFlow: "column",
            gridTemplateColumns: "1fr auto"
        },
        label: {
            color: Global.colors.blueyGrey,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "13px",
            textTransform: "uppercase"
        },
        optionContainer: {
            display: "flex",
            height: "fit-content"
        },
        optionsContainer: {
            display: "grid",
            gap: "10px"
        },
        radio: {
            checked: {
                backgroundColor: Global.colors.digitalBlue,
                border: `1px solid ${Global.colors.digitalBlue}`,
                color: Global.colors.white,
                fontSize: "12px"
            },
            checkmark: {
                alignItems: "center",
                border: `1px solid ${Global.colors.darkPaleGrey}`,
                height: "12px",
                display: "flex",
                justifyContent: "center",
                top: "2px",
                width: "12px"
            },
            container: {
                fontSize: "12px",
                lineHeight: "15px",
                paddingLeft: "22px"
            },
            input: {
                width: "0px",
                margin: "0px"
            }
        },
        tooltip: {
            icon: {
                color: Global.colors.darkSlateBlue,
                cursor: "pointer",
                fontSize: "15px",
                marginBottom: "-1px",
                marginLeft: "5px",
                marginTop: "1px"
            }
        }
    },
    container: {
        display: "grid",
        gap: "16px",
        width: "100%"
    }
};

export default styles;
