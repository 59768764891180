import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "12px",
        gridTemplateColumns: "minmax(502px, 50%)",
        gridTemplateRows: "62px min-content min-content",
        padding: "32px 24px"
    },
    deleteKeywordsNote: {
        backgroundColor: Global.colors.paleGrey,
        borderRadius: "8px",
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px",
        marginTop: "12px",
        padding: "16px"
    },
    selectControl: {
        padding: "7px 8px"
    },
    selectMenu: {
        maxHeight: "240px"
    },
    selectMenuContainer: {
        position: "relative"
    },
    selectMultiValue: {
        backgroundColor: Global.colors.darkSlateBlue,
        border: "unset",
        borderRadius: "4px",
        margin: "4px"
    },
    selectMultiValueLabel: {
        color: Global.colors.white,
        padding: "5.5px 12px"
    },
    selectMultiValueRemove: {
        borderRadius: "4px",
        color: Global.colors.white,
        margin: "unset"
    },
    textField: {
        width: "100%",

        disabled: {
            WebkitTextFillColor: Global.colors.darkPaleGrey
        },
        input: {
            padding: "15px 12px !important"
        }
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
