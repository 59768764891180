// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { change, Field } from "redux-form";
// Components
import {
    CreatableSelect,
    MaterialTextField
} from "../../../../../../../../../../../components/";
import { GroupCard } from "../../../../../";
// Validators
import {
    digits,
    digitsAndDot,
    required
} from "../../../../../../../../../../../services/validators";
// Helpers
import { sortByLabel } from "../../../../helpers/eventDetailsFormHelper";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Location.css";

class Location extends PureComponent {
    static propTypes = {
        eventDetailsForm: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        locations: PropTypes.array
    };

    prepareLocations = () => {
        const { locations } = this.props;

        if (!locations) {
            return undefined;
        }

        let locationsList = [];
        if (locations && locations.length) {
            locationsList = locations.map(location => {
                const locationName = location.name;

                return {
                    label: locationName,
                    value: locationName,
                    option: {
                        label: locationName,
                        value: location
                    }
                };
            });
        }

        return locationsList.sort(sortByLabel);
    };

    handleLocationChange = location => {
        const { dispatch } = this.props;
        const data = get(location, "option.value", undefined);

        dispatch(change("eventDetails", "location.id", get(data, "_id", null)));
        data &&
            dispatch(
                change(
                    "eventDetails",
                    "location.latitude",
                    get(data, "location.coordinates[0]", null)
                )
            );
        data &&
            dispatch(
                change(
                    "eventDetails",
                    "location.longitude",
                    get(data, "location.coordinates[1]", null)
                )
            );
        data &&
            dispatch(
                change(
                    "eventDetails",
                    "location.postalcode",
                    get(data, "postal_code", null)
                )
            );

        document.activeElement && document.activeElement.blur();
    };

    render() {
        const locations = this.prepareLocations();

        return (
            <GroupCard style={styles.card} title={translation.event.location}>
                <div style={styles.container}>
                    <Field
                        component={CreatableSelect}
                        customOnChange={this.handleLocationChange}
                        editable
                        isClearable
                        isSearchable
                        label={translation.event.location}
                        placeholder={""}
                        name="location.name"
                        options={locations}
                        validate={required}
                    />

                    <Field
                        component={MaterialTextField}
                        label={translation.events.editPopup.postalCode}
                        name="location.postalcode"
                        style={styles.textField}
                        variant="outlined"
                        validate={digits}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.event.latitude}
                        name="location.latitude"
                        style={styles.textField}
                        variant="outlined"
                        validate={[required, digitsAndDot]}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.event.longitude}
                        name="location.longitude"
                        style={styles.textField}
                        variant="outlined"
                        validate={[required, digitsAndDot]}
                    />
                </div>
            </GroupCard>
        );
    }
}

const mapStateToProps = state => {
    return {
        eventDetailsForm: get(state, "form.eventDetails", undefined),
        locations: state.eventDetails.locations
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Location);
