import Global from "../../../../../../../../../../../config/global.css";

const styles = {
    container: {
        position: "relative",
        width: "100%",
        height: "200px",
        border: `2px solid ${Global.colors.darkSlateBlue}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
        flexDirection: "column"
    },
    button: {
        display: "inline-block",
        padding: "10px 20px",
        fontSize: "11px",
        fontWeight: "bold",
        textAlign: "center",
        textTransform: "uppercase",
        textDecoration: "none",
        border: `1px solid ${Global.colors.darkSlateBlue}`,
        borderRadius: "20px",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        marginTop: "20px",
        background: "transparent",

        "&:hover": {
            backgroundColor: Global.colors.darkSlateBlue,
            color: "#fff",
            transition: "0.3s ease",
            "& svg path": { fill: "#fff" }
        }
    },
    loading: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        background: Global.colors.darkSlateBlue,
        zIndex: 100,
        borderRadius: "18px",
        opacity: "0.8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    spinner: {
        cx: "44px",
        cy: "44px",
        fill: "none",
        r: "15px",
        strokeWidth: "3px"
    },
    gradients: {
        1: Global.colors.white,
        2: Global.colors.white
    }
};

export default styles;
