export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";

export const defaultState = {
    message: undefined,
    open: false,
    type: undefined
};

// Reducer
export default (state = defaultState, action) => {
    switch (action.type) {
        case CLOSE_SNACKBAR: {
            return Object.assign({}, state, {
                open: false
            });
        }
        case OPEN_SNACKBAR: {
            return Object.assign({}, state, {
                open: true,
                message: action.payload.message,
                type: action.payload.type
            });
        }
        default:
            return state;
    }
};

export const closeSnackbar = () => {
    return {
        type: CLOSE_SNACKBAR
    };
};

export const openSnackbar = (message, type) => {
    return {
        type: OPEN_SNACKBAR,
        payload: { message, type }
    };
};
