// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import { BaseClassification } from "../";

class UnclearClassification extends PureComponent {
    static propTypes = {
        handleLoadMoreOffers: PropTypes.func.isRequired,
        handleLoadOffers: PropTypes.func.isRequired,
        openPopup: PropTypes.func,
        tab: PropTypes.string.isRequired
    };

    render() {
        const { handleLoadMoreOffers, handleLoadOffers, openPopup, tab } =
            this.props;

        return (
            <BaseClassification
                availableClassifications={[
                    "nationwide",
                    "regional",
                    "archived"
                ]}
                expandFirstRow
                handleLoadMoreOffers={handleLoadMoreOffers}
                handleLoadOffers={handleLoadOffers}
                hasSearch
                openPopup={openPopup}
                tab={tab}
            />
        );
    }
}
export default UnclearClassification;
