import Global from "../../../../config/global.css";

const styles = {
    legalEntityFilter: {
        container: {
            border: `1px solid ${Global.colors.darkSlateBlue}`,
            borderRadius: "8px",
            width: "100%"
        },
        menuList: {
            maxHeight: "196px"
        },
        valueContainer: {
            width: "100%"
        }
    },
    supplierEntityFilter: {
        container: {
            border: `1px solid ${Global.colors.darkSlateBlue}`,
            borderRadius: "8px",
            width: "100%"
        },
        menuList: {
            maxHeight: "196px"
        },
        valueContainer: {
            width: "100%"
        }
    }
};

export default styles;
