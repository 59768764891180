// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// Components
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import { IconButton, MaterialTextField } from "..";
// Styles
import styles from "./DatePicker.css";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 80
    }
}));

const TextField = React.forwardRef(
    ({ clearAction, getValue, setInternalOnChange, style, ...props }, ref) => {
        TextField.displayName = "TextField";
        TextField.propTypes = {
            clearAction: PropTypes.func,
            getValue: PropTypes.func,
            onChange: PropTypes.func,
            setInternalOnChange: PropTypes.func,
            style: PropTypes.object
        };

        const value = getValue();
        setInternalOnChange && setInternalOnChange(props.onChange);

        return (
            <MaterialTextField
                {...props}
                endAdornment={
                    <div style={styles.textFieldIconContainer}>
                        {value && (
                            <span
                                className="icon-close"
                                onClick={clearAction}
                                style={styles.clearIcon}
                            />
                        )}
                        <span
                            className="icon-calendar"
                            style={styles.calendarIcon}
                        />
                    </div>
                }
                InputLabelProps={{ shrink: !!value }}
                InputProps={{
                    readOnly: true
                }}
                ref={ref}
                style={{ ...styles.textField, ...style }}
                variant="outlined"
            />
        );
    }
);

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 7, 11);

class DatePicker extends PureComponent {
    constructor(props) {
        super(props);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.state = {
            value: undefined,
            month: undefined
        };
    }
    static propTypes = {
        containerStyle: PropTypes.object,
        endDate: PropTypes.object,
        input: PropTypes.object,
        startDate: PropTypes.object
    };

    handleYearMonthChange(month) {
        this.setState({ month });
    }

    componentDidMount() {
        const { input } = this.props;

        if (input && !!input.value) {
            this.internalOnChange &&
                this.internalOnChange({
                    target: {
                        value: this.formatDate(input.value)
                    }
                });
            this.setState({ value: input.value });
        }
    }

    handleClearValue = e => {
        const { input } = this.props;

        e.preventDefault();
        e.stopPropagation();

        this.setState(
            {
                value: null
            },
            () => {
                this.internalOnChange &&
                    this.internalOnChange({
                        target: {
                            value: ""
                        }
                    });
                input && input.onChange(null);
            }
        );
    };

    handleDayClick = (day, modifiers) => {
        const { input } = this.props;
        if (modifiers && modifiers.disabled) {
            return;
        }

        this.setState(
            {
                value: day
            },
            () => {
                input && input.onChange(day);
            }
        );
    };

    formatDate = date => {
        if (!date) {
            return null;
        }

        const options = { month: "long", day: "numeric", year: "numeric" };
        return new Intl.DateTimeFormat("de-DE", options).format(date);
    };

    Navbar = ({ onPreviousClick, onNextClick }) => {
        return (
            <div style={styles.iconsContainer}>
                <div style={styles.iconNavBarRight}>
                    <IconButton
                        defaultClassName="icon-arrow-back"
                        onClick={() => {
                            onPreviousClick();
                        }}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
                <div style={styles.iconNavBarLeft}>
                    <IconButton
                        defaultClassName="icon-arrow-right"
                        onClick={() => {
                            onNextClick();
                        }}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
            </div>
        );
    };

    YearsMonthsSelect = ({ date, localeUtils, onChange }) => {
        const classes = useStyles();
        const months = localeUtils.getMonths("de");
        const years = [];
        for (
            let i = fromMonth.getFullYear();
            i <= toMonth.getFullYear();
            i += 1
        ) {
            years.push(i);
        }

        const handleChange = function handleChange(e) {
            const { year, month } = e.target.form;
            onChange(new Date(year.value, month.value));
        };

        return (
            <div className="DayPicker-Caption">
                <FormControl variant="outlined" className={classes.formControl}>
                    <select
                        name="month"
                        style={styles.selectField}
                        onChange={handleChange}
                        value={date.getMonth()}
                    >
                        {months.map((month, i) => (
                            <option
                                key={i}
                                value={i}
                                style={styles.optionField}
                            >
                                {month}
                            </option>
                        ))}
                    </select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <select
                        name="year"
                        style={styles.selectField}
                        onChange={handleChange}
                        value={date.getFullYear()}
                    >
                        {years.map(year => (
                            <option
                                key={year}
                                value={year}
                                style={styles.optionField}
                            >
                                {year}
                            </option>
                        ))}
                    </select>
                </FormControl>
            </div>
        );
    };

    render() {
        const { containerStyle, endDate, startDate, ...props } = this.props;

        const { value, month } = this.state;

        const now = new Date();
        const tomorrow = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1
        );
        const disabledDays = [
            {
                before: startDate || tomorrow,
                after: endDate
            }
        ];

        const dayPickerProps = {
            disabledDays: disabledDays,
            localeUtils: MomentLocaleUtils,
            modifiers: {
                selected: value
            },
            month: month || value || now,
            navbarElement: <this.Navbar />,
            captionElement: ({ date, localeUtils }) => (
                <this.YearsMonthsSelect
                    date={date}
                    localeUtils={localeUtils}
                    onChange={this.handleYearMonthChange}
                />
            ),
            onDayClick: this.handleDayClick,
            showOutsideDays: false
        };

        return (
            <div
                className="date-range"
                style={{ ...styles.container, ...containerStyle }}
            >
                <DayPickerInput
                    component={TextField}
                    selectedDay={value}
                    formatDate={this.formatDate}
                    dayPickerProps={dayPickerProps}
                    onMonthChange={this.handleMonthChange}
                    inputProps={{
                        clearAction: this.handleClearValue,
                        getValue: () => this.state.value,
                        setInternalOnChange: onChange =>
                            !this.internalOnChange &&
                            (this.internalOnChange = onChange),
                        ...props
                    }}
                    placeholder={""}
                />
            </div>
        );
    }
}

export default DatePicker;
