// Modules
import React, { Fragment, PureComponent } from "react";
// Helpers
import { data } from "./helpers/data";
// Styles
import styles from "../../UserManual.css";

class UserManagement extends PureComponent {
    render() {
        const subtopics = data.sort((a, b) => a.index - b.index);

        const stylesContent = styles.details.content;
        return (
            <div style={stylesContent.container}>
                {subtopics &&
                    subtopics.map((subtopic, index) => (
                        <Fragment key={index}>
                            <div style={stylesContent.innerContainer}>
                                <div style={stylesContent.title}>
                                    {subtopic.title}
                                </div>
                                <div style={stylesContent.text}>
                                    {subtopic.description}
                                </div>
                                <img
                                    alt={"img"}
                                    style={stylesContent.image}
                                    src={subtopic.image}
                                />
                            </div>
                            {index + 1 < subtopics.length && (
                                <div style={stylesContent.separator} />
                            )}
                        </Fragment>
                    ))}
            </div>
        );
    }
}
export default UserManagement;
