// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
// Form
import { change, Field } from "redux-form";
// Components
import { CustomRadioButton } from "../../../../../../../../../components/";
// Validators
import { required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./Tax.css";

class Tax extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        taxType: PropTypes.string
    };

    state = {
        taxType: this.props.taxType
    };

    taxes = ["included", "excluded"];

    setTaxType = newTaxType => {
        const { dispatch } = this.props;
        const { taxType } = this.state;

        newTaxType !== taxType &&
            this.setState({ taxType: newTaxType }, () => {
                dispatch(
                    change("editLegalEntityMapping", "taxType", newTaxType)
                );
            });
    };

    RadioButton =
        () =>
        ({ ...props }) => {
            return <CustomRadioButton {...props} />;
        };

    render() {
        const { taxType } = this.state;

        return (
            <div style={styles.container}>
                <span style={styles.title}>
                    {`${translation.entitiesMapping.editPopup.taxType.taxType}:`}
                </span>
                <div style={styles.innerContainer}>
                    {this.taxes.map((option, index) => {
                        const isChecked = taxType === option;

                        return (
                            <div key={index} style={styles.taxType.container}>
                                <Field
                                    component={this.RadioButton()}
                                    name={"taxType"}
                                    props={{
                                        checked: isChecked,
                                        checkmarkDefaultClassName: isChecked
                                            ? "icon-check"
                                            : null,
                                        defaultClassName:
                                            "entityMapping-taxType-radio-button",
                                        label: translation.entitiesMapping
                                            .editPopup.taxType[option],
                                        onChange: () => {
                                            this.setTaxType(option);
                                        },
                                        style: styles.taxType.radio
                                    }}
                                    validate={required}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({}, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Tax);
