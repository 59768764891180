import Global from "../../config/global.css";

const styles = {
    dashboard: {
        childrenContainer: {
            background: Global.colors.bgGrey,
            display: "grid",
            position: "relative",
            overflow: "auto"
        },
        innerContainer: {
            gridTemplateColumns: "auto 1fr"
        }
    },
    dashboardPopupOpened: {
        childrenContainer: {
            overflow: "hidden",
            overflowX: "auto"
        },
        container: {
            overflowY: "hidden"
        },
        innerContainer: {
            overflowY: "hidden"
        }
    }
};

export default styles;
