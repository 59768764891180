import Global from "../../../../../../../../../config/global.css";

const styles = {
    icon: {
        fontSize: "24px"
    },
    rightAlignment: {
        justifySelf: "end"
    },
    rowBlock: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    },
    rowContainer: {
        gridAutoFlow: "column",
        minHeight: "64px",
        padding: "8px 24px"
    }
};

export default styles;
