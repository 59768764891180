import Global from "../../../../../config/global.css";

const styles = {
    contractStatusTagContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    displayShrinkedContainer: {
        height: "28px",
        overflowY: "hidden",
        alignSelf: "center"
    },
    displayShrinkedControlContainer: {
        display: "block"
    },
    dropdownIndicatorContainer: {
        display: "none"
    },
    expandButton: {
        transform: "rotate(180deg)"
    },
    expandCollapseButton: {
        transform: "rotate(0deg)",
        transition: "transform 500ms ease"
    },
    icon: {
        fontSize: "24px"
    },
    iconsContainer: {
        alignItems: "center",
        alignSelf: "flex-start",
        display: "grid",
        gridAutoFlow: "column",
        gap: "16px",
        justifyContent: "flex-end",
        marginTop: "4px"
    },
    increaseRowContainer: {
        display: "flex",
        height: "max-content"
    },
    rowContainer: {
        minHeight: "40px",
        gridAutoFlow: "column"
    },
    suppliersContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "-webkit-box",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "unset",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2
    },
    selectControl: { height: "fit-content", minHeight: "40px" },
    selectControlDisabled: { border: "none", height: "100%", padding: 0 },
    selectsContainer: {
        alignItems: "center",
        display: "flex",
        height: "40px",
        overflow: "hidden"
    },
    selectMultiValue: {
        backgroundColor: Global.colors.digitalBlueT4,
        border: "unset",
        borderRadius: "4px",
        margin: "2px 4px 2px 0px"
    },
    selectMultiValueLabel: {
        padding: "5.5px 6px",
        paddingLeft: "6px"
    }
};

export default styles;
