// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Translations
import translation from "../../../../config/translation";
// Styles
import styles from "./ContractStatusTag.css";

const ContractStatusTag = ({ status }) => {
    return (
        <div
            style={{
                ...styles.container,
                ...get(styles, `status.${status}`, {})
            }}
        >
            {get(translation.entitiesMapping.contractStatus, status, undefined)}
        </div>
    );
};

ContractStatusTag.propTypes = {
    status: PropTypes.string.isRequired
};

export default ContractStatusTag;
