// Modules
import get from "lodash.get";

export const LOADING_END = "spinner/LOADING_END";
export const LOADING_END_ALL = "spinner/LOADING_END_ALL";
export const LOADING_START = "spinner/LOADING_START";

export const defaultState = {
    loading: [],
    style: undefined
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case LOADING_END: {
            let loading = [...state.loading];
            loading.pop();

            return Object.assign({}, state, {
                loading: loading,
                style: loading.length > 0 ? state.style : undefined
            });
        }
        case LOADING_END_ALL: {
            return Object.assign({}, defaultState);
        }
        case LOADING_START: {
            let loading = [...state.loading];
            loading.push(true);

            return Object.assign({}, state, {
                loading: loading,
                style: get(action, "payload.style", state.style)
            });
        }
        default:
            return state;
    }
};

export const endSpinner = () => {
    return {
        type: LOADING_END
    };
};

export const endAllSpinners = () => {
    return {
        type: LOADING_END_ALL
    };
};

export const startSpinner = (payload = undefined) => {
    return {
        type: LOADING_START,
        payload
    };
};
