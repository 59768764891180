const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    numbersContainer: {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    tablesContainer: {
        display: "flex",
        gap: "20px"
    }
};

export default styles;
