// Modules
import React from "react";
import PropTypes from "prop-types";
// Components
import { CustomTooltip, MaterialFabButton } from "../";
// Styles
import styles from "./FabButtonWithTooltip.css";

export default function FabButton({
    classes,
    iconClassName,
    tooltipLabel,
    tooltipName,
    tooltipOpened,
    action,
    disabled,
    handleTooltipOnHover
}) {
    FabButton.propTypes = {
        action: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        classes: PropTypes.object.isRequired,
        iconClassName: PropTypes.string.isRequired,
        tooltipLabel: PropTypes.string.isRequired,
        tooltipName: PropTypes.string.isRequired,
        tooltipOpened: PropTypes.string,
        handleTooltipOnHover: PropTypes.func.isRequired
    };

    return (
        <CustomTooltip
            customClasses={{
                tooltip: classes.tooltip
            }}
            onClose={() => handleTooltipOnHover(tooltipName, false)}
            onOpen={() => handleTooltipOnHover(tooltipName, true)}
            open={tooltipOpened === tooltipName}
            placement="top"
            title={tooltipLabel}
        >
            <span>
                <MaterialFabButton
                    onClick={event => {
                        event.target && event.target.blur();
                        handleTooltipOnHover(tooltipName, false);
                        action && action();
                    }}
                    variant="circular"
                    disabled={disabled}
                >
                    <span className={iconClassName} style={styles.icon} />
                </MaterialFabButton>
            </span>
        </CustomTooltip>
    );
}
