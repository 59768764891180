// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import PropTypes from "prop-types";
// Components
import {
    FileInput,
    IconButton,
    LoadingPopup,
    MaterialButton,
    MaterialTextField,
    WarningPopup
} from "../../../components";
// Actions
import { uploadFile } from "./OffersFileUpload.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
// Translation
import translation from "../../../config/translation";
// Styles
import styles from "./OffersFileUpload.css";

class OffersFileUpload extends PureComponent {
    static propTypes = {
        closePopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        uploadFile: PropTypes.func
    };

    state = {
        file: undefined
    };

    componentWillUnmount() {
        const { closePopup, popup } = this.props;

        popup && popup.opened && closePopup && closePopup();
    }

    setFile = file => {
        this.setState({ file });
    };

    renderBrowseFileButton = () => {
        const { file } = this.state;

        return (
            <div style={styles.buttonsContainer}>
                {file && (
                    <IconButton
                        defaultClassName="icon-close"
                        onClick={() => {
                            this.setFile(undefined);
                        }}
                        type={["default"]}
                        style={styles.clearButton}
                    />
                )}
                <MaterialButton
                    customType="boldPrimary"
                    key="upload"
                    onClick={() => {
                        this.fileRef && this.fileRef.click();
                    }}
                    style={styles.uploadButton}
                >
                    {translation.offersUpload.upload}
                </MaterialButton>
            </div>
        );
    };

    handleUploadResponse = (error = false) => {
        const { closePopup, openPopup } = this.props;

        const closeAction = () => {
            this.setFile(undefined);
            closePopup && closePopup();
        };

        if (error) {
            openPopup({
                children: (
                    <WarningPopup
                        buttonAction={() => {
                            closeAction();
                        }}
                        buttonLabel={translation.labels.close}
                        closeFunction={() => {
                            closeAction();
                        }}
                        imageStyle={styles.warningErrorPopupImage}
                        subTitle={translation.offersUpload.error.subtitle}
                        title={translation.offersUpload.error.title}
                    />
                )
            });
        } else {
            closeAction();
        }
    };

    handleUploadProcessing = () => {
        const { openPopup } = this.props;

        openPopup({
            children: (
                <LoadingPopup
                    subTitle={translation.offersUpload.processing.subtitle}
                    title={translation.offersUpload.processing.title}
                />
            )
        });
    };

    render() {
        const { uploadFile } = this.props;
        const { file } = this.state;

        const actionDisabled = !file;

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.offersFileUpload}
                </div>
                <div style={styles.innerContainer}>
                    <MaterialTextField
                        endAdornment={this.renderBrowseFileButton()}
                        InputLabelProps={{ shrink: true }}
                        label={translation.offersUpload.file}
                        placeholder={translation.offersUpload.searchFile}
                        style={{
                            ...styles.textFieldDisabled,
                            ...styles.textField
                        }}
                        value={get(file, "name", "")}
                        variant="outlined"
                    />
                    <FileInput
                        accept=".xml"
                        customOnChange={this.setFile}
                        input={{
                            name: "file-upload"
                        }}
                        inputRef={ref => {
                            this.fileRef !== ref && (this.fileRef = ref);
                        }}
                        style={styles.uploadInput}
                    />
                </div>
                <div style={styles.bottomContainer}>
                    <MaterialButton
                        customType="boldPrimary"
                        disabled={actionDisabled}
                        onClick={() => {
                            uploadFile &&
                                file &&
                                uploadFile({
                                    file,
                                    processingCb: this.handleUploadProcessing,
                                    cb: this.handleUploadResponse
                                });
                        }}
                        style={{
                            ...styles.button.default,
                            ...(actionDisabled ? styles.button.disabled : {})
                        }}
                    >
                        {translation.offersUpload.uploadOffers}
                    </MaterialButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup
    };
};

const mapDispatchToProps = {
    closePopup,
    openPopup,
    uploadFile
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersFileUpload);
