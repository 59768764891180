// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { InputLabel } from "@material-ui/core";
import { CustomSelect } from "../";
// Translation
import translation from "../../config/translation";
// Styles
import styles from "./Select.css";

class Select extends PureComponent {
    static propTypes = {
        controlStyle: PropTypes.object,
        customOnChange: PropTypes.func,
        disabled: PropTypes.bool,
        input: PropTypes.object,
        label: PropTypes.string,
        menuContainerStyle: PropTypes.object,
        multiValueRemoveIconStyle: PropTypes.object,
        options: PropTypes.array,
        style: PropTypes.object
    };

    state = {
        focused: false,
        value: undefined
    };

    componentDidMount() {
        const { input } = this.props;

        if (input && !!input.value) {
            this.handleChangeSelection(input.value);
            this.handleOnFocusBlur(true);
        }
    }

    handleOnFocusBlur = focused => {
        this.setState({ focused });
    };

    handleChangeSelection = value => {
        this.setState({ value });
    };

    handleOnChange = (e, customOnChange) => {
        const { input } = this.props;

        const newValue = e && e.length === 0 ? null : e;

        input && input.onChange && input.onChange(newValue);

        customOnChange && customOnChange(newValue);

        this.handleOnFocusBlur(!!newValue);
        this.handleChangeSelection(newValue);
    };

    DropdownIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;

        const { disabled } = this.props;

        return (
            <div {...restInnerProps} style={styles.iconContainer} ref={ref}>
                {props.selectProps.menuIsOpen ? (
                    <span
                        className="icon-arrow-up"
                        style={{
                            ...styles.arrowIcon,
                            ...(disabled ? styles.disabled : {})
                        }}
                    />
                ) : (
                    <span
                        className="icon-arrow-down"
                        style={{
                            ...styles.arrowIcon,
                            ...(disabled ? styles.disabled : {})
                        }}
                    />
                )}
            </div>
        );
    };

    ClearIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;

        return (
            <div
                {...restInnerProps}
                style={styles.clearIconContainer}
                ref={ref}
            >
                <span className="icon-close" style={styles.clearIcon} />
            </div>
        );
    };

    MultiValueRemove = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;

        const { multiValueRemoveIconStyle } = this.props;

        return (
            <div
                {...restInnerProps}
                style={get(multiValueRemoveIconStyle, "container", {})}
                ref={ref}
            >
                <span
                    className="icon-close"
                    style={styles.multiValueRemoveIcon.icon}
                />
            </div>
        );
    };

    render() {
        const {
            controlStyle,
            customOnChange,
            disabled,
            label,
            menuContainerStyle,
            options,
            style,
            ...props
        } = this.props;
        const { focused, value } = this.state;

        if (!options) {
            return null;
        }

        if (!props.isMulti) {
            const defaultValue = options.find(
                option => option.value === get(this.props, "input.value", null)
            );
            props.defaultValue = defaultValue;
        }

        return (
            <div style={style}>
                {label && (
                    <InputLabel
                        style={{
                            ...styles.label,
                            ...(focused ? styles.labelShrink : {}),
                            ...(disabled ? styles.disabled : {})
                        }}
                        {...{ shrink: focused }}
                    >
                        {label}
                    </InputLabel>
                )}
                <CustomSelect
                    {...props}
                    controlStyle={{ ...styles.control, ...controlStyle }}
                    customComponents={{
                        ClearIndicator: this.ClearIndicator,
                        DropdownIndicator: this.DropdownIndicator,
                        MultiValueRemove: this.MultiValueRemove
                    }}
                    disabled={disabled}
                    disabledStyle={styles.disabledContainer}
                    inputStyle={styles.singleValue}
                    menuContainerStyle={{
                        ...styles.menu,
                        ...menuContainerStyle
                    }}
                    noOptionsMessage={translation.labels.noOptions}
                    onChange={e => this.handleOnChange(e, customOnChange)}
                    onInputChange={e => {
                        !(!e && value) &&
                            this.handleOnFocusBlur(
                                e ? e.trim().length > 0 : false
                            );
                    }}
                    onMenuOpen={() => this.handleOnFocusBlur(true)}
                    optionFocusedStyle={styles.optionFocused}
                    options={options || []}
                    singleValueStyle={{
                        ...styles.singleValue,
                        ...(disabled ? styles.disabled : {})
                    }}
                    valueContainerStyle={styles.valueContainer}
                />
            </div>
        );
    }
}

export default Select;
