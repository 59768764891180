// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { OfferManagement, UserManagement } from "./components/";
import { MenuCard } from "../components";
import { IconButton } from "../../../components";
// Actions
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
// Translation
import translation from "../../../config/translation";
// Helpers
import { hasSpecificPermission } from "../../../services/permissions";
// Styles
import styles from "./UserManual.css";

class UserManual extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        editPopup: PropTypes.object,
        openEditPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        userRole: PropTypes.object
    };

    topics = [
        {
            key: "userManagement",
            detailsComponent: UserManagement,
            icon: "icon-user-management",
            title: translation.navigationMenu.userManagement,
            subTopics: [
                translation.userManual.subTopics.overview,
                translation.userManual.subTopics.edit,
                translation.userManual.subTopics.create
            ],
            permission: "user_management"
        },
        {
            key: "offerManagement",
            detailsComponent: OfferManagement,
            icon: "icon-offers",
            title: translation.navigationMenu.offerManagement,
            subTopics: [
                translation.userManual.subTopics.overview,
                translation.userManual.subTopics.keywordTagging,
                translation.userManual.subTopics.createNewOffer,
                translation.userManual.subTopics.titleAndShortDescription,
                translation.userManual.subTopics.offerDetails,
                translation.userManual.subTopics.editOffer,
                translation.userManual.subTopics.createEvents,
                translation.userManual.subTopics.editOrDeleteEvents
            ],
            permission: "offer_management"
        }
    ];

    componentWillUnmount() {
        const {
            closeEditPopup,
            editPopup: { opened }
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
    }

    handleOpenDetails = topic => {
        const { closeEditPopup, openEditPopup } = this.props;

        openEditPopup &&
            openEditPopup({
                children: <topic.detailsComponent />,
                header: (
                    <div style={styles.details.header.title}>
                        <IconButton
                            defaultClassName="icon-arrow-back"
                            onClick={closeEditPopup}
                            style={styles.details.header.backIcon}
                            type={["default"]}
                        />
                        <span>{topic.title}</span>
                    </div>
                ),
                style: styles.details.popup
            });
    };

    render() {
        const { userRole } = this.props;

        const topics = this.topics.filter(topic =>
            hasSpecificPermission(
                get(userRole, "permissions", {}),
                "user_manual",
                topic.permission
            )
        );

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.userManual}
                </div>
                <div style={styles.subtitle}>
                    {translation.userManual.subtitle}
                </div>
                <div style={styles.innerContainer}>
                    {topics.map((topic, index) => (
                        <MenuCard
                            key={index}
                            menu={topic}
                            onClickAction={this.handleOpenDetails}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        editPopup: state.editPopup,
        userRole: state.profileData.role
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    openEditPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManual);
