// Modules
import get from "lodash.get";
// Translation
import translation from "../../../../../../../../config/translation";
// Helpers
import { getSeminarsSortedByStartDate } from "./eventsHelper";
import { getTimestamp } from "../../../../../../../../services/date";
import { formatPrice } from "../../../../../../../../services/formats";

/**
 * The possible event types.
 */
export const eventTypes = [
    {
        label: translation.event.type.presence,
        value: "presence",
        option: {
            label: "presence"
        }
    },
    {
        label: translation.event.type.online,
        value: "online",
        option: {
            label: "online"
        }
    },
    {
        label: translation.event.type.blended,
        value: "blended",
        option: {
            label: "blended"
        }
    }
];

/**
 * The possible duration units.
 */
export const durationUnitsMapping = {
    stunden: ["stunden", "hours"],
    tag: ["tag", "day"],
    tage: ["tage", "days"],
    wochen: ["wochen", "weeks"],
    monate: ["monate", "month"]
};

/**
 * The possible duration units.
 */
export const durationUnits = [
    {
        label: translation.event.unit.stunden,
        value: "stunden",
        option: {
            label: "stunden"
        }
    },
    {
        label: translation.event.unit.tag,
        value: "tag",
        option: {
            label: "tag"
        }
    },
    {
        label: translation.event.unit.tage,
        value: "tage",
        option: {
            label: "tage"
        }
    },
    {
        label: translation.event.unit.wochen,
        value: "wochen",
        option: {
            label: "wochen"
        }
    },
    {
        label: translation.event.unit.monate,
        value: "monate",
        option: {
            label: "monate"
        }
    }
];

/**
 * Gets the mapping unit.
 *
 * @param {Object} unit the unit (can be string or an object)
 */
export function mapDurationUnit(unit) {
    const unitValue = unit ? unit.value || unit : "";

    const match = Object.entries(durationUnitsMapping).find(([, value]) =>
        value.includes(unitValue.toLowerCase())
    );

    return match && match[0];
}

/**
 * Gets the list of units with the current unit if does not exist in the base list.
 *
 * @param {String} unit the unit
 */
export function getDurationUnits(unit) {
    let durationUnitsList = [...durationUnits];

    if (unit && !get(unit, "__isNew__", undefined) && !mapDurationUnit(unit)) {
        durationUnitsList.push({
            label: unit,
            value: unit,
            option: {
                label: unit
            }
        });
    }

    return durationUnitsList;
}

/**
 * Creates the form initial values.
 *
 * @param {Object} props props
 * @param {Object} details the offer details data
 */
export function mapInitialValues(props, details) {
    const eventIndex = get(props, "match.params.eventIndex", undefined);
    const seminars = getSeminarsSortedByStartDate(details);
    const eventDetails =
        eventIndex &&
        seminars &&
        seminars.length >= eventIndex &&
        seminars[eventIndex];

    const type = get(eventDetails, "type", undefined);
    let price = get(eventDetails, "price", undefined);
    price = !price || isNaN(price) ? null : formatPrice(price);

    let initialValues = {
        offer: {
            id: get(details, "_id", undefined),
            title: get(details, "title", undefined)
        },
        location: {
            name: null
        },
        modules: [],
        duration: {
            unit: null
        },
        entity: {
            name: null
        },
        instructors: [],
        contacts: []
    };

    const mapDate = date => {
        return !date || date.toLowerCase() === "auf anfrage"
            ? null
            : getTimestamp(date);
    };

    eventDetails &&
        (initialValues = {
            ...initialValues,
            id: get(eventDetails, "_id", undefined),
            price: price,
            externalId: get(eventDetails, "external_id", undefined),
            externalLink: get(eventDetails, "external_link", undefined),
            enrolmentLink: get(eventDetails, "enrolment_link", undefined),
            type: type || "",
            location: {
                id: get(eventDetails, "location._id", undefined),
                name: get(eventDetails, "location.name", undefined),
                latitude: get(
                    eventDetails,
                    "location.location.coordinates[0]",
                    undefined
                ),
                longitude: get(
                    eventDetails,
                    "location.location.coordinates[1]",
                    undefined
                ),
                postalcode: get(eventDetails, "location.postal_code", undefined)
            },
            modules: get(eventDetails, "modules", [])
                .map(module => ({
                    number: get(module, "number", undefined),
                    name: get(module, "title", undefined),
                    duration: {
                        unit:
                            mapDurationUnit(get(module, "duration_type", "")) ||
                            get(module, "duration_type", ""),
                        value: get(module, "duration", undefined)
                    },
                    startDate: mapDate(get(module, "start_date", undefined)),
                    endDate: mapDate(get(module, "end_date", undefined))
                }))
                .sort(function (a, b) {
                    return a.number - b.number;
                }),
            startDate: mapDate(get(eventDetails, "start_date", undefined)),
            endDate: mapDate(get(eventDetails, "end_date", undefined)),
            duration: {
                unit:
                    mapDurationUnit(get(eventDetails, "duration_type", "")) ||
                    get(eventDetails, "duration_type", ""),
                value: get(eventDetails, "duration", undefined)
            },
            entity: {
                id: get(eventDetails, "entity_details[0]._id", undefined),
                name: get(eventDetails, "entity_details[0].name", undefined),
                link: get(eventDetails, "entity_details[0].link", undefined)
            },
            instructors: get(eventDetails, "instructors", []).map(
                instructor => {
                    return { name: instructor.name };
                }
            ),
            contacts: get(eventDetails, "entity_contacts_details", []).map(
                contact => ({
                    id: get(contact, "_id", undefined),
                    entityId: get(contact, "entity_id", undefined),
                    name: get(contact, "name", undefined),
                    email: get(contact, "email", undefined),
                    phone: get(contact, "phone", undefined),
                    pictureLink: get(contact, "picture_link", undefined)
                })
            )
        });

    return initialValues;
}
