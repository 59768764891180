// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators, compose } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import debounce from "lodash/debounce";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Tab, Tabs } from "@material-ui/core";
import {
    MaterialFabButton,
    SearchBar,
    WarningPopup
} from "../../../components";
import {
    ChambersLocationsOnlineKeywords,
    ChambersLocationRow,
    ChamberLocationDetails,
    OnlineKeywordsRow,
    OnlineKeywordsDetails
} from "./components";
// Actions
import {
    clearEntriesList,
    getLegalEntities,
    getLocations,
    getOnlineKeywords,
    saveLegalEntities,
    saveOnlineKeywords,
    setLegalEntityOnlineKeywordsTab
} from "./ChambersLocations.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
// Helpers
import { hasPermission } from "./helpers/chambersLocationsPermissions";
import { getFilteredLegalEntitiesBySearchQuery } from "./helpers/chambersLocationsSearch";
// Styles
import styles from "./ChambersLocations.css";
// Translations
import translation from "../../../config/translation";

class ChambersLocations extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        clearEntriesList: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        editMappingForm: PropTypes.object,
        editPopup: PropTypes.object,
        getLegalEntities: PropTypes.func.isRequired,
        getLocations: PropTypes.func.isRequired,
        getOnlineKeywords: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        location: PropTypes.object,
        chamberLocation: PropTypes.object,
        setLegalEntityOnlineKeywordsTab: PropTypes.func.isRequired,
        onlineKeywords: PropTypes.array,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object,
        tab: PropTypes.string.isRequired
    };

    tabs = [
        {
            createNew: () => this.createPopup(),
            key: "legalEntities",
            icon: "icon-offers",
            rowComponent: ChambersLocationRow,
            tableHeaders: [
                {
                    label: translation.chambersLocations.tableHeaders.chamber
                },
                {
                    label: translation.chambersLocations.tableHeaders.location
                }
            ]
        },
        {
            createNew: () => this.createPopup(),
            icon: "icon-location",
            key: "onlineKeywords",
            rowComponent: OnlineKeywordsRow,
            tableHeaders: [
                translation.chambersLocations.tableHeaders.onlineKeyword
            ]
        }
    ];

    componentDidMount() {
        const {
            getLegalEntities,
            getLocations,
            tab,
            setLegalEntityOnlineKeywordsTab
        } = this.props;
        tab !== "legalEntities" &&
            setLegalEntityOnlineKeywordsTab("legalEntities");

        setTimeout(() => {
            getLegalEntities && getLegalEntities();
            getLocations && getLocations();
        }, 200);
    }

    componentDidUpdate(prevProps) {
        const {
            tab,
            getLegalEntities,
            getLocations,
            getOnlineKeywords,
            clearEntriesList
        } = this.props;

        if (tab !== get(prevProps, "tab", undefined)) {
            clearEntriesList && clearEntriesList(tab);

            let searchElement = document.getElementsByClassName(
                "mapping-search-input"
            );
            searchElement =
                searchElement && searchElement.length > 0 && searchElement[0];
            searchElement && (searchElement.value = null);

            if (tab === "legalEntities") {
                getLegalEntities && getLegalEntities();
                getLocations && getLocations();
            } else {
                getOnlineKeywords && getOnlineKeywords();
            }
        }
    }

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    closePopups = () => {
        const {
            editPopup: { opened },
            closeEditPopup,
            closePopup,
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    };

    createPopup = () => {
        const { dispatch, openEditPopup, tab } = this.props;

        let editPopupParams = {
            cancelAction: this.handleCancelCreatePopup,
            cancelButtonLabel: translation.chambersLocations.editPopup.cancel,
            confirmButtonLabel:
                translation.chambersLocations.editPopup.saveChanges,
            style: styles.editPopup
        };

        if (tab === "legalEntities") {
            editPopupParams = {
                ...editPopupParams,
                children: <ChamberLocationDetails />,
                confirmAction: () => {
                    dispatch(submit("chamberLocation"));
                },
                header: translation.chambersLocations.editPopup
                    .createChamberLocation
            };
        } else if (tab === "onlineKeywords") {
            editPopupParams = {
                ...editPopupParams,
                children: <OnlineKeywordsDetails />,
                confirmAction: () => {
                    dispatch(submit("onlineKeywords"));
                },
                header: translation.chambersLocations.editPopup
                    .createOnlineKeyword
            };
        }

        openEditPopup && openEditPopup(editPopupParams);
    };

    handleCancelCreatePopup = () => {
        const { closeEditPopup, closePopup, chamberLocation, openPopup } =
            this.props;

        let initialValues = get(chamberLocation, "initial", undefined);
        let newValues = get(chamberLocation, "values", undefined);
        const valuesHasChanged = !isEqual(initialValues, newValues);

        if (valuesHasChanged) {
            openPopup({
                children: (
                    <WarningPopup
                        buttonAction={() => {
                            closeEditPopup();
                            closePopup();
                        }}
                        buttonLabel={translation.chambersLocations.confirm}
                        closeFunction={() => {
                            closePopup();
                        }}
                        imageStyle={styles.warningPopupImage}
                        subTitle={
                            translation.chambersLocations.editPopup
                                .unsavedWarningMessage
                        }
                        title={
                            translation.chambersLocations.editPopup
                                .unsavedChanges
                        }
                    />
                )
            });
        } else {
            closeEditPopup();
        }
    };

    handleChangeActiveTab = (event, newActiveTab) => {
        const { setLegalEntityOnlineKeywordsTab, tab } = this.props;

        if (newActiveTab !== tab) {
            setLegalEntityOnlineKeywordsTab &&
                setLegalEntityOnlineKeywordsTab(newActiveTab);
            this.closePopups();
        }
    };

    handleSearch = value => {
        const { dispatch, legalEntities, onlineKeywords, tab } = this.props;
        const data = tab === "legalEntities" ? legalEntities : onlineKeywords;

        const filteredLegalEntities = getFilteredLegalEntitiesBySearchQuery(
            value,
            data
        );

        if (tab === "legalEntities") {
            dispatch(saveLegalEntities(filteredLegalEntities, true));
        } else {
            dispatch(saveOnlineKeywords(filteredLegalEntities, true));
        }
    };

    debounceSearchOnChange = debounce(this.handleSearch, 750);

    render() {
        const { profileData, classes, tab } = this.props;
        const userRole = get(profileData, "role", {});
        const tabParams = this.tabs.find(el => el.key === tab);

        return (
            <div style={styles.container}>
                <Tabs
                    classes={{
                        flexContainer: classes.tabsContainer
                    }}
                    onChange={this.handleChangeActiveTab}
                    scrollButtons="off"
                    TabIndicatorProps={{
                        style: styles.tabs.indicator
                    }}
                    style={styles.tabs.container}
                    value={tab}
                >
                    {this.tabs.map((tabObj, index) => {
                        const isTabSelected = tab === tabObj.key;
                        return (
                            <Tab
                                classes={{
                                    root: classes.tabDefault,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper
                                }}
                                icon={
                                    <div
                                        className="tab-icon"
                                        style={{
                                            ...styles.tabs.icon.container
                                                .default,
                                            ...(isTabSelected
                                                ? styles.tabs.icon.container
                                                      .selected
                                                : {})
                                        }}
                                    >
                                        <span
                                            className={tabObj.icon}
                                            style={{
                                                ...styles.tabs.icon.icon
                                                    .default,
                                                ...(isTabSelected
                                                    ? styles.tabs.icon.icon
                                                          .selected
                                                    : {})
                                            }}
                                        />
                                    </div>
                                }
                                key={index}
                                label={
                                    translation.chambersLocations.tabs[
                                        tabObj.key
                                    ]
                                }
                                value={tabObj.key}
                            />
                        );
                    })}
                </Tabs>
                <div style={styles.headerTitle}>
                    {translation.chambersLocations.tabs[tab]}
                </div>
                <div style={styles.topbarContainer}>
                    <SearchBar
                        icon="icon-search"
                        inputClassName="mapping-search-input"
                        onChange={this.debounceSearchOnChange}
                        placeholder={translation.labels.search}
                        style={{
                            container: styles.searchBarContainer,
                            input: styles.searchBarInput
                        }}
                    />

                    {hasPermission(userRole, "create") &&
                        get(tabParams, "createNew", undefined) && (
                            <MaterialFabButton
                                onClick={get(tabParams, "createNew", undefined)}
                                style={styles.addButtonContainer}
                                variant="circular"
                            >
                                <span
                                    className="icon-plus"
                                    style={styles.addButtonIcon}
                                />
                            </MaterialFabButton>
                        )}
                </div>
                <ChambersLocationsOnlineKeywords
                    key={get(tabParams, "key", undefined)}
                    RowComponent={get(tabParams, "rowComponent", null)}
                    tab={get(tabParams, "key", undefined)}
                    tableHeaders={get(tabParams, "tableHeaders", [])}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        chamberLocation: get(state, "form.chambersLocation", undefined),
        editPopup: state.editPopup,
        legalEntities: state.chambersLocations.legalEntities,
        onlineKeywords: state.chambersLocations.onlineKeywords,
        popup: state.popup,
        profileData: state.profileData,
        tab: state.chambersLocations.tab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeEditPopup,
                clearEntriesList,
                closePopup,
                getLegalEntities,
                getLocations,
                getOnlineKeywords,
                openEditPopup,
                openPopup,
                saveLegalEntities,
                saveOnlineKeywords,
                setLegalEntityOnlineKeywordsTab
            },
            dispatch
        )
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ChambersLocations);
