// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Helpers
import { createCsv } from "./components/BulkAction/helpers/csvCreation";
// Translation
import translation from "../../../config/translation";

export const CLEAR_LEGAL_ENTITIES = "invoicing/CLEAR_LEGAL_ENTITIES";
export const CLEAR_SYSTEM_BOOKINGS = "CLEAR_SYSTEM_BOOKINGS";
export const SAVE_LEGAL_ENTITIES = "invoicing/SAVE_LEGAL_ENTITIES";
export const SAVE_SYSTEM_BOOKINGS = "SAVE_SYSTEM_BOOKINGS";

export const defaultState = {
    legalEntities: [],
    systemBookings: {
        past: [],
        future: [],
        archived: []
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: defaultState.legalEntities
            });
        }
        case CLEAR_SYSTEM_BOOKINGS: {
            return Object.assign({}, state, {
                systemBookings: defaultState.systemBookings
            });
        }
        case SAVE_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: action.payload
            });
        }
        case SAVE_SYSTEM_BOOKINGS: {
            return Object.assign({}, state, {
                systemBookings: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearLegalEntities = () => {
    return {
        type: CLEAR_LEGAL_ENTITIES
    };
};

export const clearSystemBookings = () => {
    return {
        type: CLEAR_SYSTEM_BOOKINGS
    };
};

export const saveLegalEntities = entities => {
    return {
        type: SAVE_LEGAL_ENTITIES,
        payload: entities
    };
};

export const saveSystemBookings = systemBookings => {
    return {
        type: SAVE_SYSTEM_BOOKINGS,
        payload: systemBookings
    };
};

export const getLegalEntities = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearLegalEntities());

    api.get("legalentities")
        .then(response => {
            dispatch(saveLegalEntities(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getLegalEntities: ", e);
            dispatch(endSpinner());
        });
};

export const getSystemBookings =
    (entityId = undefined) =>
    dispatch => {
        dispatch(startSpinner());
        dispatch(clearSystemBookings());

        let params = {};
        entityId && (params["entity_id"] = entityId);

        api.get("events/enrolments", params)
            .then(response => {
                const data = parseSystemBookings(get(response, "data", []));
                dispatch(saveSystemBookings(data));
                dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getSystemBookings: ", e);
                dispatch(endSpinner());
            });
    };

export const parseSystemBookings = data => {
    let systemBookings = {
        past: [],
        future: [],
        archived: []
    };

    if (!data) {
        return systemBookings;
    }

    const now = getDate(Date.now());

    data.forEach(event => {
        const startDate = getDate(event.start_date);
        const bookedParticipants = get(event, "booked_participants", []);
        const isArchived = bookedParticipants.every(
            bp => !!get(bp, "archived_date", null)
        );

        if (isArchived) {
            systemBookings.archived.push(event);
        } else if (now >= startDate) {
            systemBookings.past.push(event);
        } else {
            systemBookings.future.push(event);
        }
    });

    const sortDates = list =>
        list.sort((eb1, eb2) => {
            return new Date(eb1.start_date) < new Date(eb2.start_date)
                ? -1
                : new Date(eb1.start_date) > new Date(eb2.start_date)
                ? 1
                : 0;
        });

    Object.entries(systemBookings).forEach(([key, value]) => {
        systemBookings[key] = sortDates(value);
    });

    return systemBookings;
};

export const getDate = date => {
    let newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
};

export const archiveSystemBookings =
    ({ bookingsId, successCb, errorCb }) =>
    dispatch => {
        dispatch(startSpinner());

        api.put("enrolments/archive", bookingsId)
            .then(() => {
                dispatch(endSpinner());
                dispatch(getSystemBookings());
                successCb && successCb();
            })
            .catch(e => {
                console.log("Error calling archiveSystemBookings: ", e);
                dispatch(endSpinner());
                errorCb && errorCb();
            });
    };

export const exportBookings =
    ({ data, successCb, errorCb }) =>
    dispatch => {
        dispatch(startSpinner());

        try {
            createCsv(data);

            successCb && successCb();
            dispatch(endSpinner());

            dispatch(
                openSnackbar(translation.invoicing.successExport, "success")
            );
        } catch (e) {
            console.log("Error calling exportBookings: ", e);
            dispatch(endSpinner());
            errorCb && errorCb();
        }
    };
