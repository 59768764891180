// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Menu, MenuItem } from "@material-ui/core";
// Styles
import styles from "./MaterialMenu.css";

const CssMenu = style => {
    let customStyle = get(styles, "menu", {});
    customStyle.paper = {
        ...customStyle.paper,
        ...{ "&.MuiPopover-paper": get(style, "container", {}) },
        "& .MuiList-padding": {
            ...customStyle.paper["& .MuiList-padding"],
            ...get(style, "optionsContainer", {})
        }
    };

    return withStyles(customStyle)(Menu);
};

const CssMenuItem = style => {
    let customStyle = get(styles, "menuItem", {});
    customStyle.root = {
        ...customStyle.root,
        ...get(style, "options", {})
    };

    return withStyles(customStyle)(MenuItem);
};

class MaterialMenu extends PureComponent {
    static propTypes = {
        anchorEl: PropTypes.object,
        anchorOrigin: PropTypes.shape({
            horizontal: PropTypes.string,
            vertical: PropTypes.string
        }),
        transformOrigin: PropTypes.shape({
            horizontal: PropTypes.string,
            vertical: PropTypes.string
        }),
        onClose: PropTypes.func.isRequired,
        onOpen: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                action: PropTypes.func,
                key: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
            })
        ),
        optionSelected: PropTypes.object,
        style: PropTypes.shape({
            container: PropTypes.object,
            options: PropTypes.object,
            optionsContainer: PropTypes.object
        })
    };

    state = {
        anchorEl: undefined
    };

    handleSelectOption = option => {
        const { onClose } = this.props;

        this.setState({ selectedOption: option }, () => {
            option && option.action && option.action();
            onClose && onClose();
        });
    };

    render() {
        const {
            anchorEl,
            onClose,
            onOpen,
            options,
            optionSelected,
            style,
            ...props
        } = this.props;

        if (!options || options.length === 0) {
            return null;
        }

        const StyledMenu = CssMenu(style);
        const StyledMenuItem = CssMenuItem(style);

        return (
            <StyledMenu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                getContentAnchorEl={null}
                keepMounted
                onClose={onClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                TransitionProps={{
                    onEnter: onOpen
                }}
                {...props}
            >
                {options.map(option => {
                    return (
                        <StyledMenuItem
                            key={option.key}
                            onClick={() => this.handleSelectOption(option)}
                            selected={
                                optionSelected &&
                                optionSelected.key === option.key
                            }
                        >
                            {option.value}
                        </StyledMenuItem>
                    );
                })}
            </StyledMenu>
        );
    }
}

export default MaterialMenu;
