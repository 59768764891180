// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { Checkbox, TableHeaders, WarningPopup } from "../../../components";
import { BulkAction, Filters, InvoicingRow } from "./components";
// Actions
import {
    archiveSystemBookings,
    getLegalEntities,
    getSystemBookings
} from "./Invoicing.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Helpers
import { formatDateToDEFormat } from "../../../services/date";
import {
    composeNewBulkSelectionBasedOnAction,
    getMainCheckboxStatus,
    getSectionCheckboxStatus,
    hasEntries,
    isEntryCheckboxChecked
} from "./helpers/bulkSelection";
import { getFilterOptions } from "../OffersClassification/components/BaseClassification/helpers/searchFilters";
import { getUserRoleEntityId } from "./helpers/invoicingPermissions";
// Translations
import translation from "../../../config/translation";
// Styles
import styles from "./Invoicing.css";

class Invoicing extends PureComponent {
    static propTypes = {
        archiveSystemBookings: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        getLegalEntities: PropTypes.func.isRequired,
        getSystemBookings: PropTypes.func.isRequired,
        systemBookings: PropTypes.object,
        openPopup: PropTypes.func.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object
    };

    availableSections = ["past", "future", "archived"];

    tableHeaders = [
        { label: translation.invoicing.tableHeaders.startDate },
        { label: translation.invoicing.tableHeaders.supplier },
        { label: translation.invoicing.tableHeaders.offer },
        {
            label: translation.invoicing.tableHeaders.classification,
            style: styles.textAlignRight
        },
        {
            label: translation.invoicing.tableHeaders.registration,
            style: styles.textAlignRight
        },
        { label: translation.invoicing.tableHeaders.bookingsNumber }
    ];

    state = {
        bulkSelection: [],
        entityId: undefined,
        filters: {
            classification: undefined,
            status: undefined,
            legalEntity: undefined
        }
    };

    componentDidMount() {
        const { getSystemBookings, getLegalEntities, profileData } = this.props;

        const entityId = getUserRoleEntityId(profileData);
        if (entityId === -1) {
            return;
        }

        this.setState({
            entityId
        });

        getSystemBookings && getSystemBookings(entityId);

        !entityId && getLegalEntities && getLegalEntities();
    }

    componentDidUpdate(prevProps, prevState) {
        const prevFilters = JSON.stringify(get(prevState, "filters", {}));
        const filters = JSON.stringify(get(this.state, "filters", {}));

        if (prevFilters !== filters) {
            // Clearing offers bulk selected which are not displayed anymore due to filter/updates
            const { bulkSelection } = this.state;
            let bulkSelectionFiltered = [...bulkSelection];

            const statusFilter = get(this.state, "filters.status", undefined);
            if (
                statusFilter !== get(prevState, "filters.status", undefined) &&
                statusFilter
            ) {
                bulkSelectionFiltered = bulkSelectionFiltered.filter(
                    selection =>
                        get(selection, "status", undefined) === statusFilter
                );
            }

            const classificationFilter = get(
                this.state,
                "filters.classification",
                undefined
            );
            if (
                classificationFilter !==
                    get(prevState, "filters.classification", undefined) &&
                classificationFilter
            ) {
                bulkSelectionFiltered = bulkSelectionFiltered.filter(
                    selection =>
                        get(selection, "classification", undefined) ===
                        classificationFilter
                );
            }

            const legalEntityFilter = get(
                this.state,
                "filters.legalEntity.value",
                undefined
            );
            if (
                legalEntityFilter !==
                    get(prevState, "filters.legalEntity.value", undefined) &&
                legalEntityFilter
            ) {
                bulkSelectionFiltered = bulkSelectionFiltered.filter(
                    selection =>
                        get(
                            this.state,
                            "filters.legalEntity.option.props.suppliers",
                            []
                        ).includes(get(selection, "supplier._id", undefined))
                );
            }

            bulkSelectionFiltered.length !== bulkSelection.length &&
                this.setState({
                    bulkSelection: bulkSelectionFiltered
                });
        }
    }

    componentWillUnmount() {
        const { closePopup, popup } = this.props;

        popup && popup.opened && closePopup && closePopup();
    }

    updateBulkSelection = ({ operation, entry, section }) => {
        const { bulkSelection } = this.state;

        const entries = this.getEntriesBasedOnFilters();

        const newBulkSelection = composeNewBulkSelectionBasedOnAction({
            operation,
            entry,
            section,
            bulkSelection,
            entries
        });

        this.setState({
            bulkSelection: newBulkSelection
        });
    };

    handleGroupSelection = ({ isChecked, isIndeterminate, section }) => {
        if (isChecked || isIndeterminate) {
            this.updateBulkSelection({
                operation: "unselectAll",
                section
            });
        } else {
            this.updateBulkSelection({
                operation: "selectAll",
                section
            });
        }
    };

    getEntriesBasedOnFilters = () => {
        const { filters } = this.state;
        const { systemBookings } = this.props;
        const { classification, legalEntity, status } = filters;

        let entries = { ...systemBookings };

        if (classification) {
            this.availableSections.forEach(f => {
                entries[f] = get(entries, f, []).filter(
                    eventBooking =>
                        get(eventBooking, "classification", undefined) ===
                        classification
                );
            });
        }

        if (status) {
            [...this.availableSections]
                .filter(el => el !== status)
                .forEach(el => {
                    entries[el] = [];
                });
        }

        if (legalEntity && get(legalEntity, "value", undefined) !== "all") {
            this.availableSections.forEach(f => {
                entries[f] = get(entries, f, []).filter(eventBooking =>
                    get(legalEntity, "option.props.suppliers", []).includes(
                        get(eventBooking, "supplier._id", undefined)
                    )
                );
            });
        }

        return entries;
    };

    applyFilters = filtersValues => {
        const { filters } = this.state;

        let newFilters = { ...filters };
        filtersValues &&
            filtersValues.forEach(({ filter, value }) => {
                let newFilterValue = value || undefined;
                value === "all" && (newFilterValue = undefined);

                newFilters[filter] = newFilterValue;

                this.setState({ filters: newFilters });
            });
    };

    handleArchiveEntry = entry => {
        const {
            archiveSystemBookings,
            closePopup,
            getSystemBookings,
            openPopup,
            openSnackbar
        } = this.props;

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        archiveSystemBookings &&
                            archiveSystemBookings({
                                bookingsId: {
                                    [`${get(entry, "source", "")}_ids`]: get(
                                        entry,
                                        "bookings_ids",
                                        []
                                    )
                                },
                                successCb: () => {
                                    const message = `${
                                        translation.invoicing.success.text1
                                    } ${get(entry, "offer_title", "")} - ${
                                        formatDateToDEFormat(
                                            get(entry, "start_date", undefined)
                                        ) || ""
                                    } ${translation.invoicing.success.text2}`;

                                    closePopup();
                                    openSnackbar &&
                                        openSnackbar(message, "success");
                                },
                                errorCb: () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                    getSystemBookings &&
                                                        getSystemBookings();
                                                }}
                                                buttonLabel={
                                                    translation.invoicing
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                    getSystemBookings &&
                                                        getSystemBookings();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation.invoicing
                                                        .archiveError
                                                        .description
                                                }
                                                title={
                                                    translation.invoicing
                                                        .archiveError.title
                                                }
                                            />
                                        )
                                    });
                                }
                            });
                    }}
                    buttonLabel={translation.invoicing.confirm}
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={translation.invoicing.archiveEventConfirmation}
                    title={translation.invoicing.archiveEvent}
                />
            )
        });
    };

    render() {
        const { legalEntities } = this.props;
        const { bulkSelection, entityId, filters } = this.state;

        const entries = this.getEntriesBasedOnFilters();

        const { isGroupChecked, isGroupIndeterminate } = getMainCheckboxStatus({
            bulkSelection,
            entries
        });

        const displayBulkAction = bulkSelection && bulkSelection.length > 0;

        const legalEntitiesOptions = legalEntities
            ? getFilterOptions(legalEntities)
            : [];

        const tableGridStyle = {
            ...styles.tableGrid,
            gridTemplateColumns:
                styles.tableGrid.gridTemplateColumns +
                ` ${entityId ? "32px" : "88px"}`
        };

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.invoicing}
                </div>
                <div style={styles.topbarContainer}>
                    <Filters
                        applyFilters={this.applyFilters}
                        legalEntities={legalEntitiesOptions}
                        searchFilter={filters}
                    />
                </div>
                <TableHeaders
                    containerStyle={{
                        ...tableGridStyle,
                        ...styles.tableHeaders
                    }}
                    headers={[
                        hasEntries(entries) ? (
                            <Checkbox
                                checked={isGroupChecked}
                                indeterminate={isGroupIndeterminate}
                                key="checkbox"
                                onClick={() =>
                                    this.handleGroupSelection({
                                        isChecked: isGroupChecked,
                                        isIndeterminate: isGroupIndeterminate
                                    })
                                }
                                style={styles.checkbox}
                            />
                        ) : (
                            <div />
                        ),
                        ...this.tableHeaders
                    ]}
                />
                <div
                    style={{
                        ...styles.rowsContainer,
                        ...(displayBulkAction
                            ? styles.rowsContainerCollapsed
                            : {})
                    }}
                >
                    <div style={styles.rowsInnerContainer}>
                        {entries &&
                            this.availableSections.map(section => {
                                const statusFilter = get(
                                    filters,
                                    "status",
                                    undefined
                                );

                                if (statusFilter && section !== statusFilter) {
                                    return null;
                                }

                                let eventBookings = get(entries, section, []);

                                const {
                                    isSectionChecked,
                                    isSectionIndeterminate
                                } = getSectionCheckboxStatus({
                                    bulkSelection,
                                    sectionEntries: eventBookings,
                                    section
                                });

                                return (
                                    <div key={section}>
                                        {!statusFilter && (
                                            <div style={styles.section}>
                                                {eventBookings &&
                                                eventBookings.length > 0 ? (
                                                    <Checkbox
                                                        checked={
                                                            isSectionChecked
                                                        }
                                                        indeterminate={
                                                            isSectionIndeterminate
                                                        }
                                                        onClick={() =>
                                                            this.handleGroupSelection(
                                                                {
                                                                    isChecked:
                                                                        isSectionChecked,
                                                                    isIndeterminate:
                                                                        isSectionIndeterminate,
                                                                    section
                                                                }
                                                            )
                                                        }
                                                        style={styles.checkbox}
                                                    />
                                                ) : (
                                                    <div />
                                                )}
                                                <span>
                                                    {
                                                        translation.invoicing
                                                            .filters[section]
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {eventBookings.map((entry, index) => {
                                            const isChecked =
                                                isEntryCheckboxChecked({
                                                    bulkSelection,
                                                    entry
                                                });

                                            return (
                                                <InvoicingRow
                                                    archiveEntry={
                                                        this.handleArchiveEntry
                                                    }
                                                    containerStyle={
                                                        tableGridStyle
                                                    }
                                                    entry={entry}
                                                    hasArchiveAction={!entityId}
                                                    index={index}
                                                    isChecked={isChecked}
                                                    key={index}
                                                    section={section}
                                                    toggleBulkSelection={() => {
                                                        this.updateBulkSelection(
                                                            {
                                                                operation:
                                                                    isChecked
                                                                        ? "unselect"
                                                                        : "select",
                                                                entry,
                                                                section
                                                            }
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <BulkAction
                    action="export"
                    bulkSelection={bulkSelection}
                    display={displayBulkAction}
                    updateBulkSelection={this.updateBulkSelection}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        legalEntities: state.invoicing.legalEntities,
        popup: state.popup,
        profileData: state.profileData,
        systemBookings: state.invoicing.systemBookings
    };
};

const mapDispatchToProps = {
    archiveSystemBookings,
    closePopup,
    getLegalEntities,
    getSystemBookings,
    openPopup,
    openSnackbar
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
