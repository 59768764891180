const firebase = require("firebase/app").default;

require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
require("firebase/messaging");

export const firebaseConfig = {
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    locationId: process.env.REACT_APP_FIREBASE_LOCATION_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseReduxConfig = {
    attachAuthIsReady: true,
    useFirestoreForProfile: true,
    userProfile: "users"
};

export const firestore = firebaseApp.firestore();
