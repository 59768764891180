const styles = {
    container: {
        display: "grid",
        gridAutoFlow: "row",
        gap: "0px",
        gridTemplateRows: "72px 1fr",
        height: "100%"
    },
    innerContainer: {
        display: "grid",
        gridAutoFlow: "column",
        gap: "0px",
        gridTemplateColumns: "72px 1fr",
        height: "calc(100vh - 72px)"
    }
};

export default styles;
