const styles = {
    card: {
        padding: "30px 20px 16px"
    },
    container: {
        display: "grid",
        gap: "18px 24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "62px 62px",
        height: "fit-content"
    },
    textField: {
        width: "100%"
    }
};

export default styles;
