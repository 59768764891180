// Modules
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CustomRadioButton } from "../../../../components";
// Styles
import styles from "./RadioFilter.css";

const NULL_VALUES = ["all", undefined, null];

function RadioFilter({
    getFilterValue,
    handleSetFilter,
    options,
    translationPath
}) {
    if (!options || options.length === 0) {
        return null;
    }

    return (
        <Fragment>
            {options.map((key, index) => {
                const filterValue = getFilterValue();
                const isChecked =
                    filterValue === key ||
                    (key === "all" && NULL_VALUES.includes(filterValue));

                return (
                    <CustomRadioButton
                        checked={isChecked}
                        checkmarkDefaultClassName={
                            isChecked ? "icon-check" : null
                        }
                        defaultClassName={"filter-classification-radio-button"}
                        key={index}
                        label={get(translationPath, key, undefined)}
                        onChange={() => {
                            handleSetFilter(key);
                        }}
                        style={styles.radio}
                    />
                );
            })}
        </Fragment>
    );
}

RadioFilter.propTypes = {
    getFilterValue: PropTypes.func.isRequired,
    handleSetFilter: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    translationPath: PropTypes.object.isRequired
};

export default RadioFilter;
