// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./LoginSignup.css";

class LoginSignup extends PureComponent {
    static propTypes = {
        action: PropTypes.node.isRequired,
        backButton: PropTypes.node,
        children: PropTypes.node.isRequired,
        errorMessage: PropTypes.node,
        errorMessagePosition: PropTypes.oneOf(["center", "top"]),
        footer: PropTypes.node,
        logo: PropTypes.node,
        title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        style: PropTypes.shape({
            childrenContainer: PropTypes.object,
            container: PropTypes.object,
            contentContainer: PropTypes.object,
            footerContainer: PropTypes.object,
            logoContainer: PropTypes.object,
            subTitleContainer: PropTypes.object,
            titleContainer: PropTypes.object
        })
    };

    static defaultProps = {
        errorMessagePosition: "center"
    };

    renderForm = () => {
        const {
            action,
            backButton,
            children,
            errorMessage,
            errorMessagePosition,
            footer,
            logo,
            title,
            subTitle,
            style
        } = this.props;

        return (
            <div
                className="login-signup-container"
                style={{
                    ...styles.container,
                    ...get(style, "container", {}),
                    ...(backButton ? styles.noMargin : {})
                }}
            >
                {errorMessagePosition === "top" && errorMessage}
                <div
                    className="login-signup-content-container"
                    style={{
                        ...styles.contentContainer,
                        ...get(style, "contentContainer", {})
                    }}
                >
                    {logo && (
                        <div
                            style={{
                                ...styles.logoContainer,
                                ...get(style, "logoContainer", {})
                            }}
                        >
                            {logo}
                        </div>
                    )}
                    {title && (
                        <div
                            className="login-signup-title-container"
                            style={{
                                ...styles.titleContainer,
                                ...get(style, "titleContainer", {})
                            }}
                        >
                            {title}
                        </div>
                    )}
                    {subTitle && (
                        <div
                            className="login-signup-subtitle-container"
                            style={{
                                ...styles.subTitleContainer,
                                ...get(style, "subTitleContainer", {})
                            }}
                        >
                            {subTitle}
                        </div>
                    )}
                    {errorMessagePosition === "center" && errorMessage}
                    {children && (
                        <div
                            className="login-signup-children-container"
                            style={{
                                ...styles.inputsContainer,
                                ...get(style, "childrenContainer", {})
                            }}
                        >
                            {children}
                        </div>
                    )}
                </div>
                {action}
                {footer && (
                    <div
                        className="login-signup-footer-container"
                        style={{
                            ...styles.footerContainer,
                            ...get(style, "footerContainer", {})
                        }}
                    >
                        {footer}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { backButton, style } = this.props;

        return backButton ? (
            <div
                className="login-signup-top-container"
                style={{
                    ...styles.container,
                    ...get(style, "topContainer", {})
                }}
            >
                <div
                    className="login-signup-back-button"
                    style={{
                        ...styles.backButton,
                        ...get(style, "backButton", {})
                    }}
                >
                    {backButton}
                </div>
                {this.renderForm()}
            </div>
        ) : (
            this.renderForm()
        );
    }
}

export default LoginSignup;
