// Modules
import React from "react";
import PropTypes from "prop-types";
// Components
import { DetailsButton } from "../../../../components";
import { OfferDetailsHeader } from "../components/";
import { DetailsView } from "../../Offers/components/OfferDetails/components";
// Helpers
import { parseTitle as parseTitleHelper } from "../../../../services/htmlDataParsing";

/**
 * Sanitizes the offer title.
 *
 * @param {Node} title the title to parse
 */
export function parseTitle(title) {
    return parseTitleHelper({ title });
}

/**
 * Renders the details button.
 *
 * @param {Function} openDetailView on click action
 * @param {Object} style custom style
 */
export function renderDetailsButton({ openDetailView, style }) {
    renderDetailsButton.propTypes = {
        openDetailView: PropTypes.func,
        style: PropTypes.object
    };

    return <DetailsButton onClick={openDetailView} style={style} />;
}

/**
 * Opens the offer details view popup.
 *
 * @param {Object} param all function and the offer
 */
export function openOfferDetailsView({
    offer,
    openViewPopup,
    saveOfferDetails,
    scrollPosition,
    style
}) {
    const loadOffer = () => {
        saveOfferDetails && saveOfferDetails(offer);
    };
    loadOffer();

    openViewPopup &&
        openViewPopup({
            state: {
                loadOffer,
                offer,
                scrollPosition
            },
            header: <OfferDetailsHeader offer={offer} />,
            children: <DetailsView />,
            style
        });

    scrollToTopOfViewPopupBody();
}

/**
 * Scrolls to top of the view popup body.
 */
function scrollToTopOfViewPopupBody() {
    const bodyClassName = "view-popup-body";
    const element =
        bodyClassName && document.getElementsByClassName(bodyClassName)[0];
    element && element.scrollTo(0, 0);
}

/**
 * Get the scroll position for an element.
 *
 * @param {String} className the element class name
 * @return {Object} the scoll position data
 */
export function getScrollPosition(className = undefined) {
    const elementClassName = className || "dashboard-children-container";
    const element = document.getElementsByClassName(elementClassName)[0];
    const scrollPosition = element
        ? {
              element: elementClassName,
              top: element.scrollTop || 0,
              left: element.scrollLeft || 0
          }
        : undefined;

    return scrollPosition;
}
