import Global from "../../config/global.css";

const styles = {
    calendarIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px",
        marginLeft: "8px"
    },
    clearIcon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "16px"
    },
    container: {
        cursor: "pointer",
        position: "relative"
    },
    icon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px",
        marginLeft: "0px"
    },
    iconsContainer: {
        cursor: "default",
        display: "flex"
    },
    iconNavBarRight: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-start"
    },
    iconNavBarLeft: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end"
    },
    textField: {
        position: "absolute",
        width: "100%"
    },
    textFieldIconContainer: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        marginLeft: "8px"
    },
    selectField: {
        fontSize: "14px",
        color: Global.colors.darkSlateBlue,
        backgroundColor: "transparent",
        fontWeight: "bold",
        padding: "5px 10px",
        border: "1px solid #E8EAF0",
        borderRadius: "10px",
        cursor: "pointer"
    },
    optionField: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontweight: "bold",
        cursor: "pointer",
        padding: "5px 10px"
    }
};

export default styles;
