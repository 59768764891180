// Modules
import get from "lodash.get";
// Actions
import { endSpinner } from "../components/MaterialSpinner/MaterialSpinner.ducks";

export const CLEAR_PROFILE_DATA = "CLEAR_PROFILE_DATA";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";

export const defaultState = {
    role: undefined,
    entity_id: undefined
};

// Reducer
export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_PROFILE_DATA: {
            return Object.assign({}, defaultState);
        }
        case SET_PROFILE_DATA: {
            return Object.assign({}, state, {
                role: action.payload.role,
                entity_id: action.payload.entity_id
            });
        }
        default:
            return state;
    }
};

export const clearProfileData = () => {
    return {
        type: CLEAR_PROFILE_DATA
    };
};

export const getProfileData = () => {
    return async (dispatch, getState, getFirebase) => {
        const {
            firebase: { profile }
        } = getState();

        const roleId = get(profile, "role.id", undefined);
        const entityId = get(profile, "entity_id", undefined);

        if (roleId) {
            const firebase = getFirebase();
            const firestore = firebase.firestore();

            await firestore
                .collection("roles")
                .doc(roleId)
                .get()
                .then(doc => {
                    dispatch(
                        setProfileData({
                            role: doc.data(),
                            entity_id: entityId
                        })
                    );
                    dispatch(endSpinner());
                })
                .catch(() => {
                    dispatch(endSpinner());
                });
        } else {
            dispatch(endSpinner());
        }
    };
};

export const setProfileData = ({ role, entity_id }) => {
    return {
        type: SET_PROFILE_DATA,
        payload: { role, entity_id }
    };
};
