import Global from "../../config/global.css";

const styles = {
    childrenContainer: {
        display: "flex",
        flexDirection: "column"
    },
    childrenInnerContainer: {
        display: "grid",
        gridAutoRows: "64px",
        gridGap: "16px"
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        minHeight: "min-content"
    },
    logo: {
        height: "48px"
    },
    logoContainer: {
        marginBottom: "192px",
        maxWidth: "325px"
    },
    proceedButton: {
        minHeight: "48px",
        width: "238px"
    },
    proceedIcon: {
        color: Global.colors.white,
        fontSize: "20px"
    },
    resetPasswordContainer: {
        flex: 1,
        height: "unset",
        justifyItems: "center"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px",
        margin: "16px 0px 6px",
        maxWidth: "500px"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "30px"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
