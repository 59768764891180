// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
// Components
import { GeneralData } from "./components/";
// Actions
import {
    abortMetricsDataFetch,
    getMetricsData,
    setSelectedSupplier
} from "../../Dashboard.ducks";
// Helpers
import { getSectionsPermissions } from "../../helpers/dashboardPermissions";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./DashboardMetrics.css";

class DashboardMetrics extends PureComponent {
    static propTypes = {
        abortMetricsDataFetch: PropTypes.func.isRequired,
        dashboardData: PropTypes.object,
        getMetricsData: PropTypes.func.isRequired,
        userRole: PropTypes.object,
        setSelectedSupplier: PropTypes.func,
        selectedSupplier: PropTypes.object
    };

    state = {
        sectionsPermissions: {}
    };

    componentDidMount() {
        const { getMetricsData, userRole } = this.props;

        const sectionsPermissions = getSectionsPermissions(userRole);

        this.setState({
            sectionsPermissions
        });

        getMetricsData && getMetricsData(sectionsPermissions);
    }

    componentWillUnmount() {
        const { abortMetricsDataFetch } = this.props;

        abortMetricsDataFetch && abortMetricsDataFetch();
    }

    getDate = () => {
        const date = new Date(Date.now());
        const options = { month: "long", day: "numeric" };
        return new Intl.DateTimeFormat("de-DE", options).format(date);
    };

    handleSelectedSupplier = selectedSupplier => {
        const { setSelectedSupplier } = this.props;

        setSelectedSupplier && setSelectedSupplier(selectedSupplier);
    };

    render() {
        const { dashboardData } = this.props;
        const { sectionsPermissions } = this.state;
        return (
            <div style={styles.container}>
                <span style={styles.title}>
                    {translation.dashboard.topStatistics}
                </span>
                <div style={styles.innerContainer}>
                    {get(sectionsPermissions, "general", false) && (
                        <div style={styles.section.container}>
                            <div style={styles.section.titleContainer}>
                                <span style={styles.section.title}>
                                    {translation.dashboard.generalData}
                                </span>
                                <span style={styles.section.note}>
                                    {`${
                                        translation.dashboard.status
                                    }: ${this.getDate()}`}
                                </span>
                            </div>
                            <GeneralData
                                data={dashboardData}
                                sectionsPermissions={sectionsPermissions}
                                selectedSupplier={this.handleSelectedSupplier}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dashboardData: state.dashboard,
        userRole: state.profileData.role,
        selectedSupplier: state.dashboard.selectedSupplier
    };
};

const mapDispatchToProps = {
    abortMetricsDataFetch,
    getMetricsData,
    setSelectedSupplier
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMetrics);
