import React, { useState } from "react";
import PropTypes from "prop-types";
// Components
import { DropzoneArea } from "material-ui-dropzone";
import AddIcon from "@material-ui/icons/Add";
// Translations
import translation from "../../config/translation";
// Styles
import styles from "./MultiFileUpload.css";

function MultiFileUpload({
    customOnChange,
    acceptedFiles,
    filesLimit,
    initialFiles
}) {
    const [availableImage, setAvailableImage] = useState();
    const handleChange = image => {
        const file = image[0];
        if (file) {
            setAvailableImage(file);
        } else {
            setAvailableImage(false);
        }
        customOnChange && customOnChange(file);
    };

    return (
        <>
            <div style={styles.dropZone.label}>
                {translation.marketingMaterials.multiFileUpload.label}
            </div>
            <div style={styles.dropZone.selectedImage}>
                <img
                    style={styles.selectedImage.img}
                    src={initialFiles}
                    alt=""
                />
            </div>

            <DropzoneArea
                Icon={AddIcon}
                acceptedFiles={acceptedFiles}
                filesLimit={filesLimit}
                showAlerts={false}
                dropzoneText={
                    !availableImage
                        ? translation.marketingMaterials.multiFileUpload.text
                        : null
                }
                onChange={handleChange}
            />
            <div style={styles.dropZone.note}>
                {translation.marketingMaterials.multiFileUpload.note}
            </div>
        </>
    );
}

MultiFileUpload.propTypes = {
    acceptedFiles: PropTypes.array.isRequired,
    initialFiles: PropTypes.array.isRequired,
    filesLimit: PropTypes.number.isRequired,
    customOnChange: PropTypes.func
};

export default MultiFileUpload;
