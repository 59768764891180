// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { DashboardMenus, DashboardMetrics } from "./components";
// Helpers
import { hasPermission } from "./helpers/dashboardPermissions";

class Dashboard extends PureComponent {
    static propTypes = {
        userRole: PropTypes.object
    };

    render() {
        const { userRole } = this.props;
        const dashboardMenusPermission = hasPermission(userRole, "menus");

        return dashboardMenusPermission ? (
            <DashboardMenus />
        ) : (
            <DashboardMetrics />
        );
    }
}

const mapStateToProps = state => {
    return {
        userRole: state.profileData.role
    };
};

export default connect(mapStateToProps)(Dashboard);
