import Global from "../../config/global.css";

const styles = {
    container: {
        alignItems: "center",
        borderRadius: "4px",
        default: {
            backgroundColor: Global.colors.white
        },
        display: "flex",
        error: {
            backgroundColor: Global.colors.paleOrange
        },
        maxWidth: "400px",
        minHeight: "32px",
        padding: "12px 16px",
        success: {
            backgroundColor: Global.colors.paleGreen
        },
        warn: {
            backgroundColor: Global.colors.paleYellow
        },
        width: "fit-content"
    },
    icon: {
        fontSize: "15px",
        height: "15px",
        margin: "4px",
        width: "15px",
        success: {
            height: "24px",
            margin: "0px",
            width: "24px"
        }
    },
    innerContainer: {
        alignItems: "center",
        display: "grid",
        gap: "12px",
        gridAutoFlow: "column"
    },
    message: {
        color: Global.colors.darkSlateBlue,
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "normal",
        lineHeight: "21px"
    }
};

export default styles;
