// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
// Components
import { MaterialButton } from "../../../components/";
import SettingsForm from "./components/SettingsForm";
// Styles
import styles from "./Settings.css";
// Translations
import translation from "../../../config/translation";

class Settings extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired
    };

    state = {
        editMode: false
    };

    handleChangeEditMode = editMode => {
        this.setState({
            editMode
        });
    };

    renderActions = () => {
        const { dispatch } = this.props;
        const { editMode } = this.state;

        return editMode ? (
            <Fragment>
                <MaterialButton
                    customType="boldPrimary"
                    onClick={() => {
                        this.handleChangeEditMode(false);
                    }}
                    style={{ ...styles.button, ...styles.editCancelButton }}
                >
                    {translation.settings.cancel}
                </MaterialButton>
                <div style={styles.saveChangesButtonContainer}>
                    <MaterialButton
                        customType="boldPrimary"
                        onClick={() => {
                            dispatch(submit("settings"));
                        }}
                        style={styles.button}
                    >
                        {translation.settings.saveChanges}
                    </MaterialButton>
                </div>
            </Fragment>
        ) : (
            <MaterialButton
                customType="boldPrimary"
                onClick={() => {
                    this.handleChangeEditMode(true);
                }}
                style={{ ...styles.button, ...styles.editCancelButton }}
            >
                {translation.settings.edit}
            </MaterialButton>
        );
    };

    render() {
        const { editMode } = this.state;
        return (
            <SettingsForm
                actions={this.renderActions()}
                editMode={editMode}
                handleChangeEditMode={this.handleChangeEditMode}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({}, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Settings);
