// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { change, Field } from "redux-form";
// Components
import {
    CustomRadioButton,
    CustomTooltip
} from "../../../../../../../../../components/";
// Validators
import { required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./Status.css";

class Status extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        status: PropTypes.string,
        details: PropTypes.object
    };

    state = {
        status: this.props.status
    };

    statuses = ["approved", "draft"];

    setStatus = newStatus => {
        const { dispatch } = this.props;
        const { status } = this.state;

        newStatus !== status &&
            this.setState({ status: newStatus }, () => {
                dispatch(change("offerDetails", "status", newStatus));
            });
    };

    RadioButton =
        option =>
        ({ ...props }) => {
            const hasError = Boolean(
                get(props, "meta.touched", false) &&
                    get(props, "meta.error", false)
            );
            const { details } = this.props;
            const seminarsCount = get(details, "seminars", []).length;
            return (
                <Fragment>
                    <CustomRadioButton {...props} />
                    {seminarsCount > 0 && option == "draft" && (
                        <CustomTooltip
                            placement="right-end"
                            title={
                                translation.offers.editPopup.status[option]
                                    .disabledTooltip
                            }
                        >
                            <span
                                className={"icon-info-file"}
                                style={{
                                    ...styles.status.tooltipIcon.default,
                                    ...(hasError
                                        ? styles.status.tooltipIcon.error
                                        : {})
                                }}
                            />
                        </CustomTooltip>
                    )}
                </Fragment>
            );
        };

    render() {
        const { status } = this.state;
        const { details } = this.props;
        const seminarsCount = get(details, "seminars", []).length;

        return (
            <div style={styles.container}>
                <span style={styles.title}>
                    {`${translation.offers.editPopup.status.label}:`}
                </span>
                <div style={styles.innerContainer}>
                    {this.statuses.map((option, index) => {
                        const isChecked = status === option;

                        return (
                            <div key={index} style={styles.status.container}>
                                <Field
                                    component={this.RadioButton(option)}
                                    name={"status"}
                                    {...(seminarsCount >= 0
                                        ? null
                                        : { disabled: true })}
                                    props={{
                                        checked: isChecked,
                                        checkmarkDefaultClassName: isChecked
                                            ? "icon-check"
                                            : null,
                                        defaultClassName:
                                            "offer-status-radio-button",
                                        label: translation.offers.editPopup
                                            .status[option].label,
                                        onChange: () => {
                                            seminarsCount > 0 &&
                                            option == "draft"
                                                ? null
                                                : this.setStatus(option);
                                        },
                                        style: styles.status.radio
                                    }}
                                    validate={required}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);
