// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
// Form
import { change, Field } from "redux-form";
// Components
import { CustomRadioButton } from "../../../../../../../../../components/";
// Validators
import { required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./ContractStatus.css";

class ContractStatus extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        contractStatus: PropTypes.string
    };

    state = {
        contractStatus: this.props.contractStatus
    };

    contractStatusOptions = ["signed", "notSigned"];

    setContractStatus = newStatusValue => {
        const { dispatch } = this.props;
        const { taxType } = this.state;

        newStatusValue !== taxType &&
            this.setState({ contractStatus: newStatusValue }, () => {
                dispatch(
                    change(
                        "editLegalEntityMapping",
                        "contractStatus",
                        newStatusValue
                    )
                );
            });
    };

    RadioButton =
        () =>
        ({ ...props }) => {
            return <CustomRadioButton {...props} />;
        };

    render() {
        const { contractStatus } = this.state;

        return (
            <div style={styles.container}>
                <span style={styles.title}>
                    {`${translation.entitiesMapping.editPopup.contractStatus.contractStatus}:`}
                </span>
                <div style={styles.innerContainer}>
                    {this.contractStatusOptions.map((option, index) => {
                        const isChecked = contractStatus === option;

                        return (
                            <div
                                key={index}
                                style={styles.contractStatus.container}
                            >
                                <Field
                                    component={this.RadioButton()}
                                    name={"contractStatus"}
                                    props={{
                                        checked: isChecked,
                                        checkmarkDefaultClassName: isChecked
                                            ? "icon-check"
                                            : null,
                                        defaultClassName:
                                            "entityMapping-contractStatus-radio-button",
                                        label: translation.entitiesMapping
                                            .editPopup.contractStatus[option],
                                        onChange: () => {
                                            this.setContractStatus(option);
                                        },
                                        style: styles.contractStatus.radio
                                    }}
                                    validate={required}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({}, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(ContractStatus);
