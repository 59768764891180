// Modules
import get from "lodash.get";
// Api
import api from "../../../../../../../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../../../../../../../components/MaterialSpinner/MaterialSpinner.ducks";
// Translation
import translation from "../../../../../../../../../config/translation";

export const CLEAR_ENTITIES = "CLEAR_ENTITIES";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";
export const SAVE_ENTITIES = "SAVE_ENTITIES";
export const SAVE_LOCATIONS = "SAVE_LOCATIONS";

export const defaultState = {
    entities: undefined,
    locations: undefined
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_ENTITIES: {
            return Object.assign({}, state, {
                entities: defaultState.locations
            });
        }
        case CLEAR_LOCATIONS: {
            return Object.assign({}, state, {
                locations: defaultState.locations
            });
        }
        case SAVE_ENTITIES: {
            return Object.assign({}, state, {
                entities: action.payload
            });
        }
        case SAVE_LOCATIONS: {
            return Object.assign({}, state, {
                locations: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearEntities = () => {
    return {
        type: CLEAR_ENTITIES
    };
};

export const clearLocations = () => {
    return {
        type: CLEAR_LOCATIONS
    };
};

export const saveEntities = entities => {
    return {
        type: SAVE_ENTITIES,
        payload: entities
    };
};

export const saveLocations = locations => {
    return {
        type: SAVE_LOCATIONS,
        payload: locations
    };
};

export const getEntities = () => dispatch => {
    dispatch(clearEntities());

    api.get("entities")
        .then(response => {
            const data = get(response, "data", undefined);
            dispatch(saveEntities(data));
        })
        .catch(e => {
            console.log("Error calling getEntities: ", e);
        });
};

export const getLocations = () => dispatch => {
    dispatch(clearLocations());

    api.get("seminars/locations")
        .then(response => {
            const data = get(response, "data", undefined);
            dispatch(saveLocations(data));
        })
        .catch(e => {
            console.log("Error calling getLocations: ", e);
        });
};

export const upsertEvent =
    (opType, url, event, successCb, errorCb) => dispatch => {
        const apiOp = opType === "create" ? api.post : api.put;

        dispatch(startSpinner());

        apiOp(`offers/${url}/events`, event)
            .then(() => {
                successCb &&
                    successCb(() => {
                        dispatch(endSpinner());
                    });
            })
            .catch(e => {
                console.log("Error calling upsertEvent: ", e);

                let errorMessage = translation.events.editPopup.genericError;
                let errorType = "error";

                errorCb && errorCb(errorType, errorMessage);

                dispatch(endSpinner());
            });
    };
