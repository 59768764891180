// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./SearchBar.css";

class SearchBar extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        inputClassName: PropTypes.string,
        inputValue: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        style: PropTypes.shape({
            container: PropTypes.object,
            icon: PropTypes.object,
            input: PropTypes.object
        })
    };

    state = {
        isFocus: false,
        onHover: undefined,
        value: ""
    };

    handleOnChange = e => {
        const { onChange } = this.props;
        const { value } = e.target;

        if (onChange) {
            this.setState({ value }, onChange(value));
        } else {
            this.setState({ value });
        }
    };

    handleOnFocus = value => () => {
        this.setState({
            isFocus: value
        });
    };

    iconComponent = icon => {
        const { disabled, style } = this.props;
        const { value } = this.state;
        const { isFocus } = this.state;
        if (typeof icon === "string") {
            return (
                <span
                    className={icon}
                    style={{
                        ...styles.icon,
                        ...get(style, "icon", {}),
                        ...(isFocus ? styles.focus : {}),
                        ...(value && isFocus ? styles.selected : {}),
                        ...(disabled ? styles.disabled : {})
                    }}
                />
            );
        } else if (icon) {
            return (
                <div
                    style={{
                        ...styles.icon,
                        ...get(style, "icon", {}),
                        ...(isFocus ? styles.focus : {}),
                        ...(value && isFocus ? styles.selected : {}),
                        ...(disabled ? styles.disabled : {})
                    }}
                >
                    {icon}
                </div>
            );
        } else {
            return null;
        }
    };

    handleOnHover = () => {
        this.setState({ onHover: true });
    };

    handleOnBlur = () => {
        this.setState({ onHover: false });
    };

    render() {
        const {
            disabled,
            icon,
            inputClassName,
            inputValue,
            placeholder,
            style
        } = this.props;
        const { isFocus, onHover } = this.state;

        const inputClass = inputClassName ? `input ${inputClassName}` : "input";

        return (
            <div
                onMouseEnter={this.handleOnHover}
                onMouseLeave={this.handleOnBlur}
                style={{
                    ...styles.container,
                    ...get(style, "container", {}),
                    ...(onHover && !disabled ? styles.onHover : {}),
                    ...(isFocus ? styles.boxShadowForContainer : {}),
                    ...(disabled ? styles.disabled : {})
                }}
            >
                <input
                    className={inputClass}
                    disabled={disabled}
                    onChange={this.handleOnChange}
                    onFocus={this.handleOnFocus(true)}
                    onBlur={this.handleOnFocus(false)}
                    placeholder={placeholder}
                    style={{
                        ...styles.input,
                        ...get(style, "input", {}),
                        ...(disabled ? styles.disabled : {})
                    }}
                    value={inputValue}
                />
                {this.iconComponent(icon)}
            </div>
        );
    }
}

export default SearchBar;
