const styles = {
    container: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "minmax(608px, 60%)",
        gridTemplateRows: "62px min-content",
        padding: "32px 24px"
    }
};

export default styles;
