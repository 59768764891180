// Translation
import translation from "../../../../../../config/translation";
// Styles
import styles from "../../../UserManual.css";

export const data = [
    {
        index: 1,
        title: translation.userManual.subTopics.overview,
        description:
            translation.userManual.descriptions.offerManagement["1"]
                .description,
        image: require("../../../../../../images/Angebotsverwaltung_1.png")
            .default,
        captions: [
            {
                index: 1,
                title: translation.userManual.descriptions.offerManagement["1"]
                    .caption["1"].title,
                description:
                    translation.userManual.descriptions.offerManagement["1"]
                        .caption["1"].description
            },
            {
                index: 2,
                title: translation.userManual.descriptions.offerManagement["1"]
                    .caption["2"].title,
                description:
                    translation.userManual.descriptions.offerManagement["1"]
                        .caption["2"].description
            },
            {
                index: 3,
                title: translation.userManual.descriptions.offerManagement["1"]
                    .caption["3"].title,
                description:
                    translation.userManual.descriptions.offerManagement["1"]
                        .caption["3"].description
            },
            {
                index: 4,
                title: translation.userManual.descriptions.offerManagement["1"]
                    .caption["4"].title,
                description:
                    translation.userManual.descriptions.offerManagement["1"]
                        .caption["4"].description
            }
        ]
    },
    {
        index: 2,
        title: translation.userManual.subTopics.keywordTagging,
        description:
            translation.userManual.descriptions.offerManagement["2"]
                .description,
        image: require("../../../../../../images/Stichwortzuordnung_1.png")
            .default,
        styles: {
            section: {
                description: styles.details.content.text,
                innerContainer: styles.details.content.innerContainer
            }
        },
        sections: [
            {
                index: 1,
                template: ["title", "description", "image"],
                title: "",
                descriptions: [
                    translation.userManual.descriptions.offerManagement["2"]
                        .section["1"].description
                ],
                images: [
                    require("../../../../../../images/Stichwortzuordnung_2.png")
                        .default
                ]
            },
            {
                index: 2,
                template: ["title", "description", "image"],
                title: "",
                descriptions: [
                    translation.userManual.descriptions.offerManagement["2"]
                        .section["2"].description
                ],
                images: [
                    require("../../../../../../images/Stichwortzuordnung_3.png")
                        .default
                ]
            },
            {
                index: 3,
                template: ["title", "description", "image"],
                title: "",
                descriptions: [
                    translation.userManual.descriptions.offerManagement["2"]
                        .section["3"].description
                ],
                images: [
                    require("../../../../../../images/Stichwortzuordnung_4.png")
                        .default
                ]
            }
        ]
    },
    {
        index: 3,
        title: translation.userManual.subTopics.createNewOffer,
        description:
            translation.userManual.descriptions.offerManagement["3"]
                .description,
        image: require("../../../../../../images/Angebotsverwaltung_2.png")
            .default,
        styles: {
            section: {
                innerContainer: styles.details.content.innerContainer,
                title: { marginBottom: "-8px" }
            }
        },
        sections: [
            {
                index: 1,
                template: ["title", "description", "image"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["1"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["1"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_3.png")
                        .default
                ]
            },
            {
                index: 2,
                template: ["title", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["2"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["2"].description
                ]
            },
            {
                index: 3,
                template: ["title", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["3"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["3"].description
                ]
            },
            {
                index: 4,
                template: ["title", "image", "description", "image"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["4"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["4"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_20.png")
                        .default,
                    require("../../../../../../images/Angebotsverwaltung_21.png")
                        .default
                ]
            },
            {
                index: 5,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["5"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["5"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_22.png")
                        .default
                ]
            },
            {
                index: 6,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["6"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["6"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_4.png")
                        .default
                ]
            },
            {
                index: 7,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["7"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["7"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_5.png")
                        .default
                ]
            },
            {
                index: 8,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["8"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["8"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_6.png")
                        .default
                ]
            },
            {
                index: 9,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["9"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["9"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_7.png")
                        .default
                ]
            },
            {
                index: 10,
                template: ["title", "description"],
                title: translation.userManual.descriptions.offerManagement["3"]
                    .section["10"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["3"]
                        .section["10"].description
                ]
            }
        ]
    },
    {
        index: 4,
        title: translation.userManual.subTopics.offerClassification,
        description:
            translation.userManual.descriptions.offerManagement["4"]
                .description,
        image: require("../../../../../../images/Angebotsverwaltung_23.png")
            .default
    },
    {
        index: 5,
        title: translation.userManual.subTopics.offerDetails,
        description:
            translation.userManual.descriptions.offerManagement["5"]
                .description,
        image: require("../../../../../../images/Angebotsverwaltung_8.png")
            .default,
        sections: [
            {
                index: 1,
                template: ["description"],
                descriptions: [
                    translation.userManual.descriptions.offerManagement["5"]
                        .section["1"].description
                ]
            }
        ]
    },
    {
        index: 6,
        title: translation.userManual.subTopics.editOffer,
        description:
            translation.userManual.descriptions.offerManagement["6"].description
    },
    {
        index: 7,
        title: translation.userManual.subTopics.createEvents,
        image: require("../../../../../../images/Angebotsverwaltung_9.png")
            .default,
        sections: [
            {
                index: 1,
                template: ["title", "description"],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["1"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["1"].description
                ]
            },
            {
                index: 2,
                template: ["title", "description"],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["2"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["2"].description
                ]
            },
            {
                index: 3,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["3"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["3"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_10.png")
                        .default
                ]
            },
            {
                index: 4,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["4"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["4"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_11.png")
                        .default
                ]
            },
            {
                index: 5,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["5"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["5"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_12.png")
                        .default
                ]
            },
            {
                index: 6,
                template: ["title", "image", "description"],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["6"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["6"].description
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_13.png")
                        .default
                ]
            },
            {
                index: 7,
                template: [
                    "title",
                    "image",
                    "description",
                    "image",
                    "description"
                ],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["7"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["7"].descriptions["0"],
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["7"].descriptions["1"]
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_14.png")
                        .default,
                    require("../../../../../../images/Angebotsverwaltung_15.png")
                        .default
                ]
            },
            {
                index: 8,
                template: [
                    "title",
                    "image",
                    "description",
                    "image",
                    "description",
                    "image",
                    "description"
                ],
                title: translation.userManual.descriptions.offerManagement["7"]
                    .section["8"].title,
                descriptions: [
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["8"].descriptions["0"],
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["8"].descriptions["1"],
                    translation.userManual.descriptions.offerManagement["7"]
                        .section["8"].descriptions["2"]
                ],
                images: [
                    require("../../../../../../images/Angebotsverwaltung_16.png")
                        .default,
                    require("../../../../../../images/Angebotsverwaltung_17.png")
                        .default,
                    require("../../../../../../images/Angebotsverwaltung_18.png")
                        .default
                ]
            }
        ]
    },
    {
        index: 8,
        title: translation.userManual.subTopics.editOrDeleteEvents,
        image: require("../../../../../../images/Angebotsverwaltung_19.png")
            .default,
        sections: [
            {
                index: 1,
                template: ["description"],
                descriptions: [
                    translation.userManual.descriptions.offerManagement["8"]
                        .section["1"].description
                ]
            }
        ]
    }
];
