// Modules
import React from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./Card.css";

function Card({ children, style }) {
    return (
        <div
            style={{
                ...styles.container,
                ...style
            }}
        >
            {children}
        </div>
    );
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object
};

export default Card;
