import { firebaseApp } from "../../../../services/firebase";

var storage = firebaseApp.storage();

/**
 * Gets the storage download url for a file.
 *
 * @param {String} path the file storage path
 */
export function getFromStorage(path) {
    if (!path) {
        return null;
    }

    return storage.ref(path).getDownloadURL();
}

/**
 * Checks if the image is in storage and if yes returns the download url.
 *
 * @param {String} name the image name
 * @param {Function} cb the fuction to execute with the result
 * @param {String} pathPrefix the path prefix
 */
export async function isValidImagePath(name, cb, pathPrefix = "") {
    const imagePath = `${pathPrefix}/images/${name}`;
    return getFromStorage(`${imagePath}.png`)
        .then(result => {
            cb && cb(result);
        })
        .catch(() => {
            return getFromStorage(`${imagePath}.jpg`)
                .then(result => {
                    cb && cb(result);
                })
                .catch(() => {
                    return getFromStorage(`${imagePath}.jpeg`)
                        .then(result => {
                            cb && cb(result);
                        })
                        .catch(() => {
                            // Do nothing
                        });
                });
        });
}

/**
 * Checks if the media object is in storage and if yes returns the download url.
 *
 * @param {String} name the media object name
 * @param {String} format the media object format
 * @param {Function} cb the fuction to execute with the result
 * @param {String} pathPrefix the path prefix
 */
export function isValidMediaPath(name, format, cb, pathPrefix) {
    const mediaName = `${name}.${format}`;
    return getFromStorage(`${pathPrefix}/${mediaName}`)
        .then(result => {
            cb && cb(result);
        })
        .catch(() => {
            // Do nothing
        });
}

/**
 * Upload a file to a specific location in storage.
 *
 * @param {Object} file the file to upload
 * @param {String} fileName the file name
 * @param {String} path the path in storage where the file should be uploaded
 */
export function uploadFile(file, fileName) {
    const storageRef = firebaseApp.storage().ref();

    const fileRef = storageRef.child(`marketing/${fileName}`);

    fileRef.put(file).catch(e => {
        console.log(`Error uploading file ${fileName} to storage: `, e);
    });
}

/**
 * Deletes a file from storage.
 *
 * @param {String} path the path in storage where the file should be uploaded
 * @param {String} fileName the file name
 */
export function deleteFile(fileName) {
    const storageRef = firebaseApp.storage().ref();

    const fileRef = storageRef.child(`marketing/${fileName}`);

    fileRef.delete().catch(e => {
        console.log(`Error deleting file ${fileName}: `, e);
    });
}
