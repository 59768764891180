// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endAllSpinners,
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
// Styles
import styles from "./Dashboard.css";

export const CLEAR_ALL_DATA = "dashboard/CLEAR_ALL_DATA";
export const CLEAR_CHATBOT_DATA = "dashboard/CLEAR_CHATBOT_DATA";
export const CLEAR_GENERAL_DATA = "dashboard/CLEAR_GENERAL_DATA";
export const SAVE_CHATBOT_KEYWORDS = "dashboard/SAVE_CHATBOT_KEYWORDS";
export const SAVE_CHATBOT_LOCATIONS = "dashboard/SAVE_CHATBOT_LOCATIONS";
export const SAVE_CHATBOT_OFFERS = "dashboard/SAVE_CHATBOT_OFFERS";
export const SAVE_EVENTS_COUNT = "dashboard/SAVE_EVENTS_COUNT";
export const SAVE_TOTAL_EVENTS_COUNT = "dashboard/SAVE_TOTAL_EVENTS_COUNT";
export const SAVE_LOCATIONS_COUNT = "dashboard/SAVE_LOCATIONS_COUNT";
export const SAVE_NATIONWIDE_EVENTS_COUNT =
    "dashboard/SAVE_NATIONWIDE_EVENTS_COUNT";
export const SAVE_NATIONWIDE_LOCATIONS_COUNT =
    "dashboard/SAVE_NATIONWIDE_LOCATIONS_COUNT";
export const SAVE_NATIONWIDE_OFFERS_COUNT =
    "dashboard/SAVE_NATIONWIDE_OFFERS_COUNT";
export const SAVE_OFFERS_BY_SUPPLIERS = "dashboard/SAVE_OFFERS_BY_SUPPLIERS";
export const SAVE_OFFERS_COUNT = "dashboard/SAVE_OFFERS_COUNT";
export const SAVE_REGIONAL_EVENTS_COUNT =
    "dashboard/SAVE_REGIONAL_EVENTS_COUNT";
export const SAVE_REGIONAL_LOCATIONS_COUNT =
    "dashboard/SAVE_REGIONAL_LOCATIONS_COUNT";
export const SAVE_REGIONAL_OFFERS_COUNT =
    "dashboard/SAVE_REGIONAL_OFFERS_COUNT";
export const SAVE_SUPPLIERS_BY_OFFERS = "dashboard/SAVE_SUPPLIERS_BY_OFFERS";
export const SET_ABORTED = "dashboard/SET_ABORTED";
export const SET_LAST_CHATBOT_DATA_FETCH_DATE =
    "dashboard/SET_LAST_CHATBOT_DATA_FETCH_DATE";
export const SET_SELECTED_SUPPLIER = "dashboard/SET_SELECTED_SUPPLIER";

export const defaultState = {
    aborted: false,
    selectedSupplier: null,
    chatbotKeywords: undefined,
    chatbotLocations: undefined,
    chatbotOffers: undefined,
    eventsCount: undefined,
    totalEventsCount: undefined,
    lastChatbotDataFetchDate: undefined,
    locationsCount: undefined,
    nationwideEventsCount: undefined,
    nationwideLocationsCount: undefined,
    nationwideOffersCount: undefined,
    offersBySuppliers: undefined,
    offersCount: undefined,
    regionalEventsCount: undefined,
    regionalLocationsCount: undefined,
    regionalOffersCount: undefined,
    suppliersByOffers: undefined
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_ALL_DATA: {
            return Object.assign({}, state, defaultState);
        }
        case CLEAR_CHATBOT_DATA: {
            return Object.assign({}, state, {
                chatbotKeywords: defaultState.chatbotKeywords,
                chatbotLocations: defaultState.chatbotLocations,
                chatbotOffers: defaultState.chatbotOffers,
                lastChatbotDataFetchDate: defaultState.lastChatbotDataFetchDate
            });
        }
        case CLEAR_GENERAL_DATA: {
            return Object.assign({}, defaultState, {
                chatbotKeywords: state.chatbotKeywords,
                chatbotLocations: state.chatbotLocations,
                chatbotOffers: state.chatbotOffers,
                lastChatbotDataFetchDate: state.lastChatbotDataFetchDate
            });
        }
        case SAVE_CHATBOT_KEYWORDS: {
            return Object.assign({}, state, {
                chatbotKeywords: action.payload
            });
        }
        case SAVE_CHATBOT_LOCATIONS: {
            return Object.assign({}, state, {
                chatbotLocations: action.payload
            });
        }
        case SAVE_CHATBOT_OFFERS: {
            return Object.assign({}, state, {
                chatbotOffers: action.payload
            });
        }
        case SAVE_EVENTS_COUNT: {
            return Object.assign({}, state, {
                eventsCount: action.payload
            });
        }
        case SAVE_TOTAL_EVENTS_COUNT: {
            return Object.assign({}, state, {
                totalEventsCount: action.payload
            });
        }
        case SAVE_LOCATIONS_COUNT: {
            return Object.assign({}, state, {
                locationsCount: action.payload
            });
        }
        case SAVE_NATIONWIDE_EVENTS_COUNT: {
            return Object.assign({}, state, {
                nationwideEventsCount: action.payload
            });
        }
        case SAVE_NATIONWIDE_LOCATIONS_COUNT: {
            return Object.assign({}, state, {
                nationwideLocationsCount: action.payload
            });
        }
        case SAVE_NATIONWIDE_OFFERS_COUNT: {
            return Object.assign({}, state, {
                nationwideOffersCount: action.payload
            });
        }
        case SAVE_OFFERS_BY_SUPPLIERS: {
            return Object.assign({}, state, {
                offersBySuppliers: action.payload
            });
        }
        case SAVE_OFFERS_COUNT: {
            return Object.assign({}, state, {
                offersCount: action.payload
            });
        }
        case SAVE_REGIONAL_EVENTS_COUNT: {
            return Object.assign({}, state, {
                regionalEventsCount: action.payload
            });
        }
        case SAVE_REGIONAL_LOCATIONS_COUNT: {
            return Object.assign({}, state, {
                regionalLocationsCount: action.payload
            });
        }
        case SAVE_REGIONAL_OFFERS_COUNT: {
            return Object.assign({}, state, {
                regionalOffersCount: action.payload
            });
        }
        case SAVE_SUPPLIERS_BY_OFFERS: {
            return Object.assign({}, state, {
                suppliersByOffers: action.payload
            });
        }
        case SET_ABORTED: {
            return Object.assign({}, state, {
                aborted: action.payload
            });
        }
        case SET_LAST_CHATBOT_DATA_FETCH_DATE: {
            return Object.assign({}, state, {
                lastChatbotDataFetchDate: action.payload
            });
        }
        case SET_SELECTED_SUPPLIER: {
            return Object.assign({}, state, {
                selectedSupplier: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearAllData = () => {
    return {
        type: CLEAR_ALL_DATA
    };
};

export const clearChatbotData = () => {
    return {
        type: CLEAR_CHATBOT_DATA
    };
};

export const clearGeneralData = () => {
    return {
        type: CLEAR_GENERAL_DATA
    };
};

export const saveData = (action, data) => {
    return {
        type: action,
        payload: data
    };
};

export const setAborted = aborted => {
    return {
        type: SET_ABORTED,
        payload: aborted
    };
};

export const setSelectedSupplier = selectedSupplier => {
    return {
        type: SET_SELECTED_SUPPLIER,
        payload: selectedSupplier
    };
};

export const setLastChatbotDataFetchDate = date => {
    return {
        type: SET_LAST_CHATBOT_DATA_FETCH_DATE,
        payload: date
    };
};

export const abortMetricsDataFetch = () => (dispatch, getState) => {
    dispatch(setAborted(true));
    get(getState(), "spinner.loading", []).length > 0 &&
        dispatch(endAllSpinners());
};

const getCurrentDate = () => {
    let currentDate = new Date(Date.now());
    currentDate.setHours(0, 0, 0, 0);

    return currentDate;
};

export const getMetricsData = sections => (dispatch, getState) => {
    const NATION_WIDE = "nationwide";
    const REGIONAL = "regional";
    const APPROVED = "approved";

    const {
        dashboard: {
            chatbotKeywords,
            chatbotLocations,
            chatbotOffers,
            lastChatbotDataFetchDate
        }
    } = getState();

    const currentDate = getCurrentDate();

    const shouldFetchChatbotData =
        get(sections, "chatbot", false) &&
        (!chatbotLocations ||
            !chatbotOffers ||
            !chatbotKeywords ||
            !lastChatbotDataFetchDate ||
            lastChatbotDataFetchDate < currentDate);

    if (shouldFetchChatbotData) {
        dispatch(clearAllData());
    } else {
        dispatch(clearGeneralData());
    }

    if (get(sections, "generalTotals", false)) {
        dispatch(
            getOffersCount({
                classification: NATION_WIDE,
                status: APPROVED,
                successCb: data => saveData(SAVE_NATIONWIDE_OFFERS_COUNT, data)
            })
        );
        dispatch(
            getOffersCount({
                classification: REGIONAL,
                status: APPROVED,
                successCb: data => saveData(SAVE_REGIONAL_OFFERS_COUNT, data)
            })
        );
        dispatch(
            getOffersCount({
                status: APPROVED,
                successCb: data => saveData(SAVE_OFFERS_COUNT, data)
            })
        );
        dispatch(
            getEventsCount({
                classification: NATION_WIDE,
                status: APPROVED,
                successCb: data =>
                    saveData(SAVE_NATIONWIDE_EVENTS_COUNT, data.events)
            })
        );
        dispatch(
            getEventsCount({
                classification: REGIONAL,
                status: APPROVED,
                successCb: data =>
                    saveData(SAVE_REGIONAL_EVENTS_COUNT, data.events)
            })
        );
        dispatch(
            getEventsCount({
                status: APPROVED,
                successCb: data => saveData(SAVE_EVENTS_COUNT, data.events)
            })
        );
        dispatch(
            getEventsCount({
                status: APPROVED,
                successCb: data =>
                    saveData(SAVE_TOTAL_EVENTS_COUNT, data.total_events)
            })
        );
        dispatch(
            getLocationsCount({
                classification: NATION_WIDE,
                status: APPROVED,
                successCb: data =>
                    saveData(SAVE_NATIONWIDE_LOCATIONS_COUNT, data)
            })
        );
        dispatch(
            getLocationsCount({
                classification: REGIONAL,
                status: APPROVED,
                successCb: data => saveData(SAVE_REGIONAL_LOCATIONS_COUNT, data)
            })
        );
        dispatch(
            getLocationsCount({
                status: APPROVED,
                successCb: data => saveData(SAVE_LOCATIONS_COUNT, data)
            })
        );
    }

    if (get(sections, "generalTops", false)) {
        dispatch(
            getSuppliersByOffers({
                status: APPROVED,
                top: 10,
                successCb: data => saveData(SAVE_SUPPLIERS_BY_OFFERS, data)
            })
        );
        dispatch(
            getOffersBySuppliers({
                status: APPROVED,
                top: 10,
                successCb: data => saveData(SAVE_OFFERS_BY_SUPPLIERS, data)
            })
        );
    }

    if (shouldFetchChatbotData) {
        dispatch(
            getChatbotLocation({
                days: 30,
                top: 10,
                successCb: data => saveData(SAVE_CHATBOT_LOCATIONS, data)
            })
        );

        dispatch(
            getChatbotOffers({
                days: 30,
                top: 10,
                successCb: data => saveData(SAVE_CHATBOT_OFFERS, data)
            })
        );

        dispatch(
            getChatbotKeywords({
                days: 30,
                successCb: data => saveData(SAVE_CHATBOT_KEYWORDS, data)
            })
        );

        dispatch(setLastChatbotDataFetchDate(currentDate));
    }
};

export const getOffersCount =
    ({ classification, status, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() &&
            dispatch(startSpinner({ style: styles.spinnerContainer }));

        const params = {
            classification,
            status
        };

        api.get("metrics/offers/count", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOffersCount: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getEventsCount =
    ({ classification, status, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            classification,
            status
        };

        api.get("metrics/events/count", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getEventsCount: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getLocationsCount =
    ({ classification, status, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            classification,
            status
        };

        api.get("metrics/locations/count", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getLocationsCount: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getSuppliersByOffers =
    ({ classification, status, top, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            classification,
            status,
            top
        };

        api.get("metrics/suppliers_by_offers", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getSuppliersByOffers: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getOffersBySuppliers =
    ({ classification, status, top, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            classification,
            status,
            top
        };

        api.get("metrics/offers_by_suppliers", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOffersBySuppliers: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getChatbotLocation =
    ({ days, top, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            days,
            top
        };

        api.get("metrics/bot_locations", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getChatbotLocation: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getChatbotOffers =
    ({ days, top, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            days,
            top
        };

        api.get("metrics/bot_offers", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getChatbotOffers: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };

export const getChatbotKeywords =
    ({ days, successCb }) =>
    (dispatch, getState) => {
        const isAborted = () => get(getState(), "dashboard.aborted", false);

        !isAborted() && dispatch(startSpinner());

        const params = {
            days
        };

        api.get("metrics/bot_keywords_matching", params)
            .then(response => {
                successCb &&
                    dispatch(successCb(get(response, "data", undefined)));

                !isAborted() && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getChatbotKeywords: ", e);
                !isAborted() && dispatch(endSpinner());
            });
    };
