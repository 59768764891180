// Modules
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CustomTooltip, IconButton, TableRow } from "../../../../../components";
// Helpers
import { hasPermission } from "../../helpers/chambersLocationsPermissions";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./OnlineKeywordsRow.css";

const OnlineKeywordsRow = ({ deletePopup, containerStyle, entry }) => {
    const profileData = useSelector(state => state.profileData);
    const [tooltipOpened, setTooltipOpened] = useState(undefined);

    const handleTooltipOnHover = (tooltip, onHover) => {
        setTooltipOpened(onHover ? tooltip : undefined);
    };
    const customEditIconButton = (handleTooltipOnHover, deletePopup) => {
        return (
            <IconButton
                defaultClassName="icon-delete"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover &&
                        handleTooltipOnHover(undefined, false);
                    deletePopup && deletePopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    };

    const renderActionButtons = () => {
        const userRole = get(profileData, "role", {});
        const tooltip = "editTooltip";

        return (
            <div
                className="onlin-keywords-row-actions"
                style={styles.iconsContainer}
            >
                {hasPermission(userRole, "delete") && (
                    <CustomTooltip
                        onClose={() => handleTooltipOnHover(tooltip, false)}
                        onOpen={() => handleTooltipOnHover(tooltip, true)}
                        open={tooltipOpened === tooltip}
                        placement="top"
                        title={translation.labels.delete}
                    >
                        <span>
                            {customEditIconButton(
                                handleTooltipOnHover,
                                deletePopup
                            )}
                        </span>
                    </CustomTooltip>
                )}
            </div>
        );
    };

    const renderRow = row => {
        return (
            <>
                <div style={styles.onlineKeywordsContainer}>
                    {get(row, "keyword", undefined)}
                </div>

                {renderActionButtons()}
            </>
        );
    };
    return (
        <TableRow
            containerStyle={{
                ...containerStyle,
                ...styles.rowContainer
            }}
        >
            {renderRow(entry)}
        </TableRow>
    );
};

OnlineKeywordsRow.propTypes = {
    containerStyle: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    deletePopup: PropTypes.func.isRequired
};

export default OnlineKeywordsRow;
