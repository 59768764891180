import Global from "../../../config/global.css";

const styles = {
    addButtonContainer: {
        container: {
            backgroundColor: Global.colors.blue
        }
    },
    addButtonIcon: {
        color: Global.colors.white,
        fontSize: "24px"
    },
    container: {
        padding: "24px 24px 32px"
    },
    editPopup: {
        body: { height: "calc(100vh - 306px)" },
        container: {
            gridTemplateRows: "72px auto",
            height: "unset",
            top: "162px"
        }
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "24px"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row"
    },
    searchBarContainer: {
        width: "381px"
    },
    searchBarInput: {
        backgroundColor: Global.colors.white
    },
    tabs: {
        container: {
            background: Global.colors.white,
            borderRadius: "12px",
            height: "64px",
            margin: "0px 24px 24px 0px",
            minHeight: "64px",
            width: "fit-content"
        },
        icon: {
            container: {
                default: {
                    alignItems: "center",
                    backgroundColor: "rgb(255, 197, 93, 0.4)",
                    borderRadius: "100px",
                    display: "flex",
                    height: "32px",
                    justifyContent: "center",
                    margin: "0px 16px 0px 0px",
                    width: "32px"
                },
                selected: {
                    backgroundColor: "inherit"
                }
            },
            icon: {
                default: {
                    color: Global.colors.darkSlateBlue,
                    borderRadius: "100px",
                    fontSize: "24px"
                },
                selected: {
                    color: Global.colors.white
                }
            }
        },
        indicator: {
            visibility: "hidden"
        }
    },
    tabsContainer: {
        display: "grid",
        gap: "16px",
        gridAutoFlow: "column"
    },
    tabDefault: {
        borderRadius: "12px",
        color: Global.colors.darkSlateBlue,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "bold",
        height: "64px",
        lineHeight: "15px",
        maxHeight: "64px",
        minHeight: "64px",
        opacity: "unset",
        padding: "16px",
        textTransform: "unset",
        width: "204px",

        "&:hover": {
            backgroundColor: Global.colors.digitalBlueT4,
            color: Global.colors.darkSlateBlue,

            "&:not(.Mui-selected) .tab-icon": {
                backgroundColor: `${Global.colors.digitalBlueT4} !important`
            }
        }
    },
    tabSelected: {
        backgroundColor: `${Global.colors.digitalBlue} !important`,
        color: `${Global.colors.white} !important`
    },
    alignRight: {
        justifyContent: "flex-end"
    },
    tabWrapper: {
        flexDirection: "row",
        justifyContent: "start",
        textAlign: "start"
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "minmax(150px, 35%)  minmax(300px, 65%) 80px",
        padding: "12px 24px"
    },
    tableHeaders: {
        marginTop: "16px"
    },
    topbarContainer: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr auto"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
