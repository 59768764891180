import Global from "../../config/global.css";

const styles = {
    container: {
        width: "240px"
    },
    outlined: {
        root: {
            "& label": {
                color: Global.colors.darkSlateBlue,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "normal",
                letterSpacing: "unset",
                lineHeight: "18px",
                "&.Mui-disabled": {
                    color: Global.colors.darkPaleGrey
                },
                "&.Mui-error": {
                    color: `${Global.colors.melon} !important`
                },
                "&.Mui-focused": {
                    color: Global.colors.darkSlateBlue
                }
            },
            "& .MuiFormHelperText-contained": {
                margin: "0px 12px"
            },
            "& .MuiFormHelperText-root": {
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "normal",
                letterSpacing: "unset",
                lineHeight: "13px",
                "&.Mui-error": {
                    color: Global.colors.melon
                }
            },
            "& .MuiInputBase-input": {
                color: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "normal",
                letterSpacing: "unset",
                lineHeight: "18px",
                "&.Mui-disabled": {
                    color: Global.colors.darkPaleGrey
                }
            },
            "& .MuiInputBase-root": {
                color: Global.colors.darkSlateBlue,
                height: "48px"
            },
            "& .MuiInputBase-multiline": {
                height: "unset !important",
                padding: "8px 4px 8px 0px"
            },
            "& .MuiInputLabel-outlined": {
                transform: "translate(12px, 15px) scale(1)",
                "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.72)"
                }
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-disabled": {
                    borderColor: Global.colors.darkPaleGrey,
                    borderWidth: "1px"
                },
                "&.Mui-error": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: Global.colors.melon,
                        borderWidth: "1px"
                    }
                },
                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: Global.colors.darkSlateBlue,
                        borderWidth: "1px"
                    }
                }
            },
            "& .MuiOutlinedInput-input": {
                padding: "15px 10px"
            },
            "& .MuiOutlinedInput-inputMultiline": {
                padding: "7px 10px !important"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: Global.colors.darkSlateBlue,
                borderRadius: "8px",
                borderWidth: "1px",
                paddingLeft: "11px !important",
                top: "-6px",

                "& legend": {
                    height: "13px !important"
                },

                "& legend span": {
                    fontSize: "0.64rem !important",
                    paddingLeft: "1px !important",
                    paddingRight: "2px !important"
                }
            },
            "& .MuiInputAdornment-root": {
                "& .MuiTypography-colorTextSecondary": {
                    fontSize: "14px",
                    color: Global.colors.darkPaleGrey
                }
            }
        }
    },
    standard: {
        root: {
            "& label": {
                color: Global.colors.darkSlateBlue,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "normal",
                letterSpacing: "unset",
                lineHeight: "18px",
                "&.Mui-disabled": {
                    color: Global.colors.darkPaleGrey
                },
                "&.Mui-error": {
                    color: `${Global.colors.melon} !important`
                },
                "&.Mui-focused": {
                    color: Global.colors.darkSlateBlue
                }
            },
            "& label + .MuiInput-formControl": {
                height: "48px",
                marginTop: "unset"
            },
            "& .MuiFormHelperText-root": {
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "normal",
                letterSpacing: "unset",
                lineHeight: "13px",
                marginTop: "unset",
                "&.Mui-error": {
                    color: Global.colors.melon
                }
            },
            "& .MuiInputBase-root": {
                color: Global.colors.darkSlateBlue,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "normal",
                letterSpacing: "unset",
                lineHeight: "18px",
                "&.Mui-disabled": {
                    color: Global.colors.darkPaleGrey
                }
            },
            "& .MuiInputBase-input": {
                padding: "20px 0px 10px"
            },
            "& .MuiInputAdornment-root": {
                "& .MuiTypography-colorTextSecondary": {
                    fontSize: "14px",
                    color: Global.colors.darkPaleGrey
                }
            }
        }
    }
};

export default styles;
