import Global from "../../../../../../../../../config/global.css";

const styles = {
    button: {
        alignItems: "center",
        borderRadius: "100px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        height: "24px",
        width: "24px"
    },
    buttonActive: {
        backgroundColor: Global.colors.paleGrey
    },
    container: {
        display: "flex",
        position: "relative"
    },
    icon: {
        color: Global.colors.blue,
        fontSize: "10px"
    },
    innerContainer: {
        color: Global.colors.darkSlateBlue,
        height: "calc(100% - 38px)",
        margin: "12px",
        position: "absolute",
        width: "calc(100% - 16px)"
    },
    disabled: {
        pointerEvents: "none"
    },
    div: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "18px"
    },
    editor: {
        height: "100%",
        maxHeight: "calc(100% - 24px)",
        paddingRight: "16px",
        overflowY: "auto"
    },
    h3: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "30px",
        margin: "4px 0px"
    },
    h4: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "23px",
        margin: "8px 0px"
    },
    li: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "22px"
    },
    p: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "18px",
        margin: "8px 0px"
    },
    span: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "18px",
        margin: "8px 0px"
    },
    ul: {
        margin: "8px 0px 16px",
        paddingInlineStart: "20px"
    },
    label: {
        color: Global.colors.blue,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "21px"
    },
    textField: {
        width: "100%",
        inputRoot: {
            height: "calc(100% - 13px)"
        }
    },
    toolbar: {
        display: "grid",
        gap: "8px",
        gridAutoFlow: "column",
        width: "fit-content"
    }
};

export default styles;
