import Global from "../../config/global.css";

const styles = {
    onHover: {
        background: Global.colors.white,
        borderRadius: "8px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)"
    }
};

export default styles;
