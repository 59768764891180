import Global from "../../../../../../config/global.css";

const styles = {
    button: {
        container: {
            active: {
                backgroundColor: Global.colors.darkPaleGrey
            },
            onHover: {
                backgroundColor: Global.colors.darkPaleGrey
            },
            container: {
                backgroundColor: Global.colors.darkPaleGrey
            },
            bottom: "20px",
            cursor: "default",
            height: "30px",
            minHeight: "30px",
            position: "absolute",
            right: "20px",
            width: "30px"
        },
        icon: {
            color: Global.colors.white,
            fontSize: "20px"
        }
    },
    eventData: {
        container: {
            display: "grid",
            gap: "8px",
            height: "fit-content",
            marginTop: "16px"
        },
        icon: {
            color: "inherit",
            fontSize: "24px"
        },
        innerContainer: {
            alignItems: "center",
            display: "grid",
            gap: "8px",
            gridAutoFlow: "column",
            gridTemplateColumns: "auto 1fr",
            height: "fit-content"
        },
        label: {
            display: "flex",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "normal",
            hyphens: "auto",
            WebkitHyphens: "auto",
            wordBreak: "break-word"
        },
        labelEllipsis: {
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical"
        }
    },
    icon: {
        fontSize: "16px",
        marginRight: "8px"
    },
    item: {
        display: "flex",
        backgroundColor: Global.colors.paleGrey,
        borderRadius: "8px",
        flexDirection: "column",
        height: "268px",
        overflow: "hidden",
        position: "relative",
        width: "272px"
    },
    itemHover: {
        backgroundColor: `${Global.colors.darkSlateBlue} !important`,
        color: `${Global.colors.white} !important`,
        height: "max-content",
        minHeight: "268px",
        transition:
            "max-height 1s ease-in, background-color 1s ease-in, color 1s ease-in",
        zIndex: 1
    },
    itemVisible: {
        backgroundColor: Global.colors.white,
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.16)",
        color: Global.colors.darkSlateBlue,
        hyphens: "auto",
        WebkitHyphens: "auto",
        padding: "24px 20px",
        wordBreak: "break-word",
        textAlign: "start"
    },
    subtitle: {
        color: Global.colors.blueyGrey,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginTop: "8px"
    },
    subtitleEllipsis: {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical"
    },
    title: {
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "26px"
    },
    titleEllipsis: {
        display: "-webkit-box",
        maxHeight: "80px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical"
    },
    titleWithoutShortDescription: {
        display: "-webkit-box",
        maxHeight: "unset",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 6,
        WebkitBoxOrient: "vertical"
    }
};

export default styles;
