// Modules
import get from "lodash.get";
// Helpers
import { routesPaths } from "../../Routes/Routes.helper";
import {
    hasMainPermission,
    routesMainPermissionsMapping
} from "../../../../../services/permissions";

/**
 * The available menus.
 */
export const MENUS = [
    {
        icon: "icon-home",
        key: "dashboard",
        path: routesPaths.dashboard
    },
    {
        icon: "icon-user-management",
        key: "userManagement",
        path: routesPaths.users
    },
    // {
    //     icon: "icon-chat-interaction",
    //     key: "chatInteraction",
    //     path: routesPaths.chat
    // },
    {
        icon: "icon-suppliers",
        key: "entitiesMappingManagement",
        path: routesPaths.mapping,
        subMenus: [
            {
                icon: "icon-list",
                key: "coopPartnersMapping",
                path: routesPaths.coopPartnersMapping
            },
            {
                icon: "icon-list",
                key: "entitiesMapping",
                path: routesPaths.entitiesMapping
            },
            {
                icon: "icon-list",
                key: "entitiesUploads",
                path: routesPaths.entitiesUploadsTracking
            },
            {
                icon: "icon-mapping",
                key: "unmappedEntities",
                path: routesPaths.unmappedEntities
            }
        ]
    },
    {
        icon: "icon-offers",
        key: "offerManagement",
        path: routesPaths.offers,
        subMenus: [
            {
                icon: "icon-list",
                key: "offersOverview",
                path: routesPaths.offersOverview
            },
            {
                icon: "icon-xml-upload",
                key: "offersFileUpload",
                path: routesPaths.offersFileUpload
            },
            {
                icon: "icon-mapping",
                key: "offersClassification",
                path: routesPaths.offersClassification
            },
            {
                icon: "icon-bot-training",
                key: "offersKeywordsTagging",
                path: routesPaths.offersKeywordsTagging
            },
            {
                icon: "icon-location",
                key: "chambersLocations",
                path: routesPaths.chambersLocations
            }
        ]
    },
    {
        icon: "icon-invoicing",
        key: "invoicing",
        path: routesPaths.invoicing
    },
    {
        icon: "icon-ads",
        key: "marketingMaterials",
        path: routesPaths.marketingMaterials
    },
    {
        icon: "icon-ihk-badges",
        key: "badges",
        path: routesPaths.badges
    }
];

/**
 * The user manual menu props.
 */
export const USER_MANUAL_MENU = {
    icon: "icon-user-manual",
    key: "userManual",
    path: routesPaths.userManual
};

/**
 * Gets the menus available for the user based on its role permissions.
 *
 * @param {Object} userRole the user role data
 * @returns a list of available menus for the user
 */
export function getMenusBasedOnRolePermissions(userRole) {
    return MENUS.filter(menu =>
        hasMainPermission(
            get(userRole, "permissions", {}),
            get(routesMainPermissionsMapping, menu.path, undefined)
        )
    );
}

/**
 * Checks if the user has permissions to access the user manual menu.
 *
 * @param {Object} userRole the user role data
 * @returns true if has access to user manual, false otherwise
 */
export function hasUserManualPermission(userRole) {
    return hasMainPermission(
        get(userRole, "permissions", {}),
        get(routesMainPermissionsMapping, routesPaths.userManual, undefined)
    );
}
