// Modules
import get from "lodash.get";
// Helpers
import {
    getSpecificRegexPermission,
    hasSpecificPermission
} from "../../../../services/permissions";

/**
 * Checks dashboard permission.
 *
 * @param {Object} userRole the role permissions
 * @param {String} permission the permission to validate
 * @returns true if has permission, false otherwise
 */
export function hasPermission(userRole, permission) {
    return hasSpecificPermission(
        get(userRole, "permissions", {}),
        "dashboard",
        permission
    );
}

/**
 * Gets the list of dashboard permissions matching a regex.
 *
 * @param {Object} profileData the user profile data
 * @param {String} regexPermission the permission in a regex
 * @returns list of permissions matching regex
 */
export function getRegexPermission(userRole, regexPermission) {
    const permissions =
        getSpecificRegexPermission(
            get(userRole, "permissions", {}),
            "dashboard",
            regexPermission
        ) || [];

    return permissions.map(p => p.replace(`${regexPermission}_`, ""));
}

/**
 * Checks dashboard section permission.
 *
 * @param {Object} userRole the role permissions
 * @param {String} section the section to check if has permission
 * @param {String} subSection the sub-section to check if has permission
 * @returns true if has permission, false otherwise
 */
export function hasSectionPermission(
    userRole,
    section,
    subSection = undefined
) {
    const mainPermissions = getRegexPermission(userRole, "metrics");

    if (!mainPermissions || mainPermissions.length === 0) {
        return false;
    }

    if (mainPermissions.includes("all")) {
        return true;
    }

    const sectionPermissions = getRegexPermission(
        userRole,
        `metrics_${section}`
    );

    if (!sectionPermissions || sectionPermissions.length === 0) {
        return false;
    }

    if (sectionPermissions.includes("all") || !subSection) {
        return true;
    }

    return sectionPermissions.includes(subSection);
}

/**
 * Gets the dashboard sections permissions.
 *
 * @param {Object} userRole the role permissions
 * @returns an object with the permission per section
 */
export function getSectionsPermissions(userRole) {
    const GENERAL_DATA = "general";

    const hasGeneralDataPermission = hasSectionPermission(
        userRole,
        GENERAL_DATA
    );
    const hasGeneralDataTotalsPermission = hasSectionPermission(
        userRole,
        GENERAL_DATA,
        "totals"
    );
    const hasGeneralDataTopsPermission = hasSectionPermission(
        userRole,
        GENERAL_DATA,
        "tops"
    );
    const hasChatbotPermission = hasSectionPermission(userRole, "chatbot");

    return {
        chatbot: hasChatbotPermission,
        general: hasGeneralDataPermission,
        generalTops: hasGeneralDataTopsPermission,
        generalTotals: hasGeneralDataTotalsPermission
    };
}
