import Global from "../../../../config/global.css";

const styles = {
    classifications: {
        nationwide: {
            backgroundColor: "rgba(95, 153, 255, 0.1)",
            border: `1px solid ${Global.colors.digitalBlue}`,
            color: Global.colors.digitalBlue
        },
        regional: {
            backgroundColor: "rgba(127, 93, 255, 0.1)",
            border: `1px solid ${Global.colors.intergalacticPurple}`,
            color: Global.colors.intergalacticPurple
        }
    },
    container: {
        borderRadius: "4px",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        hyphens: "auto",
        WebkitHyphens: "auto",
        minHeight: "24px",
        padding: "4.5px 7px",
        width: "fit-content",
        wordBreak: "break-word"
    }
};

export default styles;
