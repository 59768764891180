// Modules
import { push, replace } from "connected-react-router";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../components/MaterialSpinner/MaterialSpinner.ducks";
import { clearProfileData } from "../ProfileData.ducks";

export const logout = () => {
    return async (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();

        dispatch(startSpinner());
        try {
            await firebase.logout();
            dispatch(replace("/login"));
            dispatch(clearProfileData());
            dispatch(endSpinner());
        } catch (error) {
            dispatch(endSpinner());
        }
    };
};

export const navigateTo = (path, state = {}) => {
    return (dispatch, getState) => {
        const {
            router: { location }
        } = getState();

        if (path !== location.pathname) {
            dispatch(push(path, { ...location, ...state }));
        }
    };
};
