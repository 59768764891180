import Global from "../../../../../../../../../config/global.css";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "24px 0px"
    },
    header: {
        backIcon: {
            fontSize: "24px",
            height: "24px",
            width: "24px"
        },
        breadcrums: {
            color: Global.colors.blueyGrey,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "13px",
            textTransform: "uppercase"
        },
        container: {
            alignItems: "center",
            display: "grid",
            gap: "16px",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr auto",
            minHeight: "48px",
            padding: "0px 24px"
        },
        iconsContainer: {
            display: "grid",
            gap: "16px",
            gridAutoFlow: "column"
        },
        innerContainer: {
            display: "grid",
            gap: "8px"
        },
        title: {
            alignItems: "center",
            color: Global.colors.darkSlateBlue,
            display: "grid",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "23px",
            gap: "16px",
            gridAutoFlow: "column",
            gridAutoColumns: "auto 1fr"
        }
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        padding: "0px 24px 16px"
    },
    unmergePopup: {
        body: {
            alignItems: "flex-start",
            display: "grid",
            gap: "32px",
            gridTemplateRows: "min-content auto",
            marginBottom: "32px",
            minHeight: "332px"
        },
        bodyInnerContainer: {
            marginRight: "-34px"
        },
        container: {
            container: {
                padding: "32px 38px",
                width: "766px"
            },
            subtitle: {
                marginBottom: "0px",
                marginTop: "24px"
            },
            title: {
                marginRight: "24px",
                marginTop: "0px"
            }
        },
        row: {
            color: Global.colors.darkSlateBlue,
            display: "flex",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "21px"
        },
        rows: {
            display: "grid",
            gridAutoFlow: "row",
            height: "unset",
            minHeight: "44px",
            textAlign: "left"
        },
        rowsContainer: {
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            maxHeight: "288px"
        },
        tableGrid: {
            alignItems: "center",
            display: "grid",
            height: "44px",
            gap: "24px",
            gridTemplateColumns:
                "minmax(150px, 25%) minmax(75px, 20%) minmax(150px, 1fr)",
            padding: "0px 24px"
        }
    },
    tooltip: {
        backgroundColor: "#0034AC",
        color: Global.colors.white
    }
};

export default styles;
