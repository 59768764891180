// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CircularProgress } from "@material-ui/core";
// Styles
import { withStyles } from "@material-ui/core/styles";
import styles from "./MaterialCircularProgress.css";

const CssMaterialCircularProgress = (style, linearGradientId) => {
    let customStyle = { ...styles };

    customStyle.circleIndeterminate.stroke = `url(#${linearGradientId})`;

    const circleCustomStyle = get(style, "circle", undefined);
    if (circleCustomStyle) {
        customStyle.circle = circleCustomStyle;
    }

    return withStyles(customStyle)(CircularProgress);
};

class MaterialCircularProgress extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        linearGradientId: PropTypes.string.isRequired,
        size: PropTypes.number,
        style: PropTypes.shape({
            circle: PropTypes.object
        }),
        thickness: PropTypes.number
    };

    render() {
        const { linearGradientId, classes, size, style, thickness } =
            this.props;

        const StyledMaterialCircularProgress = CssMaterialCircularProgress(
            style,
            linearGradientId
        );

        return (
            <StyledMaterialCircularProgress
                classes={{
                    circle: classes.circle,
                    circleIndeterminate: classes.circleIndeterminate,
                    indeterminate: classes.indeterminate
                }}
                size={size}
                thickness={thickness}
            />
        );
    }
}

export default withStyles(styles)(MaterialCircularProgress);
