const colors = {
    bgGrey: "#F7F8FC",
    black: "#000000",
    blue: "#0041D9",
    blueyGrey: "#8B95B1",
    darkPaleGrey: "#C1C7D7",
    darkSlateBlue: "#23366C",
    digitalBlue: "#5F99FF",
    digitalBlueT0: "#5785D6",
    digitalBlueT4: "#E6EDF9",
    green: "#77C99C",
    intergalacticPurple: "#7F5DFF",
    leo: "#FFC55D",
    lightGreen: "#D2EEB5",
    lightLeo: "#F8E5C5",
    lighterSlateBlue: "#F4F5F8",
    lightPaleGrey: "#F1F2F7",
    melon: "#FF7959",
    oldMint: "#77C99C",
    oliveGreen: "#8CBF59",
    paleGreen: "#E8F2DE",
    paleGrey: "#E8EAF0",
    paleOrange: "#FFE8D8",
    palePetrol: "#13698D",
    paleYellow: "#FFF9E1",
    peppermint: "#56A1A4",
    pinkology: "#FF5D81",
    summerHeat: "#FF905D",
    toastedYellow: "#FFD946",
    white: "#FFFFFF"
};

const muiTheme = {
    typography: {
        fontFamily: "Muli",
        useNextVariants: true
    },
    overrides: {
        MuiCheckbox: {
            root: {
                padding: "0px"
            }
        },
        MuiAccordion: {
            root: {
                backgroundColor: "unset",
                boxShadow: "none",
                "&:before": {
                    height: "0px",
                    visibility: "hidden"
                },
                "&$disabled": {
                    backgroundColor: "unset"
                },
                "&$expanded": {
                    margin: "0px"
                }
            }
        },
        MuiAccordionDetails: {
            root: {
                padding: 0,
                marginTop: "8px"
            }
        },
        MuiAccordionSummary: {
            content: {
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "normal",
                lineHeight: "23px",
                margin: "0px !important"
            },
            expandIcon: {
                color: colors.darkSlateBlue,
                marginRight: 0,
                padding: 0,
                "&$disabled": {
                    color: colors.darkPaleGrey
                }
            },
            root: {
                color: colors.darkSlateBlue,
                direction: "rtl",
                display: "grid",
                justifyContent: "end",
                gridAutoFlow: "column",
                gap: "0px 8px",
                height: "24px",
                minHeight: "24px",
                padding: 0,
                "&$disabled": {
                    color: colors.darkPaleGrey,
                    opacity: "unset"
                },
                "&$expanded": {
                    height: "24px",
                    minHeight: "24px"
                }
            }
        },
        MuiSnackbarContent: {
            message: {
                padding: "0px"
            }
        },
        MuiDropzoneArea: {
            root: {
                display: "flex",
                border: "2px dashed",
                borderColor: colors.darkSlateBlue,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-15px"
            },
            text: {
                fontSize: "18px",
                color: colors.darkSlateBlue
            },
            icon: {
                color: colors.darkSlateBlue,
                width: "20px",
                height: "auto"
            }
        },
        MuiFab: {
            root: {
                color: colors.white,
                backgroundColor: colors.oliveGreen
            }
        },
        MuiGrid: {
            container: {
                width: "100%!important"
            }
        }
    }
};

export default { colors, muiTheme };
