// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { Tab, Tabs } from "@material-ui/core";
import { DetailsView, Events } from "../";
// Actions
import { setOfferDetailsTab } from "../../OfferDetails.ducks";
// Translation
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./OfferDetailsTabs.css";

class OfferDetailsTabs extends PureComponent {
    static propTypes = {
        handleOpenEventDetailsView: PropTypes.func,
        setOfferDetailsTab: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired
    };

    tabs = [
        {
            key: "details",
            label: translation.offerDetails.tabs.details,
            contentComponent: DetailsView
        },
        {
            key: "events",
            label: translation.offerDetails.tabs.events,
            contentComponent: Events
        }
    ];

    handleTabChange = (event, newValue) => {
        const { setOfferDetailsTab } = this.props;

        setOfferDetailsTab && setOfferDetailsTab(newValue);
    };

    render() {
        const { handleOpenEventDetailsView, tab } = this.props;

        return (
            <div style={styles.container}>
                <Tabs
                    onChange={this.handleTabChange}
                    scrollButtons="off"
                    TabIndicatorProps={{
                        style: styles.tabs.indicator
                    }}
                    style={styles.tabs.container}
                    value={tab}
                >
                    {this.tabs.map((tabObj, index) => (
                        <Tab
                            key={index}
                            label={tabObj.label}
                            style={{
                                ...styles.tabs.tab,
                                ...(tab === tabObj.key
                                    ? styles.tabs.tabSelected
                                    : {})
                            }}
                            value={tabObj.key}
                        />
                    ))}
                </Tabs>
                {this.tabs.map(tabObj => {
                    const Comp = tabObj.contentComponent;
                    return tab === tabObj.key ? (
                        <Comp
                            key={tabObj.key}
                            handleOpenEventDetailsView={
                                handleOpenEventDetailsView
                            }
                        />
                    ) : null;
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tab: state.offerDetails.tab
    };
};

const mapDispatchToProps = {
    setOfferDetailsTab
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsTabs);
