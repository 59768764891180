// Modules
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
// Components
import { EventsRow } from "../";
// Translations
import translation from "../../../../config/translation";
// Styles
import styles from "./Seminars.css";

class Seminars extends PureComponent {
    static propTypes = {
        elements: PropTypes.array.isRequired,
        filters: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
        user_query: PropTypes.string.isRequired
    };

    getUserQuery = () => {
        const { user_query } = this.props;

        return user_query;
    };

    prepareSeminarsData = () => {
        const { elements, filters } = this.props;

        let seminars = [];

        elements &&
            elements.forEach(element => {
                let events = [...element.seminars];
                events.push({
                    start_date: "Alle",
                    location: {
                        name: "Alle"
                    }
                });

                seminars.push({
                    filters,
                    shortDescription: element.short_description,
                    title: element.title,
                    displayTitle: element.display_title,
                    url: element.url,
                    events
                });
            });

        return seminars;
    };

    render() {
        const seminars = this.prepareSeminarsData();

        return (
            <div className="chatbot-seminars" style={styles.container}>
                {seminars && seminars.length > 0 ? (
                    <Fragment>
                        <div style={styles.title}>
                            {translation.chatbot.feedbackTitle.toUpperCase()}
                        </div>
                        <EventsRow
                            containerStyle={styles.seminarsRowContainer}
                            gridStyle={styles.seminarsGrid}
                            seminars={seminars}
                        />
                    </Fragment>
                ) : (
                    <div style={{ ...styles.title, ...styles.noSeminars }}>
                        {translation.chatbot.noSeminars}
                    </div>
                )}
            </div>
        );
    }
}

export default Seminars;
