// Modules
import get from "lodash.get";

/**
 * Performs a match with the search query in the Legal Entity name and Supplier.
 *
 * @param {String} searchQuery the search query
 * @param {Array} data the list to filter
 * @returns the list of uploads filtered by search query match
 */
export function getFilteredLegalEntitiesBySearchQuery(searchQuery, data) {
    let filteredUploads = [];

    if (searchQuery?.value) {
        const filter = searchQuery.value;

        if (data[0].legal_entities) {
            const suppliersWithLegalEntities = data.filter(
                ({ legal_entities }) => legal_entities.length > 0
            );

            const filteredData = suppliersWithLegalEntities.map(supplier => {
                let legal_entities = [];

                if (filter === "signed") {
                    legal_entities = supplier.legal_entities.filter(
                        ({ contracted_suppliers }) =>
                            contracted_suppliers?.includes(supplier._id)
                    );
                }

                if (filter === "notSigned") {
                    legal_entities = supplier.legal_entities.filter(
                        ({ contracted_suppliers }) =>
                            !contracted_suppliers?.includes(supplier._id)
                    );
                }

                if (filter === "all") {
                    return supplier;
                }

                return { ...supplier, legal_entities };
            });

            return filteredData;
        } else {
            const legalEntitiesWithSuppliers = data.filter(
                legalEntity => legalEntity.suppliers_data?.length > 0
            );
            const filteredData = legalEntitiesWithSuppliers.map(legalEntity => {
                let suppliers = [];
                let suppliers_data = [];

                if (filter === "signed") {
                    suppliers = legalEntity.suppliers.filter(supplier => {
                        return legalEntity.contracted_suppliers?.includes(
                            supplier
                        );
                    });

                    suppliers_data = legalEntity.suppliers_data.filter(
                        ({ _id }) => suppliers.includes(_id)
                    );
                }

                if (filter === "notSigned") {
                    suppliers = legalEntity.suppliers.filter(
                        supplier =>
                            !legalEntity.contracted_suppliers?.includes(
                                supplier
                            )
                    );
                    suppliers_data = legalEntity.suppliers_data.filter(
                        ({ _id }) => suppliers.includes(_id)
                    );
                }

                if (filter === "all") {
                    return legalEntity;
                }

                return { ...legalEntity, suppliers, suppliers_data };
            });
            return filteredData;
        }
    }
    if (!searchQuery || searchQuery.length === 0) {
        filteredUploads = data;
    } else if (data && data.length > 0) {
        const regularExp = new RegExp(`.*${searchQuery}.*`, "i");

        data.forEach(upload => {
            if (get(upload, "name", "").search(regularExp) >= 0) {
                filteredUploads.push(upload);
            } else {
                const matchedSuppliers = (
                    get(upload, "suppliers_data", undefined) ||
                    get(upload, "suppliers", [])
                ).filter(
                    supplier =>
                        get(supplier, "name", "").search(regularExp) >= 0
                );
                if (matchedSuppliers && matchedSuppliers.length > 0) {
                    let newUpload = { ...upload };
                    newUpload.suppliers = matchedSuppliers;
                    filteredUploads.push(newUpload);
                }
            }
        });
    }
    return filteredUploads || [];
}
