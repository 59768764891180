// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// Components
import { InputAdornment, TextField } from "@material-ui/core";
// Styles
import styles from "./MaterialTextField.css";
// Helpers
import { formatStyle } from "./helpers/customStyleHelper";

const CssTextField = (variant, multiline, style) => {
    let customStyle = formatStyle(variant, multiline, styles, style);

    return withStyles(customStyle)(TextField);
};

class MaterialTextField extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        disabled: PropTypes.bool,
        endAdornment: PropTypes.node,
        error: PropTypes.bool,
        errorMessage: PropTypes.string,
        errorMessageStyle: PropTypes.object,
        input: PropTypes.object,
        InputProps: PropTypes.object,
        label: PropTypes.string.isRequired,
        labelStyle: PropTypes.object,
        meta: PropTypes.object,
        multiline: PropTypes.bool,
        onChange: PropTypes.func,
        select: PropTypes.bool,
        startAdornment: PropTypes.node,
        style: PropTypes.shape({
            disabled: PropTypes.object,
            error: PropTypes.shape({
                input: PropTypes.object,
                label: PropTypes.object
            }),
            focused: PropTypes.object,
            focusedInput: PropTypes.object,
            helperText: PropTypes.object,
            input: PropTypes.object,
            inputRoot: PropTypes.object,
            label: PropTypes.object,
            labelShrinked: PropTypes.object
        }),
        timeoutLoadFonts: PropTypes.number,
        type: PropTypes.oneOf(["email", "number", "password", "text"]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        variant: PropTypes.oneOf(["outlined", "standard"])
    };
    static defaultProps = {
        timeoutLoadFonts: 100,
        type: "text",
        variant: "standard"
    };

    state = {
        fontsLoaded: false
    };

    componentDidMount() {
        const { timeoutLoadFonts } = this.props;
        setTimeout(() => {
            this.setState({ fontsLoaded: true });
        }, timeoutLoadFonts);
    }

    StyledTextField = CssTextField(
        this.props.variant,
        this.props.multiline,
        this.props.style
    );

    render() {
        const {
            children,
            disabled,
            endAdornment,
            error,
            errorMessage,
            errorMessageStyle,
            input,
            InputProps,
            label,
            labelStyle,
            meta,
            onChange,
            select,
            startAdornment,
            style,
            // eslint-disable-next-line no-unused-vars
            timeoutLoadFonts,
            type,
            variant,
            ...props
        } = this.props;
        const { fontsLoaded } = this.state;

        if (!fontsLoaded) {
            return null;
        }

        let customInputProps = {
            endAdornment: endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
            startAdornment: startAdornment && (
                <InputAdornment position="start">
                    {startAdornment}
                </InputAdornment>
            ),
            ...InputProps
        };
        variant === "standard" && (customInputProps["disableUnderline"] = true);

        const hasError = error || Boolean(meta && meta.touched && meta.error);
        const helperTextErrorMessage =
            errorMessage || (meta && meta.touched && meta.error);

        return (
            <this.StyledTextField
                disabled={disabled}
                error={hasError}
                FormHelperTextProps={{
                    style: errorMessageStyle
                }}
                helperText={hasError && helperTextErrorMessage}
                InputLabelProps={{
                    ref: ref => {
                        if (!this.labelRef) {
                            this.labelRef = ref;
                            this.forceUpdate();
                        }
                    },
                    style: labelStyle
                }}
                InputProps={customInputProps}
                label={label}
                onChange={onChange && onChange}
                select={select}
                style={{
                    ...styles.container,
                    ...style
                }}
                type={type}
                variant={variant}
                {...input}
                {...props}
            >
                {children}
            </this.StyledTextField>
        );
    }
}

export default MaterialTextField;
