// Modules
import React, { PureComponent } from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
// Components
import { CustomSelect } from "../";
// Translations
import translation from "../../config/translation";
// Styles
import styles from "./Filter.css";

class Filter extends PureComponent {
    static propTypes = {
        defaultOption: PropTypes.object,
        isClearable: PropTypes.bool,
        options: PropTypes.array,
        onSelection: PropTypes.func,
        style: PropTypes.object
    };

    state = {
        value: null
    };

    componentDidMount() {
        const { defaultOption } = this.props;

        this.handleSetValue(defaultOption);
    }

    handleSetValue = value => {
        this.setState({ value });
    };

    ClearIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;

        return (
            <div
                {...restInnerProps}
                style={styles.clearIcon.container}
                ref={ref}
            >
                <span className="icon-close" style={styles.clearIcon.icon} />
            </div>
        );
    };

    DropdownIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;

        return (
            <div {...restInnerProps} style={styles.iconContainer} ref={ref}>
                <span className={"icon-filters"} style={styles.icon} />
            </div>
        );
    };

    render() {
        const { isClearable, options, onSelection, style, ...props } =
            this.props;
        const { value } = this.state;

        return (
            <CustomSelect
                backspaceRemovesValue={isClearable}
                containerStyle={{
                    ...styles.container,
                    ...get(style, "container", {})
                }}
                controlStyle={{
                    ...styles.control,
                    ...get(style, "control", {})
                }}
                customComponents={{
                    ClearIndicator: this.ClearIndicator,
                    DropdownIndicator: this.DropdownIndicator
                }}
                isClearable={isClearable}
                inputStyle={{ ...styles.input, ...get(style, "input", {}) }}
                menuContainerStyle={get(style, "menu", {})}
                menuListContainerStyle={{
                    ...styles.menuList,
                    ...get(style, "menuList", {})
                }}
                noOptionsMessage={translation.labels.noOptions}
                onSelection={newValue => {
                    if (newValue !== value) {
                        this.handleSetValue(newValue);
                        onSelection && onSelection(newValue);
                    }
                }}
                optionFocusedStyle={{
                    ...styles.optionFocused,
                    ...get(style, "optionFocused", {})
                }}
                options={options}
                optionStyle={{ ...styles.option, ...get(style, "option", {}) }}
                singleValueStyle={{
                    ...styles.singleValue,
                    ...get(style, "singleValue", {})
                }}
                valueContainerStyle={{
                    ...styles.valueContainer,
                    ...(isClearable ? styles.valueContainerWithClear : {}),
                    ...get(style, "valueContainer", {})
                }}
                value={value || (options && options[0])}
                {...props}
            />
        );
    }
}
export default Filter;
