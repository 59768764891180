// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

class FileInput extends PureComponent {
    static propTypes = {
        accept: PropTypes.string.isRequired,
        customOnChange: PropTypes.func,
        input: PropTypes.object.isRequired,
        inputRef: PropTypes.func.isRequired,
        style: PropTypes.object
    };

    handleOnChange = event => {
        const {
            customOnChange,
            input: { onChange }
        } = this.props;

        const file = get(event, "target.files[0]", undefined);

        onChange && onChange(file);
        customOnChange && customOnChange(file);
    };

    render() {
        const {
            accept,
            input: { name },
            inputRef,
            style
        } = this.props;

        return (
            <input
                accept={accept}
                name={name}
                ref={ref => {
                    inputRef(ref);
                }}
                style={style}
                type="file"
                onChange={this.handleOnChange}
                onClick={event => {
                    event && event.target && (event.target.value = "");
                }}
            />
        );
    }
}

export default FileInput;
