import Global from "../../../config/global.css";

const styles = {
    container: {
        padding: "24px 24px 32px"
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "24px"
    },
    lastUpload: {
        justifyContent: "flex-end"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row"
    },
    searchBarContainer: {
        width: "381px"
    },
    searchBarInput: {
        backgroundColor: Global.colors.white
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "minmax(200px, 35%) minmax(520px, 65%)",
        padding: "20px 24px"
    },
    tableHeaders: {
        gridTemplateColumns:
            "minmax(200px, 35%) minmax(250px, 50%) minmax(150px, 15%) 64px",
        marginTop: "16px",
        padding: "12px 24px"
    },
    topbarContainer: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr auto"
    }
};

export default styles;
