import Global from "../../config/global.css";

const styles = {
    icon: {
        alignItems: "center",
        backgroundColor: Global.colors.darkSlateBlue,
        borderRadius: "100px",
        color: Global.colors.white,
        cursor: "pointer",
        display: "flex",
        fontSize: "24px",
        height: "32px",
        justifyContent: "center",
        width: "32px"
    }
};

export default styles;
