//Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import get from "lodash.get";
//Actions
import { closeEditPopup } from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import { openSnackbar } from "../../../../../components/CustomSnackBar/CustomSnackBar.ducks";

import { addOnlineKeywords } from "../../ChambersLocations.ducks";
// Helpers
import { doesValueExist } from "../../helpers/chamberLocationsFormHelper";
//Components
import { WarningPopup, MaterialTextField } from "../../../../../components";
// Validators
import { required } from "../../../../../services/validators";
// Translations
import translation from "../../../../../config/translation";
//Styles
import styles from "./OnlineKeywordsDetails.css";

function onSubmit(values, props) {
    const { addOnlineKeywords, closeEditPopup, onlineKeywords, openSnackbar } =
        props;
    let data = {
        keyword: get(values, "keyword", undefined)
    };

    const keywordExists = doesValueExist(data, onlineKeywords);

    if (keywordExists) {
        const message = translation.chambersLocations.errors.errorExists;

        openSnackbar && openSnackbar(message, "warn");
        return;
    }

    addOnlineKeywords &&
        addOnlineKeywords(
            data,
            () => {
                closeEditPopup();
            },
            (error, errorType) => {
                onError(error, errorType, props);
            }
        );
}

function onError(error, errorType, props) {
    const { closePopup, openPopup } = props;
    console.log("onError", get(error, "title", undefined));
    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.chambersLocations.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={
                    errorType === "warn"
                        ? styles.warningPopupImage
                        : styles.warningErrorPopupImage
                }
                subTitle={get(error, "description", undefined)}
                title={get(error, "title", undefined)}
            />
        )
    });
}

class OnlineKeywordsDetails extends PureComponent {
    static propTypes = {
        onlineKeywords: PropTypes.array,
        openSnackbar: PropTypes.func.isRequired
    };
    render() {
        return (
            <form style={styles.container}>
                <Field
                    component={MaterialTextField}
                    label={
                        translation.chambersLocations.editPopup.onlineKeyword
                    }
                    name="keyword"
                    style={styles.textField}
                    variant="outlined"
                    validate={required}
                />
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        onlineKeywords: state.chambersLocations.onlineKeywords
    };
};

const mapDispatchToProps = {
    addOnlineKeywords,
    closeEditPopup,
    closePopup,
    openPopup,
    openSnackbar
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "onlineKeywords",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(OnlineKeywordsDetails)
);
