// Modules
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// Components
import { GroupCard } from "../../../";
import {
    IconButton,
    MaterialTextField
} from "../../../../../../../../../components";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import { link, required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Helpers
import { createEmptyAdditionalLink } from "../../../../../../helpers/offerDetailsFormatHelper";
// Styles
import styles from "./AdditionalLinks.css";

class AdditionalLinks extends PureComponent {
    static propTypes = {
        classes: PropTypes.object
    };

    renderLinks = ({ fields }) => {
        const { classes } = this.props;

        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.container}>
                                    <span style={styles.label}>{`${
                                        translation.offers.editPopup
                                            .additionalLinks
                                    } ${index + 1}`}</span>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                                <div style={styles.container}>
                                    <Field
                                        component={MaterialTextField}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldInput
                                            }
                                        }}
                                        label={
                                            translation.offers.editPopup.title
                                        }
                                        name={`${item}.title`}
                                        style={styles.textField}
                                        variant="outlined"
                                        validate={required}
                                    />

                                    <Field
                                        component={MaterialTextField}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldInput
                                            }
                                        }}
                                        label={translation.offers.editPopup.url}
                                        name={`${item}.content`}
                                        placeholder={
                                            translation.offers.editPopup.url
                                        }
                                        style={styles.textField}
                                        variant="outlined"
                                        validate={[required, link]}
                                    />
                                </div>
                            </div>
                        );
                    })}
                <IconButton
                    defaultClassName="icon-plus"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                        fields.push(createEmptyAdditionalLink());
                    }}
                    type={["default"]}
                    style={styles.addButton}
                >
                    <span style={styles.addButtonLabel}>
                        {translation.offers.editPopup.addAdditionalLinks}
                    </span>
                </IconButton>
            </Fragment>
        );
    };

    render() {
        return (
            <GroupCard title={translation.offers.editPopup.additionalLinks}>
                <FieldArray
                    component={this.renderLinks}
                    name="additionalLinks"
                />
            </GroupCard>
        );
    }
}

export default withStyles(styles)(AdditionalLinks);
