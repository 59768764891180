// Modules
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { Card } from "../";
// Actions
import { navigateTo } from "../../../../../../Router/Router.ducks";
// Styles
import styles from "./TopTableCard.css";
import "./TopTableCardStyle.css";

// eslint-disable-next-line no-unused-vars
function TopTableCard({ data, title, supplierId, navigateTo }) {
    if (!data || data.length === 0) {
        return null;
    }

    const getLink = url => {
        if (!url) return;

        const path = `${process.env.REACT_APP_WEBSITE_URL_OFFERS}/${url}`;

        return path;
    };

    const getLinkCms = cmsUrl => {
        if (!cmsUrl) return;

        const path = `${process.env.REACT_APP_WORDPRESS_URL_CMS}/${cmsUrl}`;

        return path;
    };

    const handleSelection = e => {
        let filter = [];
        const href = e.currentTarget.href;
        if (!href) {
            navigateTo && navigateTo("offers/overview");
        }
        const id = e.currentTarget.id;
        const name = e.currentTarget.name;

        filter.push({
            key: name,
            label: name,
            option: { name: name },
            value: id
        });

        supplierId && supplierId(filter[0]);
    };

    return (
        <Card style={styles.container}>
            {title && <span style={styles.title}>{title}</span>}
            <div style={styles.table.container}>
                {data.map((entry, index) => {
                    const id = get(entry, "id", undefined);
                    const name = get(entry, "name", undefined);
                    const value = get(entry, "count", undefined);
                    const url = get(entry, "url", undefined);
                    const cmsUrl = get(entry, "cmsUrl", undefined);
                    return (
                        <div
                            className="top-table-card-row"
                            key={id || index}
                            style={styles.table.innerContainer}
                        >
                            <span style={styles.table.index}>{index + 1}</span>
                            <a
                                id={id}
                                name={name}
                                target="_blank"
                                rel="noreferrer"
                                href={
                                    cmsUrl ? getLinkCms(cmsUrl) : getLink(url)
                                }
                                onClick={handleSelection}
                                style={styles.table.link}
                            >
                                <span style={styles.table.label}>
                                    {get(entry, "label", "")}
                                </span>
                            </a>

                            <span style={styles.table.value}>
                                {value
                                    ? new Intl.NumberFormat("de-DE").format(
                                          value
                                      )
                                    : "-"}
                            </span>
                        </div>
                    );
                })}
            </div>
        </Card>
    );
}

TopTableCard.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
            cmsUrl: PropTypes.string,
            count: PropTypes.number,
            label: PropTypes.string
        })
    ),
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    supplierId: PropTypes.func,
    navigateTo: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    navigateTo
};

export default connect(null, mapDispatchToProps)(TopTableCard);
