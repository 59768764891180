import Global from "../../config/global.css";

const styles = {
    checked: {
        backgroundColor: `${Global.colors.digitalBlue} !important`,
        border: `1px solid ${Global.colors.digitalBlue} !important`
    },
    checkbox: {
        alignItems: "center",
        backgroundColor: Global.colors.paleGrey,
        border: `1px solid ${Global.colors.blueyGrey}`,
        borderRadius: "4px",
        color: Global.colors.white,
        cursor: "pointer",
        display: "flex",
        fontSize: "16px",
        height: "16px",
        justifyContent: "center",
        minWidth: "16px",
        width: "16px"
    },
    container: {
        alignItems: "center",
        display: "flex"
    },
    error: {
        border: `1px solid ${Global.colors.melon}`
    },
    input: {
        cursor: "pointer",
        height: "16px",
        margin: "0px",
        minWidth: "16px",
        opacity: "0",
        position: "absolute",
        width: "16px"
    },
    label: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "block",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "fit-content",
        lineHeight: "18px",
        paddingLeft: "8px",
        position: "relative",
        userSelect: "none",
        width: "fit-content"
    },
    labelError: {
        color: `${Global.colors.melon} !important`
    }
};

export default styles;
