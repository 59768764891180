// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
// Styles
import styles from "./ViewPopup.css";

class ViewPopup extends PureComponent {
    static propTypes = {
        viewPopup: PropTypes.object
    };

    componentDidUpdate(prevProps) {
        const {
            viewPopup: { opened }
        } = this.props;
        if (opened && !get(prevProps, "viewPopup.opened", undefined)) {
            const element = document.getElementsByClassName("view-popup")[0];
            const parent = element && element.parentElement;

            parent && parent.scrollTo(0, 0);
        }
    }

    render() {
        const {
            viewPopup: { bottomContainer, children, header, opened, style }
        } = this.props;

        if (!opened) {
            return null;
        }

        return (
            <div
                className="view-popup"
                style={{ ...styles.container, ...get(style, "container", {}) }}
            >
                {header && (
                    <div
                        style={{
                            ...styles.header,
                            ...get(style, "header", {})
                        }}
                    >
                        {header}
                    </div>
                )}
                <div
                    className="view-popup-body"
                    style={{ ...styles.body, ...get(style, "body", {}) }}
                >
                    {children}
                </div>
                {bottomContainer}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPopup: state.viewPopup
    };
};

export default connect(mapStateToProps)(ViewPopup);
