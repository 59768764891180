// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { ClassificationAction } from "..";
import { WarningPopup } from "../../../../../components/";
// Actions
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./BulkClassificationAction.css";

function BulkClassificationAction({
    bulkSelection,
    classifications,
    closePopup,
    display,
    openPopup,
    updateBulkSelection,
    handleScrollToTop
}) {
    const [action, setAction] = useState();

    useEffect(() => {
        if (!display) {
            setAction(null);
        }
    }, [display]);

    if (!classifications || classifications.length === 0) {
        return null;
    }

    const handleSelection = ({ key, bulkAction }) => {
        setAction({
            key,
            action: () => {
                openPopup({
                    children: (
                        <WarningPopup
                            buttonAction={() => {
                                closePopup();
                                bulkAction({
                                    bulkSelection,
                                    successCb: () => {
                                        updateBulkSelection({
                                            operation: "unselectAll"
                                        });
                                        handleScrollToTop();
                                    }
                                });
                            }}
                            buttonLabel={translation.labels.confirm}
                            closeFunction={() => {
                                closePopup();
                            }}
                            imageStyle={styles.warningPopupImage}
                            subTitle={
                                translation.offersClassification
                                    .bulkClassification.subtitle
                            }
                            title={
                                translation.offersClassification
                                    .bulkClassification.title
                            }
                        />
                    )
                });
            }
        });
    };

    return (
        <div
            id="bulk-action"
            style={{ ...styles.container, ...(display ? {} : styles.hide) }}
        >
            <ClassificationAction
                expandableActions={classifications}
                handleSelection={handleSelection}
                selection={action}
            />
        </div>
    );
}

BulkClassificationAction.propTypes = {
    bulkSelection: PropTypes.array,
    classifications: PropTypes.object.isRequired,
    closePopup: PropTypes.func.isRequired,
    display: PropTypes.bool,
    openPopup: PropTypes.func.isRequired,
    updateBulkSelection: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func
};

const mapDispatchToProps = {
    closePopup,
    openPopup
};

export default connect(undefined, mapDispatchToProps)(BulkClassificationAction);
