// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Tooltip } from "@material-ui/core";
// Styles
import styles from "./CustomTooltip.css";

class CustomTooltip extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        classes: PropTypes.object,
        customClasses: PropTypes.object,
        placement: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    };

    render() {
        const { children, classes, customClasses, ...props } = this.props;

        const placement = get(props, "placement", "top");

        return (
            <Tooltip
                classes={{
                    tooltip: classNames(
                        placement === "top"
                            ? classes.tooltipTop
                            : placement.includes("right")
                            ? classes.tooltipRight
                            : "",
                        get(customClasses, "tooltip", "")
                    ),
                    tooltipPlacementLeft: classes.tooltipLeftPlacement,
                    tooltipPlacementRight: classes.tooltipRightPlacement,
                    tooltipPlacementTop: classes.tooltipTopPlacement
                }}
                {...props}
            >
                {children}
            </Tooltip>
        );
    }
}

export default withStyles(styles)(CustomTooltip);
