// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Styles
import styles from "./Popup.css";

class Popup extends PureComponent {
    static propTypes = {
        popup: PropTypes.object.isRequired
    };

    render() {
        const {
            popup: { children, opened }
        } = this.props;

        if (!opened) {
            return null;
        }

        return <div style={styles.container}>{children}</div>;
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup
    };
};

export default connect(mapStateToProps)(Popup);
