// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./Dashboard.css";

class Dashboard extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        leftBar: PropTypes.node.isRequired,
        style: PropTypes.shape({
            childrenContainer: PropTypes.object,
            container: PropTypes.object,
            innerContainer: PropTypes.object
        }),
        topBar: PropTypes.node.isRequired
    };

    render() {
        const { children, leftBar, style, topBar } = this.props;

        return (
            <div
                className="dashboard"
                style={{ ...styles.container, ...get(style, "container", {}) }}
            >
                {topBar}
                <div
                    className="dashboard-inner-container"
                    style={{
                        ...styles.innerContainer,
                        ...get(style, "innerContainer", {})
                    }}
                >
                    {leftBar}
                    <div
                        className="dashboard-children-container"
                        style={get(style, "childrenContainer", {})}
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
