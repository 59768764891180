// Modules
import get from "lodash.get";
// Helpers
import { handleActionError } from "../../../helpers/classificationAction";

/**
 * Gets a list of action available for the overall classification process.
 *
 * @param {Object} props the auxiliar params and functions
 * @returns a list of actions
 */
export const getActions = props => [
    {
        key: "nationwide",
        action: offer => {
            handleUpdateOfferClassification({
                offer,
                classification: "nationwide",
                ...props
            });
        },
        bulkAction: ({ bulkSelection, successCb }) => {
            handleUpdateOffersClassificationBulk({
                bulkSelection,
                classification: "nationwide",
                successCb,
                ...props
            });
        }
    },
    {
        key: "regional",
        action: offer => {
            handleUpdateOfferClassification({
                offer,
                classification: "regional",
                ...props
            });
        },
        bulkAction: ({ bulkSelection, successCb }) => {
            handleUpdateOffersClassificationBulk({
                bulkSelection,
                classification: "regional",
                successCb,
                ...props
            });
        }
    },
    {
        key: "unclear",
        action: offer => {
            if (get(offer, "status", undefined) === "pending") {
                handleUpdateOfferStatus({
                    offer,
                    status: "unclear",
                    ...props
                });
            } else {
                handleUpdateOfferClassification({ offer, ...props });
            }
        },
        bulkAction: ({ bulkSelection, successCb }) => {
            handleUpdateOffersClassificationBulk({
                bulkSelection,
                successCb,
                ...props
            });
        }
    },
    {
        key: "archived",
        action: offer => {
            handleUpdateOfferStatus({ offer, status: "archived", ...props });
        },
        bulkAction: ({ bulkSelection, successCb }) => {
            handleUpdateOffersStatusBulk({
                bulkSelection,
                status: "archived",
                successCb,
                ...props
            });
        }
    }
];

/**
 * Triggers the offer classification update.
 *
 * @param {Object} param the auxiliar params and functions
 */
export const handleUpdateOfferClassification = ({
    offer,
    classification,
    openPopup,
    updateOfferClassification
}) => {
    const offerId = get(offer, "_id", undefined);

    updateOfferClassification &&
        updateOfferClassification({
            offerId,
            classification,
            successCb: refreshDataCb => {
                refreshDataCb && refreshDataCb();
            },
            errorCb: refreshDataCb => {
                handleActionError({ openPopup, refreshDataCb });
            }
        });
};

/**
 * Triggers the offers classification bulk update.
 *
 * @param {Object} param the auxiliar params and functions
 */
export const handleUpdateOffersClassificationBulk = ({
    bulkSelection,
    classification,
    openPopup,
    successCb,
    updateOffersClassificationBulk
}) => {
    updateOffersClassificationBulk &&
        updateOffersClassificationBulk({
            offersIds: bulkSelection,
            classification,
            successCb: refreshDataCb => {
                refreshDataCb && refreshDataCb(successCb);
            },
            errorCb: refreshDataCb => {
                handleActionError({ openPopup, refreshDataCb });
            }
        });
};

/**
 * Triggers the offer status update.
 *
 * @param {Object} param the auxiliar params and functions
 */
export const handleUpdateOfferStatus = ({
    offer,
    openPopup,
    status,
    updateOfferStatus
}) => {
    const offerId = get(offer, "_id", undefined);

    updateOfferStatus &&
        updateOfferStatus({
            offerId,
            status,
            successCb: refreshDataCb => {
                refreshDataCb && refreshDataCb();
            },
            errorCb: refreshDataCb => {
                handleActionError({ openPopup, refreshDataCb });
            }
        });
};

/**
 * Triggers the offers status bulk update.
 *
 * @param {Object} param the auxiliar params and functions
 */
export const handleUpdateOffersStatusBulk = ({
    bulkSelection,
    openPopup,
    successCb,
    status,
    updateOffersStatusBulk
}) => {
    updateOffersStatusBulk &&
        updateOffersStatusBulk({
            offersIds: bulkSelection,
            status,
            successCb: refreshDataCb => {
                refreshDataCb && refreshDataCb(successCb);
            },
            errorCb: refreshDataCb => {
                handleActionError({ openPopup, refreshDataCb });
            }
        });
};
