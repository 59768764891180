// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { LeftContainer, RightContainer } from "./components/";
// Styles
import styles from "./DetailsView.css";

class DetailsView extends PureComponent {
    static propTypes = {
        details: PropTypes.object.isRequired
    };

    render() {
        const { details } = this.props;

        if (!details) {
            return null;
        }

        return (
            <div style={styles.container}>
                <LeftContainer />
                <RightContainer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details
    };
};

export default connect(mapStateToProps)(DetailsView);
