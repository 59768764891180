// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import { CircularProgress } from "..";
// Styles
import styles from "./LoadingPopup.css";

class LoadingPopup extends PureComponent {
    static propTypes = {
        imageStyle: PropTypes.object,
        subTitle: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    };

    render() {
        const { subTitle, title } = this.props;

        return (
            <div style={styles.container}>
                <div style={styles.loadingContainer}>
                    <CircularProgress
                        className="loading-popup"
                        size={78}
                        style={{
                            circle: styles.circle
                        }}
                    />
                </div>
                <span style={styles.title}>{title}</span>
                <span style={styles.subTitle}>{subTitle}</span>
            </div>
        );
    }
}

export default LoadingPopup;
