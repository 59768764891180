// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { Field } from "redux-form";
// Components
import {
    CreatableSelect,
    MaterialTextField
} from "../../../../../../../../../../../components/";
import { GroupCard } from "../../../../../";
// Helpers
import { getDurationUnits } from "../../../../helpers/formMappingsHelper";
// Validators
import { digitsWithSeparators } from "../../../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Duration.css";

class Duration extends PureComponent {
    static propTypes = {
        eventDetailsForm: PropTypes.object
    };

    getDurationUnitsList = () => {
        const { eventDetailsForm } = this.props;

        const unit = get(eventDetailsForm, "values.duration.unit", undefined);

        return getDurationUnits(unit);
    };

    handleOnChange = () => {
        document.activeElement && document.activeElement.blur();
    };

    render() {
        const durationUnitsList = this.getDurationUnitsList();

        return (
            <GroupCard style={styles.card} title={translation.event.duration}>
                <div style={styles.container}>
                    <Field
                        component={CreatableSelect}
                        customOnChange={this.handleOnChange}
                        editable
                        isClearable
                        isSearchable
                        label={translation.events.editPopup.durationUnit}
                        placeholder={""}
                        name="duration.unit"
                        options={durationUnitsList}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.events.editPopup.durationValue}
                        name="duration.value"
                        style={styles.textField}
                        variant="outlined"
                        validate={digitsWithSeparators}
                    />
                </div>
            </GroupCard>
        );
    }
}

const mapStateToProps = state => {
    return {
        eventDetailsForm: get(state, "form.eventDetails", undefined)
    };
};

export default connect(mapStateToProps)(Duration);
