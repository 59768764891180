import Global from "../../../../../config/global.css";

const styles = {
    backIcon: {
        fontSize: "24px",
        height: "24px",
        width: "24px"
    },
    title: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "grid",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "23px",
        gap: "16px",
        gridAutoFlow: "column",
        gridAutoColumns: "auto 1fr",
        padding: "0px 24px"
    }
};

export default styles;
