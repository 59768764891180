import Global from "../../../../../../../config/global.css";

const styles = {
    alignRight: {
        justifyContent: "flex-end"
    },
    searchBarContainer: {
        marginBottom: "24px",
        width: "381px"
    },
    searchBarInput: {
        backgroundColor: Global.colors.white
    },
    tableHeaders: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "minmax(200px, 90%) minmax(92px, 10%) 50px",
        height: "44px",
        padding: "0px 24px"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "1.4em",
        padding: "24px 0px 16px"
    }
};

export default styles;
