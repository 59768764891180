// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import { Grid } from "../../../";
import { EventCard } from "./components";
// Styles
import styles from "./EventsRow.css";

class EventsRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object,
        gridStyle: PropTypes.object,
        seminars: PropTypes.arrayOf(
            PropTypes.shape({
                events: PropTypes.array,
                filters: PropTypes.object,
                shortDescription: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                displayTitle: PropTypes.string,
                url: PropTypes.string.isRequired
            })
        )
    };

    getEventsSortedByStartDate = seminar => {
        return (
            seminar.events &&
            seminar.events.sort((event1, event2) => {
                if (event1.start_date === "Alle" || !event1.start_date) {
                    return 1;
                }
                if (!event2.start_date) {
                    return -1;
                }

                return new Date(event1.start_date) < new Date(event2.start_date)
                    ? -1
                    : new Date(event1.start_date) > new Date(event2.start_date)
                    ? 1
                    : 0;
            })
        );
    };

    render() {
        const { containerStyle, gridStyle, seminars } = this.props;

        return (
            <div style={{ ...styles.container, ...containerStyle }}>
                <Grid style={{ ...styles.grid, ...gridStyle }}>
                    {seminars &&
                        seminars.map(seminar => {
                            const events =
                                this.getEventsSortedByStartDate(seminar) || [];
                            return events.map((event, eIndex) => {
                                return (
                                    <div key={eIndex}>
                                        <EventCard
                                            event={event}
                                            key={eIndex}
                                            index={eIndex}
                                            seminar={seminar}
                                        />
                                    </div>
                                );
                            });
                        })}
                </Grid>
            </div>
        );
    }
}

export default EventsRow;
