import Global from "../../config/global.css";

const styles = {
    dropZone: {
        label: {
            fontSize: "14px",
            color: Global.colors.blueyGrey
        },
        note: {
            fontSize: "11px",
            color: Global.colors.blueyGrey,
            textAlign: "center"
        }
    },
    selectedImage: {
        img: {
            width: "100px",
            borderRadius: "10px"
        }
    }
};

export default styles;
