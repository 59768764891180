// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// Helpers
import { parseHtmlData } from "../../../../../../services/htmlDataParsing";
// Styles
import { withStyles } from "@material-ui/core/styles";
import styles from "./OfferCard.css";

class OfferCard extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        shortDescription: PropTypes.string,
        title: PropTypes.object
    };

    state = {
        tileOnHover: false
    };

    handleTileMouseEnter = () => {
        this.setState({ tileOnHover: true });
    };

    handleTileLeave = () => {
        this.setState({ tileOnHover: false });
    };

    render() {
        const { classes, title, shortDescription } = this.props;
        const { tileOnHover } = this.state;

        const shortDescriptionParsed = parseHtmlData(shortDescription);

        // To force re-calculation of WebkitLineClamp
        !this.titleRef &&
            setTimeout(() => {
                this.forceUpdate();
            }, 200);

        return (
            <div
                className={classNames(classes.item, classes.itemVisible, {
                    [classes.itemHover]: tileOnHover
                })}
                onClick={this.handleTileOnClick}
                onMouseEnter={() => {
                    this.handleTileMouseEnter();
                }}
                onMouseLeave={this.handleTileLeave}
            >
                <div
                    dangerouslySetInnerHTML={title}
                    ref={ref => this.titleRef !== ref && (this.titleRef = ref)}
                    style={{
                        ...styles.title,
                        ...(tileOnHover
                            ? {}
                            : shortDescription
                            ? styles.titleEllipsis
                            : styles.titleWithoutShortDescription)
                    }}
                />
                {shortDescription && (
                    <div
                        dangerouslySetInnerHTML={shortDescriptionParsed}
                        style={{
                            ...styles.subtitle,
                            ...(tileOnHover
                                ? {
                                      maxHeight: "unset"
                                  }
                                : {
                                      ...styles.subtitleEllipsis,
                                      maxHeight: `${
                                          this.titleRef
                                              ? 140 - this.titleRef.clientHeight
                                              : 0
                                      }px`,
                                      WebkitLineClamp: this.titleRef
                                          ? Math.floor(
                                                this.titleRef.clientHeight / 18
                                            ) >= 5
                                              ? 2
                                              : 7 -
                                                Math.floor(
                                                    this.titleRef.clientHeight /
                                                        18
                                                )
                                          : 2
                                  })
                        }}
                    />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(OfferCard);
