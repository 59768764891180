// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CustomRadioButton,
    MaterialTextField,
    Select
} from "../../../../../components";
// Form
import {
    change,
    clearSubmitErrors,
    Field,
    formValueSelector,
    getFormSubmitErrors,
    reduxForm,
    stopSubmit
} from "redux-form";
// Styles
import styles from "./EditUserForm.css";
// Actions
import { closeEditPopup } from "../../../../../components/EditPopup/EditPopup.ducks";
import { addUser, updateUser } from "../../Users.ducks";
import { getLegalEntities, getCooperationPartners } from "./EditUserForm.ducks";
// Validators
import { required, email } from "../../../../../services/validators";
// Helpers
import { buildEmailData } from "../../helpers/emailDataBuilder";
// Translations
import translation from "../../../../../config/translation";

function onSubmit(values, dispatch, props) {
    const {
        addUser,
        closeEditPopup,
        getFilters,
        legalEntities,
        cooperationPartner,
        updateUser,
        user
    } = props;
    const {
        disabled,
        email,
        entityId,
        firstName,
        lastName,
        newPassword,
        uid,
        role
    } = values;

    if (!role) {
        dispatch(
            stopSubmit("editUser", {
                role: translation.users.editPopup.missingRole
            })
        );

        return;
    }

    const displayName = `${firstName} ${lastName}`;

    let data = {
        entity_id: entityId,
        displayName,
        disabled,
        role
    };

    const emailData = buildEmailData(
        user,
        legalEntities,
        cooperationPartner,
        values
    );

    const filters = getFilters && getFilters();

    if (user) {
        updateUser &&
            updateUser(uid, data, filters, emailData, () => {
                closeEditPopup();
            });
    } else {
        data.email = email;
        data.password = newPassword;

        addUser &&
            addUser(data, filters, emailData, () => {
                closeEditPopup();
            });
    }
}

class EditUserForm extends PureComponent {
    static propTypes = {
        addUser: PropTypes.func.isRequired,
        auth: PropTypes.object,
        change: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        entityIdValue: PropTypes.string,
        entityValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        dispatch: PropTypes.func.isRequired,
        getFilters: PropTypes.func,
        getLegalEntities: PropTypes.func.isRequired,
        getCooperationPartners: PropTypes.func.isRequired,
        initialValues: PropTypes.object.isRequired,
        legalEntities: PropTypes.array,
        cooperationPartner: PropTypes.array,
        permissions: PropTypes.array,
        profileData: PropTypes.object,
        submitErrors: PropTypes.object,
        user: PropTypes.object
    };

    roles = [
        "superuser",
        "ccagent",
        "ccadmin",
        "ihkagent",
        "ihkadmin",
        "cooppartneradmin",
        "cooppartneragent",
        "dihkemployee",
        "dihkadmin"
    ];

    state = {
        inactive: false,
        passwordVisible: false,
        role: undefined,
        rolesList: []
    };

    componentDidMount() {
        const { getLegalEntities, getCooperationPartners, user } = this.props;

        getLegalEntities && getLegalEntities();
        getCooperationPartners && getCooperationPartners();

        if (user) {
            const {
                initialValues,
                initialValues: { disabled }
            } = this.props;

            const { inactive, role } = this.state;
            if (disabled !== undefined) {
                inactive !== disabled && this.setState({ inactive: disabled });
            }
            if (initialValues.role !== undefined) {
                role !== initialValues.role &&
                    this.setState({ role: initialValues.role });
            }
        }

        const rolesList = this.getRolesBasedOnPermissions();
        this.setState({ rolesList });

        if (rolesList && rolesList.length === 1) {
            this.handleChangeRole(rolesList[0]);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            dispatch,
            initialValues: { entityId },
            legalEntities,
            cooperationPartner,
            user
        } = this.props;

        if (
            user &&
            ((legalEntities && legalEntities.length > 0) ||
                (cooperationPartner && cooperationPartner.length > 0)) &&
            (!prevProps.legalEntities ||
                prevProps.legalEntities.length === 0 ||
                !prevProps.cooperationPartner ||
                prevProps.cooperationPartner.length === 0) &&
            entityId
        ) {
            const legalEntitiesList = this.getLegalEntitiesList();
            const cooperationPartnersList = this.getCooperationPartnerList();

            const entityObj =
                legalEntitiesList?.find(ent => ent.option.id === entityId) ||
                cooperationPartnersList?.find(
                    partner => partner.option.id === entityId
                );

            entityObj &&
                dispatch(
                    change("editUser", "entity", get(entityObj, "value", null))
                );
        }
    }

    handleChangeDisabledStatus = inactive => {
        const { dispatch } = this.props;

        this.state.inactive !== inactive &&
            this.setState({ inactive }, () => {
                dispatch(change("editUser", "disabled", inactive));
            });
    };

    handleChangeRole = role => {
        const { dispatch } = this.props;

        this.state.role !== role &&
            this.setState({ role }, () => {
                dispatch(clearSubmitErrors("editUser"));
                dispatch(change("editUser", "role", role));

                if (
                    ![
                        "ihkadmin",
                        "ihkagent",
                        "cooppartneradmin",
                        "cooppartneragent"
                    ].includes(role)
                ) {
                    dispatch(change("editUser", "entity", null));
                    dispatch(change("editUser", "entityId", null));
                }
            });
    };

    handleChangeEntity = entity => {
        const { dispatch } = this.props;

        dispatch(
            change("editUser", "entityId", get(entity, "option.id", null))
        );
    };

    handleChangeCooPartner = entity => {
        const { dispatch } = this.props;

        dispatch(
            change("editUser", "coopartnerId", get(entity, "option.id", null))
        );
    };

    getLegalEntitiesList = () => {
        const {
            auth,
            dispatch,
            entityValue,
            legalEntities,
            permissions,
            profileData,
            user
        } = this.props;
        const { role, rolesList } = this.state;

        if (!legalEntities) {
            return undefined;
        }

        const list = legalEntities
            ? legalEntities.map(entity => ({
                  label: entity.name,
                  value: entity.name,
                  option: {
                      label: entity.name,
                      id: entity._id
                  }
              }))
            : [];

        const permission = permissions.find(p => p.match(`${role}*`));
        if (permission && permission.includes("by_entity")) {
            const entityId = get(profileData, "entity_id", undefined);

            const listFiltered =
                entityId && legalEntities
                    ? list.filter(ent => ent.option.id === entityId)
                    : [];

            if (
                !entityValue &&
                listFiltered.length === 1 &&
                rolesList.length === 1
            ) {
                dispatch(change("editUser", "entity", listFiltered[0].value));
                this.handleChangeEntity(listFiltered[0]);
            }

            return listFiltered;
        } else if (
            permissions.includes("self") &&
            user &&
            get(auth, "uid", undefined) === get(user, "id", undefined)
        ) {
            const entityId = get(profileData, "entity_id", undefined);
            const listFiltered =
                entityId && legalEntities
                    ? list.filter(ent => ent.option.id === entityId)
                    : list;

            return listFiltered;
        }

        const sortByLabel = (a, b) => a["label"].localeCompare(b["label"]);

        return list && list.sort(sortByLabel);
    };

    getCooperationPartnerList = () => {
        const {
            auth,
            dispatch,
            entityValue,
            cooperationPartner,
            permissions,
            profileData,
            user
        } = this.props;
        const { role, rolesList } = this.state;

        if (!cooperationPartner) {
            return undefined;
        }

        const list = cooperationPartner
            ? cooperationPartner.map(entity => ({
                  label: entity.name,
                  value: entity.name,
                  option: {
                      label: entity.name,
                      id: entity._id
                  }
              }))
            : [];

        const permission = permissions.find(p => p.match(`${role}*`));

        if (permission && permission.includes("by_entity")) {
            const entityId = get(profileData, "entity_id", undefined);

            const listFiltered =
                entityId && cooperationPartner
                    ? list.filter(ent => ent.option.id === entityId)
                    : [];

            if (
                !entityValue &&
                listFiltered.length === 1 &&
                rolesList.length === 1
            ) {
                dispatch(change("editUser", "entity", listFiltered[0].value));
                this.handleChangeEntity(listFiltered[0]);
            }

            return listFiltered;
        } else if (
            permissions.includes("self") &&
            user &&
            get(auth, "uid", undefined) === get(user, "id", undefined)
        ) {
            const entityId = get(profileData, "entity_id", undefined);
            const listFiltered =
                entityId && cooperationPartner
                    ? list.filter(ent => ent.option.id === entityId)
                    : list;

            return listFiltered;
        }

        const sortByLabel = (a, b) => a["label"].localeCompare(b["label"]);

        return list && list.sort(sortByLabel);
    };

    getRolesBasedOnPermissions = () => {
        const { auth, permissions, profileData, user } = this.props;

        if (permissions.includes("all")) {
            return this.roles;
        }

        if (
            permissions.includes("self") &&
            user &&
            get(auth, "uid", undefined) === get(user, "id", undefined)
        ) {
            const userRole = get(profileData, "role.key", undefined);

            return this.roles.filter(
                role =>
                    permissions.some(p => p.match(`${role}*`)) ||
                    role === userRole
            );
        }

        return this.roles.filter(role =>
            permissions.some(p => p.match(`${role}*`))
        );
    };

    handlePasswordVisibility = () => {
        const { passwordVisible } = this.state;
        this.setState({ passwordVisible: !passwordVisible });
    };

    passwordLengthValidator = value => {
        return value && value.length < 6
            ? translation.users.passwordLengthError
            : undefined;
    };

    render() {
        const { entityIdValue, entityValue, submitErrors, user } = this.props;
        const { inactive, passwordVisible, role, rolesList } = this.state;

        const setEntity = ["ihkadmin", "ihkagent"].includes(role);
        const setCooperationPartner = [
            "cooppartneradmin",
            "cooppartneragent"
        ].includes(role);

        const legalEntitiesList = this.getLegalEntitiesList();
        const cooperationPartnerList = this.getCooperationPartnerList();

        return (
            <form
                autoComplete="off"
                style={{
                    ...styles.container,
                    ...(user ? styles.containerEdit : {})
                }}
            >
                <Field
                    component={MaterialTextField}
                    label={translation.users.editPopup.firstName}
                    name="firstName"
                    style={styles.textField}
                    variant="outlined"
                    validate={required}
                />
                <Field
                    component={MaterialTextField}
                    label={translation.users.editPopup.lastName}
                    name="lastName"
                    style={styles.textField}
                    variant="outlined"
                    validate={required}
                />
                <Field
                    autoComplete="off"
                    component={MaterialTextField}
                    disabled={!!user}
                    label={translation.users.editPopup.email}
                    name="email"
                    style={styles.textField}
                    variant="outlined"
                    validate={user ? [] : [required, email]}
                />
                {!user && (
                    <Field
                        autoComplete="new-password"
                        component={MaterialTextField}
                        endAdornment={
                            <span
                                className={
                                    passwordVisible
                                        ? "icon-dont-see"
                                        : "icon-see"
                                }
                                onClick={this.handlePasswordVisibility}
                                style={styles.passwordIcon}
                            />
                        }
                        label={translation.users.editPopup.password}
                        name="newPassword"
                        type={passwordVisible ? "text" : "password"}
                        style={styles.textField}
                        variant="outlined"
                        validate={[required, this.passwordLengthValidator]}
                    />
                )}
                <div style={styles.radioGroupContainer}>
                    <div style={styles.radioGroupContainerTitle}>
                        {`${translation.users.editPopup.role}*`}
                    </div>
                    {rolesList &&
                        rolesList.map((roleOption, index) => {
                            return (
                                <CustomRadioButton
                                    key={index}
                                    disabled={rolesList.length === 1}
                                    error={
                                        !!get(submitErrors, "role", undefined)
                                    }
                                    checked={role === roleOption}
                                    label={translation.users.roles[roleOption]}
                                    onChange={() =>
                                        this.handleChangeRole(roleOption)
                                    }
                                />
                            );
                        })}
                    <div style={styles.error}>
                        {get(submitErrors, "role", undefined)}
                    </div>
                </div>
                {setEntity &&
                    legalEntitiesList &&
                    (entityValue || !entityIdValue) && (
                        <Field
                            component={Select}
                            customOnChange={this.handleChangeEntity}
                            disabled={
                                rolesList.length === 1 &&
                                legalEntitiesList &&
                                legalEntitiesList.length === 1
                            }
                            isSearchable
                            label={`${translation.users.editPopup.entity}*`}
                            menuContainerStyle={styles.selectMenuContainer}
                            name="entity"
                            placeholder={""}
                            options={legalEntitiesList || []}
                            optionStyle={styles.selectOption}
                            style={styles.select}
                            validate={required}
                        />
                    )}
                {setCooperationPartner &&
                    cooperationPartnerList &&
                    (entityValue || !entityIdValue) && (
                        <Field
                            component={Select}
                            customOnChange={this.handleChangeEntity}
                            disabled={
                                rolesList.length === 1 &&
                                cooperationPartnerList &&
                                cooperationPartnerList.length === 1
                            }
                            isSearchable
                            label={`${translation.users.editPopup.cooperationpartner}*`}
                            menuContainerStyle={styles.selectMenuContainer}
                            name="entity"
                            placeholder={""}
                            options={cooperationPartnerList || []}
                            optionStyle={styles.selectOption}
                            style={styles.select}
                            validate={required}
                        />
                    )}
                {user && (
                    <div
                        style={{
                            ...styles.radioGroupContainer,
                            ...styles.statusGroupContainer
                        }}
                    >
                        <div style={styles.radioGroupContainerTitle}>
                            {translation.users.editPopup.status}
                        </div>
                        <CustomRadioButton
                            checked={!inactive}
                            label={translation.users.active}
                            onChange={() =>
                                this.handleChangeDisabledStatus(false)
                            }
                        />
                        <CustomRadioButton
                            checked={inactive}
                            label={translation.users.inactive}
                            onChange={() =>
                                this.handleChangeDisabledStatus(true)
                            }
                        />
                    </div>
                )}
            </form>
        );
    }
}

const selector = formValueSelector("editUser");

const mapStateToProps = (state, props) => {
    if (props.user) {
        const {
            user: { displayName, email, entity_id, disabled, role, uid }
        } = props;

        const displayNameArray = displayName ? displayName.split(" ") : [];
        const firstName = displayNameArray[0];
        const lastName =
            displayNameArray.length > 0
                ? displayNameArray.slice(1).join(" ")
                : "";

        return {
            auth: state.firebase.auth,
            entityValue: selector(state, "entity"),
            entityIdValue: selector(state, "entityId") ?? null,
            initialValues: {
                uid,
                email,
                entityId: entity_id || null,
                disabled,
                firstName,
                lastName,
                role
            },
            legalEntities: state.editUser.legalEntities,
            cooperationPartner: state.editUser.cooperationPartner,
            profileData: state.profileData,
            submitErrors: getFormSubmitErrors("editUser")(state)
        };
    } else {
        return {
            auth: state.firebase.auth,
            initialValues: {
                disabled: false
            },
            legalEntities: state.editUser.legalEntities,
            cooperationPartner: state.editUser.cooperationPartner,
            profileData: state.profileData,
            submitErrors: getFormSubmitErrors("editUser")(state)
        };
    }
};

const mapDispatchToProps = {
    addUser,
    change,
    closeEditPopup,
    getLegalEntities,
    getCooperationPartners,
    updateUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editUser",

        onSubmit: (values, dispatch, props) => {
            onSubmit(values, dispatch, props);
        }
    })(EditUserForm)
);
