// Modules
import get from "lodash.get";

/**
 * Performs a match with the search query in the Legal Entity name.
 *
 * @param {String} searchQuery the search query
 * @param {Array} data the list to filter
 * @returns the list of uploads filtered by search query match
 */
export function getFilteredLegalEntitiesBySearchQuery(searchQuery, data) {
    let filteredUploads = [];
    if (!searchQuery || searchQuery.length === 0) {
        filteredUploads = data;
    } else if (data && data.length > 0) {
        const regularExp = new RegExp(`.*${searchQuery}.*`, "i");

        data.forEach(legalEntity => {
            if (
                get(legalEntity, "name", "").search(regularExp) >= 0 ||
                get(legalEntity, "keyword", "").search(regularExp) >= 0
            ) {
                filteredUploads.push(legalEntity);
            }
        });
    }
    return filteredUploads || [];
}
