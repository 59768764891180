// Modules
import get from "lodash.get";
// Translation
import translation from "../../../../config/translation";

export const FILTERS = [
    {
        key: "nameAsc",
        label: translation.entitiesUploads.filters.nameAsc,
        option: { name: translation.entitiesUploads.filters.nameAsc },
        value: translation.entitiesUploads.filters.nameAsc
    },
    {
        key: "green",
        label: translation.entitiesUploads.filters.regularUploads,
        option: {
            name: translation.entitiesUploads.filters.regularUploads
        },
        value: translation.entitiesUploads.filters.regularUploads
    },
    {
        key: "yellow",
        label: translation.entitiesUploads.filters.oldUploads,
        option: { name: translation.entitiesUploads.filters.oldUploads },
        value: translation.entitiesUploads.filters.oldUploads
    },
    {
        key: "red",
        label: translation.entitiesUploads.filters.noUploads,
        option: { name: translation.entitiesUploads.filters.noUploads },
        value: translation.entitiesUploads.filters.noUploads
    }
];

/**
 * Applies the status filter and returns the matching uploads.
 *
 * @param {Object} params the uploads and filter
 * @returns a list of uploads sorted alphabetically by name
 */
export function getTrackings({ uploads, filter }) {
    if (!uploads || uploads.length === 0) {
        return [];
    }

    const sortAlphabeticalByName = (a, b) =>
        get(a, "name", "").localeCompare(get(b, "name", ""));

    if (filter === FILTERS[0].key) {
        return uploads.sort(sortAlphabeticalByName);
    }

    let entitiesUploadsFilteredByStatus = uploads.filter(entity =>
        get(entity, "suppliers", []).some(
            supplier => get(supplier, "status", "red") === filter
        )
    );

    entitiesUploadsFilteredByStatus &&
        entitiesUploadsFilteredByStatus.forEach(entity => {
            let indexesToRemove = [];
            get(entity, "suppliers", []).forEach((supplier, index) => {
                get(supplier, "status", "red") !== filter &&
                    indexesToRemove.push(index);
            });

            if (indexesToRemove.length > 0) {
                indexesToRemove.forEach(index => {
                    delete entity.suppliers[index];
                });
            }
        });

    return entitiesUploadsFilteredByStatus
        ? entitiesUploadsFilteredByStatus.sort(sortAlphabeticalByName)
        : [];
}
