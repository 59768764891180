// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@material-ui/core";
// Styles
import styles from "./MaterialExpansionPanel.css";

class MaterialExpansionPanel extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        details: PropTypes.node.isRequired,
        disabled: PropTypes.bool,
        id: PropTypes.string,
        expanded: PropTypes.bool,
        expandIcon: PropTypes.node,
        hasExpandIcon: PropTypes.bool,
        iconStyle: PropTypes.object,
        onClick: PropTypes.func,
        onExpandCollapse: PropTypes.func,
        summary: PropTypes.node.isRequired
    };

    static defaultProps = {
        hasExpandIcon: true
    };

    render() {
        const {
            classes,
            details,
            disabled,
            id,
            expanded,
            expandIcon,
            hasExpandIcon,
            iconStyle,
            onClick,
            onExpandCollapse,
            summary
        } = this.props;

        return (
            <Accordion
                classes={{
                    root: get(classes, "root", null),
                    expanded: get(classes, "expanded", null)
                }}
                disabled={disabled}
                id={id}
                expanded={expanded}
                onChange={(event, expanded) =>
                    onExpandCollapse && onExpandCollapse(expanded)
                }
            >
                <AccordionSummary
                    classes={{
                        root: get(classes, "summaryRoot", null),
                        content: get(classes, "summaryContent", null),
                        expanded: get(classes, "summaryExpanded", null)
                    }}
                    expandIcon={
                        hasExpandIcon
                            ? expandIcon || (
                                  <span
                                      className="icon-arrow-down"
                                      style={{ ...styles.icon, ...iconStyle }}
                                  />
                              )
                            : null
                    }
                    onClick={event => {
                        onClick && onClick(event);
                    }}
                >
                    {summary}
                </AccordionSummary>
                {details && (
                    <AccordionDetails
                        classes={{ root: get(classes, "detailsRoot", null) }}
                    >
                        {details}
                    </AccordionDetails>
                )}
            </Accordion>
        );
    }
}

export default MaterialExpansionPanel;
