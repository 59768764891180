import Global from "../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gridTemplateColumns: "72px auto",
        position: "relative"
    },
    itemIcon: {
        fontSize: "24px"
    },
    logo: {
        width: "252px"
    },
    menu: {
        backgroundColor: Global.colors.white,
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        display: "grid",
        gap: "16px",
        gridAutoFlow: "row",
        gridAutoRows: "48px",
        justifyContent: "center",
        justifyItems: "center",
        padding: "16px 12px",
        width: "72px",
        zIndex: 4
    }
};

export default styles;
