import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridAutoColumns: "minmax(404px, 1fr) 404px",
        padding: "24px"
    },
    description: {
        color: Global.colors.darkSlateBlue,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "15px"
    },
    label: {
        color: Global.colors.blueyGrey,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px",
        textTransform: "uppercase"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    }
};

export default styles;
