// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MaterialMenu } from "../";
// Styles
import styles from "./Profile.css";

class Profile extends PureComponent {
    static propTypes = {
        activeSetting: PropTypes.object,
        /** The icon node or the icon class name */
        iconClose: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        iconOpen: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        /** The image node or the image already imported */
        image: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        /** For cases where one of the menu options is selected and there is a process logic on the main screen */
        isSelected: PropTypes.bool,
        menuProps: PropTypes.object,
        onClick: PropTypes.func,
        settingsOptions: PropTypes.arrayOf(
            PropTypes.shape({
                action: PropTypes.func,
                label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
                key: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
            })
        ),
        subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        style: PropTypes.shape({
            container: PropTypes.object,
            iconClose: PropTypes.object,
            iconOpen: PropTypes.object,
            image: PropTypes.object,
            subTitle: PropTypes.object,
            title: PropTypes.object
        })
    };

    profileReference = undefined;

    state = {
        anchorEl: undefined,
        isActive: false,
        onHover: false,
        isMenuOpen: false
    };

    renderProfileImage = () => {
        const { image, style, title } = this.props;
        let imageToRender = undefined;
        if (image) {
            if (typeof image === "string") {
                imageToRender = (
                    <img
                        alt={"profile"}
                        style={{ ...styles.image, ...get(style, "image", {}) }}
                        src={image}
                    />
                );
            } else {
                imageToRender = image;
            }
        } else {
            imageToRender = (
                <div
                    style={{
                        ...styles.image,
                        ...styles.defaultImage,
                        ...get(style, "image", {})
                    }}
                >
                    {title ? title[0].toUpperCase() : "U"}
                </div>
            );
        }

        return <div style={styles.image}>{imageToRender}</div>;
    };

    iconComponent = () => {
        const { iconClose, iconOpen, style } = this.props;
        const { isMenuOpen } = this.state;

        const icon = isMenuOpen ? iconClose : iconOpen;
        const iconStyle = isMenuOpen ? "iconClose" : "iconOpen";

        const customIconStyle = {
            ...styles.icon,
            ...get(style, iconStyle, {})
        };
        if (typeof icon === "string") {
            return <span className={icon} style={{ ...customIconStyle }} />;
        } else {
            return <div style={{ ...customIconStyle }}>{icon}</div>;
        }
    };

    handleOnClick = event => {
        const { onClick, settingsOptions } = this.props;

        settingsOptions &&
            settingsOptions.length > 0 &&
            this.setState({
                anchorEl: event && event.currentTarget,
                isMenuOpen: false
            });

        onClick && onClick();
    };

    handleMenuOpen = () => {
        const { isMenuOpen } = this.state;
        !isMenuOpen && this.setState({ isMenuOpen: true });
    };

    handleMouseEnter = () => {
        const { onHover } = this.state;
        !onHover && this.setState({ onHover: true });
    };

    handleMouseLeave = () => {
        const { onHover } = this.state;
        onHover && this.setState({ onHover: false });
    };

    render() {
        const {
            activeSetting,
            isSelected,
            menuProps,
            settingsOptions,
            subTitle,
            style,
            title
        } = this.props;
        const { anchorEl, onHover } = this.state;

        const hasSettings = settingsOptions && settingsOptions.length > 0;

        return (
            <div key="profile-outercontainer">
                <div
                    ref={ref => {
                        this.profileReference = ref;
                    }}
                    key="profile-container"
                    onClick={this.handleOnClick}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    style={{
                        ...styles.container,
                        ...(anchorEl || onHover || isSelected
                            ? styles.containerActive
                            : {}),
                        ...(anchorEl ? styles.containerExpanded : {}),
                        ...(hasSettings ? styles.containerClickable : {}),
                        ...get(style, "container", {})
                    }}
                >
                    {this.renderProfileImage()}
                    {(title || subTitle) && (
                        <div
                            key="profile-innercontainer"
                            style={styles.innerContainer}
                        >
                            {title && (
                                <div
                                    key="profile-title"
                                    style={{
                                        ...styles.title,
                                        ...get(style, "title", {})
                                    }}
                                >
                                    {title}
                                </div>
                            )}
                            {subTitle && (
                                <div
                                    key="profile-subtitle"
                                    style={{
                                        ...styles.subTitle,
                                        ...get(style, "subTitle", {})
                                    }}
                                >
                                    {subTitle}
                                </div>
                            )}
                        </div>
                    )}
                    {this.iconComponent()}
                </div>
                {hasSettings && (
                    <ProfileMenu
                        activeSetting={activeSetting}
                        anchorEl={anchorEl}
                        handleMenuOpen={this.handleMenuOpen}
                        handleOnClick={this.handleOnClick}
                        menuProps={menuProps}
                        profileReference={this.profileReference}
                        settingsOptions={settingsOptions}
                    />
                )}
            </div>
        );
    }
}

class ProfileMenu extends PureComponent {
    static propTypes = {
        activeSetting: PropTypes.object,
        anchorEl: PropTypes.object,
        handleMenuOpen: PropTypes.func,
        handleOnClick: PropTypes.func,
        menuProps: PropTypes.object,
        profileReference: PropTypes.object,
        settingsOptions: PropTypes.array
    };

    render() {
        const {
            activeSetting,
            anchorEl,
            handleMenuOpen,
            handleOnClick,
            menuProps,
            profileReference,
            settingsOptions
        } = this.props;

        return (
            <MaterialMenu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                onClose={() => handleOnClick()}
                onOpen={handleMenuOpen}
                options={settingsOptions}
                optionSelected={activeSetting}
                style={{
                    container: {
                        width: `${
                            (profileReference &&
                                profileReference.clientWidth) ||
                            220
                        }px`
                    }
                }}
                {...menuProps}
            />
        );
    }
}

export default Profile;
