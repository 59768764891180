// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Grid extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        columnGap: PropTypes.number,
        rowGap: PropTypes.number,
        style: PropTypes.object,
        templateColumns: PropTypes.string
    };

    render() {
        const {
            children,
            className,
            columnGap = 16,
            rowGap = 16,
            style,
            templateColumns
        } = this.props;

        return (
            <div
                style={{
                    display: "grid",
                    alignItems: "flex-start",
                    gridColumnGap: `${columnGap}px`,
                    gridRowGap: `${rowGap}px`,
                    gridTemplateColumns: `${templateColumns}`,
                    ...style
                }}
                className={className}
            >
                {children}
            </div>
        );
    }
}

export default Grid;
