import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "minmax(608px, 60%)",
        gridTemplateRows: "62px min-content",
        padding: "32px 24px"
    },
    fieldTitle: {
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px",
        marginBottom: "12px"
    },
    selectMenuContainer: {
        position: "relative"
    },
    selectControl: {
        padding: "8px 10px"
    },
    selectMenu: {
        maxHeight: "240px"
    },
    selectMultiValue: {
        backgroundColor: Global.colors.digitalBlueT4,
        border: "unset",
        borderRadius: "4px",
        margin: "2px 4px 2px 0px"
    },
    selectMultiValueLabel: {
        color: Global.colors.darkSlateBlue,
        padding: "5.5px 6px",
        paddingLeft: "6px"
    },
    selectMultiValueRemove: {
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        margin: "unset"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
