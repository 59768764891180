// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
import dialogFlow from "../../../services/dialogflow";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../../config/translation";

export const CLEAR_LEGAL_ENTITIES = "offers/CLEAR_LEGAL_ENTITIES";
export const CLEAR_SUPPLIER_ENTITIES = "offers/CLEAR_SUPPLIER_ENTITIES";
export const CLEAR_OFFERS = "CLEAR_OFFERS";
export const CLEAR_SEARCH_QUERY = "CLEAR_SEARCH_QUERY";
export const CLEAR_SEARCH_FILTER = "CLEAR_SEARCH_FILTER";
export const SAVE_LEGAL_ENTITIES = "offers/SAVE_LEGAL_ENTITIES";
export const SAVE_SUPPLIER_ENTITIES = "offers/SAVE_SUPPLIER_ENTITIES";
export const SAVE_OFFERS = "SAVE_OFFERS";
export const SAVE_SEARCH_FILTER = "SAVE_SEARCH_FILTER";
export const SAVE_SEARCH_QUERY = "SAVE_SEARCH_QUERY";

export const defaultState = {
    legalEntities: undefined,
    supplierEntities: undefined,
    offers: [],
    searchFilter: undefined,
    searchQuery: "",
    totalCount: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: defaultState.legalEntities
            });
        }
        case CLEAR_SUPPLIER_ENTITIES: {
            return Object.assign({}, state, {
                supplierEntities: defaultState.supplierEntities
            });
        }
        case CLEAR_OFFERS: {
            return Object.assign({}, defaultState, {
                legalEntities: state.legalEntities,
                supplierEntities: state.supplierEntities,
                searchFilter: state.searchFilter,
                searchQuery: state.searchQuery
            });
        }
        case CLEAR_SEARCH_FILTER: {
            return Object.assign({}, state, {
                searchFilter: defaultState.searchFilter
            });
        }
        case CLEAR_SEARCH_QUERY: {
            return Object.assign({}, state, {
                searchQuery: defaultState.searchQuery
            });
        }
        case SAVE_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: action.payload
            });
        }
        case SAVE_SUPPLIER_ENTITIES: {
            return Object.assign({}, state, {
                supplierEntities: action.payload
            });
        }
        case SAVE_OFFERS: {
            return Object.assign({}, state, {
                offers:
                    action.payload.startIndex === 0
                        ? [...action.payload.offers]
                        : [...state.offers, ...action.payload.offers],
                totalCount: action.payload.totalCount
            });
        }
        case SAVE_SEARCH_FILTER: {
            return Object.assign({}, state, {
                searchFilter: action.payload
            });
        }
        case SAVE_SEARCH_QUERY: {
            return Object.assign({}, state, {
                searchQuery: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearLegalEntities = () => {
    return {
        type: CLEAR_LEGAL_ENTITIES
    };
};

export const clearSupplierEntities = () => {
    return {
        type: CLEAR_SUPPLIER_ENTITIES
    };
};

export const clearOffers = () => {
    return {
        type: CLEAR_OFFERS
    };
};

export const clearSearchFilter = () => {
    return {
        type: CLEAR_SEARCH_FILTER
    };
};

export const clearSearchQuery = () => {
    return {
        type: CLEAR_SEARCH_QUERY
    };
};

export const saveLegalEntities = legalEntities => {
    return {
        type: SAVE_LEGAL_ENTITIES,
        payload: legalEntities
    };
};

export const saveSupplierEntities = supplierEntities => {
    return {
        type: SAVE_SUPPLIER_ENTITIES,
        payload: supplierEntities
    };
};

export const saveOffers = (offers, totalCount, startIndex) => {
    return {
        type: SAVE_OFFERS,
        payload: { offers, totalCount, startIndex }
    };
};

export const saveSearchQuery = query => {
    return {
        type: SAVE_SEARCH_QUERY,
        payload: query
    };
};

export const saveSearchFilter = filter => {
    return {
        type: SAVE_SEARCH_FILTER,
        payload: filter
    };
};

export const getOffers =
    ({
        title,
        entity_id,
        supplier_id,
        classification,
        limit,
        startIndex,
        status
    }) =>
    dispatch => {
        const displaySpinner = startIndex === 0;
        const offersStatus = status ? `['${status}']` : `['approved', 'draft']`;

        displaySpinner && dispatch(startSpinner());

        const params = {
            title: title || "",
            entity_id,
            supplier_id,
            classification,
            status: offersStatus,
            limit,
            start_index: startIndex
        };

        api.get("offers", params)
            .then(response => {
                dispatch(
                    saveOffers(
                        get(response, "data.results", []),
                        get(response, "data.total_count", 0),
                        startIndex
                    )
                );

                displaySpinner && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOffers: ", e);
                displaySpinner && dispatch(endSpinner());
            });
    };

export const deleteOffer = (url, successCb, errorCb = undefined) => {
    return async dispatch => {
        dispatch(startSpinner());

        try {
            await api.delete(`offers/${url}`);

            // Trigger dialogFlow function to update offers information
            dialogFlow.post();

            successCb &&
                successCb(() => {
                    dispatch(endSpinner());
                    dispatch(
                        openSnackbar(
                            translation.offers.openSnackbar.delete,
                            "success"
                        )
                    );
                });
        } catch (error) {
            console.log("Error calling deleteOffer: ", error);
            errorCb && errorCb();
            dispatch(endSpinner());
        }
    };
};

export const getLegalEntities = () => dispatch => {
    dispatch(clearLegalEntities());

    api.get("legalentities")
        .then(response => {
            dispatch(saveLegalEntities(get(response, "data", [])));
        })
        .catch(e => {
            console.log("Error calling getLegalEntities: ", e);
        });
};

export const getSupplierEntities = () => dispatch => {
    dispatch(clearSupplierEntities());

    api.get("entities")
        .then(response => {
            dispatch(saveSupplierEntities(get(response, "data", [])));
        })
        .catch(e => {
            console.log("Error calling getSupplierEntities: ", e);
        });
};
