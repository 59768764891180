import Global from "../../../../config/global.css";

const styles = {
    container: {
        backgroundColor: Global.colors.paleGrey,
        borderRadius: "12px 12px",
        padding: "24px 0px 4px",
        maxWidth: "680px",
        width: "100%"
    },
    noSeminars: {
        paddingBottom: "20px"
    },
    seminarsGrid: {
        paddingLeft: "38px",
        paddingRight: "38px"
    },
    seminarsRowContainer: {
        height: "unset",
        padding: "22px 0px 20px"
    },
    title: {
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "18px",
        padding: "0px 24px"
    }
};

export default styles;
