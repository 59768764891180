const styles = {
    container: {
        display: "grid",
        height: "fit-content",
        gap: "8px"
    },
    innerContainer: {
        display: "grid",
        gap: "24px"
    }
};

export default styles;
