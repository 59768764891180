// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./DataCard.css";

class DataCard extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        style: PropTypes.shape({
            container: PropTypes.object,
            title: PropTypes.object
        }),
        title: PropTypes.string
    };

    render() {
        const { children, style, title } = this.props;

        return (
            <div
                style={{ ...styles.container, ...get(style, "container", {}) }}
            >
                {title && (
                    <div
                        style={{ ...styles.title, ...get(style, "title", {}) }}
                    >
                        {title}
                    </div>
                )}
                {children}
            </div>
        );
    }
}

export default DataCard;
