// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { change, Field } from "redux-form";
// Components
import {
    CustomRadioButton,
    CustomTooltip
} from "../../../../../../../../../components/";
// Validators
import { required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./Classification.css";

class Classification extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        classification: PropTypes.string
    };

    state = {
        classification: this.props.classification
    };

    classifications = ["nationwide", "regional"];

    setClassification = newClassification => {
        const { dispatch } = this.props;
        const { classification } = this.state;

        newClassification !== classification &&
            this.setState({ classification: newClassification }, () => {
                dispatch(
                    change("offerDetails", "classification", newClassification)
                );
            });
    };

    RadioButton =
        option =>
        ({ ...props }) => {
            const hasError = Boolean(
                get(props, "meta.touched", false) &&
                    get(props, "meta.error", false)
            );

            return (
                <Fragment>
                    <CustomRadioButton {...props} />
                    <CustomTooltip
                        placement="right-end"
                        title={
                            translation.offers.editPopup.classification[option]
                                .tooltip
                        }
                    >
                        <span
                            className={"icon-info-file"}
                            style={{
                                ...styles.classification.tooltipIcon.default,
                                ...(hasError
                                    ? styles.classification.tooltipIcon.error
                                    : {})
                            }}
                        />
                    </CustomTooltip>
                </Fragment>
            );
        };

    render() {
        const { classification } = this.state;

        return (
            <div style={styles.container}>
                <span style={styles.title}>
                    {`${translation.offers.editPopup.classification.classification}:`}
                </span>
                <div style={styles.innerContainer}>
                    {this.classifications.map((option, index) => {
                        const isChecked = classification === option;

                        return (
                            <div
                                key={index}
                                style={styles.classification.container}
                            >
                                <Field
                                    component={this.RadioButton(option)}
                                    name={"classification"}
                                    props={{
                                        checked: isChecked,
                                        checkmarkDefaultClassName: isChecked
                                            ? "icon-check"
                                            : null,
                                        defaultClassName:
                                            "offer-classification-radio-button",
                                        label: translation.offers.editPopup
                                            .classification[option].label,
                                        onChange: () => {
                                            this.setClassification(option);
                                        },
                                        style: styles.classification.radio
                                    }}
                                    validate={required}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({}, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Classification);
