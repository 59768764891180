import Global from "../../config/global.css";

const styles = {
    backButton: {
        alignSelf: "flex-start",
        display: "flex"
    },
    container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        margin: "48px",
        minHeight: "min-content"
    },
    contentContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    footerContainer: {
        alignItems: "flex-end",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        flex: 1,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginTop: "48px",
        maxWidth: "240px"
    },
    inputsContainer: {
        margin: "22px 0px 32px"
    },
    logoContainer: {
        marginBottom: "112px",
        maxWidth: "240px"
    },
    noMargin: {
        margin: "0px"
    },
    subTitleContainer: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        margin: "15px 0px 32px",
        maxWidth: "240px",
        textAlign: "center"
    },
    titleContainer: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "30px",
        maxWidth: "240px",
        textAlign: "center"
    }
};

export default styles;
