// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash.get";
// Components
import Button from "@material-ui/core/Button";
// Styles
import styles from "./MaterialButton.css";

const CssButton = (customType, style) => {
    let customStyle = { root: get(styles, "root", {}) };
    customStyle.root = {
        ...customStyle.root,
        ...get(styles, customType, {}),
        ...get(style, "root", {})
    };
    customStyle.root["&:hover"] = {
        ...customStyle.root["&:hover"],
        ...get(style, "onHover", {})
    };
    customStyle.root["&:active"] = {
        ...customStyle.root["&:active"],
        ...get(style, "active", {})
    };

    return withStyles(customStyle)(Button);
};

class MaterialButton extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        classes: PropTypes.object,
        containerStyle: PropTypes.object,
        customType: PropTypes.oneOf(["boldPrimary", "default", "link"]),
        defaultClassName: PropTypes.string,
        disabled: PropTypes.bool,
        disableRipple: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        style: PropTypes.shape({
            active: PropTypes.object,
            onHover: PropTypes.object,
            root: PropTypes.object
        }),
        type: PropTypes.oneOf(["button", "submit"])
    };

    static defaultProps = {
        customType: "default",
        type: "button"
    };

    render() {
        const {
            children,
            classes,
            containerStyle,
            customType,
            defaultClassName,
            disabled,
            disableRipple,
            onClick,
            onMouseEnter,
            onMouseLeave,
            style,
            type
        } = this.props;

        const className = classNames(classes.root, defaultClassName, {
            [classes.default]: customType.includes("default"),
            [classes.link]: customType.includes("link"),
            [classes.boldPrimary]: customType.includes("boldPrimary")
        });

        const StyledButton = CssButton(customType, style);

        return (
            <div style={containerStyle}>
                <StyledButton
                    disableRipple={disableRipple}
                    className={className}
                    disabled={disabled}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={style}
                    variant={"text"}
                    type={type}
                >
                    {children}
                </StyledButton>
            </div>
        );
    }
}

export default withStyles(styles)(MaterialButton);
