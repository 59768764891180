// Modules
import get from "lodash.get";
// Api
import api from "../../../../../services/api";

export const CLEAR_LEGAL_ENTITIES = "users/CLEAR_LEGAL_ENTITIES";
export const SAVE_LEGAL_ENTITIES = "users/SAVE_LEGAL_ENTITIES";
export const CLEAR_COOPERATION_PARTNERS = "users/CLEAR_COOPERATION_PARTNERS";
export const SAVE_COOPERATION_PARTNERS = "users/SAVE_COOPERATION_PARTNERS";

export const defaultState = {
    legalEntities: undefined,
    cooperationPartner: undefined
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_LEGAL_ENTITIES: {
            return Object.assign({}, defaultState, {
                legalEntities: defaultState.legalEntities
            });
        }
        case SAVE_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: action.payload
            });
        }
        case CLEAR_COOPERATION_PARTNERS: {
            return Object.assign({}, defaultState, {
                cooperationPartner: defaultState.cooperationPartner
            });
        }
        case SAVE_COOPERATION_PARTNERS: {
            return Object.assign({}, state, {
                cooperationPartner: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearLegalEntities = () => {
    return {
        type: CLEAR_LEGAL_ENTITIES
    };
};

export const saveLegalEntities = legalEntities => {
    return {
        type: SAVE_LEGAL_ENTITIES,
        payload: legalEntities
    };
};

export const clearCooperationPartners = () => {
    return {
        type: CLEAR_COOPERATION_PARTNERS
    };
};

export const saveCooperationPartners = legalEntities => {
    return {
        type: SAVE_COOPERATION_PARTNERS,
        payload: legalEntities
    };
};

export const getLegalEntities = () => dispatch => {
    dispatch(clearLegalEntities());

    api.get("legalentities")
        .then(response => {
            dispatch(saveLegalEntities(get(response, "data", [])));
        })
        .catch(e => {
            console.log("Error calling getLegalEntities: ", e);
        });
};

export const getCooperationPartners = () => dispatch => {
    dispatch(clearCooperationPartners());

    api.get("cooperation_partners")
        .then(response => {
            dispatch(saveCooperationPartners(get(response, "data", [])));
        })
        .catch(e => {
            console.log("Error calling getCooperationPartners: ", e);
        });
};
