// Modules
import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Styles
import styles from "./Checkbox.css";

class Checkbox extends PureComponent {
    static propTypes = {
        checked: PropTypes.bool,
        classes: PropTypes.object,
        input: PropTypes.object,
        indeterminate: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        meta: PropTypes.object,
        onClick: PropTypes.func,
        style: PropTypes.shape({
            checkbox: PropTypes.object,
            container: PropTypes.object,
            input: PropTypes.object,
            label: PropTypes.object
        })
    };

    handleOnChange = e => {
        const { input, onClick } = this.props;

        input && input.onChange(!get(input, "checked", false));
        onClick && onClick(e);
    };

    render() {
        const { checked, classes, indeterminate, label, meta, style } =
            this.props;

        const isChecked = checked || get(this.props, "input.checked", false);
        const error = meta && Boolean(meta.touched && meta.error);

        return (
            <label
                className={classNames(
                    "checkbox-button-container",
                    classes.container
                )}
                style={get(style, "container", {})}
            >
                <input
                    style={{ ...styles.input, ...get(style, "input", {}) }}
                    checked={isChecked}
                    onChange={e => {
                        this.handleOnChange(e);
                    }}
                    type="checkbox"
                />
                <span
                    className={classNames(
                        classes.checkbox,
                        indeterminate
                            ? "icon-less"
                            : isChecked
                            ? "icon-check"
                            : null,
                        isChecked || indeterminate ? classes.checked : null,
                        error ? classes.error : null
                    )}
                    style={get(style, "checkbox", {})}
                />
                {label && (
                    <span
                        className={classNames(
                            classes.label,
                            error ? classes.labelError : null
                        )}
                        onChange={e => {
                            this.handleOnChange(e);
                        }}
                        style={get(style, "label", {})}
                    >
                        {label}
                    </span>
                )}
            </label>
        );
    }
}

export default withStyles(styles)(Checkbox);
