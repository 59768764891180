import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        padding: "20px",
        width: "100%"
    },
    tags: {
        container: {
            display: "flex",
            gap: "8px",
            flexWrap: "wrap"
        },
        label: {
            backgroundColor: Global.colors.darkSlateBlue,
            borderRadius: "4px",
            color: Global.colors.white,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "13px",
            minHeight: "24px",
            padding: "5.5px 8px",
            width: "fit-content"
        }
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "1.4em",
        paddingBottom: "16px",
        textTransform: "uppercase"
    }
};

export default styles;
