import Global from "../../../../../../../../../../../config/global.css";

const styles = {
    card: {
        container: {
            display: "grid",
            gap: "24px"
        },
        innerContainer: {
            display: "flex",
            flexDirection: "column"
        },
        title: {
            fontWeight: "bold"
        }
    },
    container: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(3, minmax(342px, 1fr))",
        padding: "24px"
    },
    innerContainer: {
        display: "grid",
        gap: "16px",
        gridAutoRows: "max-content"
    },
    link: {
        fontSize: "inherit",
        fontStyle: "inherit",
        fontWeight: "600",
        lineHeight: "inherit",
        maxHeight: "fit-content",
        textAlign: "start"
    },
    metaDataCard: {
        container: {
            backgroundColor: Global.colors.digitalBlueT4,
            gridAutoColumns: "auto 1fr",
            gridAutoFlow: "column"
        }
    },
    metaDataCardBody: {
        color: Global.colors.darkSlateBlue,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px",
        textAlign: "end",
        textTransform: "uppercase"
    },
    noWordBreak: {
        whiteSpace: "nowrap"
    },
    picture: {
        borderRadius: "12px",
        maxWidth: "120px"
    }
};

export default styles;
