import Global from "../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "minmax(608px, 60%)",
        gridTemplateRows: "62px min-content",
        padding: "32px 24px"
    },
    textField: {
        width: "100%"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
