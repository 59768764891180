import Global from "../../../../config/global.css";

const styles = {
    border: {
        borderTop: `2px dashed ${Global.colors.lighterSlateBlue}`
    },
    container: {
        alignItems: "center",
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr auto 1fr",
        width: "100%"
    },
    messageContainer: {
        background: Global.colors.lighterSlateBlue,
        borderRadius: "100px",
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "40px",
        lineHeight: "18px",
        margin: "0px 8px",
        padding: "12px 24px",
        textAlign: "center"
    }
};

export default styles;
