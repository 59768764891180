// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Drawer } from "@material-ui/core";
import { SubMenuItem } from "./components";
// Action
import { navigateTo } from "../../../../Router.ducks";
// Helpers
import {
    hasMainPermission,
    routesMainPermissionsMapping
} from "../../../../../../services/permissions";
// Translation
import translation from "../../../../../../config/translation";
// Styles
import styles from "./SubMenu.css";

class SubMenu extends PureComponent {
    static propTypes = {
        activeMenu: PropTypes.object,
        activeSubMenu: PropTypes.object,
        classes: PropTypes.object,
        location: PropTypes.object,
        navigateTo: PropTypes.func.isRequired,
        updateActiveSubMenu: PropTypes.func.isRequired,
        userRole: PropTypes.object
    };

    state = {
        opened: false
    };

    componentDidMount() {
        const { activeSubMenu } = this.props;

        const opened = !!activeSubMenu;

        this.state.opened !== opened &&
            this.setState({ opened }, () => {
                this.updateChildrenContainer(opened);
            });
    }

    componentDidUpdate(prevProps) {
        const { activeSubMenu } = this.props;

        if (get(prevProps, "activeSubMenu", undefined) !== activeSubMenu) {
            const opened = !!activeSubMenu;
            this.state.opened !== opened &&
                this.setState({ opened }, () => {
                    this.updateChildrenContainer(opened);
                });
        }
    }

    updateChildrenContainer = opened => {
        const element = document.getElementsByClassName(
            "dashboard-children-container"
        )[0];

        element.style.marginLeft = opened ? "232px" : "0px";
        element.style.transition = opened
            ? "margin 150ms linear 0ms"
            : "margin 100ms linear 0ms";
        element.style.flexGrow = opened ? "unset" : "1";
    };

    handleSubMenuItemClick = submenu => {
        if (!submenu) {
            return null;
        }
        const { activeSubMenu, navigateTo, updateActiveSubMenu } = this.props;

        const subMenusWithDeepLevels = ["offersOverview"];

        if (
            !activeSubMenu ||
            activeSubMenu.key !== submenu.key ||
            subMenusWithDeepLevels.includes(submenu.key)
        ) {
            updateActiveSubMenu && updateActiveSubMenu(submenu);
            navigateTo && navigateTo(submenu.path);
        }
    };

    render() {
        const { activeMenu, activeSubMenu, classes, userRole } = this.props;
        const { opened } = this.state;

        const subMenus = get(activeMenu, "subMenus", []);

        const navMenusWithPermissions =
            subMenus &&
            subMenus.filter(submenu =>
                hasMainPermission(
                    get(userRole, "permissions", {}),
                    get(routesMainPermissionsMapping, submenu.path, undefined)
                )
            );

        return (
            <Drawer
                variant="persistent"
                anchor="left"
                open={opened}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div style={styles.container}>
                    <div style={styles.headerTitle}>
                        {translation.navigationMenu[activeMenu?.key]}
                    </div>
                    {navMenusWithPermissions.map((submenu, index) => {
                        return (
                            <SubMenuItem
                                key={index}
                                active={
                                    activeSubMenu &&
                                    activeSubMenu.key === submenu.key
                                }
                                icon={get(submenu, "icon", undefined)}
                                id={submenu.key}
                                label={get(
                                    translation,
                                    `navigationMenu.${get(
                                        submenu,
                                        "key",
                                        undefined
                                    )}`,
                                    undefined
                                )}
                                onClick={() => {
                                    this.handleSubMenuItemClick(submenu);
                                }}
                            />
                        );
                    })}
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        userRole: state.profileData.role
    };
};

const mapDispatchToProps = {
    navigateTo
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(SubMenu);
