// Modules
import get from "lodash.get";

/**
 * Checks if there is any entries.
 *
 * @param {Object} entries the object with the entries per section
 * @returns true if there is entries, false otherwise
 */
export const hasEntries = entries => {
    return (
        entries &&
        Object.values(entries).length > 0 &&
        Object.values(entries).reduce(reduceArraysFunction).length > 0
    );
};

/**
 * Gets the check and indeterminate status of the main checkbox.
 *
 * @param {Object} params the bulk selection and the current entries being displayed
 * @returns the check and indeterminate status of the main checkbox
 */
export const getMainCheckboxStatus = ({ bulkSelection, entries }) => {
    const bulkSelectionLength = bulkSelection && bulkSelection.length;

    const entriesLength =
        entries &&
        Object.values(entries).length > 0 &&
        Object.values(entries).reduce(reduceArraysFunction).length;

    const isGroupChecked = bulkSelectionLength === entriesLength;
    const isGroupIndeterminate =
        bulkSelectionLength > 0 && bulkSelectionLength < entriesLength;

    return { isGroupChecked, isGroupIndeterminate };
};

/**
 * Gets the check and indeterminate status of the section checkbox.
 *
 * @param {Object} params the bulk selection and the section and section entries being displayed
 * @returns the check and indeterminate status of the section checkbox
 */
export const getSectionCheckboxStatus = ({
    bulkSelection,
    sectionEntries,
    section
}) => {
    const sectionEntriesLength = sectionEntries ? sectionEntries.length : 0;
    const sectionBulkSelectionLength =
        bulkSelection &&
        bulkSelection.filter(e => get(e, "status", undefined) === section)
            .length;

    const isSectionChecked =
        sectionEntriesLength > 0 &&
        sectionBulkSelectionLength === sectionEntriesLength;

    const isSectionIndeterminate =
        sectionBulkSelectionLength > 0 &&
        sectionBulkSelectionLength < sectionEntriesLength;

    return { isSectionChecked, isSectionIndeterminate };
};

/**
 * Checks if a single entry checkbox is selected.
 *
 * @param {Object} params the entry to check and the bulk selection
 * @returns true if it is selected, false otherwise
 */
export const isEntryCheckboxChecked = ({ bulkSelection, entry }) => {
    return (
        bulkSelection &&
        bulkSelection.some(
            e => get(e, "_id", undefined) === get(entry, "_id", undefined)
        )
    );
};

/**
 * Joins multiple arrays into one.
 *
 * @param {Array} accumulator the accumulator array
 * @param {Array} currentValue the array to merge
 * @returns an array will all elements
 */
export const reduceArraysFunction = (accumulator, currentValue) =>
    accumulator.concat(currentValue);

/**
 * Gets the new list of bulk selection based on the operation.
 *
 * @param {Object} params the params related to the operation and current entries and bulk selection
 * @returns the new list of bulk selected
 */
export const composeNewBulkSelectionBasedOnAction = ({
    operation,
    entry,
    section,
    bulkSelection,
    entries
}) => {
    let newBulkSelection = [];
    if (
        !["unselectAll", "selectAll"].includes(operation) &&
        !entry &&
        !section
    ) {
        return;
    } else if (operation === "selectAll") {
        if (section) {
            const sectionAlreadySelected = bulkSelection
                .filter(e => get(e, "status", undefined) === section)
                .map(e => get(e, "_id", undefined));
            newBulkSelection = [
                ...bulkSelection,
                ...get(entries, section, [])
                    .filter(
                        e =>
                            !sectionAlreadySelected.includes(
                                get(e, "_id", undefined)
                            )
                    )
                    .map(e => ({
                        ...e,
                        status: section
                    }))
            ];
        } else {
            Object.entries(entries).forEach(([key, value]) => {
                newBulkSelection = newBulkSelection.concat(
                    value.map(e => ({
                        ...e,
                        status: key
                    }))
                );
            });
        }
    } else if (operation === "unselectAll" && section) {
        newBulkSelection = bulkSelection.filter(
            selection => get(selection, "status", undefined) !== section
        );
    } else if (operation === "select") {
        newBulkSelection = [...bulkSelection, { ...entry, status: section }];
    } else if (operation === "unselect") {
        newBulkSelection = bulkSelection.filter(
            selection =>
                get(selection, "_id", undefined) !==
                get(entry, "_id", undefined)
        );
    }

    return newBulkSelection;
};
