// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { DataCard } from "../../..";
import { Images } from "../";
import { MaterialButton } from "../../../../../../../../../components/";
import { ClassificationTag } from "../../../../../../../components/";
// Helpers
import { validateLink } from "../../../../../../../helpers/links";
import { parseHtmlData } from "../../../../../../../../../services/htmlDataParsing";
// Translation
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./LeftContainer.css";

class LeftContainer extends PureComponent {
    static propTypes = {
        details: PropTypes.object.isRequired
    };

    renderImages = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const images = medias.filter(media => media.type === "image");

        return <Images images={images} type="images" />;
    };

    renderLink = (mediaType = "link") => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const links = medias.filter(media => media.type === mediaType);

        if (!links || links.length === 0) {
            return null;
        }

        return links.map((link, index) => {
            const linkUrl = get(link, "content", undefined);
            return (
                linkUrl && (
                    <DataCard
                        key={index}
                        title={
                            translation.offerDetails.details
                                .marketingMaterialsLink
                        }
                    >
                        <MaterialButton
                            customType="link"
                            disableRipple
                            onClick={() => {
                                window.open(validateLink(linkUrl), "_blank");
                            }}
                            style={styles.link}
                        >
                            {linkUrl}
                        </MaterialButton>
                    </DataCard>
                )
            );
        });
    };

    renderAdditionalLinks = () => {
        const { details } = this.props;

        const medias = get(details, "media_details", []);
        const links = medias.filter(media => media.type === "additional_link");

        if (!links || links.length === 0) {
            return null;
        }

        return (
            <DataCard
                style={styles.card.innerContainer}
                title={translation.offerDetails.details.additionalLinks}
            >
                {links.map((link, index) => {
                    const linkUrl = get(link, "content", undefined);
                    const linkTitle = get(link, "title", undefined);
                    return (
                        <MaterialButton
                            customType="link"
                            disableRipple
                            key={index}
                            onClick={() => {
                                window.open(validateLink(linkUrl), "_blank");
                            }}
                            style={styles.link}
                        >
                            {linkTitle}
                        </MaterialButton>
                    );
                })}
            </DataCard>
        );
    };

    render() {
        const { details } = this.props;

        if (!details) {
            return null;
        }

        const displayTitle = get(details, "display_title", undefined);
        const shortDescription = get(details, "short_description", undefined);
        const keywords = get(details, "keywords", []).sort((a, b) =>
            get(a, "keyword", "").localeCompare(get(b, "keyword", ""))
        );
        const shortDescriptionParsed = parseHtmlData(shortDescription);
        const description = parseHtmlData(
            get(details, "formatted_description", undefined)
        );
        const voiceDescription = get(details, "voice_description", undefined);
        const voiceDescriptionParsed = parseHtmlData(voiceDescription);
        const classification = get(details, "classification", undefined);
        const status = get(details, "status", undefined);
        const url = get(details, "url", undefined);
        const cmsUrl = get(details, "cms_url", undefined);

        const websiteUrl = `${process.env.REACT_APP_WEBSITE_URL_OFFERS}/${url}`;
        const wordpressCmsUrl = `${process.env.REACT_APP_WORDPRESS_URL_CMS}/${cmsUrl}`;
        return (
            <div style={styles.container}>
                {status === "approved" ? (
                    <div style={styles.approvedStatus}>
                        {translation.offerDetails.details.approvedStatus}
                    </div>
                ) : (
                    <div style={styles.draftStatus}>
                        {translation.offerDetails.details.draftStatus}
                    </div>
                )}

                <DataCard
                    style={styles.keywords.container}
                    title={translation.offerDetails.details.keywords}
                >
                    {keywords && keywords.length > 0 ? (
                        <div style={styles.keywords.innerContainer}>
                            {keywords.map((keyword, index) => (
                                <div
                                    key={index}
                                    style={{
                                        ...styles.keywords.text,
                                        ...styles.keywords.boxContainer
                                    }}
                                >
                                    {get(keyword, "keyword", undefined)}
                                </div>
                            ))}
                        </div>
                    ) : (
                        "-"
                    )}
                </DataCard>
                {status === "approved" && (
                    <DataCard title={translation.offerDetails.details.url}>
                        <MaterialButton
                            customType="link"
                            disableRipple
                            onClick={() => {
                                window.open(websiteUrl, "_blank");
                            }}
                            style={styles.link}
                        >
                            {websiteUrl}
                        </MaterialButton>
                    </DataCard>
                )}
                {cmsUrl && (
                    <DataCard title={translation.offerDetails.details.cmsUrl}>
                        <MaterialButton
                            customType="link"
                            disableRipple
                            onClick={() => {
                                window.open(wordpressCmsUrl, "_blank");
                            }}
                            style={styles.link}
                        >
                            {wordpressCmsUrl}
                        </MaterialButton>
                    </DataCard>
                )}
                {classification && (
                    <DataCard
                        title={translation.offerDetails.details.classification}
                    >
                        <ClassificationTag classification={classification} />
                    </DataCard>
                )}
                {displayTitle && (
                    <DataCard
                        title={translation.offerDetails.details.displayTitle}
                    >
                        {displayTitle}
                    </DataCard>
                )}
                {shortDescription && (
                    <DataCard
                        title={
                            translation.offerDetails.details.shortDescription
                        }
                    >
                        <div
                            className="offer-short-description"
                            dangerouslySetInnerHTML={shortDescriptionParsed}
                            style={styles.multilineDescription}
                        />
                    </DataCard>
                )}
                {voiceDescription && (
                    <DataCard
                        title={
                            translation.offerDetails.details.voiceDescription
                        }
                    >
                        <div
                            className="offer-voice-description"
                            dangerouslySetInnerHTML={voiceDescriptionParsed}
                            style={styles.multilineDescription}
                        />
                    </DataCard>
                )}
                <DataCard
                    title={translation.offerDetails.details.longDescription}
                >
                    <div
                        className="offer-description"
                        dangerouslySetInnerHTML={description}
                        style={styles.description}
                    />
                </DataCard>
                {this.renderLink()}
                {this.renderAdditionalLinks()}
                {this.renderImages()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details
    };
};

export default connect(mapStateToProps)(LeftContainer);
