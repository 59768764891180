// Translation
import translation from "../../../../../../config/translation";

export const data = [
    {
        index: 1,
        title: translation.userManual.subTopics.overview,
        description: translation.userManual.descriptions.userManagement["1"],
        image: require("../../../../../../images/Benutzerverwaltung_1.png")
            .default
    },
    {
        index: 2,
        title: translation.userManual.subTopics.edit,
        description: translation.userManual.descriptions.userManagement["2"],
        image: require("../../../../../../images/Benutzerverwaltung_2.png")
            .default
    },
    {
        index: 3,
        title: translation.userManual.subTopics.create,
        description: translation.userManual.descriptions.userManagement["3"],
        image: require("../../../../../../images/Benutzerverwaltung_3.png")
            .default
    }
];
