import Global from "../../../../../../../../../../../config/global.css";

const styles = {
    addButton: {
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "16px",
        justifySelf: "flex-end",
        width: "fit-content",
        onHover: {
            backgroundColor: "unset",
            color: `${Global.colors.blue} !important`
        }
    },
    addButtonLabel: {
        fontFamily: "Muli",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginLeft: "10px"
    },
    innerContainer: {
        display: "grid",
        gap: "18px",
        gridTemplateRows: "62px 62px",
        height: "fit-content"
    },
    container: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "fit-content"
    },
    deleteButton: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        height: "24px",
        justifySelf: "end",
        width: "24px"
    },
    label: {
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px"
    },
    mainContainer: {
        display: "grid",
        gap: "12px",
        height: "fit-content"
    },
    textField: {
        width: "100%"
    }
};

export default styles;
