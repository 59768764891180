// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { isArray } from "lodash";
// Components
import { TableHeaders, WarningPopup } from "../../../../../components";
import {
    EditLegalEntitieSuppliersForm,
    EditSupplierLegalEntitiesForm
} from "./components";
// Actions
import {
    closeEditPopup,
    openEditPopup
} from "../../../../../components/EditPopup/EditPopup.ducks";
import { navigateTo } from "../../../Router.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import {
    upsertMapping,
    deleteSupplierSeminars
} from "../../EntitiesMapping.ducks";
// Helpers
import { routesPaths } from "../../../components/Routes/Routes.helper";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./LegalEntitiesSuppliers.css";

class LegalEntitiesSuppliers extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        dispatch: PropTypes.func,
        editPopup: PropTypes.object,
        entries: PropTypes.array.isRequired,
        entityIdUrlParam: PropTypes.string,
        isUnmappedEntitiesView: PropTypes.bool,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        upsertMapping: PropTypes.func.isRequired,
        deleteSupplierSeminars: PropTypes.func.isRequired,
        RowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
            .isRequired,
        tab: PropTypes.string.isRequired,
        location: PropTypes.object,
        tableHeaders: PropTypes.array.isRequired,
        navigateTo: PropTypes.func,
        isSupplierTab: PropTypes.bool
    };

    state = {
        foundEntries: false,
        editPopupOpened: false
    };

    componentDidMount() {
        this.handleOpenEditPopupWithUrlParams(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entries !== this.props.entries) {
            this.handleOpenEditPopupWithUrlParams(this.props);
        }
    }

    generateUrlParams = ({ operation = null }) => {
        const { location } = this.props;

        const urlParams = new URLSearchParams(location?.search);
        const prevTab = urlParams.get("prevTab");

        const path = prevTab
            ? `${location?.state?.pathname}?prevTab=${prevTab}`
            : operation === "delete"
            ? routesPaths.offersOverview
            : location?.state?.pathname;

        return path;
    };

    handleOpenEditPopupWithUrlParams = props => {
        const { entries, entityIdUrlParam } = props;
        const { editPopupOpened } = this.state;

        if (entries.length > 0) {
            const entry = entries.find(
                entity => entity._id === entityIdUrlParam
            );

            if (entry && !editPopupOpened) {
                this.openEditPopup(entry);
                this.setState({ foundEntries: true, editPopupOpened: true });
            } else {
                this.setState({ foundEntries: true });
            }
        } else {
            this.setState({ foundEntries: false });
        }
    };

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    handleUnlinkEntities = mapping => {
        const {
            closeEditPopup,
            closePopup,
            openPopup,
            tab,
            upsertMapping,
            location,
            navigateTo
        } = this.props;

        let removeSuppliers = [];

        if (tab === "legalEntities") {
            removeSuppliers = [
                {
                    legalEntity: mapping._id,
                    suppliers: mapping.suppliers
                }
            ];
        } else {
            removeSuppliers = mapping.legal_entities.map(({ _id }) => {
                return {
                    legalEntity: _id,
                    suppliers: [mapping._id]
                };
            });
        }

        const data = {
            addSuppliers: [],
            removeSuppliers,
            operation: "update",
            tab,
            type: "unmapp"
        };

        const path = this.generateUrlParams({});

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        upsertMapping &&
                            upsertMapping(
                                data,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                    if (location?.search) {
                                        navigateTo && navigateTo(path);
                                    }
                                },
                                () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                }}
                                                buttonLabel={
                                                    translation.entitiesMapping
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation.entitiesMapping
                                                        .errors.errorDelete
                                                }
                                                title={
                                                    translation.entitiesMapping
                                                        .errors.genericTitle
                                                }
                                            />
                                        )
                                    });
                                }
                            );
                    }}
                    buttonLabel={translation.entitiesMapping.editPopup.confirm}
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.entitiesMapping.editPopup
                            .deleteMappingMessage
                    }
                    title={
                        translation.entitiesMapping.editPopup.deleteMappingTitle
                    }
                />
            )
        });
    };

    handleDeleteEventsFromEntity = supplier => {
        const {
            closeEditPopup,
            closePopup,
            openPopup,
            deleteSupplierSeminars,
            location,
            navigateTo
        } = this.props;

        const path = this.generateUrlParams({ operation: "delete" });

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        deleteSupplierSeminars &&
                            deleteSupplierSeminars(
                                supplier._id,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                    if (location?.search) {
                                        navigateTo && navigateTo(path);
                                    }
                                },
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                    if (location?.search) {
                                        navigateTo && navigateTo(path);
                                    }
                                }
                            );
                    }}
                    buttonLabel={translation.entitiesMapping.editPopup.delete}
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.entitiesMapping.editPopup
                            .deleteEventsMessage
                    }
                    title={
                        translation.entitiesMapping.editPopup.deleteEventsTitle
                    }
                />
            )
        });
    };

    handleCloseEditPopup = () => {
        const { location, navigateTo, closeEditPopup } = this.props;

        const path = this.generateUrlParams({});

        if (location.search) {
            navigateTo && navigateTo(path);
        }
        closeEditPopup && closeEditPopup();
    };

    handleConfirmAction = () => {
        const { location, navigateTo, dispatch } = this.props;

        const path = this.generateUrlParams({});

        if (location.search) {
            navigateTo && navigateTo(path);
        }
        dispatch(submit("editLegalEntityMapping"));
    };

    openEditPopup = entry => {
        const { isUnmappedEntitiesView, openEditPopup, tab, isSupplierTab } =
            this.props;

        let editPopupParams = {
            cancelAction: this.handleCloseEditPopup,
            cancelButtonLabel: translation.entitiesMapping.editPopup.cancel,
            confirmAction: this.handleConfirmAction,
            confirmButtonLabel:
                translation.entitiesMapping.editPopup.saveChanges,
            optionalAction: !isUnmappedEntitiesView
                ? () => this.handleUnlinkEntities(entry)
                : undefined,
            optionalButtonLabel:
                translation.entitiesMapping.editPopup.deleteMapping,
            optionalDeleteAction: isSupplierTab
                ? () => this.handleDeleteEventsFromEntity(entry)
                : undefined,
            optionalDeleteButtonLabel:
                translation.entitiesMapping.editPopup.deleteEvents,
            style: styles.editPopup
        };

        if (tab === "legalEntities") {
            editPopupParams = {
                ...editPopupParams,
                children: <EditLegalEntitieSuppliersForm entry={entry} />,
                header: translation.entitiesMapping.editPopup.editMapping
            };
        } else if (tab === "suppliers") {
            editPopupParams = {
                ...editPopupParams,
                children: <EditSupplierLegalEntitiesForm entry={entry} />,
                header: translation.entitiesMapping.editPopup.editMapping
            };
        }

        openEditPopup && openEditPopup(editPopupParams);
    };

    prepareTableEntries = () => {
        const { tab, entries, isUnmappedEntitiesView } = this.props;
        const sortedEntries = entries.sort((a, b) =>
            a["name"].localeCompare(b["name"])
        );

        if (tab === "legalEntities" || isUnmappedEntitiesView) {
            return sortedEntries;
        } else {
            let sortedSuppliers = [];

            (sortedEntries || []).map(supplier =>
                supplier.legal_entities.map(legalEntity =>
                    sortedSuppliers.push({
                        _id: supplier._id,
                        name: supplier.name,
                        legal_entities: [legalEntity],
                        tax_type: supplier.tax_type
                    })
                )
            );

            return sortedSuppliers;
        }
    };

    render() {
        const { RowComponent, tableHeaders, isUnmappedEntitiesView } =
            this.props;

        const tableEntries = this.prepareTableEntries();

        return (
            <Fragment>
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...styles.tableHeaders
                    }}
                    headers={tableHeaders}
                />
                <div style={styles.rowsContainer}>
                    {tableEntries &&
                        tableEntries.map((entry, index) =>
                            RowComponent ? (
                                <RowComponent
                                    isUnmappedEntitiesView={
                                        isUnmappedEntitiesView
                                    }
                                    containerStyle={styles.tableGrid}
                                    entry={entry}
                                    key={index}
                                    openEditPopup={() => {
                                        this.openEditPopup(entry);
                                    }}
                                />
                            ) : null
                        )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const tab = get(props, "tab", undefined);
    const isUnmappedEntitiesView = get(
        props,
        "isUnmappedEntitiesView",
        undefined
    );
    const isSupplierTab = tab === "suppliers";

    const entries = isArray(
        get(state, `entitiesMapping.dataByTab.${tab}.entries`, [])
    )
        ? get(state, `entitiesMapping.dataByTab.${tab}.entries`, [])
        : [];

    const formatedEntries = entries.filter(entry => {
        if (tab === "legalEntities") {
            return isUnmappedEntitiesView
                ? entry.suppliers?.length === 0
                : entry.suppliers?.length > 0;
        } else {
            return isUnmappedEntitiesView
                ? entry.legal_entities?.length === 0
                : entry.legal_entities?.length > 0;
        }
    });

    return {
        editPopup: state.editPopup,
        entries: formatedEntries,
        popup: state.popup,
        tab: state.entitiesMapping.tab,
        isSupplierTab: isSupplierTab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeEditPopup,
                closePopup,
                openEditPopup,
                openPopup,
                upsertMapping,
                navigateTo,
                deleteSupplierSeminars
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LegalEntitiesSuppliers);
