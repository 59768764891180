// Modules
import React, { Component } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./VideoPlayer.css";

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.processingLineRef = React.createRef();
    }
    static propTypes = {
        videoToRender: PropTypes.string
    };

    renderCustomVideoPlayer = videoToRender => {
        return (
            <div style={styles.container}>
                <video
                    ref={this.videoRef}
                    src={videoToRender}
                    style={styles.video}
                    controls
                />
            </div>
        );
    };

    render() {
        const { videoToRender } = this.props;

        if (!videoToRender) {
            return null;
        }

        return this.renderCustomVideoPlayer(videoToRender);
    }
}

export default VideoPlayer;
