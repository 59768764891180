import Global from "../../config/global.css";

const styles = {
    tooltipLeftPlacement: {
        margin: "0px 0px 2px 2px"
    },
    tooltipRight: {
        backgroundColor: Global.colors.darkSlateBlue,
        borderRadius: "4px",
        color: Global.colors.white,
        fontSize: "8px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "11px",
        maxWidth: "262px",
        padding: "4px 6px"
    },
    tooltipRightPlacement: {
        margin: "0px 2px 2px 0px"
    },
    tooltipTop: {
        backgroundColor: Global.colors.paleGrey,
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "11px",
        padding: "4px",
        textTransform: "uppercase"
    },
    tooltipTopPlacement: {
        margin: "2px 0px"
    }
};

export default styles;
