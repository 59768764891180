// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { isArray } from "lodash";
// Components
import { TableHeaders, WarningPopup } from "../../../../../components";
import {
    EditLegalEntitieCoopPartnersForm,
    EditCoopPartnerLegalEntitiesForm
} from "./components";
// Actions
import {
    closeEditPopup,
    openEditPopup
} from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import {
    upsertMapping,
    deleteCoopPartner
} from "../../CoopPartnersMapping.ducks";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./LegalEntitiesCoopPartners.css";

class LegalEntitiesCoopPartners extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        dispatch: PropTypes.func,
        editPopup: PropTypes.object,
        entries: PropTypes.array.isRequired,
        entityIdUrlParam: PropTypes.string,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        upsertMapping: PropTypes.func.isRequired,
        deleteCoopPartner: PropTypes.func.isRequired,
        RowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
            .isRequired,
        tab: PropTypes.string.isRequired,
        location: PropTypes.object,
        tableHeaders: PropTypes.array.isRequired,
        isCoopPartnersTab: PropTypes.bool
    };

    state = {
        foundEntries: false,
        editPopupOpened: false
    };

    componentDidMount() {
        this.handleOpenEditPopupWithUrlParams(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entries !== this.props.entries) {
            this.handleOpenEditPopupWithUrlParams(this.props);
        }
    }

    handleOpenEditPopupWithUrlParams = props => {
        const { entries, entityIdUrlParam } = props;
        const { editPopupOpened } = this.state;

        if (entries.length > 0) {
            const entry = entries.find(
                entity => entity._id === entityIdUrlParam
            );

            if (entry && !editPopupOpened) {
                this.openEditPopup(entry);
                this.setState({ foundEntries: true, editPopupOpened: true });
            } else {
                this.setState({ foundEntries: true });
            }
        } else {
            this.setState({ foundEntries: false });
        }
    };

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    handleUnlinkEntities = mapping => {
        const { closeEditPopup, closePopup, openPopup, tab, upsertMapping } =
            this.props;

        let removeCoopPartners = [];

        if (tab === "legalEntities") {
            removeCoopPartners = [
                {
                    legalEntity: mapping._id,
                    coopPartners: mapping.coopPartners
                }
            ];
        } else {
            removeCoopPartners = mapping.legal_entities.map(({ _id }) => {
                return {
                    legalEntity: _id,
                    coopPartners: [mapping._id]
                };
            });
        }

        const data = {
            tab,
            addCoopPartners: [],
            removeCoopPartners,
            operation: "update",
            type: "unmapp"
        };

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        upsertMapping &&
                            upsertMapping(
                                data,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                },
                                () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                }}
                                                buttonLabel={
                                                    translation
                                                        .coopPartnersMapping
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation
                                                        .coopPartnersMapping
                                                        .errors.errorDelete
                                                }
                                                title={
                                                    translation
                                                        .coopPartnersMapping
                                                        .errors.genericTitle
                                                }
                                            />
                                        )
                                    });
                                }
                            );
                    }}
                    buttonLabel={
                        translation.coopPartnersMapping.editPopup.confirm
                    }
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.coopPartnersMapping.editPopup
                            .deleteMappingMessage
                    }
                    title={
                        translation.coopPartnersMapping.editPopup
                            .deleteMappingTitle
                    }
                />
            )
        });
    };

    handleDeleteCooperationPartner = cooppartner => {
        const { closeEditPopup, closePopup, openPopup, deleteCoopPartner } =
            this.props;

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        deleteCoopPartner &&
                            deleteCoopPartner(
                                cooppartner._id,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                },
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                }
                            );
                    }}
                    buttonLabel={
                        translation.coopPartnersMapping.editPopup.delete
                    }
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.coopPartnersMapping.editPopup
                            .deleteCoopPartnerMessage
                    }
                    title={
                        translation.coopPartnersMapping.editPopup
                            .deleteCoopPartnerTitle
                    }
                />
            )
        });
    };

    handleCloseEditPopup = () => {
        const { closeEditPopup } = this.props;

        closeEditPopup && closeEditPopup();
    };

    handleConfirmAction = () => {
        const { dispatch } = this.props;

        dispatch(submit("editLegalEntityMapping"));
    };

    openEditPopup = entry => {
        const { openEditPopup, tab, isCoopPartnersTab } = this.props;
        const { legal_entities: noLegalEntityMapped } = entry || {};

        let editPopupParams = {
            cancelAction: this.handleCloseEditPopup,
            cancelButtonLabel: translation.coopPartnersMapping.editPopup.cancel,
            confirmAction: this.handleConfirmAction,
            confirmButtonLabel:
                translation.coopPartnersMapping.editPopup.saveChanges,
            optionalAction:
                noLegalEntityMapped?.length > 0
                    ? () => this.handleUnlinkEntities(entry)
                    : undefined,
            optionalButtonLabel:
                translation.coopPartnersMapping.editPopup.deleteMapping,
            optionalDeleteAction: isCoopPartnersTab
                ? () => this.handleDeleteCooperationPartner(entry)
                : undefined,
            optionalDeleteButtonLabel:
                translation.coopPartnersMapping.editPopup.deleteCoopPartner,
            style: styles.editPopup
        };

        if (tab === "legalEntities") {
            editPopupParams = {
                ...editPopupParams,
                children: <EditLegalEntitieCoopPartnersForm entry={entry} />,
                header: translation.coopPartnersMapping.editPopup.editMapping
            };
        } else if (tab === "coopPartners") {
            editPopupParams = {
                ...editPopupParams,
                children: <EditCoopPartnerLegalEntitiesForm entry={entry} />,
                header: translation.coopPartnersMapping.editPopup.editMapping
            };
        }

        openEditPopup && openEditPopup(editPopupParams);
    };

    render() {
        const { RowComponent, tableHeaders, entries } = this.props;

        return (
            <Fragment>
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...styles.tableHeaders
                    }}
                    headers={tableHeaders}
                />
                <div style={styles.rowsContainer}>
                    {entries &&
                        entries.map((entry, index) =>
                            RowComponent ? (
                                <RowComponent
                                    containerStyle={styles.tableGrid}
                                    entry={entry}
                                    key={index}
                                    openEditPopup={() => {
                                        this.openEditPopup(entry);
                                    }}
                                />
                            ) : null
                        )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const tab = get(props, "tab", undefined);
    const isCoopPartnersTab = tab === "coopPartners";

    const entries = isArray(
        get(state, `coopPartnersMapping.dataByTab.${tab}.entries`, [])
    )
        ? get(state, `coopPartnersMapping.dataByTab.${tab}.entries`, [])
        : [];

    return {
        editPopup: state.editPopup,
        entries: entries,
        popup: state.popup,
        tab: state.coopPartnersMapping.tab,
        isCoopPartnersTab: isCoopPartnersTab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeEditPopup,
                closePopup,
                openEditPopup,
                openPopup,
                upsertMapping,
                deleteCoopPartner
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LegalEntitiesCoopPartners);
