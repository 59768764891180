// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { IconButton, Profile } from "../../../../components/";
// Action
import { logout, navigateTo } from "../../Router.ducks";
// Helpers
import { routesPaths } from "../Routes/Routes.helper";
import { hasUserManualPermission } from "../NavigationBar/helpers/navigationHelper";
// Translation
import translation from "../../../../config/translation";
// Styles
import styles from "./TopBar.css";

class TopBar extends PureComponent {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        classes: PropTypes.object,
        location: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
        navigateTo: PropTypes.func.isRequired,
        profileData: PropTypes.object
    };

    componentDidMount() {
        if (this.isSettingsPath()) {
            this.navigateToSettings();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname }
        } = this.props;

        if (
            this.isSettingsPath() &&
            pathname !== get(prevProps, "location.pathname", undefined)
        ) {
            this.navigateToSettings();
        }
    }

    isSettingsPath = () => {
        const {
            location: { pathname }
        } = this.props;

        return pathname === routesPaths.settings;
    };

    navigateToSettings() {
        const { navigateTo } = this.props;

        navigateTo(routesPaths.settings);
    }

    navigateToUserManual = () => {
        const { navigateTo } = this.props;

        if (this.isUserManualActive()) {
            return;
        }

        navigateTo(routesPaths.userManual);
    };

    isUserManualActive = () => {
        const {
            location: { pathname }
        } = this.props;

        return pathname === routesPaths.userManual;
    };

    render() {
        const { auth, classes, logout, profileData } = this.props;

        const userManualPermission = hasUserManualPermission(
            get(profileData, "role", {})
        );

        const isUserManualActive = this.isUserManualActive();

        return (
            <div style={styles.container}>
                <img
                    alt={"logo"}
                    style={styles.logo}
                    src={
                        require("../../../../images/logo_DIHK-gGmbH.svg")
                            .default
                    }
                />
                <div style={styles.rightContainer}>
                    {userManualPermission && (
                        <IconButton
                            defaultClassName="icon-user-manual"
                            onClick={this.navigateToUserManual}
                            style={{
                                ...styles.icon,
                                ...(isUserManualActive
                                    ? styles.buttonSelected
                                    : {})
                            }}
                            type={["default"]}
                            TouchRippleProps={{
                                classes: {
                                    child: classNames(classes.iconRippleChild)
                                }
                            }}
                        />
                    )}
                    <Profile
                        iconClose={
                            <span
                                className={"icon-arrow-up"}
                                key="icon-close"
                                style={styles.profileIcon}
                            />
                        }
                        iconOpen={
                            <span
                                className={"icon-arrow-down"}
                                key="icon-open"
                                style={styles.profileIcon}
                            />
                        }
                        image={get(auth, "photoURL", undefined)}
                        isSelected={this.isSettingsPath()}
                        key="profile"
                        menuProps={{
                            disableAutoFocusItem: true,
                            key: "menu"
                        }}
                        settingsOptions={[
                            {
                                action: () => this.navigateToSettings(),
                                key: "settings",
                                value: translation.profile.settings
                            },
                            {
                                action: () => logout(),
                                key: "logout",
                                value: translation.profile.logout
                            }
                        ]}
                        style={{
                            container: {
                                ...styles.profile.container,
                                ...(this.isSettingsPath()
                                    ? styles.buttonSelected
                                    : {})
                            },
                            image: styles.profile.image
                        }}
                        subTitle={get(
                            translation.users.roles,
                            get(profileData, "role.key", undefined),
                            undefined
                        )}
                        title={get(auth, "email", undefined)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profileData: state.profileData
    };
};

const mapDispatchToProps = {
    logout,
    navigateTo
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(TopBar);
