import Global from "../../config/global.css";

const styles = {
    achor: { right: "20px", top: "20px" },
    closeIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    }
};

export default styles;
