export const LIST_TYPES = ["bulleted-list"];

export const ELEMENT_TAGS = {
    A: el => ({ type: "link", url: el.getAttribute("href") }),
    BLOCKQUOTE: () => ({ type: "quote" }),
    H1: () => ({ type: "heading-one" }),
    H2: () => ({ type: "heading-two" }),
    H3: () => ({ type: "heading-three" }),
    H4: () => ({ type: "heading-four" }),
    H5: () => ({ type: "heading-five" }),
    H6: () => ({ type: "heading-six" }),
    LI: () => ({ type: "list-item" }),
    OL: () => ({ type: "numbered-list" }),
    P: () => ({ type: "paragraph" }),
    PRE: () => ({ type: "code" }),
    UL: () => ({ type: "bulleted-list" }),
    DIV: () => ({ type: "div" }),
    SPAN: () => ({ type: "span" })
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
export const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true })
};

export const initialValue = [
    {
        type: "heading-three",
        children: [{ text: "Title" }]
    },
    {
        type: "heading-four",
        children: [{ text: "Subtitle" }]
    },
    {
        type: "paragraph",
        children: [{ text: "Normal Text" }]
    },
    {
        type: "bulleted-list",
        children: [
            {
                type: "list-item",
                children: [{ text: "List text one" }]
            },
            {
                type: "list-item",
                children: [{ text: "List text two" }]
            }
        ]
    },
    {
        type: "paragraph",
        children: [{ text: "" }]
    }
];
