// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
// Form
import { initialize, reduxForm } from "redux-form";
// Components
import {
    Status,
    AdditionalLinks,
    BasicInfo,
    Chatbots,
    Classification,
    ExternalMaterialLink,
    Flyers,
    Image,
    Keywords,
    Podcasts,
    Presentations,
    Videos
} from "./components";
// Actions
import {
    clearOfferDetails,
    getKeywords,
    getOfferDetails,
    upsertOffer
} from "../../OfferDetails.ducks";
import { clearOffers, getOffers } from "../../../../Offers.ducks";
import { closeEditPopup } from "../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../components/Popup/Popup.ducks";
import { navigateTo } from "../../../../../Router.ducks";
// Helpers
import {
    addIfDiff,
    getFilesToDelete,
    handleErrorPopupDisplay,
    mapKeywords,
    mapMedias,
    mapStatusAndClassification
} from "../../../../helpers/offerDetailsFormHelper";
import { deleteFile, uploadFile } from "../../../../../../../services/storage";
import { routesPaths } from "../../../../../components/Routes/Routes.helper";
// Styles
import styles from "./DetailsForm.css";

function onSubmit(values, props) {
    const {
        clearOfferDetails,
        clearOffers,
        closeEditPopup,
        closePopup,
        details,
        getOfferDetails,
        getOffers,
        navigateTo,
        offerDetailsForm,
        offersList,
        openPopup,
        searchFilter,
        searchQuery,
        upsertOffer
    } = props;

    const initialValues = get(offerDetailsForm, "initial", undefined);
    const valuesAreEqual = isEqual(initialValues, values);

    if (valuesAreEqual) {
        closeEditPopup && closeEditPopup();
        return;
    }

    const offerUrl = details ? initialValues.url : values.url;
    let offer = {};

    values.seminarDetailId && (offer._id = values.seminarDetailId);
    addIfDiff(initialValues.title, values.title, "title", offer);
    addIfDiff(
        initialValues.displayTitle,
        values.displayTitle,
        "display_title",
        offer
    );
    addIfDiff(initialValues.url, values.url, "url", offer);
    addIfDiff(initialValues.cmsUrl, values.cmsUrl, "cms_url", offer);
    addIfDiff(
        initialValues.shortDescription,
        values.shortDescription,
        "short_description",
        offer
    );
    addIfDiff(
        initialValues.description,
        values.description,
        "formatted_description",
        offer
    );
    addIfDiff(
        initialValues.voiceDescription,
        values.voiceDescription,
        "voice_description",
        offer
    );

    mapStatusAndClassification(values, offer);

    const medias = mapMedias(initialValues, values);

    medias.medias &&
        medias.isMediaDiff &&
        (offer.media_details = medias.medias);

    mapKeywords(values, details, offer);

    const opType = details ? "edit" : "create";

    const shouldTriggerDialogFlow = () => {
        if (
            (details &&
                (details.title !== values.title ||
                    details.status !== values.status ||
                    offer.keywords)) ||
            opType === "create"
        ) {
            return true;
        }

        return false;
    };

    upsertOffer &&
        upsertOffer(
            opType,
            offerUrl,
            offer,
            shouldTriggerDialogFlow(),
            endSpinner => {
                const filesToDelete = getFilesToDelete(initialValues, values);
                filesToDelete &&
                    filesToDelete.forEach(file => {
                        deleteFile(file.path, file.name);
                    });

                const files = medias.files;
                files &&
                    files.forEach(file => {
                        uploadFile(file.file, file.name, file.path);
                    });

                const timeout = files && files.length > 0 ? 5000 : 2000;

                const shouldUpdate = () => {
                    if (
                        details.status !== values.status ||
                        details.classification !== values.classification ||
                        details.title !== values.title ||
                        details.display_title !== values.displayTitle
                    ) {
                        return true;
                    }

                    return false;
                };

                const retriveUpdatedOffersList = (limit = 20) =>
                    getOffers({
                        classification: get(
                            searchFilter,
                            "classification",
                            undefined
                        ),
                        entity_id: get(
                            searchFilter,
                            "legalEntity.value",
                            undefined
                        ),
                        limit,
                        title: searchQuery || "",
                        startIndex: 0,
                        status: get(searchFilter, "offerStatus", undefined)
                    });

                if (details) {
                    setTimeout(() => {
                        clearOfferDetails && clearOfferDetails();
                        getOfferDetails && getOfferDetails(offerUrl);
                        closeEditPopup && closeEditPopup();
                        getOffers &&
                            shouldUpdate() &&
                            retriveUpdatedOffersList(offersList.length);
                        endSpinner && endSpinner();
                    }, timeout);
                } else {
                    clearOffers && clearOffers();
                    getOffers && retriveUpdatedOffersList();
                    setTimeout(() => {
                        closeEditPopup && closeEditPopup();
                        navigateTo &&
                            navigateTo(
                                routesPaths.offerDetails.replace(
                                    ":offerUrl",
                                    offerUrl
                                )
                            );

                        endSpinner && endSpinner();
                    }, timeout);
                }
            },
            (errorType, errorMessage) => {
                handleErrorPopupDisplay({
                    errorType,
                    errorMessage,
                    closePopup,
                    openPopup
                });
            }
        );
}

const prepareKeywords = keywords => {
    return !keywords || keywords.length === 0
        ? null
        : keywords.map(keyword => {
              const keywordName = get(keyword, "keyword", undefined);
              return {
                  label: keywordName,
                  value: keywordName
              };
          });
};

class DetailsForm extends PureComponent {
    static propTypes = {
        clearOfferDetails: PropTypes.func.isRequired,
        clearOffers: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        details: PropTypes.object,
        detailsOffer: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        getKeywords: PropTypes.func.isRequired,
        getOfferDetails: PropTypes.func.isRequired,
        getOffers: PropTypes.func.isRequired,
        media: PropTypes.object,
        navigateTo: PropTypes.func.isRequired,
        searchFilter: PropTypes.object,
        searchQuery: PropTypes.string,
        offerDetailsForm: PropTypes.object,
        offersList: PropTypes.array,
        upsertOffer: PropTypes.func
    };

    state = {
        initialized: false
    };

    componentDidMount() {
        const { getKeywords, offerDetailsForm } = this.props;
        const { initialized } = this.state;

        getKeywords && getKeywords();

        if (offerDetailsForm && !initialized) {
            this.initialize();
        }
    }

    componentDidUpdate(prevProps) {
        const { media, offerDetailsForm } = this.props;
        const { initialized } = this.state;

        const oldValues = JSON.stringify(get(prevProps, "media", []));
        const nextValues = JSON.stringify(media);

        if (offerDetailsForm && (!initialized || oldValues !== nextValues)) {
            this.initialize(initialized);
        }
    }

    initialize = (update = false) => {
        const {
            dispatch,
            media: { chatbot, flyer, image, podcast, presentation, video },
            offerDetailsForm
        } = this.props;

        const sortById = (a, b) => a["id"].localeCompare(b["id"]);

        this.setState({ initialized: true }, () => {
            dispatch(
                initialize("offerDetails", {
                    ...offerDetailsForm.initial,
                    flyers: ((!update ||
                        offerDetailsForm.initial.flyers.length === 0) &&
                    flyer.length > 0
                        ? flyer
                        : offerDetailsForm.initial.flyers
                    ).sort(sortById),
                    videos: ((!update ||
                        offerDetailsForm.initial.videos.length === 0) &&
                    video.length > 0
                        ? video
                        : offerDetailsForm.initial.videos
                    ).sort(sortById),
                    podcasts: ((!update ||
                        offerDetailsForm.initial.podcasts.length === 0) &&
                    podcast.length > 0
                        ? podcast
                        : offerDetailsForm.initial.podcasts
                    ).sort(sortById),
                    presentations: ((!update ||
                        offerDetailsForm.initial.presentations.length === 0) &&
                    presentation.length > 0
                        ? presentation
                        : offerDetailsForm.initial.presentations
                    ).sort(sortById),
                    chatbots: ((!update ||
                        offerDetailsForm.initial.chatbots.length === 0) &&
                    chatbot.length > 0
                        ? chatbot
                        : offerDetailsForm.initial.chatbots
                    ).sort(sortById),
                    images: ((!update ||
                        offerDetailsForm.initial.images.length === 0) &&
                    image.length > 0
                        ? image
                        : offerDetailsForm.initial.images
                    ).sort(sortById)
                })
            );
        });
    };

    render() {
        const { details } = this.props;

        return (
            <form style={styles.container}>
                <BasicInfo />
                <Keywords prepareKeywords={prepareKeywords} />
                {details?.status !== "approved" && (
                    <Classification classification={details?.classification} />
                )}
                <ExternalMaterialLink />
                <Image />
                <Flyers />
                <Videos />
                <Podcasts />
                <Presentations />
                <Chatbots />
                <AdditionalLinks />
                <Status status={details?.status} />
            </form>
        );
    }
}

const mapStateToProps = state => {
    const details = state.offerDetails.details;

    return {
        details,
        media: state.offerDetails.media,
        initialValues: {
            seminarDetailId: get(details, "_id", undefined),
            title: get(details, "title", ""),
            displayTitle: get(details, "display_title", undefined),
            classification: get(details, "classification", undefined),
            url: get(details, "url", ""),
            cmsUrl: get(details, "cms_url", ""),
            shortDescription: get(details, "short_description", ""),
            description: get(details, "formatted_description", undefined),
            voiceDescription: get(details, "voice_description", undefined),
            keywords: prepareKeywords(get(details, "keywords", undefined)),
            flyers: [],
            videos: [],
            podcasts: [],
            presentations: [],
            chatbots: [],
            images: [],
            links: get(details, "media_details", []).filter(
                media => media.type === "link"
            ),
            additionalLinks: get(details, "media_details", []).filter(
                media => media.type === "additional_link"
            ),
            status: get(details, "status", undefined)
        },
        offerDetailsForm: get(state, "form.offerDetails", undefined),
        offersList: state.offers.offers,
        searchFilter: state.offers.searchFilter,
        searchQuery: state.offers.searchQuery,
        detailsOffer: state.offerDetails.details
    };
};

const mapDispatchToProps = {
    clearOfferDetails,
    clearOffers,
    closeEditPopup,
    closePopup,
    getKeywords,
    getOfferDetails,
    getOffers,
    navigateTo,
    openPopup,
    upsertOffer
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "offerDetails",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(DetailsForm)
);
