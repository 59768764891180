import InputAdornment from "@material-ui/core/InputAdornment";
// Modules
import React, { PureComponent } from "react";
// Form
import { Field } from "redux-form";
// Components
import { MaterialTextField } from "../../../../../../../../../components";
import { TextEditor } from "../";
// Validators
import {
    onlyAlphaNum,
    required
} from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./BasicInfo.css";

class BasicInfo extends PureComponent {
    render() {
        const wordpressCmsUrl = `${process.env.REACT_APP_WORDPRESS_URL_CMS}/`;

        return (
            <div style={styles.container}>
                <div style={styles.innerContainer}>
                    <Field
                        component={MaterialTextField}
                        label={translation.offers.editPopup.title}
                        name="title"
                        style={styles.textField}
                        variant="outlined"
                        validate={required}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.offers.editPopup.displayTitle}
                        name="displayTitle"
                        style={styles.textField}
                        variant="outlined"
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.offers.editPopup.url}
                        name="url"
                        style={styles.textField}
                        variant="outlined"
                        validate={[required, onlyAlphaNum]}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.offers.editPopup.cmsUrl}
                        name="cmsUrl"
                        style={styles.textField}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {wordpressCmsUrl}
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.offers.editPopup.shortDescription}
                        name="shortDescription"
                        multiline
                        rows={4}
                        style={styles.textField}
                        variant="outlined"
                        validate={required}
                    />
                    <Field
                        component={MaterialTextField}
                        label={translation.offers.editPopup.voiceDescription}
                        name="voiceDescription"
                        multiline
                        rows={4}
                        style={styles.textField}
                        variant="outlined"
                    />
                </div>
                <Field
                    component={TextEditor}
                    fieldName="description"
                    label={translation.offers.editPopup.description}
                    name="description"
                    validate={required}
                />
            </div>
        );
    }
}

export default BasicInfo;
