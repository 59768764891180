// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CustomTooltip, IconButton, TableRow } from "../../../../../components";
import { ClassificationTag } from "../../../components/";
// Actions
import { openViewPopup } from "../../../../../components/ViewPopup/ViewPopup.ducks";
import { saveOfferDetails } from "../../../Offers/components/OfferDetails/OfferDetails.ducks";
// Helpers
import {
    getScrollPosition,
    openOfferDetailsView,
    parseTitle,
    renderDetailsButton
} from "../../helpers/rowHelper";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./OfferRow.css";

class CustomEditIconButton extends PureComponent {
    static propTypes = {
        handleTooltipOnHover: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    render() {
        const { handleTooltipOnHover, openEditPopup } = this.props;

        return (
            <IconButton
                defaultClassName="icon-edit"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover && handleTooltipOnHover(false);
                    openEditPopup && openEditPopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    }
}

class OfferRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        entry: PropTypes.object.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        openViewPopup: PropTypes.func,
        saveOfferDetails: PropTypes.func
    };

    state = { tooltipOpen: false };

    renderRow = row => {
        const offerTitle =
            get(row, "display_title", undefined) ||
            get(row, "title", undefined);

        const keywords = get(row, "keywords", []).sort((a, b) =>
            get(a, "keyword", "").localeCompare(get(b, "keyword", ""))
        );

        const classification = get(row, "classification", undefined);

        return (
            <Fragment>
                <div
                    dangerouslySetInnerHTML={parseTitle(offerTitle)}
                    style={styles.text}
                />
                {classification ? (
                    <ClassificationTag
                        classification={classification}
                        style={styles.alignRight}
                    />
                ) : (
                    <div />
                )}
                {keywords && keywords.length > 0 ? (
                    <div style={styles.innerContainer}>
                        {keywords.map((keyword, index) => (
                            <div
                                key={index}
                                style={{
                                    ...styles.text,
                                    ...styles.boxContainer
                                }}
                            >
                                {get(keyword, "keyword", undefined)}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div />
                )}
                {renderDetailsButton({
                    openDetailView: this.handleOpenOfferDetailsView
                })}
                {this.renderEditActionButton()}
            </Fragment>
        );
    };

    handleTooltipOnHover = onHover => {
        this.setState({ tooltipOpen: onHover });
    };

    renderEditActionButton = () => {
        const { tooltipOpen } = this.state;
        const { openEditPopup } = this.props;

        return (
            <CustomTooltip
                onClose={() => this.handleTooltipOnHover(false)}
                onOpen={() => this.handleTooltipOnHover(true)}
                open={tooltipOpen}
                placement="top"
                title={translation.offersKeywordsTagging.edit}
            >
                <span>
                    <CustomEditIconButton
                        handleTooltipOnHover={this.handleTooltipOnHover}
                        openEditPopup={openEditPopup}
                    />
                </span>
            </CustomTooltip>
        );
    };

    handleOpenOfferDetailsView = () => {
        const { entry, openViewPopup, saveOfferDetails } = this.props;

        const scrollPosition = getScrollPosition();

        openOfferDetailsView({
            offer: entry,
            openViewPopup,
            saveOfferDetails,
            scrollPosition
        });
    };

    render() {
        const { containerStyle, entry } = this.props;

        return (
            <TableRow
                containerStyle={{
                    ...containerStyle,
                    ...styles.rowContainer
                }}
                id={get(entry, "url", "")}
            >
                {this.renderRow(entry)}
            </TableRow>
        );
    }
}

const mapDispatchToProps = {
    openViewPopup,
    saveOfferDetails
};

export default connect(undefined, mapDispatchToProps)(OfferRow);
