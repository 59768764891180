const styles = {
    container: {
        display: "grid",
        height: "fit-content",
        gap: "8px"
    },
    content: {
        borderStyle: "none",
        frameborder: "0",
        height: "200px",
        width: "100%"
    },
    innerContainer: {
        display: "grid",
        gap: "24px"
    },
    video: {
        width: "100%",
        height: "100%"
    }
};

export default styles;
