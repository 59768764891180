import Global from "../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        height: "fit-content",
        gap: "18px",
        gridTemplateRows: "62px 62px 62px minmax(62px, auto) auto auto",
        padding: "32px 24px"
    },
    containerEdit: {
        gridTemplateRows:
            "62px 62px 62px minmax(62px, auto) minmax(62px, auto) minmax(62px, auto)"
    },
    error: {
        color: Global.colors.melon,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px"
    },
    passwordIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    },
    radioGroupContainer: {
        display: "grid",
        gap: "16px",
        marginTop: "12px"
    },
    radioGroupContainerTitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "20px"
    },
    select: {
        width: "460px"
    },
    selectMenuContainer: {
        position: "relative"
    },
    selectOption: {
        height: "unset",
        minHeight: "48px"
    },
    statusGroupContainer: {
        gridTemplateColumns: "min-content",
        gridTemplateRows: "min-content min-content min-content"
    },
    textField: {
        width: "460px"
    }
};

export default styles;
