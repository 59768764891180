// Modules
import React, { PureComponent } from "react";
// Components
import { CircularProgress } from "../";
// Styles
import styles from "./InfiniteScrollLoading.css";

class InfiniteScrollLoading extends PureComponent {
    render() {
        return (
            <div style={styles.container}>
                <CircularProgress
                    className="loading"
                    size={32}
                    style={{
                        circle: styles.circle
                    }}
                />
            </div>
        );
    }
}

export default InfiniteScrollLoading;
