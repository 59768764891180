// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CustomTooltip,
    DetailsButton,
    IconButton,
    TableRow
} from "../../../../../../../../../components/";
// Actions
import { navigateTo } from "../../../../../../../Router.ducks";
// Helpers
import { formatTimestamp } from "../../../../../../../../../services/date";
import { routesPaths } from "../../../../../../../components/Routes/Routes.helper";
import { formatPriceWithCurrency } from "../../../../../../../../../services/formats";
import { validateLink } from "../../../../../../../helpers/links";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./EventRow.css";

class EventRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        enrolment: PropTypes.object,
        details: PropTypes.object,
        handleOpenEventDetailsView: PropTypes.func,
        index: PropTypes.number.isRequired,
        navigateTo: PropTypes.func.isRequired,
        seminar: PropTypes.object.isRequired
    };

    renderRow = (row, enrolment) => {
        let startDate = get(row, "start_date", undefined);
        startDate =
            !startDate || startDate.toLowerCase() === "auf anfrage"
                ? translation.event.onRequest
                : formatTimestamp(startDate, "D. MMMM YYYY");
        let type = get(row, "type", undefined);
        type = translation.event.type[type] || "-";

        let price = get(row, "price", undefined);
        price = !price || isNaN(price) ? "-" : formatPriceWithCurrency(price);
        const location = get(row, "location.name", undefined) || "-";
        const instructors = get(row, "instructors", []);
        let instructorsNames =
            instructors && instructors.length > 0
                ? instructors.map(instructor => instructor.name).join(", ")
                : "-";
        const bookedPlaces = get(enrolment, "booked_participants", "N/A");

        return (
            <Fragment>
                <div style={styles.rowBlock}>{startDate}</div>
                <div style={styles.rowBlock}>{type}</div>
                <div style={styles.rowBlock}>{location}</div>
                <div style={styles.rowBlock}>{instructorsNames}</div>
                <div style={styles.rowBlock}>{bookedPlaces}</div>
                <div style={{ ...styles.rowBlock, ...styles.rightAlignment }}>
                    {price}
                </div>
                {this.renderActionButtons()}
            </Fragment>
        );
    };

    renderActionButtons = () => {
        const { index, seminar } = this.props;

        const externalLink = validateLink(
            get(seminar, "external_link", undefined)
        );

        const enrolmentLink = validateLink(
            get(seminar, "enrolment_link", undefined)
        );

        return (
            <Fragment>
                {!externalLink && !enrolmentLink && <div />}
                <DetailsButton
                    onClick={() => this.handleOnClick(index, seminar)}
                />
                {externalLink && (
                    <CustomTooltip
                        placement="top"
                        title={translation.event.externalLink}
                    >
                        <span>
                            <IconButton
                                defaultClassName={"icon-info-file"}
                                onClick={() => {
                                    window.open(externalLink, "_blank");
                                }}
                                style={styles.icon}
                                type={["default"]}
                            />
                        </span>
                    </CustomTooltip>
                )}

                {!externalLink && enrolmentLink && (
                    <CustomTooltip
                        placement="top"
                        title={translation.event.enrolmentLink}
                    >
                        <span>
                            <IconButton
                                defaultClassName={"icon-info-file"}
                                onClick={() => {
                                    window.open(enrolmentLink, "_blank");
                                }}
                                style={styles.icon}
                                type={["default"]}
                            />
                        </span>
                    </CustomTooltip>
                )}
            </Fragment>
        );
    };

    handleOnClick = (index, event) => {
        const { details, handleOpenEventDetailsView, navigateTo } = this.props;

        if (handleOpenEventDetailsView) {
            handleOpenEventDetailsView(event);
        } else {
            let path = routesPaths.eventDetails;
            path = path.replace(":offerUrl", details.url);
            path = path.replace(":eventIndex", index);

            navigateTo && navigateTo(path);
        }
    };

    render() {
        const { containerStyle, enrolment, index, seminar } = this.props;
        return (
            <TableRow
                id={`event-row-${index}`}
                containerStyle={{ ...containerStyle, ...styles.rowContainer }}
            >
                {this.renderRow(seminar, enrolment)}
            </TableRow>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details
    };
};

const mapDispatchToProps = {
    navigateTo
};

export default connect(mapStateToProps, mapDispatchToProps)(EventRow);
