// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { DataCard } from "../../../";
// Actions
import { setMedia } from "../../../../OfferDetails.ducks";
// Translation
import translation from "../../../../../../../../../config/translation";
// Helpers
import { isValidImagePath } from "../../../../../../../../../services/storage";
import { extractImageData } from "../../../../../../helpers/offerDetailsFormatHelper";
// Styles
import styles from "./Images.css";
import { default as formStyles } from "../../DetailsView.css";

function Images({ images, type, setMedia }) {
    Images.propTypes = {
        images: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
                description: PropTypes.string,
                format: PropTypes.string.isRequired,
                title: PropTypes.string
            })
        ),
        setMedia: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired
    };

    const [state, setState] = useState({});

    useEffect(() => {
        let isSubscribed = true;

        images &&
            images.forEach(async image => {
                const { _id, content, format } = image;

                if (!format || !content) {
                    return;
                }

                let imageURL = undefined;
                await isValidImagePath(content, imageUrl => {
                    imageURL = imageUrl;
                    isSubscribed &&
                        setState(prevState => ({
                            ...prevState,
                            [_id]: imageUrl
                        }));
                });

                setMedia(
                    image.type,
                    extractImageData({
                        ...image,
                        imageURL
                    })
                );
            });

        return () => {
            isSubscribed = false;
        };
    }, [images, setMedia]);

    const renderImage = (image, index) => {
        const { _id, content, description, format, title } = image;

        if (!format || !content) {
            return null;
        }

        return (
            <div key={index} style={styles.container}>
                {title && <span style={formStyles.title}>{title}</span>}
                {description && (
                    <span style={formStyles.description}>{description}</span>
                )}
                <img alt={title} src={state[_id]} style={styles.image} />
            </div>
        );
    };

    if (!images || images.length === 0) {
        return null;
    }

    return (
        <DataCard title={translation.offerDetails.details[type]}>
            <div style={styles.innerContainer}>
                {images.map((image, index) => {
                    return renderImage(image, index);
                })}
            </div>
        </DataCard>
    );
}

const mapDispatchToProps = {
    setMedia
};

export default connect(null, mapDispatchToProps)(Images);
