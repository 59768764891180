import Global from "../../../../../../../../../config/global.css";

const styles = {
    classification: {
        container: {
            display: "flex"
        },
        radio: {
            checked: {
                backgroundColor: Global.colors.digitalBlue,
                border: `1px solid ${Global.colors.digitalBlue}`,
                color: Global.colors.white,
                fontSize: "12px"
            },
            checkmark: {
                alignItems: "center",
                border: `1px solid ${Global.colors.darkPaleGrey}`,
                height: "12px",
                display: "flex",
                justifyContent: "center",
                top: "3px",
                width: "12px"
            },
            container: {
                color: Global.colors.darkSlateBlue,
                paddingLeft: "22px"
            },
            containerWithError: {
                color: Global.colors.melon
            },
            error: {
                border: `1px solid ${Global.colors.darkPaleGrey}`
            },
            input: {
                width: "0px",
                margin: "0px"
            }
        },
        tooltipIcon: {
            default: {
                color: Global.colors.darkSlateBlue,
                cursor: "pointer",
                fontSize: "18px",
                marginBottom: "-1px",
                marginLeft: "3px",
                marginTop: "1px"
            },
            error: {
                color: Global.colors.melon
            }
        }
    },
    container: {
        display: "grid",
        gap: "16px",
        height: "fit-content",
        width: "100%"
    },
    innerContainer: {
        display: "grid",
        gap: "12px"
    },
    title: {
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px"
    }
};

export default styles;
