// Modules
import get from "lodash.get";
// Api
import api from "../../../../../../../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../../../../../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../../../../../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translations
import translation from "../../../../../../../../../config/translation";

export const getOriginalOfferDetails =
    ({ offerId, successCb, errorCb }) =>
    dispatch => {
        dispatch(startSpinner());

        api.get(`offers/${offerId}`)
            .then(response => {
                const data = get(response, "data", undefined);
                successCb && successCb(data);
                dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOriginalOfferDetails: ", e);
                errorCb && errorCb();
                dispatch(endSpinner());
            });
    };

export const unmergeOffer =
    ({ offerId, successCb, errorCb }) =>
    dispatch => {
        dispatch(startSpinner());

        api.put(`offers/${offerId}/unmerge`)
            .then(() => {
                successCb && successCb();
                dispatch(endSpinner());

                dispatch(
                    openSnackbar(translation.event.unmerge.success, "success")
                );
            })
            .catch(e => {
                console.log("Error calling unmergeOffer: ", e);
                errorCb && errorCb();
                dispatch(endSpinner());
            });
    };
