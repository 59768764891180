// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { TableHeaders } from "../../../../../../../components";
import { EventRow } from "./components";
// Translations
import translation from "../../../../../../../config/translation";
// Helpers
import { getSeminarsSortedByStartDate } from "./helpers/eventsHelper";
// Styles
import styles from "./Events.css";

class Events extends PureComponent {
    static propTypes = {
        details: PropTypes.object.isRequired,
        enrolments: PropTypes.array,
        handleOpenEventDetailsView: PropTypes.func
    };

    tableHeaders = [
        { label: translation.events.tableHeaders.startDate },
        { label: translation.events.tableHeaders.type },
        { label: translation.events.tableHeaders.location },
        { label: translation.events.tableHeaders.instructor },
        { label: translation.events.tableHeaders.bookedPlaces },
        { label: translation.events.tableHeaders.price, style: styles.price }
    ];

    render() {
        const { details, enrolments, handleOpenEventDetailsView } = this.props;

        if (!details) {
            return null;
        }

        const seminars = getSeminarsSortedByStartDate(details);

        return (
            <div style={styles.container}>
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...styles.tableHeaders
                    }}
                    headers={this.tableHeaders}
                />
                <div style={styles.rowsContainer}>
                    {seminars &&
                        seminars.map((seminar, index) => {
                            const enrolment =
                                enrolments &&
                                enrolments.find(
                                    enrolment =>
                                        enrolment.seminar_id ===
                                        get(seminar, "_id", undefined)
                                );
                            return (
                                <EventRow
                                    containerStyle={styles.tableGrid}
                                    enrolment={enrolment}
                                    handleOpenEventDetailsView={
                                        handleOpenEventDetailsView
                                    }
                                    index={index}
                                    key={index}
                                    seminar={seminar}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details,
        enrolments: state.offerDetails.enrolments
    };
};

export default connect(mapStateToProps)(Events);
