// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash.get";
// Components
import { MaterialFabButton } from "../../../../../";
// Helpers
import { formatTimestamp } from "../../../../../../services/date";
import {
    parseHtmlData,
    parseTitle
} from "../../../../../../services/htmlDataParsing";
// Translations
import translation from "../../../../../../config/translation";
// Styles
import { withStyles } from "@material-ui/core/styles";
import styles from "./EventCard.css";

class EventCard extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        event: PropTypes.object,
        index: PropTypes.number.isRequired,
        seminar: PropTypes.shape({
            events: PropTypes.array,
            filters: PropTypes.object,
            shortDescription: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            displayTitle: PropTypes.string,
            url: PropTypes.string.isRequired
        })
    };

    state = {
        tileOnHover: undefined
    };

    handleTileMouseEnter = index => {
        this.setState({ tileOnHover: index });
    };

    handleTileLeave = () => {
        this.setState({ tileOnHover: undefined });
    };

    getLocation = () => {
        const { event } = this.props;

        return get(event, "location.name", undefined);
    };

    getStartDate = (format = "D. MMMM YYYY") => {
        const { event } = this.props;

        return event.start_date
            ? event.start_date === "Alle"
                ? event.start_date
                : formatTimestamp(event.start_date, format)
            : translation.chatbot.onRequest;
    };

    render() {
        const { classes, index, seminar } = this.props;
        const { tileOnHover } = this.state;

        const title = parseTitle(seminar);
        const shortDescription = parseHtmlData(
            get(seminar, "shortDescription", undefined)
        );
        const startDate = this.getStartDate();
        const location = this.getLocation();

        // To force re-calculation of WebkitLineClamp
        !this.titleRef &&
            setTimeout(() => {
                this.forceUpdate();
            }, 200);

        return (
            <div
                className={classNames(classes.item, classes.itemVisible, {
                    [classes.itemHover]: tileOnHover === index
                })}
                onMouseEnter={() => {
                    this.handleTileMouseEnter(index);
                }}
                onMouseLeave={this.handleTileLeave}
            >
                <div
                    dangerouslySetInnerHTML={title}
                    ref={ref => this.titleRef !== ref && (this.titleRef = ref)}
                    style={{
                        ...styles.title,
                        ...(tileOnHover === index
                            ? {}
                            : seminar.shortDescription
                            ? styles.titleEllipsis
                            : styles.titleWithoutShortDescription)
                    }}
                />
                {seminar.shortDescription && (
                    <div
                        dangerouslySetInnerHTML={shortDescription}
                        style={{
                            ...styles.subtitle,
                            ...(tileOnHover === index
                                ? {
                                      maxHeight: "unset"
                                  }
                                : {
                                      ...styles.subtitleEllipsis,
                                      maxHeight: `${
                                          this.titleRef
                                              ? 140 - this.titleRef.clientHeight
                                              : 0
                                      }px`,
                                      WebkitLineClamp: this.titleRef
                                          ? Math.floor(
                                                this.titleRef.clientHeight / 26
                                            ) >= 3
                                              ? 3
                                              : 6 -
                                                Math.floor(
                                                    this.titleRef.clientHeight /
                                                        26
                                                )
                                          : 3
                                  })
                        }}
                    />
                )}
                <div style={styles.eventData.container}>
                    <div style={styles.eventData.innerContainer}>
                        <span
                            className="icon-location"
                            style={styles.eventData.icon}
                        />
                        <span
                            style={{
                                ...styles.eventData.label,
                                ...(tileOnHover === index
                                    ? {}
                                    : {
                                          ...styles.eventData.labelEllipsis
                                      })
                            }}
                        >
                            {location}
                        </span>
                    </div>
                    <div style={styles.eventData.innerContainer}>
                        <span
                            className="icon-calendar"
                            style={styles.eventData.icon}
                        />
                        <span style={styles.eventData.label}>{startDate}</span>
                    </div>
                </div>
                <MaterialFabButton
                    onClick={() => {}}
                    disableRipple
                    style={styles.button.container}
                    variant="circular"
                >
                    <span
                        className="icon-arrow-right"
                        style={styles.button.icon}
                    />
                </MaterialFabButton>
            </div>
        );
    }
}

export default withStyles(styles)(EventCard);
