// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { DataCard } from "../../../";
// Actions
import { setMedia } from "../../../../OfferDetails.ducks";
// Helpers
import {
    isValidImagePath,
    isValidMediaPath
} from "../../../../../../../../../services/storage";
import { extractFlyerPresentationData } from "../../../../../../helpers/offerDetailsFormatHelper";
// Translation
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./Presentations.css";
import { default as formStyles } from "../../DetailsView.css";

function Presentations({ presentations, setMedia }) {
    Presentations.propTypes = {
        presentations: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
                format: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            })
        ),
        setMedia: PropTypes.func.isRequired
    };

    const [state, setState] = useState({});

    useEffect(() => {
        let isSubscribed = true;

        presentations &&
            presentations.forEach(async presentation => {
                const { _id, content, format } = presentation;

                if (!format || !content) {
                    return null;
                }

                let imageURL = undefined;
                await isValidImagePath(
                    content,
                    image => {
                        imageURL = image;
                        isSubscribed &&
                            setState(prevState => ({
                                ...prevState,
                                [`${_id}_image`]: image
                            }));
                    },
                    "presentations"
                );

                let fileURL = undefined;
                await isValidMediaPath(
                    content,
                    format,
                    presentationURL => {
                        fileURL = presentationURL;
                        isSubscribed &&
                            setState(prevState => ({
                                ...prevState,
                                [`${_id}_presentation`]: presentationURL
                            }));
                    },
                    "presentations"
                );

                setMedia(
                    presentation.type,
                    extractFlyerPresentationData({
                        ...presentation,
                        imageURL,
                        fileURL
                    })
                );
            });

        return () => {
            isSubscribed = false;
        };
    }, [presentations, setMedia]);

    const renderPresentation = (presentation, index) => {
        const { _id, content, format, title } = presentation;

        if (!format || !content) {
            return null;
        }

        const image = state[`${_id}_image`];
        const presentationURL = state[`${_id}_presentation`];

        return image || presentationURL ? (
            <div key={index} style={styles.container}>
                <span style={formStyles.title}>{title}</span>
                <img alt={content} src={image} style={styles.image} />
                {presentationURL && (
                    <div
                        onClick={() => {
                            presentationURL && window.open(presentationURL);
                        }}
                        style={styles.pdfContainer}
                    >
                        <span className="icon-pdf" style={styles.icon} />
                        <span>{translation.offerDetails.details.pdfLink}</span>
                    </div>
                )}
            </div>
        ) : null;
    };

    if (!presentations || presentations.length === 0) {
        return null;
    }

    return (
        <DataCard title={translation.offerDetails.details.presentations}>
            <div style={styles.innerContainer}>
                {presentations.map((presentation, index) => {
                    return renderPresentation(presentation, index);
                })}
            </div>
        </DataCard>
    );
}

const mapDispatchToProps = {
    setMedia
};

export default connect(null, mapDispatchToProps)(Presentations);
