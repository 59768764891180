// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CreatableSelect,
    DatePicker,
    IconButton,
    MaterialTextField
} from "../../../../../../../../../../../components/";
import { GroupCard } from "../../../../../";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import {
    digits,
    digitsWithSeparators,
    required
} from "../../../../../../../../../../../services/validators";
// Helpers
import { getDurationUnits } from "../../../../helpers/formMappingsHelper";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Modules.css";

class Modules extends PureComponent {
    static propTypes = {
        eventDetailsForm: PropTypes.object
    };

    getDurationUnitsList = () => {
        const { eventDetailsForm } = this.props;

        const unit = get(eventDetailsForm, "values.duration.unit", undefined);

        return getDurationUnits(unit);
    };

    handleOnChange = () => {
        document.activeElement && document.activeElement.blur();
    };

    renderModules = ({ fields, eventDetailsForm }) => {
        const durationUnitsList = this.getDurationUnitsList();

        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.container}>
                                    <span style={styles.label}>{`${
                                        translation.event.modules
                                    } ${index + 1}`}</span>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                                <div style={styles.container}>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            label={
                                                translation.event.moduleNumber
                                            }
                                            name={`${item}.number`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={[required, digits]}
                                        />
                                        <Field
                                            component={CreatableSelect}
                                            customOnChange={this.handleOnChange}
                                            editable
                                            isClearable
                                            isSearchable
                                            label={
                                                translation.events.editPopup
                                                    .durationUnit
                                            }
                                            placeholder={""}
                                            name={`${item}.duration.unit`}
                                            options={durationUnitsList}
                                        />
                                        <Field
                                            component={DatePicker}
                                            label={translation.event.startDate}
                                            name={`${item}.startDate`}
                                            startDate={get(
                                                eventDetailsForm,
                                                "values.startDate",
                                                undefined
                                            )}
                                            endDate={
                                                get(
                                                    eventDetailsForm,
                                                    `values.${item}.endDate`,
                                                    undefined
                                                ) ||
                                                get(
                                                    eventDetailsForm,
                                                    "values.endDate",
                                                    undefined
                                                )
                                            }
                                            validate={required}
                                        />
                                    </div>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            label={translation.event.name}
                                            name={`${item}.name`}
                                            style={styles.textField}
                                            variant="outlined"
                                        />
                                        <Field
                                            component={MaterialTextField}
                                            label={
                                                translation.events.editPopup
                                                    .durationValue
                                            }
                                            name={`${item}.duration.value`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={[
                                                required,
                                                digitsWithSeparators
                                            ]}
                                        />
                                        <Field
                                            component={DatePicker}
                                            label={translation.event.endDate}
                                            name={`${item}.endDate`}
                                            startDate={
                                                get(
                                                    eventDetailsForm,
                                                    `values.${item}.startDate`,
                                                    undefined
                                                ) ||
                                                get(
                                                    eventDetailsForm,
                                                    "values.startDate",
                                                    undefined
                                                )
                                            }
                                            endDate={get(
                                                eventDetailsForm,
                                                "values.endDate",
                                                undefined
                                            )}
                                            validate={required}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <IconButton
                    defaultClassName="icon-plus"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                        fields.push({});
                    }}
                    type={["default"]}
                    style={styles.addButton}
                >
                    <span style={styles.addButtonLabel}>
                        {translation.events.editPopup.addModule}
                    </span>
                </IconButton>
            </Fragment>
        );
    };

    render() {
        const { eventDetailsForm } = this.props;

        return (
            <GroupCard title={translation.event.modules}>
                <FieldArray
                    component={this.renderModules}
                    name="modules"
                    props={{ eventDetailsForm }}
                />
            </GroupCard>
        );
    }
}

const mapStateToProps = state => {
    return {
        eventDetailsForm: get(state, "form.eventDetails", undefined)
    };
};

export default connect(mapStateToProps)(Modules);
