// Modules
import get from "lodash.get";

/**
 * Checks if the main permission is given to the role permissions.
 *
 * @param {Object} rolePermissions the role permissions
 * @param {String} permission the permission to validate
 */
export function hasMainPermission(rolePermissions, permission) {
    const keyPermission = get(rolePermissions, permission, undefined);

    return keyPermission && keyPermission.length > 0;
}

/**
 * The routes path mapping to the main permission key.
 */
export const routesMainPermissionsMapping = {
    "/": "dashboard",
    "/users": "user_management",
    "/chat": "chat",
    "/mapping": "mapping_management",
    "/mapping/entitiesmapping": "entities_mapping",
    "/mapping/entitiesuploads": "entities_uploads",
    "/mapping/unmappedentities": "unmapped_entities",
    "/mapping/cooppartnersmapping": "cooppartners_mapping",
    "/offers": "offer_management",
    "/offers/overview": "offer_management",
    "/offers/chamberslocations": "chambers_locations",
    "/offers/fileupload": "upload_offers",
    "/offers/classification": "offer_classification",
    "/offers/keywordstagging": "keywords_tagging",
    "/usermanual": "user_manual",
    "/settings": "settings",
    "/invoicing": "invoicing",
    "/marketingmaterials": "marketing_materials",
    "/badges": "badges"
};

/**
 * Checks if the specific permission is given to the role permissions.
 *
 * @param {Object} rolePermissions the role permissions
 * @param {String} mainPermission the main permission
 * @param {String} permission the permission to validate
 */
export function hasSpecificPermission(
    rolePermissions,
    mainPermission,
    permission
) {
    const mainPermissionValues = get(
        rolePermissions,
        mainPermission,
        undefined
    );

    if (!mainPermissionValues || mainPermissionValues.length === 0) {
        return false;
    }

    if (mainPermissionValues.includes("all")) {
        return true;
    }

    return mainPermissionValues.includes(permission);
}

/**
 * Checks if the specific permission entity dependent is given to the role permissions.
 *
 * @param {Object} rolePermissions the role permissions
 * @param {String} mainPermission the main permission
 * @param {String} permission the permission to validate
 */
export function hasSpecificEntityPermission(
    rolePermissions,
    mainPermission,
    permission
) {
    const mainPermissionValues = get(
        rolePermissions,
        mainPermission,
        undefined
    );

    if (!mainPermissionValues || mainPermissionValues.length === 0) {
        return false;
    }

    return mainPermissionValues.includes(permission);
}

/**
 * Checks if the specific regex permission is given to the role permissions.
 *
 * @param {Object} rolePermissions the role permissions
 * @param {String} mainPermission the main permission
 * @param {String} permission the permission to validate
 */
export function getSpecificRegexPermission(
    rolePermissions,
    mainPermission,
    regexPermission
) {
    const mainPermissionValues = get(
        rolePermissions,
        mainPermission,
        undefined
    );

    if (!mainPermissionValues || mainPermissionValues.length === 0) {
        return false;
    }

    if (mainPermissionValues.includes("all")) {
        return ["all"];
    }

    return mainPermissionValues.filter(p => p.match(`${regexPermission}_*`));
}
