// Modules
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MaterialButton } from "..";
import { ClickAwayListener } from "@material-ui/core";
// Translation
import translation from "../../config/translation";
// Styles
import styles from "./ExpandableFilter.css";

const NULL_VALUES = ["all", undefined, null];

function ExpandableFilter({
    editPopup,
    filters,
    filtersKeys,
    getFilterValue,
    popup,
    resetAllFilters,
    viewPopup
}) {
    const [opened, setOpened] = useState(false);

    const handleOpenFilter = event => {
        if (get(event, "target.className", undefined) === "spinner-overlay") {
            return;
        }

        setOpened(!opened);
    };

    const isPopupOpened =
        get(editPopup, "opened", false) ||
        get(popup, "opened", false) ||
        get(viewPopup, "opened", false);

    const activeFilters = filtersKeys
        ? filtersKeys.reduce((accumulator, currentValue) => {
              const filterValue = getFilterValue(currentValue);
              return (
                  accumulator +
                  (filterValue && !NULL_VALUES.includes(filterValue) ? 1 : 0)
              );
          }, 0)
        : 0;

    return (
        <ClickAwayListener onClickAway={opened ? handleOpenFilter : () => {}}>
            <div style={styles.container}>
                <div onClick={handleOpenFilter} style={styles.filter}>
                    <span>{translation.labels.filter}</span>
                    <span className={"icon-filters"} style={styles.icon} />
                </div>
                {opened ? (
                    <div style={styles.dialog.container}>
                        <div
                            style={{
                                ...styles.filter,
                                ...styles.dialog.headerContainer
                            }}
                        >
                            <span>{translation.labels.filter}</span>
                            <span
                                className={"icon-close"}
                                onClick={handleOpenFilter}
                                style={styles.icon}
                            />
                        </div>
                        <div style={styles.dialog.innerContainer}>
                            {filters &&
                                filters.map((filter, index) => {
                                    const filterComponent = get(
                                        filter,
                                        "component",
                                        undefined
                                    );

                                    if (!filterComponent) {
                                        return null;
                                    }

                                    return (
                                        <Fragment key={index}>
                                            {index !== 0 && (
                                                <div
                                                    style={
                                                        styles.dialog.separator
                                                    }
                                                />
                                            )}
                                            <div
                                                style={
                                                    styles.dialog
                                                        .filterContainer
                                                }
                                            >
                                                <span
                                                    style={styles.dialog.title}
                                                >
                                                    {get(
                                                        filter,
                                                        "title",
                                                        undefined
                                                    )}
                                                </span>
                                                {filterComponent}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            <MaterialButton
                                containerStyle={
                                    styles.dialog.linkButtonContainer
                                }
                                customType="link"
                                disabled={!activeFilters}
                                disableRipple
                                onClick={resetAllFilters}
                                style={styles.dialog.linkButton}
                            >
                                {translation.labels.resetAll}
                            </MaterialButton>
                        </div>
                    </div>
                ) : (
                    activeFilters > 0 &&
                    !isPopupOpened && (
                        <div style={styles.filterCount}>{activeFilters}</div>
                    )
                )}
            </div>
        </ClickAwayListener>
    );
}

const mapStateToProps = state => {
    return {
        editPopup: state.editPopup,
        popup: state.popup,
        viewPopup: state.viewPopup
    };
};

ExpandableFilter.propTypes = {
    editPopup: PropTypes.object,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            component: PropTypes.node.isRequired
        })
    ),
    filtersKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    getFilterValue: PropTypes.func.isRequired,
    popup: PropTypes.object,
    resetAllFilters: PropTypes.func.isRequired,
    viewPopup: PropTypes.object
};

export default connect(mapStateToProps)(ExpandableFilter);
