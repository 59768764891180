// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import { MaterialButton } from "../../../";
// Styles
import styles from "./Link.css";

class Link extends PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        messages_template: PropTypes.array.isRequired,
        texts: PropTypes.array.isRequired
    };

    messageCounter = 0;

    renderLink = index => {
        const { texts } = this.props;

        return (
            <MaterialButton
                key={index}
                containerStyle={styles.linkContainer}
                customType="link"
                disabled
                disableRipple
                onClick={() => {}}
                style={styles.link}
            >
                {texts[this.messageCounter++]}
            </MaterialButton>
        );
    };

    getMessage = (messageTemplate, index) => {
        const { texts } = this.props;

        if (messageTemplate === "<text>" && texts && texts.length > 0) {
            return <span key={index}>{texts[this.messageCounter++]}</span>;
        } else if (messageTemplate === "<privacy_link>") {
            return this.renderLink(index);
        } else {
            return null;
        }
    };

    render() {
        const { messages_template } = this.props;

        this.messageCounter = 0;

        return (
            <Fragment>
                {messages_template &&
                    messages_template.map((mt, index) => {
                        return this.getMessage(mt, index);
                    })}
            </Fragment>
        );
    }
}

export default Link;
