// Modules
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect, Provider, useSelector } from "react-redux";
import { ConnectedRouter, replace } from "connected-react-router";
import { Redirect, Route, Switch } from "react-router";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import get from "lodash.get";
import {
    isLoaded,
    useFirebase,
    ReactReduxFirebaseProvider
} from "react-redux-firebase";
import firebase from "firebase/app";
// Configuration
import serviceWorker from "./config/serviceWorker";
import store, { history } from "./config/store";
import { firebaseReduxConfig } from "./services/firebase";
// Components
import GetStarted from "./scenes/GetStarted/GetStarted";
import Login from "./scenes/Login/Login";
import ResetPassword from "./scenes/ResetPassword/ResetPassword";
import Router from "./scenes/Router";
import { MaterialSpinner } from "./components";
// Actions
import { clearProfileData, getProfileData } from "./scenes/ProfileData.ducks";
import { startSpinner } from "./components/MaterialSpinner/MaterialSpinner.ducks";
// Helper
import {
    hasMainPermission,
    routesMainPermissionsMapping
} from "./services/permissions";
// Styles
import Global from "./config/global.css";

const isUserAuthenticated = () => {
    const {
        firebase: { auth }
    } = store.getState();

    return !auth.isEmpty;
};

const checkPermissions = path => {
    const {
        profileData: { role }
    } = store.getState();

    return hasMainPermission(
        get(role, "permissions", {}),
        get(routesMainPermissionsMapping, path, undefined)
    );
};

const IsUserNew = () => {
    const profile = useSelector(state => state.firebase.profile);

    return profile.isNew;
};

const hasAcceptedLegalAgreement = () => {
    const profile = useSelector(state => state.firebase.profile);
    return profile && profile.legalAgreement?.accepted !== undefined
        ? profile.legalAgreement.accepted
        : false;
};

function PrivateRoute({ path }) {
    PrivateRoute.propTypes = {
        location: PropTypes.object,
        path: PropTypes.string
    };

    const isUserLoggedIn = isUserAuthenticated();
    const isNewUser = IsUserNew();
    const hasAcceptedAgreement = hasAcceptedLegalAgreement();
    const firebase = useFirebase();

    useEffect(() => {
        isUserLoggedIn &&
            firebase.reloadAuth().catch(() => {
                firebase.logout().then(() => {
                    store.dispatch(replace("/login"));
                    store.dispatch(clearProfileData());
                });
            });
    });

    const hasPermission = checkPermissions(path);

    return (
        <Route
            render={props => {
                return !isUserLoggedIn ? (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                ) : isNewUser || !hasAcceptedAgreement ? (
                    <Redirect
                        to={"/get_started"}
                        {...{ isNewUser, hasAcceptedAgreement }}
                    />
                ) : hasPermission ? (
                    <Router {...props} />
                ) : (
                    <Redirect to={"/"} />
                );
            }}
        />
    );
}

const LoginRoute = () => {
    LoginRoute.propTypes = {
        location: PropTypes.object
    };

    const isUserLoggedIn = isUserAuthenticated();
    const isNewUser = IsUserNew();
    const hasAcceptedAgreement = hasAcceptedLegalAgreement();

    return (
        <Route
            render={props => {
                return isUserLoggedIn ? (
                    isNewUser || !hasAcceptedAgreement ? (
                        <Redirect
                            to={"/get_started"}
                            {...{ isNewUser, hasAcceptedAgreement }}
                        />
                    ) : (
                        <Redirect to={"/"} />
                    )
                ) : (
                    <Login
                        {...props}
                        from={get(props, "location.state.from.pathname", "/")}
                    />
                );
            }}
        />
    );
};

const GetStartedRoute = () => {
    const isUserLoggedIn = isUserAuthenticated();
    const isNewUser = IsUserNew();
    const hasAcceptedAgreement = hasAcceptedLegalAgreement();

    return (
        <Route
            render={props => {
                return isUserLoggedIn ? (
                    !isNewUser && hasAcceptedAgreement ? (
                        <Redirect to={"/"} />
                    ) : (
                        <GetStarted
                            {...props}
                            {...{ isNewUser, hasAcceptedAgreement }}
                        />
                    )
                ) : (
                    <Login
                        {...props}
                        from={get(props, "location.state.from.pathname", "/")}
                    />
                );
            }}
        />
    );
};

const LogoutRoute = () => {
    LogoutRoute.propTypes = {
        location: PropTypes.object
    };

    firebase.logout().then(() => {
        store.dispatch(replace("/login"));
        store.dispatch(clearProfileData());
    });

    return (
        <Route
            render={props => {
                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                );
            }}
        />
    );
};

class App extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func,
        profileData: PropTypes.object,
        profileLoaded: PropTypes.bool
    };

    componentDidMount() {
        const { profileLoaded } = this.props;
        const profileData = store.getState().profileData;

        if (profileLoaded && !get(profileData, "role", undefined)) {
            this.fetchProfileData();
        }
    }

    componentDidUpdate() {
        const { profileLoaded, profileData } = this.props;

        if (profileLoaded && !get(profileData, "role", undefined)) {
            this.fetchProfileData();
        }
    }

    fetchProfileData = () => {
        const { dispatch } = this.props;

        dispatch(startSpinner());
        dispatch(getProfileData());
    };

    render() {
        const { profileData } = this.props;

        const isUserLoggedIn = isUserAuthenticated();

        const isToFetchRole =
            isUserLoggedIn && !get(profileData, "role", undefined);

        return (
            <Switch>
                {isToFetchRole && <MaterialSpinner />}
                <LoginRoute exact path="/login" />
                <GetStartedRoute exact path="/get_started" />
                <LogoutRoute exact path="/logout" />
                <Route
                    exact
                    path="/reset-password"
                    render={props => {
                        return isUserLoggedIn ? (
                            <Redirect to={"/"} />
                        ) : (
                            <ResetPassword {...props} />
                        );
                    }}
                />
                <PrivateRoute path="/users" />
                <PrivateRoute path="/settings" />
                <PrivateRoute path="/offers/overview" />
                <Route
                    exact
                    path="/offers"
                    render={props => {
                        return isUserLoggedIn ? (
                            <Redirect to={"/offers/overview"} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        );
                    }}
                />
                <PrivateRoute path="/offers/fileupload" />
                <PrivateRoute path="/offers/classification" />
                <PrivateRoute path="/offers/keywordstagging" />
                <PrivateRoute path="/invoicing" />
                <Route
                    exact
                    path="/mapping"
                    render={props => {
                        return isUserLoggedIn ? (
                            <Redirect to={"/mapping/entitiesmapping"} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        );
                    }}
                />
                <PrivateRoute path="mapping/entitiesmapping" />
                <PrivateRoute path="mapping/unmappedentities" />
                <PrivateRoute path="mapping/entitiesuploads" />
                <PrivateRoute path="mapping/cooppartnersmapping" />
                <PrivateRoute path="/usermanual" />
                <PrivateRoute path="/marketingmaterials" />
                <PrivateRoute path="/ihk-badges" />
                <PrivateRoute path="/" />
            </Switch>
        );
    }
}

const CompConnected = connect(({ firebase: { profile }, profileData }) => ({
    profileLoaded: profile.isLoaded,
    profileData
}));
const AppConnected = CompConnected(App);

const reduxFirebaseProps = {
    firebase,
    config: firebaseReduxConfig,
    dispatch: store.dispatch
};

function AuthIsLoaded({ children }) {
    AuthIsLoaded.propTypes = {
        children: PropTypes.node
    };
    const auth = useSelector(state => state.firebase.auth);

    if (!isLoaded(auth)) return <div />;
    return children;
}

ReactDOM.render(
    <MuiThemeProvider theme={createTheme(Global.muiTheme)}>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...reduxFirebaseProps}>
                <AuthIsLoaded>
                    <ConnectedRouter history={history}>
                        <AppConnected />
                    </ConnectedRouter>
                </AuthIsLoaded>
            </ReactReduxFirebaseProvider>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById("root")
);

serviceWorker();
