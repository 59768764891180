import Global from "../../../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "16px",
        height: "fit-content",
        width: "100%"
    },
    innerContainer: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "1fr 1fr"
    },
    selectControl: {
        padding: "7px 8px"
    },
    selectMenu: {
        maxHeight: "240px"
    },
    selectMenuContainer: {
        position: "relative"
    },
    selectMultiValue: {
        backgroundColor: Global.colors.darkSlateBlue,
        border: "unset",
        borderRadius: "4px",
        margin: "4px"
    },
    selectMultiValueLabel: {
        color: Global.colors.white,
        padding: "5.5px 12px"
    },
    selectMultiValueRemove: {
        borderRadius: "4px",
        color: Global.colors.white,
        margin: "unset"
    },
    title: {
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px"
    }
};

export default styles;
