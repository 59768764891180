import Global from "../../../../../../../../../../../config/global.css";

const styles = {
    container: {
        background: Global.colors.white,
        borderRadius: "12px",
        maxWidth: "356px",
        padding: "24px 24px 29px",
        position: "relative",
        width: "100%",
        zIndex: 0
    },
    fillingBlock: {
        background: Global.colors.paleGrey,
        borderRadius: "12px 0px 0px 12px",
        height: "100%",
        left: "0",
        position: "absolute",
        top: "0",
        zIndex: 1
    },
    fillingBlockForTrackEnd: {
        borderRadius: "12px",
        transition: "0.5s",
        width: "100%"
    },
    fillingBlockForTrackStart: {
        width: 0
    },
    fillingBlockWithTransition: {
        transition: "0.5s"
    },
    headerContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        justifyContent: "space-between",
        padding: "0 0 15px",
        width: "100%"
    },
    slider: {
        background: Global.colors.darkPaleGrey,
        borderRadius: "100px",
        cursor: "pointer",
        height: "6px",
        position: "relative",
        width: "100%",
        zIndex: 1
    },
    sliderIcon: {
        alignItems: "center",
        background: Global.colors.blue,
        borderRadius: "10px",
        color: Global.colors.white,
        cursor: "pointer",
        display: "flex",
        fontSize: "18px",
        height: "20px",
        justifyContent: "center",
        position: "absolute",
        top: "-8px",
        width: "20px",
        willChange: "transform",
        zIndex: 1
    },
    timeLeft: {
        fontHeight: "26px",
        fontSize: "21px",
        zIndex: 1
    },
    title: {
        display: "block",
        fontSize: "16px",
        fontWeight: "bold",
        height: "21px",
        letterSpacing: "0.2em",
        lineHeight: "20px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        width: "calc(100% - 102px)",
        zIndex: 1
    }
};

export default styles;
