// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { change, Field } from "redux-form";
// Components
import {
    CreatableSelect,
    MaterialTextField
} from "../../../../../../../../../../../components/";
import { GroupCard } from "../../../../../";
// Validators
import { required } from "../../../../../../../../../../../services/validators";
// Helpers
import { sortByLabel } from "../../../../helpers/eventDetailsFormHelper";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Entity.css";

class Entity extends PureComponent {
    static propTypes = {
        editMode: PropTypes.bool,
        eventDetailsForm: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        entities: PropTypes.array
    };

    prepareEntities = () => {
        const { editMode, entities } = this.props;

        if (!entities) {
            return undefined;
        }

        let entitiesList = [];

        if (entities && entities.length) {
            // Map only entities which are not imported by job processes (have an external_id)
            entitiesList = (
                editMode
                    ? entities
                    : entities.filter(
                          entity => !get(entity, "external_id", undefined)
                      )
            ).map(entity => {
                const entityName = entity.name;

                return {
                    label: entityName,
                    value: entityName,
                    option: {
                        label: entityName,
                        value: entity
                    }
                };
            });
        }

        return entitiesList.sort(sortByLabel);
    };

    handleEntityChange = entity => {
        const { dispatch } = this.props;
        const data = get(entity, "option.value", undefined);

        dispatch(change("eventDetails", "entity.id", get(data, "_id", null)));
        dispatch(
            change("eventDetails", "entity.link", get(data, "link", null))
        );

        document.activeElement && document.activeElement.blur();
    };

    render() {
        const entities = this.prepareEntities();

        return (
            <GroupCard style={styles.card} title={translation.event.ihkEntity}>
                <div style={styles.container}>
                    <Field
                        component={CreatableSelect}
                        customOnChange={this.handleEntityChange}
                        editable
                        isClearable
                        isSearchable
                        label={translation.event.ihkName}
                        placeholder={""}
                        name="entity.name"
                        options={entities}
                        validate={required}
                    />

                    <Field
                        component={MaterialTextField}
                        label={translation.event.ihkLink}
                        name="entity.link"
                        style={styles.textField}
                        variant="outlined"
                    />
                </div>
            </GroupCard>
        );
    }
}

const mapStateToProps = state => {
    return {
        eventDetailsForm: get(state, "form.eventDetails", undefined),
        entities: state.eventDetails.entities
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Entity);
