/**
 * Validates the link and adds a protocol in case of missing one.
 *
 * @param {String} link the link to validate
 * @returns the link after validation and protocol addition in case of missing
 */
export function validateLink(link) {
    let linkUrl = link;
    if (linkUrl && !linkUrl.match(/^https?:\/\//i)) {
        linkUrl = "http://" + linkUrl;
    }

    return linkUrl;
}
