// Modules
import get from "lodash.get";
// Helpers
import { getRolesViewPermission } from "./usersPermissions";
// Translations
import translation from "../../../../config/translation";

/**
 * The roles filter options.
 */
export const rolesFilters = [
    {
        key: "all",
        label: translation.users.filters.all,
        option: { name: translation.users.filters.all },
        value: translation.users.filters.all
    },
    {
        key: "dihkadmin",
        label: translation.users.roles.dihkadmin,
        option: { name: translation.users.roles.dihkadmin },
        value: translation.users.roles.dihkadmin
    },
    {
        key: "dihkemployee",
        label: translation.users.roles.dihkemployee,
        option: { name: translation.users.roles.dihkemployee },
        value: translation.users.roles.dihkemployee
    },
    {
        key: "ihkadmin",
        label: translation.users.roles.ihkadmin,
        option: { name: translation.users.roles.ihkadmin },
        value: translation.users.roles.ihkadmin
    },
    {
        key: "ihkagent",
        label: translation.users.roles.ihkagent,
        option: { name: translation.users.roles.ihkagent },
        value: translation.users.roles.ihkagent
    },
    {
        key: "cooppartneradmin",
        label: translation.users.roles.cooppartneradmin,
        option: { name: translation.users.roles.cooppartneradmin },
        value: translation.users.roles.cooppartneradmin
    },
    {
        key: "cooppartneragent",
        label: translation.users.roles.cooppartneragent,
        option: { name: translation.users.roles.cooppartneragent },
        value: translation.users.roles.cooppartneragent
    },
    {
        key: "ccadmin",
        label: translation.users.roles.ccadmin,
        option: { name: translation.users.roles.ccadmin },
        value: translation.users.roles.ccadmin
    },
    {
        key: "ccagent",
        label: translation.users.roles.ccagent,
        option: { name: translation.users.roles.ccagent },
        value: translation.users.roles.ccagent
    }
];

/**
 * Gets the roles filters based on user's role and permissions.
 *
 * @param {Object} data the profile data
 * @returns an array of roles filters
 */
export function getBaseQueryRolesFilter({ profileData }) {
    const viewPermissions = getRolesViewPermission(profileData);
    const queryFilters = getRolesFiltersBasedOnPermissions({
        permissions: viewPermissions,
        profileData
    });

    return queryFilters;
}

/**
 * Gets the roles filters based on given permissions.
 *
 * @param {Object} data the profile data and permissions
 * @returns an array of roles filters
 */
export function getRolesFiltersBasedOnPermissions({
    permissions,
    profileData
}) {
    const entityId = get(profileData, "entity_id", undefined);

    let queryFilters = [];
    if (permissions.includes("all")) {
        return null;
    }

    rolesFilters.forEach(filter => {
        const permission = permissions.find(p => p.match(`${filter.key}*`));
        if (permission) {
            let queryFilter = {
                role: filter.key
            };
            if (permission.includes("by_entity")) {
                queryFilter["entity_id"] = entityId;
            }

            queryFilters.push(queryFilter);
        }
    });

    return queryFilters;
}

/**
 * Gets the allowed roles filters based on user role and permissions.
 *
 * @param {Object} data the profile data
 * @returns an array of roles filters
 */
export function getAllowedRolesFilters({ profileData }) {
    const queryFilters = getBaseQueryRolesFilter({ profileData });

    return queryFilters
        ? queryFilters.length === 0
            ? null
            : rolesFilters.filter(
                  filter =>
                      filter.key === "all" ||
                      queryFilters.some(qf => qf.role === filter.key)
              )
        : rolesFilters;
}

/**
 * Filters the users that match with the search query
 *
 * @param {*} searchQuery the search query to filter by
 * @param {*} users the users list to filter
 * @returns an array of users filtered by search query
 */
export function getFilteredUsersBySearchQuery(searchQuery, users) {
    let filteredUsers = [];
    if (!searchQuery || searchQuery.length === 0) {
        filteredUsers = users;
    } else {
        filteredUsers = users
            ? users.filter(user => {
                  const regularExp = new RegExp(`.*${searchQuery}.*`, "i");
                  return (
                      get(user, "displayName", "").search(regularExp) >= 0 ||
                      get(user, "email", "").search(regularExp) >= 0
                  );
              })
            : [];
    }
    return filteredUsers;
}
