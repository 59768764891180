// Helpers
import { getFromStorage } from "./storage";

export const dataPolicyLink =
    process.env.REACT_APP_WORDPRESS_URL_CMS + process.env.REACT_APP_POLICY_URL;
export const termsFileName =
    "AGBs_Online Shop_ZL_ISA_Kompetenz_Plus_Badges.pdf";
export const licenseAgreementFileName =
    "IHK-Users-Consent-Lizenzbedingungen.pdf";

/**
 * Opens a file from storage, in support_files folder, by its name.
 *
 * @param {String} fileName the file name to get the url
 */
export const openSupportFileLink = fileName => {
    getFromStorage(`support_files/${fileName}`).then(link => {
        window.open(link, "_blank");
    });
};

/**
 * Opens the terms file stored in storage.
 */
export const openTermsLink = () => openSupportFileLink(termsFileName);

/**
 * Opens the license agreement file stored in storage.
 */
export const openLicenseAgreementLink = () =>
    openSupportFileLink(licenseAgreementFileName);
