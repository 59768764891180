// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash.get";
// Styles
import styles from "./CustomRadioButton.css";

class CustomRadioButton extends PureComponent {
    static propTypes = {
        checked: PropTypes.bool,
        checkmarkDefaultClassName: PropTypes.string,
        defaultClassName: PropTypes.string,
        disabled: PropTypes.bool,
        error: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        meta: PropTypes.object,
        onChange: PropTypes.func,
        style: PropTypes.shape({
            checked: PropTypes.object,
            checkmark: PropTypes.object,
            container: PropTypes.object,
            containerWithError: PropTypes.object,
            disabled: PropTypes.object,
            error: PropTypes.object,
            input: PropTypes.object
        })
    };

    render() {
        const {
            checked,
            checkmarkDefaultClassName,
            defaultClassName,
            disabled,
            error,
            label,
            meta,
            onChange,
            style
        } = this.props;

        const hasError = meta ? Boolean(meta.touched && meta.error) : error;

        const checkmarkStyle = {
            ...styles.checkmark,
            ...get(style, "checkmark", {}),
            ...(checked
                ? { ...styles.checkedStyle, ...get(style, "checked", {}) }
                : {}),
            ...(hasError
                ? { ...styles.errorStyle, ...get(style, "error", {}) }
                : {}),
            ...(disabled
                ? { ...styles.disabledStyle, ...get(style, "disabled", {}) }
                : {})
        };

        const checkmarkClassName = classNames(
            "checkmark",
            checkmarkDefaultClassName,
            {
                disabled: disabled
            }
        );
        return (
            <label
                className={defaultClassName || "radio-button-container"}
                style={{
                    ...styles.container,
                    ...(disabled ? styles.containerDisabled : {}),
                    ...get(style, "container", {}),
                    ...(hasError ? get(style, "containerWithError", {}) : {})
                }}
                onClick={onChange}
            >
                {label}
                <input
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                    style={{ ...styles.input, ...get(style, "input", {}) }}
                    type="radio"
                />
                <span
                    className={checkmarkClassName}
                    onClick={e => e.preventDefault()}
                    style={checkmarkStyle}
                />
            </label>
        );
    }
}

export default CustomRadioButton;
