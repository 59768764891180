/**
 * Formats the price to german format and two fraction digits.
 *
 * @param {Number} price the price value in string
 * @returns {String} the price with german format
 */
export function formatPrice(price) {
    return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2
    }).format(price);
}

/**
 * Formats the price to german format with currency.
 *
 * @param {Number} price the price value in string
 * @returns {String} the price with german format with currency
 */
export function formatPriceWithCurrency(price) {
    return `${formatPrice(price)} €`;
}
