import Global from "../../config/global.css";

const styles = {
    circle: {
        cx: "44px",
        cy: "44px",
        fill: "none",
        r: "19.8px",
        strokeWidth: "3.8px"
    },
    container: {
        alignItems: "center",
        background: Global.colors.white,
        borderRadius: "8px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.16)",
        display: "flex",
        flexDirection: "column",
        minWidth: "344px",
        padding: "48px 24px 38px",
        position: "relative",
        width: "380px"
    },
    loadingContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    subTitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px",
        marginTop: "16px",
        textAlign: "center"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "23px",
        marginTop: "32px",
        textAlign: "center"
    }
};

export default styles;
