// Modules
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { MaterialButton, WarningPopup } from "../../../../../components/";
// Actions
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import { exportBookings } from "../../Invoicing.ducks";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./BulkAction.css";

function BulkAction({
    action,
    bulkSelection,
    closePopup,
    display,
    exportBookings,
    openPopup,
    updateBulkSelection
}) {
    const performAction = () => {
        action === "export" &&
            exportBookings({
                data: bulkSelection,
                successCb: () => {
                    updateBulkSelection({
                        operation: "unselectAll"
                    });
                },
                errorCb: () => {
                    openPopup({
                        children: (
                            <WarningPopup
                                buttonAction={() => {
                                    closePopup();
                                    performAction();
                                }}
                                buttonLabel={translation.invoicing.tryAgain}
                                closeFunction={() => {
                                    closePopup();
                                }}
                                imageStyle={styles.warningErrorPopupImage}
                                subTitle={
                                    translation.invoicing.genericError
                                        .description
                                }
                                title={translation.invoicing.genericError.title}
                            />
                        )
                    });
                }
            });
    };

    return (
        <div
            id="bulk-action"
            style={{ ...styles.container, ...(display ? {} : styles.hide) }}
        >
            <span style={styles.text}>
                {translation.invoicing.exportQuestion}
            </span>
            <MaterialButton
                containerStyle={styles.button.container}
                customType="boldPrimary"
                onClick={performAction}
                style={styles.button.default}
            >
                {translation.invoicing.confirmAndExport}
            </MaterialButton>
        </div>
    );
}

BulkAction.propTypes = {
    action: PropTypes.string.isRequired,
    bulkSelection: PropTypes.array,
    closePopup: PropTypes.func.isRequired,
    display: PropTypes.bool,
    exportBookings: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    updateBulkSelection: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    closePopup,
    exportBookings,
    openPopup
};

export default connect(null, mapDispatchToProps)(BulkAction);
