const styles = {
    container: {
        display: "grid",
        gap: "24px",
        height: "fit-content",
        padding: "32px 24px"
    }
};

export default styles;
