// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { bindActionCreators } from "redux";
// Form
import { change, Field, reduxForm } from "redux-form";
// Components
import {
    Checkbox,
    InfoMessage,
    LoginSignup,
    MaterialButton,
    MaterialFabButton,
    MaterialSpinner
} from "../../../../components";
// Helpers
import {
    dataPolicyLink,
    openLicenseAgreementLink,
    openTermsLink
} from "../../../../services/legalLinks";
// Translations
import translation from "../../../../config/translation";
// Validators
import { email, required } from "../../../../services/validators";
// Actions
import {
    addError,
    backToLogin,
    clearError,
    updateLegalAgreement
} from "../../GetStarted.ducks";
import { navigateTo } from "../../../Router/Router.ducks";
// Styles
import styles from "./LegalAgreement.css";

class LegalAgreement extends PureComponent {
    static propTypes = {
        addError: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        backToLogin: PropTypes.func.isRequired,
        clearError: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        from: PropTypes.string,
        getStartedError: PropTypes.object,
        handleSubmit: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        navigateTo: PropTypes.func.isRequired,
        updateLegalAgreement: PropTypes.func.isRequired
    };

    state = {
        confirmPasswordVisible: false,
        errorMessages: {},
        linkOnHover: undefined,
        passwordVisible: false
    };

    componentDidMount() {
        const { auth, dispatch } = this.props;

        email &&
            dispatch(
                change("updatePassword", "email", get(auth, "email", null))
            );
    }

    componentWillUnmount() {
        const { clearError, getStartedError } = this.props;

        get(getStartedError, "errorMessage", undefined) &&
            clearError &&
            clearError();
    }

    onSubmit = values => {
        const {
            clearError,
            getStartedError,
            navigateTo,
            updateLegalAgreement
        } = this.props;

        get(getStartedError, "errorMessage", undefined) &&
            clearError &&
            clearError();

        this.setState({ errorMessages: undefined });

        updateLegalAgreement &&
            updateLegalAgreement({
                legalConsent:
                    get(values, "termsConsent", undefined) &&
                    get(values, "licenseConsent", undefined),
                successCb: () => {
                    navigateTo && navigateTo("/");
                },
                errorCb: errorMessage => {
                    this.setError(errorMessage);
                }
            });
    };

    handlePasswordVisibility = () => {
        const { passwordVisible } = this.state;
        this.setState({ passwordVisible: !passwordVisible });
    };

    handleConfirmPasswordVisibility = () => {
        const { confirmPasswordVisible } = this.state;
        this.setState({ confirmPasswordVisible: !confirmPasswordVisible });
    };

    handleCloseErrorNotification = () => {
        const { clearError } = this.props;

        clearError && clearError();
    };

    getErrorMessage = field => {
        const { errorMessages } = this.state;
        return get(errorMessages, field, undefined);
    };

    setError(errorMessage) {
        const { addError } = this.props;

        addError && addError("error", errorMessage);

        const { errorMessages } = this.state;
        const newErrorMessages = {
            ...errorMessages,
            newPassword: translation.login.password,
            confirmNewPassword: translation.login.password
        };
        this.setState({
            errorMessages: newErrorMessages
        });
    }

    renderTermsConsentLabel = () => {
        return (
            <span>
                {`${translation.getStarted.consent.consents[1].text1} `}
                {this.renderLink(translation.consents.terms, () => {
                    openTermsLink();
                })}
                {` ${translation.getStarted.consent.consents[1].text2} `}
                {this.renderLink(translation.consents.dataPolicy, () => {
                    window.open(dataPolicyLink, "_blank");
                })}
                {` ${translation.getStarted.consent.consents[1].text3}*`}
            </span>
        );
    };

    renderLicenseConsentLabel = () => {
        return (
            <span>
                {`${translation.getStarted.consent.consents[2].text1} `}
                {this.renderLink(translation.consents.licenseAgreement, () => {
                    openLicenseAgreementLink();
                })}
                {` ${translation.getStarted.consent.consents[2].text2}*`}
            </span>
        );
    };

    updateLinkOnHover = label => {
        this.setState({ linkOnHover: label });
    };

    renderLink = (label, action) => {
        const { linkOnHover } = this.state;

        return (
            <MaterialButton
                containerStyle={styles.consent.linkButtonContainer}
                customType="link"
                defaultClassName={label}
                disableRipple
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.updateLinkOnHover(undefined);

                    action && action();
                }}
                onMouseEnter={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.updateLinkOnHover(label);
                }}
                onMouseLeave={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.updateLinkOnHover(undefined);
                }}
                style={{
                    ...styles.consent.linkButton,
                    ...(linkOnHover === label
                        ? styles.consent.linkButtonOnHover
                        : {})
                }}
            >
                {label}
            </MaterialButton>
        );
    };

    render() {
        const { backToLogin, getStartedError, handleSubmit } = this.props;

        return (
            <form
                onSubmit={handleSubmit(this.onSubmit)}
                style={styles.container}
            >
                <MaterialSpinner />
                <LoginSignup
                    action={
                        <MaterialFabButton
                            endAdornment={
                                <span
                                    className="icon-arrow-right"
                                    style={styles.proceedIcon}
                                />
                            }
                            key="submit-button"
                            onClick={() => {}}
                            style={styles.proceedButton}
                            type="submit"
                            variant="extended"
                        >
                            {translation.getStarted.proceed}
                        </MaterialFabButton>
                    }
                    backButton={
                        <MaterialFabButton
                            className="get-started-update-password-back-button"
                            disableRipple
                            onClick={() => {
                                backToLogin && backToLogin();
                            }}
                            style={styles.backButton.container}
                            variant="extended"
                        >
                            <span
                                className="icon-arrow-back"
                                style={styles.backButton.icon}
                            />

                            {translation.getStarted.backToLogin}
                        </MaterialFabButton>
                    }
                    errorMessage={
                        getStartedError &&
                        getStartedError.errorType && (
                            <InfoMessage
                                endAdornment={
                                    <span
                                        className="icon-close"
                                        key="info-message-icon"
                                        onClick={
                                            this.handleCloseErrorNotification
                                        }
                                        style={styles.errorMessageCloseIcon}
                                    />
                                }
                                icon={"default"}
                                key="info-message"
                                message={getStartedError.errorMessage}
                                style={styles.infoMessage}
                                type={getStartedError.errorType}
                            />
                        )
                    }
                    logo={
                        <img
                            alt={"logo"}
                            style={styles.logo}
                            src={
                                require("../../../../images/logo_DIHK-gGmbH.svg")
                                    .default
                            }
                        />
                    }
                    title={translation.getStarted.termsAndConditions}
                    subTitle={
                        <Fragment>
                            <div>
                                {
                                    translation.getStarted
                                        .acceptTermsAndConditions
                                }
                            </div>
                        </Fragment>
                    }
                    style={{
                        childrenContainer: styles.childrenContainer,
                        container: styles.getStartedContainer,
                        contentContainer: styles.getStartedContentContainer,
                        logoContainer: styles.logoContainer,
                        subTitleContainer: styles.subtitle,
                        titleContainer: styles.title
                    }}
                >
                    <div style={styles.consent.container}>
                        <div style={styles.consent.title}>
                            {translation.consents.title}
                        </div>
                        <div style={styles.consent.checkboxes}>
                            <Field
                                component={Checkbox}
                                type="checkbox"
                                label={this.renderTermsConsentLabel()}
                                name="termsConsent"
                                validate={required}
                            />
                            <Field
                                component={Checkbox}
                                type="checkbox"
                                label={this.renderLicenseConsentLabel()}
                                name="licenseConsent"
                                validate={required}
                            />
                        </div>
                    </div>
                </LoginSignup>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        getStartedError: state.getStarted
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                addError,
                backToLogin,
                clearError,
                change,
                navigateTo,
                updateLegalAgreement
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: "updateLegalAgreement" })(LegalAgreement));
