// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    InfiniteScrollLoading,
    TableHeaders,
    WarningPopup
} from "../../../../../components";
import InfiniteScroll from "react-infinite-scroller";
import { EditKeywordOffersForm, EditOfferKeywordsForm } from "./components";
// Actions
import {
    closeEditPopup,
    openEditPopup
} from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import { deleteKeyword } from "../../OffersKeywordsTagging.ducks";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./OffersKeywords.css";

class OffersKeywords extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        deleteKeyword: PropTypes.func.isRequired,
        dispatch: PropTypes.func,
        editPopup: PropTypes.object,
        entries: PropTypes.array.isRequired,
        handleLoadMoreEntries: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        RowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
            .isRequired,
        searchFilter: PropTypes.object,
        searchQuery: PropTypes.string,
        tab: PropTypes.string.isRequired,
        tableHeaders: PropTypes.array.isRequired,
        totalCount: PropTypes.number
    };

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    handleDeleteKeyword = entry => {
        const { closeEditPopup, closePopup, deleteKeyword, openPopup } =
            this.props;

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        deleteKeyword &&
                            deleteKeyword({
                                keywordId: get(entry, "_id", undefined),
                                successCb: () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                },
                                errorCb: () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                }}
                                                buttonLabel={
                                                    translation
                                                        .offersKeywordsTagging
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation
                                                        .offersKeywordsTagging
                                                        .error.subtitle
                                                }
                                                title={
                                                    translation
                                                        .offersKeywordsTagging
                                                        .error.title
                                                }
                                            />
                                        )
                                    });
                                }
                            });
                    }}
                    buttonLabel={
                        translation.offersKeywordsTagging.editPopup.confirm
                    }
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.offersKeywordsTagging
                            .deleteKeywordDescription
                    }
                    title={translation.offersKeywordsTagging.deleteKeyword}
                />
            )
        });
    };

    openEditPopup = entry => {
        const { closeEditPopup, dispatch, openEditPopup, tab } = this.props;

        let editPopupParams = {
            cancelAction: closeEditPopup,
            cancelButtonLabel:
                translation.offersKeywordsTagging.editPopup.cancel,
            confirmButtonLabel:
                translation.offersKeywordsTagging.editPopup.saveChanges,
            style: styles.editPopup
        };

        if (tab === "offers") {
            editPopupParams = {
                ...editPopupParams,
                children: <EditOfferKeywordsForm entry={entry} />,
                confirmAction: () => {
                    dispatch(submit("editOfferKeywords"));
                },
                header: translation.offersKeywordsTagging.editPopup.editKeywords
            };
        } else if (tab === "keywords") {
            editPopupParams = {
                ...editPopupParams,
                children: <EditKeywordOffersForm entry={entry} />,
                confirmAction: () => {
                    dispatch(submit("editKeywordOffers"));
                },
                header: translation.offersKeywordsTagging.editPopup.editKeyword,
                optionalAction: () => this.handleDeleteKeyword(entry),
                optionalButtonLabel:
                    translation.offersKeywordsTagging.deleteKeyword
            };
        }

        openEditPopup && openEditPopup(editPopupParams);
    };

    render() {
        const {
            handleLoadMoreEntries,
            entries,
            RowComponent,
            searchFilter,
            searchQuery,
            tab,
            tableHeaders,
            totalCount
        } = this.props;

        const tableGridStyle = {
            ...styles.tableGrid,
            ...(tab === "keywords" ? styles.tableGridKeywords : {})
        };

        return (
            <Fragment>
                <TableHeaders
                    containerStyle={{
                        ...tableGridStyle,
                        ...styles.tableHeaders
                    }}
                    headers={tableHeaders}
                />
                <div
                    className="infinite-scroll-container"
                    style={styles.rowsContainer}
                >
                    <InfiniteScroll
                        className="infinite-scroll"
                        hasMore={totalCount > (entries ? entries.length : 0)}
                        initialLoad={true}
                        loader={<InfiniteScrollLoading key="loading" />}
                        loadMore={() =>
                            handleLoadMoreEntries({
                                query: searchQuery,
                                classification: get(
                                    searchFilter,
                                    "classification",
                                    undefined
                                ),
                                hasNoKeywordsFilter:
                                    get(
                                        searchFilter,
                                        "noKeywords",
                                        undefined
                                    ) === "noKeywords"
                            })
                        }
                        threshold={500}
                        useWindow={false}
                        style={styles.infineScroll}
                        getScrollParent={() =>
                            document.getElementsByClassName(
                                "infinite-scroll-container"
                            )[0]
                        }
                    >
                        {entries &&
                            entries.map((entry, index) =>
                                RowComponent ? (
                                    <RowComponent
                                        containerStyle={tableGridStyle}
                                        entry={entry}
                                        key={index}
                                        openEditPopup={() =>
                                            this.openEditPopup(entry)
                                        }
                                    />
                                ) : null
                            )}
                    </InfiniteScroll>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const tab = get(props, "tab", undefined);
    return {
        editPopup: state.editPopup,
        entries: get(
            state,
            `offersKeywordsTagging.dataByTab.${tab}.entries`,
            []
        ),
        popup: state.popup,
        searchFilter: state.offersKeywordsTagging.searchFilter,
        searchQuery: state.offersKeywordsTagging.searchQuery,
        totalCount: get(
            state,
            `offersKeywordsTagging.dataByTab.${tab}.totalCount`,
            undefined
        )
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeEditPopup,
                closePopup,
                deleteKeyword,
                openEditPopup,
                openPopup
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffersKeywords);
