// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { change } from "redux-form";
import PropTypes from "prop-types";
// Components
import { GroupCard } from "../../../";
import {
    FileInput,
    IconButton,
    MaterialTextField
} from "../../../../../../../../../components";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import {
    onlyAlphaNum,
    required,
    requiredWithError
} from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Helpers
import { renderBrowseFileButton } from "../../../../../../helpers/offerDetailsFormHelper";
import { createEmptyPresentation } from "../../../../../../helpers/offerDetailsFormatHelper";
// Styles
import styles from "./Presentations.css";

class Presentations extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired
    };

    imageUrlRef = {};
    fileUrlRef = {};

    requiredFileImage = value => {
        return requiredWithError(
            value,
            translation.offers.editPopup.requiredPresentationFileImage
        );
    };

    renderPresentation = ({ fields }) => {
        const { dispatch } = this.props;

        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        const itemValues = fields.get(index);
                        const hasImageSet =
                            !!itemValues.image && !!itemValues.image.url;
                        const hasFileSet =
                            !!itemValues.file && !!itemValues.file.url;

                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.container}>
                                    <span style={styles.label}>{`${
                                        translation.offers.editPopup
                                            .presentation
                                    } ${index + 1}`}</span>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                                <div style={styles.container}>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            label={
                                                translation.offers.editPopup
                                                    .title
                                            }
                                            name={`${item}.title`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={required}
                                        />
                                        <Field
                                            component={MaterialTextField}
                                            disabled={
                                                (hasFileSet &&
                                                    !(
                                                        itemValues.file
                                                            .url instanceof File
                                                    )) ||
                                                (hasImageSet &&
                                                    !(
                                                        itemValues.image
                                                            .url instanceof File
                                                    ))
                                            }
                                            label={
                                                translation.offers.editPopup
                                                    .name
                                            }
                                            name={`${item}.name`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={[required, onlyAlphaNum]}
                                        />
                                    </div>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            endAdornment={renderBrowseFileButton(
                                                () => {
                                                    this.imageUrlRef[index] &&
                                                        this.imageUrlRef[
                                                            index
                                                        ].click();
                                                },
                                                hasImageSet,
                                                () => {
                                                    dispatch(
                                                        change(
                                                            "offerDetails",
                                                            `${item}.image`,
                                                            null
                                                        )
                                                    );
                                                }
                                            )}
                                            InputLabelProps={{ shrink: true }}
                                            label={
                                                translation.offers.editPopup
                                                    .presentationImage
                                            }
                                            name={`${item}.image.name`}
                                            placeholder={
                                                translation.offers.editPopup
                                                    .browseFile
                                            }
                                            style={{
                                                ...styles.disabled,
                                                ...styles.textField
                                            }}
                                            variant="outlined"
                                            validate={
                                                !hasFileSet
                                                    ? this.requiredFileImage
                                                    : undefined
                                            }
                                        />
                                        <Field
                                            accept=".jpg,.jpeg,.png"
                                            component={FileInput}
                                            customOnChange={file => {
                                                file &&
                                                    dispatch(
                                                        change(
                                                            "offerDetails",
                                                            `${item}.image.name`,
                                                            file.name
                                                        )
                                                    );
                                            }}
                                            inputRef={ref => {
                                                this.imageUrlRef[index] !==
                                                    ref &&
                                                    (this.imageUrlRef[index] =
                                                        ref);
                                            }}
                                            name={`${item}.image.url`}
                                            style={styles.uploadInput}
                                        />
                                        <Field
                                            component={MaterialTextField}
                                            endAdornment={renderBrowseFileButton(
                                                () => {
                                                    this.fileUrlRef[index] &&
                                                        this.fileUrlRef[
                                                            index
                                                        ].click();
                                                },
                                                hasFileSet,
                                                () => {
                                                    dispatch(
                                                        change(
                                                            "offerDetails",
                                                            `${item}.file`,
                                                            null
                                                        )
                                                    );
                                                }
                                            )}
                                            InputLabelProps={{ shrink: true }}
                                            label={
                                                translation.offers.editPopup
                                                    .presentationFile
                                            }
                                            name={`${item}.file.name`}
                                            placeholder={
                                                translation.offers.editPopup
                                                    .browseFile
                                            }
                                            style={{
                                                ...styles.disabled,
                                                ...styles.textField
                                            }}
                                            variant="outlined"
                                            validate={
                                                !hasImageSet
                                                    ? this.requiredFileImage
                                                    : undefined
                                            }
                                        />
                                        <Field
                                            accept=".pdf"
                                            component={FileInput}
                                            customOnChange={file => {
                                                file &&
                                                    dispatch(
                                                        change(
                                                            "offerDetails",
                                                            `${item}.file.name`,
                                                            file.name
                                                        )
                                                    );
                                            }}
                                            inputRef={ref => {
                                                this.fileUrlRef[index] !==
                                                    ref &&
                                                    (this.fileUrlRef[index] =
                                                        ref);
                                            }}
                                            name={`${item}.file.url`}
                                            style={styles.uploadInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <IconButton
                    defaultClassName="icon-plus"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                        fields.push(createEmptyPresentation());
                    }}
                    type={["default"]}
                    style={styles.addButton}
                >
                    <span style={styles.addButtonLabel}>
                        {translation.offers.editPopup.addPresentation}
                    </span>
                </IconButton>
            </Fragment>
        );
    };

    render() {
        return (
            <GroupCard title={translation.offers.editPopup.presentation}>
                <FieldArray
                    component={this.renderPresentation}
                    name="presentations"
                />
            </GroupCard>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Presentations);
