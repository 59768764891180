import Global from "../../config/global.css";

const styles = {
    boldPrimary: {
        backgroundColor: Global.colors.oliveGreen,
        borderRadius: "12px 12px 0px 12px",
        boxShadow: "none",
        color: Global.colors.white,
        "&:hover": {
            backgroundColor: "#6FAA34",
            boxShadow: "none"
        },
        "&:active": {
            boxShadow: "none"
        }
    },
    disabled: {
        backgroundColor: "transparent",
        boxShadow: "none",
        color: `${Global.colors.paleGrey} !important`
    },
    link: {
        backgroundColor: "transparent",
        boxShadow: "none",
        color: Global.colors.darkSlateBlue,
        minHeight: "auto !important",
        minWidth: "auto !important",
        padding: "0 !important",
        textDecorationLine: "underline",
        width: "auto !important",
        "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
            color: Global.colors.blue,
            textDecorationLine: "underline"
        },
        "&:active": {
            backgroundColor: "transparent",
            boxShadow: "none",
            color: Global.colors.blue,
            textDecorationLine: "underline"
        }
    },
    root: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        maxHeight: "48px",
        minWidth: "140px",
        padding: "16px 24px",
        textTransform: "none",
        width: "100%"
    }
};

export default styles;
