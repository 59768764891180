import Global from "../../../../config/global.css";

const styles = {
    childrenContainer: {
        display: "flex",
        flexDirection: "column",
        minHeight: "fit-content"
    },
    childrenInnerContainer: {
        display: "grid",
        gridAutoRows: "64px",
        gap: "16px"
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        minHeight: "fit-content"
    },
    contentContainer: {
        minHeight: "fit-content"
    },
    errorMessageCloseIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    },
    footerContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    },
    getStartedContainer: {
        flex: 1,
        height: "unset",
        minHeight: "fit-content"
    },
    loginButton: {
        marginLeft: "4px"
    },
    logo: {
        height: "48px"
    },
    logoContainer: {
        maxWidth: "325px"
    },
    passwordIcon: {
        color: Global.colors.blueyGrey,
        cursor: "pointer",
        fontSize: "24px"
    },
    proceedButton: {
        minHeight: "48px",
        width: "180px"
    },
    proceedIcon: {
        color: Global.colors.white,
        fontSize: "20px"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        maxWidth: "auto"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "30px"
    }
};

export default styles;
