import Global from "../../../../../config/global.css";

const styles = {
    blueBoxContainer: {
        backgroundColor: Global.colors.darkSlateBlue,
        borderRadius: "4px",
        color: Global.colors.white,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        margin: "4px",
        minHeight: "24px",
        padding: "5.5px 8px",
        width: "fit-content"
    },
    boxContainer: {
        border: `1px solid ${Global.colors.darkSlateBlue}`,
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        margin: "4px",
        minHeight: "24px",
        padding: "4.5px 8px",
        width: "fit-content"
    },
    icon: {
        fontSize: "24px"
    },
    innerContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    rowContainer: {
        cursor: "default",
        gridAutoFlow: "column",
        minHeight: "64px",
        padding: "16px 24px"
    },
    text: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        wordBreak: "break-word"
    }
};

export default styles;
