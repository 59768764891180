import Global from "../../../config/global.css";

const styles = {
    checkbox: {
        checkbox: {
            backgroundColor: "transparent",
            borderRadius: "2px"
        }
    },
    container: {
        display: "flex",
        flexDirection: "column"
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        padding: "24px 24px 16px"
    },
    rowsContainer: {
        alignContent: "flex-start",
        display: "grid",
        gridAutoFlow: "row",
        height: "calc(100vh - 250px)",
        padding: "0px 24px",
        overflow: "auto"
    },
    rowsContainerCollapsed: {
        height: "calc(100vh - 322px)"
    },
    rowsInnerContainer: {
        padding: "4px 0px 24px"
    },
    section: {
        alignContent: "center",
        alignItems: "center",
        background: Global.colors.paleGrey,
        borderRadius: "8px",
        color: Global.colors.blueyGrey,
        display: "grid",
        gap: "16px",
        gridTemplateColumns: "12px 1fr",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "24px",
        lineHeight: "13px",
        padding: "6px 24px",
        position: "relative",
        textTransform: "uppercase"
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "8px 160px minmax(220px, 20%) minmax(320px, 50%) minmax(92px, 10%) 65px 132px",
        padding: "12px 48px"
    },
    tableHeaders: {
        marginTop: "16px"
    },
    textAlignRight: {
        justifyContent: "flex-end"
    },
    topbarContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 24px"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
