import Global from "../../../../../../config/global.css";

const styles = {
    active: {
        backgroundColor: Global.colors.blue,
        color: Global.colors.white
    },
    container: {
        alignItems: "center",
        borderRadius: "16px",
        cursor: "pointer",
        display: "flex",
        height: "48px",
        minWidth: "48px",
        position: "relative"
    },
    disabled: {
        backgroundColor: "transparent",
        color: Global.colors.paleGrey,
        cursor: "default"
    },
    iconContainer: {
        alignItems: "center",
        display: "flex",
        height: "48px",
        justifyContent: "center",
        width: "48px"
    },
    innerContainer: {
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: "16px",
        color: Global.colors.darkSlateBlue,
        display: "grid",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "800",
        gridAutoFlow: "column",
        gridTemplateColumns: "48px auto",
        lineHeight: "21px",
        textTransform: "uppercase",
        width: "max-content"
    },
    label: {
        paddingRight: "16px"
    },
    onHover: {
        backgroundColor: Global.colors.digitalBlueT4,
        color: Global.colors.darkSlateBlue,
        position: "absolute",
        zIndex: 1
    }
};

export default styles;
