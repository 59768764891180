import Global from "../../../../../../../config/global.css";

const styles = {
    backButton: {
        container: {
            float: "left"
        },
        default: {
            backgroundColor: "unset",
            color: Global.colors.darkSlateBlue,
            fontWeight: "600",
            textTransform: "uppercase",
            width: "max-content",

            active: {
                backgroundColor: "unset"
            },
            onHover: {
                backgroundColor: Global.colors.lightPaleGrey
            }
        },
        icon: {
            fontSize: "24px",
            marginRight: "4px"
        }
    },
    mergeButton: {
        container: {
            float: "right"
        },
        default: {
            fontWeight: "600",
            textTransform: "uppercase",
            width: "max-content"
        },
        disabled: {
            backgroundColor: Global.colors.darkPaleGrey,
            color: Global.colors.white
        }
    },
    container: {
        backgroundColor: Global.colors.white,
        borderRadius: "12px 12px 0px 0px",
        margin: "0px 24px",
        padding: "12px 24px"
    }
};

export default styles;
