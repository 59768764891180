import Global from "../../../../../config/global.css";

const styles = {
    alignRight: {
        justifySelf: "end"
    },
    containerStyle: {},
    controlStyle: {
        borderWidth: "0px !important",
        paddingLeft: "0px !important"
    },
    selectControlDisabled: {
        display: "block",
        height: "fit-content",
        minHeight: "40px"
    },
    dropdownIndicatorContainer: {
        display: "none"
    },
    iconsContainer: {
        alignItems: "center",
        alignSelf: "flex-start",
        display: "grid",
        gridAutoFlow: "column",
        gap: "16px",
        justifyContent: "flex-end",
        marginTop: "4px"
    },
    rowBlock: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    },
    rowContainer: {
        gridAutoFlow: "column",
        minHeight: "64px",
        borderColor: "red"
    },
    selectsContainer: {
        alignItems: "center",
        display: "flex",
        height: "40px",
        overflow: "hidden"
    },
    selectMultiValue: {
        backgroundColor: Global.colors.digitalBlueT4,
        border: "unset",
        borderRadius: "4px",
        margin: "2px 4px 2px 0px"
    },
    selectMultiValueLabel: {
        padding: "5.5px 6px",
        paddingLeft: "6px"
    },
    suppliersContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "-webkit-box",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "unset",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2
    }
};

export default styles;
