// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Translations
import translation from "../../../../config/translation";
// Styles
import styles from "./ClassificationTag.css";

function ClassificationTag({ classification, style }) {
    return (
        <div
            style={{
                ...styles.container,
                ...get(styles, `classifications.${classification}`, {}),
                ...style
            }}
        >
            {get(translation.offers.classifications, classification, undefined)}
        </div>
    );
}

ClassificationTag.propTypes = {
    classification: PropTypes.string.isRequired,
    style: PropTypes.object
};

export default ClassificationTag;
