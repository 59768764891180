// Modules
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";
import get from "lodash.get";
import { makeStyles } from "@material-ui/core/styles";
// Components
import { Chip } from "@material-ui/core";
import OfferCard from "./components/OfferCard";
// Helpers
import { parseTitle } from "../../../../services/htmlDataParsing";
// Styles
import styles from "./Suggestions.css";
// Translation
import translation from "../../../../config/translation";

const useStyles = type =>
    makeStyles(() => ({
        label: { ...styles.label, ...get(styles, `${type}.label`, {}) },
        outlined: { ...styles.chip, ...get(styles, `${type}.container`, {}) }
    }));

function Suggestions({
    messages_template,
    sort,
    suggestions,
    template_type,
    texts
}) {
    Suggestions.propTypes = {
        id: PropTypes.number.isRequired,
        messages_template: PropTypes.array.isRequired,
        sendAutomaticMessage: PropTypes.func.isRequired,
        sort: PropTypes.oneOf(["asc", "desc", "ignore"]),
        suggestions: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                display_value: PropTypes.string,
                short_description: PropTypes.string
            })
        ),
        template_type: PropTypes.string,
        texts: PropTypes.array.isRequired
    };

    let messageCounter = 0;

    const classes = useStyles(template_type)();

    const parseText = text => {
        return {
            __html: sanitizeHtml(text, {
                allowedTags: ["span", "bchip"],
                allowedAttributes: {
                    "*": ["class"]
                },
                transformTags: {
                    bchip: () => ({
                        tagName: "span",
                        attribs: {
                            class: "bot-messages-suggestions-bchip"
                        }
                    })
                }
            })
        };
    };

    const getMessage = (messageTemplate, index) => {
        if (messageTemplate === "<text>" && texts && texts.length > 0) {
            return (
                <div
                    key={index}
                    style={{
                        ...(messageCounter > 0 ? styles.notFirstMessage : {})
                    }}
                >
                    <div
                        dangerouslySetInnerHTML={parseText(
                            texts[messageCounter++]
                        )}
                    />
                </div>
            );
        } else if (
            messageTemplate === "<tags>" ||
            messageTemplate === "<offers>"
        ) {
            return getSuggestionsTags(index);
        } else {
            return null;
        }
    };

    const getSuggestionsTags = index => {
        if (!suggestions || suggestions.length === 0) {
            return null;
        }

        if (!sort || sort !== "ignore") {
            suggestions.sort((suggestion1, suggestion2) =>
                !sort || sort === "asc"
                    ? suggestion1.value.localeCompare(suggestion2.value)
                    : suggestion2.value.localeCompare(suggestion1.value)
            );
        }

        const horizontalScroll = template_type === "offers";

        return (
            <div
                key={index}
                style={{
                    ...styles.tagsContainer,
                    ...(horizontalScroll
                        ? styles.horizontalScrollContainer
                        : {})
                }}
            >
                {suggestions.map((suggestion, index) => {
                    const suggestionValue = validateSuggestionType(suggestion);

                    return (
                        <Chip
                            classes={classes}
                            style={styles.disabled}
                            key={index}
                            clickable={false}
                            label={suggestionValue}
                            onClick={() => {
                                // Do nothing
                            }}
                            variant="outlined"
                        />
                    );
                })}
            </div>
        );
    };

    const parseOfferValue = suggestion => {
        return parseTitle({
            displayTitle: suggestion.display_value,
            title: suggestion.value
        });
    };

    const validateSuggestionType = suggestion => {
        if (suggestion.type === "date") {
            const date =
                !suggestion.value ||
                suggestion.value.toLowerCase() === "auf anfrage"
                    ? translation.chatbot.onRequest
                    : suggestion.value;

            return (
                <div style={styles.date.container}>
                    <span
                        className="icon-date"
                        style={{
                            ...styles.date.iconDisabled,
                            ...styles.date.icon
                        }}
                    />
                    <div style={styles.date.innerContainer}>{date}</div>
                </div>
            );
        } else if (template_type === "offers") {
            const value = parseOfferValue(suggestion);
            const shortDescription = get(
                suggestion,
                "short_description",
                undefined
            );

            return (
                <OfferCard title={value} shortDescription={shortDescription} />
            );
        } else {
            return suggestion.value;
        }
    };

    messageCounter = 0;

    return (
        <Fragment>
            {messages_template &&
                messages_template.map((mt, index) => {
                    return getMessage(mt, index);
                })}
        </Fragment>
    );
}

export default Suggestions;
