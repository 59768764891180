// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators, compose } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import debounce from "lodash/debounce";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Tab, Tabs } from "@material-ui/core";
import {
    MaterialFabButton,
    SearchBar,
    WarningPopup
} from "../../../components";
import { EntitiesMappingFilters } from "../components";
import {
    LegalEntityMappingRow,
    LegalEntitiesSuppliers,
    SuppliersMappingRow
} from "./components";
import {
    EditLegalEntitieSuppliersForm,
    EditSupplierLegalEntitiesForm
} from "./components/LegalEntitiesSuppliers/components";
// Actions
import {
    clearEntriesList,
    getLegalEntities,
    getSuppliers,
    saveLegalEntities,
    saveSuppliers,
    setLegalEntitySupplierTab
} from "./EntitiesMapping.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
// Helpers
import { hasPermission } from "./helpers/entitiesMappingPermissions";
import { getFilteredLegalEntitiesBySearchQuery } from "../helpers/legalEntitiesSearch";
// Styles
import styles from "./EntitiesMapping.css";
// Translations
import translation from "../../../config/translation";

class EntitiesMapping extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        clearEntriesList: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        editMappingForm: PropTypes.object,
        editPopup: PropTypes.object,
        getLegalEntities: PropTypes.func.isRequired,
        getSuppliers: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        location: PropTypes.object,
        setLegalEntitySupplierTab: PropTypes.func.isRequired,
        suppliers: PropTypes.array,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object,
        tab: PropTypes.string.isRequired
    };

    state = {
        filter: "all",
        entity_id: undefined
    };

    tabs = [
        {
            createNew: this.props.location.pathname.includes("entitiesmapping")
                ? () => this.createPopup()
                : false,
            icon: "icon-list",
            key: "legalEntities",
            rowComponent: LegalEntityMappingRow,
            tableHeaders: [
                translation.entitiesMapping.tableHeaders.legalEntity,
                translation.entitiesMapping.tableHeaders.suppliers,
                !this.props.location.pathname.includes("unmappedentities") &&
                    translation.entitiesMapping.tableHeaders.contractStatus
            ]
        },
        {
            createNew: this.props.location.pathname.includes("entitiesmapping")
                ? () => this.createPopup()
                : false,
            icon: "icon-suppliers",
            key: "suppliers",
            rowComponent: SuppliersMappingRow,
            tableHeaders: [
                translation.entitiesMapping.tableHeaders.suppliers,
                translation.entitiesMapping.tableHeaders.legalEntity,
                !this.props.location.pathname.includes("unmappedentities") &&
                    translation.entitiesMapping.tableHeaders.contractStatus
            ]
        }
    ];

    componentDidMount() {
        const { getLegalEntities, tab, setLegalEntitySupplierTab } = this.props;
        tab !== "legalEntities" && setLegalEntitySupplierTab("legalEntities");

        setTimeout(() => {
            getLegalEntities && getLegalEntities();
        }, 200);

        this.getURLQueryParams();
    }

    componentDidUpdate(prevProps) {
        const { tab, getLegalEntities, getSuppliers, clearEntriesList } =
            this.props;

        if (tab !== get(prevProps, "tab", undefined)) {
            clearEntriesList && clearEntriesList(tab);

            let searchElement = document.getElementsByClassName(
                "mapping-search-input"
            );
            searchElement =
                searchElement && searchElement.length > 0 && searchElement[0];
            searchElement && (searchElement.value = null);

            if (tab === "legalEntities") {
                getLegalEntities && getLegalEntities();
            } else {
                getSuppliers && getSuppliers();
            }
        }

        // Call getURLQueryParams when props change
        if (prevProps.tab !== tab) {
            this.getURLQueryParams();
        }
    }

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    getURLQueryParams() {
        const { setLegalEntitySupplierTab, location } = this.props;
        const urlParams = new URLSearchParams(location.search);
        const tab = urlParams.get("tab");
        const entityIdParam = urlParams.get("entityId");

        if (tab) {
            setLegalEntitySupplierTab && setLegalEntitySupplierTab(tab);
            this.setState({ entity_id: entityIdParam });
        }
    }

    closePopups = () => {
        const {
            editPopup: { opened },
            closeEditPopup,
            closePopup,
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    };

    createPopup = () => {
        const { dispatch, openEditPopup, tab } = this.props;

        openEditPopup &&
            openEditPopup({
                cancelAction: this.handleCancelCreatePopup,
                cancelButtonLabel: translation.entitiesMapping.editPopup.cancel,
                children:
                    tab === "legalEntities" ? (
                        <EditLegalEntitieSuppliersForm />
                    ) : (
                        <EditSupplierLegalEntitiesForm />
                    ),
                confirmAction: () => {
                    dispatch(submit("editLegalEntityMapping"));
                },
                confirmButtonLabel:
                    translation.entitiesMapping.editPopup.saveChanges,
                header: translation.entitiesMapping.editPopup.createMapping,
                style: styles.editPopup
            });
    };

    handleCancelCreatePopup = () => {
        const { closeEditPopup, closePopup, editMappingForm, openPopup } =
            this.props;

        let initialValues = get(editMappingForm, "initial", undefined);
        let newValues = get(editMappingForm, "values", undefined);

        const valuesHasChanged = !isEqual(initialValues, newValues);

        if (valuesHasChanged) {
            openPopup({
                children: (
                    <WarningPopup
                        buttonAction={() => {
                            closeEditPopup();
                            closePopup();
                        }}
                        buttonLabel={translation.entitiesMapping.confirm}
                        closeFunction={() => {
                            closePopup();
                        }}
                        imageStyle={styles.warningPopupImage}
                        subTitle={
                            translation.entitiesMapping.editPopup
                                .unsavedWarningMessage
                        }
                        title={
                            translation.entitiesMapping.editPopup.unsavedChanges
                        }
                    />
                )
            });
        } else {
            closeEditPopup();
        }
    };

    handleChangeActiveTab = (event, newActiveTab) => {
        const { setLegalEntitySupplierTab, tab } = this.props;

        if (newActiveTab !== tab) {
            setLegalEntitySupplierTab &&
                setLegalEntitySupplierTab(newActiveTab);
            this.closePopups();
            this.setState({ filter: "all" });

            // Clear the URL parameters
            const newUrl = window.location.origin + window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
        }

        this.setState({ entity_id: undefined });
    };

    applyFilters = filterValue => {
        const { dispatch, legalEntities, suppliers, tab } = this.props;
        const data = tab === "legalEntities" ? legalEntities : suppliers;

        const filter =
            filterValue.length === 0 ? { value: "all" } : filterValue[0];

        const filteredLegalEntities = getFilteredLegalEntitiesBySearchQuery(
            filter,
            data
        );

        if (tab === "legalEntities") {
            dispatch(saveLegalEntities(filteredLegalEntities, true));
        } else {
            dispatch(saveSuppliers(filteredLegalEntities, true));
        }

        this.setState({ filter: filter.value });
    };

    handleSearch = value => {
        const { dispatch, legalEntities, suppliers, tab } = this.props;
        const data = tab === "legalEntities" ? legalEntities : suppliers;

        const filteredLegalEntities = getFilteredLegalEntitiesBySearchQuery(
            value,
            data
        );

        if (tab === "legalEntities") {
            dispatch(saveLegalEntities(filteredLegalEntities, true));
        } else {
            dispatch(saveSuppliers(filteredLegalEntities, true));
        }
    };

    debounceSearchOnChange = debounce(this.handleSearch, 750);

    render() {
        const { profileData, classes, tab, location } = this.props;
        const { filter, entity_id } = this.state;
        const userRole = get(profileData, "role", {});
        const tabParams = this.tabs.find(el => el.key === tab);

        const unmappedEntitiesUrl =
            location.pathname.includes("unmappedentities");

        const submenuTitle = unmappedEntitiesUrl
            ? translation.navigationMenu.unmappedEntities
            : translation.navigationMenu.entitiesMapping;

        return (
            <div style={styles.container}>
                <Tabs
                    classes={{
                        flexContainer: classes.tabsContainer
                    }}
                    onChange={this.handleChangeActiveTab}
                    scrollButtons="off"
                    TabIndicatorProps={{
                        style: styles.tabs.indicator
                    }}
                    style={styles.tabs.container}
                    value={tab}
                >
                    {this.tabs.map((tabObj, index) => {
                        const isTabSelected = tab === tabObj.key;
                        return (
                            <Tab
                                classes={{
                                    root: classes.tabDefault,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper
                                }}
                                icon={
                                    <div
                                        className="tab-icon"
                                        style={{
                                            ...styles.tabs.icon.container
                                                .default,
                                            ...(isTabSelected
                                                ? styles.tabs.icon.container
                                                      .selected
                                                : {})
                                        }}
                                    >
                                        <span
                                            className={tabObj.icon}
                                            style={{
                                                ...styles.tabs.icon.icon
                                                    .default,
                                                ...(isTabSelected
                                                    ? styles.tabs.icon.icon
                                                          .selected
                                                    : {})
                                            }}
                                        />
                                    </div>
                                }
                                key={index}
                                label={
                                    translation.entitiesMapping.tabs[tabObj.key]
                                }
                                value={tabObj.key}
                            />
                        );
                    })}
                </Tabs>
                <div style={styles.headerTitle}>
                    {`${submenuTitle} — ${translation.entitiesMapping.tabs[tab]}`}
                </div>
                <div style={styles.topbarContainer}>
                    <SearchBar
                        icon="icon-search"
                        inputClassName="mapping-search-input"
                        onChange={this.debounceSearchOnChange}
                        placeholder={translation.labels.search}
                        style={{
                            container: styles.searchBarContainer,
                            input: styles.searchBarInput
                        }}
                    />
                    {get(tabParams, "createNew", undefined) &&
                        hasPermission(userRole, "create") && (
                            <MaterialFabButton
                                onClick={get(tabParams, "createNew", undefined)}
                                style={styles.addButtonContainer}
                                variant="circular"
                            >
                                <span
                                    className="icon-plus"
                                    style={styles.addButtonIcon}
                                />
                            </MaterialFabButton>
                        )}
                    {!unmappedEntitiesUrl && (
                        <EntitiesMappingFilters
                            applyFilters={this.applyFilters}
                            value={filter}
                        />
                    )}
                </div>
                <LegalEntitiesSuppliers
                    key={get(tabParams, "key", undefined)}
                    RowComponent={get(tabParams, "rowComponent", null)}
                    tab={get(tabParams, "key", undefined)}
                    tableHeaders={get(tabParams, "tableHeaders", [])}
                    isUnmappedEntitiesView={unmappedEntitiesUrl}
                    entityIdUrlParam={entity_id}
                    location={location}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        editMappingForm: get(state, "form.editLegalEntityMapping", undefined),
        editPopup: state.editPopup,
        legalEntities: state.entitiesMapping.legalEntities,
        suppliers: state.entitiesMapping.suppliers,
        popup: state.popup,
        profileData: state.profileData,
        tab: state.entitiesMapping.tab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeEditPopup,
                clearEntriesList,
                closePopup,
                getLegalEntities,
                getSuppliers,
                openEditPopup,
                openPopup,
                saveLegalEntities,
                saveSuppliers,
                setLegalEntitySupplierTab
            },
            dispatch
        )
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(EntitiesMapping);
