// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
// Components
import { SearchBar, TableHeaders } from "../../../../../../../components";
// Translation
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./OfferMergeHeader.css";

class OfferMergeHeader extends PureComponent {
    static propTypes = {
        clearSearchQuery: PropTypes.func.isRequired,
        setSearchQuery: PropTypes.func.isRequired
    };

    componentWillUnmount() {
        const { clearSearchQuery } = this.props;

        clearSearchQuery && clearSearchQuery();
    }

    handleSearch = value => {
        const { setSearchQuery } = this.props;

        setSearchQuery && setSearchQuery(value);
    };

    debounceSearchOnChange = debounce(this.handleSearch, 500);

    render() {
        return (
            <Fragment>
                <div
                    style={styles.title}
                >{`${translation.offersClassification.selectOfferToMerge}:`}</div>
                <SearchBar
                    icon="icon-search"
                    onChange={this.debounceSearchOnChange}
                    placeholder={translation.offers.search}
                    style={{
                        container: styles.searchBarContainer,
                        input: styles.searchBarInput
                    }}
                />
                <TableHeaders
                    containerStyle={styles.tableHeaders}
                    headers={[
                        {
                            label: translation.offersClassification.tableHeaders
                                .title
                        },
                        {
                            label: translation.offersClassification.tableHeaders
                                .classification,
                            style: styles.alignRight
                        }
                    ]}
                />
            </Fragment>
        );
    }
}

export default OfferMergeHeader;
