// Modules
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MenuCard } from "../../../components";
// Actions
import { navigateTo } from "../../../Router.ducks";
// Helpers
import {
    getMenusBasedOnRolePermissions,
    hasUserManualPermission,
    MENUS,
    USER_MANUAL_MENU
} from "../../../components/NavigationBar/helpers/navigationHelper";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./DashboardMenus.css";

function DashboardMenus({ navigateTo, userRole }) {
    const getAvailableMenus = () => {
        let menus = getMenusBasedOnRolePermissions(userRole);

        return menus && menus.filter(menu => menu.key !== MENUS[0].key);
    };

    const handleMenuOnClick = menu => {
        const path = get(menu, "path", undefined);
        if (!path) {
            return null;
        }

        navigateTo && navigateTo(path);
    };

    const menus = getAvailableMenus();

    return (
        <div style={styles.container}>
            <div style={styles.title}>{translation.dashboard.welcome}</div>
            <div style={styles.subtitle}>
                {translation.dashboard.availableMenus}
            </div>
            <div style={styles.menus}>
                {menus &&
                    menus.map((menu, index) => (
                        <MenuCard
                            key={index}
                            menu={{
                                ...menu,
                                title: translation.navigationMenu[menu.key]
                            }}
                            onClickAction={handleMenuOnClick}
                        />
                    ))}
            </div>
            {hasUserManualPermission(userRole) && (
                <div style={styles.userManual}>
                    <div style={styles.subtitle}>
                        {translation.dashboard.userManualReference}
                    </div>
                    <MenuCard
                        menu={{
                            ...USER_MANUAL_MENU,
                            title: translation.navigationMenu[
                                USER_MANUAL_MENU.key
                            ]
                        }}
                        onClickAction={handleMenuOnClick}
                    />
                </div>
            )}
        </div>
    );
}

DashboardMenus.propTypes = {
    navigateTo: PropTypes.func.isRequired,
    userRole: PropTypes.object
};

const mapStateToProps = state => {
    return {
        userRole: state.profileData.role
    };
};

const mapDispatchToProps = {
    navigateTo
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMenus);
