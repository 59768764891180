import Global from "../../../../config/global.css";

const styles = {
    chip: {
        backgroundColor: Global.colors.white,
        border: `1px solid ${Global.colors.darkSlateBlue}`,
        borderRadius: "12px",
        height: "unset",
        margin: "0px 8px 8px 0px",
        minHeight: "40px",
        padding: "10px 0px"
    },
    date: {
        container: {
            alignItems: "center",
            display: "flex",
            margin: "0px -4px"
        },
        icon: {
            backgroundColor: Global.colors.paleGrey,
            borderRadius: "100px",
            color: "inherit",
            fontSize: "24px",
            marginRight: "8px",
            padding: "3px"
        },
        iconDisabled: {
            color: Global.colors.darkPaleGrey
        },
        innerContainer: {
            display: "flex",
            flexDirection: "column"
        }
    },
    disabled: {
        borderColor: Global.colors.darkPaleGrey,
        color: Global.colors.darkPaleGrey
    },
    horizontalScrollContainer: {
        flexDirection: "row",
        flexWrap: "nowrap"
    },
    label: {
        hyphens: "auto",
        MozHyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "18px",
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    notFirstMessage: {
        marginTop: "8px"
    },
    offers: {
        container: {
            alignItems: "start",
            justifyContent: "left",
            height: "182px",
            minHeight: "182px",
            minWidth: "206px",
            padding: "0px",
            width: "206px",

            "&:hover": {
                backgroundColor: `${Global.colors.lighterSlateBlue} !important`,
                height: "max-content !important",
                zIndex: 1
            }
        },
        label: {
            display: "-webkit-box",
            lineHeight: "1.45em",
            overflow: "hidden",
            paddingLeft: "0px",
            paddingRight: "0px",
            textOverflow: "ellipsis",
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            whiteSpace: "break-spaces",

            "&:hover": {
                minHeight: "max-content !important",
                textOverflow: "unset !important",
                WebkitLineClamp: "unset !important",
                WebkitBoxOrient: "unset !important"
            }
        }
    },
    tagsContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "8px",
        marginTop: "12px"
    }
};

export default styles;
