//Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import get from "lodash.get";
//Actions
import { closeEditPopup } from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import { upsertChamberLocation } from "../../ChambersLocations.ducks";
//Components
import { Select, WarningPopup } from "../../../../../components";
import { Location } from "./components";
// Validators
import { required } from "../../../../../services/validators";
// Translations
import translation from "../../../../../config/translation";
//Styles
import styles from "./ChamberLocationDetails.css";

function onSubmit(values, props) {
    const { upsertChamberLocation, entry, closeEditPopup } = props;

    let data = {
        operation: entry ? "update" : "create",
        legalEntityId: get(
            values,
            `${entry ? "legalEntity" : "legalEntity.value"}`,
            undefined
        ),
        locationData: {
            locationId: get(values, "location.id", undefined),
            name: get(values, "location.name.label", undefined),
            postalCode: parseInt(get(values, "location.postalcode", undefined)),
            location: {
                type: "Point",
                coordinates: {
                    longitude: parseInt(
                        get(values, "location.longitude", undefined)
                    ),
                    latitude: parseInt(
                        get(values, "location.latitude", undefined)
                    )
                }
            }
        }
    };

    upsertChamberLocation &&
        upsertChamberLocation(
            data,
            () => {
                closeEditPopup();
            },
            (error, errorType) => {
                onError(error, errorType, props);
            }
        );
}

function onError(error, errorType, props) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.chambersLocations.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={
                    errorType === "warn"
                        ? styles.warningPopupImage
                        : styles.warningErrorPopupImage
                }
                subTitle={get(error, "description", undefined)}
                title={get(error, "title", undefined)}
            />
        )
    });
}

class ChamberLocationDetails extends PureComponent {
    static propTypes = {
        entry: PropTypes.object,
        legalEntities: PropTypes.array
    };

    prepareLegalEntities() {
        const { legalEntities, entry } = this.props;

        const chambersWithoutLocation = (legalEntities || []).filter(
            ({ location_id }) => (entry ? location_id : !location_id)
        );

        return chambersWithoutLocation.map(legalEntity => {
            const { _id, name } = legalEntity;

            return {
                label: name,
                value: _id,
                option: {
                    name: name,
                    id: _id
                }
            };
        });
    }

    render() {
        const { entry } = this.props;

        const legalEntitiesOptions = this.prepareLegalEntities();

        return (
            <form style={styles.container}>
                <Field
                    component={Select}
                    disabled={!!entry}
                    isClearable
                    isSearchable
                    label={translation.chambersLocations.editPopup.chamber}
                    name="legalEntity"
                    placeholder={""}
                    options={legalEntitiesOptions}
                    validate={required}
                />
                <Location locationId={get(entry, "location_id", undefined)} />
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { entry } = props;

    const locationInitialValues = () => {
        const locations = get(state, "chambersLocations.locations", []);

        const locationById = locations.find(
            x => x._id === get(entry, "location_id", undefined)
        );

        if (locationById) {
            const { _id, name, postal_code, location } = locationById;

            return {
                id: _id,
                name,
                postalcode: postal_code,
                latitude: get(location, "coordinates[0]", undefined),
                longitude: get(location, "coordinates[1]", undefined)
            };
        }
    };

    return {
        initialValues: {
            legalEntity: get(entry, "_id", undefined),
            location: entry && locationInitialValues()
        },
        legalEntities: get(state, "chambersLocations.legalEntities", undefined),
        locations: get(state, "chambersLocations.locations", undefined)
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    closePopup,
    openPopup,
    upsertChamberLocation
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "chamberLocation",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(ChamberLocationDetails)
);
