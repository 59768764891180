import Global from "../../config/global.css";

const styles = {
    endAdornment: {
        display: "flex",
        marginLeft: "4px"
    },
    extended: {
        root: {
            "& .MuiFab-label": {
                justifyContent: "space-evenly"
            },
            "&.MuiFab-root": {
                backgroundColor: Global.colors.blue,
                borderRadius: "100px",
                boxShadow: "none",
                color: Global.colors.white,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "normal",
                height: "48px",
                letterSpacing: "unset",
                lineHeight: "18px",
                textTransform: "unset",
                "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.26)",
                    backgroundColor: "rgba(0, 0, 0, 0.12)"
                },
                "&:hover": {
                    backgroundColor: "#0034AC"
                },
                "&:active": {
                    backgroundColor: Global.colors.blue
                }
            }
        }
    },
    circular: {
        root: {
            "&.MuiFab-root": {
                backgroundColor: Global.colors.blue,
                boxShadow: "none",
                color: Global.colors.white,
                height: "48px",
                width: "48px",
                "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.26)",
                    backgroundColor: "rgba(0, 0, 0, 0.12)"
                },
                "&:hover": {
                    backgroundColor: "#0034AC"
                },
                "&:active": {
                    backgroundColor: Global.colors.blue
                }
            }
        }
    },
    startAdornment: {
        display: "flex",
        marginRight: "4px"
    }
};

export default styles;
