// Modules
import sanitizeHtml from "sanitize-html";

/**
 * Sanitizes the html title.
 *
 * @param {Object} params display title and title params
 * @returns html title sanitized
 */
export function parseTitle({ displayTitle, title }) {
    const titleText = displayTitle || title || "";

    return {
        __html: sanitizeHtml(titleText, {
            allowedTags: [],
            allowedAttributes: false
        })
    };
}

/**
 * Sanitizes the html data allowing all tags and attributes.
 *
 * @param {String} data the data to sanitize
 * @returns html data sanitized
 */
export function parseHtmlData(data) {
    return {
        __html: sanitizeHtml(data || "", {
            allowedTags: false,
            allowedAttributes: false
        })
    };
}
