// Modules
import get from "lodash.get";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
// Translations
import translation from "../../../config/translation";

export const ADD_ERROR = "settings/ADD_ERROR";
export const CLEAR_ERROR = "settings/CLEAR_ERROR";

export const defaultState = {
    error: {
        fields: [],
        message: undefined
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ADD_ERROR: {
            return Object.assign({}, state, {
                error: action.payload
            });
        }
        case CLEAR_ERROR: {
            return Object.assign({}, state, { error: defaultState.error });
        }
        default: {
            return state;
        }
    }
};

export const addError = (errorMessage, fields) => {
    return {
        type: ADD_ERROR,
        payload: { fields, message: errorMessage }
    };
};

export const clearError = () => {
    return {
        type: CLEAR_ERROR
    };
};

export const checkCurrentPassword = (
    password,
    successCb = undefined,
    errorCb = undefined
) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();
        const user = firebaseAuth.currentUser;

        await user
            .reauthenticateWithCredential(
                firebase.auth.EmailAuthProvider.credential(user.email, password)
            )
            .then(function () {
                successCb
                    ? successCb(() => {
                          dispatch(endSpinner());
                      })
                    : dispatch(endSpinner());
            })
            .catch(function (error) {
                dispatch(endSpinner());

                let errorMessage = translation.errors.default;
                if (get(error, "code", error) === "auth/wrong-password") {
                    errorMessage =
                        translation.settings.errorMessage.wrongPassword;
                }
                errorCb && errorCb(errorMessage);
            });
    };
};

export const updatePassword = (
    newPassword,
    successCb = undefined,
    errorCb = undefined
) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();
        const user = firebaseAuth.currentUser;

        await user
            .updatePassword(newPassword)
            .then(function () {
                successCb && successCb();
                dispatch(endSpinner());
                firebase.reloadAuth();
            })
            .catch(function (error) {
                dispatch(endSpinner());

                let errorMessage = translation.errors.default;
                if (get(error, "code", error) === "auth/weak-password") {
                    errorMessage =
                        translation.settings.errorMessage.weakNewPassword;
                }
                errorCb && errorCb(errorMessage);
            });
    };
};

export const updateName = ({ name, cb }) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());

        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();
        const user = firebaseAuth.currentUser;

        await user.updateProfile({
            displayName: name
        });

        firebase.reloadAuth();

        setTimeout(() => {
            dispatch(endSpinner());
            cb && cb();
        }, 1000);
    };
};

export const uploadPhoto = ({ newPhotoURL, cb, newNameToUpdate }) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseStorage = firebase.storage().ref();
        const firebaseAuth = firebase.auth();
        const user = firebaseAuth.currentUser;

        await firebaseStorage
            .child(`profileImages/${user.uid}`)
            .put(newPhotoURL)
            .then(async function (file) {
                await file.ref
                    .getDownloadURL()
                    .then(async function (downloadURL) {
                        let data = {
                            photoURL: downloadURL
                        };
                        newNameToUpdate &&
                            (data["displayName"] = newNameToUpdate);

                        await user.updateProfile(data);
                    });

                firebase.reloadAuth();

                setTimeout(() => {
                    dispatch(endSpinner());
                    cb && cb();
                }, 1000);
            })
            .catch(function () {
                dispatch(endSpinner());
                cb && cb();
            });
    };
};

export const removePhoto = ({ cb, newNameToUpdate }) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseStorage = firebase.storage().ref();
        const firebaseAuth = firebase.auth();
        const user = firebaseAuth.currentUser;

        await firebaseStorage
            .child(`profileImages/${user.uid}`)
            .delete()
            .then(async function () {
                let data = {
                    photoURL: null
                };
                newNameToUpdate && (data["displayName"] = newNameToUpdate);

                await user.updateProfile(data);
                firebase.reloadAuth();

                setTimeout(() => {
                    dispatch(endSpinner());
                    cb && cb();
                }, 1000);
            })
            .catch(function () {
                dispatch(endSpinner());
                cb && cb();
            });
    };
};
