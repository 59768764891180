// Modules
import get from "lodash.get";
// Helpers
import { getSpecificRegexPermission } from "../../../../services/permissions";

/**
 * Gets the list of user management permissions matching a regex.
 *
 * @param {Object} profileData the user profile data
 * @param {String} regexPermission the permission in a regex
 * @returns list of permissions matching regex
 */
export function getRolesRegexPermission(profileData, regexPermission) {
    const userRole = get(profileData, "role", {});

    const permissions =
        getSpecificRegexPermission(
            get(userRole, "permissions", {}),
            "user_management",
            regexPermission
        ) || [];

    return permissions.map(p => p.replace(`${regexPermission}_`, ""));
}

/**
 * Gets the list of user management view permissions regex.
 *
 * @param {Object} profileData the user profile data
 * @returns list of view permissions matching regex
 */
export function getRolesViewPermission(profileData) {
    const regexPermission = "view";
    return getRolesRegexPermission(profileData, regexPermission);
}

/**
 * Gets the list of user management create permissions regex.
 *
 * @param {Object} profileData the user profile data
 * @returns list of create permissions matching regex
 */
export function getRolesCreatePermission(profileData) {
    const regexPermission = "create";
    return getRolesRegexPermission(profileData, regexPermission);
}

/**
 * Gets the list of user management update permissions regex.
 *
 * @param {Object} profileData the user profile data
 * @returns list of update permissions matching regex
 */
export function getRolesUpdatePermission(profileData) {
    const regexPermission = "update";
    return getRolesRegexPermission(profileData, regexPermission);
}

/**
 * Gets the list of user management delete permissions regex.
 *
 * @param {Object} profileData the user profile data
 * @returns list of delete permissions matching regex
 */
export function getRolesDeletePermission(profileData) {
    const regexPermission = "delete";
    return getRolesRegexPermission(profileData, regexPermission);
}

/**
 * Checks if the user has permission to perform an action to another user.
 *
 * @param {Object} params necessary params for the validation
 * @returns true if has permission, false otherwise
 */
function hasPermissionToAction({ actionPermissions, user, auth, profileData }) {
    if (actionPermissions.includes("all")) {
        return true;
    }

    const userId = get(user, "id", undefined);
    if (
        actionPermissions.includes("self") &&
        userId === get(auth, "uid", undefined)
    ) {
        return true;
    }

    const entityId = get(profileData, "entity_id", undefined);

    const userRole = get(user, "role", undefined);
    const userEntityId = get(user, "entity_id", undefined);

    const permission = actionPermissions.find(p => p.match(`${userRole}*`));
    if (permission) {
        if (permission.includes("by_entity")) {
            return userEntityId === entityId;
        }

        return true;
    }

    return false;
}

/**
 * Checks if the user has permission to delete another user.
 *
 * @param {Object} params necessary params for the validation
 * @returns true if has permission to delete, false otherwise
 */
export function hasPermissionToDelete({ user, profileData, auth }) {
    const deletePermissions = getRolesDeletePermission(profileData);
    return hasPermissionToAction({
        actionPermissions: deletePermissions,
        user,
        auth,
        profileData
    });
}

/**
 * Checks if the user has permission to edite another user.
 *
 * @param {Object} params necessary params for the validation
 * @returns true if has permission to edit, false otherwise
 */
export function hasPermissionToEdit({ user, profileData, auth }) {
    const updatePermissions = getRolesUpdatePermission(profileData);
    return hasPermissionToAction({
        actionPermissions: updatePermissions,
        user,
        auth,
        profileData
    });
}
