// Modules
import React, { Fragment, PureComponent } from "react";
import sanitizeHtml from "sanitize-html";
import get from "lodash.get";
// Helpers
import { data } from "./helpers/data";
// Styles
import { default as mainStyles } from "../../UserManual.css";

class OfferManagement extends PureComponent {
    parseText = text => {
        return {
            __html: sanitizeHtml(text, {
                allowedTags: ["p", "ul", "li", "b", "u"],
                allowedAttributes: false
            })
        };
    };

    render() {
        const subtopics = data.sort((a, b) => a.index - b.index);

        const stylesContent = mainStyles.details.content;
        return (
            <div
                className="user-manual-offer-management"
                style={stylesContent.container}
            >
                {subtopics &&
                    subtopics.map((subtopic, index) => {
                        const captions =
                            subtopic.captions &&
                            subtopic.captions.sort((a, b) => a.index - b.index);
                        const sections =
                            subtopic.sections &&
                            subtopic.sections.sort((a, b) => a.index - b.index);

                        return (
                            <Fragment key={index}>
                                <div style={stylesContent.innerContainer}>
                                    <div style={stylesContent.title}>
                                        {subtopic.title}
                                    </div>
                                    {subtopic.description && (
                                        <div
                                            dangerouslySetInnerHTML={this.parseText(
                                                subtopic.description
                                            )}
                                            style={stylesContent.text}
                                        />
                                    )}
                                    {subtopic.image && (
                                        <img
                                            alt={"img"}
                                            style={stylesContent.image}
                                            src={subtopic.image}
                                        />
                                    )}
                                    {captions && (
                                        <div
                                            style={
                                                stylesContent.captions.container
                                            }
                                        >
                                            {captions.map((caption, cindex) => (
                                                <div
                                                    key={cindex}
                                                    style={
                                                        stylesContent.captions
                                                            .innerContainer
                                                    }
                                                >
                                                    <div
                                                        style={
                                                            stylesContent
                                                                .captions.key
                                                        }
                                                    >
                                                        {caption.index}
                                                    </div>
                                                    <div
                                                        style={
                                                            stylesContent
                                                                .section
                                                                .innerContainer
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                ...stylesContent
                                                                    .section
                                                                    .title,
                                                                ...stylesContent
                                                                    .captions
                                                                    .title
                                                            }}
                                                        >
                                                            {caption.title}
                                                        </div>
                                                        <div
                                                            style={
                                                                stylesContent
                                                                    .section
                                                                    .description
                                                            }
                                                        >
                                                            {
                                                                caption.description
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {sections && (
                                        <div
                                            style={
                                                stylesContent.section.container
                                            }
                                        >
                                            {sections.map((section, sindex) => {
                                                let indexDesc = 0;
                                                let indexImg = 0;
                                                return (
                                                    <div
                                                        key={sindex}
                                                        style={{
                                                            ...stylesContent
                                                                .section
                                                                .innerContainer,
                                                            ...get(
                                                                subtopic,
                                                                "styles.section.innerContainer",
                                                                {}
                                                            )
                                                        }}
                                                    >
                                                        {section.template.map(
                                                            (t, tindex) => (
                                                                <Fragment
                                                                    key={tindex}
                                                                >
                                                                    {t ===
                                                                        "title" && (
                                                                        <div
                                                                            style={{
                                                                                ...stylesContent
                                                                                    .section
                                                                                    .title,
                                                                                ...get(
                                                                                    subtopic,
                                                                                    "styles.section.title",
                                                                                    {}
                                                                                )
                                                                            }}
                                                                        >
                                                                            {
                                                                                section.title
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    {t ===
                                                                        "description" && (
                                                                        <div
                                                                            dangerouslySetInnerHTML={this.parseText(
                                                                                section
                                                                                    .descriptions[
                                                                                    indexDesc++
                                                                                ]
                                                                            )}
                                                                            style={{
                                                                                ...stylesContent
                                                                                    .section
                                                                                    .description,
                                                                                ...get(
                                                                                    subtopic,
                                                                                    "styles.section.description",
                                                                                    {}
                                                                                )
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {t ===
                                                                        "image" &&
                                                                        section.images && (
                                                                            <img
                                                                                alt={
                                                                                    "img"
                                                                                }
                                                                                style={
                                                                                    stylesContent.image
                                                                                }
                                                                                src={
                                                                                    section
                                                                                        .images[
                                                                                        indexImg++
                                                                                    ]
                                                                                }
                                                                            />
                                                                        )}
                                                                </Fragment>
                                                            )
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                                {index + 1 < subtopics.length && (
                                    <div style={stylesContent.separator} />
                                )}
                            </Fragment>
                        );
                    })}
            </div>
        );
    }
}

export default OfferManagement;
