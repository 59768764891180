import Global from "../../../../config/global.css";

const styles = {
    icon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    },
    message: {
        alignItems: "center",
        display: "flex",
        padding: 0
    },
    messageComponent: {
        container: {
            padding: "10px 12px",
            maxWidth: "480px"
        },
        innerContainer: {
            gap: "8px"
        }
    },
    root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        minWidth: "unset !important",
        width: "fit-content"
    }
};

export default styles;
