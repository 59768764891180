import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        margin: "0px 12px"
    },
    price: {
        justifyContent: "flex-end"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row",
        margin: "12px"
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "minmax(150px, 20%) minmax(75px, 10%) minmax(150px, 24%) minmax(150px, 20%) minmax(70px, 8%) minmax(100px, 10%) 60px 32px",
        padding: "0px 24px"
    },
    tableHeaders: {
        height: "28px",
        marginTop: "24px",
        padding: "0px 32px"
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    },
    warningPopupImageError: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImageSuccess: {
        background: "rgba(140, 191, 89, 0.2)",
        color: Global.colors.oliveGreen
    }
};

export default styles;
