import Global from "../../config/global.css";

const styles = {
    boxShadowForContainer: {
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)"
    },
    container: {
        background: Global.colors.white,
        border: "1px solid transparent",
        borderRadius: "100px",
        cursor: "pointer",
        display: "grid",
        height: "48px",
        gap: "16px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr auto",
        paddingLeft: "24px",
        paddingRight: "16px"
    },
    disabled: {
        background: Global.colors.white,
        color: Global.colors.darkPaleGrey,
        cursor: "default"
    },
    focus: {
        color: Global.colors.darkSlateBlue
    },
    icon: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "24px",
        justifyContent: "center"
    },
    input: {
        border: "none",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "max-content",
        lineHeight: "18px",
        outline: "none",
        padding: "14px 0"
    },
    onHover: {
        border: `1px solid ${Global.colors.paleGrey}`
    },
    selected: {
        color: Global.colors.darkSlateBlue
    }
};

export default styles;
