// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
// Translation
import translation from "../../../config/translation";

export const CLEAR_KEYWORDS = "offersKeywordsTagging/CLEAR_KEYWORDS";
export const CLEAR_KEYWORDS_TAGGING =
    "offersKeywordsTagging/CLEAR_KEYWORDS_TAGGING";
export const CLEAR_KEYWORDS_TAGGING_LIST =
    "offersKeywordsTagging/CLEAR_KEYWORDS_TAGGING_LIST";
export const CLEAR_OFFERS = "offersKeywordsTagging/CLEAR_OFFERS";
export const CLEAR_SEARCH_FILTER = "offersKeywordsTagging/CLEAR_SEARCH_FILTER";
export const CLEAR_SEARCH_QUERY = "offersKeywordsTagging/CLEAR_SEARCH_QUERY";
export const SAVE_KEYWORDS = "offersKeywordsTagging/SAVE_KEYWORDS";
export const SAVE_KEYWORDS_TAGGING_LIST =
    "offersKeywordsTagging/SAVE_KEYWORDS_TAGGING_LIST";
export const SAVE_OFFERS = "offersKeywordsTagging/SAVE_OFFERS";
export const SAVE_SEARCH_FILTER = "offersKeywordsTagging/SAVE_SEARCH_FILTER";
export const SAVE_SEARCH_QUERY = "offersKeywordsTagging/SAVE_SEARCH_QUERY";
export const SET_OFFERS_KEYWORDS_TAGGING_TAB =
    "SET_OFFERS_KEYWORDS_TAGGING_TAB";

export const defaultState = {
    dataByTab: {
        offers: {
            entries: [],
            totalCount: 0
        },
        keywords: {
            entries: [],
            totalCount: 0
        }
    },
    keywords: undefined,
    offers: undefined,
    searchFilter: undefined,
    searchQuery: "",
    tab: "offers"
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_KEYWORDS: {
            return Object.assign({}, state, {
                keywords: defaultState.keywords
            });
        }
        case CLEAR_KEYWORDS_TAGGING: {
            return Object.assign({}, defaultState);
        }
        case CLEAR_KEYWORDS_TAGGING_LIST: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    [action.payload]: defaultState.dataByTab[action.payload]
                })
            });
        }
        case CLEAR_OFFERS: {
            return Object.assign({}, state, {
                offers: defaultState.offers
            });
        }
        case CLEAR_SEARCH_FILTER: {
            return Object.assign({}, state, {
                searchFilter: defaultState.searchFilter
            });
        }
        case CLEAR_SEARCH_QUERY: {
            return Object.assign({}, state, {
                searchQuery: defaultState.searchQuery
            });
        }
        case SAVE_KEYWORDS: {
            return Object.assign({}, state, {
                keywords: action.payload
            });
        }
        case SAVE_KEYWORDS_TAGGING_LIST: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    [action.payload.tab]: {
                        entries:
                            action.payload.startIndex === 0
                                ? [...action.payload.entries]
                                : [
                                      ...state.dataByTab[action.payload.tab]
                                          .entries,
                                      ...action.payload.entries
                                  ],
                        totalCount: action.payload.totalCount
                    }
                })
            });
        }
        case SAVE_OFFERS: {
            return Object.assign({}, state, {
                offers: action.payload
            });
        }
        case SAVE_SEARCH_FILTER: {
            return Object.assign({}, state, {
                searchFilter: action.payload
            });
        }
        case SAVE_SEARCH_QUERY: {
            return Object.assign({}, state, {
                searchQuery: action.payload
            });
        }
        case SET_OFFERS_KEYWORDS_TAGGING_TAB: {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearKeywords = () => {
    return {
        type: CLEAR_KEYWORDS
    };
};

export const clearKeywordsTagging = () => {
    return {
        type: CLEAR_KEYWORDS_TAGGING
    };
};

export const clearKeywordsTaggingList = tab => {
    return {
        type: CLEAR_KEYWORDS_TAGGING_LIST,
        payload: tab
    };
};

export const clearOffers = () => {
    return {
        type: CLEAR_OFFERS
    };
};

export const clearSearchFilter = () => {
    return {
        type: CLEAR_SEARCH_FILTER
    };
};

export const clearSearchQuery = () => {
    return {
        type: CLEAR_SEARCH_QUERY
    };
};

export const saveKeywords = keywords => {
    return {
        type: SAVE_KEYWORDS,
        payload: keywords
    };
};

export const saveKeywordsTaggingList = (
    tab,
    entries,
    startIndex,
    totalCount
) => {
    return {
        type: SAVE_KEYWORDS_TAGGING_LIST,
        payload: { tab, entries, startIndex, totalCount }
    };
};

export const saveOffers = offers => {
    return {
        type: SAVE_OFFERS,
        payload: offers
    };
};

export const saveSearchFilter = filter => {
    return {
        type: SAVE_SEARCH_FILTER,
        payload: filter
    };
};

export const saveSearchQuery = query => {
    return {
        type: SAVE_SEARCH_QUERY,
        payload: query
    };
};

export const setOffersKeywordsTaggingTab = tab => {
    return {
        type: SET_OFFERS_KEYWORDS_TAGGING_TAB,
        payload: tab
    };
};

export const getKeywords = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearKeywords());

    api.get("keywords")
        .then(response => {
            dispatch(saveKeywords(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getKeywords: ", e);
            dispatch(endSpinner());
        });
};

export const getOffers = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearOffers());

    api.get("offers")
        .then(response => {
            dispatch(saveOffers(get(response, "data.results", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getOffers: ", e);
            dispatch(endSpinner());
        });
};

export const getOffersKeywords =
    ({
        tab,
        query,
        classification,
        limit = 50,
        startIndex,
        hasNoKeywordsFilter = undefined,
        successCb = undefined,
        errorCb = undefined
    }) =>
    dispatch => {
        const displaySpinner = startIndex === 0;
        displaySpinner && dispatch(startSpinner());

        let params = {
            query: query || "",
            classification,
            limit,
            start_index: startIndex
        };
        let apiEndpoint = "keywords/offers";
        if (tab === "offers") {
            hasNoKeywordsFilter &&
                (params["no_keywords"] = hasNoKeywordsFilter);
        } else {
            apiEndpoint = "keywords/keywords";
        }

        api.get(apiEndpoint, params)
            .then(response => {
                dispatch(
                    saveKeywordsTaggingList(
                        tab,
                        get(response, "data.results", []),
                        startIndex,
                        get(response, "data.total_count", 0)
                    )
                );

                successCb && successCb();
                displaySpinner && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOffersKeywords: ", e);
                errorCb && errorCb();
                displaySpinner && dispatch(endSpinner());
            });
    };

export const upsertKeywords =
    ({ operation, data, successCb = undefined, errorCb = undefined }) =>
    dispatch => {
        dispatch(startSpinner());

        const apiOp = operation === "add" ? api.post : api.put;

        apiOp("keywords", data)
            .then(() => {
                setTimeout(() => {
                    dispatch(endSpinner());
                    dispatch(refreshDataByTab());
                    successCb && successCb();
                }, 500);
            })
            .catch(e => {
                console.log("Error calling upsertKeywords: ", e);

                let errorMessage = translation.offersKeywordsTagging.error;
                if (e.response && e.response.status === 409) {
                    errorMessage =
                        translation.offersKeywordsTagging
                            .errorDuplicatedKeyword;
                }

                dispatch(endSpinner());
                errorCb && errorCb(errorMessage);
            });
    };

export const refreshDataByTab = () => (dispatch, getState) => {
    const {
        offersKeywordsTagging: { dataByTab, searchFilter, searchQuery, tab }
    } = getState();

    const tabData = get(dataByTab, tab, {});
    const tabEntriesList = get(tabData, "entries", []);

    dispatch(startSpinner());
    dispatch(
        getOffersKeywords({
            tab,
            query: searchQuery,
            classification: get(searchFilter, "classification", undefined),
            hasNoKeywordsFilter:
                get(searchFilter, "noKeywords", undefined) === "noKeywords",
            limit: tabEntriesList.length ? tabEntriesList.length + 1 : 50,
            startIndex: 0,
            successCb: () => {
                dispatch(endSpinner());
            },
            errorCb: () => {
                dispatch(endSpinner());
            }
        })
    );
};

export const deleteKeyword =
    ({ keywordId, successCb, errorCb }) =>
    dispatch => {
        dispatch(startSpinner());

        api.delete(`keywords/${keywordId}`)
            .then(() => {
                dispatch(endSpinner());
                dispatch(refreshDataByTab());
                successCb && successCb();
            })
            .catch(e => {
                console.log("Error calling deleteKeyword: ", e);

                dispatch(endSpinner());
                errorCb && errorCb();
            });
    };
