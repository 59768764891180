// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Tab, Tabs } from "@material-ui/core";
import {
    ArchivedClassification,
    PendingClassification,
    ReadyReleaseClassification,
    UnclearClassification
} from "./components";
import { WarningPopup } from "../../../components";
// Actions
import {
    clearOffersClassification,
    clearOffersClassificationList,
    getLegalEntities,
    getOffersByTab,
    setOffersClassificationTab
} from "./OffersClassification.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
// Translation
import translation from "../../../config/translation";
// Styles
import styles from "./OffersClassification.css";

class OffersClassification extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        clearOffersClassification: PropTypes.func.isRequired,
        clearOffersClassificationList: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        getLegalEntities: PropTypes.func.isRequired,
        getOffersByTab: PropTypes.func.isRequired,
        offersByTab: PropTypes.object,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        setOffersClassificationTab: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired,
        location: PropTypes.object
    };

    tabs = [
        {
            key: "pending",
            icon: "icon-new-imports",
            contentComponent: PendingClassification
        },
        {
            key: "unclear",
            icon: "icon-double-check",
            contentComponent: UnclearClassification
        },
        {
            key: "readyRelease",
            icon: "icon-merge-release",
            contentComponent: ReadyReleaseClassification
        },
        {
            key: "archived",
            icon: "icon-archived",
            contentComponent: ArchivedClassification
        }
    ];

    componentDidMount() {
        const { getLegalEntities } = this.props;

        this.handleLoadOffers({ fromStart: true });
        this.getURLQueryParams();
        getLegalEntities && getLegalEntities();
    }

    componentDidUpdate(prevProps) {
        const { clearOffersClassificationList, getLegalEntities, tab } =
            this.props;

        if (tab !== get(prevProps, "tab", undefined)) {
            getLegalEntities && getLegalEntities();

            clearOffersClassificationList && clearOffersClassificationList(tab);
            this.handleLoadOffers({ fromStart: true });
        }
    }

    componentWillUnmount() {
        const { clearOffersClassification, closePopup, popup } = this.props;

        clearOffersClassification && clearOffersClassification();

        popup && popup.opened && closePopup && closePopup();
    }

    getURLQueryParams() {
        const { location, setOffersClassificationTab } = this.props;
        const urlParams = new URLSearchParams(location?.search);
        const prevTab = urlParams.get("prevTab");

        if (prevTab)
            setOffersClassificationTab && setOffersClassificationTab(prevTab);
    }

    handleLoadOffers = ({
        fromStart = true,
        title,
        classification,
        entity_id
    }) => {
        const { getOffersByTab, offersByTab, tab } = this.props;

        const offers = get(offersByTab, `${tab}.offers`, []);

        getOffersByTab &&
            getOffersByTab({
                tab,
                title,
                classification,
                entity_id,
                limit: 50,
                startIndex: fromStart
                    ? 0
                    : offers && offers.length > 0
                    ? offers.length
                    : 0,
                successCb: () => {
                    if (fromStart) {
                        const element = document.getElementsByClassName(
                            "infinite-scroll-container"
                        )[0];
                        element && element.scrollTo(0, 0);
                    }
                }
            });
    };

    handleLoadMoreOffers = ({ title, classification, entity_id }) => {
        const { offersByTab, tab } = this.props;

        const offers = get(offersByTab, `${tab}.offers`, []);
        const totalCount = get(offersByTab, `${tab}.totalCount`, 0);

        totalCount > 0 &&
            offers.length > 0 &&
            this.handleLoadOffers({
                fromStart: false,
                title,
                classification,
                entity_id
            });
    };

    handleChangeActiveTab = (event, newActiveTab) => {
        const { setOffersClassificationTab, tab } = this.props;

        if (newActiveTab !== tab) {
            setOffersClassificationTab &&
                setOffersClassificationTab(newActiveTab);

            // Clear the URL parameters
            const newUrl = window.location.origin + window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
        }
    };

    handleOpenPopup = (isError, title, subtitle, cb = undefined) => {
        const { closePopup, openPopup } = this.props;

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        closePopup();
                        cb && cb();
                    }}
                    buttonLabel={
                        isError
                            ? translation.offersClassification.tryAgain
                            : translation.offersClassification.confirm
                    }
                    closeFunction={() => {
                        closePopup();
                        cb && cb();
                    }}
                    iconClassName={isError ? null : "icon-success"}
                    imageStyle={
                        isError
                            ? styles.warningPopupImageError
                            : styles.warningPopupSuccessImage
                    }
                    subTitle={subtitle}
                    title={title}
                />
            )
        });
    };

    render() {
        const { classes, tab } = this.props;

        return (
            <div style={styles.container}>
                <Tabs
                    classes={{
                        flexContainer: classes.tabsContainer
                    }}
                    onChange={this.handleChangeActiveTab}
                    scrollButtons="off"
                    TabIndicatorProps={{
                        style: styles.tabs.indicator
                    }}
                    style={styles.tabs.container}
                    value={tab}
                >
                    {this.tabs.map((tabObj, index) => {
                        const isTabSelected = tab === tabObj.key;

                        return (
                            <Tab
                                classes={{
                                    root: classes.tabDefault,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper
                                }}
                                icon={
                                    <div
                                        className="tab-icon"
                                        style={{
                                            ...styles.tabs.icon.container
                                                .default,
                                            ...(isTabSelected
                                                ? styles.tabs.icon.container
                                                      .selected
                                                : {})
                                        }}
                                    >
                                        <span
                                            className={tabObj.icon}
                                            style={{
                                                ...styles.tabs.icon.icon
                                                    .default,
                                                ...(isTabSelected
                                                    ? styles.tabs.icon.icon
                                                          .selected
                                                    : {})
                                            }}
                                        />
                                    </div>
                                }
                                key={index}
                                label={
                                    translation.offersClassification.tabs[
                                        tabObj.key
                                    ]
                                }
                                value={tabObj.key}
                            />
                        );
                    })}
                </Tabs>
                {this.tabs.map(tabObj => {
                    const TabComponent =
                        tab === tabObj.key ? tabObj.contentComponent : null;
                    return TabComponent ? (
                        <TabComponent
                            handleLoadMoreOffers={this.handleLoadMoreOffers}
                            handleLoadOffers={this.handleLoadOffers}
                            key={tabObj.key}
                            openPopup={this.handleOpenPopup}
                            tab={tabObj.key}
                        />
                    ) : null;
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup,
        offersByTab: state.offersClassification.offersByTab,
        tab: state.offersClassification.tab
    };
};

const mapDispatchToProps = {
    clearOffersClassification,
    clearOffersClassificationList,
    closePopup,
    getLegalEntities,
    getOffersByTab,
    openPopup,
    setOffersClassificationTab
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(OffersClassification);
