import Global from "../../config/global.css";

const styles = {
    clearIcon: {
        color: Global.colors.blueyGrey,
        fontSize: "16px"
    },
    container: {
        height: "48px"
    },
    disabledContainer: {
        background: "transparent",
        border: `1px solid ${Global.colors.paleGrey}`,
        color: `${Global.colors.melon}!important`
    },
    errorContainer: {
        border: `1px solid ${Global.colors.melon}`
    },
    errorMessage: {
        color: `${Global.colors.melon}`,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        letterSpacing: "unset",
        lineHeight: "13px",
        marginTop: 0,
        paddingLeft: "16px"
    },
    iconContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        marginLeft: "16px",
        width: "24px"
    },
    iconRemoveContainer: {
        alignItems: "center",
        borderRadius: "100px",
        cursor: "pointer",
        display: "flex",
        fontSize: "16px",
        height: "16px",
        justifyContent: "center",
        width: "16px"
    },
    input: {
        color: Global.colors.darkSlateBlue,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0
    },
    menu: {
        borderRadius: "8px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)",
        margin: "4px 0 0"
    },
    menuDisabled: {
        display: "none"
    },
    menuList: {
        padding: 0
    },
    multiValue: {
        backgroundColor: Global.colors.darkSlateBlue,
        borderRadius: "100px",
        color: Global.colors.white,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "24px",
        lineHeight: "13px",
        margin: "4px 8px 4px 0px"
    },
    multiValueLabel: {
        alignItems: "center",
        color: Global.colors.white,
        display: "flex",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        padding: "0px 8px",
        paddingLeft: "8px",
        textAlign: "center"
    },
    multiValueRemove: {
        alignItems: "center",
        borderRadius: "100px",
        color: Global.colors.white,
        display: "flex",
        height: "24px",
        justifyContent: "center",
        margin: "0px 0px 0px -4px",
        padding: 0,
        width: "24px",
        "&:hover": {
            background: Global.colors.melon,
            color: Global.colors.white
        }
    },
    multiValueRemoveHide: {
        display: "none"
    },
    noOptionsMessage: {
        color: Global.colors.darkPaleGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        height: "48px",
        padding: "15px 16px",
        textAlign: "start"
    },
    option: {
        textAlign: "start",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "48px",
        lineHeight: "21px",
        padding: "15px 16px",
        wordBreak: "break-word",
        "&:active": {
            backgroundColor: Global.colors.blue,
            color: Global.colors.white
        },
        "&:first-of-type": {
            borderRadius: "8px 8px 0px 0px"
        },
        "&:last-child": {
            borderRadius: "0px 0px 8px 8px"
        },
        "&:first-of-type&:last-child": {
            borderRadius: "8px"
        }
    },
    optionFocused: {
        backgroundColor: Global.colors.lightPaleGrey,
        color: Global.colors.darkSlateBlue
    },
    optionSelected: {
        backgroundColor: Global.colors.blue,
        color: Global.colors.white
    },
    placeholder: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginLeft: 0
    },
    selectContainer: {
        backgroundColor: "transparent",
        border: `1px solid ${Global.colors.darkSlateBlue}`,
        borderRadius: "8px",
        boxShadow: "unset",
        boxSizing: "border-box",
        height: "48px",
        padding: "15px 16px",
        placeContent: "center",
        "&:focus-within": {
            border: `1px solid ${Global.colors.blue}`
        },
        "&:hover": {
            cursor: "pointer"
        }
    },
    selectContainerMargin: {
        padding: "11px 16px"
    },
    selectContainerMulti: {
        height: "unset",
        minHeight: "48px"
    },
    valueContainer: {
        padding: 0
    }
};

export default styles;
