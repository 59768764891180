import Global from "../../../../config/global.css";

const styles = {
    radio: {
        checked: {
            backgroundColor: Global.colors.digitalBlue,
            border: `1px solid ${Global.colors.digitalBlue}`,
            color: Global.colors.white,
            fontSize: "12px"
        },
        checkmark: {
            alignItems: "center",
            border: `1px solid ${Global.colors.darkPaleGrey}`,
            height: "12px",
            display: "flex",
            justifyContent: "center",
            top: "3px",
            width: "12px"
        },
        container: {
            color: Global.colors.darkSlateBlue,
            fontSize: "12px",
            paddingLeft: "22px"
        },
        containerWithError: {
            color: Global.colors.melon
        },
        error: {
            border: `1px solid ${Global.colors.darkPaleGrey}`
        },
        input: {
            width: "0px",
            margin: "0px"
        }
    }
};

export default styles;
