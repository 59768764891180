import Global from "../../../config/global.css";

const styles = {
    container: {
        padding: "24px 24px 32px"
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "16px"
    },
    image: {
        height: "338px"
    },
    innerContainer: {
        alignItems: "center",
        borderRadius: "4px",
        backgroundColor: Global.colors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "calc(100% - 48px)",
        padding: "96px 48px"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "23px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "1.4em",
        paddingTop: "88px",
        textAlign: "center",
        width: "55%"
    },
    text: {
        color: Global.colors.darkSlateBlue,
        fontSize: "16px",
        fontStyle: "normal",
        lineHeight: "1.4em",
        paddingTop: "10px",
        textAlign: "center",
        width: "55%",
        marginBottom: "20px"
    },
    addButtonIcon: {
        marginRight: "5px",
        fontWeight: "bold"
    }
};

export default styles;
