// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { DataCard } from "../../../";
import { VideoPlayer, EmptyPlayer } from "./components";
// Actions
import { setMedia } from "../../../../OfferDetails.ducks";
// Translation
import translation from "../../../../../../../../../config/translation";
// Helpers
import { extractVideoData } from "../../../../../../helpers/offerDetailsFormatHelper";
import { isValidMediaPath } from "../../../../../../../../../services/storage";
// Styles
import styles from "./Videos.css";
import { default as formStyles } from "../../DetailsView.css";

const Videos = ({ videos, setMedia }) => {
    Videos.propTypes = {
        videos: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
                format: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            })
        ),
        setMedia: PropTypes.func.isRequired
    };

    const [state, setState] = useState({});

    useEffect(() => {
        videos &&
            videos.forEach(async video => {
                const { _id, content, format } = video;

                if (!format || !content) {
                    return null;
                }

                let fileURL = undefined;
                await isValidMediaPath(
                    content,
                    format,
                    videoUrl => {
                        fileURL = videoUrl;
                        setState(prevState => ({
                            ...prevState,
                            [`${_id}_video`]: videoUrl
                        }));
                    },
                    "videos"
                );

                setMedia(
                    "video",
                    extractVideoData({
                        ...video,
                        fileURL
                    })
                );
            });
    }, [videos, setMedia]);

    const triggerVideoRefresh = async video => {
        const { _id, content, format } = video;

        await isValidMediaPath(
            content,
            format,
            videoUrl => {
                videoUrl;
                setState(prevState => ({
                    ...prevState,
                    [`${_id}_video`]: videoUrl
                }));
            },
            "videos"
        );
    };

    const renderVideo = (video, index, triggerVideoRefresh) => {
        const { _id, content, format, title } = video;

        if (!format || !content) {
            return null;
        }

        const videoURL = state[`${_id}_video`];

        return (
            <div key={index} style={styles.container}>
                <span style={formStyles.title}>{title}</span>
                {video.format === "link" && (
                    <iframe
                        className="video"
                        key={index}
                        title={index}
                        style={styles.content}
                        src={content
                            .replace(
                                "//youtu.be/",
                                "//www.youtube.com//watch?v="
                            )
                            .replace("watch?v=", "embed/")}
                    />
                )}
                {video.format === "mp4" &&
                    (videoURL ? (
                        <VideoPlayer videoToRender={videoURL} />
                    ) : (
                        <EmptyPlayer
                            video={video}
                            triggerVideoRefresh={triggerVideoRefresh}
                        />
                    ))}
            </div>
        );
    };

    if (!videos || videos.length === 0) {
        return null;
    }

    return (
        <DataCard title={translation.offerDetails.details.videos}>
            <div style={styles.innerContainer}>
                {videos.map((video, index) => {
                    return renderVideo(video, index, triggerVideoRefresh);
                })}
            </div>
        </DataCard>
    );
};

const mapDispatchToProps = {
    setMedia
};

export default connect(null, mapDispatchToProps)(Videos);
