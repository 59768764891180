// Modules
import React from "react";
import PropTypes from "prop-types";
// Components
import { Card } from "../";
// Styles
import styles from "./NumberCard.css";

function NumberCard({ subtitle, title, value }) {
    return (
        <Card style={styles.container}>
            <div style={styles.innerContainer}>
                {subtitle && <span style={styles.subtitle}>{subtitle}</span>}
                <div style={styles.valueContainer}>
                    <span style={styles.value}>
                        {value
                            ? new Intl.NumberFormat("de-DE").format(value)
                            : "-"}
                    </span>
                    {title && <span style={styles.title}>{title}</span>}
                </div>
            </div>
        </Card>
    );
}

NumberCard.propTypes = {
    subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    value: PropTypes.number
};

export default NumberCard;
