// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { BaseClassification } from "../";
import {
    OfferMergeBody,
    OfferMergeFooter,
    OfferMergeHeader
} from "./components";
// Actions
import {
    closeViewPopup,
    openViewPopup,
    updateViewPopupState
} from "../../../../../components/ViewPopup/ViewPopup.ducks";
import {
    getOffersByTab,
    getOfferSuggestions
} from "../../OffersClassification.ducks";
// Helpers
import { getScrollPosition } from "../../helpers/rowHelper";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./ReadyReleaseClassification.css";

class ReadyReleaseClassification extends PureComponent {
    static propTypes = {
        closeViewPopup: PropTypes.func,
        getOffersByTab: PropTypes.func,
        getOfferSuggestions: PropTypes.func,
        handleLoadMoreOffers: PropTypes.func.isRequired,
        handleLoadOffers: PropTypes.func.isRequired,
        offersByTab: PropTypes.object,
        openPopup: PropTypes.func,
        openViewPopup: PropTypes.func,
        updateViewPopupState: PropTypes.func,
        tab: PropTypes.string.isRequired,
        viewPopup: PropTypes.object
    };

    state = {
        searchQuery: ""
    };

    componentWillUnmount() {
        const {
            closeViewPopup,
            viewPopup: { opened }
        } = this.props;

        opened && closeViewPopup && closeViewPopup();
    }

    getOfferMergeBottomContainer = (offer, removeSelection) => {
        const { openPopup } = this.props;

        return (
            <OfferMergeFooter
                offer={offer}
                openPopup={openPopup}
                removeSelection={removeSelection}
            />
        );
    };

    getOfferElement = offer => {
        const offerURL = get(offer, "url", undefined);
        const rowElement =
            offerURL && document.getElementById(`${offerURL}-summary`);

        return rowElement;
    };

    clearSearchQuery = () => {
        const { searchQuery } = this.state;
        searchQuery !== "" && this.setState({ searchQuery: "" });
    };

    handleMergeOffersSearch = (value, offer) => {
        const { updateViewPopupState, viewPopup } = this.props;
        const { searchQuery } = this.state;

        const classification = get(offer, "classification", undefined);

        value !== searchQuery &&
            this.setState({ searchQuery: value || "" }, () => {
                this.handleLoadOffers({
                    fromStart: true,
                    title: value,
                    classification,
                    successCb: () => {
                        updateViewPopupState &&
                            updateViewPopupState(
                                Object.assign({}, get(viewPopup, "state", {}), {
                                    selection: undefined
                                })
                            );
                    }
                });
            });
    };

    handleMergeAction = (offer, removeSelection, initialRowHeight) => {
        const { getOfferSuggestions, openViewPopup, tab } = this.props;

        const classification = get(offer, "classification", undefined);

        getOfferSuggestions &&
            getOfferSuggestions({
                tab,
                offerId: get(offer, "_id", undefined),
                classification
            });

        let rowHeight = initialRowHeight;
        if (!rowHeight) {
            const rowElement = this.getOfferElement(offer);
            rowHeight = rowElement && rowElement.clientHeight;
        }

        const bodyHeight = `calc(100vh - ${414 + 72 + rowHeight}px)`;

        const elementClassName = "dashboard-children-container";
        const parentElement =
            document.getElementsByClassName(elementClassName)[0];

        this.handleLoadOffers({ fromStart: true, classification });

        openViewPopup &&
            openViewPopup({
                style: {
                    header: styles.header.container,
                    body: {
                        height: bodyHeight,
                        minHeight: "88px"
                    },
                    container: {
                        gridTemplateRows: `180px minmax(88px, ${bodyHeight}) 72px`,
                        height: `calc(100vh - ${234 + (rowHeight || 0)}px)`,
                        minWidth: parentElement
                            ? `${parentElement.scrollWidth}px`
                            : "100%",
                        overflow: "auto",
                        top: `${162 + (rowHeight || 0)}px`
                    }
                },
                header: (
                    <OfferMergeHeader
                        clearSearchQuery={this.clearSearchQuery}
                        setSearchQuery={value =>
                            this.handleMergeOffersSearch(value, offer)
                        }
                    />
                ),
                children: (
                    <OfferMergeBody
                        handleLoadMoreOffers={() =>
                            this.handleLoadMoreOffers({ classification })
                        }
                        tab={tab}
                        getSearchQuery={() => this.state.searchQuery}
                        offerToMergeURL={get(offer, "url", undefined)}
                    />
                ),
                bottomContainer: this.getOfferMergeBottomContainer(
                    offer,
                    removeSelection
                ),
                state: {
                    scrollPosition: getScrollPosition()
                }
            });
    };

    handleLoadOffers = ({
        fromStart = true,
        title,
        classification,
        successCb
    }) => {
        const { getOffersByTab, offersByTab } = this.props;

        const tab = "approved";
        const offers = get(offersByTab, `${tab}.offers`, []);

        getOffersByTab &&
            getOffersByTab({
                tab,
                title,
                classification,
                limit: 50,
                startIndex: fromStart
                    ? 0
                    : offers && offers.length > 0
                    ? offers.length
                    : 0,
                successCb
            });
    };

    handleLoadMoreOffers = ({ classification }) => {
        const { offersByTab } = this.props;
        const { searchQuery } = this.state;

        const tab = "approved";
        const offers = get(offersByTab, `${tab}.offers`, []);
        const totalCount = get(offersByTab, `${tab}.totalCount`, 0);

        totalCount > 0 &&
            offers.length > 0 &&
            this.handleLoadOffers({
                fromStart: false,
                title: searchQuery,
                classification
            });
    };

    render() {
        const {
            handleLoadMoreOffers,
            handleLoadOffers,
            openPopup,
            tab,
            viewPopup: { opened }
        } = this.props;

        return (
            <BaseClassification
                action={{ key: "merge", action: this.handleMergeAction }}
                additionalFilters={["classification"]}
                availableClassifications={["unclear"]}
                handleLoadMoreOffers={handleLoadMoreOffers}
                handleLoadOffers={handleLoadOffers}
                hasSearch
                openPopup={openPopup}
                tab={tab}
                tableHeaders={[
                    {
                        label: translation.offersClassification.tableHeaders
                            .title
                    },
                    {
                        label: translation.offersClassification.tableHeaders
                            .classification,
                        style: styles.alignRight
                    },
                    {
                        label: translation.offersClassification.tableHeaders
                            .supplier
                    }
                ]}
                tableHeadersStyle={{
                    ...styles.tableHeaders,
                    ...(opened ? styles.hideContainer : {})
                }}
                topbarContainerStyle={{
                    ...(opened ? styles.hideContainer : {})
                }}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        offersByTab: state.offersClassification.offersByTab,
        viewPopup: state.viewPopup
    };
};

const mapDispatchToProps = {
    closeViewPopup,
    getOffersByTab,
    getOfferSuggestions,
    openViewPopup,
    updateViewPopupState
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReadyReleaseClassification);
