// Modules
import get from "lodash.get";

/**
 * Returns an array of seminars ordered by start date ascending.
 *
 * @param {Object} details the seminar details
 */
export function getSeminarsSortedByStartDate(details) {
    if (!details) {
        return undefined;
    }

    let seminars = get(details, "seminars", []);
    seminars &&
        seminars.sort((seminar1, seminar2) => {
            return new Date(seminar1.start_date) < new Date(seminar2.start_date)
                ? -1
                : new Date(seminar1.start_date) > new Date(seminar2.start_date)
                ? 1
                : 0;
        });

    return seminars;
}
