import Global from "../../../../../config/global.css";

const styles = {
    checkbox: {
        checkbox: {
            backgroundColor: "transparent",
            borderRadius: "2px"
        }
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        padding: "0px 24px 24px"
    },
    infineScroll: {
        padding: "4px 0px 24px",
        position: "relative"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row",
        height: "calc(100vh - 278px)",
        marginRight: "4px",
        padding: "0px 20px 0px 24px",
        overflow: "auto"
    },
    searchBarContainer: {
        width: "381px"
    },
    searchBarInput: {
        backgroundColor: Global.colors.white
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "8px minmax(200px, 60%) minmax(200px, 40%) 32px 50px",
        padding: "0px 24px"
    },
    tableGridData: {
        base: "8px minmax(200px, 60%) minmax(200px, 40%)",
        withClassification:
            "8px minmax(200px, 60%) minmax(92px, 10%) minmax(200px, 30%)"
    },
    tableGridActions: {
        base: "32px 50px",
        withExternalLink: "50px 32px"
    },
    tableHeaders: {
        height: "44px",
        padding: "0px 48px"
    },
    topbarContainer: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "1fr auto",
        padding: "0px 24px 24px"
    }
};

export default styles;
