import Global from "../../../../../config/global.css";

const styles = {
    container: {
        backgroundColor: Global.colors.white,
        borderRadius: "12px 12px 0px 0px",
        margin: "0px 24px",
        padding: "24px 24px 16px"
    },
    hide: {
        position: "fixed",
        visibility: "hidden"
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
