import Global from "../../../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        height: "fit-content",
        gap: "8px"
    },
    icon: {
        fontSize: "24px"
    },
    image: {
        border: `1px solid ${Global.colors.lightPaleGrey}`,
        width: "100%"
    },
    innerContainer: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "repeat(auto-fit, minmax(48%, auto))"
    },
    pdfContainer: {
        alignItems: "center",
        color: Global.colors.blueyGrey,
        cursor: "pointer",
        display: "grid",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        gap: "12px",
        gridAutoFlow: "column",
        gridTemplateColumns: "auto 1fr",
        lineHeight: "15px",
        width: "fit-content"
    }
};

export default styles;
