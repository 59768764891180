import Global from "../../../../../config/global.css";

const styles = {
    activeContainer: {
        background: Global.colors.darkSlateBlue,
        color: Global.colors.white
    },
    icon: {
        fontSize: "24px",
        justifySelf: "end"
    },
    inactiveContainer: {
        background: Global.colors.darkPaleGrey,
        color: Global.colors.darkSlateBlue
    },
    roleContainer: {
        borderRadius: "100px",
        color: Global.colors.white,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        height: "24px",
        justifySelf: "end",
        lineHeight: "13px",
        padding: "6px 8px",
        width: "max-content"
    },
    roles: {
        ccadmin: {
            backgroundColor: Global.colors.digitalBlueT0
        },
        ccagent: {
            backgroundColor: Global.colors.digitalBlueT0
        },
        dihkadmin: {
            backgroundColor: Global.colors.palePetrol
        },
        dihkemployee: {
            backgroundColor: Global.colors.palePetrol
        },
        ihkadmin: {
            backgroundColor: Global.colors.peppermint
        },
        ihkagent: {
            backgroundColor: Global.colors.peppermint
        },
        cooppartneradmin: {
            backgroundColor: Global.colors.peppermint
        },
        cooppartneragent: {
            backgroundColor: Global.colors.peppermint
        },
        superuser: {
            backgroundColor: Global.colors.oldMint
        }
    },
    rowBlock: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    },
    rowContainer: {
        gridAutoFlow: "column",
        minHeight: "64px"
    },
    statusContainer: {
        borderRadius: "100px",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        height: "24px",
        lineHeight: "13px",
        padding: "6px 8px",
        width: "fit-content"
    },
    userLogoContainer: {
        alignItems: "center",
        borderRadius: "100px",
        display: "flex",
        height: "40px",
        justifyContent: "center",
        width: "40px"
    },
    userDefaultLogo: {
        background: Global.colors.darkSlateBlue,
        color: Global.colors.white,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "bold",
        justifyContent: "center",
        lineHeight: "23px",
        textTransform: "uppercase"
    }
};

export default styles;
