// Modules
import get from "lodash.get";
// Form
import { stopSubmit } from "redux-form";
// Translation
import translation from "../../../../../config/translation";

/**
 * Checks if passwords fields are valid and trigger form error in case of invalid.
 *
 * @param {Object} values the form values
 * @param {Object} props other props with auxiliar methods / data
 * @returns true if passwords fields are valid, otherwise false
 */
export function arePasswordsFieldsValid(values, props) {
    const { newPassword, newPasswordConfirmation, password } = values;
    const { dispatch } = props;

    if (
        (password || newPassword || newPasswordConfirmation) &&
        (!password || !newPassword || !newPasswordConfirmation)
    ) {
        dispatch(
            stopSubmit("settings", {
                password: password
                    ? undefined
                    : translation.validators.requiredField,
                newPassword: newPassword
                    ? undefined
                    : translation.validators.requiredField,
                newPasswordConfirmation: newPasswordConfirmation
                    ? undefined
                    : translation.validators.requiredField
            })
        );

        return false;
    }

    return true;
}

/**
 * Checks if the new password and new password confirmation fields have the same value.
 *
 * @param {Object} values the form values
 * @returns true if the new password and new password confirmation fields have the same value, false otherwise
 */
export function areNewPasswordsEqual(values) {
    const { newPassword, newPasswordConfirmation } = values;

    const newPasswordsAreEqual = newPassword === newPasswordConfirmation;

    return newPasswordsAreEqual;
}

/**
 * Checks if the password fields are set.
 *
 * @param {Object} values the form values
 * @returns true if there was a change of password fields, false otherwise
 */
export function isPasswordUpdateNeeded(values) {
    const { newPassword, newPasswordConfirmation, password } = values;

    return password && newPassword && newPasswordConfirmation;
}

/**
 * Checks current password and if valid then proceed with the password update.
 *
 * @param {Object} values the form values
 * @param {Object} props other props with auxiliar methods / data
 */
export async function checkAndUpdatePassword(values, props) {
    const { addError, checkCurrentPassword, updatePassword } = props;
    const { newPassword, password } = values;

    await checkCurrentPassword(
        password,
        endSpinner => {
            updatePassword &&
                updatePassword(
                    newPassword,
                    () => {
                        updatePhotoAndName(values, props);
                        endSpinner && endSpinner();
                    },
                    errorMessage => {
                        endSpinner && endSpinner();
                        addError &&
                            addError(errorMessage, [
                                "newPassword",
                                "newPasswordConfirmation"
                            ]);
                    }
                );
        },
        errorMessage => {
            addError && addError(errorMessage, ["password"]);
        }
    );
}

/**
 * Updated the photo and name if there was a change.
 *
 * @param {Object} values the form values
 * @param {Object} props other props with auxiliar methods / data
 */
export function updatePhotoAndName(values, props) {
    const { handleChangeEditMode, removePhoto, updateName, uploadPhoto } =
        props;

    const photoURL = get(values, "photoURL", undefined);
    const initialPhotoURL = get(props, "initialValues.photoURL", undefined);

    const newNameToUpdate = nameToUpdate(values, props);

    const closeEditMode = () => {
        handleChangeEditMode && handleChangeEditMode(false);
    };

    if (initialPhotoURL && !photoURL) {
        removePhoto &&
            removePhoto({
                cb: closeEditMode,
                newNameToUpdate
            });
    } else if (photoURL instanceof File) {
        uploadPhoto &&
            uploadPhoto({
                newPhotoURL: photoURL,
                cb: closeEditMode,
                newNameToUpdate
            });
    } else {
        if (newNameToUpdate && updateName) {
            updateName({
                name: newNameToUpdate,
                cb: closeEditMode
            });
        } else {
            closeEditMode();
        }
    }
}

/**
 * Gets the new name if there was a change in the name.
 *
 * @param {Object} values the form values
 * @param {Object} props other props including form initialValues
 * @returns the new name if there was a change in the name, otherwise undefined
 */
export function nameToUpdate(values, props) {
    const newFirstName = get(values, "firstName", undefined);
    const newLastName = get(values, "lastName", undefined);
    if (
        newFirstName !== get(props, "initialValues.firstName", undefined) ||
        newLastName !== get(props, "initialValues.lastName", undefined)
    ) {
        return `${newFirstName} ${newLastName}`;
    }

    return undefined;
}
