// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { IconButton } from "../../../../../components";
// Actions
import { closeViewPopup } from "../../../../../components/ViewPopup/ViewPopup.ducks";
import { clearOfferDetails } from "../../../Offers/components/OfferDetails/OfferDetails.ducks";
// Style
import styles from "./OfferDetailsHeader.css";

class OfferDetailsHeader extends PureComponent {
    static propTypes = {
        clearOfferDetails: PropTypes.func.isRequired,
        closeViewPopup: PropTypes.func.isRequired,
        offer: PropTypes.object.isRequired,
        viewPopup: PropTypes.object
    };

    componentWillUnmount() {
        const { clearOfferDetails } = this.props;

        clearOfferDetails && clearOfferDetails();
    }

    handleGoBack = () => {
        const { clearOfferDetails, closeViewPopup, offer, viewPopup } =
            this.props;

        closeViewPopup && closeViewPopup();
        clearOfferDetails && clearOfferDetails();

        const loadOffer = get(
            viewPopup,
            "previousState.state.loadOffer",
            undefined
        );
        loadOffer && loadOffer();

        const scrollPosition = get(
            viewPopup,
            "state.scrollPosition",
            undefined
        );
        if (scrollPosition) {
            const element = document.getElementsByClassName(
                scrollPosition.element
            )[0];

            if (element) {
                setTimeout(() => {
                    element.scrollTo(scrollPosition.left, scrollPosition.top);
                }, 2);
                return;
            }
        }

        const offerURL = get(offer, "url", undefined);
        const rowElement = offerURL && document.getElementById(offerURL);

        rowElement &&
            rowElement.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "start"
            });
    };

    render() {
        const { offer } = this.props;

        return (
            <div style={styles.title}>
                <IconButton
                    defaultClassName="icon-arrow-back"
                    onClick={this.handleGoBack}
                    style={styles.backIcon}
                    type={["default"]}
                />
                <span>{get(offer, "title", "")}</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPopup: state.viewPopup
    };
};

const mapDispatchToProps = {
    clearOfferDetails,
    closeViewPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsHeader);
