import Global from "../../../config/global.css";

const styles = {
    button: {
        fontWeight: "600",
        textTransform: "uppercase"
    },
    editCancelButton: {
        background: "transparent",
        color: Global.colors.darkSlateBlue
    },
    saveChangesButtonContainer: {
        marginLeft: "16px"
    }
};

export default styles;
