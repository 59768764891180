import Global from "../../../../../../../../config/global.css";

const styles = {
    active: {
        backgroundColor: Global.colors.blue,
        color: Global.colors.white
    },
    container: {
        alignItems: "center",
        backgroundColor: "transparent",
        borderRadius: "16px",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "grid",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        gap: "8px",
        gridAutoFlow: "column",
        gridTemplateColumns: "24px auto",
        hyphens: "auto",
        MozHyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "15px",
        minHeight: "48px",
        padding: "12px"
    },
    icon: {
        fontSize: "24px"
    },
    iconActive: {
        color: Global.colors.white
    },
    iconContainer: {
        alignItems: "center",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        width: "24px"
    },
    onHover: {
        backgroundColor: Global.colors.digitalBlueT4,
        color: Global.colors.darkSlateBlue
    },
    submenu: {
        chambersLocations: {
            color: Global.colors.digitalBlue
        },
        entitiesMapping: {
            color: Global.colors.darkSlateBlue
        },

        unmappedEntities: {
            color: Global.colors.leo
        },

        entitiesUploads: {
            color: Global.colors.oliveGreen
        },
        offersClassification: {
            color: Global.colors.leo
        },
        offersFileUpload: {
            color: Global.colors.intergalacticPurple
        },
        offersKeywordsTagging: {
            color: Global.colors.summerHeat
        },
        offersOverview: {
            color: Global.colors.pinkology
        }
    }
};

export default styles;
