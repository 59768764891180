// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./InfoMessage.css";

class InfoMessage extends PureComponent {
    static propTypes = {
        endAdornment: PropTypes.node,
        icon: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.oneOf(["default", "*"])
        ]),
        iconStyle: PropTypes.object,
        message: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
            .isRequired,
        style: PropTypes.shape({
            container: PropTypes.object,
            innerContainer: PropTypes.object,
            message: PropTypes.object
        }),
        type: PropTypes.oneOf(["default", "error", "info", "success", "warn"])
    };

    iconComponent = () => {
        const { icon, iconStyle, type } = this.props;

        if (typeof icon === "string") {
            const customIconStyle = {
                ...styles.icon,
                ...get(styles.icon, type, {}),
                ...iconStyle
            };

            return icon === "default" ? (
                <img
                    alt={"icon"}
                    style={{ ...customIconStyle }}
                    src={require(`../../images/icon-${type}.svg`).default}
                />
            ) : (
                <span className={icon} style={{ ...customIconStyle }} />
            );
        } else {
            return icon;
        }
    };

    render() {
        const { endAdornment, icon, message, style, type } = this.props;

        return (
            <div
                className="info-message-container"
                style={{
                    ...styles.container,
                    ...get(styles, `container[${type}]`, {}),
                    ...get(style, "container", {})
                }}
            >
                <div
                    className="info-message-inner-container"
                    style={{
                        ...styles.innerContainer,
                        ...get(style, "innerContainer", {})
                    }}
                >
                    {icon && this.iconComponent()}
                    <span
                        className="info-message-message"
                        style={{
                            ...styles.message,
                            ...get(style, "message", {})
                        }}
                    >
                        {message}
                    </span>
                    {endAdornment && endAdornment}
                </div>
            </div>
        );
    }
}

export default InfoMessage;
