import Global from "../../../../config/global.css";

const styles = {
    container: {
        alignItems: "center",
        borderRadius: "4px",
        boxSizing: "border-box",
        display: "flex",
        fontFamily: "Muli",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        height: "24px",
        justifyContent: "center",
        justifySelf: "center",
        lineHeight: "13px",
        width: "51px"
    },

    status: {
        approved: {
            backgroundColor: Global.colors.lightGreen,
            border: `1px solid ${Global.colors.oliveGreen}`,
            color: Global.colors.oliveGreen
        },
        draft: {
            backgroundColor: Global.colors.lightLeo,
            border: `1px solid ${Global.colors.leo}`,
            color: Global.colors.leo
        }
    }
};

export default styles;
