import Global from "../../config/global.css";

const styles = {
    container: {
        onHover: {
            backgroundColor: Global.colors.paleGrey
        }
    }
};

export default styles;
