import Global from "../../../config/global.css";

const styles = {
    addButtonContainer: {
        container: {
            backgroundColor: Global.colors.blue
        }
    },
    addButtonIcon: {
        color: Global.colors.white,
        fontSize: "24px"
    },
    container: {
        padding: "24px 24px 32px"
    },
    disabledStyle: {
        color: Global.colors.paleGrey
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "16px"
    },
    roleHeader: {
        justifySelf: "end"
    },
    searchBarAndFilterAndAddButtonContainer: {
        display: "grid",
        gridAutoFlow: "column",
        gap: "24px",
        gridTemplateColumns: "1fr auto auto",
        marginBottom: "16px"
    },
    searchBarContainer: {
        width: "381px"
    },
    searchBarInput: {
        backgroundColor: Global.colors.white
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "minmax(50px, 5%) minmax(150px, 15%) minmax(150px, 15%) minmax(250px, 40%) minmax(192px, 15%) minmax(50px, 5%) 32px",
        padding: "0px 24px"
    },
    tableGridMargin: {
        marginTop: "12px"
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    },
    warningPopupImageDeleteUser: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
