const styles = {
    container: {
        alignItems: "center",
        background: "rgba(35 ,54 ,108 ,0.35)",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        left: "0",
        position: "fixed",
        top: "0",
        width: "100vw",
        zIndex: 6
    }
};

export default styles;
