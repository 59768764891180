// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { AudioPlayer } from "./components";
import { DataCard } from "../../../";
// Actions
import { setMedia } from "../../../../OfferDetails.ducks";
// Helpers
import { isValidMediaPath } from "../../../../../../../../../services/storage";
import { extractPodcastData } from "../../../../../../helpers/offerDetailsFormatHelper";
// Translation
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./Podcasts.css";
import { default as formStyles } from "../../DetailsView.css";

function Podcasts({ podcasts, setMedia }) {
    Podcasts.propTypes = {
        podcasts: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                format: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            })
        ),
        setMedia: PropTypes.func.isRequired
    };

    const [state, setState] = useState({});

    useEffect(() => {
        let isSubscribed = true;

        podcasts &&
            podcasts.forEach(async podcast => {
                const { _id, content, format } = podcast;

                if (!format || !content) {
                    return;
                }

                let fileURL = undefined;
                await isValidMediaPath(
                    content,
                    format,
                    podcastURL => {
                        fileURL = podcastURL;
                        isSubscribed &&
                            setState(prevState => ({
                                ...prevState,
                                [_id]: podcastURL
                            }));
                    },
                    "podcasts"
                );

                setMedia(
                    "podcast",
                    extractPodcastData({
                        ...podcast,
                        fileURL
                    })
                );
            });

        return () => {
            isSubscribed = false;
        };
    }, [podcasts, setMedia]);

    const renderPodcast = (podcast, index) => {
        const { _id, content, description, format, title } = podcast;

        if (!format || !content) {
            return null;
        }

        return (
            <div key={index} style={styles.container}>
                {title && <span style={formStyles.title}>{title}</span>}
                {description && (
                    <span style={formStyles.description}>{description}</span>
                )}
                <AudioPlayer audioToRender={state[_id]} track={podcast} />
            </div>
        );
    };

    if (!podcasts || podcasts.length === 0) {
        return null;
    }

    return (
        <DataCard title={translation.offerDetails.details.podcasts}>
            <div style={styles.innerContainer}>
                {podcasts.map((podcast, index) => {
                    return renderPodcast(podcast, index);
                })}
            </div>
        </DataCard>
    );
}

const mapDispatchToProps = {
    setMedia
};

export default connect(null, mapDispatchToProps)(Podcasts);
