import Global from "../../../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        height: "fit-content",
        gap: "8px"
    },
    image: {
        border: `1px solid ${Global.colors.lightPaleGrey}`,
        borderRadius: "8px",
        margin: "auto",
        maxWidth: "269px"
    },
    innerContainer: {
        display: "grid",
        gap: "24px"
    }
};

export default styles;
