const styles = {
    container: {
        display: "grid",
        gap: "18px 24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "62px 62px 62px",
        height: "fit-content"
    },
    price: {
        textAlign: "end"
    },
    priceSymbol: {
        fontSize: "16px",
        fontWeight: "bold"
    },
    textField: {
        width: "100%"
    }
};

export default styles;
