// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./GroupCard.css";

class GroupCard extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        style: PropTypes.object,
        title: PropTypes.string
    };

    render() {
        const { children, style, title } = this.props;

        return (
            <div style={styles.container}>
                {title && <div style={styles.title}>{title}</div>}
                <div style={{ ...styles.innerContainer, ...style }}>
                    {children}
                </div>
            </div>
        );
    }
}

export default GroupCard;
