import Global from "../../../config/global.css";

const styles = {
    addButtonContainer: {
        container: {
            backgroundColor: Global.colors.blue
        }
    },
    addButtonIcon: {
        color: Global.colors.white,
        fontSize: "24px"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "16px 24px 24px"
    },
    cardsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "30px",
        marginTop: "54px"
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        minHeight: "48px"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    }
};

export default styles;
