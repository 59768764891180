const styles = {
    alignRight: {
        justifyContent: "flex-end"
    },
    header: {
        container: {
            alignItems: "unset",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0px",
            paddingLeft: "24px",
            paddingRight: "24px"
        }
    },
    hideContainer: {
        display: "none"
    },
    tableHeaders: {
        gridTemplateColumns:
            "8px minmax(200px, 60%) minmax(92px, 10%) minmax(200px, 30%) 32px 50px"
    }
};

export default styles;
