const styles = {
    circle: {
        cx: "44px",
        cy: "44px",
        fill: "none",
        r: "20px",
        strokeWidth: "4px"
    },
    circleIndeterminate: {
        strokeDasharray: "90px, 200px",
        strokeDashoffset: 0,
        strokeLinecap: "round"
    },
    indeterminate: {
        animation: "none"
    }
};

export default styles;
