// Actions
import {
    endSpinner,
    startSpinner
} from "../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../config/translation";

export const resetPassword = ({ email, successCb, errorCb }) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());

        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();

        await firebaseAuth
            .sendPasswordResetEmail(email)
            .then(function () {
                successCb && successCb();
                dispatch(endSpinner());

                dispatch(
                    openSnackbar(translation.resetPassword.success, "success")
                );
            })
            .catch(e => {
                console.log("Error calling reset password:", e);
                errorCb && errorCb();

                dispatch(endSpinner());
            });
    };
};
