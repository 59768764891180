import Global from "../../../../../../../config/global.css";

const styles = {
    alignRight: {
        justifySelf: "end"
    },
    container: {
        display: "grid",
        gridAutoFlow: "row"
    },
    extraHeader: {
        color: Global.colors.darkSlateBlue,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "24px",
        padding: "21px 24px 16px"
    },
    header: {
        alignItems: "unset",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    icon: {
        fontSize: "24px"
    },
    infineScroll: {
        margin: "0px 16px 4px 24px",
        position: "relative"
    },
    marker: {
        backgroundColor: Global.colors.digitalBlue,
        bottom: 0,
        borderRadius: "8px",
        height: "100%",
        position: "absolute",
        top: 0,
        width: "4px"
    },
    radio: {
        checked: {
            backgroundColor: Global.colors.digitalBlue,
            border: `1px solid ${Global.colors.digitalBlue}`,
            color: Global.colors.white,
            fontSize: "12px"
        },
        checkmark: {
            alignItems: "center",
            border: `1px solid ${Global.colors.darkPaleGrey}`,
            bottom: "calc(50% - 6.5px)",
            height: "12px",
            display: "flex",
            justifyContent: "center",
            top: "unset",
            width: "12px"
        },
        container: {
            fontSize: "12px",
            lineHeight: "15px",
            paddingLeft: "36px"
        },
        input: {
            width: "0px",
            margin: "0px"
        }
    },
    rowContainer: {
        alignItems: "center",
        cursor: "default",
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr auto auto",
        minHeight: "64px",
        padding: "16px 24px"
    },
    rowContainerSelected: {
        backgroundColor: Global.colors.white,
        borderRadius: "8px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        position: "relative"
    },
    rowContainerWithExternalLink: {
        gridTemplateColumns: "1fr auto auto 32px"
    },
    section: {
        background: Global.colors.paleGrey,
        borderRadius: "8px",
        color: Global.colors.blueyGrey,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        height: "24px",
        lineHeight: "13px",
        margin: "0px 16px 0px 24px",
        padding: "6px 24px",
        textTransform: "uppercase"
    },
    sectionMargin: {
        margin: "0px 16px 0px 24px"
    },
    text: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        wordBreak: "break-word"
    }
};

export default styles;
