// Translation
import translation from "./../../config/translation";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../components/MaterialSpinner/MaterialSpinner.ducks";

export const LOGIN_ERROR_ADD = "LOGIN_ERROR_ADD";
export const LOGIN_ERROR_CLEAR = "LOGIN_ERROR_CLEAR";

export const defaultState = {
    errorMessage: undefined,
    errorType: undefined
};

// Reducer
export default (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_ERROR_ADD: {
            return Object.assign({}, state, {
                errorMessage: action.payload.message,
                errorType: action.payload.type
            });
        }
        case LOGIN_ERROR_CLEAR: {
            return Object.assign({}, defaultState);
        }
        default:
            return state;
    }
};

export const login = (username, password, successCb, errorCb) => {
    return async (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();

        dispatch(startSpinner());
        try {
            await firebase.login({
                email: username,
                password: password
            });

            dispatch(loginErrorClear());
            successCb && successCb();
            dispatch(endSpinner());
        } catch (error) {
            let errorMessage;
            if (error.code) {
                errorMessage = translation.errors[error.code];
            } else {
                errorMessage = translation.errors.default;
            }

            switch (error.code) {
                case "auth/wrong-password": {
                    errorCb && errorCb("password", errorMessage);
                    break;
                }
                case "auth/invalid-email": {
                    errorCb && errorCb("email", errorMessage);
                    break;
                }
                case "auth/user-not-found": {
                    dispatch(loginErrorAdd("warn", errorMessage));
                    errorCb && errorCb("user", errorMessage);
                    break;
                }
                case "auth/user-disabled": {
                    dispatch(loginErrorAdd("error", errorMessage));
                    errorCb && errorCb("user", errorMessage);
                    break;
                }
                default:
                    break;
            }

            dispatch(endSpinner());
        }
    };
};

export const loginErrorAdd = (type, message) => {
    return {
        type: LOGIN_ERROR_ADD,
        payload: { type, message }
    };
};

export const loginErrorClear = () => {
    return {
        type: LOGIN_ERROR_CLEAR
    };
};
