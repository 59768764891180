// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { bindActionCreators } from "redux";
// Form
import { change, Field, reduxForm } from "redux-form";
// Components
import {
    InfoMessage,
    LoginSignup,
    MaterialButton,
    MaterialFabButton,
    MaterialSpinner,
    MaterialTextField
} from "../../../../components";
// Translations
import translation from "../../../../config/translation";
// Validators
import { email, required } from "../../../../services/validators";
// Actions
import { clearError, getStartedLogin } from "../../GetStarted.ducks";
import { navigateTo } from "../../../Router/Router.ducks";
// Styles
import styles from "./GetStartedLogin.css";

class GetStartedLogin extends PureComponent {
    static propTypes = {
        clearError: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        from: PropTypes.string,
        getStartedError: PropTypes.object,
        getStartedLogin: PropTypes.func.isRequired,
        handleChangeLoginStep: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        navigateTo: PropTypes.func.isRequired
    };

    state = {
        errorMessages: {},
        passwordVisible: false,
        wrongPasswordCounter: 0
    };

    componentDidMount() {
        const { dispatch, location } = this.props;

        const params = new URLSearchParams(get(location, "search", ""));

        const email = params && params.get("email");
        email && dispatch(change("getStarted", "email", email));
    }

    componentWillUnmount() {
        const { clearError, getStartedError } = this.props;

        get(getStartedError, "errorMessage", undefined) &&
            clearError &&
            clearError();
    }

    onSubmit = values => {
        const {
            clearError,
            getStartedError,
            getStartedLogin,
            handleChangeLoginStep
        } = this.props;

        get(getStartedError, "errorMessage", undefined) &&
            clearError &&
            clearError();

        this.setState({ errorMessages: undefined });

        getStartedLogin &&
            getStartedLogin(
                values.email,
                values.password,
                () => {
                    handleChangeLoginStep && handleChangeLoginStep(false);
                },
                (field, errorMessage) => {
                    const { errorMessages, wrongPasswordCounter } = this.state;
                    const newErrorMessages = {
                        ...errorMessages,
                        [field]: errorMessage
                    };

                    const newWrongPasswordCounter =
                        field === "password" ? wrongPasswordCounter + 1 : 0;

                    this.setState({
                        errorMessages: newErrorMessages,
                        wrongPasswordCounter: newWrongPasswordCounter
                    });
                }
            );
    };

    handlePasswordVisibility = () => {
        const { passwordVisible } = this.state;
        this.setState({ passwordVisible: !passwordVisible });
    };

    handleCloseErrorNotification = () => {
        const { clearError } = this.props;

        clearError && clearError();
    };

    getErrorMessage = field => {
        const { errorMessages } = this.state;
        return get(errorMessages, field, undefined);
    };

    render() {
        const { getStartedError, handleSubmit, navigateTo } = this.props;
        const { passwordVisible, wrongPasswordCounter } = this.state;

        const emailErrorMessage = this.getErrorMessage("email");
        const passwordErrorMessage = this.getErrorMessage("password");

        return (
            <form
                onSubmit={handleSubmit(this.onSubmit)}
                style={styles.container}
            >
                <MaterialSpinner />
                <LoginSignup
                    action={
                        <MaterialFabButton
                            endAdornment={
                                <span
                                    className="icon-arrow-right"
                                    style={styles.proceedIcon}
                                />
                            }
                            key="submit-button"
                            onClick={() => {}}
                            style={styles.proceedButton}
                            type="submit"
                            variant="extended"
                        >
                            {translation.getStarted.proceed}
                        </MaterialFabButton>
                    }
                    errorMessage={
                        getStartedError &&
                        getStartedError.errorType && (
                            <InfoMessage
                                endAdornment={
                                    <span
                                        className="icon-close"
                                        key="info-message-icon"
                                        onClick={
                                            this.handleCloseErrorNotification
                                        }
                                        style={styles.errorMessageCloseIcon}
                                    />
                                }
                                icon={"default"}
                                key="info-message"
                                message={getStartedError.errorMessage}
                                type={getStartedError.errorType}
                            />
                        )
                    }
                    footer={
                        wrongPasswordCounter > 3 ? (
                            <a href="mailto:dihk-support@coach-bot.de">
                                <MaterialButton
                                    customType="link"
                                    disableRipple
                                    key="contact-admin"
                                    onClick={() => {}}
                                >
                                    {translation.login.contactAdmin}
                                </MaterialButton>
                            </a>
                        ) : (
                            <div style={styles.footerContainer}>
                                {translation.getStarted.userHaveAccount}
                                <MaterialButton
                                    customType="link"
                                    disableRipple
                                    key="login"
                                    onClick={() => {
                                        navigateTo("/login");
                                    }}
                                    style={styles.loginButton}
                                >
                                    {translation.getStarted.login}
                                </MaterialButton>
                            </div>
                        )
                    }
                    logo={
                        <img
                            alt={"logo"}
                            style={styles.logo}
                            src={
                                require("../../../../images/logo_DIHK-gGmbH.svg")
                                    .default
                            }
                        />
                    }
                    title={translation.getStarted.getStarted}
                    subTitle={
                        <Fragment>
                            <div>{translation.getStarted.setupAccount}</div>
                            <div>
                                {translation.getStarted.passwordInformation}
                            </div>
                        </Fragment>
                    }
                    style={{
                        childrenContainer: styles.childrenContainer,
                        container: styles.getStartedContainer,
                        contentContainer: styles.contentContainer,
                        logoContainer: styles.logoContainer,
                        subTitleContainer: styles.subtitle,
                        titleContainer: styles.title
                    }}
                >
                    <Fragment>
                        <div style={styles.childrenInnerContainer}>
                            <Field
                                component={MaterialTextField}
                                error={!!emailErrorMessage}
                                errorMessage={emailErrorMessage}
                                label={translation.login.email}
                                name="email"
                                type="email"
                                variant="outlined"
                                validate={[required, email]}
                            />
                            <Field
                                component={MaterialTextField}
                                endAdornment={
                                    <span
                                        className={
                                            passwordVisible
                                                ? "icon-dont-see"
                                                : "icon-see"
                                        }
                                        onClick={this.handlePasswordVisibility}
                                        style={styles.passwordIcon}
                                    />
                                }
                                error={!!passwordErrorMessage}
                                errorMessage={passwordErrorMessage}
                                label={translation.login.password}
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                variant="outlined"
                                validate={required}
                            />
                        </div>
                    </Fragment>
                </LoginSignup>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        getStartedError: state.getStarted
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            { clearError, change, getStartedLogin, navigateTo },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: "getStarted" })(GetStartedLogin));
