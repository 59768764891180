// Modules
import get from "lodash.get";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Cloud Functions
import cloudFunctionApi from "../../../services/cloudFunctionApi";
// Translations
import translation from "../../../config/translation";

export const CLEAR_USERS = "CLEAR_USERS";
export const SAVE_USERS = "SAVE_USERS";

export const defaultState = {
    users: undefined
};

// Reducer
export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_USERS: {
            return Object.assign({}, defaultState);
        }
        case SAVE_USERS: {
            return Object.assign({}, state, {
                users: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearUsers = () => {
    return {
        type: CLEAR_USERS
    };
};

export const saveUsers = users => {
    return {
        type: SAVE_USERS,
        payload: users
    };
};

export const getUsers = (queryFilters, hasFilter = false) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();
        const currentUser = firebaseAuth.currentUser;

        let params = {
            filters: queryFilters || ""
        };

        !hasFilter && (params["uid"] = currentUser.uid);

        try {
            const response = await cloudFunctionApi.get(
                firebase,
                "getAllUsers",
                params
            );

            const users = response.data;
            dispatch(saveUsers(users.filter(user => user.email)));
            dispatch(endSpinner());
        } catch (error) {
            dispatch(clearUsers());
            dispatch(endSpinner());
        }
    };
};

export const deleteUser = (userUid, filters, emailData, cb) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();
        const currentUser = firebaseAuth.currentUser;

        const { filter, hasFilter } = filters;

        try {
            await cloudFunctionApi.delete(firebase, "deleteUser", {
                uid: userUid
            });

            emailData && triggerEmailNotification(firebase, emailData);

            dispatch(getUsers(filter, hasFilter));
            cb && cb();
            dispatch(endSpinner());
            currentUser.uid === userUid && window.location.reload();
        } catch (error) {
            dispatch(getUsers(filter, hasFilter));
            cb && cb();
            dispatch(endSpinner());
        }
    };
};

export const updateUser = (userUid, data, filters, emailData, cb) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();
        const auth = firebase.auth();

        const { filter, hasFilter } = filters;

        try {
            await cloudFunctionApi.put(
                firebase,
                "updateUser",
                {
                    uid: userUid
                },
                data
            );

            emailData && triggerEmailNotification(firebase, emailData);

            const currentUser = auth.currentUser;
            userUid === currentUser.uid && firebase.reloadAuth();

            dispatch(getUsers(filter, hasFilter));
            cb && cb();
            dispatch(endSpinner());
            currentUser.uid === userUid && window.location.reload();
        } catch (error) {
            dispatch(getUsers(filter, hasFilter));
            cb && cb();
            dispatch(endSpinner());
        }
    };
};

export const addUser = (data, filters, emailData, cb) => {
    return async (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();

        const { filter, hasFilter } = filters;

        try {
            await cloudFunctionApi.post(firebase, "addUser", data);

            emailData && triggerEmailNotification(firebase, emailData);

            dispatch(getUsers(filter, hasFilter));
            cb && cb();
            dispatch(endSpinner());
            dispatch(
                openSnackbar(
                    `${translation.users.errors.user} ${data.displayName} ${translation.users.errors.userCreated}`,
                    "success"
                )
            );
        } catch (error) {
            cb && cb();
            dispatch(endSpinner());

            const errorCode = get(error, "response.data.code", undefined);

            let message = undefined;
            let type = undefined;
            if (errorCode === "auth/email-already-exists") {
                message = translation.users.errors.emailAlreadyRegistered;
                type = "error";
            } else {
                message = translation.users.errors.default;
                type = "warn";
            }
            dispatch(openSnackbar(message, type));
        }
    };
};

export const triggerEmailNotification = async (firebase, data) => {
    await cloudFunctionApi.post(
        firebase,
        "triggerEmailNotificationUserManagement",
        data
    );
};
