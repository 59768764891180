// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../../config/translation";

export const CLEAR_LEGAL_ENTITIES = "offersClassification/CLEAR_LEGAL_ENTITIES";
export const CLEAR_OFFERS_CLASSIFICATION = "CLEAR_OFFERS_CLASSIFICATION";
export const CLEAR_OFFERS_CLASSIFICATION_LIST =
    "CLEAR_OFFERS_CLASSIFICATION_LIST";
export const CLEAR_SEARCH_FILTER = "offersClassification/CLEAR_SEARCH_FILTER";
export const CLEAR_SEARCH_QUERY = "offersClassification/CLEAR_SEARCH_QUERY";
export const SAVE_LEGAL_ENTITIES = "offersClassification/SAVE_LEGAL_ENTITIES";
export const SAVE_OFFERS_CLASSIFICATION_LIST =
    "SAVE_OFFERS_CLASSIFICATION_LIST";
export const SAVE_OFFER_SUGGESTIONS =
    "offersClassification/SAVE_OFFER_SUGGESTIONS";
export const SAVE_SEARCH_FILTER = "offersClassification/SAVE_SEARCH_FILTER";
export const SAVE_SEARCH_QUERY = "offersClassification/SAVE_SEARCH_QUERY";
export const SET_OFFERS_CLASSIFICATION_TAB = "SET_OFFERS_CLASSIFICATION_TAB";

export const defaultState = {
    legalEntities: undefined,
    offersByTab: {
        pending: {
            offers: [],
            totalCount: 0
        },
        readyRelease: {
            offers: [],
            totalCount: 0,
            suggestions: []
        },
        unclear: {
            offers: [],
            totalCount: 0
        },
        archived: {
            offers: [],
            totalCount: 0
        },
        approved: {
            offers: [],
            totalCount: 0
        }
    },
    searchFilter: undefined,
    searchQuery: "",
    tab: "pending"
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: defaultState.legalEntities
            });
        }
        case CLEAR_OFFERS_CLASSIFICATION: {
            return Object.assign({}, defaultState);
        }
        case CLEAR_OFFERS_CLASSIFICATION_LIST: {
            return Object.assign({}, state, {
                offersByTab: Object.assign({}, state.offersByTab, {
                    [action.payload]: defaultState.offersByTab[action.payload]
                })
            });
        }
        case CLEAR_SEARCH_FILTER: {
            return Object.assign({}, state, {
                searchFilter: defaultState.searchFilter
            });
        }
        case CLEAR_SEARCH_QUERY: {
            return Object.assign({}, state, {
                searchQuery: defaultState.searchQuery
            });
        }
        case SAVE_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: action.payload
            });
        }
        case SAVE_OFFERS_CLASSIFICATION_LIST: {
            return Object.assign({}, state, {
                offersByTab: Object.assign({}, state.offersByTab, {
                    [action.payload.tab]: {
                        offers:
                            action.payload.startIndex === 0
                                ? [...action.payload.offers]
                                : [
                                      ...state.offersByTab[action.payload.tab]
                                          .offers,
                                      ...action.payload.offers
                                  ],
                        totalCount: action.payload.totalCount,
                        suggestions:
                            state.offersByTab[action.payload.tab].suggestions
                    }
                })
            });
        }
        case SAVE_OFFER_SUGGESTIONS: {
            return Object.assign({}, state, {
                offersByTab: Object.assign({}, state.offersByTab, {
                    [action.payload.tab]: Object.assign(
                        {},
                        state.offersByTab[action.payload.tab],
                        {
                            suggestions: action.payload.offers
                        }
                    )
                })
            });
        }
        case SAVE_SEARCH_FILTER: {
            return Object.assign({}, state, {
                searchFilter: action.payload
            });
        }
        case SAVE_SEARCH_QUERY: {
            return Object.assign({}, state, {
                searchQuery: action.payload
            });
        }
        case SET_OFFERS_CLASSIFICATION_TAB: {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearLegalEntities = () => {
    return {
        type: CLEAR_LEGAL_ENTITIES
    };
};

export const clearOffersClassification = () => {
    return {
        type: CLEAR_OFFERS_CLASSIFICATION
    };
};

export const clearOffersClassificationList = tab => {
    return {
        type: CLEAR_OFFERS_CLASSIFICATION_LIST,
        payload: tab
    };
};

export const clearSearchFilter = () => {
    return {
        type: CLEAR_SEARCH_FILTER
    };
};

export const clearSearchQuery = () => {
    return {
        type: CLEAR_SEARCH_QUERY
    };
};

export const saveLegalEntities = legalEntities => {
    return {
        type: SAVE_LEGAL_ENTITIES,
        payload: legalEntities
    };
};

export const saveOffersClassificationList = (
    tab,
    offers,
    startIndex,
    totalCount
) => {
    return {
        type: SAVE_OFFERS_CLASSIFICATION_LIST,
        payload: { tab, offers, startIndex, totalCount }
    };
};

export const saveOfferSuggestions = (tab, offers) => {
    return {
        type: SAVE_OFFER_SUGGESTIONS,
        payload: { tab, offers }
    };
};

export const saveSearchFilter = filter => {
    return {
        type: SAVE_SEARCH_FILTER,
        payload: filter
    };
};

export const saveSearchQuery = query => {
    return {
        type: SAVE_SEARCH_QUERY,
        payload: query
    };
};

export const setOffersClassificationTab = tab => {
    return {
        type: SET_OFFERS_CLASSIFICATION_TAB,
        payload: tab
    };
};

export const getOfferSuggestions =
    ({ tab, offerId, classification }) =>
    dispatch => {
        dispatch(saveOfferSuggestions(tab, []));
        dispatch(startSpinner());

        const params = {
            classification
        };

        api.get(`offers/${offerId}/suggestions`, params)
            .then(response => {
                dispatch(saveOfferSuggestions(tab, get(response, "data", [])));
                dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOfferSuggestions: ", e);
                dispatch(endSpinner());
            });
    };

const statusByTab = {
    pending: "pending",
    unclear: "unclear",
    readyRelease: "ready_release",
    archived: "archived",
    approved: "approved"
};

export const getOffersByTab =
    ({
        tab,
        title,
        entity_id = undefined,
        classification = undefined,
        limit = 50,
        startIndex,
        successCb = undefined,
        errorCb = undefined
    }) =>
    dispatch => {
        const status = get(statusByTab, tab, undefined);
        if (!tab || !status) {
            errorCb && errorCb();
            return;
        }

        const displaySpinner = startIndex === 0;
        const offersStatus = status ? `['${status}']` : undefined;

        displaySpinner && dispatch(startSpinner());

        const params = {
            title: title || "",
            entity_id,
            classification,
            limit,
            start_index: startIndex,
            status: offersStatus
        };

        api.get("offers", params)
            .then(response => {
                dispatch(
                    saveOffersClassificationList(
                        tab,
                        get(response, "data.results", []),
                        startIndex,
                        get(response, "data.total_count", 0)
                    )
                );

                successCb && successCb();
                displaySpinner && dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOffersByTab: ", e);
                errorCb && errorCb();
                displaySpinner && dispatch(endSpinner());
            });
    };

export const updateOfferStatus =
    ({ offerId, status, successCb = undefined, errorCb = undefined }) =>
    dispatch => {
        dispatch(startSpinner());

        const data = {
            status
        };

        api.put(`offers/${offerId}/status`, data)
            .then(() => {
                dispatch(endSpinner());
                successCb &&
                    successCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            })
            .catch(e => {
                console.log("Error calling updateOfferStatus: ", e);
                dispatch(endSpinner());
                errorCb &&
                    errorCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            });
    };

export const updateOffersStatusBulk =
    ({ offersIds, status, successCb = undefined, errorCb = undefined }) =>
    dispatch => {
        dispatch(startSpinner());

        const data = {
            offers_ids: offersIds,
            status
        };

        api.put("offers/status", data)
            .then(() => {
                dispatch(endSpinner());
                successCb &&
                    successCb(cb => {
                        dispatch(refreshOffersByTab(cb));
                    });
                dispatch(
                    openSnackbar(
                        translation.offersClassification
                            .successBulkClassification,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log("Error calling updateOffersStatusBulk: ", e);
                dispatch(endSpinner());
                errorCb &&
                    errorCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            });
    };

export const updateOfferClassification =
    ({ offerId, classification, successCb = undefined, errorCb = undefined }) =>
    dispatch => {
        dispatch(startSpinner());

        const data = {
            classification
        };

        api.put(`offers/${offerId}/classification`, data)
            .then(() => {
                dispatch(endSpinner());
                successCb &&
                    successCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            })
            .catch(e => {
                console.log("Error calling updateOfferClassification: ", e);
                dispatch(endSpinner());
                errorCb &&
                    errorCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            });
    };

export const updateOffersClassificationBulk =
    ({
        offersIds,
        classification,
        successCb = undefined,
        errorCb = undefined
    }) =>
    dispatch => {
        dispatch(startSpinner());

        const data = {
            offers_ids: offersIds,
            classification
        };

        api.put("offers/classification", data)
            .then(() => {
                dispatch(endSpinner());
                successCb &&
                    successCb(cb => {
                        dispatch(refreshOffersByTab(cb));
                    });

                dispatch(
                    openSnackbar(
                        translation.offersClassification
                            .successBulkClassification,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log(
                    "Error calling updateOffersClassificationBulk: ",
                    e
                );
                dispatch(endSpinner());
                errorCb &&
                    errorCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            });
    };

export const mergeOffers =
    ({ offerId, mergeToOfferId, successCb = undefined, errorCb = undefined }) =>
    dispatch => {
        dispatch(startSpinner());

        const data = {
            merge_to_offer_id: mergeToOfferId
        };

        api.put(`offers/${offerId}/merge`, data)
            .then(() => {
                dispatch(endSpinner());
                successCb &&
                    successCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            })
            .catch(e => {
                console.log("Error calling mergeOffers: ", e);
                dispatch(endSpinner());
                errorCb &&
                    errorCb(() => {
                        dispatch(refreshOffersByTab());
                    });
            });
    };

export const refreshOffersByTab =
    (successCb = undefined) =>
    (dispatch, getState) => {
        const {
            offersClassification: {
                offersByTab,
                searchFilter,
                searchQuery,
                tab
            }
        } = getState();

        const tabOffersData = get(offersByTab, tab, {});
        const tabOffersList = get(tabOffersData, "offers", []);

        dispatch(startSpinner());
        dispatch(
            getOffersByTab({
                title: searchQuery,
                tab,
                classification: get(searchFilter, "classification", undefined),
                entity_id: get(searchFilter, "legalEntity.value", undefined),
                limit: tabOffersList.length || 50,
                startIndex: 0,
                successCb: () => {
                    successCb && successCb();
                    dispatch(endSpinner());
                },
                errorCb: () => {
                    dispatch(endSpinner());
                }
            })
        );
    };

export const getLegalEntities = () => (dispatch, getState) => {
    const {
        offersClassification: { tab }
    } = getState();
    dispatch(clearLegalEntities());

    const status = get(statusByTab, tab, undefined);

    const params = {
        status
    };

    api.get("offers/legalentities", params)
        .then(response => {
            dispatch(saveLegalEntities(get(response, "data", [])));
        })
        .catch(e => {
            console.log("Error calling getLegalEntities: ", e);
        });
};
