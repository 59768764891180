// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { change, Field, formValueSelector, reduxForm } from "redux-form";
// Components
import {
    CustomSnackBar,
    InfoMessage,
    LoginSignup,
    MaterialButton,
    MaterialFabButton,
    MaterialSpinner,
    MaterialTextField
} from "../../components";
// Translations
import translation from "../../config/translation";
// Validators
import { email, required } from "../../services/validators";
// Actions
import { login, loginErrorClear } from "./Login.ducks";
import { navigateTo } from "../Router/Router.ducks";
// Helpers
import { generalContact } from "../../config/generalContact";
// Styles
import styles from "./Login.css";

class Login extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        from: PropTypes.string,
        handleSubmit: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        loginError: PropTypes.object,
        loginErrorClear: PropTypes.func.isRequired,
        navigateTo: PropTypes.func.isRequired
    };

    state = {
        errorMessages: {},
        passwordVisible: false,
        wrongPasswordCounter: 0
    };

    componentDidMount() {
        const { dispatch, location } = this.props;

        const params = new URLSearchParams(get(location, "search", ""));

        const email = params && params.get("email");
        email && dispatch(change("login", "email", email));
    }

    componentWillUnmount() {
        const { loginError, loginErrorClear } = this.props;

        get(loginError, "errorMessage", undefined) &&
            loginErrorClear &&
            loginErrorClear();
    }

    onSubmit = values => {
        const { from, login, loginError, loginErrorClear, navigateTo } =
            this.props;

        get(loginError, "errorMessage", undefined) &&
            loginErrorClear &&
            loginErrorClear();

        this.setState({ errorMessages: undefined });

        login &&
            login(
                values.email,
                values.newPassword,
                () => {
                    navigateTo(from);
                },
                (field, errorMessage) => {
                    const { errorMessages, wrongPasswordCounter } = this.state;
                    const newErrorMessages = {
                        ...errorMessages,
                        [field]: errorMessage
                    };

                    const newWrongPasswordCounter =
                        field === "password" ? wrongPasswordCounter + 1 : 0;

                    this.setState({
                        errorMessages: newErrorMessages,
                        wrongPasswordCounter: newWrongPasswordCounter
                    });
                }
            );
    };

    handlePasswordVisibility = () => {
        const { passwordVisible } = this.state;
        this.setState({ passwordVisible: !passwordVisible });
    };

    handleCloseErrorNotification = () => {
        const { loginErrorClear } = this.props;

        loginErrorClear && loginErrorClear();
    };

    getErrorMessage = field => {
        const { errorMessages } = this.state;
        return get(errorMessages, field, undefined);
    };

    render() {
        const { handleSubmit, location, loginError, navigateTo } = this.props;
        const { passwordVisible, wrongPasswordCounter } = this.state;

        const emailErrorMessage = this.getErrorMessage("email");
        const passwordErrorMessage = this.getErrorMessage("password");

        const backFromGetStarted = get(location, "state.pathname", "").includes(
            "get_started"
        );

        const phone = get(generalContact, "phone", undefined);
        const emailContact = get(generalContact, "email", undefined);

        return (
            <form
                onSubmit={handleSubmit(this.onSubmit)}
                style={styles.container}
            >
                <MaterialSpinner />
                <CustomSnackBar />
                <LoginSignup
                    action={
                        <MaterialFabButton
                            endAdornment={
                                <span
                                    className="icon-arrow-right"
                                    style={styles.loginIcon}
                                />
                            }
                            key="submit-button"
                            onClick={() => {}}
                            style={styles.loginButton}
                            type="submit"
                            variant="extended"
                        >
                            {translation.login.login}
                        </MaterialFabButton>
                    }
                    errorMessage={
                        loginError &&
                        loginError.errorType && (
                            <InfoMessage
                                endAdornment={
                                    <span
                                        className="icon-close"
                                        key="info-message-icon"
                                        onClick={
                                            this.handleCloseErrorNotification
                                        }
                                        style={styles.errorMessageCloseIcon}
                                    />
                                }
                                icon={"default"}
                                key="info-message"
                                message={loginError.errorMessage}
                                type={loginError.errorType}
                            />
                        )
                    }
                    footer={
                        wrongPasswordCounter > 3 && (
                            <a href="mailto:dihk-support@coach-bot.de">
                                <MaterialButton
                                    customType="link"
                                    disableRipple
                                    key="contact-admin"
                                    onClick={() => {}}
                                >
                                    {translation.login.contactAdmin}
                                </MaterialButton>
                            </a>
                        )
                    }
                    logo={
                        <img
                            alt={"logo"}
                            style={styles.logo}
                            src={
                                require("../../images/logo_DIHK-gGmbH.svg")
                                    .default
                            }
                        />
                    }
                    title={translation.login.login}
                    subTitle={
                        <Fragment>
                            <div>
                                {translation.login.welcomeTo}
                                <span style={styles.subtitleBold}>
                                    {` ${translation.login.backoffice}. `}
                                </span>
                            </div>
                            <div>{translation.login.pleaseLogin}</div>
                            <div style={styles.contactInfo}>
                                {translation.login.contactInfo}
                                <br />
                                <a href={`mailto:${emailContact}`}>
                                    {emailContact}
                                </a>
                                <br />
                                {translation.login.or}
                                <br />
                                <a href={`tel:${phone}`}>{phone}</a>
                                <br />
                                {translation.login.contactInfo2}
                            </div>
                        </Fragment>
                    }
                    style={{
                        childrenContainer: styles.childrenContainer,
                        container: styles.loginContainer,
                        contentContainer: styles.contentContainer,
                        logoContainer: styles.logoContainer,
                        subTitleContainer: styles.subtitle,
                        titleContainer: styles.title
                    }}
                >
                    <Fragment>
                        <div style={styles.childrenInnerContainer}>
                            <Field
                                autoComplete={
                                    backFromGetStarted ? "off" : undefined
                                }
                                component={MaterialTextField}
                                error={!!emailErrorMessage}
                                errorMessage={emailErrorMessage}
                                label={translation.login.email}
                                name="email"
                                type="email"
                                variant="outlined"
                                validate={[required, email]}
                            />
                            <Field
                                autoComplete={
                                    backFromGetStarted
                                        ? "new-password"
                                        : undefined
                                }
                                component={MaterialTextField}
                                endAdornment={
                                    <span
                                        className={
                                            passwordVisible
                                                ? "icon-dont-see"
                                                : "icon-see"
                                        }
                                        onClick={this.handlePasswordVisibility}
                                        style={styles.passwordIcon}
                                    />
                                }
                                error={!!passwordErrorMessage}
                                errorMessage={passwordErrorMessage}
                                label={translation.login.password}
                                name="newPassword"
                                type={passwordVisible ? "text" : "password"}
                                variant="outlined"
                                validate={required}
                            />
                        </div>
                        <MaterialButton
                            containerStyle={
                                styles.passwordForgottenButton.container
                            }
                            customType="link"
                            disableRipple
                            onClick={() => {
                                const email = get(
                                    this.props,
                                    "emailFieldValue",
                                    undefined
                                );
                                navigateTo &&
                                    navigateTo(
                                        `/reset-password${
                                            email ? `?email=${email}` : ""
                                        }`
                                    );
                            }}
                            style={styles.passwordForgottenButton}
                        >
                            {translation.login.forgotPassword}
                        </MaterialButton>
                    </Fragment>
                </LoginSignup>
            </form>
        );
    }
}

const selector = formValueSelector("login");

const mapStateToProps = state => {
    return {
        emailFieldValue: selector(state, "email"),
        loginError: state.login
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ login, loginErrorClear, navigateTo }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: "login" })(Login));
