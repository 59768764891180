// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./TableHeaders.css";

class TableHeaders extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object,
        headers: PropTypes.array
    };

    renderTitle = (header, index) => {
        const headerTitle = get(header, "label", undefined) || header;
        const headerStyle = get(header, "style", {});

        return (
            <div
                key={index}
                style={{ ...styles.titleContainer, ...headerStyle }}
            >
                {headerTitle}
            </div>
        );
    };

    render() {
        const { containerStyle, headers } = this.props;
        return (
            <div style={{ ...styles.container, ...containerStyle }}>
                {headers.map(this.renderTitle)}
            </div>
        );
    }
}

export default TableHeaders;
