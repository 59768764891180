// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { TableRow } from "../../../../../components";
// Styles
import styles from "./EntityUploadTrackingRow.css";

class EntityUploadTrackingRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        index: PropTypes.number,
        entry: PropTypes.object.isRequired
    };

    getSuppliers = row => {
        const sortByName = (a, b) => a["name"].localeCompare(b["name"]);

        const suppliers = get(row, "suppliers", []);

        return suppliers && suppliers.sort(sortByName);
    };

    renderRow = row => {
        const suppliers = this.getSuppliers(row);

        return (
            <Fragment>
                <div style={styles.text}>{get(row, "name", undefined)}</div>
                <div style={styles.suppliers.container}>
                    {suppliers && suppliers.length > 0 ? (
                        suppliers.map((supplier, index) => {
                            const status = get(supplier, "status", "red");

                            return (
                                <div
                                    key={index}
                                    style={styles.suppliers.innerContainer}
                                >
                                    <div style={styles.suppliers.supplier}>
                                        {get(supplier, "name", "")}
                                    </div>
                                    <div
                                        style={{
                                            ...styles.text,
                                            ...styles.suppliers.container,
                                            ...styles.suppliers.alignRight
                                        }}
                                    >
                                        {get(supplier, "lastUploadDate", "-")}
                                    </div>
                                    <div
                                        style={{
                                            ...styles.suppliers.status
                                                .container,
                                            ...styles.suppliers.status[status]
                                        }}
                                    >
                                        <span
                                            className={
                                                status === "green"
                                                    ? "icon-arrow-long-up"
                                                    : "icon-arrow-long-down"
                                            }
                                            style={styles.suppliers.status.icon}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div />
                    )}
                </div>
            </Fragment>
        );
    };

    render() {
        const { containerStyle, entry } = this.props;

        return (
            <TableRow
                containerStyle={{
                    ...containerStyle,
                    ...styles.rowContainer
                }}
            >
                {this.renderRow(entry)}
            </TableRow>
        );
    }
}

export default EntityUploadTrackingRow;
