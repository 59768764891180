// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash.get";
// Components
import { IconButton } from "../";
// Translations
import translation from "../../config/translation";
// Styles
import styles from "./DetailsButton.css";

class DetailsButton extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        style: PropTypes.object
    };

    render() {
        const { classes, onClick, style } = this.props;

        return (
            <IconButton
                defaultClassName="details"
                onClick={onClick}
                style={{ ...styles.container, ...get(style, "container", {}) }}
                type={["default"]}
                TouchRippleProps={{
                    classes: {
                        ripple: classNames(classes.iconRipple)
                    }
                }}
            >
                <span className="blur-on-click" style={styles.label}>
                    {translation.labels.details}
                </span>
            </IconButton>
        );
    }
}

export default withStyles(styles)(DetailsButton);
