// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CustomRadioButton,
    CustomTooltip,
    MaterialButton
} from "../../../../../components/";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./ClassificationAction.css";

function ClassificationAction({
    expandableActions,
    handleSelection,
    offer,
    selection
}) {
    if (!expandableActions) {
        return null;
    }

    const actionDisabled = !selection;

    return (
        <div style={styles.container}>
            <div style={styles.actions.container}>
                <span style={styles.actions.label}>
                    {`${get(expandableActions, "label", "")}:`}
                </span>
                <div style={styles.actions.innerContainer}>
                    <div style={styles.actions.optionsContainer}>
                        {get(expandableActions, "options", []).map(
                            (option, index) => {
                                const key = get(option, "key", undefined);
                                const isChecked =
                                    get(selection, "key", undefined) === key;

                                return (
                                    <div
                                        key={index}
                                        style={styles.actions.optionContainer}
                                    >
                                        <CustomRadioButton
                                            checked={isChecked}
                                            checkmarkDefaultClassName={
                                                isChecked ? "icon-check" : null
                                            }
                                            defaultClassName={
                                                "offer-classification-radio-button"
                                            }
                                            label={
                                                translation.offersClassification
                                                    .classification[key].label
                                            }
                                            onChange={() =>
                                                handleSelection(option)
                                            }
                                            style={styles.actions.radio}
                                        />
                                        <CustomTooltip
                                            placement="right-end"
                                            title={
                                                translation.offersClassification
                                                    .classification[key]
                                                    .description
                                            }
                                        >
                                            <span
                                                className={"icon-info-file"}
                                                style={
                                                    styles.actions.tooltip.icon
                                                }
                                            />
                                        </CustomTooltip>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <MaterialButton
                        containerStyle={styles.actions.button.container}
                        customType="boldPrimary"
                        disabled={actionDisabled}
                        onClick={() => {
                            const action = get(selection, "action", undefined);

                            action && action(offer);
                        }}
                        style={{
                            ...styles.actions.button.default,
                            ...(actionDisabled
                                ? styles.actions.button.disabled
                                : {})
                        }}
                    >
                        {translation.offersClassification.submit}
                    </MaterialButton>
                </div>
            </div>
        </div>
    );
}

ClassificationAction.propTypes = {
    expandableActions: PropTypes.object.isRequired,
    handleSelection: PropTypes.func,
    offer: PropTypes.object,
    selection: PropTypes.object
};

export default ClassificationAction;
