// Modules
import axios from "axios";

async function callFunction(action) {
    const { firebase, method, path, data, params } = action;

    const idToken = await firebase.auth().currentUser.getIdToken();

    const options = {
        mode: "cors",
        headers: {
            Authorization: idToken,
            "Content-Type": "application/json"
        }
    };

    return await axios({
        method,
        baseURL: cloudFunctionApi.url(),
        url: path,
        headers: options.headers,
        params: params ? params : {},
        data: data,
        withCredentials: true
    });
}

const cloudFunctionApi = {
    delete: (firebase, path, params) => {
        return callFunction({ firebase, method: "delete", path, params });
    },
    get: (firebase, path, params) => {
        return callFunction({ firebase, method: "get", path, params });
    },
    post: (firebase, path, data) => {
        return callFunction({ firebase, method: "post", path, data });
    },
    put: (firebase, path, params, data) => {
        return callFunction({ firebase, method: "put", path, params, data });
    },
    url: () => {
        return process.env.REACT_APP_CF;
    }
};

export default cloudFunctionApi;
