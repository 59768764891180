// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";
// Components
import { GetStartedLogin, UpdatePassword, LegalAgreement } from "./components";

class GetStarted extends PureComponent {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    };

    state = {
        isNewUser: get(this.props, "isNewUser", undefined),
        hasAcceptedLegalAgreement: get(
            this.props,
            "hasAcceptedAgreement",
            undefined
        )
    };

    handleChangeLoginStep = loginStep => {
        this.setState({
            loginStep
        });
    };

    render() {
        const { isNewUser, hasAcceptedLegalAgreement } = this.state;
        return isNewUser ? (
            <UpdatePassword {...this.props} />
        ) : hasAcceptedLegalAgreement ? (
            <GetStartedLogin
                handleChangeLoginStep={this.handleChangeLoginStep}
                {...this.props}
            />
        ) : (
            <LegalAgreement {...this.props} />
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth
    };
};

export default connect(mapStateToProps)(GetStarted);
