import Global from "../../../../../../../../../config/global.css";

const styles = {
    addButton: {
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "16px",
        justifySelf: "flex-end",
        width: "fit-content",
        onHover: {
            backgroundColor: "unset",
            color: `${Global.colors.blue} !important`
        }
    },
    addButtonLabel: {
        fontFamily: "Muli",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginLeft: "10px"
    },
    container: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "fit-content"
    },
    deleteButton: {
        alignSelf: "center",
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        height: "24px",
        justifySelf: "end",
        width: "24px"
    },
    disabled: {
        pointerEvents: "none"
    },
    innerContainer: {
        display: "grid",
        gap: "18px",
        gridTemplateRows: "62px",
        height: "fit-content",
        paddingTop: "12px"
    },
    mainContainer: {
        display: "grid",
        gap: "12px",
        height: "fit-content"
    },
    textField: {
        width: "100%"
    },
    uploadInput: {
        display: "none"
    }
};

export default styles;
