import Global from "../../../../../config/global.css";

const styles = {
    actionButtonsContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    closeIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    },
    container: {
        display: "grid",
        gridAutoFlow: "row",
        gap: "32px",
        gridTemplateRows: "auto 48px",
        padding: "24px 24px 32px"
    },
    cursor: {
        cursor: "pointer"
    },
    field: {
        width: "100%",

        focusedInput: {
            backgroundColor: Global.colors.white,
            borderRadius: "10px"
        }
    },
    infoMessage: {
        maxWidth: "unset"
    },
    legalContainer: {
        container: {
            display: "grid",
            height: "fit-content",
            gap: "16px"
        },
        linkButton: {
            root: {
                color: Global.colors.darkSlateBlue,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "18px"
            },
            active: {
                color: Global.colors.darkSlateBlue
            },
            onHover: {
                color: Global.colors.darkSlateBlue
            }
        },
        linkButtonOnHover: {
            onHover: {
                color: Global.colors.blue
            }
        }
    },
    logo: {
        borderRadius: "100px",
        display: "flex",
        height: "120px",
        position: "relative",
        width: "120px"
    },
    logoDefault: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px",
        placeSelf: "center",
        position: "absolute",
        textAlign: "center"
    },
    logoDefaultContainer: {
        background: Global.colors.paleGrey,
        position: "unset"
    },
    logoDefaultEdit: {
        fontWeight: "bold",
        textShadow: `-1px 0px ${Global.colors.white}, 0px 1px ${Global.colors.white}, 1px 0px ${Global.colors.white}, 0px -1px ${Global.colors.white}`
    },
    logoDefaultEditContainer: {
        background: Global.colors.darkPaleGrey
    },
    logoImage: {
        cursor: "inherit",
        opacity: "0.3"
    },
    logoInnerContainer: {
        padding: "12px"
    },
    logoRemoveButton: {
        color: Global.colors.darkSlateBlue,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px"
    },
    logoRemoveButtonContainer: {
        display: "inline-flex"
    },
    profileInformation: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "min-content auto"
    },
    settingsContainer: {
        display: "grid",
        height: "fit-content",
        gap: "24px"
    },
    settingsNameContainer: {
        display: "grid",
        height: "fit-content",
        gap: "16px",
        gridAutoRows: "62px"
    },
    settingsPasswordsContainer: {
        display: "grid",
        gap: "16px",
        gridAutoRows: "62px"
    },
    settingsInnerContainer: {
        display: "grid",
        gap: "16px",
        gridTemplateColumns: "minmax(50%, 440px)"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px"
    },
    uploadLogoInput: {
        display: "none"
    }
};

export default styles;
