// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FormHelperText } from "@material-ui/core";
// Styles
import styles from "./CustomCreatableSelect.css";

class CustomCreatableSelect extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object,
        controlStyle: PropTypes.object,
        customComponents: PropTypes.shape({
            ClearIndicator: PropTypes.func,
            DropdownIndicator: PropTypes.func,
            IndicatorSeparator: PropTypes.func,
            MultiValueRemove: PropTypes.func
        }),
        defaultOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        disabled: PropTypes.bool,
        disabledStyle: PropTypes.object,
        editable: PropTypes.bool,
        error: PropTypes.bool,
        errorMessage: PropTypes.string,
        errorMessageStyle: PropTypes.object,
        input: PropTypes.object,
        inputStyle: PropTypes.object,
        isClearable: PropTypes.bool,
        isMulti: PropTypes.bool,
        menuListStyle: PropTypes.object,
        menuStyle: PropTypes.object,
        meta: PropTypes.object,
        multiValueLabelStyle: PropTypes.object,
        multiValueRemoveStyle: PropTypes.object,
        multiValueStyle: PropTypes.object,
        noOptionsMessage: PropTypes.string,
        onBlur: PropTypes.func,
        onMenuClose: PropTypes.func,
        onSelection: PropTypes.func,
        optionFocusedStyle: PropTypes.object,
        options: PropTypes.array,
        optionSelectedStyle: PropTypes.object,
        optionStyle: PropTypes.object,
        outerContainerStyle: PropTypes.object,
        placeholder: PropTypes.string,
        singleValueStyle: PropTypes.object,
        valueContainerStyle: PropTypes.object
    };

    componentDidMount() {
        const { defaultOption, input } = this.props;
        input && defaultOption && input.onChange(defaultOption);
    }

    ClearIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;
        return (
            <div {...restInnerProps} style={styles.iconContainer} ref={ref}>
                <span className="icon-close" style={styles.clearIcon} />
            </div>
        );
    };

    MultiValueRemove = props => {
        return (
            <components.MultiValueRemove {...props}>
                <span
                    className="icon-close"
                    style={styles.iconRemoveContainer}
                />
            </components.MultiValueRemove>
        );
    };

    render() {
        const {
            containerStyle,
            controlStyle,
            customComponents,
            defaultOption,
            disabled,
            disabledStyle,
            editable,
            error,
            errorMessage,
            errorMessageStyle,
            input,
            inputStyle,
            isClearable,
            isMulti,
            menuListStyle,
            menuStyle,
            meta,
            multiValueLabelStyle,
            multiValueRemoveStyle,
            multiValueStyle,
            noOptionsMessage,
            onBlur,
            onMenuClose,
            onSelection,
            optionFocusedStyle,
            options,
            optionSelectedStyle,
            optionStyle,
            outerContainerStyle,
            placeholder,
            singleValueStyle,
            valueContainerStyle,
            ...props
        } = this.props;

        const valueContainer = valueContainerStyle ? valueContainerStyle : null;

        const hasError = error || Boolean(meta && meta.touched && meta.error);
        const helperTextErrorMessage =
            errorMessage || (meta && meta.touched && meta.error);

        return (
            <div
                key={`custom-creatable-select-outer-container-${props.keyLabel}`}
                style={outerContainerStyle}
            >
                <CreatableSelect
                    components={{
                        ClearIndicator: get(
                            customComponents,
                            "ClearIndicator",
                            this.ClearIndicator
                        ),
                        DropdownIndicator: get(
                            customComponents,
                            "DropdownIndicator",
                            null
                        ),
                        IndicatorSeparator: get(
                            customComponents,
                            "IndicatorSeparator",
                            null
                        ),
                        MultiValueRemove: get(
                            customComponents,
                            "MultiValueRemove",
                            this.MultiValueRemove
                        )
                    }}
                    defaultValue={defaultOption}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    key={`custom-creatable-select-${props.keyLabel}`}
                    noOptionsMessage={() => noOptionsMessage}
                    onBlur={() => {
                        onBlur && onBlur();
                    }}
                    onChange={e => {
                        onSelection && onSelection(e);
                        input && input.onChange(e);
                    }}
                    onMenuClose={() => {
                        onMenuClose && onMenuClose();
                    }}
                    options={options}
                    placeholder={placeholder}
                    styles={{
                        container: base => ({
                            ...base,
                            ...containerStyle
                        }),
                        control: (base, data) => ({
                            ...base,
                            ...styles.selectContainer,
                            ...(data ? styles.selectContainerMargin : {}),
                            ...(data.isMulti
                                ? styles.selectContainerMulti
                                : {}),
                            ...(disabled ? styles.disabledContainer : {}),
                            ...(hasError ? styles.errorContainer : {}),
                            ...controlStyle,
                            ...(disabled
                                ? {
                                      ...styles.disabledContainer,
                                      ...disabledStyle
                                  }
                                : {})
                        }),
                        input: base => ({
                            ...base,
                            ...styles.input,
                            ...inputStyle
                        }),
                        menu: base => ({
                            ...base,
                            ...(options ? styles.menu : styles.menuDisabled),
                            ...menuStyle
                        }),
                        menuList: base => ({
                            ...base,
                            ...styles.menuList,
                            ...menuListStyle
                        }),
                        multiValue: base => ({
                            ...base,
                            ...styles.multiValue,
                            ...multiValueStyle
                        }),
                        multiValueLabel: base => ({
                            ...base,
                            ...styles.multiValueLabel,
                            ...multiValueLabelStyle
                        }),
                        multiValueRemove: base => ({
                            ...base,
                            ...styles.multiValueRemove,
                            ...multiValueRemoveStyle,
                            ...(!(!disabled && editable)
                                ? styles.multiValueRemoveHide
                                : {})
                        }),
                        noOptionsMessage: base => ({
                            ...base,
                            ...styles.noOptionsMessage
                        }),
                        option: (base, data) => ({
                            ...base,
                            ...styles.option,
                            ...optionStyle,
                            ...(data.isSelected
                                ? {
                                      ...styles.optionSelected,
                                      ...optionSelectedStyle
                                  }
                                : {}),
                            ...(data.isFocused
                                ? {
                                      ...styles.optionFocused,
                                      ...optionFocusedStyle
                                  }
                                : {})
                        }),
                        placeholder: base => ({
                            ...base,
                            ...styles.placeholder
                        }),
                        singleValue: base => ({
                            ...base,
                            ...singleValueStyle
                        }),
                        valueContainer: base => ({
                            ...base,
                            ...styles.valueContainer,
                            ...valueContainer
                        })
                    }}
                    {...props}
                />
                {hasError && helperTextErrorMessage && (
                    <FormHelperText
                        error
                        id="ccs-error-message"
                        key={`css-helper-text-${props.keyLabel}`}
                        style={{ ...styles.errorMessage, ...errorMessageStyle }}
                    >
                        {helperTextErrorMessage}
                    </FormHelperText>
                )}
            </div>
        );
    }
}

export default CustomCreatableSelect;
