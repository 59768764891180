import Global from "../../config/global.css";

const styles = {
    body: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 210px)",
        overflowY: "auto"
    },
    buttonsContainer: {
        alignItems: "flex-end",
        display: "grid",
        flex: 1,
        gridTemplateColumns: "auto auto",
        gap: "16px",
        padding: "24px"
    },
    cancelButton: {
        background: Global.colors.white,
        color: Global.colors.darkSlateBlue,
        fontWeight: "600",
        textTransform: "uppercase"
    },
    cancelButtonContainer: {
        marginRight: "16px"
    },
    confirmButton: {
        textTransform: "uppercase"
    },
    container: {
        background: Global.colors.white,
        borderRadius: "4px",
        display: "grid",
        gridAutoFlow: "row",
        gridTemplateRows: "72px auto 96px",
        height: "100%",
        left: "24px",
        position: "absolute",
        right: "24px",
        top: "66px",
        zIndex: 1
    },
    header: {
        alignItems: "center",
        borderBottom: `2px solid ${Global.colors.bgGrey}`,
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        justifyContent: "flex-start",
        lineHeight: "23px",
        padding: "24px"
    },
    optionalButtonsContainer: { display: "flex", justifyContent: "flex-start" },
    optionalConfirmButton: {
        textTransform: "uppercase",
        root: {
            backgroundColor: Global.colors.leo,
            "&:hover": {
                backgroundColor: Global.colors.leo
            }
        }
    },
    optionalConfirmButtonContainer: {
        marginRight: "16px"
    },
    optionalButton: {
        background: Global.colors.lightPaleGrey,
        color: Global.colors.darkSlateBlue,
        fontWeight: "600",
        textTransform: "uppercase",
        width: "fit-content"
    },
    optionalDeleteButton: {
        background: Global.colors.lightPaleGrey,
        color: Global.colors.pinkology,
        fontWeight: "600",
        textTransform: "uppercase",
        width: "fit-content"
    },
    requiredButtonsContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonsMarginLeft: {
        marginLeft: "16px"
    }
};

export default styles;
