// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MaterialButton } from "..";
// Styles
import styles from "./WarningPopup.css";

class WarningPopup extends PureComponent {
    static propTypes = {
        buttonAction: PropTypes.func.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        closeFunction: PropTypes.func.isRequired,
        iconClassName: PropTypes.string,
        imageStyle: PropTypes.object,
        noIcon: PropTypes.bool,
        style: PropTypes.shape({
            container: PropTypes.object,
            subtitle: PropTypes.object,
            title: PropTypes.object
        }),
        subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
            .isRequired,
        title: PropTypes.string.isRequired
    };

    handleOnClose = () => {
        const { closeFunction } = this.props;

        closeFunction && closeFunction();
    };

    render() {
        const {
            buttonAction,
            buttonLabel,
            iconClassName,
            imageStyle,
            noIcon,
            style,
            subTitle,
            title
        } = this.props;

        return (
            <div
                style={{ ...styles.container, ...get(style, "container", {}) }}
            >
                <span
                    className="icon-close"
                    onClick={this.handleOnClose}
                    style={styles.closeIcon}
                />
                {!noIcon && (
                    <span
                        className={iconClassName || "icon-warning"}
                        style={{ ...styles.warningIcon, ...imageStyle }}
                    />
                )}
                <span style={{ ...styles.title, ...get(style, "title", {}) }}>
                    {title}
                </span>
                <div
                    style={{
                        ...styles.subTitle,
                        ...get(style, "subtitle", {})
                    }}
                >
                    {subTitle}
                </div>
                <div>
                    <MaterialButton
                        customType="boldPrimary"
                        key="corfirm"
                        onClick={buttonAction}
                        style={styles.button}
                    >
                        {buttonLabel}
                    </MaterialButton>
                </div>
            </div>
        );
    }
}

export default WarningPopup;
