// Modules
import axios from "axios";
import get from "lodash.get";

async function performRequest(action) {
    const { method } = action;

    const options = {
        mode: "cors",
        headers: {
            "Content-Type":
                get(action, "headers.contentType", undefined) ||
                "application/json"
        }
    };

    return await axios({
        method,
        baseURL: dialogFlow.url(),
        headers: options.headers
    });
}

const dialogFlow = {
    get: () => {
        return performRequest({ method: "get" });
    },
    post: () => {
        return performRequest({ method: "post" });
    },
    url: () => {
        return process.env.REACT_APP_DIALOGFLOW_URL;
    }
};

export default dialogFlow;
