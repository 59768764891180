import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        backgroundColor: Global.colors.white,
        borderRadius: "8px",
        display: "flex",
        padding: "24px",
        width: "fit-content"
    }
};

export default styles;
