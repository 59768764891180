import Global from "../../config/global.css";

const styles = {
    body: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 266px)",
        overflowY: "auto"
    },
    container: {
        background: Global.colors.bgGrey,
        borderRadius: "4px",
        display: "grid",
        gridAutoFlow: "row",
        gridTemplateRows: "32px auto auto",
        height: "100%",
        left: "0px",
        minWidth: "1124px",
        position: "absolute",
        right: "0px",
        top: "162px",
        width: "calc(100vw - 304px)",
        zIndex: 2
    },
    header: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "normal",
        justifyContent: "flex-start",
        lineHeight: "23px",
        paddingBottom: "8px"
    }
};

export default styles;
