import Global from "../../../../../config/global.css";

const styles = {
    container: {
        padding: "88px 32px 48px",
        textAlign: "center"
    },
    menus: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "auto",
        maxWidth: "792px"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginBottom: "20px"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "26px",
        marginBottom: "16px"
    },
    userManual: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        paddingTop: "60px"
    }
};

export default styles;
