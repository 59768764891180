const styles = {
    container: {
        display: "grid",
        gap: "24px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "fit-content"
    },
    innerContainer: {
        display: "grid",
        gap: "18px",
        gridTemplateRows: "62px 62px 62px 62px 116px",
        height: "fit-content"
    },
    textField: {
        width: "100%"
    }
};

export default styles;
