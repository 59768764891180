// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { TagsCard, TopTableCard } from "../";
// Translations
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./ChatbotData.css";

function ChatbotData({ data }) {
    return (
        <div style={styles.container}>
            <div style={styles.tablesContainer}>
                <TopTableCard
                    data={get(data, "chatbotLocations", []).map(entry => ({
                        id: get(entry, "location_id", undefined),
                        count: get(entry, "location_count", undefined),
                        label: get(entry, "location_name", undefined)
                    }))}
                    title={translation.dashboard.topLocationsFromChatbot}
                />
                <TopTableCard
                    data={get(data, "chatbotOffers", []).map(entry => ({
                        id: get(entry, "offer_id", undefined),
                        count: get(entry, "offer_count", undefined),
                        label: get(entry, "offer_title", undefined)
                    }))}
                    title={translation.dashboard.topOffersFromChatbot}
                />
            </div>
            <TagsCard
                data={get(data, "chatbotKeywords", []).map(entry => ({
                    id: get(entry, "keyword_id", undefined),
                    count: get(entry, "keyword_count", undefined),
                    label: get(entry, "keyword", undefined)
                }))}
                title={translation.dashboard.keywordsFromChatbot}
            />
        </div>
    );
}

ChatbotData.propTypes = {
    data: PropTypes.shape({
        chatbotKeywords: PropTypes.arrayOf(
            PropTypes.shape({
                keyword_id: PropTypes.string,
                keyword_count: PropTypes.number,
                keyword: PropTypes.string
            })
        ),
        chatbotLocations: PropTypes.arrayOf(
            PropTypes.shape({
                location_id: PropTypes.string,
                location_count: PropTypes.number,
                location_name: PropTypes.string
            })
        ),
        chatbotOffers: PropTypes.arrayOf(
            PropTypes.shape({
                offer_id: PropTypes.string,
                offer_count: PropTypes.number,
                offer_title: PropTypes.string
            })
        )
    })
};

export default ChatbotData;
