// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import { ExpandableFilter } from "../../../../components";
import { RadioFilter } from "../../components";
// Translation
import translation from "../../../../config/translation";

const CONTRACT_STATUS = "contractStatus";

class Filters extends PureComponent {
    static propTypes = {
        applyFilters: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired
    };

    handleSetFilter = ({ filter, value }) => {
        const { applyFilters } = this.props;

        applyFilters && applyFilters([{ filter, value }]);
    };

    resetAllFilters = () => {
        const { applyFilters } = this.props;

        applyFilters && applyFilters([]);
    };

    render() {
        return (
            <ExpandableFilter
                filters={[
                    {
                        title: translation.contractStatus.contractStatus,
                        component: (
                            <RadioFilter
                                getFilterValue={() => this.props.value}
                                handleSetFilter={value => {
                                    this.handleSetFilter({
                                        filter: CONTRACT_STATUS,
                                        value
                                    });
                                }}
                                options={["all", "signed", "notSigned"]}
                                translationPath={translation.contractStatus}
                            />
                        )
                    }
                ]}
                filtersKeys={[CONTRACT_STATUS]}
                getFilterValue={() => this.props.value}
                resetAllFilters={this.resetAllFilters}
            />
        );
    }
}

export default Filters;
