// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MaterialButton } from "../../../../../../../../../../../components";
import { DataCard } from "../../../../../";
// Helpers
import { formatModule, handleEventDetails } from "../../helpers/detailsHelper";
// Translation
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./EventDetailsContent.css";
import { routesPaths } from "../../../../../../../../../components/Routes/Routes.helper";

class EventDetailsContent extends PureComponent {
    static propTypes = {
        details: PropTypes.object,
        enrolments: PropTypes.array,
        eventDetails: PropTypes.object,
        navigateTo: PropTypes.func
    };

    render() {
        const { details, enrolments, eventDetails } = this.props;
        if (!details) {
            return null;
        }

        const offerTitle = get(details, "title", "");

        if (!eventDetails) {
            return null;
        }

        const enrolment =
            enrolments &&
            enrolments.find(
                enrolment =>
                    enrolment.seminar_id === get(eventDetails, "_id", undefined)
            );

        const eventDetailsProcessed = handleEventDetails(
            eventDetails,
            enrolment
        );

        const eventStartDate = get(
            eventDetailsProcessed,
            "startDate",
            undefined
        );
        const eventEndDate = get(eventDetailsProcessed, "endDate", undefined);
        const enrolmentDeadline = get(
            eventDetailsProcessed,
            "enrolmentDeadline",
            undefined
        );
        const externalId = get(eventDetailsProcessed, "externalId", undefined);
        const eventType = get(eventDetailsProcessed, "type", undefined);
        const price = get(eventDetailsProcessed, "price", undefined);
        const instructorsNames = get(
            eventDetailsProcessed,
            "instructorsNames",
            undefined
        );
        const bookedPlaces = get(
            eventDetailsProcessed,
            "bookedPlaces",
            undefined
        );
        const duration = get(eventDetailsProcessed, "duration", undefined);
        const durationUnit = get(eventDetailsProcessed, "durationUnit", "");
        const entity = get(eventDetailsProcessed, "entity", undefined);
        const entityName = entity?.name;
        const entityLink = entity?.link;

        const externalLink = get(
            eventDetailsProcessed,
            "externalLink",
            undefined
        );

        const enrolmentLink = get(
            eventDetailsProcessed,
            "enrolmentLink",
            undefined
        );

        const sourceLabel = get(
            translation.event.sourceType,
            get(eventDetailsProcessed, "source", undefined),
            undefined
        );
        const lastUpdatedTimestamp = get(
            eventDetailsProcessed,
            "lastUpdatedTimestamp",
            undefined
        );

        const city = get(eventDetailsProcessed, "city", undefined);
        const postalCode = get(eventDetailsProcessed, "postalCode", undefined);
        const latitude = get(eventDetailsProcessed, "latitude", undefined);
        const longitude = get(eventDetailsProcessed, "longitude", undefined);

        const modules = get(eventDetailsProcessed, "modules", undefined);
        const contacts = get(eventDetailsProcessed, "contacts", undefined);

        const handleOpenEntityEditView = entity => {
            const { navigateTo } = this.props;
            let path = null;

            const queryParams = new URLSearchParams({
                tab: "suppliers",
                entityId: entity?._id
            });

            path =
                entity?.legal_entities.length > 0
                    ? routesPaths.entitiesMapping
                    : routesPaths.unmappedEntities;

            path = `${path}?${queryParams.toString()}`;

            navigateTo && navigateTo(path);
        };

        return (
            <div style={styles.container}>
                <div style={styles.innerContainer}>
                    <DataCard title={translation.event.associatedSeminar}>
                        {offerTitle}
                    </DataCard>
                    {eventType && translation.event.type[eventType] && (
                        <DataCard title={translation.event.eventType}>
                            {translation.event.type[eventType]}
                        </DataCard>
                    )}
                    <DataCard title={translation.event.location}>
                        <div style={styles.card.container}>
                            <div style={styles.card.innerContainer}>
                                <span style={styles.card.title}>
                                    {`${translation.event.city}:`}
                                </span>
                                <span>{city}</span>
                            </div>
                            {postalCode && (
                                <div style={styles.card.innerContainer}>
                                    <span style={styles.card.title}>
                                        {`${translation.event.postalCode}:`}
                                    </span>
                                    <span>{postalCode}</span>
                                </div>
                            )}
                            {latitude && (
                                <div style={styles.card.innerContainer}>
                                    <span style={styles.card.title}>
                                        {`${translation.event.latitude}:`}
                                    </span>
                                    <span>{latitude}</span>
                                </div>
                            )}
                            {longitude && (
                                <div style={styles.card.innerContainer}>
                                    <span style={styles.card.title}>
                                        {`${translation.event.longitude}:`}
                                    </span>
                                    <span>{longitude}</span>
                                </div>
                            )}
                        </div>
                    </DataCard>
                    {modules && (
                        <DataCard title={translation.event.modules}>
                            <div style={styles.card.container}>
                                {modules.map((module, index) => (
                                    <div
                                        key={index}
                                        style={styles.card.innerContainer}
                                    >
                                        <span style={styles.card.title}>
                                            {`${translation.event.module} ${
                                                module.number
                                            }: ${get(module, "title", "")}`}
                                        </span>
                                        <span>{formatModule(module)}</span>
                                    </div>
                                ))}
                            </div>
                        </DataCard>
                    )}
                </div>
                <div style={styles.innerContainer}>
                    {price && (
                        <DataCard title={translation.event.price}>
                            {price}
                        </DataCard>
                    )}
                    {instructorsNames && (
                        <DataCard title={translation.event.instructor}>
                            {instructorsNames}
                        </DataCard>
                    )}
                    <DataCard title={translation.event.bookedPlaces}>
                        {bookedPlaces}
                    </DataCard>
                    <DataCard title={translation.event.startDate}>
                        {eventStartDate}
                    </DataCard>
                    <DataCard title={translation.event.endDate}>
                        {eventEndDate}
                    </DataCard>
                    {enrolmentDeadline && (
                        <DataCard title={translation.event.enrolmentDeadline}>
                            {enrolmentDeadline}
                        </DataCard>
                    )}
                    {duration && (
                        <DataCard title={translation.event.duration}>
                            {`${duration} ${durationUnit}`}
                        </DataCard>
                    )}
                    {externalLink && (
                        <DataCard title={translation.event.externalLink}>
                            <MaterialButton
                                customType="link"
                                disableRipple
                                onClick={() => {
                                    window.open(externalLink);
                                }}
                                style={styles.link}
                            >
                                {externalLink}
                            </MaterialButton>
                        </DataCard>
                    )}
                    {enrolmentLink && (
                        <DataCard title={translation.event.enrolmentLink}>
                            <MaterialButton
                                customType="link"
                                disableRipple
                                onClick={() => {
                                    window.open(enrolmentLink);
                                }}
                                style={styles.link}
                            >
                                {enrolmentLink}
                            </MaterialButton>
                        </DataCard>
                    )}
                </div>
                <div style={styles.innerContainer}>
                    {sourceLabel && (
                        <DataCard
                            style={styles.metaDataCard}
                            title={translation.event.source}
                        >
                            <span style={styles.metaDataCardBody}>
                                {sourceLabel}
                            </span>
                        </DataCard>
                    )}
                    {lastUpdatedTimestamp && (
                        <DataCard
                            style={styles.metaDataCard}
                            title={translation.event.timestamp}
                        >
                            <span style={styles.metaDataCardBody}>
                                {`${translation.event.lastUpdated} `}
                                <span style={styles.noWordBreak}>
                                    {lastUpdatedTimestamp}
                                </span>
                            </span>
                        </DataCard>
                    )}
                    {externalId && (
                        <DataCard title={translation.event.externalId}>
                            {externalId}
                        </DataCard>
                    )}
                    <DataCard title={translation.event.ihk}>
                        <MaterialButton
                            customType="link"
                            disableRipple
                            onClick={() => handleOpenEntityEditView(entity)}
                            style={styles.link}
                        >
                            {entityName}
                        </MaterialButton>
                        {entityLink && (
                            <MaterialButton
                                customType="link"
                                disableRipple
                                onClick={() => {
                                    window.open(entityLink);
                                }}
                                style={styles.link}
                            >
                                {entityLink}
                            </MaterialButton>
                        )}
                    </DataCard>
                    {contacts &&
                        contacts.map((contact, index) => {
                            const name = get(contact, "name", undefined);
                            const email = get(contact, "email", undefined);
                            const phone = get(contact, "phone", undefined);
                            const pictureLink = get(
                                contact,
                                "pictureLink",
                                undefined
                            );

                            return (
                                <DataCard
                                    key={index}
                                    title={translation.event.contact}
                                >
                                    <div style={styles.card.container}>
                                        {name && (
                                            <div
                                                style={
                                                    styles.card.innerContainer
                                                }
                                            >
                                                <span style={styles.card.title}>
                                                    {`${translation.event.name}:`}
                                                </span>
                                                <span>{name}</span>
                                            </div>
                                        )}
                                        {email && (
                                            <div
                                                style={
                                                    styles.card.innerContainer
                                                }
                                            >
                                                <span style={styles.card.title}>
                                                    {`${translation.event.email}:`}
                                                </span>
                                                <span>{email}</span>
                                            </div>
                                        )}
                                        {phone && (
                                            <div
                                                style={
                                                    styles.card.innerContainer
                                                }
                                            >
                                                <span style={styles.card.title}>
                                                    {`${translation.event.telephone}:`}
                                                </span>
                                                <span>{phone}</span>
                                            </div>
                                        )}
                                        {pictureLink && (
                                            <img
                                                alt="contactPerson"
                                                src={pictureLink}
                                                style={styles.picture}
                                            />
                                        )}
                                    </div>
                                </DataCard>
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details,
        enrolments: state.offerDetails.enrolments
    };
};

export default connect(mapStateToProps)(EventDetailsContent);
