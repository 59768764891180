// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { change, Field, reduxForm } from "redux-form";
// Components
import {
    CustomSnackBar,
    LoginSignup,
    MaterialFabButton,
    MaterialSpinner,
    MaterialTextField,
    Popup,
    WarningPopup
} from "../../components";
// Actions
import { resetPassword } from "./ResetPassword.ducks";
import { closePopup, openPopup } from "../../components/Popup/Popup.ducks";
import { navigateTo } from "../Router/Router.ducks";
// Validators
import { email, required } from "../../services/validators";
// Translation
import translation from "../../config/translation";
// Styles
import styles from "./ResetPassword.css";

class ResetPassword extends PureComponent {
    static propTypes = {
        closePopup: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        navigateTo: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        resetPassword: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { dispatch, location } = this.props;

        const params = new URLSearchParams(get(location, "search", ""));

        const email = params && params.get("email");
        email && dispatch(change("reset-password", "email", email));
    }

    componentWillUnmount() {
        const { closePopup, popup } = this.props;

        get(popup, "opened", undefined) && closePopup && closePopup();
    }

    onSubmit = values => {
        const { navigateTo, resetPassword } = this.props;

        const email = get(values, "email", undefined);

        resetPassword &&
            resetPassword({
                email,
                successCb: () => {
                    this.handleOpenPopup({
                        resultType: "success",
                        action: () => {
                            navigateTo && navigateTo(`/login?email=${email}`);
                        }
                    });
                },
                errorCb: () => {
                    this.handleOpenPopup({ resultType: "error" });
                }
            });
    };

    handleOpenPopup = ({ resultType, action }) => {
        const { closePopup, openPopup } = this.props;

        const closeAction = () => {
            closePopup && closePopup();
            action && action();
        };

        if (resultType === "success") {
            closeAction();
        } else {
            openPopup &&
                openPopup({
                    children: (
                        <WarningPopup
                            buttonAction={closeAction}
                            buttonLabel={translation.resetPassword.tryAgain}
                            closeFunction={closeAction}
                            imageStyle={styles.warningErrorPopupImage}
                            subTitle={translation.resetPassword.error.subtitle}
                            title={translation.resetPassword.error.title}
                        />
                    )
                });
        }
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <form
                className="reset-password-form"
                onSubmit={handleSubmit(this.onSubmit)}
                style={styles.container}
            >
                <MaterialSpinner />
                <CustomSnackBar />
                <LoginSignup
                    action={
                        <MaterialFabButton
                            endAdornment={
                                <span
                                    className="icon-arrow-right"
                                    style={styles.proceedIcon}
                                />
                            }
                            key="submit-button"
                            onClick={() => {}}
                            type="submit"
                            style={styles.proceedButton}
                            variant="extended"
                        >
                            {translation.resetPassword.resetPassword}
                        </MaterialFabButton>
                    }
                    logo={
                        <img
                            alt={"logo"}
                            style={styles.logo}
                            src={
                                require("../../images/logo_DIHK-gGmbH.svg")
                                    .default
                            }
                        />
                    }
                    title={translation.resetPassword.passwordForgotten}
                    subTitle={translation.resetPassword.subtitle}
                    style={{
                        childrenContainer: styles.childrenContainer,
                        container: styles.resetPasswordContainer,
                        logoContainer: styles.logoContainer,
                        subTitleContainer: styles.subtitle,
                        titleContainer: styles.title
                    }}
                >
                    <div style={styles.childrenInnerContainer}>
                        <Field
                            component={MaterialTextField}
                            label={translation.resetPassword.email}
                            name="email"
                            type="email"
                            variant="outlined"
                            validate={[required, email]}
                        />
                    </div>
                </LoginSignup>
                <Popup />
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            { closePopup, navigateTo, openPopup, resetPassword },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: "reset-password" })(ResetPassword));
