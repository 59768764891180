// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CreatableSelect,
    IconButton,
    MaterialTextField
} from "../../../../../../../../../../../components/";
import { GroupCard } from "../../../../../";
// Form
import { change, Field, FieldArray } from "redux-form";
// Validators
import {
    email,
    required,
    telephone
} from "../../../../../../../../../../../services/validators";
// Helpers
import { sortByLabel } from "../../../../helpers/eventDetailsFormHelper";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Contacts.css";

class Contacts extends PureComponent {
    static propTypes = {
        eventDetailsForm: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        entities: PropTypes.array
    };

    prepareContacts = () => {
        const { entities, eventDetailsForm } = this.props;

        const entitySelected = get(
            eventDetailsForm,
            "values.entity.id",
            undefined
        );

        if (!entities) {
            return undefined;
        }

        if (!entitySelected) {
            return [];
        }

        const entity = entities.find(entity => entity._id === entitySelected);
        const contacts = (entity && entity.contacts) || [];

        let contactsList = [];
        if (contacts && contacts.length) {
            contactsList = contacts.map(contact => {
                const contactName = contact.name;

                return {
                    label: contactName,
                    value: contactName,
                    option: {
                        label: contactName,
                        value: contact
                    }
                };
            });
        }

        return contactsList.sort(sortByLabel);
    };

    handleContactChange = (entity, item) => {
        const { dispatch } = this.props;
        const data = get(entity, "option.value", undefined);

        dispatch(change("eventDetails", `${item}.id`, get(data, "_id", null)));
        dispatch(
            change(
                "eventDetails",
                `${item}.entityId`,
                get(data, "entity_id", null)
            )
        );
        dispatch(
            change("eventDetails", `${item}.email`, get(data, "email", null))
        );
        dispatch(
            change("eventDetails", `${item}.phone`, get(data, "phone", null))
        );
        dispatch(
            change(
                "eventDetails",
                `${item}.pictureLink`,
                get(data, "picture_link", null)
            )
        );

        document.activeElement && document.activeElement.blur();
    };

    renderContacts = ({ fields, contacts }) => {
        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.container}>
                                    <span style={styles.label}>{`${
                                        translation.event.contact
                                    } ${index + 1}`}</span>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                                <div style={styles.container}>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={CreatableSelect}
                                            customOnChange={e =>
                                                this.handleContactChange(
                                                    e,
                                                    item
                                                )
                                            }
                                            editable
                                            isClearable
                                            isSearchable
                                            label={translation.event.name}
                                            placeholder={""}
                                            name={`${item}.name`}
                                            options={contacts}
                                            validate={required}
                                        />
                                        <Field
                                            component={MaterialTextField}
                                            label={`${translation.event.telephone} (${translation.event.telephoneNumber})`}
                                            name={`${item}.phone`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={telephone}
                                        />
                                    </div>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            label={translation.event.email}
                                            name={`${item}.email`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={[required, email]}
                                        />
                                        <Field
                                            component={MaterialTextField}
                                            label={
                                                translation.event.pictureLink
                                            }
                                            name={`${item}.pictureLink`}
                                            style={styles.textField}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <IconButton
                    defaultClassName="icon-plus"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                        fields.push({});
                    }}
                    type={["default"]}
                    style={styles.addButton}
                >
                    <span style={styles.addButtonLabel}>
                        {translation.events.editPopup.addContact}
                    </span>
                </IconButton>
            </Fragment>
        );
    };

    render() {
        const contacts = this.prepareContacts();

        return (
            <GroupCard title={translation.event.contact}>
                <FieldArray
                    component={this.renderContacts}
                    name="contacts"
                    props={{ contacts }}
                />
            </GroupCard>
        );
    }
}

const mapStateToProps = state => {
    return {
        eventDetailsForm: get(state, "form.eventDetails", undefined),
        entities: state.eventDetails.entities
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
