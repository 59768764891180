// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash.get";
// Components
import { IconButton } from "..";
// Styles
import styles from "./ClassicButton.css";

class ClassicButton extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        style: PropTypes.object,
        label: PropTypes.string
    };

    render() {
        const { classes, onClick, style, label } = this.props;

        return (
            <IconButton
                defaultClassName="supplier"
                onClick={onClick}
                style={{ ...styles.container, ...get(style, "container", {}) }}
                type={["default"]}
                TouchRippleProps={{
                    classes: {
                        ripple: classNames(classes.iconRipple)
                    }
                }}
            >
                <span className="blur-on-click" style={styles.label}>
                    {label}
                </span>
            </IconButton>
        );
    }
}

export default withStyles(styles)(ClassicButton);
