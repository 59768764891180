// Modules
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// Reducers
import { reducer as formReducer } from "redux-form";
import { firebaseReducer } from "react-redux-firebase";
import Login from "../scenes/Login/Login.ducks";
import MaterialSpinner from "../components/MaterialSpinner/MaterialSpinner.ducks";
import Dashboard from "../scenes/Router/Dashboard/Dashboard.ducks";
import Popup from "../components/Popup/Popup.ducks";
import Users from "../scenes/Router/Users/Users.ducks";
import EditUserForm from "../scenes/Router/Users/components/EditUserForm/EditUserForm.ducks";
import CustomSnackBar from "../components/CustomSnackBar/CustomSnackBar.ducks";
import EditPopup from "../components/EditPopup/EditPopup.ducks";
import ViewPopup from "../components/ViewPopup/ViewPopup.ducks";
import Settings from "../scenes/Router/Settings/Settings.ducks";
import GetStarted from "../scenes/GetStarted/GetStarted.ducks";
import Offers from "../scenes/Router/Offers/Offers.ducks";
import OfferDetails from "../scenes/Router/Offers/components/OfferDetails/OfferDetails.ducks";
import EventDetailsForm from "../scenes/Router/Offers/components/OfferDetails/components/Events/components/EventDetailsForm/EventDetailsForm.ducks";
import ProfileData from "../scenes/ProfileData.ducks";
import EntitiesMapping from "../scenes/Router/EntitiesMapping/EntitiesMapping.ducks";
import CoopPartnersMapping from "../scenes/Router/CoopPartnersMapping/CoopPartnersMapping.ducks";
import ChambersLocations from "../scenes/Router/ChambersLocations/ChambersLocations.ducks";
import Invoicing from "../scenes/Router/Invoicing/Invoicing.ducks";
import EntitiesUploadsTracking from "../scenes/Router/EntitiesUploadsTracking/EntitiesUploadsTracking.ducks";
import OffersClassification from "../scenes/Router/OffersClassification/OffersClassification.ducks";
import OffersKeywordsTagging from "../scenes/Router/OffersKeywordsTagging/OffersKeywordsTagging.ducks";
import MarketingMaterials from "../scenes/Router/MarketingMaterials/MarketingMaterials.ducks";
import Badges from "../scenes/Router/Badges/Badges.ducks";

export default history =>
    combineReducers({
        chambersLocations: ChambersLocations,
        dashboard: Dashboard,
        editPopup: EditPopup,
        editUser: EditUserForm,
        entitiesMapping: EntitiesMapping,
        coopPartnersMapping: CoopPartnersMapping,
        entitiesUploads: EntitiesUploadsTracking,
        eventDetails: EventDetailsForm,
        firebase: firebaseReducer,
        form: formReducer,
        getStarted: GetStarted,
        invoicing: Invoicing,
        login: Login,
        marketingMaterials: MarketingMaterials,
        badges: Badges,
        offers: Offers,
        offersClassification: OffersClassification,
        offerDetails: OfferDetails,
        offersKeywordsTagging: OffersKeywordsTagging,
        popup: Popup,
        profileData: ProfileData,
        settings: Settings,
        snackbar: CustomSnackBar,
        spinner: MaterialSpinner,
        router: connectRouter(history),
        users: Users,
        viewPopup: ViewPopup
    });
