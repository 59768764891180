import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        maxWidth: "650px",
        padding: "20px",
        width: "100%"
    },
    table: {
        container: {
            display: "flex",
            flexDirection: "column"
        },
        index: {
            color: Global.colors.blueyGrey,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "15px",
            textAlign: "right"
        },
        innerContainer: {
            alignItems: "center",
            borderRadius: "4px",
            display: "grid",
            gap: "16px",
            gridTemplateColumns: "24px 1fr auto",
            minHeight: "40px",
            padding: "8px 0px"
        },
        label: {
            color: Global.colors.darkSlateBlue,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "normal",
            hyphens: "auto",
            MozHyphens: "auto",
            WebkitHyphens: "auto",
            lineHeight: "20px",
            wordBreak: "break-word",
            cursor: "pointer"
        },
        value: {
            color: Global.colors.darkSlateBlue,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "20px",
            paddingRight: "20px"
        },
        link: {
            textDecoration: "underline",
            color: Global.colors.darkSlateBlue
        }
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "1.4em",
        paddingBottom: "16px",
        textTransform: "uppercase"
    }
};

export default styles;
