import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        backgroundColor: Global.colors.white,
        borderRadius: "8px",
        color: Global.colors.darkSlateBlue,
        display: "grid",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        gap: "8px",
        height: "fit-content",
        lineHeight: "18px",
        padding: "24px",
        wordBreak: "break-word"
    },
    title: {
        color: Global.colors.blueyGrey,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px",
        textTransform: "uppercase"
    }
};

export default styles;
