// Modules
import React, { Fragment } from "react";
import PropTypes from "prop-types";
// Components
import { DetailsButton } from "../../../../components";
import { OfferDetailsHeader } from "../components/";
import { OfferDetailsTabs } from "../../Offers/components/OfferDetails/components";
import { EventDetailsContent } from "../../Offers/components/OfferDetails/components/Events/components/EventDetails/components";
// Helpers
import { parseTitle as parseTitleHelper } from "../../../../services/htmlDataParsing";

/**
 * Sanitizes the offer title.
 *
 * @param {Node} title the title to parse
 */
export function parseTitle(title) {
    return parseTitleHelper({ title });
}

/**
 * Renders the details button.
 *
 * @param {Function} openDetailView on click action
 * @param {Object} style custom style
 */
export function renderDetailsButton({ openDetailView, style }) {
    renderDetailsButton.propTypes = {
        openDetailView: PropTypes.func,
        style: PropTypes.object
    };

    return <DetailsButton onClick={openDetailView} style={style} />;
}

/**
 * Opens the offer details view popup.
 *
 * @param {Object} param all function and the offer
 */
export function openOfferDetailsView({
    closeViewPopup,
    extraHeader,
    getGFIOfferEnrolments,
    offer,
    openViewPopup,
    saveOfferDetails,
    scrollPosition,
    style
}) {
    const loadOffer = () => {
        saveOfferDetails && saveOfferDetails(offer);
        getGFIOfferEnrolments && getGFIOfferEnrolments(offer.url);
    };

    loadOffer();

    const offerLevel = "offer";
    const eventLevel = "event";

    const offerDetailsHeader = (
        <Fragment>
            {extraHeader}
            <OfferDetailsHeader level={offerLevel} offer={offer} />
        </Fragment>
    );

    const children = (
        <OfferDetailsTabs
            handleOpenEventDetailsView={event => {
                const bodyClassName = "view-popup-body";
                const scrollPositionEventsList =
                    getScrollPosition(bodyClassName);

                openViewPopup({
                    state: {
                        level: eventLevel,
                        event,
                        scrollPosition: scrollPositionEventsList
                    },
                    header: (
                        <Fragment>
                            {extraHeader}
                            <OfferDetailsHeader
                                handleBackToOfferLevel={() => {
                                    closeViewPopup && closeViewPopup();
                                }}
                                level={eventLevel}
                                offer={offer}
                            />
                        </Fragment>
                    ),
                    children: <EventDetailsContent eventDetails={event} />,
                    style
                });

                // Reset scroll to start from top instead of keeping the existing scroll coordinates
                scrollToTopOfViewPopupBody();
            }}
        />
    );

    openViewPopup &&
        openViewPopup({
            state: {
                level: offerLevel,
                loadOffer,
                offer,
                scrollPosition
            },
            header: offerDetailsHeader,
            children,
            style
        });

    scrollToTopOfViewPopupBody();
}

/**
 * Scrolls to top of the view popup body.
 */
function scrollToTopOfViewPopupBody() {
    const bodyClassName = "view-popup-body";
    const element =
        bodyClassName && document.getElementsByClassName(bodyClassName)[0];
    element && element.scrollTo(0, 0);
}

/**
 * Get the scroll position for an element.
 *
 * @param {String} className the element class name
 * @return {Object} the scoll position data
 */
export function getScrollPosition(className = undefined) {
    const elementClassName = className || "infinite-scroll-container";
    const element = document.getElementsByClassName(elementClassName)[0];
    const scrollPosition = element
        ? {
              element: elementClassName,
              top: element.scrollTop || 0,
              left: element.scrollLeft || 0
          }
        : undefined;

    return scrollPosition;
}
