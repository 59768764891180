import Global from "../../../../../config/global.css";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "24px 0px 32px"
    },
    header: {
        backIcon: {
            fontSize: "24px",
            height: "24px",
            width: "24px"
        },
        breadcrums: {
            color: Global.colors.blueyGrey,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "13px",
            textTransform: "uppercase"
        },
        container: {
            alignItems: "center",
            display: "grid",
            gap: "16px",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr auto",
            minHeight: "48px",
            padding: "0px 24px"
        },
        icon: {
            color: Global.colors.white,
            fontSize: "24px"
        },
        iconsContainer: {
            display: "grid",
            gap: "16px",
            gridAutoFlow: "column"
        },
        innerContainer: {
            display: "grid",
            gap: "8px"
        },
        title: {
            alignItems: "center",
            color: Global.colors.darkSlateBlue,
            display: "grid",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "23px",
            gap: "16px",
            gridAutoFlow: "column",
            gridAutoColumns: "auto 1fr"
        }
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        padding: "0px 24px 16px"
    },
    tooltip: {
        backgroundColor: "#0034AC",
        color: Global.colors.white
    }
};

export default styles;
