// Modules
import React, { Component } from "react";
import { utc } from "moment";
import PropTypes from "prop-types";
// Styles
import styles from "./AudioPlayer.css";

class AudioPlayer extends Component {
    static propTypes = {
        audioToRender: PropTypes.string,
        track: PropTypes.object.isRequired
    };

    state = {
        finishSliderPosition: null,
        iconWidth: 0,
        isPlaying: false,
        parentWidth: 0,
        rewindTrackByClickingOnLine: null,
        startSliderPosition: null,
        time: 0
    };

    componentDidUpdate(prevProps, prevState) {
        const { audioToRender } = this.props;

        if (audioToRender && !prevProps.audioToRender) {
            this.audio = new Audio();
            if (audioToRender) {
                this.audio.src = audioToRender;
                this.audio.onloadeddata = () => {
                    this.onTimeUpdate();
                };
            }
        }

        if (!prevState.parentWidth && !prevState.iconWidth) {
            if (this.parentRef && this.iconRef) {
                this.setState({
                    parentWidth: this.parentRef.getBoundingClientRect().width,
                    iconWidth: this.iconRef.getBoundingClientRect().width
                });
            }
        }
    }

    onTimeUpdate = () => {
        requestAnimationFrame(() => {
            if (this.audio) {
                this.setState({
                    time: utc(
                        Math.round(
                            this.audio.duration - this.audio.currentTime
                        ) * 1000
                    ).format("HH:mm:ss")
                });
                this.onTimeUpdate();
            }
        });
    };

    componentWillUnmount() {
        this.audio && this.audio.pause();
        this.audio = null;
    }

    pauseOrPlayAudio = () => {
        const { isPlaying } = this.state;
        if (isPlaying) {
            this.audio.pause();
        } else {
            this.audio.play();
        }

        if (
            this.audio &&
            this.audio.currentTime &&
            this.audio.duration &&
            this.audio.duration === this.audio.currentTime
        ) {
            this.audio.currentTime = 0;
            this.audio.play();
            return null;
        }

        this.setState({ isPlaying: !isPlaying });
    };

    handleOnMouseDown = e => {
        this.setState({
            startSliderPosition: e.clientX,
            finishSliderPosition: null
        });
    };

    handleOnClickOnLine = e => {
        if (e.target.tagName === "DIV") {
            this.setState({
                rewindTrackByClickingOnLine: e.nativeEvent.offsetX,
                finishSliderPosition: null,
                startSliderPosition: null
            });
        }
    };

    handleOnMouseUp = e => {
        this.setState({
            finishSliderPosition: e.clientX
        });
    };

    calcSliderIconPosition = () => {
        const { parentWidth, iconWidth } = this.state;
        let sliderIconPosition = iconWidth;
        const sliderWay = parentWidth - iconWidth;
        if (this.audio && parentWidth) {
            const currentTimeInPercent =
                this.audio.currentTime && !isNaN(this.audio.duration)
                    ? this.audio.currentTime / this.audio.duration
                    : 0;

            sliderIconPosition = currentTimeInPercent * sliderWay;
        }

        return sliderIconPosition;
    };

    rewindAudio = sliderPosition => {
        const {
            finishSliderPosition,
            iconWidth,
            parentWidth,
            rewindTrackByClickingOnLine,
            startSliderPosition
        } = this.state;

        if (rewindTrackByClickingOnLine) {
            if (this.audio && this.audio.currentTime && this.audio.duration) {
                this.audio.currentTime =
                    (this.audio.duration * rewindTrackByClickingOnLine) /
                    parentWidth;
            }
            this.setState({
                rewindTrackByClickingOnLine: null,
                finishSliderPosition: null,
                startSliderPosition: null
            });
            return this.audio.currentTime;
        }

        if (!finishSliderPosition || !startSliderPosition) {
            return sliderPosition;
        }

        const sliderWay = parentWidth - iconWidth;
        const differenceTimeInPX = finishSliderPosition - startSliderPosition;

        const newPosition = sliderPosition + differenceTimeInPX;

        if (
            this.audio &&
            Object.prototype.hasOwnProperty.call(this.audio, "currentTime") &&
            Object.prototype.hasOwnProperty.call(this.audio, "duration")
        ) {
            this.audio.currentTime =
                this.audio.currentTime +
                (this.audio.duration * differenceTimeInPX) / sliderWay;
        }

        this.setState({
            finishSliderPosition: null,
            startSliderPosition: null,
            rewindTrackByClickingOnLine: null
        });

        if (newPosition <= 0) {
            this.audio.currentTime = 0;
            return 0;
        } else if (newPosition >= sliderWay) {
            this.audio.currentTime = this.audio.duration;
            return `${sliderWay}px`;
        }

        return newPosition;
    };

    renderCustomAudioPlayer = (timeLeft, description) => {
        const {
            isPlaying,
            iconWidth,
            parentWidth,
            rewindTrackByClickingOnLine
        } = this.state;

        let iconClassName = isPlaying ? "icon-pause" : "icon-play";
        let sliderIconPosition = this.calcSliderIconPosition();
        if (rewindTrackByClickingOnLine) {
            sliderIconPosition = this.rewindAudio(sliderIconPosition);
        }

        if (
            this.audio &&
            this.audio.currentTime &&
            this.audio.duration &&
            this.audio.duration === this.audio.currentTime
        ) {
            iconClassName = "icon-play";
        }

        const parentPadding = this.parentRef
            ? this.parentRef.parentNode.style.paddingLeft
            : 0;

        const sliderWay = parentWidth - iconWidth;
        const fillingBlock = `calc(${sliderIconPosition}px + ${parentPadding} + ${
            iconWidth / 2
        }px)`;
        const fillingBlockStyle =
            sliderIconPosition === 0
                ? {
                      ...styles.fillingBlock,
                      ...styles.fillingBlockForTrackStart
                  }
                : sliderIconPosition < 1
                ? {
                      ...styles.fillingBlock,
                      ...styles.fillingBlockWithTransition,
                      ...{
                          width: fillingBlock
                      }
                  }
                : sliderIconPosition < sliderWay
                ? {
                      ...styles.fillingBlock,
                      ...{
                          width: fillingBlock
                      }
                  }
                : {
                      ...styles.fillingBlock,
                      ...styles.fillingBlockForTrackEnd
                  };
        return (
            <div style={styles.container} onMouseUp={this.handleOnMouseUp}>
                <div style={fillingBlockStyle} />
                <div style={styles.headerContainer}>
                    <span style={styles.title}>{description}</span>
                    <span style={styles.timeLeft}>{timeLeft}</span>
                </div>
                <div
                    onClick={this.handleOnClickOnLine}
                    ref={ref => (this.parentRef = ref)}
                    style={styles.slider}
                >
                    <span
                        onClick={this.pauseOrPlayAudio}
                        onMouseDown={this.handleOnMouseDown}
                        ref={ref => (this.iconRef = ref)}
                        style={{
                            ...styles.sliderIcon,
                            ...{ left: sliderIconPosition }
                        }}
                        className={iconClassName}
                    />
                </div>
            </div>
        );
    };

    render() {
        const { audioToRender, track } = this.props;
        const { time } = this.state;

        if (
            !audioToRender ||
            !Object.prototype.hasOwnProperty.call(track, "content") ||
            !Object.prototype.hasOwnProperty.call(track, "format") ||
            !Object.prototype.hasOwnProperty.call(track, "description")
        ) {
            return null;
        }

        const timeLeft = this.audio ? time : null;

        return this.renderCustomAudioPlayer(timeLeft, track.description);
    }
}

export default AudioPlayer;
