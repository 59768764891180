// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../../config/translation";

export const uploadFile = ({ file, processingCb, cb }) => {
    return async (dispatch, getState, getFirebase) => {
        processingCb && processingCb();

        const firebase = getFirebase();
        const firebaseAuth = firebase.auth();
        const {
            firebase: { auth }
        } = getState();

        const user = firebaseAuth.currentUser;

        const data = new FormData();
        data.append("user_name", get(auth, "displayName", undefined));
        data.append("user_email", get(user, "email", undefined));
        data.append("file", file);

        api.postFile("uploadoffersevents", data)
            .then(() => {
                cb && cb();
                dispatch(
                    openSnackbar(translation.offersUpload.success, "success")
                );
            })
            .catch(e => {
                console.log("Error calling uploadFile: ", e);

                cb && cb(true);
            });
    };
};
