// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Fab } from "@material-ui/core";
// Styles
import styles from "./MaterialFabButton.css";

const CssButton = (variant, style) => {
    let customStyle = get(styles, variant, {});
    customStyle.root["&.MuiFab-root"] = {
        ...customStyle.root["&.MuiFab-root"],
        ...get(style, "container", {})
    };
    customStyle.root["& .MuiFab-label"] = {
        ...customStyle.root["& .MuiFab-label"],
        ...get(style, "label", {})
    };
    customStyle.root["&.MuiFab-root"]["&.Mui-disabled"] = {
        ...customStyle.root["&.MuiFab-root"]["&.Mui-disabled"],
        ...get(style, "disabled", {})
    };
    customStyle.root["&.MuiFab-root"]["&:hover"] = {
        ...customStyle.root["&.MuiFab-root"]["&:hover"],
        ...get(style, "onHover", {})
    };
    customStyle.root["&.MuiFab-root"]["&:active"] = {
        ...customStyle.root["&.MuiFab-root"]["&:active"],
        ...get(style, "active", {})
    };

    return withStyles(customStyle)(Fab);
};

class MaterialFabButton extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        disableRipple: PropTypes.bool,
        endAdornment: PropTypes.node,
        endAdornmentStyle: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        startAdornment: PropTypes.node,
        startAdornmentStyle: PropTypes.object,
        style: PropTypes.shape({
            active: PropTypes.object,
            container: PropTypes.object,
            disabled: PropTypes.object,
            label: PropTypes.object,
            onHover: PropTypes.object
        }),
        type: PropTypes.oneOf(["button", "submit"]),
        variant: PropTypes.oneOf(["extended", "circular"])
    };
    static defaultProps = {
        type: "button",
        variant: "circular"
    };

    render() {
        const {
            children,
            className,
            disabled,
            disableRipple,
            endAdornment,
            endAdornmentStyle,
            onClick,
            startAdornment,
            startAdornmentStyle,
            style,
            type,
            variant
        } = this.props;

        const StyledButton = CssButton(variant, style);

        return (
            <StyledButton
                className={className}
                disabled={disabled}
                disableRipple={disableRipple}
                onClick={onClick && onClick}
                style={style}
                type={type}
                variant={variant}
            >
                {startAdornment && (
                    <div
                        style={{
                            ...styles.startAdornment,
                            ...startAdornmentStyle
                        }}
                    >
                        {startAdornment}
                    </div>
                )}
                {children}
                {endAdornment && (
                    <div
                        style={{ ...styles.endAdornment, ...endAdornmentStyle }}
                    >
                        {endAdornment}
                    </div>
                )}
            </StyledButton>
        );
    }
}

export default MaterialFabButton;
