// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// Components
import { MaterialSnackBar } from "../";
// Actions
import { closeSnackbar } from "./CustomSnackBar.ducks";
// Styles
import styles from "./CustomSnackBar.css";

class CustomSnackBar extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        closeSnackbar: PropTypes.func,
        snackbar: PropTypes.object
    };

    render() {
        const { classes, closeSnackbar, snackbar } = this.props;

        return (
            <MaterialSnackBar
                {...snackbar}
                onClose={closeSnackbar}
                closeIcon={props => (
                    <span
                        className="icon-close"
                        style={styles.closeIcon}
                        {...props}
                    />
                )}
                classes={{ anchorOriginTopRight: classes.achor }}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        snackbar: state.snackbar
    };
};

const mapDispatchToProps = {
    closeSnackbar
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(CustomSnackBar);
