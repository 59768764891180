import Global from "../../../../../config/global.css";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "24px"
    },
    innerContainer: {
        display: "grid",
        gap: "48px"
    },
    section: {
        container: {
            display: "grid",
            gap: "16px"
        },
        note: {
            color: Global.colors.blueyGrey,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "13px",
            textTransform: "uppercase"
        },
        title: {
            color: Global.colors.darkSlateBlue,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "15px"
        },
        titleContainer: {
            display: "grid",
            gap: "16px",
            gridAutoFlow: "column",
            gridTemplateColumns: "1fr auto"
        }
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "24px"
    }
};

export default styles;
