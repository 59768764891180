// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Translation
import translation from "../../../../config/translation";
// Styles
import styles from "./NotificationMessage.css";

class NotificationMessage extends PureComponent {
    static propTypes = {
        displayTypingIndicatorAfterUserMessage: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired
    };

    componentDidMount() {
        const { displayTypingIndicatorAfterUserMessage, text } = this.props;

        text === translation.chatbot.talkingWithHumanNotification &&
            displayTypingIndicatorAfterUserMessage &&
            displayTypingIndicatorAfterUserMessage(false);
    }

    render() {
        const { text } = this.props;

        return (
            <div style={styles.container}>
                <div style={styles.border} />
                <div style={styles.messageContainer}>{text}</div>
                <div style={styles.border} />
            </div>
        );
    }
}

export default NotificationMessage;
