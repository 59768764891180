import Global from "../../../../../config/global.css";

const styles = {
    icon: {
        fontSize: "24px"
    },
    iconsContainer: {
        alignItems: "center",
        alignSelf: "flex-start",
        display: "grid",
        gridAutoFlow: "column",
        gap: "16px",
        justifyContent: "flex-end",
        marginTop: "4px"
    },
    rowContainer: {
        minHeight: "40px",
        gridAutoFlow: "column"
    },
    onlineKeywordsContainer: {
        backgroundColor: Global.colors.darkSlateBlue,
        borderRadius: "4px",
        color: Global.colors.white,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        margin: "4px",
        minHeight: "24px",
        padding: "5.5px 8px",
        width: "fit-content"
    }
};

export default styles;
