// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Components
import { CircularProgress } from "../";
// Styles
import styles from "./MaterialSpinner.css";

class MaterialSpinner extends PureComponent {
    static propTypes = {
        spinner: PropTypes.object
    };

    render() {
        const {
            spinner: { loading, style }
        } = this.props;

        return loading && loading.length > 0 ? (
            <div
                className="spinner-overlay"
                style={{ ...styles.container, ...style }}
            >
                <CircularProgress className="spinner" />
            </div>
        ) : null;
    }
}

const mapStateToProps = state => {
    return {
        spinner: state.spinner
    };
};

export default connect(mapStateToProps, null)(MaterialSpinner);
