import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "24px"
    },
    tabs: {
        container: {
            background: Global.colors.white,
            borderRadius: "100px",
            margin: "0px 24px",
            width: "fit-content"
        },
        indicator: {
            visibility: "hidden"
        },
        tab: {
            borderRadius: "100px",
            color: Global.colors.darkSlateBlue,
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "18px",
            opacity: "unset",
            padding: "16px 24px",
            textTransform: "unset"
        },
        tabSelected: {
            background: Global.colors.green,
            color: Global.colors.white
        }
    }
};

export default styles;
