import Global from "../../config/global.css";

const styles = {
    container: {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "1.4em",
        position: "relative"
    },
    dialog: {
        container: {
            backgroundColor: Global.colors.white,
            borderRadius: "8px",
            color: Global.colors.darkSlateBlue,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "1.4em",
            position: "absolute",
            right: 0,
            top: 0,
            width: "320px",
            zIndex: 1
        },
        filterContainer: {
            display: "grid",
            gap: "10px",
            gridAutoFlow: "row"
        },
        headerContainer: {
            cursor: "default",
            width: "unset"
        },
        innerContainer: {
            display: "grid",
            gap: "24px",
            gridAutoFlow: "row",
            padding: "0px 16px 24px 16px"
        },
        linkButton: {
            root: {
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "21px",
                maxHeight: "fit-content"
            }
        },
        linkButtonContainer: {
            textAlign: "end"
        },
        separator: {
            borderTop: `1px solid ${Global.colors.paleGrey}`
        },
        title: {
            color: Global.colors.blueyGrey,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "21px",
            textTransform: "uppercase"
        }
    },
    filter: {
        alignItems: "center",
        backgroundColor: Global.colors.white,
        borderRadius: "8px",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        display: "grid",
        gap: "16px",
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr auto",
        height: "48px",
        padding: "12px 16px",
        width: "105px"
    },
    filterCount: {
        alignItems: "center",
        backgroundColor: Global.colors.melon,
        borderRadius: "100%",
        color: Global.colors.white,
        display: "flex",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "bold",
        justifyContent: "center",
        height: "20px",
        lineHeight: "1.4em",
        position: "absolute",
        right: "-10px",
        top: "-10px",
        width: "20px"
    },
    icon: {
        cursor: "pointer",
        fontSize: "24px"
    }
};

export default styles;
