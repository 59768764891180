// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Components
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "./components/SnackbarContentWrapper";

class MaterialSnackBar extends PureComponent {
    static propTypes = {
        autoHideDuration: PropTypes.number,
        closeIcon: PropTypes.func,
        horizontal: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        onClose: PropTypes.func,
        open: PropTypes.bool,
        transitionProps: PropTypes.shape({
            direction: PropTypes.string,
            timeout: PropTypes.shape({
                enter: PropTypes.number,
                exit: PropTypes.number
            })
        }),
        type: PropTypes.oneOf(["error", "info", "success", "warn"]),
        vertical: PropTypes.string
    };

    static defaultProps = {
        autoHideDuration: 5000,
        horizontal: "right",
        message: "",
        transitionProps: {
            direction: "left",
            timeout: { enter: 500, exit: 500 }
        },
        type: "success",
        vertical: "top"
    };

    state = {
        open: false,
        transition: undefined
    };

    componentDidMount() {
        if (this.props.open) {
            this.openSnackBar();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.openSnackBar();
        }
    }

    openSnackBar() {
        this.setState({
            open: true,
            transition: this.slideTransition
        });
    }

    slideTransition = props => {
        return (
            <Slide
                {...props}
                direction={this.props.transitionProps.direction}
                timeout={{
                    enter: this.props.transitionProps.timeout.enter,
                    exit: this.props.transitionProps.timeout.exit
                }}
            />
        );
    };

    handleClose = (event, reason) => {
        const { onClose } = this.props;
        if (reason === "clickaway") {
            return;
        }

        this.setState(
            {
                ...this.state,
                open: false
            },
            () => {
                setTimeout(function () {
                    onClose && onClose();
                }, 1000);
            }
        );
    };

    render() {
        const {
            autoHideDuration,
            closeIcon,
            horizontal,
            message,
            // eslint-disable-next-line no-unused-vars
            transitionProps,
            type,
            vertical,
            ...props
        } = this.props;
        const { open, transition } = this.state;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: vertical,
                    horizontal: horizontal
                }}
                autoHideDuration={autoHideDuration}
                onClose={this.handleClose}
                open={open}
                TransitionComponent={transition}
                {...props}
            >
                <SnackbarContentWrapper
                    CustomCloseIcon={closeIcon}
                    message={message}
                    onClose={this.handleClose}
                    type={type}
                />
            </Snackbar>
        );
    }
}

export default MaterialSnackBar;
