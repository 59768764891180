// Modules
import get from "lodash.get";
// Helpers
import {
    changeFormatFrom,
    findDifferentPerMonthForTwoDates,
    findDifferentPerYearForTwoDates,
    formatTimestamp,
    formatTimestampDETimezone
} from "../../../../../../../../../../services/date";
import { formatPriceWithCurrency } from "../../../../../../../../../../services/formats";
import { validateLink } from "../../../../../../../../helpers/links";
// Translation
import translation from "../../../../../../../../../../config/translation";

export function formatModuleDates(startDate, endDate) {
    const format = "D. MMMM YYYY";
    const oldFormat = "YYYY-MM-DDTHH:mm:ss";
    let formattedStartDate = changeFormatFrom(startDate, oldFormat, format);
    const formattedEndDate = changeFormatFrom(endDate, oldFormat, format);

    formattedStartDate =
        findDifferentPerYearForTwoDates(
            formattedStartDate,
            formattedEndDate,
            format
        ) !== 0
            ? formattedStartDate
            : findDifferentPerMonthForTwoDates(
                  formattedStartDate,
                  formattedEndDate,
                  format
              ) === 0
            ? changeFormatFrom(formattedStartDate, format, "D.")
            : changeFormatFrom(formattedStartDate, format, "D. MMMM");

    return `${translation.event.from} ${formattedStartDate} ${translation.event.to} ${formattedEndDate}`;
}

/**
 * Formats the module data.
 *
 * @param {Object} module the module data
 */
export function formatModule(module) {
    const duration = get(module, "duration", undefined);
    const durationUnit = get(module, "durationUnit", "");

    let moduleDuration = duration && `${duration} ${durationUnit}`;

    return `${moduleDuration ? moduleDuration : ""}${
        moduleDuration && durationUnit ? " - " : moduleDuration ? "- " : ""
    }${formatModuleDates(module.startDate, module.endDate)}`;
}

export function formatDate(date) {
    if (!date) {
        return translation.event.onRequest;
    }

    return formatTimestamp(date, "D. MMMM YYYY");
}

/**
 * Processes the details raw data.
 *
 * @param {Object} eventDetails the event details data got from backend fetch
 * @param {Object} enrolment the event enrolment details data got from backend fetch
 */
export function handleEventDetails(eventDetails, enrolment) {
    const startDate = formatDate(get(eventDetails, "start_date", undefined));
    const endDate = formatDate(get(eventDetails, "end_date", undefined));
    let enrolmentDeadline = get(eventDetails, "enrolment_deadline", undefined);
    enrolmentDeadline && (enrolmentDeadline = formatDate(enrolmentDeadline));

    const id = get(eventDetails, "_id", undefined);
    const externalId = get(eventDetails, "external_id", undefined);
    const type = get(eventDetails, "type", undefined);
    let price = get(eventDetails, "price", undefined);
    price = !price || isNaN(price) ? undefined : formatPriceWithCurrency(price);

    const instructors = get(eventDetails, "instructors", []);
    let instructorsNames =
        instructors && instructors.length > 0
            ? instructors.map(instructor => instructor.name).join(", ")
            : undefined;
    const bookedPlaces = get(enrolment, "booked_participants", "N/A");

    const duration = get(eventDetails, "duration", undefined);
    const durationUnit = mapDurationUnit(
        get(eventDetails, "duration_type", undefined)
    );

    const entity = get(eventDetails, "entity_details[0]", undefined);

    const externalLink = validateLink(
        get(eventDetails, "external_link", undefined)
    );

    const enrolmentLink = validateLink(
        get(eventDetails, "enrolment_link", undefined)
    );

    const source = get(eventDetails, "source", undefined);
    let lastUpdatedTimestamp = get(
        eventDetails,
        "last_updated_timestamp",
        undefined
    );
    lastUpdatedTimestamp &&
        (lastUpdatedTimestamp = formatTimestampDETimezone(
            lastUpdatedTimestamp,
            "D. MMMM YYYY"
        ));

    const city = get(eventDetails, "location.name", undefined);
    const postalCode = get(eventDetails, "location.postal_code", undefined);
    const latitude = get(
        eventDetails,
        "location.location.coordinates[0]",
        undefined
    );
    const longitude = get(
        eventDetails,
        "location.location.coordinates[1]",
        undefined
    );

    let modules = get(eventDetails, "modules", undefined);
    modules &&
        (modules = modules.map(module => {
            return {
                number: get(module, "number", undefined),
                title: get(module, "title", undefined),
                duration: get(module, "duration", undefined),
                durationUnit: mapDurationUnit(
                    get(module, "duration_type", undefined)
                ),
                startDate: get(module, "start_date", undefined),
                endDate: get(module, "end_date", undefined)
            };
        }));
    modules &&
        modules.sort(function (a, b) {
            return get(a, "number", "") - get(b, "number", "");
        });

    let contacts = get(eventDetails, "entity_contacts_details", undefined);
    contacts &&
        (contacts = contacts.map(contact => {
            return {
                name: get(contact, "name", undefined),
                email: get(contact, "email", undefined),
                phone: get(contact, "phone", undefined),
                pictureLink: get(contact, "picture_link", undefined)
            };
        }));

    return {
        id,
        startDate,
        endDate,
        enrolmentDeadline,
        externalId,
        type,
        price,
        instructorsNames,
        bookedPlaces,
        duration,
        durationUnit,
        entity,
        externalLink,
        enrolmentLink,
        city,
        postalCode,
        latitude,
        longitude,
        modules: modules && modules.length > 0 ? modules : undefined,
        contacts,
        source,
        lastUpdatedTimestamp
    };
}

export function mapDurationUnit(unit) {
    return (unit && translation.event.unit[unit.toLowerCase()]) || unit;
}
