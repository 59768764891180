import Global from "../../config/global.css";

const styles = {
    container: {
        alignItems: "center",
        display: "grid",
        gridAutoFlow: "column",
        gridGap: "16px",
        maxWidth: "288px",
        padding: "12px 16px",
        width: "fit-content"
    },
    containerActive: {
        backgroundColor: Global.colors.white,
        borderRadius: "6px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)"
    },
    containerClickable: {
        cursor: "pointer"
    },
    containerExpanded: {
        borderRadius: "6px 6px 0px 0px"
    },
    defaultImage: {
        alignItems: "center",
        backgroundColor: Global.colors.darkPaleGrey,
        color: Global.colors.white,
        display: "flex",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        justifyContent: "center",
        lineHeight: "23px"
    },
    icon: {
        alignSelf: "center",
        display: "flex",
        justifySelf: "center",
        height: "24px",
        width: "24px"
    },
    image: {
        borderRadius: "100px",
        display: "flex",
        height: "48px",
        width: "48px"
    },
    innerContainer: {
        display: "grid",
        gridGap: "5px"
    },
    subTitle: {
        color: Global.colors.blueyGrey,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "13px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
        whiteSpace: "nowrap"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "18px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
};

export default styles;
