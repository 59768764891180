// Modules
import React from "react";
import { submit } from "redux-form";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import differenceWith from "lodash.differencewith";
// Components
import {
    IconButton,
    MaterialButton,
    WarningPopup
} from "../../../../components";
import { DetailsForm } from "../components/OfferDetails/components";
// Translations
import translation from "../../../../config/translation";
// Styles
import styles from "../Offers.css";

/**
 * Checks if there are changes and if there is displays a popup to confirm unsaved changes.
 *
 * @param {Object} params all functions and params to perform the check and popup opening.
 */
function handleCancelEditPopup({
    closeEditPopup,
    getOfferDetailsForm,
    closePopup,
    openPopup
}) {
    const offerDetailsForm = getOfferDetailsForm && getOfferDetailsForm();
    const initialValues = get(offerDetailsForm, "initial", undefined);
    const newValues = get(offerDetailsForm, "values", undefined);
    const valuesHasChanged = !isEqual(initialValues, newValues);

    if (valuesHasChanged) {
        openPopup({
            children: displayWarningPopup(
                () => {
                    closeEditPopup();
                    closePopup();
                },
                translation.offers.editPopup.confirm,
                closePopup,
                "warn",
                translation.offers.editPopup.unsavedWarningMessage,
                translation.offers.editPopup.unsavedChanges
            )
        });
    } else {
        closeEditPopup();
    }
}

/**
 * Opens the warning popup with the error passed in param.
 *
 * @param {Object} param the error and functions to open and close warning popup
 */
export function handleErrorPopupDisplay({
    errorType,
    errorMessage: { title, subtitle },
    closePopup,
    openPopup
}) {
    openPopup({
        children: displayWarningPopup(
            closePopup,
            translation.offers.editPopup.tryAgain,
            closePopup,
            errorType,
            subtitle,
            title
        )
    });
}

/**
 * Displays the warning popup.
 *
 * @param {Function} action the main action
 * @param {String} actionLabel the main action label
 * @param {Function} closeAction the close action
 * @param {String} errorType the error type (warn or error)
 * @param {String} subtitle the popup subtitle
 * @param {String} title the popup title
 */
export function displayWarningPopup(
    action,
    actionLabel,
    closeAction,
    errorType,
    subtitle,
    title
) {
    return (
        <WarningPopup
            buttonAction={() => {
                action && action();
            }}
            buttonLabel={actionLabel}
            closeFunction={() => {
                closeAction && closeAction();
            }}
            imageStyle={
                errorType === "error"
                    ? styles.warningPopupImageError
                    : styles.warningPopupImage
            }
            subTitle={subtitle}
            title={title}
        />
    );
}

/**
 * Opens the offer details form.
 *
 * @param {Object} params all functions and params to perform the open.
 */
export function openOfferDetailsFormPopup({
    closeEditPopup,
    closePopup,
    dispatch,
    editMode,
    getOfferDetailsForm,
    openEditPopup,
    openPopup,
    status
}) {
    openEditPopup &&
        openEditPopup({
            cancelAction: () =>
                handleCancelEditPopup({
                    closeEditPopup,
                    closePopup,
                    getOfferDetailsForm,
                    openPopup
                }),
            cancelButtonLabel: translation.offers.editPopup.cancel,
            children: <DetailsForm />,
            confirmAction: () => {
                dispatch(submit("offerDetails"));
            },
            confirmButtonLabel: editMode
                ? status == "draft"
                    ? translation.offers.editPopup.publish
                    : translation.offers.editPopup.saveChanges
                : translation.offers.editPopup.createOffer,

            header: editMode
                ? translation.offers.editPopup.editOffer
                : translation.offers.editPopup.createOffer
        });
}

/**
 * Render browse file and clear buttons.
 *
 * @param {Function} uploadAction the action to browse files
 * @param {boolean} displayClear true if the clear button is to be displayed, false otherwise
 * @param {Function} clearAction the action to clear the field
 */
export function renderBrowseFileButton(
    uploadAction,
    displayClear,
    clearAction
) {
    return (
        <div style={styles.buttonsContainer}>
            {displayClear && (
                <IconButton
                    defaultClassName="icon-close"
                    onClick={() => {
                        clearAction && clearAction();
                    }}
                    type={["default"]}
                    style={styles.clearButton}
                />
            )}
            <MaterialButton
                customType="boldPrimary"
                key="upload"
                onClick={() => {
                    uploadAction && uploadAction();
                }}
                style={styles.uploadButton}
            >
                {translation.offers.editPopup.upload}
            </MaterialButton>
        </div>
    );
}

export function addIfDiff(oldValue, newValue, key, diffObj) {
    oldValue !== newValue && (diffObj[key] = newValue);
}

export function checkDiffMediaContent(initialValue, value) {
    if (!initialValue) {
        return true;
    }

    const diffs = differenceWith(
        Object.entries(value),
        Object.entries(initialValue),
        isEqual
    );

    return diffs && diffs.some(diff => !["file", "image"].includes(diff[0]));
}

/**
 * Sets the status and classification of an offer.
 *
 * @param {*} values the form values
 * @param {*} offer the offer final values
 */
export function mapStatusAndClassification(values, offer) {
    const classification = get(values, "classification", undefined);
    const status = get(values, "status", undefined);

    offer["status"] = status;

    if (!classification) {
        return;
    }

    offer["classification"] = classification;
}

/**
 * Sets the keywords updates.
 *
 * @param {*} values the form values
 * @param {*} details the offer details
 * @param {*} offer the offer final values
 */
export function mapKeywords(values, details, offer) {
    const initialKeywords = (get(details, "keywords", []) || []).map(keyword =>
        get(keyword, "keyword", undefined)
    );

    const keywords = (get(values, "keywords", []) || []).map(
        keyword =>
            get(keyword, "value", undefined) &&
            get(keyword, "value", undefined).trim()
    );

    const keywordsRemoved = differenceWith(initialKeywords, keywords, isEqual);
    const keywordsAdded = differenceWith(keywords, initialKeywords, isEqual);

    if (
        (!keywordsRemoved || keywordsRemoved.length === 0) &&
        (!keywordsAdded || keywordsAdded.length === 0)
    ) {
        return;
    }

    const offerId = get(details, "_id", undefined);

    let data = {
        add_offers:
            (keywordsAdded &&
                keywordsAdded.map(keyword => ({
                    offer_id: offerId,
                    keyword
                }))) ||
            [],
        delete_offers:
            (keywordsRemoved &&
                keywordsRemoved.map(keyword => ({
                    offer_id: offerId,
                    keyword
                }))) ||
            []
    };

    offer["keywords"] = data;
}

/**
 * Map medias and file to be sent in the api request and
 * to store in storage.
 *
 * @param {Object} initialValues the form intial values
 * @param {Object} values the form values
 */
export function mapMedias(initialValues, values) {
    let newMedia = [];
    let newFiles = [];
    let isMediaDiff =
        get(initialValues, "flyers.length", 0) !==
            get(values, "flyers.length", 0) ||
        get(initialValues, "presentations.length", 0) !==
            get(values, "presentations.length", 0) ||
        get(initialValues, "videos.length", 0) !==
            get(values, "videos.length", 0) ||
        get(initialValues, "podcasts.length", 0) !==
            get(values, "podcasts.length", 0) ||
        get(initialValues, "chatbots.length", 0) !==
            get(values, "chatbots.length", 0) ||
        get(initialValues, "images.length", 0) !==
            get(values, "links.length", 0) ||
        get(initialValues, "links.length", 0) !==
            get(values, "links.length", 0) ||
        get(initialValues, "additionalLinks.length", 0) !==
            get(values, "additionalLinks.length", 0);

    let previousUniqueId = Date.now().toString();
    const uniqueId = index => {
        let newUniqeId = Date.now().toString() + index;

        if (newUniqeId === previousUniqueId) {
            newUniqeId += 1;
        }

        previousUniqueId = newUniqeId;
        return newUniqeId;
    };

    values.flyers.forEach((flyer, index) => {
        let format = flyer.format;
        let name = flyer.name;

        if (!flyer.seminarDetailsId) {
            name = `${name}_${uniqueId(index)}`;
        }

        if (flyer.file && flyer.file.url instanceof File) {
            format = flyer.file.url.name.split(".")[1];

            newFiles.push({
                path: "flyers",
                name: `${name}.${format}`,
                file: flyer.file.url
            });
        }
        if (flyer.image && flyer.image.url instanceof File) {
            const imageFormat = flyer.image.url.name.split(".")[1];

            newFiles.push({
                path: "flyers/images",
                name: `${name}.${imageFormat}`,
                file: flyer.image.url
            });

            !format && (format = "pdf");
        }

        newMedia.push({
            content: name,
            title: flyer.title,
            type: flyer.type,
            format: format
        });

        isMediaDiff =
            isMediaDiff ||
            checkDiffMediaContent(
                get(initialValues, `flyers[${index}]`, undefined),
                flyer
            );
    });

    values.videos.forEach((video, index) => {
        let format = video?.format;
        let name = video?.name || video?.link;

        if (!video.seminarDetailsId || video.link) {
            name = name + "_" + uniqueId(index);
        }

        if (video?.file && video.file.url instanceof File) {
            const file = video?.file;

            format = file.url.name.substring(
                file.url.name.lastIndexOf(".") + 1
            );

            newFiles.push({
                path: "videos",
                name: `${name}.${format}`,
                file: video?.file?.url
            });
        }

        newMedia.push({
            content: name,
            title: video?.title,
            type: video?.type?.value || video?.type,
            format: format || "link"
        });

        isMediaDiff =
            isMediaDiff ||
            checkDiffMediaContent(
                get(initialValues, `videos[${index}]`, undefined),
                video
            );
    });

    values.presentations.forEach((presentation, index) => {
        let format = presentation.format;
        let name = presentation.name;

        if (!presentation.seminarDetailsId) {
            name = `${name}_${uniqueId(index)}`;
        }

        if (presentation.file && presentation.file.url instanceof File) {
            format = presentation.file.url.name.split(".")[1];

            newFiles.push({
                path: "presentations",
                name: `${name}.${format}`,
                file: presentation.file.url
            });
        }
        if (presentation.image && presentation.image.url instanceof File) {
            const imageFormat = presentation.image.url.name.split(".")[1];

            newFiles.push({
                path: "presentations/images",
                name: `${name}.${imageFormat}`,
                file: presentation.image.url
            });

            !format && (format = "pdf");
        }

        newMedia.push({
            content: name,
            title: presentation.title,
            type: presentation.type,
            format: format
        });

        isMediaDiff =
            isMediaDiff ||
            checkDiffMediaContent(
                get(initialValues, `presentations[${index}]`, undefined),
                presentation
            );
    });

    values.podcasts.forEach((podcast, index) => {
        let format = podcast.format;
        let name = podcast.name;

        if (!podcast.seminarDetailsId) {
            name = `${name}_${uniqueId(index)}`;
        }

        if (podcast.file && podcast.file.url instanceof File) {
            format = podcast.file.url.name.split(".")[1];

            newFiles.push({
                path: "podcasts",
                name: `${name}.${format}`,
                file: podcast.file.url
            });
        }

        newMedia.push({
            content: name,
            title: podcast.title,
            type: podcast.type,
            format: format,
            description: podcast.description
        });

        isMediaDiff =
            isMediaDiff ||
            checkDiffMediaContent(
                get(initialValues, `podcasts[${index}]`, undefined),
                podcast
            );
    });

    values.chatbots.forEach((chatbot, index) => {
        let format = chatbot.format;
        let name = chatbot.name;

        if (!chatbot.seminarDetailsId) {
            name = `${name}_${uniqueId(index)}`;
        }

        if (chatbot.image && chatbot.image.url instanceof File) {
            format = chatbot.image.url.name.split(".")[1];

            newFiles.push({
                path: "images",
                name: `${name}.${format}`,
                file: chatbot.image.url
            });
        }

        newMedia.push({
            content: name,
            title: chatbot.title,
            type: chatbot.type,
            format: format,
            description: chatbot.description
        });

        isMediaDiff =
            isMediaDiff ||
            checkDiffMediaContent(
                get(initialValues, `chatbots[${index}]`, undefined),
                chatbot
            );
    });

    values.images.forEach((image, index) => {
        let format = image.format;
        let name = values.url;

        if (image.image && image.image.url instanceof File) {
            format = image.image.url.name.split(".")[1];

            newFiles.push({
                path: "images",
                name: `${name}.${format}`,
                file: image.image.url
            });
        }

        newMedia.push({
            content: name,
            title: image.title,
            type: image.type,
            format: format,
            description: image.description
        });

        isMediaDiff =
            isMediaDiff ||
            checkDiffMediaContent(
                get(initialValues, `images[${index}]`, undefined),
                image
            );
    });

    values.links.forEach(link => {
        newMedia.push({
            content: link.content,
            title: link.title,
            type: link.type,
            format: "link"
        });
    });

    values.additionalLinks.forEach(link => {
        newMedia.push({
            content: link.content,
            title: link.title,
            type: link.type,
            format: "link"
        });
    });

    return {
        medias: newMedia,
        files: newFiles,
        isMediaDiff
    };
}

/**
 * Get the files identification/details which need to be deleted from storage.
 *
 * @param {Object} initialValues the form initial values
 * @param {Object} values the form submitting values
 */
export function getFilesToDelete(initialValues, values) {
    let initialMedia = []
        .concat(initialValues.flyers)
        .concat(initialValues.videos)
        .concat(initialValues.presentations)
        .concat(initialValues.podcasts)
        .concat(initialValues.chatbots)
        .concat(initialValues.images);
    let media = []
        .concat(values.flyers)
        .concat(values.videos)
        .concat(values.presentations)
        .concat(values.podcasts)
        .concat(values.chatbots)
        .concat(values.images);

    let images = initialMedia.filter(
        m =>
            m.image &&
            m.image.url &&
            !(m.image.url instanceof File) &&
            !media.some(nm => nm.image && nm.image.url === m.image.url)
    );
    let files = initialMedia.filter(
        m =>
            m.file &&
            m.file.url &&
            !(m.file.url instanceof File) &&
            !media.some(nm => nm.file && nm.file.url === m.file.url)
    );

    let filesToDelete = [];
    images &&
        images.forEach(image => {
            filesToDelete.push({
                name: image.image.name,
                path: ["chatbot", "image"].includes(image.type)
                    ? "images"
                    : `${image.type}s/images`
            });
        });
    files &&
        files.forEach(file => {
            filesToDelete.push({
                name: file.file.name,
                path: file.format.includes("mp4") ? "videos" : `${file.type}s`
            });
        });

    return filesToDelete;
}

/**
 * Get the files to be deleted from storage.
 *
 * @param {Object} medias the form initial values
 */
export function prepareFilesToDelete(medias) {
    let media = []
        .concat(medias.flyer)
        .concat(medias.videos)
        .concat(medias.presentation)
        .concat(medias.podcast)
        .concat(medias.chatbot)
        .concat(medias.image);

    let images = media.filter(
        m => m.image && m.image.url && !(m.image.url instanceof File)
    );
    let files = media.filter(
        m => m.file && m.file.url && !(m.file.url instanceof File)
    );

    let filesToDelete = [];
    images &&
        images.forEach(image => {
            filesToDelete.push({
                name: image.image.name,
                path: ["chatbot", "image"].includes(image.type)
                    ? "images"
                    : `${image.type}s/images`
            });
        });
    files &&
        files.forEach(file => {
            filesToDelete.push({
                name: file.file.name,
                path: `${file.type}s`
            });
        });

    return filesToDelete;
}
