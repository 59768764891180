// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import {
    FabButtonWithTooltip,
    IconButton,
    MaterialFabButton
} from "../../../../../components/";
import { OfferDetailsTabs } from "./components/";
// Actions
import { navigateTo } from "../../../Router.ducks";
import { clearOfferDetails, getOfferDetails } from "./OfferDetails.ducks";
import { clearOffers, deleteOffer } from "../../Offers.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
// Helpers
import { routesPaths } from "../../../components/Routes/Routes.helper";
import {
    displayWarningPopup,
    prepareFilesToDelete,
    openOfferDetailsFormPopup
} from "../../helpers/offerDetailsFormHelper";
import { openEventDetailsFormPopup } from "./components/Events/helpers/eventDetailsFormHelper";
import { deleteFile } from "../../../../../services/storage";
import {
    getUserRoleEntityId,
    hasPermission
} from "../../helpers/offerPermissions";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./OfferDetails.css";

class OfferDetails extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        clearOfferDetails: PropTypes.func.isRequired,
        clearOffers: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        deleteOffer: PropTypes.func.isRequired,
        details: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        editPopup: PropTypes.object,
        eventDetailsForm: PropTypes.object,
        getOfferDetails: PropTypes.func.isRequired,
        history: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object.isRequired,
        media: PropTypes.object,
        navigateTo: PropTypes.func.isRequired,
        offerDetailsForm: PropTypes.object,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object,
        searchFilter: PropTypes.object,
        tab: PropTypes.string.isRequired,
        userRole: PropTypes.object
    };

    state = {
        tooltipOpened: undefined
    };

    componentDidMount() {
        const {
            clearOfferDetails,
            details,
            getOfferDetails,
            history,
            location,
            match,
            profileData,
            searchFilter,
            tab
        } = this.props;

        const offerUrl = get(match, "params.offerUrl", undefined);

        details &&
            details.url !== offerUrl &&
            clearOfferDetails &&
            clearOfferDetails();

        if (
            (!details || details.url !== offerUrl) &&
            offerUrl &&
            getOfferDetails
        ) {
            const entityId = getUserRoleEntityId(profileData);
            if (entityId === -1) {
                return;
            }

            getOfferDetails(
                offerUrl,
                entityId || get(searchFilter, "legalEntity.value", undefined)
            );
        }

        // If coming back from an event detail, then the events list should has the event in the center of the screen
        const previousPathname = get(location, "state.pathname", "");
        if (
            tab === "events" &&
            previousPathname &&
            previousPathname.includes("/events/") &&
            get(history, "action", undefined) !== "POP"
        ) {
            const previousPathnameSplitted = previousPathname.split("/events/");
            const eventIndex =
                previousPathnameSplitted && previousPathnameSplitted.length > 1
                    ? previousPathnameSplitted[1]
                    : undefined;

            if (eventIndex) {
                const rowElement = document.getElementById(
                    `event-row-${eventIndex}`
                );

                rowElement &&
                    rowElement.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                        inline: "start"
                    });
            }
        }
    }

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    handleClickOnBackIcon = () => {
        const { navigateTo } = this.props;

        navigateTo && navigateTo(routesPaths.offersOverview);
    };

    openEditOfferPopup = () => {
        const {
            closeEditPopup,
            closePopup,
            dispatch,
            details,
            openPopup,
            openEditPopup
        } = this.props;

        openOfferDetailsFormPopup({
            closeEditPopup,
            closePopup,
            dispatch,
            editMode: true,
            getOfferDetailsForm: () => this.props.offerDetailsForm,
            openPopup,
            openEditPopup,
            status: get(details, "status", undefined)
        });
    };

    openAddEventPopup = () => {
        const {
            closeEditPopup,
            closePopup,
            openPopup,
            dispatch,
            openEditPopup
        } = this.props;

        openEventDetailsFormPopup({
            closeEditPopup,
            closePopup,
            dispatch,
            editMode: false,
            getEventDetailsForm: () => this.props.eventDetailsForm,
            openEditPopup,
            openPopup
        });
    };

    handleDeleteOffer = () => {
        const {
            clearOfferDetails,
            clearOffers,
            closePopup,
            deleteOffer,
            details,
            media,
            openPopup,
            navigateTo
        } = this.props;

        const offerUrl = get(details, "url", undefined);

        openPopup({
            children: displayWarningPopup(
                () => {
                    deleteOffer &&
                        deleteOffer(offerUrl, () => {
                            const filesToDelete = prepareFilesToDelete(media);
                            filesToDelete &&
                                filesToDelete.forEach(file => {
                                    deleteFile(file.path, file.name);
                                });

                            clearOffers && clearOffers();

                            closePopup && closePopup();
                            navigateTo &&
                                navigateTo(routesPaths.offersOverview);

                            clearOfferDetails && clearOfferDetails();
                        });
                },
                translation.offerDetails.delete,
                closePopup,
                "error",
                translation.offerDetails.deleteOfferMessage,
                translation.offerDetails.deleteOfferTitle
            )
        });
    };

    handleTooltipOnHover = (tooltip, onHover) => {
        this.setState({ tooltipOpened: onHover ? tooltip : undefined });
    };

    render() {
        const { classes, details, tab, userRole } = this.props;
        const { tooltipOpened } = this.state;
        const seminarsCount = get(details, "seminars", []).length;

        if (!details) {
            return null;
        }

        const offerTitle = get(details, "title", "");

        const tooltipEdit = "edit";
        const tooltipDelete = "delete";

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.offersOverview}
                </div>
                <div style={styles.header.container}>
                    <div style={styles.header.innerContainer}>
                        <div style={styles.header.title}>
                            <IconButton
                                defaultClassName="icon-arrow-back"
                                onClick={this.handleClickOnBackIcon}
                                style={styles.header.backIcon}
                                type={["default"]}
                            />
                            <span>{offerTitle}</span>
                        </div>
                        <span
                            style={styles.header.breadcrums}
                        >{`${translation.navigationMenu.offerManagement} / ${offerTitle} / ${translation.offerDetails.tabs[tab]}`}</span>
                    </div>

                    <div style={styles.header.iconsContainer}>
                        {tab === "details" && (
                            <Fragment>
                                {hasPermission(userRole, "update") && (
                                    <FabButtonWithTooltip
                                        action={this.openEditOfferPopup}
                                        classes={classes}
                                        iconClassName="icon-edit"
                                        handleTooltipOnHover={
                                            this.handleTooltipOnHover
                                        }
                                        tooltipLabel={translation.labels.edit}
                                        tooltipName={tooltipEdit}
                                        tooltipOpened={tooltipOpened}
                                    />
                                )}
                                {hasPermission(userRole, "delete") && (
                                    <FabButtonWithTooltip
                                        action={this.handleDeleteOffer}
                                        classes={classes}
                                        iconClassName="icon-delete"
                                        handleTooltipOnHover={
                                            this.handleTooltipOnHover
                                        }
                                        tooltipLabel={translation.labels.delete}
                                        tooltipName={tooltipDelete}
                                        tooltipOpened={
                                            seminarsCount > 0
                                                ? null
                                                : tooltipOpened
                                        }
                                        disabled={
                                            seminarsCount > 0 ? true : false
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                        {tab === "events" && hasPermission(userRole, "create") && (
                            <MaterialFabButton
                                onClick={() => {
                                    this.openAddEventPopup();
                                }}
                                variant="circular"
                            >
                                <span
                                    className="icon-plus"
                                    style={styles.header.icon}
                                />
                            </MaterialFabButton>
                        )}
                    </div>
                </div>
                <OfferDetailsTabs />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details,
        editPopup: state.editPopup,
        eventDetailsForm: get(state, "form.eventDetails", undefined),
        media: state.offerDetails.media,
        offerDetailsForm: get(state, "form.offerDetails", undefined),
        popup: state.popup,
        profileData: state.profileData,
        searchFilter: state.offers.searchFilter,
        tab: state.offerDetails.tab,
        userRole: state.profileData.role
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                clearOfferDetails,
                clearOffers,
                closeEditPopup,
                closePopup,
                deleteOffer,
                getOfferDetails,
                navigateTo,
                openEditPopup,
                openPopup
            },
            dispatch
        )
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(OfferDetails);
