// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { ExpandableFilter, Filter } from "../../../../../components";
import { RadioFilter } from "../../../components/";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./Filters.css";

const FILTER_CLASSIFICATION = "classification";
const FILTER_STATUS = "status";
const FILTER_LEGAL_ENTITY = "legalEntity";
const NULL_VALUES = ["all", undefined, null];

class Filters extends PureComponent {
    static propTypes = {
        applyFilters: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        searchFilter: PropTypes.object
    };

    handleSetFilter = ({ filter, value }) => {
        const { applyFilters, searchFilter } = this.props;

        if (get(searchFilter, filter, undefined) === value) {
            return;
        }

        applyFilters && applyFilters([{ filter, value }]);
    };

    resetAllFilters = () => {
        const { applyFilters, searchFilter } = this.props;

        let filters = [];

        !NULL_VALUES.includes(
            get(searchFilter, FILTER_CLASSIFICATION, undefined)
        ) &&
            filters.push({
                filter: FILTER_CLASSIFICATION,
                value: "all"
            });
        !NULL_VALUES.includes(get(searchFilter, FILTER_STATUS, undefined)) &&
            filters.push({
                filter: FILTER_STATUS,
                value: "all"
            });
        !NULL_VALUES.includes(
            get(searchFilter, `${FILTER_LEGAL_ENTITY}.value`, undefined)
        ) &&
            filters.push({
                filter: FILTER_LEGAL_ENTITY,
                value: undefined
            });

        filters && filters.length > 0 && applyFilters && applyFilters(filters);
    };

    getFilterValue = filter => {
        const { searchFilter } = this.props;

        switch (filter) {
            case FILTER_LEGAL_ENTITY: {
                return get(searchFilter, `${filter}.value`, undefined);
            }
            default: {
                return get(searchFilter, filter, undefined);
            }
        }
    };

    getFilters = () => {
        const { legalEntities, searchFilter } = this.props;

        let filters = [
            {
                title: translation.offers.classification,
                component: (
                    <RadioFilter
                        getFilterValue={() =>
                            this.getFilterValue(FILTER_CLASSIFICATION)
                        }
                        handleSetFilter={value => {
                            this.handleSetFilter({
                                filter: FILTER_CLASSIFICATION,
                                value
                            });
                        }}
                        options={["all", "nationwide", "regional"]}
                        translationPath={translation.offers.classifications}
                    />
                )
            },
            {
                title: translation.invoicing.status,
                component: (
                    <RadioFilter
                        getFilterValue={() =>
                            this.getFilterValue(FILTER_STATUS)
                        }
                        handleSetFilter={value => {
                            this.handleSetFilter({
                                filter: FILTER_STATUS,
                                value
                            });
                        }}
                        options={["all", "past", "future", "archived"]}
                        translationPath={translation.invoicing.filters}
                    />
                )
            }
        ];

        if (legalEntities && legalEntities.length > 1) {
            filters.unshift({
                title: translation.offers.legalEntity,
                component: (
                    <Filter
                        defaultOption={legalEntities[0]}
                        isClearable={!!this.getFilterValue(FILTER_LEGAL_ENTITY)}
                        isSearchable
                        style={styles.legalEntityFilter}
                        onSelection={value => {
                            this.handleSetFilter({
                                filter: FILTER_LEGAL_ENTITY,
                                value
                            });
                        }}
                        options={legalEntities}
                        value={
                            get(searchFilter, FILTER_LEGAL_ENTITY, undefined) ||
                            legalEntities[0]
                        }
                    />
                )
            });
        }

        return filters;
    };

    render() {
        const filters = this.getFilters();

        return (
            <ExpandableFilter
                filters={filters}
                filtersKeys={[
                    FILTER_LEGAL_ENTITY,
                    FILTER_CLASSIFICATION,
                    FILTER_STATUS
                ]}
                getFilterValue={this.getFilterValue}
                resetAllFilters={this.resetAllFilters}
            />
        );
    }
}

export default Filters;
