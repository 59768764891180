import Global from "../../../../../config/global.css";

const styles = {
    alignRight: {
        justifySelf: "end"
    },
    bookingDate: { paddingLeft: "8px" },
    checkbox: {
        checkbox: {
            backgroundColor: "transparent",
            borderRadius: "2px"
        }
    },
    container: {
        position: "relative"
    },
    expandCollapseButton: {
        transform: "rotate(0deg)",
        transition: "transform 500ms ease"
    },
    expandButton: {
        transform: "rotate(180deg)"
    },
    expansionPanel: {
        details: {
            root: {
                backgroundColor: Global.colors.white,
                borderRadius: "0px 0px 8px 8px",
                margin: "0px",
                padding: "4px 24px 24px"
            }
        },
        expanded: {
            backgroundColor: Global.colors.white,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px !important"
        },
        summary: {
            content: {
                display: "unset"
            },
            expanded: {
                height: "unset !important",
                minHeight: "64px !important"
            },
            root: {
                direction: "unset",
                height: "unset",
                justifyContent: "unset",
                minHeight: "64px"
            }
        }
    },
    dropdownIndicatorContainer: {
        display: "none"
    },
    icon: {
        fontSize: "24px"
    },
    iconsContainer: {
        alignItems: "center",
        display: "grid",
        gridAutoFlow: "column",
        gap: "16px",
        justifyContent: "flex-end"
    },
    increaseRowContainer: {
        display: "flex",
        height: "max-content"
    },
    marker: {
        backgroundColor: Global.colors.digitalBlue,
        bottom: 0,
        borderRadius: "8px",
        height: "100%",
        position: "absolute",
        top: 0,
        width: "4px"
    },
    participants: {
        alignItems: "center",
        background: Global.colors.oldMint,
        borderRadius: "100px",
        color: Global.colors.white,
        display: "flex",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "bold",
        justifyContent: "center",
        lineHeight: "15px",
        height: "32px",
        marginRight: "8px",
        width: "32px"
    },
    participantsDetails: {
        bold: {
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "15px"
        },
        container: {
            color: Global.colors.darkSlateBlue,
            display: "grid",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "normal",
            gap: "6px",
            lineHeight: "15px",
            width: "100%"
        },
        innerContainer: {
            display: "grid",
            gap: "8px"
        },
        label: {
            color: Global.colors.blueyGrey,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "13px",
            textTransform: "uppercase"
        },
        row: {
            display: "grid",
            gap: "12px",
            gridTemplateColumns: "auto auto 1fr",
            paddingLeft: "4px"
        }
    },
    rowContainer: {
        cursor: "default",
        gridAutoFlow: "column",
        minHeight: "64px",
        padding: "16px 24px"
    },
    section: {
        archived: {
            archiveAction: {
                default: {
                    color: `${Global.colors.oldMint} !important`,
                    cursor: "default !important"
                },
                onHover: {
                    backgroundColor: "unset !important"
                }
            },
            participants: {
                backgroundColor: Global.colors.darkPaleGrey
            }
        },
        future: {
            archiveAction: {
                default: {
                    color: `${Global.colors.darkPaleGrey} !important`,
                    cursor: "default !important"
                },
                onHover: {
                    backgroundColor: "unset !important"
                }
            }
        },
        past: {
            archiveAction: {
                color: Global.colors.darkSlateBlue
            }
        }
    },
    supplierContainer: {
        backgroundColor: Global.colors.digitalBlueT4,
        borderRadius: "4px",
        color: Global.colors.darkSlateBlue,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        hyphens: "auto",
        WebkitHyphens: "auto",
        minHeight: "24px",
        padding: "5.5px 8px",
        userSelect: "text",
        WebkitUserSelect: "text",
        width: "fit-content",
        wordBreak: "break-word"
    },
    text: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        userSelect: "text",
        WebkitUserSelect: "text",
        wordBreak: "break-word"
    },
    textAlignRight: {
        justifyContent: "flex-end"
    }
};

export default styles;
