import Global from "../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "74px auto auto auto 74px 74px",
        height: "48px",
        padding: "0 24px"
    },
    titleContainer: {
        alignItems: "center",
        color: Global.colors.blueyGrey,
        display: "flex",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "13px",
        textTransform: "uppercase"
    }
};

export default styles;
