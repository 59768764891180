// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CustomTooltip, IconButton, TableRow } from "../../../../../components";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./UserRow.css";

class CustomEditIconButton extends PureComponent {
    static propTypes = {
        handleTooltipOnHover: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    render() {
        const { handleTooltipOnHover, openEditPopup } = this.props;

        return (
            <IconButton
                defaultClassName="icon-edit"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover && handleTooltipOnHover(false);
                    openEditPopup && openEditPopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    }
}

class UserRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        hasEditPermission: PropTypes.bool,
        openEditPopup: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired
    };

    state = { tooltipOpen: false };

    renderUserPhoto = (photoURL, email) => {
        if (!photoURL) {
            return (
                <span
                    style={{
                        ...styles.userLogoContainer,
                        ...styles.userDefaultLogo
                    }}
                >
                    {email ? email[0] : "U"}
                </span>
            );
        }

        return (
            <img
                alt={"user-logo"}
                style={styles.userLogoContainer}
                src={photoURL}
            />
        );
    };

    renderRole = role => {
        return (
            <span
                style={{
                    ...styles.roleContainer,
                    ...styles.roles[role]
                }}
            >
                {translation.users.roles[role]}
            </span>
        );
    };

    renderStatus = isDisabled => {
        return isDisabled ? (
            <span
                style={{
                    ...styles.statusContainer,
                    ...styles.inactiveContainer
                }}
            >
                {translation.users.inactive}
            </span>
        ) : (
            <span
                style={{
                    ...styles.statusContainer,
                    ...styles.activeContainer
                }}
            >
                {translation.users.active}
            </span>
        );
    };

    handleTooltipOnHover = onHover => {
        this.setState({ tooltipOpen: onHover });
    };

    renderActionButton = () => {
        const { tooltipOpen } = this.state;
        const { hasEditPermission, openEditPopup } = this.props;

        return hasEditPermission ? (
            <CustomTooltip
                onClose={() => this.handleTooltipOnHover(false)}
                onOpen={() => this.handleTooltipOnHover(true)}
                open={tooltipOpen}
                placement="top"
                title={translation.offersKeywordsTagging.edit}
            >
                <span>
                    <CustomEditIconButton
                        handleTooltipOnHover={this.handleTooltipOnHover}
                        openEditPopup={openEditPopup}
                    />
                </span>
            </CustomTooltip>
        ) : null;
    };

    renderRow = row => {
        const photoURL = get(row, "photoURL", undefined);
        const displayName = get(row, "displayName", "").split(" ");

        const firstName = displayName[0];
        const lastName =
            displayName.length > 0 ? displayName.slice(1).join(" ") : "";

        const email = get(row, "email", "");
        const isDisabled = get(row, "disabled", true);
        const role = get(row, "role", true);

        return (
            <Fragment>
                {this.renderUserPhoto(photoURL, email)}
                <div style={styles.rowBlock}>{firstName}</div>
                <div style={styles.rowBlock}>{lastName}</div>
                <div style={styles.rowBlock}>{email}</div>
                {this.renderRole(role)}
                {this.renderStatus(isDisabled)}
                {this.renderActionButton()}
            </Fragment>
        );
    };

    render() {
        const { containerStyle, user } = this.props;
        return (
            <TableRow
                containerStyle={{ ...containerStyle, ...styles.rowContainer }}
            >
                {this.renderRow(user)}
            </TableRow>
        );
    }
}

export default UserRow;
