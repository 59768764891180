import Global from "../../../../../../config/global.css";

const styles = {
    item: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative"
    },
    itemHover: {
        height: "max-content",
        zIndex: 1
    },
    itemVisible: {
        hyphens: "auto",
        MozHyphens: "auto",
        WebkitHyphens: "auto",
        padding: "16px",
        wordBreak: "break-word",
        textAlign: "start"
    },
    subtitle: {
        color: Global.colors.blueyGrey,
        display: "flex",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "1.45em",
        marginTop: "8px"
    },
    subtitleEllipsis: {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical"
    },
    title: {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "1.45em"
    },
    titleEllipsis: {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 5,
        WebkitBoxOrient: "vertical"
    },
    titleWithoutShortDescription: {
        display: "-webkit-box",
        maxHeight: "unset",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 7,
        WebkitBoxOrient: "vertical"
    }
};

export default styles;
