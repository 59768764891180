// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import {
    change,
    Field,
    formValueSelector,
    reduxForm,
    reset,
    stopSubmit
} from "redux-form";
// Components
import {
    FileInput,
    InfoMessage,
    MaterialButton,
    MaterialTextField
} from "../../../../../components";
// Actions
import {
    addError,
    checkCurrentPassword,
    clearError,
    removePhoto,
    updateName,
    updatePassword,
    uploadPhoto
} from "../../Settings.ducks";
// Helper
import {
    areNewPasswordsEqual,
    arePasswordsFieldsValid,
    isPasswordUpdateNeeded,
    checkAndUpdatePassword,
    updatePhotoAndName
} from "./SettingsForm.helper";
import {
    dataPolicyLink,
    openLicenseAgreementLink,
    openTermsLink
} from "../../../../../services/legalLinks";
// Validator
import { required } from "../../../../../services/validators";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./SettingsForm.css";

async function onSubmit(values, props) {
    const { addError, clearError, settingsError } = props;

    if (!arePasswordsFieldsValid(values, props)) {
        return;
    }

    get(settingsError, "message", undefined) && clearError && clearError();

    if (isPasswordUpdateNeeded(values)) {
        if (areNewPasswordsEqual(values)) {
            await checkAndUpdatePassword(values, props);
        } else {
            addError &&
                addError(translation.settings.errorMessage.passwordsDoNotMatch);
        }
    } else {
        updatePhotoAndName(values, props);
    }
}

class SettingsForm extends PureComponent {
    static propTypes = {
        actions: PropTypes.node,
        addError: PropTypes.func.isRequired,
        auth: PropTypes.object,
        change: PropTypes.func.isRequired,
        checkCurrentPassword: PropTypes.func.isRequired,
        clearError: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        editMode: PropTypes.bool,
        handleChangeEditMode: PropTypes.func.isRequired,
        photoURL: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        settingsError: PropTypes.object,
        removePhoto: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        updateName: PropTypes.func.isRequired,
        updatePassword: PropTypes.func.isRequired,
        uploadPhoto: PropTypes.func.isRequired
    };

    state = {
        linkOnHover: undefined
    };

    componentDidUpdate(prevProps) {
        const { clearError, dispatch, editMode, settingsError } = this.props;
        if (editMode !== prevProps.editMode && !editMode) {
            dispatch(reset("settings"));

            get(settingsError, "message", undefined) &&
                clearError &&
                clearError();
        }
    }

    componentWillUnmount() {
        const { clearError, settingsError } = this.props;

        get(settingsError, "message", undefined) && clearError && clearError();
    }

    renderUserPhoto = () => {
        const { auth, change, dispatch, editMode, photoURL } = this.props;

        const photo = editMode ? photoURL : get(auth, "photoURL", undefined);

        return (
            <div
                onClick={() => {
                    editMode && this.logoRef && this.logoRef.click();
                }}
                onDrop={event => {
                    event.preventDefault();
                    if (editMode) {
                        const files = get(
                            event,
                            "dataTransfer.items",
                            undefined
                        );
                        let file = files && files[0];
                        if (file && file.type && file.type.match("image/*")) {
                            file = file.getAsFile();

                            dispatch(change("photoURL", file));
                        }
                    }
                }}
                onDragOver={event => {
                    event.preventDefault();
                }}
                style={{
                    ...styles.logo,
                    ...(photo
                        ? {}
                        : {
                              ...styles.logoDefault,
                              ...styles.logoDefaultContainer,
                              ...(editMode
                                  ? styles.logoDefaultEditContainer
                                  : {})
                          }),
                    ...(editMode ? styles.cursor : {})
                }}
            >
                {photo && (
                    <img
                        alt={"user-logo"}
                        style={{ ...styles.logo, ...styles.logoImage }}
                        src={
                            photo instanceof File
                                ? URL.createObjectURL(photo)
                                : photo
                        }
                    />
                )}
                <div
                    style={{
                        ...styles.logo,
                        ...styles.logoDefault,
                        ...(editMode ? styles.logoDefaultEdit : {})
                    }}
                >
                    <span style={styles.logoInnerContainer}>
                        {translation.settings.logoTitle}
                        <MaterialButton
                            containerStyle={styles.logoRemoveButtonContainer}
                            customType="link"
                            disabled={!editMode}
                            disableRipple
                            key="remove-photo"
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();

                                photo && dispatch(change("photoURL", null));
                            }}
                            style={{
                                ...styles.logoRemoveButton,
                                ...(editMode ? styles.logoDefaultEdit : {})
                            }}
                        >
                            {translation.settings.logoTitleRemove}
                        </MaterialButton>
                        .
                    </span>
                    <Field
                        accept="image/*"
                        component={FileInput}
                        inputRef={ref => {
                            this.logoRef !== ref && (this.logoRef = ref);
                        }}
                        name="photoURL"
                        ref={ref => {
                            !this.logoFieldRef && (this.logoFieldRef = ref);
                        }}
                        style={styles.uploadLogoInput}
                    />
                </div>
            </div>
        );
    };

    renderError = () => {
        const { clearError, settingsError } = this.props;

        const errorMessage = get(settingsError, "message", undefined);
        if (errorMessage) {
            return (
                <InfoMessage
                    endAdornment={
                        <span
                            className="icon-close"
                            key="info-message-icon"
                            onClick={clearError}
                            style={styles.closeIcon}
                        />
                    }
                    icon={"default"}
                    key="info-message"
                    message={errorMessage}
                    style={styles.infoMessage}
                    type={"error"}
                />
            );
        } else {
            return null;
        }
    };

    updateLinkOnHover = label => {
        this.setState({ linkOnHover: label });
    };

    renderLink = (label, action) => {
        const { linkOnHover } = this.state;

        return (
            <MaterialButton
                customType="link"
                defaultClassName={label}
                disableRipple
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.updateLinkOnHover(undefined);

                    action && action();
                }}
                onMouseEnter={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.updateLinkOnHover(label);
                }}
                onMouseLeave={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.updateLinkOnHover(undefined);
                }}
                style={{
                    ...styles.legalContainer.linkButton,
                    ...(linkOnHover === label
                        ? styles.legalContainer.linkButtonOnHover
                        : {})
                }}
            >
                {label}
            </MaterialButton>
        );
    };

    render() {
        const { actions, editMode, settingsError } = this.props;

        return (
            <form style={styles.container}>
                <div style={styles.settingsContainer}>
                    <div style={styles.settingsInnerContainer}>
                        <div style={styles.title}>
                            {editMode
                                ? translation.profile.editAccount
                                : translation.settings.profileInformation}
                        </div>
                        <div style={styles.profileInformation}>
                            {this.renderUserPhoto()}
                            <div style={styles.settingsNameContainer}>
                                <Field
                                    component={MaterialTextField}
                                    disabled={!editMode}
                                    label={translation.settings.firstName}
                                    name="firstName"
                                    style={styles.field}
                                    variant="outlined"
                                    validate={required}
                                />
                                <Field
                                    component={MaterialTextField}
                                    disabled={!editMode}
                                    label={translation.settings.lastName}
                                    name="lastName"
                                    style={styles.field}
                                    variant="outlined"
                                    validate={required}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={styles.settingsInnerContainer}>
                        <div style={styles.subtitle}>
                            {translation.settings.changePassword}
                        </div>
                        {this.renderError()}
                        <div style={styles.settingsPasswordsContainer}>
                            <Field
                                component={MaterialTextField}
                                disabled={!editMode}
                                error={get(settingsError, "fields", []).some(
                                    field => field === "password"
                                )}
                                label={`${translation.settings.currentPassword}*`}
                                name="password"
                                type={"password"}
                                style={styles.field}
                                variant="outlined"
                            />
                            <Field
                                component={MaterialTextField}
                                disabled={!editMode}
                                error={get(settingsError, "fields", []).some(
                                    field => field === "newPassword"
                                )}
                                label={`${translation.settings.newPassword}*`}
                                name="newPassword"
                                type={"password"}
                                style={styles.field}
                                variant="outlined"
                            />
                            <Field
                                component={MaterialTextField}
                                disabled={!editMode}
                                error={get(settingsError, "fields", []).some(
                                    field => field === "newPasswordConfirmation"
                                )}
                                label={`${translation.settings.confirmPassword}*`}
                                name="newPasswordConfirmation"
                                type={"password"}
                                style={styles.field}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    {!editMode && (
                        <div style={styles.legalContainer.container}>
                            <div style={styles.subtitle}>
                                {translation.consents.title}
                            </div>
                            {this.renderLink(translation.consents.terms, () => {
                                openTermsLink();
                            })}
                            {this.renderLink(
                                translation.consents.dataPolicy,
                                () => {
                                    window.open(dataPolicyLink, "_blank");
                                }
                            )}
                            {this.renderLink(
                                translation.consents.licenseAgreement,
                                () => {
                                    openLicenseAgreementLink();
                                }
                            )}
                        </div>
                    )}
                </div>
                <div style={styles.actionButtonsContainer}>{actions}</div>
            </form>
        );
    }
}

const selector = formValueSelector("settings");

const mapStateToProps = state => {
    const auth = state.firebase.auth;
    const settingsError = state.settings.error;

    const photoURL = get(auth, "photoURL", undefined);
    const displayName = get(auth, "displayName", undefined);
    const displayNameArray = displayName ? displayName.split(" ") : [];
    const firstName = displayNameArray[0];
    const lastName =
        displayNameArray.length > 0 ? displayNameArray.slice(1).join(" ") : "";

    return {
        auth,
        initialValues: {
            firstName,
            lastName,
            photoURL
        },
        photoURL: selector(state, "photoURL"),
        settingsError
    };
};

const mapDispatchToProps = {
    addError,
    change,
    checkCurrentPassword,
    clearError,
    removePhoto,
    reset,
    stopSubmit,
    updateName,
    updatePassword,
    uploadPhoto
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "settings",
        enableReinitialize: true,
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(SettingsForm)
);
