// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import debounce from "lodash/debounce";
// Components
import {
    Checkbox,
    Filter,
    InfiniteScrollLoading,
    SearchBar,
    TableHeaders
} from "../../../../../components";
import { OffersFilters } from "../../../components/";
import {
    FILTER_CLASSIFICATION,
    FILTER_LEGAL_ENTITY
} from "../../../components/OffersFilters/OffersFilters";
import InfiniteScroll from "react-infinite-scroller";
import { BulkClassificationAction, ExpandableRow } from "../";
// Actions
import {
    clearSearchFilter,
    clearSearchQuery,
    saveSearchFilter,
    saveSearchQuery,
    updateOfferClassification,
    updateOffersClassificationBulk,
    updateOfferStatus,
    updateOffersStatusBulk
} from "../../OffersClassification.ducks";
// Helpers
import { getActions } from "./helpers/actions";
import { applyFilters, getFilterOptions } from "./helpers/searchFilters";
// Translations
import translation from "../../../../../config/translation";
// Styles
import styles from "./BaseClassification.css";

class BaseClassification extends PureComponent {
    static propTypes = {
        action: PropTypes.object,
        additionalFilters: PropTypes.array,
        availableClassifications: PropTypes.array,
        clearSearchFilter: PropTypes.func,
        clearSearchQuery: PropTypes.func,
        expandFirstRow: PropTypes.bool,
        handleLoadMoreOffers: PropTypes.func.isRequired,
        handleLoadOffers: PropTypes.func.isRequired,
        hasSearch: PropTypes.bool,
        legalEntities: PropTypes.array,
        offers: PropTypes.array,
        openPopup: PropTypes.func,
        saveSearchFilter: PropTypes.func,
        saveSearchQuery: PropTypes.func,
        searchFilter: PropTypes.object,
        searchQuery: PropTypes.string,
        tab: PropTypes.string.isRequired,
        tableHeaders: PropTypes.array,
        tableHeadersStyle: PropTypes.object,
        topbarContainerStyle: PropTypes.object,
        totalCount: PropTypes.number,
        updateOfferClassification: PropTypes.func,
        updateOffersClassificationBulk: PropTypes.func,
        updateOfferStatus: PropTypes.func,
        updateOffersStatusBulk: PropTypes.func
    };

    state = {
        bulkSelection: []
    };

    tableHeaders = [
        translation.offersClassification.tableHeaders.title,
        translation.offersClassification.tableHeaders.supplier
    ];

    actions = getActions(this.props);

    handleScrollToTop = () => {
        const element = document.querySelector(".infinite-scroll");
        element?.scrollIntoView({ behavior: "smooth" });
    };

    componentDidUpdate(prevProps) {
        const prevOffers = JSON.stringify(get(prevProps, "offers", []));
        const offers = JSON.stringify(get(this.props, "offers", []));

        const { bulkSelection } = this.state;
        if (prevOffers !== offers) {
            // Clearing offers bulk selected which are not displayed anymore due to filter/updates
            const bulkSelectionFiltered =
                (bulkSelection &&
                    bulkSelection.filter(offer =>
                        get(this.props, "offers", []).find(
                            el => get(el, "_id", undefined) === offer
                        )
                    )) ||
                [];

            bulkSelectionFiltered.length !== bulkSelection.length &&
                this.setState({
                    bulkSelection: bulkSelectionFiltered
                });
        }
    }

    componentWillUnmount() {
        const { clearSearchFilter, clearSearchQuery } = this.props;

        clearSearchQuery && clearSearchQuery();
        clearSearchFilter && clearSearchFilter();

        this.debounceSearchOnChange && this.debounceSearchOnChange.cancel();
    }

    updateBulkSelection = ({ operation, offerId }) => {
        const { bulkSelection } = this.state;
        const { offers } = this.props;

        let newBulkSelection = [];
        if (!["unselectAll", "selectAll"].includes(operation) && !offerId) {
            return;
        } else if (operation === "selectAll") {
            newBulkSelection = offers
                ? offers.map(offer => get(offer, "_id", undefined))
                : [];
        } else if (operation === "select") {
            newBulkSelection = [...bulkSelection, offerId];
        } else if (operation === "unselect") {
            newBulkSelection = bulkSelection.filter(offer => offerId !== offer);
        }

        this.setState({
            bulkSelection: newBulkSelection
        });
    };

    handleGroupSelection = (isChecked, isGroupIndeterminate) => {
        if (isChecked || isGroupIndeterminate) {
            this.updateBulkSelection({
                operation: "unselectAll"
            });
        } else {
            this.updateBulkSelection({
                operation: "selectAll"
            });
        }
    };

    handleSearch = value => {
        const { saveSearchQuery, searchFilter, searchQuery } = this.props;

        const newValue = (value && value.trim()) || "";
        if (newValue !== searchQuery) {
            saveSearchQuery && saveSearchQuery(newValue);

            this.loadEntries(newValue, searchFilter);
        }
    };

    debounceSearchOnChange = debounce(this.handleSearch, 500);

    loadEntries = (query, filters) => {
        const { handleLoadOffers } = this.props;

        handleLoadOffers({
            fromStart: true,
            title: query,
            classification: get(filters, "classification", undefined),
            entity_id: get(filters, "legalEntity.value", undefined)
        });
    };

    applyFilters = filtersValues => {
        const { saveSearchFilter, searchFilter, searchQuery } = this.props;
        applyFilters({
            filtersValues,
            loadEntries: this.loadEntries,
            saveSearchFilter,
            searchFilter,
            searchQuery
        });
    };

    renderFilters = () => {
        const { additionalFilters, legalEntities, searchFilter } = this.props;

        const filterOptions = getFilterOptions(legalEntities);

        return (
            <Fragment>
                {additionalFilters &&
                additionalFilters.length === 1 &&
                additionalFilters.includes("classification") ? (
                    <OffersFilters
                        applyFilters={this.applyFilters}
                        defaultFilters={[
                            FILTER_LEGAL_ENTITY,
                            FILTER_CLASSIFICATION
                        ]}
                        defaultLegalEntity={filterOptions && filterOptions[0]}
                        legalEntities={filterOptions}
                        searchFilter={searchFilter}
                    />
                ) : filterOptions && filterOptions.length > 1 ? (
                    <Filter
                        defaultOption={filterOptions && filterOptions[0]}
                        isClearable={
                            !!get(searchFilter, "legalEntity.value", undefined)
                        }
                        isSearchable
                        onSelection={value =>
                            this.applyFilters([
                                { filter: "legalEntity", value }
                            ])
                        }
                        options={filterOptions}
                    />
                ) : null}
            </Fragment>
        );
    };

    getAvailableActions = () => {
        const { availableClassifications } = this.props;

        return availableClassifications
            ? this.actions.filter(action =>
                  availableClassifications.includes(action.key)
              )
            : undefined;
    };

    renderRow = (offer, index) => {
        const { action, expandFirstRow } = this.props;
        const { bulkSelection } = this.state;

        const availableActions = this.getAvailableActions();

        const id = get(offer, "_id", undefined);
        const externalLink = get(offer, "external_link", undefined);
        const enrolmentLink = get(offer, "enrolment_link", undefined);

        const classification = get(offer, "classification", undefined);

        const isChecked = bulkSelection && bulkSelection.includes(id);
        const disableExpandCollapse = bulkSelection && bulkSelection.length > 0;

        let gridTemplateColumns = classification
            ? styles.tableGridData.withClassification
            : styles.tableGridData.base;

        gridTemplateColumns +=
            " " +
            (externalLink || enrolmentLink
                ? styles.tableGridActions.withExternalLink
                : styles.tableGridActions.base);

        let tableGridStyle = {
            ...styles.tableGrid,
            gridTemplateColumns
        };
        action && (tableGridStyle.gridTemplateColumns += " 32px");
        !disableExpandCollapse &&
            (tableGridStyle.gridTemplateColumns += " 32px");

        return (
            <ExpandableRow
                action={action}
                containerStyle={tableGridStyle}
                disableExpandCollapse={disableExpandCollapse}
                expandableActions={{
                    label: translation.offersClassification.classify,
                    options: availableActions
                }}
                isChecked={isChecked}
                isRowDefaultExpanded={expandFirstRow && index === 0}
                key={index}
                offer={offer}
                toggleBulkSelection={() => {
                    this.updateBulkSelection({
                        operation: isChecked ? "unselect" : "select",
                        offerId: id
                    });
                }}
            />
        );
    };

    render() {
        const {
            action,
            handleLoadMoreOffers,
            hasSearch,
            offers,
            searchFilter,
            searchQuery,
            tab,
            tableHeaders,
            tableHeadersStyle,
            topbarContainerStyle,
            totalCount
        } = this.props;
        const { bulkSelection } = this.state;

        const label = translation.offersClassification.tabs[tab];

        const disableExpandCollapse = bulkSelection && bulkSelection.length > 0;

        let tableGridStyle = {
            ...styles.tableGrid,
            ...styles.tableHeaders,
            ...tableHeadersStyle
        };
        action && (tableGridStyle.gridTemplateColumns += " 32px");
        !disableExpandCollapse &&
            (tableGridStyle.gridTemplateColumns += " 32px");

        const isGroupChecked =
            (bulkSelection && bulkSelection.length) ===
            (offers && offers.length);
        const isGroupIndeterminate =
            bulkSelection &&
            bulkSelection.length > 0 &&
            (bulkSelection && bulkSelection.length) < (offers && offers.length);

        const bulkActionElementHeight = disableExpandCollapse
            ? get(document.getElementById("bulk-action"), "clientHeight", 0)
            : 0;

        return (
            <div>
                <div style={styles.headerTitle}>
                    {`${translation.navigationMenu.offersClassification} — ${label}`}
                </div>
                {hasSearch && (
                    <div
                        style={{
                            ...styles.topbarContainer,
                            ...topbarContainerStyle
                        }}
                    >
                        <SearchBar
                            icon="icon-search"
                            onChange={this.debounceSearchOnChange}
                            placeholder={translation.offers.search}
                            style={{
                                container: styles.searchBarContainer,
                                input: styles.searchBarInput
                            }}
                        />
                        {this.renderFilters()}
                    </div>
                )}
                <TableHeaders
                    containerStyle={tableGridStyle}
                    headers={[
                        offers && offers.length > 0 ? (
                            <Checkbox
                                checked={isGroupChecked}
                                indeterminate={isGroupIndeterminate}
                                onClick={() =>
                                    this.handleGroupSelection(
                                        isGroupChecked,
                                        isGroupIndeterminate
                                    )
                                }
                                style={styles.checkbox}
                            />
                        ) : (
                            <div />
                        ),
                        ...(tableHeaders || this.tableHeaders)
                    ]}
                />
                <div
                    className="infinite-scroll-container"
                    style={{
                        ...styles.rowsContainer,
                        ...(hasSearch
                            ? {
                                  height: `calc(100vh - ${
                                      350 + bulkActionElementHeight
                                  }px)`
                              }
                            : {})
                    }}
                >
                    <InfiniteScroll
                        className="infinite-scroll"
                        hasMore={totalCount > (offers ? offers.length : 0)}
                        initialLoad={true}
                        loader={<InfiniteScrollLoading key="loading" />}
                        loadMore={() =>
                            handleLoadMoreOffers({
                                title: searchQuery,
                                classification: get(
                                    searchFilter,
                                    "classification",
                                    undefined
                                ),
                                entity_id: get(
                                    searchFilter,
                                    "legalEntity.value",
                                    undefined
                                )
                            })
                        }
                        threshold={500}
                        useWindow={false}
                        style={styles.infineScroll}
                        getScrollParent={() =>
                            document.getElementsByClassName(
                                "infinite-scroll-container"
                            )[0]
                        }
                    >
                        {offers &&
                            offers.map((offer, index) =>
                                this.renderRow(offer, index)
                            )}
                    </InfiniteScroll>
                </div>
                <BulkClassificationAction
                    bulkSelection={bulkSelection}
                    classifications={{
                        label: translation.offersClassification.classify,
                        options: this.getAvailableActions()
                    }}
                    display={disableExpandCollapse}
                    updateBulkSelection={this.updateBulkSelection}
                    handleScrollToTop={this.handleScrollToTop}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const tab = get(props, "tab", undefined);
    return {
        legalEntities: state.offersClassification.legalEntities,
        offers: get(
            state,
            `offersClassification.offersByTab.${tab}.offers`,
            []
        ),
        searchFilter: state.offersClassification.searchFilter,
        searchQuery: state.offersClassification.searchQuery,
        totalCount: get(
            state,
            `offersClassification.offersByTab.${tab}.totalCount`,
            undefined
        )
    };
};

const mapDispatchToProps = {
    clearSearchFilter,
    clearSearchQuery,
    saveSearchFilter,
    saveSearchQuery,
    updateOfferClassification,
    updateOffersClassificationBulk,
    updateOfferStatus,
    updateOffersStatusBulk
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseClassification);
