// Modules
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import merge from "lodash.merge";
// Components
import { NavigationBar, Routes, TopBar } from "./components";
import {
    CustomSnackBar,
    Dashboard,
    EditPopup,
    MaterialSpinner,
    Popup,
    ViewPopup
} from "../../components";
// Styles
import styles from "./Router.css";

class Router extends PureComponent {
    static propTypes = {
        editPopupOpened: PropTypes.bool,
        location: PropTypes.object,
        viewPopupOpened: PropTypes.bool
    };

    render() {
        const { editPopupOpened, location, viewPopupOpened } = this.props;

        const mergedDashboardStyle =
            editPopupOpened || viewPopupOpened
                ? merge(
                      JSON.parse(JSON.stringify(styles.dashboard)),
                      JSON.parse(JSON.stringify(styles.dashboardPopupOpened))
                  )
                : styles.dashboard;

        return (
            <Fragment>
                <MaterialSpinner />
                <CustomSnackBar />
                <Popup />
                <Dashboard
                    leftBar={
                        <NavigationBar
                            key="navigation-bar"
                            location={location}
                        />
                    }
                    style={mergedDashboardStyle}
                    topBar={<TopBar key="top-bar" location={location} />}
                >
                    <EditPopup />
                    <ViewPopup />
                    <Routes />
                </Dashboard>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        editPopupOpened: state.editPopup.opened,
        viewPopupOpened: state.viewPopup.opened
    };
};

export default connect(mapStateToProps)(Router);
