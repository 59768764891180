// Modules
import get from "lodash.get";
import isEqual from "lodash.isequal";
// Translation
import translation from "../../../../config/translation";

/**
 * Builds the email data to be triggered an email to the DIHK Admins and respective IHK Admins.
 *
 * @param {Object} user the initial user data
 * @param {Array} legalEntities the legal entities
 * @param {Object} values the new user data
 * @returns the email data
 */
export function buildEmailData(
    user,
    legalEntities,
    cooperationPartner,
    values
) {
    let oldEntity = get(user, "entity_id", undefined);
    oldEntity &&
        (oldEntity =
            (legalEntities && legalEntities.find(le => le._id === oldEntity)) ||
            (cooperationPartner &&
                cooperationPartner.find(le => le._id === oldEntity)));

    oldEntity && (oldEntity = get(oldEntity, "name", undefined));

    const newFirstName = get(values, "firstName", undefined);
    const newLastName = get(values, "lastName", undefined);

    const oldData = {
        name: get(user, "displayName", undefined),
        email: get(user, "email", undefined),
        role: translation.users.roles[get(user, "role", undefined)],
        legalEntity: oldEntity || undefined,
        legalEntityId: get(user, "entity_id", undefined) || undefined
    };

    let emailData = {
        oldData,
        newData: {
            name:
                newFirstName && newLastName
                    ? `${newFirstName} ${newLastName}`
                    : get(oldData, "displayName", undefined),
            email:
                get(values, "email", undefined) ||
                get(oldData, "email", undefined),
            role:
                translation.users.roles[get(values, "role", undefined)] ||
                get(oldData, "role", undefined),
            legalEntity:
                get(values, "entity.label", get(values, "entity", undefined)) ||
                (values ? undefined : get(oldData, "legalEntity", undefined)) ||
                undefined,
            legalEntityId:
                get(
                    values,
                    "entityId",
                    values
                        ? undefined
                        : get(oldData, "legalEntityId", undefined)
                ) || undefined
        }
    };

    if (!values) {
        emailData.newData = {
            name: undefined,
            email: undefined,
            role: undefined,
            legalEntity: undefined,
            legalEntityId: undefined
        };
    }
    if (!user) {
        emailData.oldData = {
            name: undefined,
            email: undefined,
            role: undefined,
            legalEntity: undefined,
            legalEntityId: undefined
        };
    }

    let newDataBase = { ...emailData.newData };
    delete newDataBase["legalEntityId"];

    if (isEqual(emailData.oldData, emailData.newData)) {
        emailData = undefined;
    }

    return emailData;
}
