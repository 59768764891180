import Global from "../../../../../config/global.css";

const styles = {
    button: {
        fontSize: "18px",
        height: "25px",
        width: "25px"
    },
    title: {
        fontSize: "14px",
        color: Global.colors.blueyGrey,
        display: "-webkit-box",
        overflow: "hidden",
        fontWeight: "normal",
        lineHeight: "18px",
        textTransform: "uppercase",
        textOverflow: "ellipsis",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        width: "80%",
        padding: "8px",
        marginTop: "10px",
        height: "60px"
    },
    url: {
        root: {
            fontWeight: "600",

            "& span": {
                display: "block",
                overflow: "hidden",
                textAlign: "initial",
                textOverflow: "ellipsis",
                width: "200px",
                whiteSpace: "nowrap"
            }
        }
    }
};

export default styles;
