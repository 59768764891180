// Modules
import moment from "moment";
import "moment/locale/de";
// Translation
import translation from "../config/translation";

export function formatDateWithFormat(date, format, newFormat) {
    return moment(date, format).format(newFormat);
}

export function formatDate(date, format) {
    return moment(date, "YYYY-MM-DD").format(format);
}

export function formatTime(time, format) {
    return moment(time, "HH:mm:ss").format(format);
}

export function formatTimestamp(date, format) {
    return moment(date, "YYYY-MM-DDTHH:mm:ss").format(format);
}

export function formatTimestampDETimezone(date, format) {
    const momentTz = require("moment-timezone");

    return momentTz
        .utc(date, "YYYY-MM-DDTHH:mm:ss")
        .tz("Europe/Berlin")
        .format(format);
}

export function changeFormat(date, format) {
    return moment(date).format(format);
}

export function changeFormatFrom(date, oldFormat, format) {
    return moment(date, oldFormat).format(format);
}

export function findDifferentPerMonthForTwoDates(date1, date2, format) {
    return (
        moment(date2, format).format("MM") - moment(date1, format).format("MM")
    );
}

export function findDifferentPerYearForTwoDates(date1, date2, format) {
    return (
        moment(date2, format).format("YYYY") -
        moment(date1, format).format("YYYY")
    );
}

export function getTimestamp(date) {
    return moment(date, "YYYY-MM-DDTHH:mm:ss").toDate();
}

export function formatDateToDEFormat(date) {
    if (!date) {
        return translation.event.onRequest;
    }

    return formatTimestamp(date, "D. MMMM YYYY");
}
