// Modules
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
// Components
import { CircularProgress } from "../../../../../../../../../../../components";
// Translation
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./EmptyPlayer.css";

const EmptyPlayer = ({ video, triggerVideoRefresh }) => {
    const [loading, setLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const useStyles = makeStyles(() => ({
        button: styles.button
    }));

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    const clearTimeoutAndHideOverlay = () => {
        clearTimeout(timeoutId);
        setLoading(false);
    };

    const handleOnClick = () => {
        setLoading(true);
        triggerVideoRefresh(video);

        const newTimeoutId = setTimeout(() => {
            clearTimeoutAndHideOverlay();
        }, 3000);

        setTimeoutId(newTimeoutId);
    };

    const classes = useStyles();

    return (
        <div style={styles.container}>
            <h4>{translation.offerDetails.details.videoProccesing}</h4>

            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M36 0H4C1.83333 0 0 1.83333 0 4V36C0 38.1667 1.83333 40 4 40H36C38.25 40 40 38.1667 40 36V4C40 1.83333 38.1667 0 36 0ZM8.5 35.6667C8.5 36.5 7.83333 37.25 6.91667 37.25H4.33333C3.5 37.25 2.75 36.5833 2.75 35.6667V33.0833C2.75 32.25 3.41667 31.5 4.33333 31.5H6.91667C7.75 31.5 8.5 32.1667 8.5 33.0833V35.6667ZM8.5 26.0833C8.5 26.9167 7.83333 27.6667 6.91667 27.6667H4.33333C3.5 27.6667 2.75 27 2.75 26.0833V23.5C2.75 22.6667 3.41667 21.9167 4.33333 21.9167H6.91667C7.75 21.9167 8.5 22.5833 8.5 23.5V26.0833ZM8.5 16.5C8.5 17.3333 7.83333 18.0833 6.91667 18.0833H4.33333C3.5 18.0833 2.75 17.4167 2.75 16.5V13.9167C2.75 13.0833 3.41667 12.3333 4.33333 12.3333H6.91667C7.75 12.3333 8.5 13 8.5 13.9167V16.5ZM8.5 6.91667C8.5 7.75 7.83333 8.5 6.91667 8.5H4.33333C3.5 8.5 2.75 7.83333 2.75 6.91667V4.33333C2.75 3.5 3.41667 2.75 4.33333 2.75H6.91667C7.75 2.75 8.5 3.41667 8.5 4.33333V6.91667ZM25.1667 21.0833L17.8333 26.0833C16.9167 26.6667 15.75 26.0833 15.75 25V15C15.75 13.9167 16.9167 13.25 17.8333 13.9167L25.1667 18.9167C26 19.4167 26 20.5833 25.1667 21.0833ZM37.1667 35.6667C37.1667 36.5 36.5 37.25 35.5833 37.25H33C32.1667 37.25 31.4167 36.5833 31.4167 35.6667V33.0833C31.4167 32.25 32.0833 31.5 33 31.5H35.5833C36.4167 31.5 37.1667 32.1667 37.1667 33.0833V35.6667ZM37.1667 26.0833C37.1667 26.9167 36.5 27.6667 35.5833 27.6667H33C32.1667 27.6667 31.4167 27 31.4167 26.0833V23.5C31.4167 22.6667 32.0833 21.9167 33 21.9167H35.5833C36.4167 21.9167 37.1667 22.5833 37.1667 23.5V26.0833ZM37.1667 16.5C37.1667 17.3333 36.5 18.0833 35.5833 18.0833H33C32.1667 18.0833 31.4167 17.4167 31.4167 16.5V13.9167C31.4167 13.0833 32.0833 12.3333 33 12.3333H35.5833C36.4167 12.3333 37.1667 13 37.1667 13.9167V16.5ZM37.1667 6.91667C37.1667 7.75 36.5 8.5 35.5833 8.5H33C32.1667 8.5 31.4167 7.83333 31.4167 6.91667V4.33333C31.4167 3.5 32.0833 2.75 33 2.75H35.5833C36.4167 2.75 37.1667 3.41667 37.1667 4.33333V6.91667Z"
                    fill="#23366C"
                />
            </svg>

            <button className={classes.button} onClick={handleOnClick}>
                <svg
                    width="20"
                    height="10"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.48571 3.9686C9.30426 3.67828 8.92926 3.5815 8.62685 3.75086C8.32443 3.93231 8.23975 4.3194 8.4212 4.62182C8.84459 5.31133 9.05023 6.1339 8.96555 7.01696C8.7841 8.87984 7.24783 10.3556 5.38494 10.4887C3.12286 10.646 1.23578 8.83146 1.25998 6.60567C1.28417 4.53714 2.9777 2.81941 5.04623 2.78312C5.05833 2.78312 5.07043 2.78312 5.07043 2.78312V3.70247C5.07043 3.9686 5.36075 4.13795 5.59058 3.99279L8.0704 2.44442C8.28814 2.31135 8.28814 1.99684 8.0704 1.86378L5.60268 0.303308C5.37284 0.158148 5.08252 0.327501 5.08252 0.593628V1.52507C2.14303 1.54926 -0.20372 4.04118 0.0140202 7.02905C0.19547 9.53306 2.21561 11.5532 4.71962 11.7347C7.7196 11.9645 10.2236 9.58145 10.2236 6.62986C10.2357 5.66213 9.95748 4.74278 9.48571 3.9686Z"
                        fill="#23366C"
                    />
                </svg>
                {translation.offerDetails.details.refreshVideo}
            </button>
            {loading && (
                <div style={styles.loading}>
                    <CircularProgress
                        className="loading-popup"
                        size={50}
                        style={{
                            circle: styles.spinner,
                            gradients: styles.gradients
                        }}
                    />
                </div>
            )}
        </div>
    );
};

EmptyPlayer.propTypes = {
    video: PropTypes.object,
    triggerVideoRefresh: PropTypes.func
};

export default EmptyPlayer;
