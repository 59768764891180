// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { DetailsButton, TableRow } from "../../../../../components";
import { ClassificationTag, StatusTag } from "../../../components";
// Actions
import { navigateTo } from "../../../Router.ducks";
// Helpers
import { routesPaths } from "../../../components/Routes/Routes.helper";
import { parseTitle } from "../../../../../services/htmlDataParsing";
// Styles
import styles from "./OfferRow.css";

class OfferRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        id: PropTypes.string,
        navigateTo: PropTypes.func.isRequired,
        offer: PropTypes.object.isRequired
    };

    renderRow = offer => {
        const title = parseTitle(offer);
        return (
            <Fragment>
                <div dangerouslySetInnerHTML={title} style={styles.rowBlock} />
                <StatusTag status={get(offer, "status", undefined)} />
                <ClassificationTag
                    classification={get(offer, "classification", undefined)}
                    style={styles.alignRight}
                />
                {this.renderActionButtons()}
            </Fragment>
        );
    };

    renderActionButtons = () => {
        return <DetailsButton onClick={this.handleOnClick} />;
    };

    handleOnClick = event => {
        const { navigateTo, offer } = this.props;

        if (get(event, "metaKey", false) || get(event, "ctrlKey", false)) {
            window.open(`${routesPaths.offersOverview}/${offer.url}`, "_blank");
        } else {
            navigateTo &&
                navigateTo(`${routesPaths.offersOverview}/${offer.url}`);
        }
    };

    render() {
        const { containerStyle, id, offer } = this.props;
        return (
            <TableRow
                id={id}
                containerStyle={{ ...containerStyle, ...styles.rowContainer }}
            >
                {this.renderRow(offer)}
            </TableRow>
        );
    }
}

const mapDispatchToProps = {
    navigateTo
};

export default connect(null, mapDispatchToProps)(OfferRow);
