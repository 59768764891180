// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import differenceWith from "lodash.differencewith";
import isEqual from "lodash.isequal";
import { Field, reduxForm } from "redux-form";
// Actions
import {
    getCoopPartners,
    upsertMapping
} from "../../../../CoopPartnersMapping.ducks";
import { closeEditPopup } from "../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../components/Popup/Popup.ducks";
// Components
import {
    MaterialTextField,
    Select,
    WarningPopup
} from "../../../../../../../components";
// Validators
import { required } from "../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./EditCoopPartnerLegalEntitiesForm.css";

function onError(error, errorType, props) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.coopPartnersMapping.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={
                    errorType === "warn"
                        ? styles.warningPopupImage
                        : styles.warningErrorPopupImage
                }
                subTitle={get(error, "description", undefined)}
                title={get(error, "title", undefined)}
            />
        )
    });
}

function onSubmit(values, props) {
    const { closeEditPopup, entry, upsertMapping, tab } = props;

    let addCoopPartners = [];
    let removeCoopPartners = [];

    if (entry) {
        const initialValuesIds = {
            legalEntities: entry.legal_entities.map(({ _id }) => _id),
            coopPartner: entry._id
        };
        const newLegalEntities = differenceWith(
            [values.legalEntities],
            initialValuesIds?.legalEntities,
            isEqual
        );
        const removedLegalEntities = differenceWith(
            initialValuesIds?.legalEntities,
            [values.legalEntities],
            isEqual
        );
        if (values.legalEntities) {
            addCoopPartners = newLegalEntities.map(legalEntity => {
                return {
                    legalEntity: legalEntity?.value,
                    coopPartners: [initialValuesIds?.coopPartner]
                };
            });
        }

        removeCoopPartners = removedLegalEntities.map(legalEntity => {
            return {
                legalEntity: legalEntity,
                coopPartners: [initialValuesIds?.coopPartner]
            };
        });
    }

    const data = {
        operation: entry ? "update" : "create",
        tab,
        type: "mapp",
        name: values.name,
        addCoopPartners,
        removeCoopPartners,
        coopPartnerId: entry ? entry._id : null,
        legalEntity:
            entry && values.legalEntities
                ? entry.legal_entities[0]?._id != values.legalEntities
                    ? values.legalEntities.value
                    : entry.legal_entities[0]._id
                : null,
        receivableNumber: values.receivableNumber,
        payableNumber: values.payableNumber
    };

    upsertMapping &&
        upsertMapping(
            data,
            () => {
                closeEditPopup();
            },
            (error, errorType) => {
                onError(error, errorType, props);
            }
        );
}

const prepareLegalEntities = legalEntities => {
    const list =
        legalEntities &&
        legalEntities.map(legalEntity => {
            const legalEntityName = get(legalEntity, "name", undefined);
            const legalEntityId = get(legalEntity, "_id", undefined);
            return {
                label: legalEntityName,
                value: legalEntityId,
                option: {
                    label: legalEntityName,
                    id: legalEntityId
                }
            };
        });

    return list ? list.sort(sortByLabel) : [];
};

const sortByLabel = (a, b) => {
    return a["label"].localeCompare(b["label"]);
};

class EditCoopPartnerLegalEntitiesForm extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        entry: PropTypes.object,
        getCoopPartners: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        coopPartners: PropTypes.array,
        mapping: PropTypes.object,
        openPopup: PropTypes.func.isRequired,
        upsertMapping: PropTypes.func.isRequired,
        initialValues: PropTypes.object
    };

    componentDidMount() {
        const { getCoopPartners } = this.props;

        getCoopPartners && getCoopPartners();
    }

    validateExistingCoopPartners = value => {
        const { coopPartners } = this.props;

        return coopPartners &&
            value &&
            coopPartners.find(
                partner =>
                    partner.name.toLowerCase() === value.trim().toLowerCase()
            )
            ? translation.coopPartnersMapping.editPopup.duplicatedCoopPartners
            : undefined;
    };

    render() {
        const { entry, legalEntities } = this.props;
        const legalEntitiesOptions = prepareLegalEntities(legalEntities);

        return (
            <form style={styles.container}>
                <Field
                    component={MaterialTextField}
                    label={
                        translation.coopPartnersMapping.editPopup.coopPartners
                    }
                    name="name"
                    style={styles.textField}
                    variant="outlined"
                    validate={
                        entry
                            ? []
                            : [required, this.validateExistingCoopPartners]
                    }
                />
                <Field
                    component={MaterialTextField}
                    label={
                        translation.coopPartnersMapping.editPopup
                            .receivableNumber
                    }
                    name="receivableNumber"
                    style={styles.textField}
                    variant="outlined"
                />
                <Field
                    component={MaterialTextField}
                    label={
                        translation.coopPartnersMapping.editPopup.payableNumber
                    }
                    name="payableNumber"
                    style={styles.textField}
                    variant="outlined"
                />
                {entry && (
                    <div>
                        <div style={styles.fieldTitle}>
                            {
                                translation.coopPartnersMapping.editPopup
                                    .legalEntity
                            }
                        </div>
                        <Field
                            component={Select}
                            controlStyle={styles.selectControl}
                            isClearable
                            editable
                            hideSelectedOptions
                            isSearchable
                            name="legalEntities"
                            placeholder={""}
                            options={legalEntitiesOptions}
                            menuContainerStyle={styles.selectMenuContainer}
                            menuListContainerStyle={styles.selectMenu}
                            multiValueLabelStyle={styles.selectMultiValueLabel}
                            multiValueRemoveStyle={
                                styles.selectMultiValueRemove
                            }
                            multiValueStyle={styles.selectMultiValue}
                        />
                    </div>
                )}
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { entry } = props;
    return {
        initialValues: {
            name: get(entry, "name", undefined),
            receivableNumber: get(entry, "receivable_number", undefined),
            payableNumber: get(entry, "payable_number", undefined),
            legalEntities: get(entry, "legal_entities[0]._id", undefined)
        },
        legalEntities:
            state.coopPartnersMapping.dataByTab.legalEntities.entries,
        coopPartners: state.coopPartnersMapping.dataByTab.coopPartners.entries,
        tab: state.coopPartnersMapping.tab
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    closePopup,
    getCoopPartners,
    openPopup,
    upsertMapping
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editLegalEntityMapping",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EditCoopPartnerLegalEntitiesForm)
);
