// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
// Helpers
import { formatDateToDEFormat } from "../../../services/date";

export const CLEAR_ENTITIES_UPLOADS = "CLEAR_ENTITIES_UPLOADS";
export const SAVE_ENTITIES_UPLOADS = "SAVE_ENTITIES_UPLOADS";

export const defaultState = {
    listByLegalEntity: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_ENTITIES_UPLOADS: {
            return Object.assign({}, state, {
                listByLegalEntity: defaultState.listByLegalEntity
            });
        }
        case SAVE_ENTITIES_UPLOADS: {
            return Object.assign({}, state, {
                listByLegalEntity: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearEntitiesUploads = () => {
    return {
        type: CLEAR_ENTITIES_UPLOADS
    };
};

export const saveEntitiesUploads = entitiesUploads => {
    return {
        type: SAVE_ENTITIES_UPLOADS,
        payload: entitiesUploads
    };
};

export const getEntitiesUploads =
    (legalEntityId = undefined) =>
    dispatch => {
        dispatch(startSpinner());
        dispatch(clearEntitiesUploads());

        const apiUrl = legalEntityId
            ? `legalentities/${legalEntityId}/uploadstracking`
            : "legalentities/uploadstracking";

        api.get(apiUrl)
            .then(response => {
                const data = parseEntitiesUploadsData(
                    get(response, "data", [])
                );
                dispatch(saveEntitiesUploads(data));
                dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getEntitiesUploads: ", e);
                dispatch(endSpinner());
            });
    };

export const parseEntitiesUploadsData = data => {
    if (!data) {
        return [];
    }

    const setStatus = dateStr => {
        if (!dateStr) {
            return "red";
        }

        const date = new Date(dateStr);
        const now = new Date(Date.now());
        const nowMinus80Days = new Date(now.setDate(now.getDate() - 80));

        if (date < nowMinus80Days) {
            return "yellow";
        }

        return "green";
    };

    return data.map(legalEntity => ({
        id: get(legalEntity, "_id", undefined),
        name: get(legalEntity, "name", undefined),
        suppliers: get(legalEntity, "suppliers", []).map(supplier => {
            const lastUploadDate = get(supplier, "last_upload_date", undefined);

            return {
                id: get(supplier, "_id", undefined),
                name: get(supplier, "name", undefined),
                lastUploadDate:
                    lastUploadDate && formatDateToDEFormat(lastUploadDate),
                status: setStatus(lastUploadDate)
            };
        })
    }));
};
