// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { NumberCard, TopTableCard } from "../";
// Translations
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./GeneralData.css";

function GeneralData({ data, sectionsPermissions, selectedSupplier }) {
    const handleSelectedSupplier = supplierId => {
        selectedSupplier(supplierId);
    };
    return (
        <div style={styles.container}>
            {get(sectionsPermissions, "generalTotals", false) && (
                <div style={styles.numbersContainer}>
                    <NumberCard
                        subtitle={translation.dashboard.nationwideCert}
                        title={translation.dashboard.offers}
                        value={get(data, "nationwideOffersCount", undefined)}
                    />
                    <NumberCard
                        subtitle={translation.dashboard.nationwideCert}
                        title={translation.dashboard.locations}
                        value={get(data, "nationwideLocationsCount", undefined)}
                    />
                    <NumberCard
                        subtitle={translation.dashboard.nationwideCert}
                        title={translation.dashboard.events}
                        value={get(data, "nationwideEventsCount", undefined)}
                    />
                    <NumberCard
                        subtitle={translation.dashboard.regionalCert}
                        title={translation.dashboard.offers}
                        value={get(data, "regionalOffersCount", undefined)}
                    />

                    <NumberCard
                        subtitle={translation.dashboard.regionalCert}
                        title={translation.dashboard.locations}
                        value={get(data, "regionalLocationsCount", undefined)}
                    />

                    <NumberCard
                        subtitle={translation.dashboard.regionalCert}
                        title={translation.dashboard.events}
                        value={get(data, "regionalEventsCount", undefined)}
                    />
                    <NumberCard
                        title={translation.dashboard.totalOffers}
                        value={get(data, "offersCount", undefined)}
                    />
                    <NumberCard
                        title={translation.dashboard.totalLocations}
                        value={get(data, "locationsCount", undefined)}
                    />
                    <NumberCard
                        title={translation.dashboard.totalEvents}
                        value={get(data, "eventsCount", undefined)}
                    />
                    <NumberCard
                        title={translation.dashboard.totalEventsAcummulated}
                        value={get(data, "totalEventsCount", undefined)}
                    />
                </div>
            )}
            {get(sectionsPermissions, "generalTops", false) && (
                <div style={styles.tablesContainer}>
                    <TopTableCard
                        data={get(data, "suppliersByOffers", []).map(entry => ({
                            id: get(entry, "supplier_id", undefined),
                            name: get(entry, "supplier_name", undefined),
                            count: get(entry, "offers_count", undefined),
                            label: `${get(entry, "supplier_name", "")} (${get(
                                entry,
                                "legal_entities_name",
                                []
                            )
                                .sort((a, b) => a.localeCompare(b))
                                .reduce((acc, cv) => `${acc}, ${cv}`)})`
                        }))}
                        title={translation.dashboard.topSuppliersByOffers}
                        supplierId={handleSelectedSupplier}
                    />
                    <TopTableCard
                        data={get(data, "offersBySuppliers", []).map(entry => ({
                            id: get(entry, "offer_id", undefined),
                            url: get(entry, "offer_url", undefined),
                            cmsUrl: get(entry, "offer_cms_url", undefined),
                            count: get(entry, "suppliers_count", undefined),
                            label: get(entry, "offer_title", undefined)
                        }))}
                        title={translation.dashboard.topOffersBySuppliers}
                    />
                </div>
            )}
        </div>
    );
}

GeneralData.propTypes = {
    data: PropTypes.shape({
        eventsCount: PropTypes.number,
        totalEventsCount: PropTypes.number,
        locationsCount: PropTypes.number,
        nationwideEventsCount: PropTypes.number,
        nationwideLocationsCount: PropTypes.number,
        nationwideOffersCount: PropTypes.number,
        offersBySuppliers: PropTypes.arrayOf(
            PropTypes.shape({
                offer_id: PropTypes.string,
                offer_url: PropTypes.string,
                offer_cms_url: PropTypes.string,
                offer_title: PropTypes.string,
                suppliers_count: PropTypes.number
            })
        ),
        offersCount: PropTypes.number,
        regionalEventsCount: PropTypes.number,
        regionalLocationsCount: PropTypes.number,
        regionalOffersCount: PropTypes.number,
        suppliersByOffers: PropTypes.arrayOf(
            PropTypes.shape({
                offers_count: PropTypes.number,
                supplier_id: PropTypes.string,
                supplier_name: PropTypes.string,
                legal_entity_name: PropTypes.string
            })
        )
    }),
    sectionsPermissions: PropTypes.object,
    selectedSupplier: PropTypes.func
};

export default GeneralData;
