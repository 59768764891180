// Modules
import get from "lodash.get";
// Api
import api from "../../../../../services/api";
import dialogFlow from "../../../../../services/dialogflow";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../../../../config/translation";

export const CLEAR_KEYWORDS = "CLEAR_KEYWORDS";
export const CLEAR_OFFER_DETAILS = "CLEAR_OFFER_DETAILS";
export const CLEAR_OFFER_ENROLMENTS = "CLEAR_OFFER_ENROLMENTS";
export const SAVE_KEYWORDS = "SAVE_KEYWORDS";
export const SAVE_OFFER_DETAILS = "SAVE_OFFER_DETAILS";
export const SAVE_OFFER_ENROLMENTS = "SAVE_OFFER_ENROLMENTS";
export const SET_MEDIA = "SET_MEDIA";
export const SET_OFFER_DETAILS_TAB = "SET_OFFER_DETAILS_TAB";

export const defaultState = {
    details: undefined,
    enrolments: undefined,
    keywords: undefined,
    media: {
        flyer: [],
        video: [],
        podcast: [],
        presentation: [],
        chatbot: [],
        image: []
    },
    tab: "details"
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_KEYWORDS: {
            return Object.assign({}, state, {
                keywords: defaultState.keywords
            });
        }
        case CLEAR_OFFER_DETAILS: {
            return Object.assign({}, defaultState);
        }
        case CLEAR_OFFER_ENROLMENTS: {
            return Object.assign({}, state, {
                enrolments: defaultState.enrolments
            });
        }
        case SAVE_KEYWORDS: {
            return Object.assign({}, state, {
                keywords: action.payload
            });
        }
        case SAVE_OFFER_DETAILS: {
            return Object.assign({}, state, {
                details: action.payload
            });
        }
        case SAVE_OFFER_ENROLMENTS: {
            return Object.assign({}, state, {
                enrolments: action.payload
            });
        }
        case SET_MEDIA: {
            let newArray = [...state.media[action.payload.type]];
            newArray.push(action.payload.media);
            return Object.assign({}, state, {
                media: Object.assign({}, state.media, {
                    [action.payload.type]: newArray
                })
            });
        }
        case SET_OFFER_DETAILS_TAB: {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }
        default:
            return state;
    }
};

export const clearKeywords = () => {
    return {
        type: CLEAR_KEYWORDS
    };
};

export const clearOfferDetails = () => {
    return {
        type: CLEAR_OFFER_DETAILS
    };
};

export const clearOfferEnrolments = () => {
    return {
        type: CLEAR_OFFER_ENROLMENTS
    };
};

export const saveKeywords = keywords => {
    return {
        type: SAVE_KEYWORDS,
        payload: keywords
    };
};

export const saveOfferDetails = details => {
    return {
        type: SAVE_OFFER_DETAILS,
        payload: details
    };
};

export const saveOfferEnrolments = enrolments => {
    return {
        type: SAVE_OFFER_ENROLMENTS,
        payload: enrolments
    };
};

export const setMedia = (type, media) => {
    return {
        type: SET_MEDIA,
        payload: {
            type,
            media
        }
    };
};

export const setOfferDetailsTab = tab => {
    return {
        type: SET_OFFER_DETAILS_TAB,
        payload: tab
    };
};

export const getOfferDetails =
    (url, entityId = undefined) =>
    dispatch => {
        dispatch(startSpinner());
        dispatch(getGFIOfferEnrolments(url));

        const params = {
            entity_id: entityId
        };

        api.get(`seminars/${url}`, params)
            .then(response => {
                const data = get(response, "data", undefined);
                dispatch(saveOfferDetails(data));
                dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling getOfferDetails: ", e);
                dispatch(endSpinner());
            });
    };

export const getGFIOfferEnrolments = url => dispatch => {
    api.get(`offers/${url}/gfienrolments`)
        .then(response => {
            const data = get(response, "data", undefined);
            dispatch(saveOfferEnrolments(data));
        })
        .catch(e => {
            console.log("Error calling getGFIOfferEnrolments: ", e);
        });
};

export const upsertOffer = (
    opType,
    url,
    offer,
    triggerDialogFlow,
    successCb,
    errorCb
) => {
    return async dispatch => {
        const apiOp = opType === "create" ? api.post : api.put;

        dispatch(startSpinner());

        try {
            await apiOp(`offers/${url}`, offer);

            // Trigger dialogFlow function if title,keywords or status of the offer has changed
            // DialogFlow will update chatbot with updated information about the offer
            if (triggerDialogFlow) {
                dialogFlow.post();
            }

            successCb &&
                successCb(() => {
                    dispatch(endSpinner());
                    dispatch(
                        openSnackbar(
                            translation.offers.openSnackbar.save,
                            "success"
                        )
                    );
                });
        } catch (error) {
            console.log("Error calling upsertOffer: ", error);

            let errorMessage = translation.offers.editPopup.genericError;
            let errorType = "error";
            if (error.response && error.response.status === 409) {
                errorMessage = translation.offers.editPopup.urlError;
                errorType = "warn";
            }

            errorCb && errorCb(errorType, errorMessage);

            dispatch(endSpinner());
        }
    };
};

export const deleteEvent =
    (url, eventId, successCb, errorCb = undefined) =>
    dispatch => {
        dispatch(startSpinner());

        api.delete(`offers/${url}/events/${eventId}`)
            .then(() => {
                successCb && successCb();
                dispatch(endSpinner());
            })
            .catch(e => {
                console.log("Error calling deleteEvent: ", e);
                errorCb && errorCb();
                dispatch(endSpinner());
            });
    };

export const getKeywords = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearKeywords());

    api.get("keywords")
        .then(response => {
            dispatch(saveKeywords(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getKeywords: ", e);
            dispatch(endSpinner());
        });
};
