import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        alignItems: "center",
        justifyContent: "center",
        minHeight: "203px",
        minWidth: "203px",
        maxWidth: "203px",
        textAlign: "center",
        padding: "18px"
    },
    innerContainer: {
        display: "grid",
        gap: "16px"
    },
    subtitle: {
        color: Global.colors.darkPaleGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "1.4em",
        textTransform: "uppercase"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "1.4em"
    },
    value: {
        color: Global.colors.darkSlateBlue,
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "1.4em"
    },
    valueContainer: {
        display: "grid",
        gap: "8px"
    }
};

export default styles;
