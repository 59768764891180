// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Cloud Functions
import cloudFunctionApi from "../../../services/cloudFunctionApi";
// Translation
import translation from "../../../config/translation";

export const CLEAR_ENTRIES_LIST = "CLEAR_ENTRIES_LIST";
export const CLEAR_LEGAL_ENTITIES = "CLEAR_LEGAL_ENTITIES";
export const CLEAR_COOPPARTNERS = "CLEAR_COOPPARTNERS";
export const SAVE_LEGAL_ENTITIES = "SAVE_LEGAL_ENTITIES";
export const SAVE_COOPPARTNERS = "SAVE_COOPPARTNERS";
export const SET_LEGAL_ENTITY_SUPPLIERS_TAB = "SET_LEGAL_ENTITY_SUPPLIERS_TAB";
export const SET_URL_PARAMS = "SET_URL_PARAMS";

export const defaultState = {
    dataByTab: {
        legalEntities: {
            entries: [],
            totalCount: 0
        },
        coopPartners: {
            entries: [],
            totalCount: 0
        }
    },
    legalEntities: undefined,
    coopPartners: undefined,
    tab: "legalEntities",
    searchFilter: undefined,
    urlParams: {
        entityIdParam: undefined
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                legalEntities: defaultState.legalEntities
            });
        }
        case CLEAR_ENTRIES_LIST: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    [action.payload]: defaultState.dataByTab[action.payload]
                })
            });
        }
        case CLEAR_COOPPARTNERS: {
            return Object.assign({}, state, {
                coopPartners: defaultState.coopPartners
            });
        }
        case SAVE_LEGAL_ENTITIES: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    legalEntities: {
                        entries: action.payload.entries
                    }
                }),
                ...(!action.payload.isSearch && {
                    legalEntities: action.payload.entries
                })
            });
        }
        case SAVE_COOPPARTNERS: {
            return Object.assign({}, state, {
                dataByTab: Object.assign({}, state.dataByTab, {
                    coopPartners: {
                        entries: action.payload.entries
                    }
                }),
                ...(!action.payload.isSearch && {
                    coopPartners: action.payload.entries
                })
            });
        }
        case SET_LEGAL_ENTITY_SUPPLIERS_TAB: {
            return Object.assign({}, state, {
                tab: action.payload
            });
        }

        case SET_URL_PARAMS: {
            return Object.assign({}, state, {
                urlParams: Object.assign({}, state.urlParams, {
                    entityIdParam: action.payload
                })
            });
        }

        default:
            return state;
    }
};

export const clearEntriesList = tab => {
    return {
        type: CLEAR_ENTRIES_LIST,
        payload: tab
    };
};

export const clearLegalEntities = () => {
    return {
        type: CLEAR_LEGAL_ENTITIES
    };
};

export const clearCoopPartners = () => {
    return {
        type: CLEAR_COOPPARTNERS
    };
};

export const getLegalEntities = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearLegalEntities());

    api.get("legalentities")
        .then(response => {
            dispatch(saveLegalEntities(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getLegalEntities: ", e);
            dispatch(endSpinner());
        });
};

export const getCoopPartners = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearCoopPartners());

    api.get("cooperation_partners")
        .then(response => {
            dispatch(saveCoopPartners(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getCoopPartners: ", e);
            dispatch(endSpinner());
        });
};

export const saveLegalEntities = (entries, isSearch = false) => {
    return {
        type: SAVE_LEGAL_ENTITIES,
        payload: { entries, isSearch }
    };
};

export const saveCoopPartners = (entries, isSearch = false) => {
    return {
        type: SAVE_COOPPARTNERS,
        payload: { entries, isSearch }
    };
};

export const setLegalEntitySupplierTab = tab => {
    return {
        type: SET_LEGAL_ENTITY_SUPPLIERS_TAB,
        payload: tab
    };
};

export const setEntityIdParam = entityId => {
    return {
        type: SET_URL_PARAMS,
        payload: entityId
    };
};

export const upsertMapping = (mapping, successCb, errorCb) => dispatch => {
    dispatch(startSpinner());

    api.put("cooperation_partners", mapping)
        .then(() => {
            mapping.tab === "legalEntities"
                ? dispatch(getLegalEntities())
                : dispatch(getCoopPartners());
            successCb && successCb();
            dispatch(endSpinner());
            dispatch(
                openSnackbar(
                    mapping.type === "mapp"
                        ? translation.coopPartnersMapping.success[
                              get(mapping, "operation", undefined)
                          ]
                        : translation.coopPartnersMapping.success.delete,
                    "success"
                )
            );
        })
        .catch(e => {
            console.log("Error calling upsertMapping: ", e);

            let error = undefined;
            let errorType = "error";
            if (get(e, "response.status", undefined) === 409) {
                error = {
                    title: translation.coopPartnersMapping.errors
                        .duplicatedMappingTitle,
                    description:
                        translation.coopPartnersMapping.errors
                            .duplicatedMappingError
                };
                errorType = "warn";
            } else if (get(mapping, "operation", undefined) === "create") {
                error = {
                    title: translation.coopPartnersMapping.errors.genericTitle,
                    description:
                        translation.coopPartnersMapping.errors
                            .genericErrorCreate
                };
            } else {
                error = {
                    title: translation.coopPartnersMapping.errors.genericTitle,
                    description:
                        translation.coopPartnersMapping.errors
                            .genericErrorUpdate
                };
            }

            errorCb && errorCb(error, errorType);
            dispatch(endSpinner());
        });
};

export const deleteCoopPartner =
    (cooppartnerId, successCb, errorCb) =>
    (dispatch, getState, getFirebase) => {
        dispatch(startSpinner());
        const firebase = getFirebase();

        api.delete(`cooperation_partners/${cooppartnerId}`)
            .then(async () => {
                await cloudFunctionApi.put(firebase, "updateUsersByEntityId", {
                    entity_id: cooppartnerId
                });
                dispatch(getCoopPartners());
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.coopPartnersMapping.success
                            .deleteCooppartner,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log("Error calling deleteCoopPartner: ", e);

                errorCb && errorCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.coopPartnersMapping.errors.genericTitle,
                        "error"
                    )
                );
            });
    };

export const deleteMapping =
    (legalEntityId, successCb, errorCb) => dispatch => {
        dispatch(startSpinner());

        api.delete(`legalentities/${legalEntityId}`)
            .then(() => {
                dispatch(getLegalEntities());
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.coopPartnersMapping.success.delete,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log("Error calling deleteMapping: ", e);
                errorCb && errorCb();
                dispatch(endSpinner());
            });
    };
