// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { bindActionCreators } from "redux";
import isEqual from "lodash.isequal";
import { submit } from "redux-form";
// Components
import { EditMarketingMaterialForm, MarketingCard } from "./components";
import { WarningPopup, MaterialFabButton } from "../../../components";
// Actions
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
import {
    deleteMarketingMaterial,
    getMarketingMaterials
} from "./MarketingMaterials.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
//Helpers
import { hasPermission } from "./helpers/marketingMaterialsPermissions";
// Translations
import translation from "../../../config/translation";
//Styles
import styles from "./MarketingMaterials.css";

class MarketingMaterials extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        deleteMarketingMaterial: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        editMarketingMaterialForm: PropTypes.object,
        editPopup: PropTypes.object,
        getMarketingMaterials: PropTypes.func.isRequired,
        marketingMaterials: PropTypes.array,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object
    };

    componentDidMount() {
        const { getMarketingMaterials } = this.props;

        getMarketingMaterials && getMarketingMaterials();
    }

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    editPopup = (marketingMaterial = undefined) => {
        const { dispatch, openEditPopup } = this.props;

        openEditPopup &&
            openEditPopup({
                cancelAction: this.handleCancelEditPopup,
                cancelButtonLabel:
                    translation.marketingMaterials.editPopup.cancel,
                children: (
                    <EditMarketingMaterialForm data={marketingMaterial} />
                ),
                confirmAction: () => {
                    dispatch(submit("editMarketingMaterial"));
                },
                confirmButtonLabel:
                    translation.marketingMaterials.editPopup.saveChanges,
                header: marketingMaterial
                    ? translation.marketingMaterials.editPopup
                          .editMarketingMaterial
                    : translation.marketingMaterials.editPopup
                          .createMarketingMaterial
            });
    };

    handleDeleteMaterial = material => {
        const {
            closeEditPopup,
            closePopup,
            deleteMarketingMaterial,
            openPopup
        } = this.props;

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        deleteMarketingMaterial &&
                            deleteMarketingMaterial(
                                get(material, "_id", undefined),
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                },
                                () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                }}
                                                buttonLabel={
                                                    translation
                                                        .marketingMaterials
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation
                                                        .marketingMaterials
                                                        .errors.errorDelete
                                                }
                                                title={
                                                    translation
                                                        .marketingMaterials
                                                        .errors.genericTitle
                                                }
                                            />
                                        )
                                    });
                                }
                            );
                    }}
                    buttonLabel={translation.marketingMaterials.confirm}
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.marketingMaterials.editPopup
                            .deleteMarketingMaterialMessage
                    }
                    title={
                        translation.marketingMaterials.editPopup
                            .deleteMaterialTitle
                    }
                />
            )
        });
    };

    handleCancelEditPopup = () => {
        const {
            closeEditPopup,
            closePopup,
            editMarketingMaterialForm,
            openPopup
        } = this.props;

        let initialValues = get(
            editMarketingMaterialForm,
            "initial",
            undefined
        );
        let newValues = get(editMarketingMaterialForm, "values", undefined);
        const valuesHasChanged = !isEqual(initialValues, newValues);

        if (valuesHasChanged) {
            openPopup({
                children: (
                    <WarningPopup
                        buttonAction={() => {
                            closeEditPopup();
                            closePopup();
                        }}
                        buttonLabel={translation.marketingMaterials.confirm}
                        closeFunction={() => {
                            closePopup();
                        }}
                        imageStyle={styles.warningPopupImage}
                        subTitle={
                            translation.marketingMaterials.editPopup
                                .unsavedWarningMessage
                        }
                        title={
                            translation.marketingMaterials.editPopup
                                .unsavedChanges
                        }
                    />
                )
            });
        } else {
            closeEditPopup();
        }
    };

    render() {
        const { editPopup, profileData, marketingMaterials } = this.props;
        const userRole = get(profileData, "role", {});
        const popupIsOpened = editPopup.opened;

        return (
            <div style={styles.container}>
                <div style={styles.headerContainer}>
                    <div style={styles.headerTitle}>
                        {translation.navigationMenu.marketingMaterials}
                    </div>

                    {!popupIsOpened && hasPermission(userRole, "create") && (
                        <MaterialFabButton
                            onClick={() => {
                                this.editPopup();
                            }}
                            style={styles.addButtonContainer}
                            variant="circular"
                        >
                            <span
                                className="icon-plus"
                                style={styles.addButtonIcon}
                            />
                        </MaterialFabButton>
                    )}
                </div>

                <div style={styles.cardsContainer}>
                    {marketingMaterials &&
                        marketingMaterials.map((material, index) => {
                            return (
                                <MarketingCard
                                    key={index}
                                    material={material}
                                    openEditPopup={() =>
                                        this.editPopup(material)
                                    }
                                    deleteMaterial={() =>
                                        this.handleDeleteMaterial(material)
                                    }
                                    userRole={userRole}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profileData,
        editPopup: state.editPopup,
        popup: state.popup,
        marketingMaterials: state.marketingMaterials.marketingMaterials,
        editMarketingMaterialForm: get(
            state,
            "form.editMarketingMaterialForm",
            undefined
        )
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        ...bindActionCreators(
            {
                openEditPopup,
                openPopup,
                closeEditPopup,
                closePopup,
                getMarketingMaterials,
                deleteMarketingMaterial
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingMaterials);
