// Modules
import get from "lodash.get";
// Translations
import translation from "../../../../../../config/translation";

/**
 * Gets the legal entities filter options.
 *
 * @param {Array} legalEntities the list of legal entities
 * @returns a list of legal entities formated as filter options
 */
export const getFilterOptions = legalEntities => {
    let filters = [];
    filters.push({
        key: "all",
        label: translation.offersClassification.filters.all,
        option: {
            name: translation.offersClassification.filters.all
        },
        value: "all"
    });

    legalEntities &&
        legalEntities.forEach(legalEntity => {
            const name = get(legalEntity, "name", undefined);
            name &&
                filters.push({
                    key: name,
                    label: name,
                    option: { name: name, props: legalEntity },
                    value: get(legalEntity, "_id", name)
                });
        });

    return filters;
};

/**
 * Saves and triggers new search based on filters values.
 *
 * @param {Object} param auxiliar params and functions
 */
export const applyFilters = ({ filtersValues, loadEntries, ...props }) => {
    const { saveSearchFilter, searchFilter, searchQuery } = props;

    let newFilters = { ...searchFilter };

    filtersValues &&
        filtersValues.forEach(({ filter, value }) => {
            let filterValue =
                typeof value === "object"
                    ? get(value, "value", undefined)
                    : value;
            let newFilter = value;
            filterValue === "all" && (newFilter = undefined);

            newFilters[filter] = newFilter;
        });

    saveSearchFilter && saveSearchFilter(newFilters);
    loadEntries(searchQuery, newFilters);
};
