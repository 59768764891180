/**
 * Extract flyer/presentation data to be used in form.
 *
 * @param {Object} rawData the flyer/presentation raw data
 */
export function extractFlyerPresentationData(rawData) {
    let imageName = undefined;
    if (rawData.imageURL) {
        const url = new URL(rawData.imageURL);
        const matchValidation = url.pathname.match(`${rawData.content}.*`);
        imageName =
            matchValidation && matchValidation.length > 0 && matchValidation[0];
    }

    return {
        id: rawData._id,
        seminarDetailsId: rawData.seminar_details_id,
        title: rawData.title,
        name: rawData.content,
        image: {
            url: rawData.imageURL,
            name: imageName
        },
        file: {
            url: rawData.fileURL,
            name: rawData.fileURL && `${rawData.content}.${rawData.format}`
        },
        format: rawData.format,
        type: rawData.type
    };
}

/**
 * Create an empty flyer structure.
 */
export function createEmptyFlyer() {
    return {
        type: "flyer"
    };
}

/**
 * Extract video data to be used in form.
 *
 * @param {Object} rawVideo the video raw data
 */
export function extractVideoData(rawVideo) {
    const VIDEO_LINK = "video_link";
    const result = {
        id: rawVideo._id,
        seminarDetailsId: rawVideo.seminar_details_id,
        title: rawVideo.title,
        name: rawVideo.content,
        format: rawVideo.format,
        type: rawVideo.type
    };

    if (rawVideo.type === VIDEO_LINK) {
        result.link = rawVideo.content;
    } else {
        result.file = {
            url: rawVideo.fileURL,
            name: `${rawVideo.content}.${rawVideo.format}`
        };
    }

    return result;
}

/**
 * Create an empty video structure.
 */
export function createEmptyVideo() {
    return {
        type: "video"
    };
}

/**
 * Create an empty link structure.
 */
export function createEmptyLink() {
    return {
        type: "link"
    };
}

/**
 * Create an empty additional link structure.
 */
export function createEmptyAdditionalLink() {
    return {
        type: "additional_link"
    };
}

/**
 * Extract podcast data to be used in form.
 *
 * @param {Object} rawData the podcast raw data
 */
export function extractPodcastData(rawData) {
    return {
        id: rawData._id,
        seminarDetailsId: rawData.seminar_details_id,
        title: rawData.title,
        name: rawData.content,
        description: rawData.description,
        file: {
            url: rawData.fileURL,
            name: rawData.fileURL && `${rawData.content}.${rawData.format}`
        },
        format: rawData.format,
        type: rawData.type
    };
}

/**
 * Create an empty podcast structure.
 */
export function createEmptyPodcast() {
    return {
        type: "podcast"
    };
}

/**
 * Create an empty presentation structure.
 */
export function createEmptyPresentation() {
    return {
        type: "presentation"
    };
}

/**
 * Extract image data to be used in form.
 *
 * @param {Object} rawData the image raw data
 */
export function extractImageData(rawData) {
    return {
        id: rawData._id,
        seminarDetailsId: rawData.seminar_details_id,
        title: rawData.title,
        name: rawData.content,
        description: rawData.description,
        image: {
            url: rawData.imageURL,
            name: rawData.imageURL && `${rawData.content}.${rawData.format}`
        },
        format: rawData.format,
        type: rawData.type
    };
}

/**
 * Create an empty chatbot structure.
 */
export function createEmptyChatbot() {
    return {
        type: "chatbot"
    };
}

/**
 * Create an empty image structure.
 */
export function createEmptyImage() {
    return {
        type: "image"
    };
}
