// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { Field } from "redux-form";
// Components
import { CreatableSelect } from "../../../../../../../../../components";
// Translations
import translation from "../../../../../../../../../config/translation";
// Styles
import styles from "./Keywords.css";

class Keywords extends PureComponent {
    static propTypes = {
        details: PropTypes.object,
        keywordsList: PropTypes.array,
        prepareKeywords: PropTypes.func.isRequired
    };

    prepareKeywordsList = () => {
        const { keywordsList } = this.props;

        return (
            keywordsList &&
            keywordsList.map(keyword => {
                return {
                    label: keyword,
                    value: keyword
                };
            })
        );
    };

    render() {
        const { details, prepareKeywords } = this.props;

        const keywordsOptions = this.prepareKeywordsList();

        const defaultKeywords = prepareKeywords(
            get(details, "keywords", undefined)
        );

        return (
            <div style={styles.container}>
                <span style={styles.title}>
                    {`${translation.navigationMenu.offersKeywordsTagging}:`}
                </span>
                <div style={styles.innerContainer}>
                    <Field
                        component={CreatableSelect}
                        controlStyle={styles.selectControl}
                        defaultOption={defaultKeywords}
                        hideSelectedOptions={false}
                        editable
                        isClearable
                        isMulti
                        isSearchable
                        label={translation.offers.editPopup.keywords}
                        menuListStyle={styles.selectMenu}
                        menuStyle={styles.selectMenuContainer}
                        multiValueLabelStyle={styles.selectMultiValueLabel}
                        multiValueRemoveStyle={styles.selectMultiValueRemove}
                        multiValueStyle={styles.selectMultiValue}
                        name="keywords"
                        noOptionsMessage={translation.labels.noOptions}
                        options={keywordsOptions}
                        placeholder={""}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.offerDetails.details,
        keywordsList: state.offerDetails.keywords
    };
};

export default connect(mapStateToProps)(Keywords);
