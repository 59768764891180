import Global from "../../../../config/global.css";

const styles = {
    default: {
        alignItems: "center",
        backgroundColor: Global.colors.white,
        borderRadius: "12px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        height: "170px",
        justifyContent: "flex-start",
        margin: "12px",
        padding: "32px",
        width: "240px"
    },
    hover: {
        backgroundColor: Global.colors.digitalBlueT4
    },
    label: {
        color: Global.colors.darkSlateBlue,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "21px",
        paddingTop: "24px",
        textTransform: "uppercase"
    },
    icon: {
        color: Global.colors.darkSlateBlue,
        fontSize: "32px"
    },
    iconContainer: {
        default: {
            alignItems: "center",
            backgroundColor: "rgba(95, 153, 255, 0.4)",
            borderRadius: "100%",
            display: "flex",
            height: "40px",
            justifyContent: "center",
            width: "40px"
        },
        hover: {
            backgroundColor: "inherit"
        }
    }
};

export default styles;
