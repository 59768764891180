// React
import React, { Fragment } from "react";
// Router
import { Route } from "react-router";
// Routes
import ChambersLocations from "../../ChambersLocations";
import Dashboard from "../../Dashboard";
import EntitiesMapping from "../../EntitiesMapping";
import EntitiesUploadsTracking from "../../EntitiesUploadsTracking";
import CoopPartnersMapping from "../../CoopPartnersMapping";
import EventDetails from "../../Offers/components/OfferDetails/components/Events/components/EventDetails";
import Invoicing from "../../Invoicing";
import Offers from "../../Offers";
import OfferDetails from "../../Offers/components/OfferDetails";
import OffersFileUpload from "../../OffersFileUpload";
import OffersClassification from "../../OffersClassification";
import OffersKeywordsTagging from "../../OffersKeywordsTagging";
import Settings from "../../Settings";
import UserManual from "../../UserManual";
import Users from "../../Users";
import MarketingMaterials from "../../MarketingMaterials";
import Badges from "../../Badges";
// Helpers
import { routesPaths } from "./Routes.helper";

export default function Routes() {
    return (
        <Fragment>
            <Route
                path={routesPaths.chambersLocations}
                component={ChambersLocations}
            />
            <Route exact path={routesPaths.offersOverview} component={Offers} />
            <Route
                exact
                path={routesPaths.offerDetails}
                component={OfferDetails}
            />
            <Route path={routesPaths.eventDetails} component={EventDetails} />
            <Route
                path={routesPaths.offersFileUpload}
                component={OffersFileUpload}
            />
            <Route
                path={routesPaths.offersClassification}
                component={OffersClassification}
            />
            <Route
                path={routesPaths.offersKeywordsTagging}
                component={OffersKeywordsTagging}
            />
            <Route path={routesPaths.users} component={Users} />
            <Route path={routesPaths.settings} component={Settings} />
            <Route path={routesPaths.invoicing} component={Invoicing} />
            <Route
                path={routesPaths.marketingMaterials}
                component={MarketingMaterials}
            />
            <Route
                path={routesPaths.entitiesMapping}
                component={EntitiesMapping}
            />
            <Route
                path={routesPaths.unmappedEntities}
                component={EntitiesMapping}
            />
            <Route
                path={routesPaths.entitiesUploadsTracking}
                component={EntitiesUploadsTracking}
            />
            <Route
                path={routesPaths.coopPartnersMapping}
                component={CoopPartnersMapping}
            />
            <Route path={routesPaths.userManual} component={UserManual} />
            <Route exact path={routesPaths.badges} component={Badges} />
            <Route exact path={routesPaths.dashboard} component={Dashboard} />
        </Fragment>
    );
}
