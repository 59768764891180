// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { change } from "redux-form";
import PropTypes from "prop-types";
// Components
import { GroupCard } from "../../../";
import {
    FileInput,
    IconButton,
    MaterialTextField
} from "../../../../../../../../../components";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import { required } from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Helpers
import { renderBrowseFileButton } from "../../../../../../helpers/offerDetailsFormHelper";
import { createEmptyImage } from "../../../../../../helpers/offerDetailsFormatHelper";
// Styles
import styles from "./Image.css";

class Image extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired
    };

    imageUrlRef = {};

    renderImage = ({ fields }) => {
        const { dispatch } = this.props;

        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.container}>
                                    <div style={styles.innerContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            endAdornment={renderBrowseFileButton(
                                                () => {
                                                    this.imageUrlRef[index] &&
                                                        this.imageUrlRef[
                                                            index
                                                        ].click();
                                                },
                                                false,
                                                () => {
                                                    dispatch(
                                                        change(
                                                            "offerDetails",
                                                            `${item}.image`,
                                                            null
                                                        )
                                                    );
                                                }
                                            )}
                                            InputLabelProps={{ shrink: true }}
                                            label={
                                                translation.offers.editPopup
                                                    .image
                                            }
                                            name={`${item}.image.name`}
                                            placeholder={
                                                translation.offers.editPopup
                                                    .browseFile
                                            }
                                            style={{
                                                ...styles.disabled,
                                                ...styles.textField
                                            }}
                                            variant="outlined"
                                            validate={required}
                                        />
                                        <Field
                                            accept=".jpg,.jpeg,.png"
                                            component={FileInput}
                                            customOnChange={file => {
                                                file &&
                                                    dispatch(
                                                        change(
                                                            "offerDetails",
                                                            `${item}.image.name`,
                                                            file.name
                                                        )
                                                    );
                                            }}
                                            inputRef={ref => {
                                                this.imageUrlRef[index] !==
                                                    ref &&
                                                    (this.imageUrlRef[index] =
                                                        ref);
                                            }}
                                            name={`${item}.image.url`}
                                            style={styles.uploadInput}
                                        />
                                    </div>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                            </div>
                        );
                    })}
                {fields.length === 0 && (
                    <IconButton
                        defaultClassName="icon-plus"
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                            fields.push(createEmptyImage());
                        }}
                        type={["default"]}
                        style={styles.addButton}
                    >
                        <span style={styles.addButtonLabel}>
                            {translation.offers.editPopup.addImage}
                        </span>
                    </IconButton>
                )}
            </Fragment>
        );
    };

    render() {
        return (
            <GroupCard title={translation.offers.editPopup.image}>
                <FieldArray component={this.renderImage} name="images" />
            </GroupCard>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Image);
