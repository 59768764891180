const styles = {
    container: {
        display: "flex",
        height: "100%",
        padding: "22px 14px",
        overflowX: "auto"
    },
    grid: {
        display: "grid",
        gridAutoColumns: "272px",
        gridAutoFlow: "column",
        gap: "0 30px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingRight: "14px",
        width: "max-content"
    }
};

export default styles;
