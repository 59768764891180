// Modules
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { Field, reduxForm } from "redux-form";
import { uploadFile } from "../../helpers/storageUpload";
import { getFromStorage } from "../../helpers/storageUpload";
// Components
import {
    WarningPopup,
    MaterialTextField,
    MultiFileUpload
} from "../../../../../components";
// Styles
import styles from "./EditMarketingMaterialForm.css";
// Validators
import { required, link } from "../../../../../services/validators";
// Translations
import translation from "../../../../../config/translation";
// Actions
import {
    createMarketingMaterial,
    updateMarketingMaterial
} from "../../MarketingMaterials.ducks";
import { closeEditPopup } from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";

export const FOLDER_MARKETING = "marketing";

const onSubmit = (values, props) => {
    const {
        closeEditPopup,
        createMarketingMaterial,
        data,
        updateMarketingMaterial
    } = props;

    if (data) {
        updateMarketingMaterial &&
            updateMarketingMaterial(
                { ...values, _id: data._id },
                () => {
                    closeEditPopup();
                },
                (error, errorType) => {
                    onError(error, errorType, props);
                }
            );
    } else {
        createMarketingMaterial &&
            createMarketingMaterial(
                values,
                () => {
                    closeEditPopup();
                },
                (error, errorType) => {
                    onError(error, errorType, props);
                }
            );
    }
};

function onError(error, errorType, props) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.marketingMaterials.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={
                    errorType === "warn"
                        ? styles.warningPopupImage
                        : styles.warningErrorPopupImage
                }
                subTitle={get(error, "description", undefined)}
                title={get(error, "title", undefined)}
            />
        )
    });
}

const EditMarketingMaterialForm = ({ dispatch, data }) => {
    const [imageCover, setImageCover] = useState();

    useEffect(() => {
        if (!data) {
            return () => {};
        }

        getFromStorage(`${FOLDER_MARKETING}/${data.image}`)
            .then(url => {
                setImageCover(url);
            })
            .catch(() => {
                // Do nothing
            });
    }, [data]);

    const handleChange = file => {
        if (!file) {
            return;
        }

        if (file) {
            setImageCover(file);
        }

        file && dispatch(change("editMarketingMaterial", `image`, file.name));
        uploadFile(file, file?.name);

        getFromStorage(`${FOLDER_MARKETING}/${file.name}`)
            .then(url => {
                setImageCover(url);
            })
            .catch(() => {
                // Do nothing
            });
    };

    return (
        <form style={styles.container}>
            <Field
                component={MaterialTextField}
                label={
                    translation.marketingMaterials.editPopup.materialNameLabel
                }
                name="title"
                style={styles.textField}
                variant="outlined"
                validate={required}
            />

            <Field
                component={MaterialTextField}
                label={
                    translation.marketingMaterials.editPopup.materialUrlLabel
                }
                name="url"
                style={styles.textField}
                variant="outlined"
                validate={[required, link]}
            />
            <Field
                component={MultiFileUpload}
                initialFiles={[imageCover]}
                acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
                filesLimit={1}
                name="image"
                customOnChange={handleChange}
            />
        </form>
    );
};

const mapStateToProps = (state, props) => {
    const { data } = props;

    return {
        initialValues: {
            title: get(data, "title", undefined),
            url: get(data, "url", undefined),
            image: get(data, "image", undefined)
        }
    };
};

const mapDispatchToProps = {
    openPopup,
    closeEditPopup,
    closePopup,
    createMarketingMaterial,
    updateMarketingMaterial
};

EditMarketingMaterialForm.propTypes = {
    closeEditPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired,
    createMarketingMaterial: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired,
    data: PropTypes.object,
    updateMarketingMaterial: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editMarketingMaterial",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EditMarketingMaterialForm)
);
