// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { ExpandableFilter, Filter } from "../../../../components";
import { RadioFilter } from "../";
// Translation
import translation from "../../../../config/translation";
// Styles
import styles from "./OffersFilters.css";

export const FILTER_CLASSIFICATION = "classification";
export const FILTER_LEGAL_ENTITY = "legalEntity";
export const FILTER_SUPPLIER_ENTITY = "supplierEntity";
export const FILTER_OFFERSTATUS = "offerStatus";
const NULL_VALUES = ["all", undefined, null];

class OffersFilters extends PureComponent {
    static propTypes = {
        applyFilters: PropTypes.func.isRequired,
        defaultFilters: PropTypes.array,
        defaultLegalEntity: PropTypes.object,
        defaultSupplierEntity: PropTypes.object,
        hasPermissionToFilterByLegalEntity: PropTypes.bool,
        hasPermissionToFilterBySupplierEntity: PropTypes.bool,
        legalEntities: PropTypes.array,
        supplierEntities: PropTypes.array,
        profileData: PropTypes.object,
        searchFilter: PropTypes.object,
        handleSelectedSupplierFromDashboard: PropTypes.func,
        selectedSupplier: PropTypes.object
    };

    static defaultProps = {
        defaultFilters: [
            FILTER_LEGAL_ENTITY,
            FILTER_SUPPLIER_ENTITY,
            FILTER_CLASSIFICATION,
            FILTER_OFFERSTATUS
        ],
        hasPermissionToFilterByLegalEntity: true,
        hasPermissionToFilterBySupplierEntity: true
    };

    componentDidMount() {
        const { selectedSupplier, defaultSupplierEntity } = this.props;
        if (selectedSupplier && defaultSupplierEntity.value != "all") {
            this.handleSetFilter({
                filter: FILTER_SUPPLIER_ENTITY,
                value: selectedSupplier && selectedSupplier
            });
        }
    }

    handleSetFilter = ({ filter, value }) => {
        const { applyFilters, searchFilter } = this.props;

        if (get(searchFilter, filter, undefined) === value) {
            return;
        }
        applyFilters && applyFilters([{ filter, value }]);
    };

    resetAllFilters = () => {
        const {
            applyFilters,
            legalEntities,
            supplierEntities,
            searchFilter,
            handleSelectedSupplierFromDashboard
        } = this.props;

        let filters = [];

        !NULL_VALUES.includes(
            get(searchFilter, FILTER_CLASSIFICATION, undefined)
        ) &&
            filters.push({
                filter: FILTER_CLASSIFICATION,
                value: "all"
            });

        !NULL_VALUES.includes(
            get(searchFilter, FILTER_OFFERSTATUS, undefined)
        ) &&
            filters.push({
                filter: FILTER_OFFERSTATUS,
                value: "all"
            });

        !NULL_VALUES.includes(
            get(searchFilter, `${FILTER_LEGAL_ENTITY}.value`, undefined)
        ) &&
            filters.push({
                filter: FILTER_LEGAL_ENTITY,
                value: legalEntities && legalEntities[0]
            });

        !NULL_VALUES.includes(
            get(searchFilter, `${FILTER_SUPPLIER_ENTITY}.value`, undefined)
        ) &&
            filters.push({
                filter: FILTER_SUPPLIER_ENTITY,
                value: supplierEntities && supplierEntities[0]
            });

        filters && filters.length > 0 && applyFilters && applyFilters(filters);
        handleSelectedSupplierFromDashboard(null);
    };

    getFilterValue = filter => {
        const { searchFilter } = this.props;

        switch (filter) {
            case FILTER_CLASSIFICATION:
            case FILTER_OFFERSTATUS: {
                return get(searchFilter, filter, undefined);
            }
            case FILTER_LEGAL_ENTITY: {
                return get(searchFilter, `${filter}.value`, undefined);
            }
            case FILTER_SUPPLIER_ENTITY: {
                return get(searchFilter, `${filter}.value`, undefined);
            }
            default: {
                return undefined;
            }
        }
    };

    render() {
        const {
            defaultFilters,
            defaultLegalEntity,
            defaultSupplierEntity,
            hasPermissionToFilterByLegalEntity,
            hasPermissionToFilterBySupplierEntity,
            legalEntities,
            supplierEntities,
            searchFilter,
            // eslint-disable-next-line no-unused-vars
            selectedSupplier,
            handleSelectedSupplierFromDashboard
        } = this.props;
        let filters = [];
        let filtersKeys = [];

        if (selectedSupplier && defaultSupplierEntity.value != "all") {
            this.handleSetFilter({
                filter: FILTER_SUPPLIER_ENTITY,
                value: selectedSupplier && selectedSupplier
            });
        }

        if (
            hasPermissionToFilterByLegalEntity &&
            defaultFilters.includes(FILTER_LEGAL_ENTITY) &&
            legalEntities &&
            legalEntities.length > 0
        ) {
            filters.push({
                title: translation.offers.legalEntity,
                component: (
                    <Filter
                        defaultOption={defaultLegalEntity}
                        isClearable={!!this.getFilterValue(FILTER_LEGAL_ENTITY)}
                        isSearchable
                        style={styles.legalEntityFilter}
                        onSelection={value => {
                            this.handleSetFilter({
                                filter: FILTER_LEGAL_ENTITY,
                                value
                            });
                        }}
                        options={legalEntities}
                        value={
                            get(searchFilter, FILTER_LEGAL_ENTITY, undefined) ||
                            (legalEntities && legalEntities[0])
                        }
                    />
                )
            });

            filtersKeys.push(FILTER_LEGAL_ENTITY);
        }

        if (
            hasPermissionToFilterBySupplierEntity &&
            defaultFilters.includes(FILTER_SUPPLIER_ENTITY) &&
            supplierEntities &&
            supplierEntities.length > 0
        ) {
            filters.push({
                title: translation.offers.supplierEntity,
                component: (
                    <Filter
                        defaultOption={defaultSupplierEntity}
                        isClearable={
                            !!this.getFilterValue(FILTER_SUPPLIER_ENTITY)
                        }
                        isSearchable
                        style={styles.supplierEntityFilter}
                        onSelection={value => {
                            this.handleSetFilter({
                                filter: FILTER_SUPPLIER_ENTITY,
                                value
                            });
                            handleSelectedSupplierFromDashboard(null);
                        }}
                        options={supplierEntities}
                        value={
                            get(
                                searchFilter,
                                FILTER_SUPPLIER_ENTITY,
                                undefined
                            ) ||
                            (supplierEntities && supplierEntities[0])
                        }
                    />
                )
            });

            filtersKeys.push(FILTER_SUPPLIER_ENTITY);
        }

        if (defaultFilters.includes(FILTER_CLASSIFICATION)) {
            filters.push({
                title: translation.offers.classification,
                component: (
                    <RadioFilter
                        getFilterValue={() =>
                            this.getFilterValue(FILTER_CLASSIFICATION)
                        }
                        handleSetFilter={value => {
                            this.handleSetFilter({
                                filter: FILTER_CLASSIFICATION,
                                value
                            });
                        }}
                        options={["all", "nationwide", "regional"]}
                        translationPath={translation.offers.classifications}
                    />
                )
            });

            filtersKeys.push(FILTER_CLASSIFICATION);
        }

        if (defaultFilters.includes(FILTER_OFFERSTATUS)) {
            filters.push({
                title: translation.offers.offerStatus,
                component: (
                    <RadioFilter
                        getFilterValue={() =>
                            this.getFilterValue(FILTER_OFFERSTATUS)
                        }
                        handleSetFilter={value => {
                            this.handleSetFilter({
                                filter: FILTER_OFFERSTATUS,
                                value
                            });
                        }}
                        options={["all", "approved", "draft"]}
                        translationPath={translation.offers.offerStatuses}
                    />
                )
            });

            filtersKeys.push(FILTER_OFFERSTATUS);
        }

        return (
            <ExpandableFilter
                filters={filters}
                filtersKeys={filtersKeys}
                getFilterValue={this.getFilterValue}
                resetAllFilters={this.resetAllFilters}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profileData,
        selectedSupplier: state.dashboard.selectedSupplier
    };
};

export default connect(mapStateToProps)(OffersFilters);
