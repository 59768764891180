// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import {
    Checkbox,
    CustomTooltip,
    IconButton,
    MaterialExpansionPanel,
    TableRow
} from "../../../../../components";
import { ClassificationTag } from "../../../components/";
// Helpers
import {
    formatDateToDEFormat,
    changeFormat
} from "../../../../../services/date";
import { parseTitle } from "../../../../../services/htmlDataParsing";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./InvoicingRow.css";

const ExpansionPanel = withStyles(() => ({
    detailsRoot: styles.expansionPanel.details.root,
    expanded: styles.expansionPanel.expanded,
    summaryRoot: styles.expansionPanel.summary.root,
    summaryContent: styles.expansionPanel.summary.content,
    summaryExpanded: styles.expansionPanel.summary.expanded
}))(MaterialExpansionPanel);

class InvoicingRow extends PureComponent {
    static propTypes = {
        archiveEntry: PropTypes.func.isRequired,
        containerStyle: PropTypes.object.isRequired,
        entry: PropTypes.object.isRequired,
        hasArchiveAction: PropTypes.bool,
        index: PropTypes.number,
        isChecked: PropTypes.bool,
        section: PropTypes.string.isRequired,
        toggleBulkSelection: PropTypes.func
    };

    state = {
        isRowExpandend: false
    };

    bookingVia = {
        gfi: "GFI",
        internal: "E-Mail"
    };

    renderRowSummary = row => {
        const { isChecked, section, toggleBulkSelection } = this.props;

        const startDate = formatDateToDEFormat(
            get(row, "start_date", undefined)
        );
        const supplier = get(row, "supplier.name", "");
        const offerTitles = {
            displayTitle: get(row, "offer_display_title", undefined),
            title: get(row, "offer_title", undefined)
        };
        const source = get(row, "source", undefined);
        const bookingVia = get(this.bookingVia, source, "");
        const participants = get(row, "booked_participants", []);
        const participantsCount = participants.length;
        const classification = get(row, "classification", undefined);

        return (
            <Fragment>
                <Checkbox
                    checked={isChecked}
                    onClick={toggleBulkSelection}
                    style={styles.checkbox}
                />
                <div style={styles.text}>{startDate}</div>
                {supplier ? (
                    <div style={styles.supplierContainer}>{supplier}</div>
                ) : (
                    <div />
                )}
                <div
                    dangerouslySetInnerHTML={parseTitle(offerTitles)}
                    style={styles.text}
                />
                {classification ? (
                    <ClassificationTag
                        classification={classification}
                        style={styles.alignRight}
                    />
                ) : (
                    <div />
                )}
                <div style={{ ...styles.text, ...styles.textAlignRight }}>
                    {bookingVia}
                </div>
                <div style={styles.text}>
                    <div
                        style={{
                            ...styles.participants,
                            ...get(
                                styles,
                                `section.${section}.participants`,
                                {}
                            )
                        }}
                    >
                        {participantsCount}
                    </div>
                    <span>{translation.invoicing.participant}</span>
                </div>
                {this.renderActionButtons()}
            </Fragment>
        );
    };

    renderRowDetails = row => {
        const participants = get(row, "booked_participants", []);
        return (
            <div style={styles.participantsDetails.container}>
                <div style={styles.participantsDetails.innerContainer}>
                    <span style={styles.participantsDetails.label}>
                        {`${translation.invoicing.moreInfo}:`}
                    </span>
                    {participants &&
                        participants.map((participant, index) => (
                            <div key={index}>
                                <div style={styles.participantsDetails.row}>
                                    <span>{`#${index + 1}`}</span>
                                    <span>
                                        {translation.invoicing.participant}
                                    </span>
                                    <div>
                                        <div>
                                            <span
                                                style={
                                                    styles.participantsDetails
                                                        .bold
                                                }
                                            >
                                                {`${translation.invoicing.postalCode}: `}
                                            </span>
                                            <span>
                                                {get(
                                                    participant,
                                                    "postal_code",
                                                    undefined
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        style={{
                                            ...styles.participantsDetails.bold,
                                            ...styles.bookingDate
                                        }}
                                    >
                                        {`${
                                            translation.invoicing[
                                                participant.booking_request_date
                                                    ? "bookingRequestDate"
                                                    : "bookingDate"
                                            ]
                                        }: `}
                                    </span>

                                    <span>
                                        {changeFormat(
                                            participant.booking_date ??
                                                participant.booking_request_date,
                                            "DD.MM.YYYY"
                                        )}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    renderActionButtons = () => {
        const { archiveEntry, entry, hasArchiveAction, section } = this.props;

        const archivedDisabled = section !== "past";
        const hasParticipantsData = get(entry, "source", undefined) !== "gfi";

        return (
            <div
                className="invoicing-row-actions"
                style={styles.iconsContainer}
            >
                {hasArchiveAction && (
                    <CustomTooltip
                        placement="top"
                        title={translation.invoicing.archive}
                    >
                        <span>
                            <IconButton
                                defaultClassName="icon-archive"
                                disableFocusRipple={archivedDisabled}
                                disableRipple={archivedDisabled}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    if (archivedDisabled) {
                                        return;
                                    }

                                    archiveEntry && archiveEntry(entry);
                                }}
                                style={{
                                    ...styles.icon,
                                    ...get(
                                        styles,
                                        `section.${section}.archiveAction`,
                                        {}
                                    )
                                }}
                                type={["default"]}
                            />
                        </span>
                    </CustomTooltip>
                )}
                {hasParticipantsData && this.renderExpandCollapseButton()}
            </div>
        );
    };

    renderExpandCollapseButton = () => {
        const { isRowExpandend } = this.state;

        return (
            <CustomTooltip
                placement="top"
                title={
                    isRowExpandend
                        ? translation.invoicing.close
                        : translation.invoicing.open
                }
            >
                <div
                    style={{
                        ...styles.expandCollapseButton,
                        ...(isRowExpandend ? styles.expandButton : {})
                    }}
                >
                    <IconButton
                        defaultClassName={"icon-arrow-down"}
                        onClick={this.handleExpandCollapseRow}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
            </CustomTooltip>
        );
    };

    handleExpandCollapseRow = () => {
        const { isRowExpandend } = this.state;

        this.setState({
            isRowExpandend: !isRowExpandend
        });
    };

    render() {
        const { isRowExpandend } = this.state;
        const { containerStyle, entry } = this.props;

        return (
            <div style={styles.container}>
                <ExpansionPanel
                    expanded={isRowExpandend}
                    hasExpandIcon={null}
                    summary={
                        <TableRow
                            containerStyle={{
                                ...containerStyle,
                                ...styles.rowContainer
                            }}
                            disableOnHover={isRowExpandend}
                        >
                            {this.renderRowSummary(entry)}
                        </TableRow>
                    }
                    details={this.renderRowDetails(entry)}
                />
                {isRowExpandend && <div style={styles.marker} />}
            </div>
        );
    }
}

export default InvoicingRow;
