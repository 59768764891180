import Global from "../../../config/global.css";

const styles = {
    addButtonContainer: {
        container: {
            backgroundColor: Global.colors.blue
        }
    },
    addButtonIcon: {
        color: Global.colors.white,
        fontSize: "24px"
    },
    alignRight: {
        justifyContent: "flex-end"
    },
    buttonsContainer: {
        alignItems: "center",
        display: "grid",
        gap: "8px",
        gridAutoFlow: "column"
    },
    clearButton: {
        color: Global.colors.darkSlateBlue,
        fontSize: "16px",
        pointerEvents: "auto"
    },
    container: {
        padding: "24px 24px 32px"
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "26px",
        paddingBottom: "16px"
    },
    infineScroll: {
        margin: "4px 0px"
    },
    rowsContainer: {
        display: "grid",
        gridAutoFlow: "row"
    },
    searchBarContainer: {
        width: "381px"
    },
    searchBarInput: {
        backgroundColor: Global.colors.white
    },
    tableGrid: {
        alignItems: "center",
        display: "grid",
        gap: "24px",
        gridTemplateColumns:
            "minmax(200px, 85%) minmax(80px, 5%) minmax(92px, 8%) minmax(50px, 5%)",
        padding: "8px 24px"
    },
    tableHeaders: {
        height: "28px",
        marginTop: "24px",
        padding: "0px 24px"
    },
    topbarContainer: {
        display: "grid",
        gap: "24px",
        gridTemplateColumns: "1fr auto auto"
    },
    uploadButton: {
        backgroundColor: Global.colors.blue,
        borderRadius: "6px",
        fontSize: "14px",
        lineHeight: "21px",
        maxHeight: "32px",
        minWidth: "120px",
        padding: "4px 24px",
        pointerEvents: "auto"
    },
    warningPopupImage: {
        background: Global.colors.paleYellow,
        color: Global.colors.toastedYellow
    },
    warningPopupImageError: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
