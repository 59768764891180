import Global from "../../../../../config/global.css";

const styles = {
    alignRight: {
        justifySelf: "end"
    },
    boxContainer: {
        backgroundColor: Global.colors.digitalBlueT4,
        borderRadius: "4px",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "13px",
        minHeight: "24px",
        padding: "6px 8px",
        width: "fit-content"
    },
    checkbox: {
        checkbox: {
            backgroundColor: "transparent",
            borderRadius: "2px"
        }
    },
    container: {
        position: "relative"
    },
    expandCollapseButton: {
        transform: "rotate(0deg)",
        transition: "transform 500ms ease"
    },
    expandButton: {
        transform: "rotate(180deg)"
    },
    expansionPanel: {
        details: {
            root: {
                backgroundColor: Global.colors.white,
                borderRadius: "0px 0px 8px 8px",
                margin: "0px",
                padding: "8px 24px 24px 56px"
            }
        },
        expanded: {
            backgroundColor: Global.colors.white,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px !important"
        },
        summary: {
            content: {
                display: "unset"
            },
            expanded: {
                height: "unset !important",
                minHeight: "64px !important"
            },
            root: {
                direction: "unset",
                height: "unset",
                justifyContent: "unset",
                minHeight: "64px"
            }
        }
    },
    displayAll: {
        height: "unset",
        display: "unset",
        overflow: "unset",
        textOverflow: "unset",
        WebkitLineClamp: "unset",
        WebkitBoxOrient: "unset"
    },
    icon: {
        fontSize: "24px"
    },
    marker: {
        backgroundColor: Global.colors.digitalBlue,
        bottom: 0,
        borderRadius: "8px",
        height: "100%",
        position: "absolute",
        top: 0,
        width: "4px"
    },
    rowContainer: {
        cursor: "default",
        gridAutoFlow: "column",
        minHeight: "64px",
        padding: "16px 24px"
    },
    rowExpanded: {
        alignSelf: "start"
    },
    rowContainerSelected: {
        backgroundColor: Global.colors.digitalBlueT4,
        borderRadius: "8px",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 1
    },
    text: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        cursor: "text",
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        userSelect: "text",
        WebkitUserSelect: "text",
        wordBreak: "break-word"
    },
    textOverflow: {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical"
    },
    button: {
        cursor: "pointer",
        container: {
            alignSelf: "center",
            borderRadius: "4px",
            color: Global.colors.darkSlateBlue,
            cursor: "pointer",
            justifySelf: "start",
            width: "auto",
            height: "auto",
            backgroundColor: Global.colors.digitalBlueT4,
            onHover: {
                color: `${Global.colors.white} !important`,
                backgroundColor: `${Global.colors.darkSlateBlue} !important`
            }
        },
        iconRipple: {
            height: "48px !important",
            left: "0px !important",
            top: "-12px !important",
            width: "50px !important"
        },
        label: {
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "1.4em",
            padding: "6px 8px"
        }
    }
};

export default styles;
