// Modules
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
//Components
import { IconButton, MaterialButton } from "../../../../../components";
//Helpers
import { hasPermission } from "../../helpers/marketingMaterialsPermissions";
import { getFromStorage } from "../../helpers/storageUpload";
// Translations
import translation from "../../../../../config/translation";
//Styles
import styles from "./MarketingCard.css";

const MarketingCard = ({
    deleteMaterial,
    material,
    userRole,
    openEditPopup
}) => {
    const useStyles = makeStyles({
        root: {
            width: 280
        },
        media: {
            height: 280,
            backgroundColor: "#D2D2D2"
        }
    });
    const [imageCover, setImageCover] = useState(undefined);

    const { title, url, image } = material;

    const hasUpdatePermission = hasPermission(userRole, "update");
    const hasDeletePermission = hasPermission(userRole, "delete");

    useEffect(() => {
        if (!image) {
            return () => {};
        }
        getFromStorage(`marketing/${image}`)
            .then(result => {
                setImageCover(result);
            })
            .catch(() => {
                // Do nothing
            });
    }, []);

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            {imageCover && (
                <CardMedia
                    className={classes.media}
                    image={imageCover}
                    title={title}
                />
            )}
            <div style={styles.title}>{title}</div>

            <CardActions>
                <MaterialButton
                    customType="link"
                    style={styles.url}
                    onClick={() => window.open(url)}
                >
                    {translation.marketingMaterials.link}
                </MaterialButton>
                {hasUpdatePermission && (
                    <IconButton
                        defaultClassName="icon-edit"
                        onClick={openEditPopup}
                        style={styles.button}
                        type={["default"]}
                    />
                )}

                {hasDeletePermission && (
                    <IconButton
                        defaultClassName="icon-delete"
                        onClick={deleteMaterial}
                        style={styles.button}
                        type={["default"]}
                    />
                )}
            </CardActions>
        </Card>
    );
};

MarketingCard.propTypes = {
    deleteMaterial: PropTypes.func.isRequired,
    material: PropTypes.object,
    openEditPopup: PropTypes.func.isRequired,
    tooltip: PropTypes.object,
    userRole: PropTypes.object
};

export default MarketingCard;
