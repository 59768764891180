import Global from "../../config/global.css";

const styles = {
    container: {
        alignSelf: "center",
        borderRadius: "100px",
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        height: "24px",
        justifySelf: "end",
        width: "50px",

        onHover: {
            color: `${Global.colors.white} !important`,
            backgroundColor: Global.colors.darkSlateBlue
        }
    },
    iconRipple: {
        height: "48px !important",
        left: "0px !important",
        top: "-12px !important",
        width: "50px !important"
    },
    label: {
        border: `1px solid ${Global.colors.darkSlateBlue}`,
        borderRadius: "100px",
        fontSize: "8px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "10px",
        padding: "6px 7.5px",
        textTransform: "uppercase"
    }
};

export default styles;
