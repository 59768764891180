import Global from "../../config/global.css";

const styles = {
    icon: {
        color: Global.colors.white,
        fontSize: "24px"
    }
};

export default styles;
