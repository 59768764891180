// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Form
import { Field } from "redux-form";
// Components
import { DatePicker } from "../../../../../../../../../../../components/";
import { GroupCard } from "../../../../../";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Dates.css";

class Dates extends PureComponent {
    static propTypes = {
        eventDetailsForm: PropTypes.object
    };

    render() {
        const { eventDetailsForm } = this.props;

        return (
            <GroupCard style={styles.card} title={translation.event.date}>
                <div style={styles.container}>
                    <Field
                        component={DatePicker}
                        label={translation.event.startDate}
                        name="startDate"
                        endDate={get(
                            eventDetailsForm,
                            "values.endDate",
                            undefined
                        )}
                    />
                    <Field
                        component={DatePicker}
                        label={translation.event.endDate}
                        name="endDate"
                        startDate={get(
                            eventDetailsForm,
                            "values.startDate",
                            undefined
                        )}
                    />
                </div>
            </GroupCard>
        );
    }
}

const mapStateToProps = state => {
    return {
        eventDetailsForm: get(state, "form.eventDetails", undefined)
    };
};

export default connect(mapStateToProps)(Dates);
