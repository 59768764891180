const styles = {
    linkContainer: {
        display: "inline-flex",
        fontWeight: "bold !important"
    },
    link: {
        fontSize: "inherit",
        fontStyle: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit"
    }
};

export default styles;
