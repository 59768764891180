// Modules
import get from "lodash.get";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import api from "../../../services/api";

export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_LEGAL_ENTITY = "SAVE_LEGAL_ENTITY";

export const defaultState = {
    token: undefined
};

// Reducer
export default (state = defaultState, action) => {
    switch (action.type) {
        case SAVE_TOKEN: {
            return Object.assign({}, state, {
                token: action.payload
            });
        }
        default:
            return state;
    }
};

export const saveToken = token => {
    return {
        type: SAVE_TOKEN,
        payload: token
    };
};

export const getToken = (userId, userEmail, userRole, entityId) => dispatch => {
    dispatch(startSpinner());
    const params = {
        userId,
        userEmail,
        userRole,
        entityId
    };
    const badgesUrl = process.env.REACT_APP_BADGES_URL;
    const allowedRoles = [
        "superuser",
        "dihkadmin",
        "dihkemployee",
        "ihkadmin",
        "ihkagent",
        "cooppartneradmin",
        "cooppartneragent"
    ];
    api.put("badges/token", params)
        .then(response => {
            dispatch(saveToken(get(response, "data", undefined)));

            dispatch(endSpinner());
            {
                if (allowedRoles.includes(userRole)) {
                    window.location.href = `${badgesUrl}?token=${get(
                        response,
                        "data",
                        undefined
                    )}`;
                }
            }
        })
        .catch(e => {
            console.log("Error getting token: ", e);
            dispatch(endSpinner());
        });
};
