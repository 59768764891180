import Global from "../../../../../config/global.css";

const styles = {
    button: {
        container: {
            placeSelf: "end"
        },
        default: {
            fontWeight: "600",
            textTransform: "uppercase",
            width: "max-content"
        }
    },
    container: {
        alignItems: "center",
        backgroundColor: Global.colors.white,
        borderRadius: "12px 12px 0px 0px",
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 24px",
        padding: "12px 24px"
    },
    hide: {
        position: "fixed",
        visibility: "hidden"
    },
    text: {
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px"
    },
    warningErrorPopupImage: {
        background: "rgba(255, 121, 89, 0.2)",
        color: Global.colors.melon
    }
};

export default styles;
