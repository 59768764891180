// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators, compose } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import debounce from "lodash/debounce";
import { withStyles } from "@material-ui/core/styles";
// Components
import { Tab, Tabs } from "@material-ui/core";
import { MaterialFabButton, SearchBar } from "../../../components";
import { Filters, KeywordRow, OfferRow, OffersKeywords } from "./components";
import { EditKeywordOffersForm } from "./components/OffersKeywords/components/";
// Actions
import {
    clearKeywordsTagging,
    clearKeywordsTaggingList,
    clearSearchFilter,
    clearSearchQuery,
    getOffersKeywords,
    saveSearchQuery,
    saveSearchFilter,
    setOffersKeywordsTaggingTab
} from "./OffersKeywordsTagging.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
import { closePopup } from "../../../components/Popup/Popup.ducks";
import { closeViewPopup } from "../../../components/ViewPopup/ViewPopup.ducks";
// Translation
import translation from "../../../config/translation";
// Styles
import styles from "./OffersKeywordsTagging.css";

class OffersKeywordsTagging extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        clearKeywordsTagging: PropTypes.func.isRequired,
        clearKeywordsTaggingList: PropTypes.func.isRequired,
        clearSearchFilter: PropTypes.func.isRequired,
        clearSearchQuery: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        closeViewPopup: PropTypes.func.isRequired,
        dataByTab: PropTypes.object,
        dispatch: PropTypes.func,
        editPopup: PropTypes.object,
        getOffersKeywords: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        saveSearchFilter: PropTypes.func.isRequired,
        saveSearchQuery: PropTypes.func.isRequired,
        searchFilter: PropTypes.object,
        searchQuery: PropTypes.string,
        setOffersKeywordsTaggingTab: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired,
        viewPopup: PropTypes.object
    };

    tabs = [
        {
            key: "offers",
            icon: "icon-offers",
            createNew: false,
            rowComponent: OfferRow,
            tableHeaders: [
                {
                    label: translation.offersKeywordsTagging.tableHeaders
                        .offerTitle
                },
                {
                    label: translation.offersKeywordsTagging.tableHeaders
                        .classification,
                    style: styles.alignRight
                },
                {
                    label: translation.offersKeywordsTagging.tableHeaders
                        .keywords
                }
            ]
        },
        {
            key: "keywords",
            icon: "icon-tagging",
            createNew: () => {
                const { closeEditPopup, dispatch, openEditPopup } = this.props;

                openEditPopup &&
                    openEditPopup({
                        cancelAction: closeEditPopup,
                        cancelButtonLabel:
                            translation.offersKeywordsTagging.editPopup.cancel,
                        confirmButtonLabel:
                            translation.offersKeywordsTagging.editPopup
                                .saveChanges,
                        style: styles.editPopup,
                        children: <EditKeywordOffersForm />,
                        confirmAction: () => {
                            dispatch(submit("editKeywordOffers"));
                        },
                        header: translation.offersKeywordsTagging.editPopup
                            .createKeyword
                    });
            },
            rowComponent: KeywordRow,
            tableHeaders: [
                translation.offersKeywordsTagging.tableHeaders.keywords,
                translation.offersKeywordsTagging.tableHeaders.offersTitle
            ]
        }
    ];

    componentDidMount() {
        this.handleLoadEntries({ fromStart: true });
    }

    componentDidUpdate(prevProps) {
        const { clearKeywordsTaggingList, tab } = this.props;

        if (tab !== get(prevProps, "tab", undefined)) {
            let searchElement = document.getElementsByClassName(
                "keywords-tagging-search-input"
            );
            searchElement =
                searchElement && searchElement.length > 0 && searchElement[0];
            searchElement && (searchElement.value = null);

            this.clearSearchQueryFilter();
            clearKeywordsTaggingList && clearKeywordsTaggingList(tab);
            this.handleLoadEntries({ fromStart: true });
        }
    }

    componentWillUnmount() {
        const { clearKeywordsTagging } = this.props;

        this.clearSearchQueryFilter();
        clearKeywordsTagging && clearKeywordsTagging();
        this.closePopups();
    }

    closePopups = () => {
        const {
            closeEditPopup,
            closePopup,
            closeViewPopup,
            editPopup: { opened },
            popup,
            viewPopup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
        viewPopup && viewPopup.opened && closeViewPopup && closeViewPopup();
    };

    clearSearchQueryFilter = () => {
        const { clearSearchFilter, clearSearchQuery } = this.props;

        clearSearchQuery && clearSearchQuery();
        clearSearchFilter && clearSearchFilter();
    };

    handleChangeActiveTab = (event, newActiveTab) => {
        const { setOffersKeywordsTaggingTab, tab } = this.props;

        if (newActiveTab !== tab) {
            setOffersKeywordsTaggingTab &&
                setOffersKeywordsTaggingTab(newActiveTab);
            this.clearSearchQueryFilter();
            this.closePopups();
        }
    };

    handleLoadEntries = ({
        fromStart = true,
        query,
        classification,
        hasNoKeywordsFilter
    }) => {
        const { getOffersKeywords, dataByTab, tab } = this.props;

        const entries = get(dataByTab, `${tab}.entries`, []);

        getOffersKeywords &&
            getOffersKeywords({
                tab,
                query,
                classification,
                hasNoKeywordsFilter,
                limit: 25,
                startIndex: fromStart
                    ? 0
                    : entries && entries.length > 0
                    ? entries.length
                    : 0,
                successCb: () => {
                    if (fromStart) {
                        const element = document.getElementsByClassName(
                            "infinite-scroll-container"
                        )[0];
                        element && element.scrollTo(0, 0);
                    }
                }
            });
    };

    handleLoadMoreEntries = ({
        query,
        classification,
        hasNoKeywordsFilter
    }) => {
        const { dataByTab, tab } = this.props;

        const entries = get(dataByTab, `${tab}.entries`, []);
        const totalCount = get(dataByTab, `${tab}.totalCount`, 0);

        totalCount > 0 &&
            entries.length > 0 &&
            this.handleLoadEntries({
                fromStart: false,
                query,
                classification,
                hasNoKeywordsFilter
            });
    };

    getFilterOptions = () => {
        const { tab } = this.props;

        let filters = [];
        if (tab === "offers") {
            return ["all", "noKeywords"];
        }

        return filters;
    };

    loadEntries = (query, filters) => {
        this.handleLoadEntries({
            fromStart: true,
            query,
            classification: get(filters, "classification", undefined),
            hasNoKeywordsFilter:
                get(filters, "noKeywords", undefined) === "noKeywords"
        });
    };

    handleSearch = value => {
        const { saveSearchQuery, searchFilter, searchQuery } = this.props;

        if (value !== searchQuery) {
            saveSearchQuery && saveSearchQuery(value);
            this.loadEntries(value, searchFilter);
        }
    };

    debounceSearchOnChange = debounce(this.handleSearch, 500);

    applyFilters = filtersValues => {
        const { saveSearchFilter, searchFilter, searchQuery } = this.props;

        let newFilters = { ...searchFilter };
        filtersValues &&
            filtersValues.forEach(({ filter, value }) => {
                let newFilterValue = value || undefined;
                value === "all" && (newFilterValue = undefined);

                newFilters[filter] = newFilterValue;
            });

        saveSearchFilter && saveSearchFilter(newFilters);
        this.loadEntries(searchQuery, newFilters);
    };

    render() {
        const { classes, searchFilter, tab } = this.props;

        const filterOptions = this.getFilterOptions();

        const tabParams = this.tabs.find(el => el.key === tab);

        return (
            <div style={styles.container}>
                <Tabs
                    classes={{
                        flexContainer: classes.tabsContainer
                    }}
                    onChange={this.handleChangeActiveTab}
                    scrollButtons="off"
                    TabIndicatorProps={{
                        style: styles.tabs.indicator
                    }}
                    style={styles.tabs.container}
                    value={tab}
                >
                    {this.tabs.map((tabObj, index) => {
                        const isTabSelected = tab === tabObj.key;

                        return (
                            <Tab
                                classes={{
                                    root: classes.tabDefault,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper
                                }}
                                icon={
                                    <div
                                        className="tab-icon"
                                        style={{
                                            ...styles.tabs.icon.container
                                                .default,
                                            ...(isTabSelected
                                                ? styles.tabs.icon.container
                                                      .selected
                                                : {})
                                        }}
                                    >
                                        <span
                                            className={tabObj.icon}
                                            style={{
                                                ...styles.tabs.icon.icon
                                                    .default,
                                                ...(isTabSelected
                                                    ? styles.tabs.icon.icon
                                                          .selected
                                                    : {})
                                            }}
                                        />
                                    </div>
                                }
                                key={index}
                                label={
                                    translation.offersKeywordsTagging.tabs[
                                        tabObj.key
                                    ]
                                }
                                value={tabObj.key}
                            />
                        );
                    })}
                </Tabs>
                <div>
                    <div style={styles.headerTitle}>
                        {`${translation.navigationMenu.offersKeywordsTagging} — ${translation.offersKeywordsTagging.tabs[tab]}`}
                    </div>
                    <div style={styles.topbarContainer}>
                        <SearchBar
                            icon="icon-search"
                            inputClassName="keywords-tagging-search-input"
                            onChange={this.debounceSearchOnChange}
                            placeholder={
                                translation.offersKeywordsTagging.search
                            }
                            style={{
                                container: styles.searchBarContainer,
                                input: styles.searchBarInput
                            }}
                        />
                        {filterOptions && filterOptions.length > 1 && (
                            <Filters
                                applyFilters={this.applyFilters}
                                searchFilter={searchFilter}
                            />
                        )}
                        {get(tabParams, "createNew", undefined) && (
                            <MaterialFabButton
                                onClick={get(tabParams, "createNew", undefined)}
                                style={styles.addButtonContainer}
                                variant="circular"
                            >
                                <span
                                    className="icon-plus"
                                    style={styles.addButtonIcon}
                                />
                            </MaterialFabButton>
                        )}
                    </div>
                    <OffersKeywords
                        handleLoadMoreEntries={this.handleLoadMoreEntries}
                        key={get(tabParams, "key", undefined)}
                        RowComponent={get(tabParams, "rowComponent", null)}
                        tab={get(tabParams, "key", undefined)}
                        tableHeaders={get(tabParams, "tableHeaders", [])}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dataByTab: state.offersKeywordsTagging.dataByTab,
        editPopup: state.editPopup,
        popup: state.popup,
        searchQuery: state.offersKeywordsTagging.searchQuery,
        searchFilter: state.offersKeywordsTagging.searchFilter,
        tab: state.offersKeywordsTagging.tab,
        viewPopup: state.viewPopup
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                clearKeywordsTagging,
                clearKeywordsTaggingList,
                clearSearchFilter,
                clearSearchQuery,
                closeEditPopup,
                closePopup,
                closeViewPopup,
                getOffersKeywords,
                openEditPopup,
                saveSearchFilter,
                saveSearchQuery,
                setOffersKeywordsTaggingTab
            },
            dispatch
        )
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(OffersKeywordsTagging);
