// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { ExpandableFilter } from "../../../../../components";
import { RadioFilter } from "../../../components";
// Translation
import translation from "../../../../../config/translation";

const FILTER_CLASSIFICATION = "classification";
const FILTER_NO_KEYWORDS = "noKeywords";
const NULL_VALUES = ["all", undefined, null];

class Filters extends PureComponent {
    static propTypes = {
        applyFilters: PropTypes.func.isRequired,
        searchFilter: PropTypes.object
    };

    handleSetFilter = ({ filter, value }) => {
        const { applyFilters, searchFilter } = this.props;

        if (get(searchFilter, filter, undefined) === value) {
            return;
        }

        applyFilters && applyFilters([{ filter, value }]);
    };

    resetAllFilters = () => {
        const { applyFilters, searchFilter } = this.props;

        let filters = [];

        !NULL_VALUES.includes(
            get(searchFilter, FILTER_CLASSIFICATION, undefined)
        ) &&
            filters.push({
                filter: FILTER_CLASSIFICATION,
                value: "all"
            });
        !NULL_VALUES.includes(
            get(searchFilter, FILTER_NO_KEYWORDS, undefined)
        ) &&
            filters.push({
                filter: FILTER_NO_KEYWORDS,
                value: "all"
            });

        filters && filters.length > 0 && applyFilters && applyFilters(filters);
    };

    getFilterValue = filter => {
        const { searchFilter } = this.props;

        return get(searchFilter, filter, undefined);
    };

    render() {
        return (
            <ExpandableFilter
                filters={[
                    {
                        title: translation.offers.classification,
                        component: (
                            <RadioFilter
                                getFilterValue={() =>
                                    this.getFilterValue(FILTER_CLASSIFICATION)
                                }
                                handleSetFilter={value => {
                                    this.handleSetFilter({
                                        filter: FILTER_CLASSIFICATION,
                                        value
                                    });
                                }}
                                options={["all", "nationwide", "regional"]}
                                translationPath={
                                    translation.offers.classifications
                                }
                            />
                        )
                    },
                    {
                        title: translation.offersKeywordsTagging.taggegOffers,
                        component: (
                            <RadioFilter
                                getFilterValue={() =>
                                    this.getFilterValue(FILTER_NO_KEYWORDS)
                                }
                                handleSetFilter={value => {
                                    this.handleSetFilter({
                                        filter: FILTER_NO_KEYWORDS,
                                        value
                                    });
                                }}
                                options={["all", "noKeywords"]}
                                translationPath={
                                    translation.offersKeywordsTagging.filters
                                }
                            />
                        )
                    }
                ]}
                filtersKeys={[FILTER_CLASSIFICATION, FILTER_NO_KEYWORDS]}
                getFilterValue={this.getFilterValue}
                resetAllFilters={this.resetAllFilters}
            />
        );
    }
}

export default Filters;
