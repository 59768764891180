import Global from "../../config/global.css";

const styles = {
    default: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        "&:hover": {
            backgroundColor: Global.colors.lightPaleGrey
        }
    },
    disabled: {
        color: `${Global.colors.paleGrey} !important`
    },
    iconRippleChild: {
        backgroundColor: Global.colors.darkSlateBlue
    },
    root: {
        display: "block",
        height: "32px",
        padding: 0,
        width: "32px"
    }
};

export default styles;
