import de from "./translations/de";
import en from "./translations/en";
import moment from "moment";
import "moment/locale/de";

moment.locale(process.env.REACT_APP_DEFAULT_LANGUAGE);

const envLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
const translation = envLanguage === "de" ? de : en;

export default translation;
