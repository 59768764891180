// Modules
import get from "lodash.get";
// Helpers
import { hasSpecificPermission } from "../../../../services/permissions";

/**
 * Checks entities mapping permission.
 *
 * @param {Object} userRole the role permissions
 * @param {String} permission the permission to validate
 * @returns true if has permission, false otherwise
 */
export function hasPermission(userRole, permission) {
    return hasSpecificPermission(
        get(userRole, "permissions", {}),
        "chambers_locations",
        permission
    );
}
