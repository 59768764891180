// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import differenceWith from "lodash.differencewith";
// Components
import {
    MaterialTextField,
    Select,
    WarningPopup
} from "../../../../../../../components/";
// Form
import { Field, reduxForm } from "redux-form";
// Actions
import {
    getKeywords,
    getOffers,
    upsertKeywords
} from "../../../../OffersKeywordsTagging.ducks";
import { closeEditPopup } from "../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../components/Popup/Popup.ducks";
// Validators
import {
    required,
    requiredArray
} from "../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./EditKeywordOffersForm.css";

function onSubmit(values, props) {
    const { closeEditPopup, entry, upsertKeywords } = props;

    const initialOffers = (get(entry, "offers_data", []) || []).map(offer =>
        get(offer, "_id", undefined)
    );

    const offers = (get(values, "offers", []) || []).map(offer =>
        get(offer, "value", undefined)
    );

    const offersRemoved = differenceWith(initialOffers, offers, isEqual);
    const offersAdded = differenceWith(offers, initialOffers, isEqual);

    if (
        (!offersRemoved || offersRemoved.length === 0) &&
        (!offersAdded || offersAdded.length === 0)
    ) {
        closeEditPopup();
        return;
    }

    const keyword =
        get(entry, "keyword", undefined) ||
        (get(values, "keyword", undefined) &&
            get(values, "keyword", undefined).trim());

    let data = {
        add_offers:
            (offersAdded &&
                offersAdded.map(offer => ({
                    offer_id: offer,
                    keyword
                }))) ||
            [],
        delete_offers:
            (offersRemoved &&
                offersRemoved.map(offer => ({
                    offer_id: offer,
                    keyword
                }))) ||
            []
    };

    upsertKeywords &&
        upsertKeywords({
            operation: entry ? "update" : "add",
            data,
            successCb: closeEditPopup,
            errorCb: errorMessage => {
                onError(props, errorMessage);
            }
        });
}

function onError(props, errorMessage) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.offersKeywordsTagging.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={styles.warningErrorPopupImage}
                subTitle={get(errorMessage, "subtitle", "")}
                title={get(errorMessage, "title", "")}
            />
        )
    });
}

const prepareOffers = offers => {
    return !offers || offers.length === 0
        ? null
        : offers.map(offer => {
              const offerTitle = get(offer, "title", undefined);
              const offerId = get(offer, "_id", undefined);

              return {
                  label: offerTitle,
                  value: offerId,
                  option: {
                      label: offerTitle,
                      id: offerId
                  }
              };
          });
};

class EditKeywordOffersForm extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        entry: PropTypes.object,
        getKeywords: PropTypes.func.isRequired,
        getOffers: PropTypes.func.isRequired,
        offersList: PropTypes.array,
        keywordsList: PropTypes.array,
        openPopup: PropTypes.func.isRequired,
        upsertKeywords: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { getKeywords, getOffers } = this.props;

        getKeywords && getKeywords();
        getOffers && getOffers();
    }

    validateExistingKeyword = value => {
        const { keywordsList } = this.props;

        return keywordsList &&
            value &&
            keywordsList.find(
                keyword => keyword.toLowerCase() === value.trim().toLowerCase()
            )
            ? translation.offersKeywordsTagging.editPopup.duplicatedKeyword
            : undefined;
    };

    render() {
        const { entry, offersList } = this.props;

        const offersOptions = prepareOffers(offersList);

        const defaultOffers = prepareOffers(
            get(entry, "offers_data", undefined)
        );

        return (
            <form style={styles.container}>
                <Field
                    component={MaterialTextField}
                    disabled={!!entry}
                    label={translation.offersKeywordsTagging.keyword}
                    name="keyword"
                    style={styles.textField}
                    variant="outlined"
                    validate={
                        entry ? [] : [required, this.validateExistingKeyword]
                    }
                />
                <Field
                    component={Select}
                    controlStyle={styles.selectControl}
                    defaultOption={defaultOffers}
                    hideSelectedOptions={false}
                    editable
                    isClearable
                    isMulti
                    isSearchable
                    label={translation.offersKeywordsTagging.offers}
                    menuContainerStyle={styles.selectMenuContainer}
                    menuListContainerStyle={styles.selectMenu}
                    multiValueLabelStyle={styles.selectMultiValueLabel}
                    multiValueRemoveIconStyle={
                        styles.selectMultiValueRemoveIcon
                    }
                    multiValueRemoveStyle={styles.selectMultiValueRemove}
                    multiValueStyle={styles.selectMultiValue}
                    name="offers"
                    noOptionsMessage={translation.labels.noOptions}
                    options={offersOptions}
                    placeholder={""}
                    validate={entry ? null : requiredArray}
                />
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { entry } = props;

    return {
        initialValues: {
            keyword: get(entry, "keyword", undefined),
            offers: prepareOffers(get(entry, "offers_data", undefined))
        },
        keywordsList: state.offersKeywordsTagging.keywords,
        offersList: state.offersKeywordsTagging.offers
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    closePopup,
    getKeywords,
    getOffers,
    openPopup,
    upsertKeywords
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editKeywordOffers",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EditKeywordOffersForm)
);
