// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import differenceWith from "lodash.differencewith";
import isEqual from "lodash.isequal";
// Components
import { Select, WarningPopup } from "../../../../../../../components";
// Form
import { Field, reduxForm } from "redux-form";
// Styles
import styles from "./EditLegalEntitieSuppliersForm.css";
// Validators
import {
    required,
    requiredArray
} from "../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../config/translation";
// Actions
import { getSuppliers, upsertMapping } from "../../../../EntitiesMapping.ducks";
import { closeEditPopup } from "../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../components/Popup/Popup.ducks";

function onSubmit(values, props) {
    const { closeEditPopup, entry, upsertMapping, tab } = props;

    const newValuesIds = {
        legalEntity: values.legalEntity.value,
        suppliers: values.suppliers.map(({ value }) => value)
    };

    let addSuppliers = [];
    let removeSuppliers = [];

    if (entry) {
        const addedSuppliers = differenceWith(
            newValuesIds.suppliers,
            entry.suppliers,
            isEqual
        );
        const removedSuppliers = differenceWith(
            entry.suppliers,
            newValuesIds.suppliers,
            isEqual
        );

        addedSuppliers.length > 0
            ? (addSuppliers = [
                  {
                      legalEntity: entry._id,
                      suppliers: addedSuppliers
                  }
              ])
            : null;

        removedSuppliers.length > 0
            ? (removeSuppliers = [
                  {
                      legalEntity: entry._id,
                      suppliers: removedSuppliers
                  }
              ])
            : null;
    } else {
        addSuppliers = [
            {
                legalEntity: newValuesIds.legalEntity,
                suppliers: newValuesIds.suppliers
            }
        ];
    }

    const data = {
        operation: entry ? "update" : "create",
        tab,
        type: "mapp",
        addSuppliers,
        removeSuppliers
    };

    upsertMapping &&
        upsertMapping(
            data,
            () => {
                closeEditPopup();
            },
            (error, errorType) => {
                onError(error, errorType, props);
            }
        );
}

function onError(error, errorType, props) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.entitiesMapping.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={
                    errorType === "warn"
                        ? styles.warningPopupImage
                        : styles.warningErrorPopupImage
                }
                subTitle={get(error, "description", undefined)}
                title={get(error, "title", undefined)}
            />
        )
    });
}

const sortByLabel = (a, b) => {
    return a["label"].localeCompare(b["label"]);
};

const prepareSuppliers = suppliers => {
    const list =
        suppliers &&
        suppliers.map(supplier => {
            const supplierName = get(supplier, "name", undefined);
            const supplierId = get(supplier, "_id", undefined);
            return {
                label: supplierName,
                value: supplierId,
                option: {
                    label: supplierName,
                    id: supplierId
                }
            };
        });

    return list ? list.sort(sortByLabel) : [];
};

class EditLegalEntitieSuppliersForm extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        entry: PropTypes.object,
        getSuppliers: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        suppliers: PropTypes.array,
        mapping: PropTypes.object,
        upsertMapping: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { getSuppliers } = this.props;

        getSuppliers && getSuppliers();
    }

    prepareLegalEntities = () => {
        const { legalEntities, entry } = this.props;

        const legalEntitiesNotMapped = entry
            ? legalEntities
            : legalEntities &&
              legalEntities.filter(
                  legalEntity =>
                      get(legalEntity, "suppliers", []) &&
                      get(legalEntity, "suppliers", []).length === 0
              );

        const list =
            legalEntitiesNotMapped &&
            legalEntitiesNotMapped.map(legalEntity => {
                const legalEntityName = get(legalEntity, "name", undefined);
                const legalEntityId = get(legalEntity, "_id", undefined);
                return {
                    label: legalEntityName,
                    value: legalEntityId,
                    option: {
                        label: legalEntityName,
                        id: legalEntityId
                    }
                };
            });

        return list ? list.sort(sortByLabel) : [];
    };

    render() {
        const { entry, suppliers } = this.props;

        const defaultSuppliers = prepareSuppliers(
            get(entry, "suppliers_data", undefined)
        );

        const suppliersOptions = prepareSuppliers(suppliers);
        const legalEntitiesOptions = this.prepareLegalEntities();

        return (
            <form style={styles.container}>
                <Field
                    component={Select}
                    disabled={!!entry}
                    isClearable
                    isSearchable
                    label={translation.entitiesMapping.editPopup.legalEntity}
                    name="legalEntity"
                    placeholder={""}
                    options={legalEntitiesOptions}
                    validate={required}
                />
                <div>
                    <div style={styles.fieldTitle}>
                        {translation.entitiesMapping.editPopup.suppliers}
                    </div>

                    <Field
                        component={Select}
                        controlStyle={styles.selectControl}
                        defaultOption={defaultSuppliers}
                        editable
                        hideSelectedOptions
                        isClearable
                        isMulti
                        isSearchable
                        menuContainerStyle={styles.selectMenuContainer}
                        menuListContainerStyle={styles.selectMenu}
                        multiValueLabelStyle={styles.selectMultiValueLabel}
                        multiValueRemoveStyle={styles.selectMultiValueRemove}
                        multiValueStyle={styles.selectMultiValue}
                        name="suppliers"
                        noOptionsMessage={translation.labels.noOptions}
                        options={suppliersOptions}
                        placeholder={""}
                        validate={requiredArray}
                    />
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { entry } = props;

    return {
        initialValues: {
            legalEntity: get(entry, "_id", undefined),
            suppliers: prepareSuppliers(get(entry, "suppliers_data", undefined))
        },
        legalEntities: state.entitiesMapping.dataByTab.legalEntities.entries,
        suppliers: state.entitiesMapping.dataByTab.suppliers.entries,
        tab: state.entitiesMapping.tab
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    closePopup,
    getSuppliers,
    openPopup,
    upsertMapping
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editLegalEntityMapping",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EditLegalEntitieSuppliersForm)
);
