// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submit } from "redux-form";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import debounce from "lodash/debounce";
// Components
import { EditUserForm, UserRow } from "./components";
import {
    Filter,
    MaterialFabButton,
    SearchBar,
    TableHeaders,
    WarningPopup
} from "../../../components";
// Actions
import { clearUsers, deleteUser, getUsers } from "./Users.ducks";
import { closePopup, openPopup } from "../../../components/Popup/Popup.ducks";
import {
    closeEditPopup,
    openEditPopup
} from "../../../components/EditPopup/EditPopup.ducks";
import {
    getLegalEntities,
    getCooperationPartners
} from "./components/EditUserForm/EditUserForm.ducks";
// Helpers
import {
    getRolesCreatePermission,
    getRolesUpdatePermission,
    hasPermissionToDelete,
    hasPermissionToEdit
} from "./helpers/usersPermissions";
import { buildEmailData } from "./helpers/emailDataBuilder";
import {
    getAllowedRolesFilters,
    getBaseQueryRolesFilter,
    getFilteredUsersBySearchQuery,
    rolesFilters
} from "./helpers/filterUsers";
// Styles
import styles from "./Users.css";
// Translations
import translation from "../../../config/translation";

class Users extends PureComponent {
    static propTypes = {
        auth: PropTypes.object,
        clearUsers: PropTypes.func.isRequired,
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        deleteUser: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        editUserForm: PropTypes.object,
        editPopup: PropTypes.object,
        getLegalEntities: PropTypes.func.isRequired,
        getCooperationPartners: PropTypes.func.isRequired,
        getUsers: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        cooperationPartner: PropTypes.array,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        profileData: PropTypes.object,
        users: PropTypes.array
    };

    headers = [
        { label: translation.users.tableHeaders.user },
        { label: translation.users.tableHeaders.firstName },
        { label: translation.users.tableHeaders.surname },
        { label: translation.users.tableHeaders.email },
        {
            label: translation.users.tableHeaders.role,
            style: styles.roleHeader
        },
        { label: translation.users.tableHeaders.status }
    ];

    state = {
        filter: [],
        hasFilter: false,
        searchQuery: undefined,
        users: []
    };

    componentDidMount() {
        const {
            getLegalEntities,
            getCooperationPartners,
            getUsers,
            profileData
        } = this.props;

        const queryFilters = getBaseQueryRolesFilter({ profileData });

        this.setState({ filter: queryFilters });

        getUsers(queryFilters);
        getLegalEntities && getLegalEntities();
        getCooperationPartners && getCooperationPartners();
    }

    componentDidUpdate(prevProps) {
        const { users } = this.props;
        const { searchQuery } = this.state;

        const oldValues =
            (prevProps.users && JSON.stringify(prevProps.users)) || [];
        const nextValues = JSON.stringify(users);

        if (oldValues !== nextValues) {
            const filteredUsers = getFilteredUsersBySearchQuery(
                searchQuery,
                users
            );
            this.setState({ users: filteredUsers });
        }
    }

    componentWillUnmount() {
        const {
            clearUsers,
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
        clearUsers && clearUsers();
    }

    handleCancelEditPopup = () => {
        const { closeEditPopup, editUserForm, closePopup, openPopup } =
            this.props;

        let initialValues = Object.assign(
            {},
            get(editUserForm, "initial", undefined)
        );
        delete initialValues["entity"];
        let newValues = Object.assign(
            {},
            get(editUserForm, "values", undefined)
        );
        delete newValues["entity"];
        const valuesHasChanged = !isEqual(initialValues, newValues);

        if (valuesHasChanged) {
            openPopup({
                children: (
                    <WarningPopup
                        buttonAction={() => {
                            closeEditPopup();
                            closePopup();
                        }}
                        buttonLabel={translation.users.editPopup.confirm}
                        closeFunction={() => {
                            closePopup();
                        }}
                        imageStyle={styles.warningPopupImage}
                        subTitle={
                            translation.users.editPopup.unsavedWarningMessage
                        }
                        title={translation.users.editPopup.unsavedChanges}
                    />
                )
            });
        } else {
            closeEditPopup();
        }
    };

    handleDeleteUser = user => {
        const {
            closeEditPopup,
            closePopup,
            deleteUser,
            legalEntities,
            cooperationPartner,
            openPopup
        } = this.props;

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        const emailData = buildEmailData(
                            user,
                            legalEntities,
                            cooperationPartner,
                            undefined
                        );

                        deleteUser &&
                            deleteUser(
                                get(user, "uid", undefined),
                                this.state,
                                emailData,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                }
                            );
                    }}
                    buttonLabel={translation.users.editPopup.confirm}
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImageDeleteUser}
                    subTitle={translation.users.editPopup.deleteUserMessage}
                    title={translation.users.editPopup.deleteUserTitle}
                />
            )
        });
    };

    editPopup = (user = undefined) => {
        const { auth, dispatch, openEditPopup, profileData } = this.props;

        openEditPopup &&
            openEditPopup({
                cancelAction: this.handleCancelEditPopup,
                cancelButtonLabel: translation.users.editPopup.cancel,
                children: (
                    <EditUserForm
                        getFilters={() => {
                            return this.state;
                        }}
                        permissions={
                            user
                                ? getRolesUpdatePermission(profileData)
                                : getRolesCreatePermission(profileData)
                        }
                        user={user}
                    />
                ),
                confirmAction: () => {
                    dispatch(submit("editUser"));
                },
                confirmButtonLabel: user
                    ? translation.users.editPopup.saveChanges
                    : translation.users.editPopup.createUser,
                header: user
                    ? translation.users.editPopup.editUser
                    : translation.users.editPopup.createUser,
                optionalAction:
                    user && hasPermissionToDelete({ user, profileData, auth })
                        ? () => {
                              this.handleDeleteUser(user);
                          }
                        : undefined,
                optionalButtonLabel: translation.users.editPopup.deleteUser
            });
    };

    applyRoleFilter = roleSelected => {
        const { getUsers, profileData } = this.props;

        const role = get(roleSelected, "key", "all");

        const queryFilters = getBaseQueryRolesFilter({ profileData });
        if (role === "all") {
            this.setState({ filter: queryFilters, hasFilter: false });

            getUsers && getUsers(queryFilters);

            return;
        }

        let filter = queryFilters && queryFilters.find(qf => qf.role === role);
        !filter && (filter = { role });

        this.setState({ filter: [filter], hasFilter: true });

        getUsers && getUsers([filter], true);
    };

    handleSearch = value => {
        const { users } = this.props;
        const { searchQuery } = this.state;

        if (value !== searchQuery) {
            let filteredUsers = getFilteredUsersBySearchQuery(value, users);

            this.setState({ searchQuery: value || "", users: filteredUsers });
        }
    };

    debounceSearchOnChange = debounce(this.handleSearch, 750);

    render() {
        const { auth, profileData } = this.props;
        const { users } = this.state;

        const canCreate = getRolesCreatePermission(profileData);
        const allowedFilters = getAllowedRolesFilters({ profileData });

        return (
            <div style={styles.container}>
                <div style={styles.headerTitle}>
                    {translation.navigationMenu.userManagement}
                </div>
                {allowedFilters && (
                    <div style={styles.searchBarAndFilterAndAddButtonContainer}>
                        <SearchBar
                            icon="icon-search"
                            onChange={this.debounceSearchOnChange}
                            placeholder={translation.users.search}
                            style={{
                                container: styles.searchBarContainer,
                                input: styles.searchBarInput
                            }}
                        />
                        <Filter
                            defaultOption={rolesFilters[0]}
                            onSelection={this.applyRoleFilter}
                            options={allowedFilters}
                        />
                        {canCreate && canCreate.length > 0 && (
                            <MaterialFabButton
                                onClick={() => {
                                    this.editPopup();
                                }}
                                style={styles.addButtonContainer}
                                variant="circular"
                            >
                                <span
                                    className="icon-plus"
                                    style={styles.addButtonIcon}
                                />
                            </MaterialFabButton>
                        )}
                    </div>
                )}
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...(allowedFilters ? {} : styles.tableGridMargin)
                    }}
                    headers={this.headers}
                />
                {users &&
                    users.map((user, index) => {
                        return (
                            <UserRow
                                containerStyle={styles.tableGrid}
                                hasEditPermission={hasPermissionToEdit({
                                    user,
                                    profileData,
                                    auth
                                })}
                                key={index}
                                openEditPopup={() => this.editPopup(user)}
                                user={user}
                            />
                        );
                    })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        editUserForm: get(state, "form.editUser", undefined),
        editPopup: state.editPopup,
        legalEntities: state.editUser.legalEntities,
        cooperationPartner: state.editUser.cooperationPartner,
        popup: state.popup,
        profileData: state.profileData,
        users: state.users.users
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                clearUsers,
                closeEditPopup,
                closePopup,
                deleteUser,
                getLegalEntities,
                getCooperationPartners,
                getUsers,
                openEditPopup,
                openPopup
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
