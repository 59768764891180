// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { NavigationMenuItem, SubMenu } from "./components";
// Action
import { navigateTo } from "../../Router.ducks";
// Helpers
import {
    getMenusBasedOnRolePermissions,
    MENUS
} from "./helpers/navigationHelper";
// Translation
import translation from "../../../../config/translation";
// Styles
import styles from "./NavigationBar.css";

class NavigationBar extends PureComponent {
    static propTypes = {
        location: PropTypes.object,
        navigateTo: PropTypes.func.isRequired,
        userRole: PropTypes.object
    };

    state = {
        activeMenu: undefined,
        activeSubMenu: undefined
    };

    componentDidMount() {
        this.updateActiveMenu();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname }
        } = this.props;

        if (pathname !== get(prevProps, "location.pathname", undefined)) {
            this.updateActiveMenu();
        }
    }

    updateActiveMenu() {
        const {
            location: { pathname }
        } = this.props;

        let menuItem = MENUS.slice(1).find(
            item => pathname && pathname.startsWith(item.path)
        );

        if (!menuItem) {
            menuItem =
                pathname === "/"
                    ? MENUS.find(item => item.key === "dashboard")
                    : undefined;
        }

        if (!menuItem) {
            this.setState({ activeMenu: undefined, activeSubMenu: undefined });
            return null;
        }

        const newActiveSubMenu = (get(menuItem, "subMenus", []) || []).find(
            item => pathname && pathname.startsWith(item.path)
        );

        const { activeMenu, activeSubMenu } = this.state;
        if (activeMenu !== menuItem || activeSubMenu !== newActiveSubMenu) {
            this.setState({
                activeMenu: menuItem,
                activeSubMenu: newActiveSubMenu
            });
        }
    }

    updateActiveSubMenu = submenu => {
        this.setState({
            activeSubMenu: submenu
        });
    };

    handleMenuItemClick = menuItem => {
        if (!menuItem) {
            return null;
        }
        const { navigateTo } = this.props;
        const { activeMenu } = this.state;

        const menusWithDeepLevels = ["offerManagement"];
        if (
            !activeMenu ||
            activeMenu.key !== menuItem.key ||
            menusWithDeepLevels.includes(menuItem.key)
        ) {
            let path = menuItem.path;

            if (menuItem.subMenus && menuItem.subMenus.length > 0) {
                path = menuItem.subMenus[0].path;
            }

            navigateTo && navigateTo(path);
        }
    };

    render() {
        const { userRole } = this.props;
        const { activeMenu, activeSubMenu } = this.state;

        const navMenusWithPermissions =
            getMenusBasedOnRolePermissions(userRole);

        return (
            <div style={styles.container}>
                <div style={styles.menu}>
                    {navMenusWithPermissions.map(menuItem => {
                        return (
                            <NavigationMenuItem
                                active={
                                    activeMenu &&
                                    activeMenu.key === menuItem.key
                                }
                                disabled={menuItem.disabled}
                                icon={
                                    <span
                                        className={menuItem.icon}
                                        style={styles.itemIcon}
                                    />
                                }
                                key={menuItem.key}
                                label={translation.navigationMenu[menuItem.key]}
                                onClick={() => {
                                    this.handleMenuItemClick(menuItem);
                                }}
                            />
                        );
                    })}
                </div>
                <SubMenu
                    activeMenu={activeMenu}
                    activeSubMenu={activeSubMenu}
                    updateActiveSubMenu={this.updateActiveSubMenu}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userRole: state.profileData.role
    };
};

const mapDispatchToProps = {
    navigateTo
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
