import Global from "../../../../../../../config/global.css";

const styles = {
    container: {
        display: "grid",
        gap: "8px",
        height: "fit-content"
    },
    innerContainer: {
        border: `1px dashed ${Global.colors.darkSlateBlue}`,
        borderRadius: "8px",
        display: "grid",
        gap: "16px",
        height: "fit-content",
        padding: "16px 20px"
    },
    title: {
        color: Global.colors.blueyGrey,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "21px"
    }
};

export default styles;
