// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// Styles
import styles from "./NavigationMenuItem.css";

class NavigationMenuItem extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        icon: PropTypes.node.isRequired,
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
            .isRequired,
        onClick: PropTypes.func.isRequired
    };

    state = {
        onHover: false
    };

    handleMouseEnter = () => {
        this.setState({ onHover: true });
    };

    handleMouseLeave = () => {
        this.setState({ onHover: false });
    };

    render() {
        const { active, disabled, icon, label, onClick } = this.props;
        const { onHover } = this.state;

        return (
            <div
                onClick={onClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                style={styles.container}
            >
                <div
                    style={{
                        ...styles.innerContainer,
                        ...(onHover ? styles.onHover : {}),
                        ...(active ? styles.active : {}),
                        ...(disabled ? styles.disabled : {})
                    }}
                >
                    <div
                        style={{
                            ...styles.iconContainer
                        }}
                    >
                        {icon}
                    </div>
                    {onHover && <div style={styles.label}>{label}</div>}
                </div>
            </div>
        );
    }
}

export default NavigationMenuItem;
