import Global from "../../config/global.css";

const styles = {
    childrenContainer: {
        display: "flex",
        flexDirection: "column",
        minHeight: "fit-content"
    },
    childrenInnerContainer: {
        display: "grid",
        gridAutoRows: "64px",
        gap: "16px"
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        minHeight: "fit-content"
    },
    contentContainer: {
        minHeight: "fit-content"
    },
    errorMessageCloseIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    },
    loginButton: {
        minHeight: "48px",
        width: "180px"
    },
    loginContainer: {
        flex: 1,
        height: "unset",
        minHeight: "min-content"
    },
    loginIcon: {
        color: Global.colors.white,
        fontSize: "20px"
    },
    logo: {
        height: "48px"
    },
    logoContainer: {
        maxWidth: "325px"
    },
    passwordForgottenButton: {
        container: {
            marginTop: "6px",
            textAlign: "end"
        }
    },
    passwordIcon: {
        color: Global.colors.blueyGrey,
        cursor: "pointer",
        fontSize: "24px"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    },
    subtitleBold: {
        fontWeight: "bold"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "30px"
    },
    contactInfo: {
        marginTop: "10px"
    }
};

export default styles;
