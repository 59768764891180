// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import differenceWith from "lodash.differencewith";
import isEqual from "lodash.isequal";
// Components
import { Select, WarningPopup } from "../../../../../../../components";
// Form
import { Field, reduxForm } from "redux-form";
// Styles
import styles from "./EditSupplierLegalEntitiesForm.css";
// Validators
import { required } from "../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../config/translation";
// Actions
import { getSuppliers, upsertMapping } from "../../../../EntitiesMapping.ducks";
import { closeEditPopup } from "../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../components/Popup/Popup.ducks";
import { Tax, ContractStatus } from "./components";

function onError(error, errorType, props) {
    const { closePopup, openPopup } = props;

    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    closePopup();
                }}
                buttonLabel={translation.entitiesMapping.tryAgain}
                closeFunction={() => {
                    closePopup();
                }}
                imageStyle={
                    errorType === "warn"
                        ? styles.warningPopupImage
                        : styles.warningErrorPopupImage
                }
                subTitle={get(error, "description", undefined)}
                title={get(error, "title", undefined)}
            />
        )
    });
}

function onSubmit(values, props) {
    const { closeEditPopup, entry, upsertMapping, tab } = props;

    const newValuesIds = {
        legalEntities: [values.legalEntities],
        supplier: values.supplier.value
    };

    let addSuppliers = [];
    let removeSuppliers = [];

    if (entry) {
        const initialValuesIds = {
            legalEntities: entry.legal_entities.map(({ _id }) => _id),
            supplier: entry._id
        };
        const newLegalEntities = differenceWith(
            newValuesIds.legalEntities,
            initialValuesIds.legalEntities,
            isEqual
        );
        const removedLegalEntities = differenceWith(
            initialValuesIds.legalEntities,
            newValuesIds.legalEntities,
            isEqual
        );

        addSuppliers = newLegalEntities.map(legalEntity => {
            return {
                legalEntity: legalEntity.value,
                suppliers: [initialValuesIds.supplier]
            };
        });

        removeSuppliers = removedLegalEntities.map(legalEntity => {
            return {
                legalEntity: legalEntity,
                suppliers: [initialValuesIds.supplier]
            };
        });
    } else {
        addSuppliers = newValuesIds.legalEntities.map(({ value }) => {
            return {
                legalEntity: value,
                suppliers: [newValuesIds.supplier]
            };
        });
    }

    const data = {
        operation: entry ? "update" : "create",
        tab,
        type: "mapp",
        addSuppliers,
        removeSuppliers,
        supplierId: entry
            ? entry._id != values.supplier
                ? values.supplier
                : entry._id
            : values.supplier.value,
        taxType: entry
            ? entry.taxType != values.taxType
                ? values.taxType
                : entry.taxType
            : values.taxType,
        contractStatus: entry
            ? entry.contractStatus != values.contractStatus
                ? values.contractStatus
                : entry.contractStatus
            : values.contractStatus,
        legalEntity: entry
            ? entry.legal_entities[0]?._id != values.legalEntities
                ? values.legalEntities.value
                : entry.legal_entities[0]._id
            : values.legalEntities.value
    };

    upsertMapping &&
        upsertMapping(
            data,
            () => {
                closeEditPopup();
            },
            (error, errorType) => {
                onError(error, errorType, props);
            }
        );
}

const prepareLegalEntities = legalEntities => {
    const list =
        legalEntities &&
        legalEntities.map(legalEntity => {
            const legalEntityName = get(legalEntity, "name", undefined);
            const legalEntityId = get(legalEntity, "_id", undefined);
            return {
                label: legalEntityName,
                value: legalEntityId,
                option: {
                    label: legalEntityName,
                    id: legalEntityId
                }
            };
        });

    return list ? list.sort(sortByLabel) : [];
};

const sortByLabel = (a, b) => {
    return a["label"].localeCompare(b["label"]);
};

class EditSupplierLegalEntitiesForm extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        entry: PropTypes.object,
        getSuppliers: PropTypes.func.isRequired,
        legalEntities: PropTypes.array,
        mapping: PropTypes.object,
        openPopup: PropTypes.func.isRequired,
        suppliers: PropTypes.array,
        upsertMapping: PropTypes.func.isRequired,
        initialValues: PropTypes.object
    };

    componentDidMount() {
        const { getSuppliers } = this.props;

        getSuppliers && getSuppliers();
    }

    prepareSuppliers = () => {
        const { suppliers, entry } = this.props;

        const suppliersNotMapped = entry
            ? suppliers
            : suppliers &&
              suppliers.filter(
                  supplier =>
                      get(supplier, "legal_entities", []) &&
                      get(supplier, "legal_entities", []).length === 0
              );

        const list =
            suppliersNotMapped &&
            suppliersNotMapped.map(supplier => {
                const supplierName = get(supplier, "name", undefined);
                const supplierId = get(supplier, "_id", undefined);
                return {
                    label: supplierName,
                    value: supplierId,
                    option: {
                        label: supplierName,
                        id: supplierId
                    }
                };
            });

        return list ? list.sort(sortByLabel) : [];
    };

    render() {
        const { entry, legalEntities, initialValues } = this.props;

        const legalEntitiesOptions = prepareLegalEntities(legalEntities);
        const suppliersOptions = this.prepareSuppliers();

        return (
            <form style={styles.container}>
                <Field
                    component={Select}
                    disabled={!!entry}
                    isClearable
                    isSearchable
                    label={translation.entitiesMapping.editPopup.suppliers}
                    name="supplier"
                    placeholder={""}
                    options={suppliersOptions}
                    validate={required}
                />
                <div>
                    <div style={styles.fieldTitle}>
                        {translation.entitiesMapping.editPopup.legalEntity}
                    </div>
                    <Field
                        component={Select}
                        isClearable
                        isSearchable
                        name="legalEntities"
                        placeholder={""}
                        options={legalEntitiesOptions}
                        validate={required}
                    />
                </div>
                <ContractStatus contractStatus={initialValues.contractStatus} />
                <Tax taxType={initialValues.taxType} />
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { entry } = props;
    return {
        initialValues: {
            supplier: get(entry, "_id", undefined),
            legalEntities: get(entry, "legal_entities[0]._id", undefined),
            taxType: get(entry, "legal_entities[0].tax_type", "excluded"),
            contractStatus: entry
                ? entry.legal_entities[0]?.contracted_suppliers?.includes(
                      entry._id
                  )
                    ? "signed"
                    : "notSigned"
                : "notSigned"
        },
        legalEntities: state.entitiesMapping.dataByTab.legalEntities.entries,
        suppliers: state.entitiesMapping.dataByTab.suppliers.entries,
        tab: state.entitiesMapping.tab
    };
};

const mapDispatchToProps = {
    closeEditPopup,
    closePopup,
    getSuppliers,
    openPopup,
    upsertMapping
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "editLegalEntityMapping",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EditSupplierLegalEntitiesForm)
);
