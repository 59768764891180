// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { isArray } from "lodash";
// Components
import { TableHeaders, WarningPopup } from "../../../../../components";
import { ChamberLocationDetails } from "..";
// Actions
import {
    closeEditPopup,
    openEditPopup
} from "../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import {
    upsertChamberLocation,
    deleteOnlineKeyword
} from "../../ChambersLocations.ducks";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./ChambersLocationsOnlineKeywords.css";

class LegalEntitiesSuppliers extends PureComponent {
    static propTypes = {
        closeEditPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        dispatch: PropTypes.func,
        editPopup: PropTypes.object,
        entries: PropTypes.array.isRequired,
        openEditPopup: PropTypes.func.isRequired,
        openPopup: PropTypes.func.isRequired,
        popup: PropTypes.object,
        upsertChamberLocation: PropTypes.func.isRequired,
        deleteOnlineKeyword: PropTypes.func.isRequired,
        RowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
            .isRequired,
        tab: PropTypes.string.isRequired,
        tableHeaders: PropTypes.array.isRequired
    };

    componentWillUnmount() {
        const {
            closeEditPopup,
            closePopup,
            editPopup: { opened },
            popup
        } = this.props;

        opened && closeEditPopup && closeEditPopup();
        popup && popup.opened && closePopup && closePopup();
    }

    handleRemoveLocation = entry => {
        const { closeEditPopup, closePopup, openPopup, upsertChamberLocation } =
            this.props;
        const data = {
            legalEntityId: get(entry, "_id", undefined),
            operation: "delete"
        };

        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        upsertChamberLocation &&
                            upsertChamberLocation(
                                data,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                },
                                () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                }}
                                                buttonLabel={
                                                    translation
                                                        .chambersLocations
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation
                                                        .chambersLocations
                                                        .errors.errorDelete
                                                }
                                                title={
                                                    translation
                                                        .chambersLocations
                                                        .errors.genericTitle
                                                }
                                            />
                                        )
                                    });
                                }
                            );
                    }}
                    buttonLabel={
                        translation.chambersLocations.editPopup.confirm
                    }
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.chambersLocations.editPopup
                            .deleteLocationMessage
                    }
                    title={
                        translation.chambersLocations.editPopup
                            .deleteLocationTitle
                    }
                />
            )
        });
    };

    handleDeleteOnlineKeyword = entry => {
        const { closeEditPopup, closePopup, openPopup, deleteOnlineKeyword } =
            this.props;
        const keywordId = entry?._id;
        openPopup({
            children: (
                <WarningPopup
                    buttonAction={() => {
                        deleteOnlineKeyword &&
                            deleteOnlineKeyword(
                                keywordId,
                                () => {
                                    closeEditPopup && closeEditPopup();
                                    closePopup && closePopup();
                                },
                                () => {
                                    openPopup({
                                        children: (
                                            <WarningPopup
                                                buttonAction={() => {
                                                    closePopup();
                                                }}
                                                buttonLabel={
                                                    translation
                                                        .chambersLocations
                                                        .tryAgain
                                                }
                                                closeFunction={() => {
                                                    closePopup();
                                                }}
                                                imageStyle={
                                                    styles.warningErrorPopupImage
                                                }
                                                subTitle={
                                                    translation
                                                        .chambersLocations
                                                        .errors.errorDelete
                                                }
                                                title={
                                                    translation
                                                        .chambersLocations
                                                        .errors.genericTitle
                                                }
                                            />
                                        )
                                    });
                                }
                            );
                    }}
                    buttonLabel={
                        translation.chambersLocations.deletePopup.confirm
                    }
                    closeFunction={() => {
                        closePopup();
                    }}
                    imageStyle={styles.warningPopupImage}
                    subTitle={
                        translation.chambersLocations.deletePopup
                            .deleteOnlineKeywordMessage
                    }
                    title={
                        translation.chambersLocations.deletePopup
                            .deleteOnlineKeywordTitle
                    }
                />
            )
        });
    };

    openEditPopup = entry => {
        const { closeEditPopup, dispatch, openEditPopup } = this.props;

        let editPopupParams = {
            cancelAction: closeEditPopup,
            cancelButtonLabel: translation.chambersLocations.editPopup.cancel,
            confirmAction: () => {
                dispatch(submit("chamberLocation"));
            },
            confirmButtonLabel:
                translation.chambersLocations.editPopup.saveChanges,
            optionalAction: () => this.handleRemoveLocation(entry),
            optionalButtonLabel:
                translation.chambersLocations.editPopup.deleteLocation,
            style: styles.editPopup,
            children: <ChamberLocationDetails entry={entry} />,
            header: translation.chambersLocations.editPopup
                .createChamberLocation
        };

        openEditPopup && openEditPopup(editPopupParams);
    };

    render() {
        const { RowComponent, tableHeaders, entries } = this.props;

        return (
            <Fragment>
                <TableHeaders
                    containerStyle={{
                        ...styles.tableGrid,
                        ...styles.tableHeaders
                    }}
                    headers={tableHeaders}
                />
                <div style={styles.rowsContainer}>
                    {entries &&
                        entries.map((entry, index) =>
                            RowComponent ? (
                                <RowComponent
                                    containerStyle={styles.tableGrid}
                                    entry={entry}
                                    key={index}
                                    openEditPopup={() =>
                                        this.openEditPopup(entry)
                                    }
                                    deletePopup={() =>
                                        this.handleDeleteOnlineKeyword(entry)
                                    }
                                />
                            ) : null
                        )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const tab = get(props, "tab", undefined);

    const entries = isArray(
        get(state, `chambersLocations.dataByTab.${tab}.entries`, [])
    )
        ? get(state, `chambersLocations.dataByTab.${tab}.entries`, [])
        : [];

    const formatedEntries = entries.filter(entry => {
        if (tab === "legalEntities") {
            if (!entry.location_id) {
                return;
            }
            return entry.suppliers;
        } else {
            return entry;
        }
    });

    return {
        editPopup: state.editPopup,
        entries: formatedEntries,
        popup: state.popup,
        searchQuery: state.chambersLocations.searchQuery,
        tab: state.chambersLocations.tab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeEditPopup,
                closePopup,
                openEditPopup,
                openPopup,
                upsertChamberLocation,
                deleteOnlineKeyword
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LegalEntitiesSuppliers);
