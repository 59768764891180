// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MaterialCircularProgress } from "./components/";
// Styles
import styles from "./CircularProgress.css";

class CircularProgress extends PureComponent {
    static propTypes = {
        className: PropTypes.string.isRequired,
        size: PropTypes.number,
        style: PropTypes.shape({
            circle: PropTypes.object,
            gradients: PropTypes.shape({
                1: PropTypes.string,
                2: PropTypes.string
            })
        }),
        thickness: PropTypes.number
    };

    static defaultProps = {
        size: 100,
        thickness: 4
    };

    render() {
        const { className, size, style, thickness } = this.props;

        const gradient1 = get(style, "gradients.1", styles.gradients["1"]);
        const gradient2 = get(style, "gradients.2", styles.gradients["2"]);

        const linearGradientId = `spinner-gradient-${className}`;

        return (
            <Fragment>
                <MaterialCircularProgress
                    linearGradientId={linearGradientId}
                    size={size}
                    style={style}
                    thickness={thickness}
                />
                <svg height="0" width="0" viewBox={`0 0 ${size} ${size}`}>
                    <defs>
                        <linearGradient
                            id={linearGradientId}
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                        >
                            <stop offset="0%" stopColor={gradient1} />
                            <stop offset="100%" stopColor={gradient2} />
                        </linearGradient>
                    </defs>
                </svg>
            </Fragment>
        );
    }
}

export default CircularProgress;
