// Modules
import get from "lodash.get";
// Api
import api from "../../../services/api";
// Actions
import {
    endSpinner,
    startSpinner
} from "../../../components/MaterialSpinner/MaterialSpinner.ducks";
import { openSnackbar } from "../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Translation
import translation from "../../../config/translation";

export const SAVE_MARKETING_MATERIALS = "SAVE_MARKETING_MATERIALS";
export const CLEAR_MARKETING_MATERIALS = "CLEAR_MARKETING_MATERIALS";

export const defaultState = {
    marketingMaterials: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_MARKETING_MATERIALS: {
            return Object.assign({}, state, {
                marketingMaterials: defaultState.marketingMaterials
            });
        }
        case SAVE_MARKETING_MATERIALS: {
            return Object.assign({}, state, {
                marketingMaterials: action.payload
            });
        }

        default:
            return state;
    }
};

export const clearMarketingMaterials = () => {
    return {
        type: CLEAR_MARKETING_MATERIALS
    };
};

export const saveMarketingMaterials = entities => {
    return {
        type: SAVE_MARKETING_MATERIALS,
        payload: entities
    };
};

export const getMarketingMaterials = () => dispatch => {
    dispatch(startSpinner());
    dispatch(clearMarketingMaterials());

    api.get("marketing_materials")
        .then(response => {
            dispatch(saveMarketingMaterials(get(response, "data", [])));
            dispatch(endSpinner());
        })
        .catch(e => {
            console.log("Error calling getMarketingMaterials: ", e);
            dispatch(endSpinner());
        });
};

export const createMarketingMaterial =
    (material, successCb, errorCb) => dispatch => {
        dispatch(startSpinner());

        api.post("marketing_materials", material)
            .then(() => {
                dispatch(getMarketingMaterials());
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.marketingMaterials.success["create"],
                        "success"
                    )
                );
            })
            .catch(() => {
                const errorType = "warn";
                const error = {
                    title: translation.marketingMaterials.errors.genericTitle,
                    description:
                        translation.marketingMaterials.errors.genericErrorCreate
                };
                errorCb && errorCb(error, errorType);
                dispatch(endSpinner());
            });
    };

export const updateMarketingMaterial =
    (material, successCb, errorCb) => dispatch => {
        dispatch(startSpinner());

        api.put(`marketing_materials/${material._id}`, material)
            .then(() => {
                dispatch(getMarketingMaterials());
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.marketingMaterials.success["create"],
                        "success"
                    )
                );
            })
            .catch(() => {
                const errorType = "warn";
                const error = {
                    title: translation.marketingMaterials.errors.genericTitle,
                    description:
                        translation.marketingMaterials.errors.genericErrorCreate
                };
                errorCb && errorCb(error, errorType);
                dispatch(endSpinner());
            });
    };

export const deleteMarketingMaterial =
    (marketingMaterialId, successCb, errorCb) => dispatch => {
        dispatch(startSpinner());

        api.delete(`marketing_materials/${marketingMaterialId}`)
            .then(() => {
                dispatch(getMarketingMaterials());
                successCb && successCb();
                dispatch(endSpinner());
                dispatch(
                    openSnackbar(
                        translation.marketingMaterials.success.delete,
                        "success"
                    )
                );
            })
            .catch(e => {
                console.log("Error calling deleteMarketingMaterial: ", e);
                errorCb && errorCb();
                dispatch(endSpinner());
            });
    };
