import Global from "../../../../../../../../../../../config/global.css";

const styles = {
    container: {
        background: Global.colors.white,
        borderRadius: "12px",
        maxWidth: "356px",
        position: "relative",
        width: "100%",
        zIndex: 0
    },

    title: {
        display: "block",
        fontSize: "16px",
        fontWeight: "bold",
        height: "21px",
        letterSpacing: "0.2em",
        lineHeight: "20px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        width: "calc(100% - 102px)",
        zIndex: 1
    },

    video: {
        width: "100%",
        height: "100%"
    },

    controls: {
        position: "absolute",
        display: "flex",
        bottom: 0,
        width: "100%",
        color: "#ffffff",
        padding: "10px",
        boxSizing: "border-box",
        justifyContent: "space-evenly",
        alignItems: "center",
        background: "#3337"
    },
    playButton: {
        background: Global.colors.blue,
        borderRadius: "20px",
        padding: "5px 5px",
        cursor: "pointer",
        marginRight: "10px"
    },
    seekBar: {
        background: Global.colors.blue
    }
};

export default styles;
