// Modules
import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { change } from "redux-form";
import get from "lodash.get";
import PropTypes from "prop-types";
// Components
import { MaterialTextField } from "../../../../../../../../../components/";
import { Editable, Slate, useSlate, withReact } from "slate-react";
import { Editor, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";
// Helpers
import { deserialize, serialize } from "./helpers/format";
import { LIST_TYPES, initialValue } from "./helpers/elements";
// Styles
import styles from "./TextEditor.css";

function TextEditor({
    defaultValue,
    dispatch,
    fieldName,
    label,
    meta,
    offerDetailsForm
}) {
    TextEditor.propTypes = {
        defaultValue: PropTypes.array,
        dispatch: PropTypes.func.isRequired,
        fieldName: PropTypes.string,
        label: PropTypes.string.isRequired,
        meta: PropTypes.object,
        offerDetailsForm: PropTypes.object
    };

    const breakLine = editor => {
        Editor.insertText(editor, "\n");
    };

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format);
        const isList = LIST_TYPES.includes(format);

        // Support for list of lists
        if (isActive && isList && format === "bulleted-list") {
            const node = {
                type: format,
                children: [{ type: "list-item", children: [{ text: "" }] }]
            };
            Editor.insertNode(editor, node);
            return;
        }

        Transforms.unwrapNodes(editor, {
            match: n => LIST_TYPES.includes(n.type),
            split: true
        });

        Transforms.setNodes(editor, {
            type: isActive ? "paragraph" : isList ? "list-item" : format
        });

        if (!isActive && isList) {
            const block = { type: format, children: [] };
            Transforms.wrapNodes(editor, block);
        }
    };

    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format);

        if (isActive) {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    };

    const isBlockActive = (editor, format) => {
        const [match] = Editor.nodes(editor, {
            match: n => n.type === format
        });

        return !!match;
    };

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    };
    const Element = ({ attributes, children, element }) => {
        Element.propTypes = {
            attributes: PropTypes.object,
            children: PropTypes.array,
            element: PropTypes.object
        };

        switch (element.type) {
            case "quote":
                return <blockquote {...attributes}>{children}</blockquote>;
            case "code":
                return (
                    <pre>
                        <code {...attributes}>{children}</code>
                    </pre>
                );
            case "bulleted-list":
                return (
                    <ul {...attributes} style={styles.ul}>
                        {children}
                    </ul>
                );
            case "heading-one":
            case "heading-three":
                return (
                    <h3 {...attributes} style={styles.h3}>
                        {children}
                    </h3>
                );
            case "heading-two":
            case "heading-four":
                return (
                    <h4 {...attributes} style={styles.h4}>
                        {children}
                    </h4>
                );
            case "heading-five":
                return <h5 {...attributes}>{children}</h5>;
            case "heading-six":
                return <h6 {...attributes}>{children}</h6>;
            case "numbered-list":
                return <ol {...attributes}>{children}</ol>;
            case "list-item":
                return (
                    <li {...attributes} style={styles.li}>
                        {children}
                    </li>
                );
            case "paragraph":
                return (
                    <p {...attributes} style={styles.p}>
                        {children}
                    </p>
                );
            case "div":
                return (
                    <div {...attributes} style={styles.div}>
                        {children}
                    </div>
                );
            case "span":
                return (
                    <span {...attributes} style={styles.span}>
                        {children}
                    </span>
                );
            case "link":
                return (
                    <a href={element.url} {...attributes}>
                        {children}
                    </a>
                );
            default:
                return (
                    <span {...attributes} style={styles.span}>
                        {children}
                    </span>
                );
        }
    };

    const Leaf = ({ attributes, children, leaf }) => {
        Leaf.propTypes = {
            attributes: PropTypes.object,
            children: PropTypes.object,
            leaf: PropTypes.object
        };

        if (leaf.bold) {
            children = <strong>{children}</strong>;
        }
        if (leaf.code) {
            children = <code>{children}</code>;
        }

        if (leaf.italic) {
            children = <em>{children}</em>;
        }

        if (leaf.underline) {
            children = <u>{children}</u>;
        }

        if (leaf.strikethrough) {
            children = <del>{children}</del>;
        }

        return <span {...attributes}>{children}</span>;
    };

    const Button = React.forwardRef(({ active, ...props }, ref) => {
        Button.displayName = "Button";
        Button.propTypes = {
            active: PropTypes.bool
        };

        return (
            <span
                {...props}
                ref={ref}
                style={{
                    ...styles.button,
                    ...(active ? styles.buttonActive : {})
                }}
            />
        );
    });

    const Icon = React.forwardRef(({ className, label, ...props }, ref) => {
        Icon.displayName = "Icon";
        Icon.propTypes = {
            className: PropTypes.string
        };

        return (
            <span
                {...props}
                ref={ref}
                className={className}
                style={label ? styles.label : styles.icon}
            >
                {label}
            </span>
        );
    });

    const BlockButton = ({ format, icon, label }) => {
        BlockButton.propTypes = {
            format: PropTypes.string.isRequired,
            icon: PropTypes.string,
            label: PropTypes.string
        };

        const editor = useSlate();
        return (
            <Button
                active={isBlockActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault();
                    toggleBlock(editor, format);
                }}
            >
                <Icon className={icon} label={label} />
            </Button>
        );
    };

    const MarkButton = ({ format, icon, label }) => {
        MarkButton.propTypes = {
            format: PropTypes.string.isRequired,
            icon: PropTypes.string,
            label: PropTypes.string
        };

        const editor = useSlate();
        return (
            <Button
                active={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault();
                    toggleMark(editor, format);
                }}
            >
                <Icon className={icon} label={label} />
            </Button>
        );
    };

    const withHtml = editor => {
        const { insertData, isInline } = editor;

        editor.isInline = element => {
            return get(element, "type", "") === "link"
                ? true
                : isInline(element);
        };

        editor.insertData = data => {
            const html = data.getData("text/html");

            if (html) {
                const parsed = new DOMParser().parseFromString(
                    html,
                    "text/html"
                );
                const fragment = deserialize(parsed.body);
                Transforms.insertFragment(editor, fragment);
                return;
            }

            insertData(data);
        };

        return editor;
    };

    let desc =
        offerDetailsForm &&
        offerDetailsForm.initial &&
        offerDetailsForm.initial[fieldName];
    let descStructure = undefined;
    if (desc && desc.trim().length > 0) {
        desc = desc.trim().replace(/(\r\n|\n|\r)/gm, "");
        let document = new DOMParser().parseFromString(desc, "text/html");
        if (
            !Array.from(document.body.childNodes).some(
                node => node.nodeType === 1
            )
        ) {
            document = new DOMParser().parseFromString(
                `<p>${desc}</p>`,
                "text/html"
            );
        }
        descStructure = deserialize(document.body);
    }

    const [value, setValue] = useState(
        descStructure || defaultValue || initialValue
    );
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(
        () => withHtml(withHistory(withReact(createEditor()))),
        []
    );

    return (
        <div style={styles.container}>
            <MaterialTextField
                InputLabelProps={{ shrink: true }}
                label={label}
                name={fieldName}
                variant="outlined"
                style={{
                    ...styles.disabled,
                    ...styles.textField
                }}
                error={Boolean(meta.touched && meta.error)}
                errorMessage={(meta.touched && meta.error) || null}
            />
            <div style={styles.innerContainer}>
                <Slate
                    editor={editor}
                    value={value}
                    onChange={value => {
                        // If empty set undefined to display field error
                        const htmlValue = serialize(value);
                        dispatch(change("offerDetails", fieldName, htmlValue));

                        setValue(value);
                    }}
                >
                    <div style={styles.toolbar}>
                        <BlockButton format="heading-three" label="H3" />
                        <BlockButton format="heading-four" label="H4" />
                        <BlockButton format="paragraph" label="P" />
                        <MarkButton format="bold" label="B" />
                        <BlockButton
                            format="bulleted-list"
                            icon="icon-bullet-list"
                        />
                    </div>
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck
                        style={styles.editor}
                        onKeyDown={event => {
                            if (event.key === "Enter" && event.shiftKey) {
                                event.preventDefault();
                                breakLine(editor);
                            }
                        }}
                    />
                </Slate>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        offerDetailsForm: get(state, "form.offerDetails", undefined)
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
