import Global from "../../config/global.css";

const styles = {
    gradients: {
        1: Global.colors.darkSlateBlue,
        2: Global.colors.blue
    }
};

export default styles;
