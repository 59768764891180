import Global from "../../../config/global.css";

const styles = {
    container: {
        padding: "88px 32px 48px",
        textAlign: "center"
    },
    details: {
        content: {
            captions: {
                container: {
                    display: "grid",
                    gap: "16px",
                    marginTop: "8px"
                },
                innerContainer: {
                    alignItems: "center",
                    display: "grid",
                    gap: "8px",
                    gridAutoFlow: "column",
                    gridAutoColumns: "auto 1fr"
                },
                key: {
                    alignItems: "center",
                    alignSelf: "flex-start",
                    backgroundColor: Global.colors.blue,
                    borderRadius: "100px",
                    color: Global.colors.white,
                    display: "flex",
                    fontSize: "9px",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    justifyContent: "center",
                    height: "16px",
                    lineHeight: "11px",
                    width: "16px"
                },
                title: {
                    lineHeight: "16px"
                }
            },
            container: {
                display: "grid",
                height: "min-content",
                gap: "24px",
                minWidth: "min-content",
                padding: "24px 64px 32px"
            },
            image: {
                justifySelf: "center",
                maxWidth: "640px"
            },
            innerContainer: {
                display: "grid",
                gap: "16px"
            },
            section: {
                container: {
                    display: "grid",
                    gap: "16px",
                    marginTop: "8px"
                },
                imagesContainer: {
                    display: "grid",
                    gap: "8px",
                    gridAutoFlow: "column",
                    justifyContent: "center"
                },
                innerContainer: {
                    display: "grid",
                    gap: "8px"
                },
                description: {
                    color: Global.colors.blueyGrey,
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    lineHeight: "1.4em"
                },
                title: {
                    color: Global.colors.darkSlateBlue,
                    display: "flex",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    lineHeight: "13px",
                    textTransform: "uppercase"
                }
            },
            separator: {
                borderTop: `1px dashed ${Global.colors.paleGrey}`
            },
            text: {
                alignItems: "center",
                color: Global.colors.darkSlateBlue,
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "normal",
                lineHeight: "1.4em"
            },
            title: {
                alignItems: "center",
                color: Global.colors.darkSlateBlue,
                display: "flex",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "1.4em",
                marginBottom: "-8px"
            }
        },
        header: {
            icon: {
                fontSize: "24px",
                height: "24px",
                width: "24px"
            },
            title: {
                alignItems: "center",
                color: Global.colors.darkSlateBlue,
                display: "grid",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "normal",
                lineHeight: "23px",
                gap: "16px",
                gridAutoFlow: "column",
                gridAutoColumns: "auto 1fr"
            }
        },
        popup: {
            body: { height: "calc(100vh - 168px)" },
            container: { top: "24px" }
        }
    },
    headerTitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "26px",
        paddingBottom: "16px"
    },
    innerContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "auto",
        maxWidth: "792px"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        marginBottom: "20px"
    }
};

export default styles;
