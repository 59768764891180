// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Styles
import styles from "./TableRow.css";

class TableRow extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        containerStyle: PropTypes.object,
        disableOnHover: PropTypes.bool,
        id: PropTypes.string,
        onClick: PropTypes.func
    };

    state = {
        onHover: undefined
    };

    handleOnHover = () => {
        this.setState({ onHover: true });
    };

    handleOnBlur = () => {
        this.setState({ onHover: false });
    };

    handleOnClick = event => {
        const { onClick } = this.props;

        const nodeName = get(event, "target.nodeName", "");

        ((nodeName.toLowerCase() === "button" &&
            !get(event, "target.className", "").includes("icon-arrow-down")) ||
            get(event, "target.className", "").includes("blur-on-click")) &&
            this.handleOnBlur();

        onClick && onClick(event);
    };

    render() {
        const { children, containerStyle, disableOnHover, id } = this.props;
        const { onHover } = this.state;

        return (
            <div
                id={id}
                onMouseEnter={this.handleOnHover}
                onMouseLeave={this.handleOnBlur}
                onClick={this.handleOnClick}
                style={{
                    ...containerStyle,
                    ...(onHover && !disableOnHover ? styles.onHover : {})
                }}
            >
                {children}
            </div>
        );
    }
}

export default TableRow;
