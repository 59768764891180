// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Translations
import translation from "../../../../config/translation";
// Styles
import styles from "./StatusTag.css";

const StatusTag = ({ status }) => {
    return (
        <div
            style={{
                ...styles.container,
                ...get(styles, `status.${status}`, {})
            }}
        >
            {get(translation.offers.offerStatuses, status, undefined)}
        </div>
    );
};

StatusTag.propTypes = {
    status: PropTypes.string.isRequired
};

export default StatusTag;
