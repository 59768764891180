// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CustomSelect,
    CustomTooltip,
    IconButton,
    TableRow
} from "../../../../../components";
//Actions
import {
    closePopup,
    openPopup
} from "../../../../../components/Popup/Popup.ducks";
import { closeEditPopup } from "../../../../../components/EditPopup/EditPopup.ducks";
// Helpers
import { hasPermission } from "../../helpers/chambersLocationsPermissions";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./ChambersLocationRow.css";

class CustomEditIconButton extends PureComponent {
    static propTypes = {
        handleTooltipOnHover: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    render() {
        const { handleTooltipOnHover, openEditPopup } = this.props;

        return (
            <IconButton
                defaultClassName="icon-edit"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover &&
                        handleTooltipOnHover(undefined, false);
                    openEditPopup && openEditPopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    }
}

class ChambersLocationRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        entry: PropTypes.object.isRequired,
        locations: PropTypes.array,
        openEditPopup: PropTypes.func.isRequired,
        profileData: PropTypes.object
    };

    state = {
        tooltipOpened: undefined
    };

    handleTooltipOnHover = (tooltip, onHover) => {
        this.setState({ tooltipOpened: onHover ? tooltip : undefined });
    };

    renderActionButtons = () => {
        const { openEditPopup, profileData } = this.props;
        const { tooltipOpened } = this.state;

        const userRole = get(profileData, "role", {});

        const tooltip = "editTooltip";

        return (
            <div
                className="legal-entities-location-row-actions"
                style={styles.iconsContainer}
            >
                {hasPermission(userRole, "update") && (
                    <CustomTooltip
                        onClose={() =>
                            this.handleTooltipOnHover(tooltip, false)
                        }
                        onOpen={() => this.handleTooltipOnHover(tooltip, true)}
                        open={tooltipOpened === tooltip}
                        placement="top"
                        title={translation.labels.edit}
                    >
                        <span>
                            <CustomEditIconButton
                                handleTooltipOnHover={this.handleTooltipOnHover}
                                openEditPopup={openEditPopup}
                            />
                        </span>
                    </CustomTooltip>
                )}
            </div>
        );
    };

    renderRow = row => {
        const { locations } = this.props;
        const { name, location_id } = row;

        if (!location_id) {
            return null;
        }

        const legalEntityLocation = (locations || []).find(
            ({ _id }) => _id === location_id
        );

        const locationFieldValue = {
            label: legalEntityLocation?.name,
            value: legalEntityLocation?._id,
            option: {
                name: legalEntityLocation?.name,
                id: legalEntityLocation?._id
            }
        };

        return (
            <Fragment>
                <div style={styles.suppliersContainer}>{name}</div>

                <div style={styles.selectsContainer}>
                    <CustomSelect
                        containerStyle={styles.containerStyle}
                        controlStyle={styles.controlStyle}
                        disabled
                        disabledStyle={styles.selectControlDisabled}
                        dropdownIndicatorContainerStyle={
                            styles.dropdownIndicatorContainer
                        }
                        isMulti
                        keyLabel={`test`}
                        multiValueLabelStyle={styles.selectMultiValueLabel}
                        multiValueStyle={styles.selectMultiValue}
                        value={[locationFieldValue]}
                    />
                </div>
                {this.renderActionButtons()}
            </Fragment>
        );
    };

    render() {
        const { containerStyle, entry } = this.props;

        return (
            entry && (
                <TableRow
                    containerStyle={{
                        ...containerStyle,
                        ...styles.rowContainer
                    }}
                >
                    {this.renderRow(entry)}
                </TableRow>
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profileData,
        locations: get(state, "chambersLocations.locations", undefined)
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                openPopup,
                closeEditPopup,
                closePopup
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChambersLocationRow);
