// Modules
import React from "react";
import get from "lodash.get";
// Components
import {
    TableHeaders,
    WarningPopup
} from "../../../../../../../../../../components";
// Helpers
import { formatTimestamp } from "../../../../../../../../../../services/date";
// Translation
import translation from "../../../../../../../../../../config/translation";
// Styles
import styles from "../EventDetails.css";

/**
 * Opens the un-merge offer review popup.
 *
 * @param {Object} param the data and functions
 */
export function openUnmergeOfferReviewPopup({
    originalOfferId,
    successCb,
    errorCb,
    openPopup,
    closePopup,
    events,
    unmergeOffer
}) {
    openPopup({
        children: (
            <WarningPopup
                buttonAction={() => {
                    unmergeOffer({
                        offerId: originalOfferId,
                        successCb,
                        errorCb
                    });
                }}
                buttonLabel={translation.labels.continue}
                closeFunction={() => {
                    closePopup && closePopup();
                }}
                noIcon
                style={styles.unmergePopup.container}
                subTitle={
                    <div style={styles.unmergePopup.body}>
                        <span>{translation.event.unmerge.subtitle}</span>
                        <div style={styles.bodyInnerContainer}>
                            <TableHeaders
                                containerStyle={styles.unmergePopup.tableGrid}
                                headers={[
                                    translation.events.tableHeaders.startDate,
                                    translation.events.tableHeaders.type,
                                    translation.events.tableHeaders.location
                                ]}
                            />
                            <div style={styles.unmergePopup.rowsContainer}>
                                {events &&
                                    events.map(row => {
                                        let startDate = get(
                                            row,
                                            "start_date",
                                            undefined
                                        );
                                        startDate =
                                            !startDate ||
                                            startDate.toLowerCase() ===
                                                "auf anfrage"
                                                ? translation.event.onRequest
                                                : formatTimestamp(
                                                      startDate,
                                                      "D. MMMM YYYY"
                                                  );

                                        let type = get(row, "type", undefined);
                                        type =
                                            translation.event.type[type] || "-";

                                        const location =
                                            get(
                                                row,
                                                "location.name",
                                                undefined
                                            ) || "-";

                                        return (
                                            <div
                                                key={get(row, "_id", undefined)}
                                                style={{
                                                    ...styles.unmergePopup
                                                        .tableGrid,
                                                    ...styles.unmergePopup.rows
                                                }}
                                            >
                                                <span
                                                    style={
                                                        styles.unmergePopup.row
                                                    }
                                                >
                                                    {startDate}
                                                </span>
                                                <span
                                                    style={
                                                        styles.unmergePopup.row
                                                    }
                                                >
                                                    {type}
                                                </span>
                                                <span
                                                    style={
                                                        styles.unmergePopup.row
                                                    }
                                                >
                                                    {location}
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                }
                title={translation.event.unmerge.title}
            />
        )
    });
}
