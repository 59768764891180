import Global from "../../../../../../config/global.css";

const styles = {
    container: {
        padding: "16px"
    },
    drawerPaper: {
        borderRight: "unset",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        left: "72px",
        position: "absolute",
        top: "0px",
        width: "232px",
        zIndex: 3
    },
    headerTitle: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        paddingBottom: "16px"
    }
};

export default styles;
