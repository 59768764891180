import Global from "../../../../../config/global.css";

const styles = {
    rowContainer: {
        gridAutoFlow: "column",
        minHeight: "64px"
    },
    suppliers: {
        alignRight: {
            textAlign: "end"
        },
        container: {
            display: "grid",
            gap: "8px",
            lineHeight: "24px"
        },
        innerContainer: {
            alignItems: "center",
            display: "grid",
            gap: "24px",
            gridAutoFlow: "column",
            gridTemplateColumns: "minmax(250px, 77%) minmax(150px, 33%) 64px"
        },
        status: {
            container: {
                alignItems: "center",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                height: "18px",
                width: "18px"
            },
            icon: {
                color: Global.colors.white,
                fontSize: "16px"
            },
            green: {
                backgroundColor: Global.colors.oliveGreen
            },
            red: {
                backgroundColor: Global.colors.melon
            },
            yellow: {
                backgroundColor: Global.colors.leo
            }
        },
        supplier: {
            backgroundColor: Global.colors.digitalBlueT4,
            borderRadius: "4px",
            color: Global.colors.darkSlateBlue,
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "13px",
            minHeight: "24px",
            padding: "5px 8px",
            width: "fit-content"
        }
    },
    text: {
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        hyphens: "auto",
        WebkitHyphens: "auto",
        lineHeight: "1.4em",
        wordBreak: "break-word"
    }
};

export default styles;
