// Modules
import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { Card } from "../";
// Styles
import styles from "./TagsCard.css";

function TagsCard({ data, title }) {
    if (!data || data.length === 0) {
        return null;
    }

    return (
        <Card style={styles.container}>
            {title && <span style={styles.title}>{title}</span>}
            <div style={styles.tags.container}>
                {data.map((entry, index) => (
                    <div key={index} style={styles.tags.label}>
                        {get(entry, "label", undefined)}
                    </div>
                ))}
            </div>
        </Card>
    );
}

TagsCard.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            count: PropTypes.number,
            label: PropTypes.string
        })
    ),
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default TagsCard;
