// Modules
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
// Reducers
import createRootReducer from "./combinedReducers";
// Firebase
import { getFirebase } from "react-redux-firebase";

export const history = createBrowserHistory();

const store = createStore(
    createRootReducer(history),
    {},
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk.withExtraArgument(getFirebase)
        )
    )
);

export default store;
