// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { MaterialButton } from "../../../../../../../components/";
// Actions
import {
    closeViewPopup,
    openViewPopup,
    updateViewPopupState
} from "../../../../../../../components/ViewPopup/ViewPopup.ducks";
import {
    clearOffersClassificationList,
    mergeOffers
} from "../../../../OffersClassification.ducks";
import { openSnackbar } from "../../../../../../../components/CustomSnackBar/CustomSnackBar.ducks";
// Helpers
import {
    handleActionError,
    handleActionSuccess
} from "../../../../helpers/classificationAction";
// Translation
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./OfferMergeFooter.css";

class OfferMergeFooter extends PureComponent {
    static propTypes = {
        clearOffersClassificationList: PropTypes.func,
        closeViewPopup: PropTypes.func,
        mergeOffers: PropTypes.func,
        offer: PropTypes.object,
        openPopup: PropTypes.func,
        openSnackbar: PropTypes.func,
        openViewPopup: PropTypes.func,
        removeSelection: PropTypes.func,
        viewPopup: PropTypes.object,
        updateViewPopupState: PropTypes.func
    };

    componentDidMount() {
        // Fixed row of the offer to merge
        const element = document.getElementsByClassName(
            "infinite-scroll-container"
        )[0];
        element && element.scrollTo(0, 0);
        element && (element.style.overflow = "hidden");
    }

    componentWillUnmount() {
        // Unfixed row of the offer to merge
        const element = document.getElementsByClassName(
            "infinite-scroll-container"
        )[0];
        element && (element.style.overflow = "auto");
    }

    getOfferElement = offer => {
        const offerURL = get(offer, "url", undefined);
        const rowElement = offerURL && document.getElementById(offerURL);

        return rowElement;
    };

    closeMergeAction = (offer, removeSelection) => {
        const {
            clearOffersClassificationList,
            closeViewPopup,
            viewPopup,
            updateViewPopupState
        } = this.props;

        closeViewPopup && closeViewPopup();
        removeSelection && removeSelection();
        updateViewPopupState &&
            updateViewPopupState(
                Object.assign({}, get(viewPopup, "state", {}), {
                    selection: undefined
                })
            );
        clearOffersClassificationList &&
            clearOffersClassificationList("approved");

        const scrollPosition = get(
            viewPopup,
            "state.scrollPosition",
            undefined
        );

        if (scrollPosition) {
            const element = document.getElementsByClassName(
                scrollPosition.element
            )[0];

            if (element) {
                setTimeout(() => {
                    element.scrollTo(scrollPosition.left, scrollPosition.top);
                }, 2);
                return;
            }
        }

        const rowElement = this.getOfferElement(offer);
        rowElement &&
            rowElement.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "start"
            });
    };

    handleMergeOffers = (offer, removeSelection) => {
        const { mergeOffers, openPopup, openSnackbar, viewPopup } = this.props;
        const selection = get(viewPopup, "state.selection", undefined);

        const offerId = get(offer, "_id", undefined);

        const cb = refreshDataCb => {
            refreshDataCb && refreshDataCb();
            this.closeMergeAction(offer, removeSelection);
        };

        mergeOffers &&
            mergeOffers({
                offerId,
                mergeToOfferId: get(selection, "_id", undefined),
                successCb: refreshDataCb => {
                    handleActionSuccess({
                        offer,
                        openSnackbar,
                        refreshDataCb: () => cb(refreshDataCb),
                        customTexts:
                            translation.offersClassification.successMerge
                    });
                },
                errorCb: refreshDataCb => {
                    handleActionError({
                        openPopup,
                        refreshDataCb: () => cb(refreshDataCb)
                    });
                }
            });
    };

    render() {
        const { offer, removeSelection, viewPopup } = this.props;
        const selection = get(viewPopup, "state.selection", undefined);

        const actionDisabled = !selection;

        return (
            <div style={styles.container}>
                <MaterialButton
                    containerStyle={styles.backButton.container}
                    customType="boldPrimary"
                    onClick={() => {
                        this.closeMergeAction(offer, removeSelection);
                    }}
                    style={styles.backButton.default}
                >
                    <span
                        className="icon-arrow-back"
                        style={styles.backButton.icon}
                    />
                    {translation.offersClassification.back}
                </MaterialButton>
                <MaterialButton
                    containerStyle={styles.mergeButton.container}
                    customType="boldPrimary"
                    disabled={actionDisabled}
                    onClick={() =>
                        this.handleMergeOffers(offer, removeSelection)
                    }
                    style={{
                        ...styles.mergeButton.default,
                        ...(actionDisabled ? styles.mergeButton.disabled : {})
                    }}
                >
                    {translation.offersClassification.merge}
                </MaterialButton>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPopup: state.viewPopup
    };
};

const mapDispatchToProps = {
    clearOffersClassificationList,
    closeViewPopup,
    mergeOffers,
    openSnackbar,
    openViewPopup,
    updateViewPopupState
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferMergeFooter);
