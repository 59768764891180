// Translation
import translation from "../../../../../../config/translation";

export const data = [
    {
        index: 1,
        title: translation.userManual.subTopics.overview,
        description: translation.userManual.descriptions.chatInteraction["1"],
        image: require("../../../../../../images/ChatVerwaltung_1.png").default,
        style: {
            image: {
                maxWidth: "500px"
            }
        }
    },
    {
        index: 2,
        title: translation.userManual.subTopics.ongoingConversations,
        description: translation.userManual.descriptions.chatInteraction["2"],
        image: require("../../../../../../images/ChatVerwaltung_2.png").default
    },
    {
        index: 3,
        title: translation.userManual.subTopics.pendingConversations,
        description: translation.userManual.descriptions.chatInteraction["3"],
        image: require("../../../../../../images/ChatVerwaltung_3.png").default
    }
];
