// Modules
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// Components
import { GroupCard } from "../../../../../";
import {
    IconButton,
    MaterialTextField
} from "../../../../../../../../../../../components";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import { required } from "../../../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../../../config/translation";
// Styles
import styles from "./Instructors.css";

class Instructors extends PureComponent {
    static propTypes = {
        classes: PropTypes.object
    };

    renderInstructors = ({ fields }) => {
        const { classes } = this.props;

        return (
            <Fragment>
                {fields.length > 0 && (
                    <div style={styles.innerContainer}>
                        {fields.map((item, index) => {
                            return (
                                <div key={index} style={styles.mainContainer}>
                                    <div style={styles.container}>
                                        <span style={styles.label}>{`${
                                            translation.event.instructor
                                        } ${index + 1}`}</span>
                                        <IconButton
                                            defaultClassName="icon-delete"
                                            onClick={() => {
                                                fields.remove(index);
                                            }}
                                            type={["default"]}
                                            style={styles.deleteButton}
                                        />
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <Field
                                            component={MaterialTextField}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldInput
                                                }
                                            }}
                                            label={translation.event.name}
                                            name={`${item}.name`}
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={required}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                <IconButton
                    defaultClassName="icon-plus"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                        fields.push({});
                    }}
                    type={["default"]}
                    style={styles.addButton}
                >
                    <span style={styles.addButtonLabel}>
                        {translation.events.editPopup.addInstructor}
                    </span>
                </IconButton>
            </Fragment>
        );
    };

    render() {
        return (
            <GroupCard title={translation.event.instructor}>
                <FieldArray
                    component={this.renderInstructors}
                    name="instructors"
                />
            </GroupCard>
        );
    }
}

export default withStyles(styles)(Instructors);
