// Modules
import get from "lodash.get";
// Helpers
import { formatTimestampDETimezone } from "../../../../../../services/date";
// Translations
import translation from "../../../../../../config/translation";

const BOOKING_VIA = {
    gfi: "GFI",
    internal: "E-Mail"
};

/**
 * Creates a csv file with the given bookings.
 *
 * @param {Array} bookings the bookings to export
 *
 */
export const createCsv = bookings => {
    const bookingsSorted = sortBookingData(bookings);

    const date = formatTimestampDETimezone(new Date(Date.now()), "YYYYMMDD");

    const fileName = `Export Buchungen_${date}.csv`;

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
        "Bildungsträger,Angebots Titel,Klassifizierung,Start Datum,Rechnungs Status,Anmeldung,Teilnehmer,ID\r\n";

    const data = bookingsSorted
        ? bookingsSorted.map(booking => {
              const supplier = get(booking, "supplier.name", "");
              let delimiterCharLE = '"';
              supplier.includes('"') && (delimiterCharLE = "'");

              const offerTitle = get(booking, "offer_title", "");
              let delimiterCharOT = '"';
              offerTitle.includes('"') && (delimiterCharOT = "'");

              return [
                  `${delimiterCharLE}${supplier}${delimiterCharLE}`,
                  `${delimiterCharOT}${offerTitle}${delimiterCharOT}`,
                  get(
                      translation.offers.classifications,
                      get(booking, "classification", ""),
                      ""
                  ),
                  get(booking, "start_date", "").split("T")[0],
                  translation.invoicing.filters[get(booking, "status", "")] ||
                      "",
                  get(BOOKING_VIA, get(booking, "source", ""), ""),
                  get(booking, "booked_participants", []).length,
                  get(booking, "_id", "")
              ];
          })
        : [];

    data.forEach(rowArray => {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const hiddenElement = document.createElement("a");

    hiddenElement.setAttribute("href", encodedUri);
    hiddenElement.setAttribute("download", fileName);

    hiddenElement.click();
};

/**
 * Sorts a list of bookings, grouping by supplier and then sorted ascending by start date.
 *
 * @param {Array} data bookings to sort
 * @returns a list of bookings sorted.
 */
const sortBookingData = data => {
    if (!data || data.length === 0) {
        return [];
    }

    let groupedBySupplier = {};
    data.sort(sortBySupplierName).forEach(booking => {
        const supplier = get(booking, "supplier.name", "");

        if (Object.keys(groupedBySupplier).includes(supplier)) {
            groupedBySupplier[supplier].push(booking);
        } else {
            groupedBySupplier[supplier] = [booking];
        }
    });

    let sortedData = [];
    Object.values(groupedBySupplier).forEach(bookings => {
        sortedData.push(...sortByStartDate(bookings));
    });

    return sortedData;
};

/**
 * Sorts a list by start_date param.
 *
 * @param {Array} list the list to sort
 * @returns a list sorted by start date
 */
const sortByStartDate = list =>
    list.sort((a, b) => {
        return get(a, "start_date", undefined)
            ? get(b, "start_date", undefined)
                ? new Date(a.start_date) < new Date(b.start_date)
                    ? -1
                    : new Date(a.start_date) > new Date(b.start_date)
                    ? 1
                    : 0
                : -1
            : 1;
    });

/**
 * Sort function by supplier name.
 */
const sortBySupplierName = (a, b) => {
    return get(a, "supplier.name", "").localeCompare(
        get(b, "supplier.name", "")
    );
};
