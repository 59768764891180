// Modules
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { change } from "redux-form";
import PropTypes from "prop-types";
// Components
import { GroupCard } from "../../../";
import {
    CreatableSelect,
    FileInput,
    IconButton,
    MaterialTextField
} from "../../../../../../../../../components";
// Form
import { Field, FieldArray } from "redux-form";
// Validators
import {
    onlyAlphaNum,
    required
} from "../../../../../../../../../services/validators";
// Translations
import translation from "../../../../../../../../../config/translation";
// Helpers
import { createEmptyVideo } from "../../../../../../helpers/offerDetailsFormatHelper";
import { renderBrowseFileButton } from "../../../../../../helpers/offerDetailsFormHelper";
// Styles
import styles from "./Videos.css";
import { withStyles } from "@material-ui/core";

class Videos extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            key: 0
        };
    }
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        classes: PropTypes.object
    };

    videoRef = {};

    renderVideos = ({ fields }) => {
        const { dispatch, classes } = this.props;

        const options = [
            {
                label: "Link",
                value: "video_link"
            },
            {
                label: "Video",
                value: "video_file"
            }
        ];

        return (
            <Fragment>
                {fields.length > 0 &&
                    fields.map((item, index) => {
                        const itemValues = fields.get(index);
                        const videoType = itemValues?.type;
                        const hasVideoSet =
                            !!itemValues?.file || !!itemValues?.file?.url;

                        return (
                            <div key={index} style={styles.mainContainer}>
                                <div style={styles.titleContainer}>
                                    <span style={styles.label}>{`${
                                        translation.offers.editPopup.video
                                    } ${index + 1}`}</span>
                                    <IconButton
                                        defaultClassName="icon-delete"
                                        onClick={() => {
                                            fields.remove(index);
                                            this.setState(prevState => ({
                                                key: prevState.key + 1
                                            }));
                                        }}
                                        type={["default"]}
                                        style={styles.deleteButton}
                                    />
                                </div>
                                <div
                                    style={{
                                        ...styles.formContainer,
                                        ...(videoType === "video_file"
                                            ? styles.fourBlocksGrid
                                            : {})
                                    }}
                                >
                                    <Field
                                        component={MaterialTextField}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldInput
                                            }
                                        }}
                                        label={
                                            translation.offers.editPopup.title
                                        }
                                        name={`${item}.title`}
                                        style={styles.textField}
                                        variant="outlined"
                                        validate={required}
                                    />

                                    <Field
                                        key={`${item}.${this.state.key}`}
                                        component={CreatableSelect}
                                        disabled={
                                            hasVideoSet &&
                                            !(
                                                itemValues.file.url instanceof
                                                File
                                            )
                                        }
                                        customOnChange={option =>
                                            dispatch(
                                                change(
                                                    "offerDetails",
                                                    `${item}.type`,
                                                    option?.value
                                                )
                                            )
                                        }
                                        editable
                                        isSearchable={false}
                                        hideSelectedOptions
                                        label={
                                            translation.offers.editPopup
                                                .videoTypeLabel
                                        }
                                        name={`${item}.type`}
                                        options={options}
                                        validate={required}
                                    />

                                    {videoType === "video_link" && (
                                        <Field
                                            component={MaterialTextField}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldInput
                                                }
                                            }}
                                            label={
                                                translation.offers.editPopup
                                                    .videoLink
                                            }
                                            name={`${item}.link`}
                                            placeholder={
                                                translation.offers.editPopup
                                                    .videoLinkPlaceholder
                                            }
                                            style={styles.textField}
                                            variant="outlined"
                                            validate={
                                                videoType === "video_file"
                                                    ? required
                                                    : undefined
                                            }
                                        />
                                    )}

                                    {videoType === "video_file" && (
                                        <>
                                            <Field
                                                component={MaterialTextField}
                                                disabled={
                                                    hasVideoSet &&
                                                    !(
                                                        itemValues.file
                                                            .url instanceof File
                                                    )
                                                }
                                                label={
                                                    translation.offers.editPopup
                                                        .name
                                                }
                                                name={`${item}.name`}
                                                style={styles.textField}
                                                variant="outlined"
                                                validate={[
                                                    required,
                                                    onlyAlphaNum
                                                ]}
                                            />
                                            <Field
                                                component={MaterialTextField}
                                                endAdornment={renderBrowseFileButton(
                                                    () => {
                                                        this.videoRef[index] &&
                                                            this.videoRef[
                                                                index
                                                            ].click();
                                                    },
                                                    hasVideoSet,
                                                    () => {
                                                        dispatch(
                                                            change(
                                                                "offerDetails",
                                                                `${item}.file`,
                                                                null
                                                            )
                                                        );
                                                    }
                                                )}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                label={
                                                    translation.offers.editPopup
                                                        .video
                                                }
                                                name={`${item}.file.name`}
                                                placeholder={
                                                    translation.offers.editPopup
                                                        .browseFile
                                                }
                                                style={{
                                                    ...styles.disabled,
                                                    ...styles.textField
                                                }}
                                                variant="outlined"
                                                validate={required}
                                            />
                                            <Field
                                                accept=".mp4"
                                                component={FileInput}
                                                customOnChange={file => {
                                                    file &&
                                                        dispatch(
                                                            change(
                                                                "offerDetails",
                                                                `${item}.file.name`,
                                                                file.name
                                                            )
                                                        );
                                                }}
                                                inputRef={ref => {
                                                    this.videoRef[index] !==
                                                        ref &&
                                                        (this.videoRef[index] =
                                                            ref);
                                                }}
                                                name={`${item}.file.url`}
                                                style={styles.uploadInput}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                <IconButton
                    defaultClassName="icon-plus"
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                        fields.push(createEmptyVideo());
                    }}
                    type={["default"]}
                    style={styles.addButton}
                >
                    <span style={styles.addButtonLabel}>
                        {translation.offers.editPopup.addVideo}
                    </span>
                </IconButton>
            </Fragment>
        );
    };

    render() {
        return (
            <GroupCard title={translation.offers.editPopup.video}>
                <FieldArray component={this.renderVideos} name="videos" />
            </GroupCard>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(dispatch)
    };
}

export default compose(
    connect(null, mapDispatchToProps),
    withStyles(styles)
)(Videos);
