// Modules
import get from "lodash.get";

export function formatStyle(variant, multiline, styles, style) {
    let customStyle = get(styles, variant, {});

    let errorStyle = { ...get(style, "error", {}) };
    delete errorStyle.input;
    delete errorStyle.label;
    let focusedStyle = get(style, "focused", {});
    let focusedInputStyle = get(style, "focusedInput", {});
    let disabledStyle = get(style, "disabled", {});

    customStyle.root["& label"] = {
        ...customStyle.root["& label"],
        ...get(style, "label", {})
    };
    customStyle.root["& label"]["&.Mui-error"] = {
        ...customStyle.root["& label"]["&.Mui-error"],
        ...errorStyle
    };
    customStyle.root["& label"]["&.Mui-error"] = {
        ...customStyle.root["& label"]["&.Mui-error"],
        ...get(style, "error.label", {})
    };
    customStyle.root["& label"]["&.Mui-disabled"] = {
        ...customStyle.root["& label"]["&.Mui-disabled"],
        ...disabledStyle
    };
    customStyle.root["& label"]["&.Mui-focused"] = {
        ...customStyle.root["& label"]["&.Mui-focused"],
        ...focusedStyle
    };
    customStyle.root["& .MuiInputBase-input"] = {
        ...customStyle.root["& .MuiInputBase-input"],
        ...get(style, "input", {})
    };
    customStyle.root["& .MuiInputBase-input"]["&.Mui-disabled"] = {
        ...customStyle.root["& .MuiInputBase-input"]["&.Mui-disabled"],
        ...disabledStyle
    };
    customStyle.root["& .MuiInputBase-root"] = {
        ...customStyle.root["& .MuiInputBase-root"],
        ...get(style, "inputRoot", {})
    };
    customStyle.root["& .MuiInputBase-root"]["&.Mui-error"] = {
        ...customStyle.root["& .MuiInputBase-root"]["&.Mui-error"],
        ...get(style, "error.input", {})
    };
    customStyle.root["& .MuiFormHelperText-root"] = {
        ...customStyle.root["& .MuiFormHelperText-root"],
        ...get(style, "helperText", {})
    };
    customStyle.root["& .MuiFormHelperText-root"]["&.Mui-error"] = {
        ...customStyle.root["& .MuiFormHelperText-root"]["&.Mui-error"],
        ...errorStyle
    };
    if (variant === "outlined") {
        customStyle.root["& .MuiInputLabel-outlined"][
            "&.MuiInputLabel-shrink"
        ] = {
            ...customStyle.root["& .MuiInputLabel-outlined"][
                "&.MuiInputLabel-shrink"
            ],
            ...get(style, "labelShrinked", {})
        };
        customStyle.root["& .MuiOutlinedInput-notchedOutline"] = {
            ...customStyle.root["& .MuiOutlinedInput-notchedOutline"],
            ...get(style, "outline", {})
        };
        customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-error"][
            "& .MuiOutlinedInput-notchedOutline"
        ] = {
            ...customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-error"][
                "& .MuiOutlinedInput-notchedOutline"
            ],
            ...errorStyle
        };
        customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-focused"] = {
            ...customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-focused"],
            ...focusedInputStyle
        };
        customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-focused"][
            "& .MuiOutlinedInput-notchedOutline"
        ] = {
            ...customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-focused"][
                "& .MuiOutlinedInput-notchedOutline"
            ],
            ...focusedStyle
        };
        customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-disabled"] = {
            ...customStyle.root["& .MuiOutlinedInput-root"]["&.Mui-disabled"],
            ...disabledStyle
        };
        multiline &&
            (customStyle.root["& .MuiOutlinedInput-multiline"] = {
                ...customStyle.root["& .MuiOutlinedInput-multiline"],
                ...get(style, "input", {})
            });
    }
    return customStyle;
}
