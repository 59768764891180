// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import Select, { components } from "react-select";
import { FormHelperText, InputLabel } from "@material-ui/core";
// Styles
import styles from "./CustomSelect.css";

class CustomSelect extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object,
        controlStyle: PropTypes.object,
        customComponents: PropTypes.shape({
            ClearIndicator: PropTypes.func,
            DropdownIndicator: PropTypes.func,
            IndicatorSeparator: PropTypes.func,
            MultiValueRemove: PropTypes.func
        }),
        defaultOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        disabled: PropTypes.bool,
        disabledStyle: PropTypes.object,
        dropdownIndicatorContainerStyle: PropTypes.object,
        editable: PropTypes.bool,
        error: PropTypes.bool,
        errorMessage: PropTypes.string,
        errorMessageStyle: PropTypes.object,
        hideSelectedOptions: PropTypes.bool,
        icon: PropTypes.node,
        input: PropTypes.object,
        inputStyle: PropTypes.object,
        isClearable: PropTypes.bool,
        isMulti: PropTypes.bool,
        isSearchable: PropTypes.bool,
        menuContainerStyle: PropTypes.object,
        menuListContainerStyle: PropTypes.object,
        meta: PropTypes.object,
        multiValueLabelStyle: PropTypes.object,
        multiValueRemoveStyle: PropTypes.object,
        multiValueStyle: PropTypes.object,
        name: PropTypes.string,
        label: PropTypes.string,
        noOptionsMessage: PropTypes.string,
        onBlur: PropTypes.func,
        onMenuClose: PropTypes.func,
        onSelection: PropTypes.func,
        optionFocusedStyle: PropTypes.object,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                option: PropTypes.object.isRequired
            })
        ),
        optionSelectedStyle: PropTypes.object,
        optionStyle: PropTypes.object,
        outerContainerStyle: PropTypes.object,
        placeholder: PropTypes.string,
        singleValueStyle: PropTypes.object,
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        valueContainerStyle: PropTypes.object
    };

    componentDidMount() {
        const { defaultOption, input } = this.props;
        input &&
            input.onChange &&
            defaultOption &&
            input.onChange(defaultOption);
    }

    ClearIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;
        return (
            <div {...restInnerProps} style={styles.iconContainer} ref={ref}>
                <span className="icon-close" style={styles.clearIcon} />
            </div>
        );
    };

    DropdownIndicator = props => {
        const {
            innerProps: { ref, ...restInnerProps }
        } = props;

        const { disabled, disabledStyle, dropdownIndicatorContainerStyle } =
            this.props;

        const { icon } = this.props;
        return (
            <div
                {...restInnerProps}
                style={{
                    ...styles.iconContainer,
                    ...dropdownIndicatorContainerStyle
                }}
                ref={ref}
            >
                {icon ? (
                    icon
                ) : props.selectProps.menuIsOpen ? (
                    <span className="icon-arrow-up" style={styles.arrowIcon} />
                ) : (
                    <span
                        className="icon-arrow-down"
                        style={
                            disabled && disabledStyle
                                ? { ...styles.arrowIcon, ...disabledStyle }
                                : disabled
                                ? styles.disabledIcon
                                : styles.arrowIcon
                        }
                    />
                )}
            </div>
        );
    };

    MultiValueRemove = props => {
        return (
            <components.MultiValueRemove {...props}>
                <span
                    className="icon-close"
                    style={styles.iconRemoveContainer}
                />
            </components.MultiValueRemove>
        );
    };

    render() {
        const {
            containerStyle,
            controlStyle,
            customComponents,
            defaultOption,
            disabled,
            disabledStyle,
            editable,
            error,
            errorMessage,
            errorMessageStyle,
            hideSelectedOptions,
            input,
            inputStyle,
            isClearable,
            isMulti,
            isSearchable,
            menuContainerStyle,
            menuListContainerStyle,
            meta,
            multiValueLabelStyle,
            multiValueRemoveStyle,
            multiValueStyle,
            noOptionsMessage,
            onBlur,
            onMenuClose,
            onSelection,
            optionFocusedStyle,
            options,
            optionSelectedStyle,
            optionStyle,
            outerContainerStyle,
            placeholder,
            singleValueStyle,
            value,
            label,
            valueContainerStyle,
            ...props
        } = this.props;

        const singleValue = singleValueStyle ? singleValueStyle : null;
        const valueContainer = valueContainerStyle ? valueContainerStyle : null;

        const hasError = error || Boolean(meta && meta.touched && meta.error);
        const helperTextErrorMessage =
            errorMessage || (meta && meta.touched && meta.error);

        return (
            <div
                key={`custom-select-outer-container-${props.keyLabel}`}
                style={outerContainerStyle}
            >
                {label && (
                    <InputLabel
                        style={{
                            ...styles.label,
                            ...styles.labelShrink
                        }}
                    >
                        {label}
                    </InputLabel>
                )}
                <Select
                    closeMenuOnSelect={!isMulti}
                    components={{
                        ClearIndicator: get(
                            customComponents,
                            "ClearIndicator",
                            this.ClearIndicator
                        ),
                        DropdownIndicator: get(
                            customComponents,
                            "DropdownIndicator",
                            this.DropdownIndicator
                        ),
                        IndicatorSeparator: get(
                            customComponents,
                            "IndicatorSeparator",
                            null
                        ),
                        MultiValueRemove: get(
                            customComponents,
                            "MultiValueRemove",
                            this.MultiValueRemove
                        )
                    }}
                    defaultValue={defaultOption}
                    hideSelectedOptions={
                        hideSelectedOptions !== undefined
                            ? hideSelectedOptions
                            : false
                    }
                    isClearable={isClearable || false}
                    isDisabled={disabled}
                    isMulti={isMulti || false}
                    isSearchable={isSearchable || false}
                    key={`custom-select-${props.keyLabel}`}
                    noOptionsMessage={() => noOptionsMessage}
                    onBlur={() => {
                        onBlur && onBlur();
                    }}
                    onChange={e => {
                        onSelection && onSelection(e);
                        input && input.onChange(e);
                    }}
                    onMenuClose={() => {
                        onMenuClose && onMenuClose();
                    }}
                    options={options}
                    placeholder={placeholder}
                    styles={{
                        container: base => ({
                            ...base,
                            ...containerStyle
                        }),
                        control: (base, data) => ({
                            ...base,
                            ...styles.selectContainer,
                            ...(data ? styles.selectContainerMargin : {}),
                            ...(data.isMulti
                                ? styles.selectContainerMulti
                                : {}),
                            ...(data.isFocused && data.menuIsOpen
                                ? styles.selectContainerOpened
                                : {}),
                            ...(hasError ? styles.errorContainer : {}),
                            ...controlStyle,
                            ...(disabled
                                ? {
                                      ...styles.disabledContainer,
                                      ...disabledStyle
                                  }
                                : {})
                        }),
                        input: base => ({
                            ...base,
                            ...styles.input,
                            ...inputStyle
                        }),
                        menu: base => ({
                            ...base,
                            ...styles.menu,
                            ...menuContainerStyle
                        }),
                        menuList: base => ({
                            ...base,
                            ...styles.menuList,
                            ...menuListContainerStyle
                        }),
                        multiValue: base => ({
                            ...base,
                            ...styles.multiValue,
                            ...multiValueStyle
                        }),
                        multiValueLabel: base => ({
                            ...base,
                            ...styles.multiValueLabel,
                            ...multiValueLabelStyle
                        }),
                        multiValueRemove: base => ({
                            ...base,
                            ...styles.multiValueRemove,
                            ...multiValueRemoveStyle,
                            ...(!(!disabled && editable)
                                ? styles.multiValueRemoveHide
                                : {})
                        }),
                        noOptionsMessage: base => ({
                            ...base,
                            ...styles.noOptionsMessage
                        }),
                        option: (base, data) => ({
                            ...base,
                            ...styles.option,
                            ...optionStyle,
                            ...(data.isSelected
                                ? {
                                      ...styles.optionSelected,
                                      ...optionSelectedStyle
                                  }
                                : {}),
                            ...(data.isFocused
                                ? {
                                      ...styles.optionFocused,
                                      ...optionFocusedStyle
                                  }
                                : {})
                        }),
                        placeholder: base => ({
                            ...base,
                            ...styles.placeholder
                        }),
                        singleValue: base => ({
                            ...base,
                            ...styles.singleValue,
                            ...singleValue
                        }),
                        valueContainer: base => ({
                            ...base,
                            ...styles.valueContainer,
                            ...valueContainer
                        })
                    }}
                    value={value}
                    {...props}
                />
                {hasError && helperTextErrorMessage && (
                    <FormHelperText
                        error
                        id="cs-error-message"
                        key={`cs-helper-text-${props.keyLabel}`}
                        style={{ ...styles.errorMessage, ...errorMessageStyle }}
                    >
                        {helperTextErrorMessage}
                    </FormHelperText>
                )}
            </div>
        );
    }
}

export default CustomSelect;
