// Modules
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import { IconButton } from "@material-ui/core";
// Styles
import styles from "./MaterialIconButton.css";

const CssIconButton = (type, style) => {
    let customStyle = { ...styles };
    delete customStyle.default;
    delete customStyle.iconRippleChild;

    let addionalStyle = { ...style };
    delete addionalStyle.disabled;
    delete addionalStyle.onHover;
    delete addionalStyle.default;

    customStyle.root = {
        ...customStyle.root,
        ...addionalStyle
    };

    customStyle.disabled = {
        ...customStyle.disabled,
        ...get(style, "disabled", {})
    };

    if (type.includes("default")) {
        customStyle.root = {
            ...customStyle.root,
            ...get(styles, "default", {}),
            ...get(style, "default", {})
        };

        customStyle.root["&:hover"] = {
            ...customStyle.root["&:hover"],
            ...get(style, "onHover", {})
        };
    }

    return withStyles(customStyle)(IconButton);
};

class MaterialIconButton extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        children: PropTypes.node,
        defaultClassName: PropTypes.string.isRequired,
        defaultType: PropTypes.oneOf(["button", "submit"]),
        disabled: PropTypes.bool,
        disableFocusRipple: PropTypes.bool,
        disableRipple: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        onMouseOut: PropTypes.func,
        onMouseOver: PropTypes.func,
        style: PropTypes.shape({
            default: PropTypes.object,
            disabled: PropTypes.object,
            onHover: PropTypes.object
        }),
        TouchRippleProps: PropTypes.object,
        type: PropTypes.arrayOf(PropTypes.oneOf(["default"])).isRequired
    };

    static defaultProps = {
        defaultType: "button",
        type: "default"
    };

    render() {
        const {
            children,
            classes,
            defaultClassName,
            defaultType,
            disabled,
            disableFocusRipple,
            disableRipple,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onMouseOut,
            onMouseOver,
            style,
            TouchRippleProps,
            type
        } = this.props;

        const className = classNames(classes.root, defaultClassName, {
            [classes.default]: type.includes("default"),
            [classes.disabled]: disabled
        });

        const StyledButton = CssIconButton(type, style);

        return (
            <StyledButton
                className={className}
                disabled={disabled}
                disableFocusRipple={disableFocusRipple}
                disableRipple={disableRipple}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
                style={style}
                TouchRippleProps={{
                    classes: { child: classes.iconRippleChild },
                    ...TouchRippleProps
                }}
                type={defaultType}
            >
                {children}
            </StyledButton>
        );
    }
}

export default withStyles(styles)(MaterialIconButton);
