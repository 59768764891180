// Modules
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import isEqual from "lodash.isequal";
// Form
import { reduxForm } from "redux-form";
// Components
import {
    BasicInfo,
    Contacts,
    Dates,
    Duration,
    Entity,
    Instructors,
    Location,
    Modules
} from "./components/";
// Actions
import {
    getEntities,
    getLocations,
    upsertEvent
} from "./EventDetailsForm.ducks";
import { closeEditPopup } from "../../../../../../../../../components/EditPopup/EditPopup.ducks";
import {
    closePopup,
    openPopup
} from "../../../../../../../../../components/Popup/Popup.ducks";
import {
    clearOfferDetails,
    getOfferDetails,
    setOfferDetailsTab
} from "../../../../OfferDetails.ducks";
import { navigateTo } from "../../../../../../../Router.ducks";
// Helpers
import { mapInitialValues } from "../../helpers/formMappingsHelper";
import {
    handleErrorPopupDisplay,
    mapEventData
} from "../../helpers/eventDetailsFormHelper";
import { routesPaths } from "../../../../../../../components/Routes/Routes.helper";
import { getUserRoleEntityId } from "../../../../../../helpers/offerPermissions";
// Styles
import styles from "./EventDetailsForm.css";

function onSubmit(values, props) {
    const {
        clearOfferDetails,
        closeEditPopup,
        closePopup,
        details,
        eventDetailsForm,
        getOfferDetails,
        navigateTo,
        openPopup,
        profileData,
        searchFilter,
        setOfferDetailsTab,
        upsertEvent
    } = props;

    const initialValues = get(eventDetailsForm, "initial", undefined);
    const valuesAreEqual = isEqual(initialValues, values);

    if (valuesAreEqual) {
        closeEditPopup && closeEditPopup();
        return;
    }

    const offerUrl = get(details, "url", undefined);

    let event = mapEventData(initialValues, values);

    const opType = values.id ? "edit" : "create";
    const startDateUpdated = Object.keys(event).includes("start_date");

    upsertEvent &&
        upsertEvent(
            opType,
            offerUrl,
            event,
            endSpinner => {
                clearOfferDetails && clearOfferDetails();
                setOfferDetailsTab && setOfferDetailsTab("events");

                setTimeout(() => {
                    const entityId =
                        getUserRoleEntityId(profileData) ||
                        get(searchFilter, "legalEntity.value", undefined);
                    getOfferDetails && getOfferDetails(offerUrl, entityId);
                    closeEditPopup && closeEditPopup();
                    startDateUpdated &&
                        navigateTo &&
                        navigateTo(
                            routesPaths.offerDetails.replace(
                                ":offerUrl",
                                offerUrl
                            )
                        );

                    endSpinner && endSpinner();
                }, 2000);
            },
            (errorType, errorMessage) => {
                handleErrorPopupDisplay({
                    errorType,
                    errorMessage,
                    closePopup,
                    openPopup
                });
            }
        );
}

class EventDetailsForm extends PureComponent {
    static propTypes = {
        details: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        getEntities: PropTypes.func.isRequired,
        getLocations: PropTypes.func.isRequired,
        initialValues: PropTypes.object,
        profileData: PropTypes.object,
        searchFilter: PropTypes.object
    };

    componentDidMount() {
        const { getEntities, getLocations } = this.props;

        getEntities && getEntities();
        getLocations && getLocations();
    }

    render() {
        const { initialValues } = this.props;

        return (
            <form style={styles.container}>
                <BasicInfo />
                <Location />
                <Modules />
                <Dates />
                <Duration />
                <Entity editMode={!!get(initialValues, "id", undefined)} />
                <Instructors />
                <Contacts />
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    const details = state.offerDetails.details;

    let initialValues = mapInitialValues(props, details);

    return {
        details,
        eventDetailsForm: get(state, "form.eventDetails", undefined),
        initialValues: initialValues,
        profileData: state.profileData,
        searchFilter: state.offers.searchFilter
    };
};

const mapDispatchToProps = {
    clearOfferDetails,
    closeEditPopup,
    closePopup,
    getEntities,
    getLocations,
    getOfferDetails,
    navigateTo,
    openPopup,
    setOfferDetailsTab,
    upsertEvent
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "eventDetails",
        onSubmit: (values, dispatch, props) => {
            onSubmit(values, props);
        }
    })(EventDetailsForm)
);
