// Modules
import get from "lodash.get";
// Helpers
import {
    hasSpecificEntityPermission,
    hasSpecificPermission
} from "../../../../services/permissions";

/**
 * Checks offer management permission.
 *
 * @param {Object} userRole the role permissions
 * @param {String} permission the permission to validate
 * @returns true if has permission, false otherwise
 */
export function hasPermission(userRole, permission) {
    return hasSpecificPermission(
        get(userRole, "permissions", {}),
        "offer_management",
        permission
    );
}

/**
 * Checks offer management permission entity dependent.
 *
 * @param {Object} userRole the role permissions
 * @param {String} permission the permission to validate
 * @returns true if has permission, false otherwise
 */
export function hasEntityPermission(userRole, permission) {
    return hasSpecificEntityPermission(
        get(userRole, "permissions", {}),
        "offer_management",
        permission
    );
}

/**
 * Gets the entity id associated to the user role.
 *
 * @param {Object} profileData the user profile data
 * @returns -1 if no entity defined when it is required, the entity id otherwise
 */
export function getUserRoleEntityId(profileData) {
    const userRole = get(profileData, "role", {});
    const viewByEntity = hasEntityPermission(userRole, "view_by_entity");
    const entityId = viewByEntity
        ? get(profileData, "entity_id", undefined)
        : undefined;

    if (viewByEntity && !entityId) {
        return -1;
    }

    return entityId;
}
