import Global from "../../../../config/global.css";

const styles = {
    backButton: {
        container: {
            active: {
                backgroundColor: Global.colors.white,
                color: Global.colors.blue
            },
            onHover: {
                backgroundColor: Global.colors.white,
                color: Global.colors.blue
            },
            container: {
                backgroundColor: Global.colors.white,
                color: Global.colors.darkSlateBlue
            },
            height: "40px",
            padding: "0px",
            width: "max-content"
        },
        icon: {
            alignItems: "center",
            border: "1px solid currentColor",
            borderRadius: "100px",
            color: "inherit",
            display: "flex",
            fontSize: "24px",
            height: "40px",
            justifyContent: "center",
            marginRight: "16px",
            width: "40px"
        }
    },
    childrenContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: "22px 0px 48px",
        minHeight: "fit-content"
    },
    childrenInnerContainer: {
        display: "grid",
        gridAutoRows: "64px",
        gap: "16px"
    },
    consent: {
        checkboxes: {
            display: "grid",
            gap: "12px"
        },
        container: {
            backgroundColor: Global.colors.bgGrey,
            borderRadius: "12px",
            marginTop: "16px",
            padding: "24px",
            width: "768px"
        },
        linkButtonContainer: {
            display: "inline-flex"
        },
        linkButton: {
            root: {
                color: "inherit",
                fontSize: "inherit",
                fontStyle: "inherit",
                fontWeight: "700",
                lineHeight: "inherit"
            },
            active: {
                color: "inherit"
            },
            onHover: {
                color: "inherit"
            }
        },
        linkButtonOnHover: {
            onHover: {
                color: Global.colors.blue
            }
        },
        title: {
            color: Global.colors.darkSlateBlue,
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "30px",
            marginBottom: "16px"
        }
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column"
    },
    errorMessageCloseIcon: {
        color: Global.colors.darkSlateBlue,
        cursor: "pointer",
        fontSize: "24px"
    },
    footerContainer: {
        alignItems: "center",
        color: Global.colors.darkSlateBlue,
        display: "flex",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px"
    },
    getStartedContainer: {
        flex: 1,
        height: "unset",
        margin: "48px 48px 0px",
        minHeight: "min-content"
    },
    getStartedContentContainer: {
        minHeight: "fit-content"
    },
    infoMessage: {
        maxWidth: "456px"
    },
    loginButton: {
        marginLeft: "4px"
    },
    logo: {
        height: "48px"
    },
    logoContainer: {
        marginBottom: "48px",
        maxWidth: "325px"
    },
    passwordIcon: {
        color: Global.colors.blueyGrey,
        cursor: "pointer",
        fontSize: "24px"
    },
    proceedButton: {
        active: {
            backgroundColor: Global.colors.blue,
            color: Global.colors.white
        },
        onHover: {
            backgroundColor: "#0034AC",
            color: Global.colors.white
        },
        container: {
            backgroundColor: Global.colors.blue,
            color: Global.colors.white
        },
        marginBottom: "48px",
        minHeight: "48px",
        width: "180px"
    },
    proceedIcon: {
        color: Global.colors.white,
        fontSize: "20px"
    },
    subtitle: {
        color: Global.colors.darkSlateBlue,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "18px",
        maxWidth: "auto"
    },
    title: {
        color: Global.colors.darkSlateBlue,
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "30px"
    }
};

export default styles;
