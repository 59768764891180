import translation from "../config/translation";

export const email = value => {
    return value &&
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
            value
        )
        ? undefined
        : translation.validators.invalidEmail;
};

export const required = value =>
    value ? undefined : translation.validators.requiredField;

export const requiredWithError = (value, error) => (value ? undefined : error);

export const requiredArray = value =>
    value && value.length > 0
        ? undefined
        : translation.validators.requiredField;

export const onlyAlphaNum = value => {
    return value && /^([A-Za-z0-9_-]+)$/i.test(value)
        ? undefined
        : translation.validators.onlyAlphaNum;
};

export const price = value => {
    return !value || /^\d+(?:\.\d{3})*(?:,\d+)?$/i.test(value)
        ? undefined
        : translation.validators.invalidPrice;
};

export const digitsWithSeparators = value => {
    return !value || /^\d+(?:\.\d{3})*(?:,\d+)?$/i.test(value)
        ? undefined
        : translation.validators.onlyDigits;
};

export const digitsAndDot = value => {
    return !value || /^\d+(?:\.\d+)?$/i.test(value)
        ? undefined
        : translation.validators.digitsAndDot;
};

export const digits = value => {
    return !value || /^\d+$/i.test(value)
        ? undefined
        : translation.validators.onlyDigits;
};

export const telephone = value => {
    return !value || /^[+]?(\d)+(( |-|.|\||\/)?(\d)+){1,}$/i.test(value)
        ? undefined
        : translation.validators.invalidTelephone;
};

export const link = value => {
    const pattern = new RegExp(
        "http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*(),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+"
    );
    return !value || pattern.test(value)
        ? undefined
        : translation.validators.invalidLink;
};
